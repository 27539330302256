import { nanoid } from 'nanoid';

export default {
  namespaced: true,
  state: {
    notifications: [],
  },

  mutations: {
    addNotification(state, payload) {
      const notification = payload;

      state.notifications.push(notification);
    },

    removeNotification(state, payload) {
      const { notificationId } = payload;

      state.notifications = state.notifications.filter(
        (notification) => notification.id !== notificationId,
      );
    },

    removeAllNotifications(state) {
      state.notifications = [];
    },
  },

  actions: {
    notify: {
      // This pattern makes the action available on the root namespace
      // See https://vuex.vuejs.org/guide/modules.html#register-global-action-in-namespaced-modules
      root: true,
      handler(context, { type = 'info', text, timeout, action }) {
        if (!timeout) {
          switch (type) {
            case 'info':
            case 'success':
              timeout = 5000;
              break;
            case 'error':
              timeout = 7000;
              break;
          }
        }

        const notification = {
          id: nanoid(6),
          type,
          text,
          timeout,
          action,
        };

        context.commit('addNotification', notification);

        setTimeout(() => {
          context.commit('removeNotification', {
            notificationId: notification.id,
          });
        }, timeout);
      }, // -> Action: 'notify'
    },
  },
};
