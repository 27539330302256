import { DataDocument, toolsTemplateDirectoryId } from '@fillip/api';

export const TemplateToolEmbed: DataDocument = {
  id: 'tool-embed',
  info: {
    title: 'Tool: Embed',
    icon: 'window-frame-open',
  },
  tag: {
    tag: 'template',
  },
  parentId: toolsTemplateDirectoryId,
  children: {
    default: [
      {
        templateId: 'tag-h3',
        query: ':[data]',
        condition: ':$$showTitle',
        identity: '',
        propMappings: {
          text: ':$title',
        },
      },
      {
        templateId: 'tag-embed',
        query: ':[data]',
        condition: '',
        identity: "${env?.$props?.$embedIdentity || templateId +':'+data.id}",
        propMappings: {
          embedSrc: ':$embedSrc',
          embedCode: ':$embedCode',
        },
      },
    ],
  },
  class: {
    elementClass: '',
    class: 'flex items-center justify-center',
  },
  arrangement: {
    type: 'arrangement.divide',
    divideAspectRatio: '16/9',
    divideStretch: false,
  },
  interfaceDefinition: {
    title: '',
    props: [
      {
        title: 'Titel',
        key: 'title',
        type: 'string',
        defaultValue: ':properties?.title?.value || info.title',
      },
      {
        title: 'Embed-URL',
        key: 'embedSrc',
        type: 'string',
        defaultValue: ':info.content.embedSrc',
      },
      {
        title: 'Embed-Code',
        key: 'embedCode',
        type: 'string',
        defaultValue: ":info.content.embedCode ? info.content.embedCode : ''",
      },
      {
        title: 'Embed-Identity',
        key: 'embedIdentity',
        type: 'string',
        defaultValue: ':`${templateId}:${data.id}`',
      },
    ],
  },
};
