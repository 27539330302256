import { computed, onBeforeUnmount, unref, watch } from '@vue/composition-api';
import { useStore } from '../globals/useStore';

export function useMediasoupConsumer(props: {
  producerId: string;
  participantId: string;
  producerName: string;
}) {
  const { store } = useStore();

  const consumer = computed(() => {
    return store.getters.communityStore?.mediasoup?.consumers?.[
      props.producerId
    ];
  });

  const track = computed(() => {
    return consumer.value?.track ? consumer.value.track : null;
  });

  const addMediasoupConsumer = (producerId: string) => {
    store.commit('client/REQUEST_MEDIASOUP_CONSUMER', {
      communitySlug: store.getters.communitySlug,
      producerId: producerId,
      participantId: props.participantId,
      producerName: props.producerName,
    });
  };

  const removeMediasoupConsumer = (producerId: string) => {
    store.commit('client/UNREQUEST_MEDIASOUP_CONSUMER', {
      communitySlug: store.getters.communitySlug,
      producerId: producerId,
    });
  };

  watch(
    () => props.producerId,
    (newValue, oldValue) => {
      if (oldValue && oldValue !== newValue) {
        removeMediasoupConsumer(oldValue);
      }
      if (newValue) {
        addMediasoupConsumer(newValue);
      }
    },
    { immediate: true },
  );

  onBeforeUnmount(async () => {
    removeMediasoupConsumer(props.producerId);
  });

  return {
    consumer,
    track,
    addMediasoupConsumer,
    removeMediasoupConsumer,
  };
}
