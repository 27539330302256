// Detect if a pointer event happens in the area covered by an HTML Element
export const isMouseEventOverElement = (
  $event: PointerEvent | MouseEvent,
  element: HTMLElement,
  scrollableParent?: string,
) => {
  const { clientX: mouseX, clientY: mouseY } = $event;
  const rect = element.getBoundingClientRect();

  const scrollableElement = scrollableParent
    ? document.querySelector(scrollableParent)
    : document.body;

  const { scrollLeft, scrollTop } = scrollableElement;

  const resultX =
    mouseX >= rect.left + scrollLeft && mouseX <= rect.right + scrollLeft;
  const resultY =
    mouseY >= rect.top + scrollTop && mouseY <= rect.bottom + scrollTop;
  // console.log('Result X', resultX);
  // console.log('Result Y', resultY);
  return resultX && resultY;
};
