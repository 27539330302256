











import Vue from 'vue';
import BaseEditorText from '../base/BaseEditorText.vue';

export default Vue.extend({
  name: 'BlockEditorText',
  components: {
    BaseEditorText,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
});
