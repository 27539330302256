import { deepEqual } from 'fast-equals';
import { EventEmitter } from 'eventemitter3';

export enum ConnectionState {
  CONNECTING = 'CONNECTING',
  CONNECTED = 'CONNECTED',
  PENDING_RECONNECT = 'PENDING_RECONNECT',
  DISCONNECTED = 'DISCONNECTED',
  FAILED = 'FAILED',
}

export interface Connector {
  connectionParameters: any;
  connect: (
    connectionParameters: any,
    onClose: (connection) => {},
  ) => Promise<any>;
  disconnect: (connection) => void;
}

export class ConnectionManager<Connection> extends EventEmitter {
  public connection: Connection = null;
  public closed = false;
  public _state: ConnectionState = ConnectionState.DISCONNECTED;
  public connectionPromise: Promise<Connection> = null;
  public numConnectionAttempts = 0;
  public connectionDelaysInMs = [50, 150, 500, 1000, 4000];
  public resolveConnectionHandle: (connection: Connection) => void;
  private reconnectionTimeout = null;

  get state() {
    return this._state;
  }
  set state(newValue) {
    if (this._state == newValue) return;
    this._state = newValue;
    this.emit('change', newValue);
  }
  constructor(public connector: Connector) {
    super();
    if (connector.connectionParameters) {
      this.CONNECTING();
    }
  }

  async close() {
    this.DISCONNECTED();
    this.removeAllListeners();
    this.closed = true;
    this.connector.connectionParameters = null;
    this.emit('closed');
  }

  async getConnection(timeoutInMs: number = 5000): Promise<Connection> {
    if (this.connection) return this.connection;
    return new Promise((resolve, reject) => {
      let timedOut = false;
      const timeoutHandle = setTimeout(() => {
        timedOut = true;
        reject(new Error('Connection Attempt Timedout'));
      }, timeoutInMs);
      const gotConnection = (connection) => {
        if (!timedOut) {
          clearTimeout(timeoutHandle);
          resolve(connection);
          this.removeListener('connected', gotConnection);
        }
      };
      this.addListener('connected', gotConnection);
    });
  }

  // idempotent
  async setConnectionParameters(newValue: any) {
    if (this.closed || deepEqual(newValue, this.connector.connectionParameters))
      return;

    if (newValue) {
      this.DISCONNECTED();
      if (deepEqual(newValue, this.connector.connectionParameters)) return;

      this.connector.connectionParameters = newValue;
      this.CONNECTING();
    } else {
      this.connector.connectionParameters = null;
      this.DISCONNECTED();
    }
  }

  public abortScheduledTasks() {
    if (this.reconnectionTimeout) {
      clearTimeout(this.reconnectionTimeout);
      this.reconnectionTimeout = null;
    }
  }

  public triggerReconnectAttemptNow() {
    if (this.state != ConnectionState.PENDING_RECONNECT) return;
    this.abortScheduledTasks();
    this.CONNECTING();
  }

  private async onConnectionLost(connection: Connection) {
    if (connection != this.connection) return;

    if (this.state != ConnectionState.CONNECTED) {
      console.error(
        'onClose event fired on connection that was already closed',
        connection,
      );
      throw new Error(
        'onClose event fired on connection that was already closed',
      );
    }

    this.DISCONNECTED(); // TODO: Check if this causes the connection to not be reestablished
    this.CONNECTING();
  }

  private CONNECTING() {
    if (this.state == ConnectionState.CONNECTED) {
      throw new Error('Trying to transition from CONNECTED to CONNECTING');
    }
    if (this.state == ConnectionState.CONNECTING) return;
    this.abortScheduledTasks();
    this.state = ConnectionState.CONNECTING;

    const connectionPromise = this.connector.connect(
      this.connector.connectionParameters,
      this.onConnectionLost.bind(this),
    );
    this.connectionPromise = connectionPromise;
    this.numConnectionAttempts += 1;

    connectionPromise
      .then((connection) => {
        if (this.connectionPromise != connectionPromise) {
          console.warn(
            'Connection Attempt got aborted. Connection Parameters: ',
            this.connector.connectionParameters,
            'New Connection Promise',
            connectionPromise,
          );
          this.connector.disconnect(connection);
        } else {
          this.CONNECTED(connection);
        }
      })
      .catch((error) => {
        if (this.connectionPromise != connectionPromise) return;

        if (
          error &&
          error.message &&
          error.message.includes('Permission denied')
        ) {
          console.warn('Permission denied');
          this.DISCONNECTED();
        } else {
          console.error(
            `Connection Attempt ${this.numConnectionAttempts} Failed, Error: `,
            error,
            'Connection Parameters: ',
            this.connector.connectionParameters,
          );
          this.connectionPromise = null;
          this.PENDING_RECONNECT();
        }
      });
  }

  private CONNECTED(connection: Connection) {
    this.state = ConnectionState.CONNECTED;
    this.connection = connection;
    this.emit('connected', connection);
    if (this.resolveConnectionHandle) {
      // Resolve all waiting promises
      this.resolveConnectionHandle(connection);
    }
  }

  private PENDING_RECONNECT() {
    this.state = ConnectionState.PENDING_RECONNECT;
    const delay = this.connectionDelaysInMs[this.numConnectionAttempts];
    if (delay == null) {
      this.emit('automaticReconnectFailed');
      return;
    }

    if (delay == 0) {
      this.CONNECTING();
    } else {
      this.reconnectionTimeout = setTimeout(() => {
        this.reconnectionTimeout = null;
        this.CONNECTING();
      }, delay);
    }
  }

  private DISCONNECTED() {
    if (this.state == ConnectionState.DISCONNECTED) {
      return;
    }
    this.state = ConnectionState.DISCONNECTED;
    const connection = this.connection;
    if (connection) {
      this.connection = null;
      this.connector.disconnect(connection);
      this.emit('disconnected', connection);
    }
    this.numConnectionAttempts = 0;
    this.connectionPromise = null; // This aborts CONNECTING state
    this.abortScheduledTasks(); // This aborts PENDING_RECONNECT state
  }
}
