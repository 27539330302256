import { ModuleTypeMeta, FileId, URLString } from '../../common';

export interface ModuleAvatar {
  id?: FileId;
  url: URLString;
}

export const ModuleAvatarMeta: ModuleTypeMeta<ModuleAvatar> = {
  default: {
    id: 0,
    url: '',
  },
};
