





































































































































import { Access, Status, CreateCommunityRequest, shells } from '@fillip/api';
import { defineComponent, ref, onBeforeMount } from '@vue/composition-api';
import { useFillip, useRouter, useStore } from '@/composables';

export default defineComponent({
  name: 'ConmmunityEditor',
  setup() {
    const { router } = useRouter();
    const { fillip, $communitySlug, baseUrl } = useFillip();
    const { store } = useStore();

    const communitySlug = ref('');
    const community = ref({} as CreateCommunityRequest);

    const accessTypes = [Access.PRIVATE, Access.PUBLIC] as Access[];

    const statusTypes = [
      Status.ACTIVE,
      Status.INACTIVE,
      Status.DOCUMENTATION,
      Status.ENDED,
    ] as Status[];

    const languages = [
      {
        value: 'en',
        text: 'English',
      },
      {
        value: 'de',
        text: 'Deutsch',
      },
    ];

    const availableShells = Object.values(shells).map(({ name, title }) => ({
      value: name,
      text: title,
    }));

    const updateCommunity = async (setting) => {
      const updatedCommunitySetting = { [setting]: community.value[setting] };
      const updateResult = await fillip.communities.updateOne(
        updatedCommunitySetting,
        communitySlug.value,
      );

      if (router.currentRoute.name === 'Community') {
        store.commit('SET_ACTIVE_COMMUNITY', updateResult);
        if (setting == 'slug') {
          router.push({
            name: 'Community',
            params: {
              ...router.currentRoute.params,
              slug: updateResult.slug,
            },
            query: { ...router.currentRoute.query },
          });
        }
      }
    };

    onBeforeMount(async () => {
      communitySlug.value =
        router.currentRoute.name == 'CommunityHub'
          ? router.currentRoute.query.dialog.split('=')[1]
          : $communitySlug;

      community.value = (await fillip.communities.getOneBySlug(
        communitySlug.value,
      )) as CreateCommunityRequest;
    });

    return {
      baseUrl,
      community,
      communitySlug,
      accessTypes,
      statusTypes,
      languages,
      availableShells,
      updateCommunity,
    };
  },
});
