













import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import InputMedia from '@/components/input/media/InputMedia.vue';

export default {
  name: 'HdrTextureEditor',
  components: {
    InputMedia,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, data, save } = useSave(props, {
      path: 'model',
    });

    return {
      buffer,
      data,
      save,
    };
  },
  computed: {
    library() {
      return [
        { header: this.$t('model.hdrTexture.options.starsAndSky') },
        // TODO: Find file again
        // {
        //   text: this.$t('model.hdrTexture.options.nebula'),
        //   value: `${process.env.MINIO_URL}/media/hdr/nebula.jpg`,
        // },
        {
          text: this.$t('model.hdrTexture.options.satara'),
          value: `${process.env.MINIO_URL}/media/hdr/satara_night.jpg`,
        },
        { divider: true },
        { header: this.$t('model.hdrTexture.options.outdoors') },
        {
          text: this.$t('model.hdrTexture.options.greenField'),
          value: `${process.env.MINIO_URL}/media/hdr/lilienstein.jpg`,
        },
        {
          text: this.$t('model.hdrTexture.options.lakeview'),
          value: `${process.env.MINIO_URL}/media/hdr/cannon.jpg`,
        },
        {
          text: this.$t('model.hdrTexture.options.lakeside'),
          value: `${process.env.MINIO_URL}/media/hdr/lakeside.jpg`,
        },
        {
          text: this.$t('model.hdrTexture.options.veniceSunset'),
          value: `${process.env.MINIO_URL}/media/hdr/venice_sunset.jpg`,
        },
        {
          text: this.$t('model.hdrTexture.options.otherSunset'),
          value: `${process.env.MINIO_URL}/media/hdr/spruit_sunrise.jpg`,
        },

        { divider: true },
        { header: this.$t('model.hdrTexture.options.indoors') },
        {
          text: this.$t('model.hdrTexture.options.machineShop'),
          value: `${process.env.MINIO_URL}/media/hdr/machine_shop.jpg`,
        },
        {
          text: this.$t('model.hdrTexture.options.machineShop2'),
          value: `${process.env.MINIO_URL}/media/hdr/machine_shop_2.jpg`,
        },
        {
          text: this.$t('model.hdrTexture.options.studio'),
          value: `${process.env.MINIO_URL}/media/hdr/colorful_studio.jpg`,
        },
        {
          text: this.$t('model.hdrTexture.options.decorShop'),
          value: `${process.env.MINIO_URL}/media/hdr/decor_shop.jpg`,
        },
        {
          text: this.$t('model.hdrTexture.options.entranceHall'),
          value: `${process.env.MINIO_URL}/media/hdr/st_fagans_interior.jpg`,
        },
        {
          text: this.$t('model.hdrTexture.options.comfyCafe'),
          value: `${process.env.MINIO_URL}/media/hdr/comfy_cafe.jpg`,
        },
        {
          text: this.$t('model.hdrTexture.options.tvStudio'),
          value: `${process.env.MINIO_URL}/media/hdr/tv_studio.jpg`,
        },
        {
          text: this.$t('model.hdrTexture.options.oldSchoolTheater'),
          value: `${process.env.MINIO_URL}/media/hdr/old_school_theater.jpg`,
        },
      ];
    },
  },
  methods: {
    changeMediaSource(newBuffer) {
      this.buffer = newBuffer;
      this.save();
    },
  },
};
