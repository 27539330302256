











import { computed, defineComponent } from '@vue/composition-api';
import AuthMethodLogin from './AuthMethodLogin.vue';
import AuthMethodSignup from './AuthMethodSignup.vue';
import AuthMethodAnonymous from './AuthMethodAnonymous.vue';
import AuthMethodExternal from './AuthMethodExternal.vue';

export default defineComponent({
  name: 'AuthMethod',
  props: {
    method: {
      type: String,
      default: '',
    },
    provider: {
      type: String,
      default: '',
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const authMethods = {
      login: AuthMethodLogin,
      signup: AuthMethodSignup,
      anonymous: AuthMethodAnonymous,
      external: AuthMethodExternal,
    } as const;

    const activeMethod = computed(() => authMethods[props.method]);

    return {
      activeMethod,
    };
  },
});
