



















const QuillWrapper = function (resolve) {
  require(/* webpackChunkName: "styledtext" */ [
    './../../editors/styled-text/QuillWrapper.vue',
  ], resolve);
};

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'InputStyledText',
  components: {
    QuillWrapper,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
    editingOptions: {
      type: String,
      default: 'advanced',
    },
    bounds: {
      type: [String, Element],
      default: '.quill-wrapper',
    },
    targetFormat: {
      type: String,
      default: 'html',
    },
  },
  setup(props, { emit }) {
    const handleEvent = (event: string, data: any) => {
      emit(event, data);
    };

    return { handleEvent };
  },
});
