import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagBaseContent: DataDocument = {
  id: 'baseContent',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Base Content',
    icon: 'question',
    isProtected: true,
  },
  tag: {
    tag: 'template',
  },
  interfaceDefinition: {
    title: '',
    props: [
      {
        title: 'Text',
        key: 'text',
        type: 'string',
        defaultValue:
          ':$text || info?.content || properties?.text?.value || properties?.title?.value || info.title',
      },
      {
        title: 'Variante',
        key: 'variant',
        type: 'string',
        defaultValue: ":$variant || properties?.variant?.value || 'default'",
      },
    ],
  },
};
