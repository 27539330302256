import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagIcon: DataDocument = {
  id: 'tag-icon',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Icon',
    icon: 'icons',
    isProtected: true,
  },
  element: {
    type: 'element.icon',
    icon: ':$icon',
    size: ':$size',
    color: ':$color',
  },
  class: {
    class: ':$containerClasses',
    elementClass: ':$elementClasses',
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'icon',
    title: 'Icon',
    category: 'content',
    icon: 'icons',
    editor: 'default',
  },
  interfaceDefinition: {
    title: '',
    props: [
      {
        title: 'Icon',
        key: 'icon',
        type: 'icon',
        defaultValue:
          ":$icon || properties?.icon?.value || info.icon || 'heart'",
      },
      {
        title: 'Color',
        key: 'color',
        type: 'color',
        defaultValue: ':$color || properties?.color?.value || rgba(1,1,1,0.54)',
      },
      {
        title: 'Size',
        key: 'size',
        type: 'select',
        options: {
          items: [
            {
              key: 'small',
              title: 'Small',
            },
            {
              key: 'medium',
              title: 'Medium',
            },
            {
              key: 'large',
              title: 'Large',
            },
            {
              key: 'xl',
              title: 'Xl',
            },
          ],
        },
        defaultValue: ":$size || properties?.size?.value || 'large'",
      },
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":$containerClasses || properties?.containerClasses?.value || ''",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":$elementClasses || properties?.elementClasses?.value || ''",
      },
    ],
  },
};
