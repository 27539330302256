
























import AuthContainer from './components/AuthContainer.vue';
import AuthMethod from './components/AuthMethod.vue';
import AuthTerms from './components/AuthTerms.vue';

import { defineComponent } from '@vue/composition-api';
import { useShell } from '@/composables';

export default defineComponent({
  name: 'Signup',
  components: {
    AuthContainer,
    AuthMethod,
    AuthTerms,
  },
  setup() {
    const { shell } = useShell();

    return {
      shell,
    };
  },
});
