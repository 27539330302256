import { deepEqual } from 'fast-equals';
import Vue from 'vue';
import { GlobalPropsNode } from '@/plugins/global-props';
import {
  Id,
  ModuleVideoConferencingMeta,
  EvaluatedModuleVideoConferencing,
} from '@fillip/api';
import { usePermission } from '@vueuse/core';

export default Vue.extend({
  // Converted in branch remerge-vc-refactor
  name: 'VideoConferencingManager',
  mixins: [GlobalPropsNode],
  inject: ['router'],
  // setup() {
  //   const micPermission = usePermission('microphone');
  //   const camPermission = usePermission('camera');
  //   return {
  //     micPermission,
  //     camPermission,
  //   };
  // },
  computed: {
    videoConferenceAndParticipantSettings() {
      const settings: EvaluatedModuleVideoConferencing = this.settings;

      const participantSettings = this.participantSettings;
      return { settings, participantSettings };
    },
    settings() {
      return (
        this.$getGlobalProp('videoConferencing') || {
          camera: ModuleVideoConferencingMeta.default.camera,
          microphone: ModuleVideoConferencingMeta.default.microphone,
          screen: ModuleVideoConferencingMeta.default.screen,
        }
      );
    },
    participantSettings() {
      return this.$me.participantVc;
    },
    isMuted() {
      return Boolean(!this.$me.participantVc?.microphone);
    },
    isCameraOn() {
      return this.$me.participantVc?.camera;
    },
    isLowCameraResolution() {
      return Boolean(
        this.$store.state.videoConference.streams?.camera?.maxResolutionLayer ==
          1,
      );
    },
    isConferenceAudioMuted() {
      if (!this.$store.state.videoConference) return;
      return this.$store.state.videoConference.audioMuted;
    },
    route() {
      return this.router.value?.focusedStation?.id;
    },
  },
  watch: {
    route: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue == oldValue) return;
        this.setScreenshareState(false);
        if (this.isConferenceAudioMuted) {
          this.setConferenceAudioState(false);
        }
      },
    },

    videoConferenceAndParticipantSettings: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        const streams = {};
        if (!newValue) return streams;
        if (deepEqual(newValue, oldValue)) return;

        ['camera', 'microphone', 'screen'].forEach((stream) => {
          if (
            newValue.settings[stream].state == 'OFF' &&
            oldValue?.settings[stream].state == 'ON'
          ) {
            streams[stream] = false;
            this.setState(stream, false);
          } else if (
            stream == 'screen' &&
            ((newValue.settings[stream].state == 'ON' &&
              oldValue?.settings[stream].state == 'OFF') ||
              !newValue.participantSettings[stream])
          ) {
            streams[stream] = false;
            this.setState(stream, false);
            // CHECK @ISTA
            // } else if (
            //   stream == 'microphone' &&
            //   newValue.settings[stream].state == 'ON'
            // ) {
            //   streams[stream] = {
            //     channel: newValue.settings[stream].channel,
            //     roomSize: parseInt(newValue.settings[stream].roomSize) || 0.2,
            //     paused: !newValue.participantSettings[stream],
            //     settings: newValue.settings[stream].settings,
            //   };
          } else if (
            newValue.settings[stream].state == 'ON' &&
            newValue.participantSettings[stream]
          ) {
            streams[stream] = {
              channel: newValue.settings[stream].channel,
              roomSize: parseInt(newValue.settings[stream].roomSize) || 0.2,
              settings: newValue.settings[stream].settings,
            };
          } else {
            streams[stream] = false;
          }
        });
        this.$store.commit('client/SET_REQUESTED_PRODUCERS', {
          producers: streams,
          communitySlug: this.$communitySlug,
        });
      },
    },
  },
  methods: {
    // Microphone
    async toggleMicrophone(participant = this.$me) {
      // if (participant == this.$me && this.micPermission == 'denied')
      //   this.setVc(participant.id, {
      //     microphone: false,
      //   });
      // else
      this.setVc(participant.id, {
        microphone: !participant.participantVc.microphone,
      });
    },
    setMicrophoneState(shouldBeActive, participant = this.$me) {
      // if (participant == this.$me && this.micPermission == 'denied')
      //   shouldBeActive = false;
      this.setState('microphone', shouldBeActive, participant);
    },

    // Camera
    async toggleCamera(participant = this.$me) {
      // TODO: if(!this.$me) --> ask participant for permission
      // if (participant == this.$me && this.camPermission == 'denied')
      //   this.setVc(participant.id, {
      //     camera: false,
      //   });
      // else
      this.setVc(participant.id, {
        camera: !participant.participantVc.camera,
      });
    },
    setCameraState(shouldBeActive, participant = this.$me) {
      // if (participant == this.$me && this.camPermission == 'denied')
      //   shouldBeActive = false;
      this.setState('camera', shouldBeActive, participant);
    },
    toggleVideoResolution() {
      this.isLowVideoResolution
        ? this.$store.commit('videoConference/SET_RESOLUTION_LAYER', 2)
        : this.$store.commit('videoConference/SET_RESOLUTION_LAYER', 1);
    },

    // Conference Audio
    toggleConferenceAudio() {
      this.$store.commit(
        'videoConference/MUTE_VIDEOCONFERENCE',
        !this.isConferenceAudioMuted,
      );
    },
    setConferenceAudioState(shouldBeActive) {
      this.$store.commit(
        'videoConference/MUTE_VIDEOCONFERENCE',
        !shouldBeActive,
      );
    },
    // Screenshare
    toggleScreenshare(participant = this.$me) {
      return this.setVc(participant.id, {
        screen: !participant.participantVc.screen,
      });
    },
    setScreenshareState(shouldBeActive, participant = this.$me) {
      return this.setState('screen', shouldBeActive, participant);
    },
    // Helpers
    setState(name: string, shouldBeActive: boolean, participant = this.$me) {
      if (participant.participantVc[name] == shouldBeActive) {
        return;
      }
      this.setVc(participant.id, { [name]: shouldBeActive });
    },
    async setVc(participantId: Id, stream: Record<string, boolean>) {
      return this.$invoke('participantSetVc', participantId, stream);
    },
  },
  render() {
    return null;
  },
});
