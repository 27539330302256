import VueRouter from 'vue-router';
import Vue from 'vue';
import vuetify from '../plugins/vuetify';
import { routes } from '../router/index';

export type LayoutRouterPlugin = typeof LayoutRouter;
export type NavigatePlugin = typeof Navigate;
type LayoutRouterSettings = {
  syncInUrlQuery?: boolean;
};
type LayoutRouterDefinition = {
  name: string;
  settings?: LayoutRouterSettings;
};

class LayoutRouter extends VueRouter {
  area: string;
  globalRouter: any;
  settings: LayoutRouterSettings;

  constructor(
    name: string,
    routes: any[],
    globalRouter: any,
    settings: LayoutRouterSettings = { syncInUrlQuery: false },
  ) {
    super({
      mode: 'abstract',
      routes,
    });

    this.area = name;
    this.globalRouter = globalRouter;
    this.settings = settings;

    super.beforeEach((to, from, next) => {
      // Avoid redirection to communities in abstract routers
      if (to.redirectedFrom == '/' && to.path === '/communities') {
        next(false);
      } else {
        next();
      }
    });
    if (settings.syncInUrlQuery) {
      // If a query param for this layout router is detected in a route, mirror the path to the layout router
      // Currently used for populating layout areas on page load
      globalRouter.afterEach((to, from) => {
        const mixinName = `$${name}`;
        const toQuery = to.query?.[name];
        const fromQuery = from.query[name];
        const currentLayoutRoute = Vue.prototype[mixinName].route.fullPath;
        if (toQuery && toQuery != fromQuery && toQuery != currentLayoutRoute) {
          Vue.prototype[mixinName].push(toQuery).catch((error) => {
            if (error.name != 'NavigationDuplicated') {
              throw error;
            }
          });
        }
      });

      super.afterEach((to, from) => {
        const routerQuery = globalRouter.history.current.query;
        if (!to.fullPath || to.fullPath == '/') {
          // Remove query param from global router if layout area is closed

          const query = Object.assign({}, routerQuery);
          delete query[name];

          return globalRouter.replace({ query });
        }
        if (!routerQuery[name] || routerQuery[name] != to.fullPath) {
          // Keep query param from global router in sync if layout area is open
          // But only if it changed

          const query = Object.assign({}, routerQuery, {
            [name]: to.fullPath,
          });

          return globalRouter.replace({ query });
        }
      });
    }
  }

  async open(to) {
    return this.push(to);
  }

  toggle(to) {
    if (to.name == this.route.name) {
      return this.close();
    } else {
      return this.push(to);
    }
  }

  close() {
    if (this.route.path != '/') {
      return this.push('/');
    }
  }

  expand() {
    Vue.prototype.$navigate.push(this.currentRoute, 'main');
  }

  detach() {
    // Call the current route in the detachedLayoutArea after it is implemented
    console.error('Not implemented yet');
  }

  async navigate(location) {
    const toResolved = super.resolve(location);
    if (toResolved.resolved.fullPath === super.currentRoute.fullPath) {
      // Close area when navigation command is toggled
      return;
    }

    return this.push(toResolved.location).catch((error) => {
      if (error.name != 'NavigationDuplicated') {
        throw error;
      }
    });
  }

  get route() {
    return {
      ...this.currentRoute,
      area: this.area,
      $$$isLayoutArea: true,
    };
  }

  get isOpen() {
    return this.route.path !== '/';
  }
}

function createRouter(
  name: string,
  router: any,
  settings: LayoutRouterSettings,
) {
  return new LayoutRouter(name, routes, router, settings);
}

class Navigate {
  globalRouter: any;
  areas: Record<string, any>;

  constructor(globalRouter, layoutRouters: LayoutRouterDefinition[]) {
    this.globalRouter = globalRouter;

    this.areas = {};

    layoutRouters.forEach(({ name, settings }) => {
      this.areas[name] = createRouter(name, globalRouter, settings);
    });
  }

  async push(to, target) {
    const targetResolved = target || this.defaultRouteTarget(to);
    const targetRouter = Vue.prototype[`$${targetResolved}`];
    if (!targetRouter) {
      console.error(
        'Router not found. Did you spell the router correctly?',
        target,
        targetResolved,
      );
      return false;
    }
    const toResolved = targetRouter.resolve(to);
    if (toResolved.resolved.fullPath === targetRouter.currentRoute.fullPath) {
      // Close area when navigation command is toggled
      to = '/';
    }
    return await targetRouter.push(to).catch((error) => {
      if (error.name != 'NavigationDuplicated') {
        throw error;
      }
    });
  }

  go(delta, target) {
    if (!target) return false;
    return Vue.prototype[`$${target}`].go(delta);
  }

  getLayoutRoute(component) {
    const lookupComponent = component || this;
    if (lookupComponent.$route?.$$$isLayoutArea) {
      return lookupComponent.$route;
    }
    return this.getLayoutRoute(lookupComponent.$parent);
  }

  defaultRouteTarget(to) {
    const toResolved = this.globalRouter.resolve(to);
    if (toResolved?.meta?.preferredTarget) {
      return toResolved.meta.preferredTarget;
    }
    // TODO: This is a basic implementation for breakpoint specific routing logic.
    // Making preferredTarget an Object with breakpoint definitions,
    // we could also define preferred targets by route and viewport
    return vuetify.framework.breakpoint.smAndDown ? 'dialog' : 'drawer';
  }

  resolve(to) {
    const resolved = this.globalRouter.resolve(to);
    return resolved;
  }
}

const layoutMixin = {
  inject: {
    layout: {
      default: () => {
        return {
          route: null,
          area: 'global',
        };
      },
    },
  },
  methods: {
    navigate(to) {
      // Navigate to the specified to object in the current layout area
      return this.$navigate.push(to, this.layout.area).catch((error) => {
        if (error.name != 'NavigationDuplicated') {
          throw error;
        }
      });
    },
    go(steps = -1) {
      // Navigate in the current layout area's history
      return this.$navigate.go(steps, this.layout.area);
    },
  },
};

export const layoutRouters: LayoutRouterDefinition[] = [
  { name: 'dialog', settings: { syncInUrlQuery: true } },
  { name: 'drawer' },
  { name: 'navdrawer' },
  { name: 'window' },
];

export default {
  install(Vue, router, config) {
    const navigate = new Navigate(router, layoutRouters);
    Vue.mixin(layoutMixin);
    Vue.prototype.$dialog = Vue.observable(navigate.areas.dialog);
    Vue.prototype.$drawer = Vue.observable(navigate.areas.drawer);
    Vue.prototype.$navdrawer = Vue.observable(navigate.areas.navdrawer);
    Vue.prototype.$window = Vue.observable(navigate.areas.window);
    Vue.prototype.$navigate = navigate;
  },
};
