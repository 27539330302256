
















































































import {
  useSave,
  useData,
  useVolatileData,
  useTags,
  useDocuments,
  useI18n,
} from '@/composables';
import BlockEditor from '@/components/block-editor/BlockEditor.vue';
import EditorContainer from '@/features/editor/components/EditorContainer.vue';
import draggable from 'vuedraggable';
import { defineComponent, computed, nextTick, ref } from '@vue/composition-api';
import { ActionControl } from '@fillip/api';

export default defineComponent({
  name: 'ListEditorLarge',
  components: {
    BlockEditor,
    EditorContainer,
    draggable,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    allowedTags: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    allowMultipleChildren: {
      type: Boolean,
      default: true,
    },
    mountExpanded: {
      type: Boolean,
      default: false,
    },
    alwaysExpanded: {
      type: Boolean,
      default: false,
    },
    alwaysActive: {
      type: Boolean,
      default: false,
    },
    forbidEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const {
      getData,
      pushData,
      insertData,
      removeData,
      duplicateDataFromId,
      clearChildren,
    } = useData();
    const { getAllowedChildTags } = useTags();
    const { tr } = useI18n();

    const blockEditor = ref(null);

    const { buffer, save } = useSave(props, {
      removeIfNull: true,
      path: 'list',
      defaultValue: { items: [] },
    });
    const { clearVolatile, getVolatileProp, setVolatileProp } =
      useVolatileData();
    const { editInDialog } = useDocuments();

    const dragOptions = {
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
    };

    const doc = getData(props.id);
    const tags = computed(() =>
      getAllowedChildTags(doc.tag.tag, props.allowedTags),
    );

    const focusEntry = (id: string) => {
      const editor = blockEditor.value.find((e) => e.id == id);
      const elFocus =
        editor.$refs?.isComponent?.$refs?.editorInstance?.$refs?.inputField
          ?.focus;
      if (elFocus && typeof elFocus == 'function') {
        elFocus();
      }
    };

    const addEntry = async (id: string, index: number, event: any) => {
      const tag = nextTag(event.tag);
      const nextIndex = index + 1;
      const newItem = await insertData(id, nextIndex, tag);
      await nextTick();
      focusEntry(newItem.id);
    };

    const addOnClick = async (id: string, tag: string) => {
      const newItem = await pushData(id, tag);
      focusEntry(newItem.id);
    };

    const onTagChange = async (id: string) => {
      setTimeout(() => {
        focusEntry(id);
      }, 500);
    };

    const nextTag = (lastTag: string) => {
      if (lastTag == 'page') {
        return 'page';
      } else if (lastTag == 'collection') {
        return 'collection';
      } else if (lastTag == 'document') {
        return 'document';
      } else {
        return 'text';
      }
    };

    const removeEntry = (id: string, event: any, indexToRemove: number) => {
      if (event.target.value != '') return;
      const focusId =
        indexToRemove < 1
          ? buffer.value[indexToRemove + 1]?.id
          : buffer.value[indexToRemove - 1].id;
      removeData(id);
      if (focusId) {
        focusEntry(focusId);
      }
    };

    const listActions = computed<ActionControl[]>(() => {
      return [
        {
          type: 'action',
          title: tr('explorer.clearChildren'),
          action: (data) => clearChildren(data.id),
          icon: 'trash',
          color: 'red',
          disabled: (data) => !data.list?.items?.length,
        },
      ];
    });

    const itemActions = computed<ActionControl[]>(() => {
      return [
        {
          type: 'action',
          icon: 'expand-alt',
          title: tr('explorer.open'),
          action: (item) => editInDialog(item.id),
          if: !props.forbidEditing,
        },
        {
          type: 'action',
          title: tr('explorer.duplicate'),
          action: (data) => duplicateDataFromId(data.id),
          icon: 'clone',
        },
        {
          type: 'action',
          icon: 'trash',
          title: tr('explorer.remove'),
          action: (item) => removeData(item.id),
          color: 'error',
        },
      ];
    });

    return {
      pushData,
      insertData,
      removeData,
      buffer,
      save,
      doc,
      clearVolatile,
      getVolatileProp,
      setVolatileProp,
      dragOptions,
      tags,
      editInDialog,
      addEntry,
      addOnClick,
      onTagChange,
      removeEntry,
      nextTag,
      blockEditor,
      listActions,
      itemActions,
    };
  },
});
