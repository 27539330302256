import { URLString, TypeMeta, ModuleTypeMeta } from '../../common';
import { ControlType } from './shared';

export interface MediaSettings {
  controlledBy: ControlType;
  startedAt: number;
  pausedAt: number;
  loop: boolean;
  volume: number;
  startEvent: 'manual' | 'focus' | 'load';
  controlPanel: boolean;
  title: string;
  description: string;
  copyright: string;
  transcript?: string;
}

type ModelTypeKeys =
  | 'model.loader'
  | 'model.marker'
  | 'model.video360'
  | 'model.colorfulSky'
  | 'model.hdrTexture'
  | 'model.positionalAudio';
interface ModelType<Key extends ModelTypeKeys> {
  type: Key;
}

interface ModelTypeMeta<T extends ModelType<ModelTypeKeys>>
  extends TypeMeta<T> {
  type: T['type'];
  icon: string;
  name?: string;
}

export interface ModelLoader extends ModelType<'model.loader'> {
  loaderSrc: URLString;
  loaderAnimate: boolean;
}

export const ModelLoaderMeta: ModelTypeMeta<ModelLoader> = {
  type: 'model.loader',
  icon: 'cubes',
  default: {
    type: 'model.loader',
    loaderSrc: '',
    loaderAnimate: false,
  },
};

export interface ModelMarker extends ModelType<'model.marker'> {
  markerContent: string;
}

export const ModelMarkerMeta: ModelTypeMeta<ModelMarker> = {
  type: 'model.marker',
  icon: 'map-marker-alt',
  default: {
    type: 'model.marker',
    markerContent: '',
  },
};
export interface ModelVideo360 extends ModelType<'model.video360'> {
  video360Src: URLString;
  video360Settings: MediaSettings;
}

export const ModelVideo360Meta: ModelTypeMeta<ModelVideo360> = {
  type: 'model.video360',
  icon: 'film-alt',
  default: {
    type: 'model.video360',
    video360Src: '',
    video360Settings: {
      controlledBy: 'host',
      startedAt: null,
      pausedAt: null,
      loop: false,
      volume: 0.5,
      startEvent: 'manual',
      controlPanel: false,
      title: '',
      description: '',
      copyright: '',
    },
  },
};

export interface ModelColorfulSky extends ModelType<'model.colorfulSky'> {
  colorfulSkySettings: {
    animate: boolean;
    azimuth: number;
    elevation: number;
  };
}

export const ModelColorfulSkyMeta: ModelTypeMeta<ModelColorfulSky> = {
  type: 'model.colorfulSky',
  icon: 'sun-haze',
  default: {
    type: 'model.colorfulSky',
    colorfulSkySettings: {
      animate: false,
      azimuth: -15,
      elevation: 1,
    },
  },
};

export interface ModelHdrTexture extends ModelType<'model.hdrTexture'> {
  hdrTextureSrc: URLString;
}

export const ModelHdrTextureMeta: ModelTypeMeta<ModelHdrTexture> = {
  type: 'model.hdrTexture',
  icon: 'braille',
  default: {
    type: 'model.hdrTexture',
    hdrTextureSrc: '',
  },
};

export interface ModelPositionalAudio
  extends ModelType<'model.positionalAudio'> {
  positionalAudioSrc: URLString;
  positionalAudioSettings: MediaSettings;
}

export const ModelPositionalAudioMeta: ModelTypeMeta<ModelPositionalAudio> = {
  type: 'model.positionalAudio',
  icon: 'music',
  default: {
    type: 'model.positionalAudio',
    positionalAudioSrc: '',
    positionalAudioSettings: {
      controlledBy: 'host',
      startedAt: null,
      pausedAt: null,
      loop: false,
      volume: 0.5,
      startEvent: 'manual',
      controlPanel: false,
      title: '',
      description: '',
      copyright: '',
    },
  },
};

export type ModuleModel =
  | ModelLoader
  | ModelMarker
  | ModelVideo360
  | ModelColorfulSky
  | ModelHdrTexture
  | ModelPositionalAudio;

export interface ModuleModelTypeMeta extends ModuleTypeMeta<ModuleModel> {
  types: {
    [key in ModelTypeKeys]: ModelTypeMeta<ModelType<key>>;
  };
}

export const ModuleModelMeta: ModuleModelTypeMeta = {
  types: {
    'model.loader': ModelLoaderMeta,
    'model.marker': ModelMarkerMeta,
    'model.video360': ModelVideo360Meta,
    'model.colorfulSky': ModelColorfulSkyMeta,
    'model.hdrTexture': ModelHdrTextureMeta,
    'model.positionalAudio': ModelPositionalAudioMeta,
  },
  default: ModelLoaderMeta.default,
  icon: 'cubes',
};
