import { GlobalPropsNode } from '@/plugins/global-props';

export const BaseComponent = {
  mixins: [GlobalPropsNode],
  inject: ['viewport'],
  props: {
    id: {
      type: String,
      // required: true,
    },
    parentId: {
      type: String,
      // required: false,
    },
    modules: {
      type: Object,
      default: () => ({}),
    },
  },
};
