






















import { useVolatileData } from '@/composables';
import {
  defineComponent,
  computed,
  watch,
  onBeforeUnmount,
  onMounted,
} from '@vue/composition-api';

export default defineComponent({
  name: 'BaseActionDocumentManager',
  props: {
    name: {
      type: String,
      default: function () {
        return this.$t('action.documentManager.defaultTitle');
      },
    },
    canvasSlot: {
      type: String,
      default: 'bottom',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'x-large',
    },
    id: {
      type: String,
      required: true,
    },
    vPropId: {
      type: String,
      required: true,
    },
    areaMeta: {
      type: Object,
      required: true,
    },
  },
  setup(props: any) {
    const {
      getVolatileProp,
      setVolatileProp,
      removeVolatileProp,
      clearVolatile,
      renameVolatile,
      setVolatile,
    } = useVolatileData();

    const vMaximizedId = computed(() => {
      return props.areaMeta.all.filter((id) => {
        return getVolatileProp(id, props.areaMeta.vMinimized, false) == false;
      });
    });

    const minimizeIcon = computed(() => {
      const isMinimized = isTargetMinimized(props.vPropId);
      if (props.canvasSlot == 'bottom') {
        return isMinimized ? 'chevron-circle-up' : 'chevron-circle-down';
      } else if (props.canvasSlot == 'top') {
        return isMinimized ? 'chevron-circle-down' : 'chevron-circle-up';
      } else if (props.canvasSlot == 'left') {
        return isMinimized ? 'chevron-circle-right' : 'chevron-circle-left';
      } else {
        return isMinimized ? 'chevron-circle-left' : 'chevron-circle-right';
      }
    });

    const computedIcon = computed(() => props.icon || minimizeIcon.value);

    const isTargetMinimized = (targetId: string) => {
      return getVolatileProp(targetId, props.areaMeta.vMinimized, true);
    };

    const vMinimize = (targetId: string) => {
      props.areaMeta.all.forEach((id) => {
        if (id === targetId) return;
        setVolatileProp(id, props.areaMeta.vMinimized, true);
      });

      setVolatileProp(
        targetId,
        props.areaMeta.vMinimized,
        !isTargetMinimized(targetId),
      );
    };

    onMounted(async () => {
      await setVolatile(props.vPropId, {});
      await setVolatileProp(props.vPropId, props.areaMeta.vMinimized, true);

      if (
        vMaximizedId.value.length < 1 &&
        props.vPropId == props.areaMeta.lastAdded
      ) {
        setVolatileProp(props.vPropId, props.areaMeta.vMinimized, false);
      } else {
        vMinimize(props.areaMeta.lastAdded);
      }
    });

    onBeforeUnmount(() => {
      clearVolatile(props.vPropId);
    });

    watch(
      () => props.vPropId,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          renameVolatile(newValue, oldValue);
        }
      },
    );

    return {
      getVolatileProp,
      setVolatileProp,
      removeVolatileProp,
      clearVolatile,
      renameVolatile,
      setVolatile,
      vMaximizedId,
      minimizeIcon,
      computedIcon,
      isTargetMinimized,
      vMinimize,
    };
  },
});
