































import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'TabsLayout',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object as PropType<{ vertical: boolean }>,
      default: () => ({}),
    },
    currentChild: {
      type: Object,
      default: () => ({}),
    },
    childProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const defaultOptions = {
      vertical: false,
    };

    const currentOptions = computed(() => ({
      ...defaultOptions,
      ...props.options,
    }));

    const isVertical = computed(() => currentOptions.value.vertical);

    const currentTab = computed({
      set(tab) {
        emit('change', tab);
      },
      get() {
        return props.currentChild ? props.currentChild.name : '';
      },
    });
    return {
      currentOptions,
      isVertical,
      currentTab,
    };
  },
});
