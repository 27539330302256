












































import Vue from 'vue';
import { useMediaControls, useSave, useVolatileData } from '@/composables';
import MediaControls from '@/features/editor/components/MediaControls.vue';

export default Vue.extend({
  name: 'ActionMediaControls',
  components: {
    MediaControls,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    mediaType: {
      type: String,
      required: true,
    },
    vPropId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canvasSlot: {
      type: String,
      default: 'bottom',
    },
    name: {
      type: String,
      default: function () {
        return this.$t('action.mediaControls.title');
      },
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    size: {
      type: String,
    },
    vMinimized: {
      type: String,
    },
  },
  setup(props: any) {
    const { buffer } = useSave(props, {
      path: 'model',
    });
    const { getVolatileProp } = useVolatileData();
    const { title, description, copyright } = useMediaControls(props, {
      mediaType: props.mediaType,
    });

    return {
      buffer,
      getVolatileProp,
      title,
      description,
      copyright,
    };
  },
  data() {
    return {
      showInfo: false,
    };
  },
  computed: {
    //TODO make outsource volatileProp creation
    isMinimized() {
      return this.getVolatileProp(this.vPropId, this.vMinimized, true);
    },
  },
  methods: {
    toggleInfo() {
      (this as any).showInfo = !(this as any).showInfo;
    },
  },
});
