import { DataDocument, toolsTemplateDirectoryId } from '@fillip/api';

// TODO: Add option to activate / deactivate sheet
export const TemplateToolContent: DataDocument = {
  id: 'tool-content',
  info: {
    title: 'Tool: Content',
    icon: 'file-alt',
  },
  tag: {
    tag: 'template',
  },
  parentId: toolsTemplateDirectoryId,
  children: {
    default: [
      {
        templateId: 'tool-content-container',
        query: ':[data]',
        identity: '',
      },
    ],
  },
  class: {
    elementClass: '',
    class: 'p-6 w-full grid grid-cols-1 place-content-center',
  },
  list: {
    items: [
      {
        id: 'tool-content-container',
      },
    ],
  },
  sheet: {
    scriptedValue: false,
    orientTowardsCamera: false,
    value: ':true',
  },
};

export const TemplateToolContentChildren: DataDocument[] = [
  {
    id: 'tool-content-container',
    info: {
      title: 'Tool: Content Container',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-content',
    class: {
      class:
        ":`p-6 w-full flex flex-col overflow-y-auto ${$$containerClasses} ${$$horizontalCentered == true ? 'items-center text-center' : '' } ${$$verticalCentered == true ? 'justify-center' : '' }`",
      elementClass: '',
    },
    children: {
      default: [
        {
          templateId: null,
          query: ':hasList(id) ? getList(id) : get(id)',
          condition: '',
          identity: '',
        },
      ],
    },
  },
];
