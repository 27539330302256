var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"m-4 v-text-field--minimal clearable",attrs:{"placeholder":_vm.$t('general.search'),"flat":"","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('BaseIcon',{attrs:{"icon":"search","size":"small"}})]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-treeview',{ref:"tree",attrs:{"items":_vm.items,"dense":"","activatable":"","hoverable":"","active":_vm.activeNodes,"open":_vm.openNodes,"search":_vm.search,"filter":_vm.filter},on:{"update:active":_vm.updateTreeSelection,"update:open":_vm.updateOpenNodes},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var data = ref.item;
return [(data)?[_c('BaseIcon',{staticClass:"mr-2",attrs:{"icon":_vm.getDataIcon(data),"fixed-width":"","size":"medium"},on:{"click":function($event){return _vm.editInDialog(data.id)}}})]:_vm._e()]}},{key:"label",fn:function(ref){
var data = ref.item;
return [(data)?[(data.id == _vm.dataTitleEditing)?_c('v-text-field',{ref:"titleTextField",staticClass:"v-text-field--minimal",attrs:{"dense":"","value":data.info.title,"autofocus":""},on:{"blur":function($event){return _vm.handleTitleBlurEvent($event, data)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleTitleBlurEvent($event, data)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dataTitleEditing = null}],"focus":function($event){return $event.target.select()}}}):_c('div',{staticClass:"flex align-items"},[_c('div',{on:{"click":function($event){return _vm.onClickDataTitle(data)}}},[_vm._v(" "+_vm._s(data.info.title || _vm.$t('general.untitled'))+" ")]),_c('div',{staticClass:"flex-grow-1",on:{"dblclick":function($event){return _vm.open(data.id)}}})])]:_vm._e()]}},{key:"append",fn:function(ref){
var data = ref.item;
var active = ref.active;
return [_c('div',{staticClass:"flex items-center gap-1"},[(
            data && data.list && data.list.items && data.list.items.length
          )?_c('v-chip',{staticClass:"w-6 p-0 flex justify-center inline-flex",attrs:{"pill":"","x-small":""}},[_vm._v(" "+_vm._s(data.list.items.length)+" ")]):_vm._e(),(active)?_c('v-menu',{attrs:{"absolute":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('BaseButton',_vm._g({attrs:{"icon-only":"","icon":"plus","small":""}},on))]}}],null,true)},[_c('PresetSelector',{attrs:{"category":"all","parent-id":data.id},on:{"added":_vm.activate}})],1):_vm._e(),(active)?_c('BaseMenu',{attrs:{"actions":_vm.itemActions,"small":"","dense":"","item-argument":data}}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }