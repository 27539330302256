




































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  watch,
} from '@vue/composition-api';
import DOMPurify from 'dompurify';
import { sleep } from '@fillip/api';
import { useScene, useVuetify } from '@/composables';

export default defineComponent({
  name: 'ElementStyledText',
  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      default: '',
    },
    modules: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const vuetify = useVuetify();
    const { globalDisplayMode } = useScene();

    const annotationNodes = ref([]);
    const selectedAnnotation = ref(null);

    const isDesktop = computed(() => {
      if (globalDisplayMode.value === 'print') {
        return true;
      }
      return vuetify.breakpoint.mdAndUp;
    });

    const el = ref(null);
    const element = computed(() => el.value);

    const content = computed(() => {
      return DOMPurify.sanitize(props.modules.element.htmlContent || '');
    });

    const annotations = computed(() => {
      return annotationNodes.value.map((annotation: HTMLSpanElement) => {
        return {
          id: annotation.dataset.id,
          comment: annotation.dataset.comment,
          top: annotation.offsetTop,
          left: annotation.offsetLeft,
          // node: annotation,
        };
      });
    });

    const findAnnotation = (id) => {
      return annotations.value.find((a) => a.id == id);
    };

    const hideAnnotation = () => {
      selectedAnnotation.value = null;
    };

    const showAnnotation = ($event) => {
      // Annotations are only clickable on mobile
      if (isDesktop.value) return;

      const id = $event.target.dataset.id;
      selectedAnnotation.value = findAnnotation(id);
    };

    watch(
      content,
      async (newValue) => {
        if (newValue) {
          await sleep(500);
          annotationNodes.value.forEach((annotation) => {
            annotation.removeEventListener('click', showAnnotation);
          });
          annotationNodes.value.splice(0);
          Array.from(
            element.value.getElementsByClassName('annotation') || [],
          ).forEach((annotation: HTMLSpanElement) => {
            annotation.addEventListener('click', showAnnotation);
            annotationNodes.value.push(annotation);
          });
        }
      },
      { immediate: true },
    );

    onBeforeUnmount(() => {
      annotationNodes.value.forEach((node) => {
        node.removeEventListener('click', showAnnotation);
      });
    });

    return {
      annotationNodes,
      selectedAnnotation,
      isDesktop,
      el,
      element,
      content,
      annotations,
      hideAnnotation,
      showAnnotation,
      findAnnotation,
      globalDisplayMode,
    };
  },
});
