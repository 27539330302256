




















import { useFillip, useShell } from '@/composables';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';

type LoginProvider = {
  title: string;
  actionTitle: string;
  icon: string;
  methodName: string;
};

type ProviderName = 'google' | 'discord';
type LoginProviders = Record<ProviderName, LoginProvider>;

export default defineComponent({
  name: 'AuthMethodExternal',
  props: {
    provider: {
      type: String as PropType<'google' | 'discord'>,
      default: '',
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { classes, toggleLoading } = useShell();
    const { fillip } = useFillip();

    const loginProviders: LoginProviders = {
      google: {
        title: 'login.google.login',
        actionTitle: 'login.google.login',
        icon: 'google',
        methodName: 'routeToConnectWithGoogle',
      },
      discord: {
        title: 'login.discord.login',
        actionTitle: 'login.discord.login',
        icon: 'discord',
        methodName: 'routeToConnectWithDiscord',
      },
    } as const;

    const activeProvider = computed<(typeof loginProviders)['google']>(
      () => loginProviders[props.provider],
    );

    const triggerLogin = async () => {
      const login = fillip.auth[activeProvider.value.methodName];
      if (!login) {
        console.error('No method found for ', props.provider);
        return;
      }
      toggleLoading(true);
      try {
        await login();
      } catch (e) {
        console.error(e);
        toggleLoading(false);
      }
    };
    return {
      triggerLogin,
      classes,
      loginProviders,
      activeProvider,
    };
  },
});
