import type { Box3, Object3D, Vector3 } from 'three';
import type { Location3DType, ModuleCamera, Vector3Type } from '@fillip/api';
export { PanoramaControls } from './ControlsPanorama';
export { FixedControls } from './ControlsFixed';
export { PanZoomControls } from './ControlsPanZoom';
export { AutoZoomControls } from './ControlsAutoZoom';
export { OrbitControls } from './ControlsOrbit';
import type CameraControls from 'camera-controls';

export * from './CameraUtils';

export interface IBaseControls {
  controls?: CameraControls;
  initialize: (sceneCamera: ModuleCamera) => void;
  updateCamera: (
    sceneCamera: ModuleCamera,
    sceneBoundingBox: Box3,
    hasParentChanged: boolean,
  ) => void;
  checkCameraPosition: (override?: boolean) => void;
  truck: (x: number, y: number) => void;
  zoom: (z: number) => void;
  moveTo: (location: Location3DType | Vector3Type) => void;
  lookAt: (position: Vector3Type | number, y?: number, z?: number) => void;
  zoomToFit?: (focusedObject: Box3 | Object3D) => Promise<void>;
  getCamera: () => THREE.PerspectiveCamera;
  enable: () => void;
  disable: () => void;
  dispose: () => Promise<void>;
}
