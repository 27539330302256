





























































































































import { defineComponent, inject, PropType } from '@vue/composition-api';
import { Action } from '@fillip/api';

import ActionBreakoutRooms from './ActionBreakoutRooms.vue';
import ActionButton from './ActionButton.vue';
import ActionListManager from './document-managers/ActionListManager.vue';
import ActionWorkspaceControl from './ActionWorkspaceControl.vue';
import ActionMultiSelect from './ActionMultiSelect.vue';
import ActionPropertiesManager from './document-managers/ActionPropertiesManager.vue';
import ActionRoleManager from './document-managers/ActionRoleManager.vue';
import ActionStepperControl from './ActionStepperControl.vue';
import ActionTextfield from './ActionTextfield.vue';
import ActionMediaControls from './ActionMediaControls.vue';

export default defineComponent({
  name: 'ActionsComponent',
  components: {
    ActionBreakoutRooms,
    ActionButton,
    ActionListManager,
    ActionWorkspaceControl,
    ActionMultiSelect,
    ActionPropertiesManager,
    ActionRoleManager,
    ActionStepperControl,
    ActionTextfield,
    ActionMediaControls,
  },
  inject: ['actions'],
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    vPropId: {
      type: String,
      required: true,
    },
    vMinimized: {
      type: String,
      required: true,
    },
    areaMeta: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const actionsManager = inject('actions') as any;
    const execute = (action: Action, data: Record<string, any> = {}) => {
      const script = data.script || action.script;
      actionsManager.value.execute(script, { ...action.context, ...data });
    };
    return {
      execute,
    };
  },
});
