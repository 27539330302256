import { ModuleData, Modules } from '@fillip/api';

export interface EvaluationContext {
  environment: Record<string, any>;
  data: ModuleData | {};
  variables: Record<string, any>;
  vm: any;
  local: Record<string, any>; // Local environment with $station
  scope?: Record<string, any>; // Used in interpreter for multi-line expression
  computeds?: Record<string, any>;
  props?: Record<string, any>;
}

export interface EvaluatedTemplate {
  evaluatedTemplate: Modules;
  variables: Record<string, any>;
  computeds: Record<string, any>;
  props: Record<string, any>;
}

export const frontendPrefixMarker = ':';
