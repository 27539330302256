


















































import { useData, useTags } from '@/composables';
import {
  defineComponent,
  computed,
  nextTick,
  ref,
  watch,
  type Ref,
  type PropType,
} from '@vue/composition-api';

export default defineComponent({
  name: 'DataSelector',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array] as PropType<string | string[]>,
      default: '',
    },
    tags: {
      type: [Object, Array] as PropType<
        | string[]
        | {
            option?: string;
            tags?: string[];
          }
      >,
      default: () => ({
        option: 'exclude',
        tags: ['template'],
      }),
    },
    displayType: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: 'children.data',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Array as PropType<string[] | undefined>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { getAll } = useData();
    // const { getDataIcon } = useTags();

    const data = computed(() => getAll());

    const buffer: Ref<string | string[]> = ref(null);
    const searchString = ref('');

    const getInputForItem = () => {
      if (Array.isArray(props.tags)) {
        return data.value.filter((item) =>
          (props.tags as unknown as string[]).includes(item.tag.tag),
        );
      }

      if (props.tags?.option === 'all') {
        return data.value;
      }

      if (props.tags?.option === 'exclude') {
        return data.value.filter((item) => {
          return !props.tags.tags.includes(item.tag.tag);
        });
      }

      if (props.tags?.tags?.length) {
        return data.value.filter((item) => {
          return props.tags.tags.includes(item.tag.tag);
        });
      }

      return data.value;
    };

    const items = computed(() => {
      let input = getInputForItem();
      if (props.filter?.length) {
        input = input.filter((item) => props.filter.includes(item.parentId));
      }
      return input.map((item) => ({
        value: item.id,
        text: item.info?.title || item.id,
        // icon: getDataIcon(item),
      }));
    });

    const onChange = () => {
      emit('input', buffer.value);
      emit('change', buffer.value);
      nextTick(() => {
        searchString.value = '';
      });
    };

    const removeDocument = (documentId) => {
      if (props.multiple) {
        const filtered = (props.value as string[]).filter(
          (d) => d !== documentId,
        );
        buffer.value = filtered;
      } else {
        buffer.value = '';
      }
      onChange();
    };

    watch(
      () => props.value,
      (newValue) => (buffer.value = newValue),
      { immediate: true },
    );

    return {
      // getDataIcon,
      data,
      buffer,
      items,
      searchString,
      onChange,
      removeDocument,
    };
  },
});
