<template>
  <BaseTextInput
    ref="emailInput"
    :value="value"
    :label="label"
    type="email"
    :rules="emailRules"
    icon="envelope"
    :dark="dark"
    :autocomplete="autocomplete"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
import { emailRequired } from '@/utils/validators';
export default {
  name: 'EmailInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: function () {
        return this.$t('user.email');
      },
    },
    autocomplete: {
      type: String,
      default: 'email',
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    emailRules() {
      return [emailRequired];
    },
  },
};
</script>
