






































































import { BaseModuleEditor } from '@/features/editor/base';
import clone from 'rfdc/default';
import { deepEqual } from 'fast-equals';
import {
  defineComponent,
  onMounted,
  ref,
  set,
  watch,
} from '@vue/composition-api';
import { useFillip, useMessage } from '@/composables';
// import '@mux/mux-uploader';
import '@mux/mux-uploader/dist/mux-uploader.js';

export default defineComponent({
  name: 'InputVideo',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const message = useMessage();
    const { fillip } = useFillip();
    const uploader = ref(null);

    const asset = ref(null);
    const buffer = ref(clone(props.value));

    watch(
      () => props.value,
      (newValue) => {
        if (deepEqual(buffer.value, newValue)) {
          return;
        }
        buffer.value = clone(newValue);
      },
      {
        immediate: true,
      },
    );

    const createUpload = async function () {
      const result = await fillip.media.createVideoUploadUrl();
      asset.value = result;
      return result.uploadUrl;
    };

    onMounted(() => {
      uploader.value.endpoint = createUpload;
    });

    const onSuccess = ($event) => {
      getVideoInfo(asset.value.uploadId);
    };

    const onError = ($event) => {
      console.log('Upload error', $event);
      message.error('Upload error');
    };

    const getVideoInfo = async (uploadId: string) => {
      const result = await fillip.media.getVideoUploadInfo(uploadId);
      if (result.status === 'asset_created') {
        asset.value.assetId = result.asset_id;
        getPlaybackId();
      } else {
        setTimeout(() => {
          getVideoInfo(uploadId);
        }, 1000);
      }
      return result;
    };

    const getPlaybackId = async () => {
      const result = await fillip.media.getPlaybackId(asset.value.assetId);
      buffer.value.videoPlaybackId = result;
      buffer.value.videoAssetId = asset.value.assetId;
      save();
    };

    const save = async () => {
      const result = clone(buffer.value);
      emit('change', result);
      emit('input', result);
      emit('blur', result);
    };

    const addTrack = () => {
      if (!buffer.value.subtitles) {
        buffer.value.subtitles = [];
      }
      buffer.value.subtitles.push({
        languageCode: '',
        title: '',
        url: '',
      });
      save();
    };

    const saveTrack = async (index: number) => {
      const result = await fillip.media.addVideoTrack(
        buffer.value.videoAssetId,
        {
          languageCode: buffer.value.subtitles[index].languageCode,
          title: buffer.value.subtitles[index].title,
          url: buffer.value.subtitles[index].url,
        },
      );
      set(buffer.value.subtitles[index], 'id', result.id);
      save();
    };

    const removeTrack = async (index: number) => {
      const trackId = buffer.value.subtitles[index].id;
      if (trackId) {
        await fillip.media.removeVideoTrack(buffer.value.videoAssetId, trackId);
      }
      buffer.value.subtitles.splice(index, 1);
      save();
    };

    return {
      message,
      buffer,
      save,
      asset,

      uploader,
      createUpload,
      onSuccess,
      getVideoInfo,
      getPlaybackId,
      onError,

      addTrack,
      saveTrack,
      removeTrack,
    };
  },
});
