import { ComponentConfig, ComponentInstance } from '../../../core/types';
import * as Location3D from '@/utils/location3D';
import { ArrangementBlock } from '@fillip/api';
import { noLocation } from './utils';

export const blockArranger =
  (vm: ComponentInstance) =>
  (input: ComponentConfig[], slot: string = '') => {
    const arrangement = vm.modules.arrangement as ArrangementBlock;
    const gutter = arrangement.blockGutter ?? 0;
    const constraintsParent = vm.sizeConstraints;
    const maxWidth = !Number.isFinite(constraintsParent.maxWidth)
      ? vm.viewport.width
      : constraintsParent.maxWidth;
    const maxHeight = Infinity;
    const minHeight = 0;
    const minWidth = arrangement.blockKeepSize ? 0 : maxWidth;
    let heightBlock = input
      .map((e) => {
        return e.size ? e.size.height : 0;
      })
      .reduce((a, b) => a + b, 0);
    if (gutter) {
      heightBlock += (input.length - 1) * gutter;
    }

    const sizeConstraints = {
      minWidth: minWidth,
      minHeight: minHeight,
      minDepth: 0,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      maxDepth: 0,
    };

    let top =
      constraintsParent.maxHeight == Infinity
        ? heightBlock / 2
        : constraintsParent.maxHeight / 2;

    return input.map((element) => {
      if (!element.size) {
        return { ...noLocation, sizeConstraints };
      }
      const result = {
        location: Location3D.addDefaults({
          position: {
            y: top - element.size.height / 2,
          },
        }),
        sizeConstraints,
      };
      top -= element.size.height + gutter;
      return result;
    });
  };
