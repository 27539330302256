import { ConnectionState } from './../../../../libs/vue-client/src/helpers/connection-manager';
import { FillipError } from '@fillip/api';

export default {
  methods: {
    async $request(request) {
      try {
        if (!this.$communitySlug) {
          throw new Error(
            'Trying to create community connection while outside a community',
          );
        }
        const connection = await this.$fillip.realtime.communityConnection(
          this.$communitySlug,
        );
        return await request(connection);
      } catch (error) {
        // console.log('request failed', error);
        if (error.$$$fillipError) {
          throw new FillipError(error.error);
        }
        this.$store.dispatch('displayError', error);
      }
    },
    async $invoke(method: string, ...args: any[]) {
      return (this as any).$request((fillip) =>
        fillip.vm.invoke(method, ...args),
      );
    },
  },
  computed: {
    $styles() {
      return this.$store.state.styles;
    },
    $user() {
      return this.$fillip.state.user;
    },
    $communitySlug() {
      return this.$router.currentRoute.params.slug;
    },
    $me() {
      return this.$store.getters.me;
    },
    $community() {
      return this.$store?.state?.activeCommunity;
    },
    $offline() {
      if (
        !this.$communitySlug ||
        !this.$fillip.state.communities[this.$communitySlug]
      )
        return true;
      const wsState =
        this.$fillip.state.communities[this.$communitySlug].connectionState;
      return Boolean(wsState != ConnectionState.CONNECTED);
    },
  },
};
