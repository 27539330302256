import { Vector3, Euler, Matrix4, Quaternion } from 'three';

const UP = new Vector3(0, 1, 0);

// Partial implementation of Object3D
export class Transform3D {
  public position = new Vector3();
  public rotation = new Euler();
  public scale = new Vector3(1, 1, 1);
  public quaternion = new Quaternion();
  public matrix = new Matrix4();
  public matrixNeedsUpdate = false;

  onRotationChange() {
    this.quaternion.setFromEuler(this.rotation);
  }

  onQuaternionChange() {
    this.rotation.setFromQuaternion(this.quaternion);
  }

  updateMatrix() {
    if (!this.matrixNeedsUpdate) return;
    this.matrix.compose(this.position, this.quaternion, this.scale);
    this.matrixNeedsUpdate = false;
  }

  lookAt(target: Vector3) {
    const m1 = new Matrix4().lookAt(this.position, target, UP);

    this.quaternion.setFromRotationMatrix(m1);
    this.onQuaternionChange();
  }

  copy(other: Transform3D) {
    if (other.matrixNeedsUpdate) other.updateMatrix();
    this.position.copy(other.position);
    this.rotation.copy(other.rotation);
    this.scale.copy(other.scale);
    this.quaternion.copy(other.quaternion);
    this.matrix.copy(other.matrix);
    this.matrixNeedsUpdate = false;
  }
}
