// import { BrandingImages, BrandingTheme } from '@fillip/api';
import {
  IsString,
  IsOptional,
  IsNotEmpty,
  MinLength,
  MaxLength,
  IsUrl,
} from 'class-validator';
import { ShellName } from '../shell';

export enum Status {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  DOCUMENTATION = 'documentation',
  ENDED = 'ended',
}

export enum Access {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export interface Community {
  id: number;
  slug: string;
  title: string;
  subtitle?: string;
  description?: string;
  image?: string;
  status: Status;
  access: Access;
  language?: string;
  // shell?: ShellName;
  shell?: string;
}

export interface CommunityProfile {
  id: string;
  community: Community;
}

export class CreateCommunityRequest {
  @MaxLength(30, { message: 'communities.error.titleTooLong||$constraint1' })
  @IsNotEmpty({
    message: 'communities.error.titleNotEmpty',
  })
  @IsString()
  title: string;

  @IsOptional()
  @IsString()
  subtitle: string;

  @IsOptional()
  @IsString()
  description: string;

  // @MinLength(5)
  @IsString()
  @IsOptional()
  slug: string;

  @IsString()
  @IsOptional()
  status: Status;

  @IsNotEmpty({ message: 'communities.error.accessNotEmpty' })
  @IsString()
  access: Access;

  @IsOptional()
  @IsString()
  language?: string;

  @IsOptional()
  @IsString()
  shell?: string;

  @IsOptional()
  @IsUrl()
  image?: string;
}

export class UpdateCommunityRequest {
  @MaxLength(30, { message: 'communities.error.titleTooLong||$constraint1' })
  @IsOptional()
  @IsString()
  title?: string;

  @IsOptional()
  @IsString()
  subtitle?: string;

  @IsOptional()
  @IsString()
  description?: string;

  @MinLength(2, { message: `communities.error.slugMinLength||$constraint1` })
  @IsOptional()
  @IsString()
  slug?: string;

  @IsOptional()
  @IsString()
  status?: Status;

  @IsOptional()
  @IsString()
  access?: Access;

  @IsOptional()
  @IsString()
  language?: string;

  @IsOptional()
  @IsString()
  shell?: string;

  @IsOptional()
  @IsUrl()
  image?: string;
}
