











import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseForm',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null);

    const reset = () => {
      return formRef.value.reset();
    };
    const validate = () => {
      return formRef.value.validate();
    };
    const resetValidation = () => {
      return formRef.value.resetValidation();
    };
    const submit = ($event: MouseEvent | KeyboardEvent) => {
      if (validate()) emit('submit', $event);
    };

    return { formRef, reset, validate, resetValidation, submit };
  },
});
