




import { BaseModuleEditor } from '../base';
import InputVideo from '@/components/input/video/InputVideo.vue';
import { useSave } from '@/composables';
import { defineComponent } from '@vue/composition-api';
import type { ElementVideo } from '@fillip/api';

export default defineComponent({
  name: 'VideoEditor',
  components: {
    InputVideo,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, data, save } = useSave<ElementVideo>(props, {
      path: 'element',
    });

    const changeVideoData = (newBuffer: ElementVideo) => {
      buffer.value = newBuffer;
      save();
    };

    return {
      buffer,
      data,
      save,
      changeVideoData,
    };
  },
});
