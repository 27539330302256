import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagReference: DataDocument = {
  id: 'tag-reference',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Reference',
    icon: 'paperclip',
    isProtected: true,
  },
  element: {
    type: 'element.text',
    textContent: ':$text',
    textFormat: 'div',
  },
  children: {
    default: [
      {
        templateId: 'tag-button',
        query: ':docs($documents)',
        propMappings: {
          script: 'execute(context, $onClick)',
          text: ':properties?.text?.value || properties?.title?.value || info.title',
        },
      },
    ],
  },
  class: {
    class:
      ':`flex flex-wrap items-center gap-2 bg-gray-200 p-4 rounded-md mb-4 ${$containerClasses}`',
    elementClass: ':`${$elementClasses}`',
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'reference',
    title: 'Reference',
    category: 'content',
    icon: 'paperclip',
    editor: 'reference',
  },
  interfaceDefinition: {
    title: '',
    props: [
      {
        title: 'Variante',
        key: 'variant',
        type: 'string',
        defaultValue: ":$variant || properties?.variant?.value || 'default'",
      },
      {
        title: 'Text',
        key: 'text',
        type: 'string',
        defaultValue:
          ':$text || info?.content?.text || properties?.text?.value || properties?.title?.value || info.title',
      },
      {
        title: 'Documents',
        key: 'documents',
        type: 'documents',
        defaultValue:
          ':$documents || info?.content?.documents || properties?.documents?.value || []',
      },
      {
        title: 'onClick Script',
        key: 'onClick',
        type: 'script',
        defaultValue:
          ":$onClick || properties?.onClick?.value || 'router.gotoChild(createRouteSegment($$libraryTemplateId, data.id))'",
      },
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":$containerClasses || properties?.containerClasses?.value || ''",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":$elementClasses || properties?.elementClasses?.value || ''",
      },
    ],
  },
};
