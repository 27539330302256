























































































































import Vue from 'vue';
import { computed } from '@vue/composition-api';
import { BaseModuleEditor } from '../base';
import { useSave, useData } from '@/composables';
import { ModuleNavigationMeta, TNavigationSlot } from '@fillip/api';
import clone from 'rfdc/default';
import InputRoute from '@/components/input/route/InputRoute.vue';

export default Vue.extend({
  name: 'NavigationEditor',
  components: {
    InputRoute,
  },
  mixins: [BaseModuleEditor],
  inject: ['router'],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'navigation',
    });
    const { getData } = useData();
    return {
      buffer,
      save,
      root: computed(() => getData('0')),
    };
  },
  computed: {
    displaySlots() {
      return Object.keys(ModuleNavigationMeta.slots).map((slot) => {
        return {
          value: slot,
          text: this.$t(`navigation.${slot}`),
        };
      });
    },
  },
  methods: {
    addLink() {
      this.buffer.links.push(clone(ModuleNavigationMeta.defaultLink));
      this.save();
    },
    removeLink(index) {
      this.buffer.links.splice(index, 1);
      this.save();
    },
    updateDisplaySlots(index, value) {
      if (!this.buffer.links[index].sortingIndex) {
        Vue.set(this.buffer.links[index], 'sortingIndex', {});
      }
      this.displaySlots.forEach((slot: TNavigationSlot) => {
        if (this.sortingEnabled(index)) {
          if (value.includes(slot)) {
            if (this.buffer.links[index].sortingIndex[slot] == undefined) {
              Vue.set(this.buffer.links[index].sortingIndex, slot, 0);
            }
          } else {
            Vue.delete(this.buffer.links[index].sortingIndex, slot);
          }
        }
      });
      this.save();
    },
    sortingEnabled(index) {
      const activeAreas = Object.keys(
        this.buffer.links[index].sortingIndex || {},
      ).filter((sortingArea) => {
        return (
          this.buffer.links[index].sortingIndex?.[sortingArea] != undefined
        );
      });
      return Boolean(activeAreas.length);
    },
    updateSortingOrder(index) {
      if (this.sortingEnabled(index)) {
        Object.keys(this.buffer.links[index].sortingIndex).forEach(
          (sortingArea) => {
            Vue.delete(this.buffer.links[index].sortingIndex, sortingArea);
          },
        );
        this.save();
      } else {
        this.buffer.links[index].displaySlots.forEach((slot) => {
          if (!this.buffer.links[index].sortingIndex) {
            Vue.set(this.buffer.links[index], 'sortingIndex', {});
          }
          Vue.set(this.buffer.links[index].sortingIndex, slot, 0);
        });
        this.save();
      }
    },
  },
});
