import { maybeWrapInFunction } from './utils';
import type { DataDocument, DataId, DocumentRole } from '@fillip/api';
import { overEvery, matches, matchesProperty, get } from 'lodash/fp';
import { guard } from '@ucast/mongo2js';
import { lodashFp as _ } from '..';

export type FilterFunction = (doc: DataDocument) => boolean;
export type CompareFilterFunction = (
  doc1: DataDocument,
  doc2: DataDocument,
) => boolean;
export type CurriedFilterFunction = (
  ...args: any
) => (doc: DataDocument) => boolean;

export const applyFilters = (filters: Array<FilterFunction | boolean>) => {
  const wrappedFilters = filters.map(maybeWrapInFunction);
  return overEvery(wrappedFilters.filter((f) => Boolean(f)));
};

// General Filters

export const keyStartsWith: CurriedFilterFunction =
  (key: string, needle: string) => (doc: DataDocument) => {
    const value = get(key, doc);
    return value.startsWith && Boolean(value.startsWith(needle));
  };

export const matchQuery: CurriedFilterFunction =
  (query: Record<string, any>) => (doc: DataDocument) => {
    const test = guard<DataDocument>(query);
    return test(doc);
  };

// Document Filters

export const isTemplate: FilterFunction = (doc: DataDocument) =>
  doc.tag?.tag === 'template';
export const isParticipant: FilterFunction = (doc: DataDocument) =>
  doc.tag?.tag === 'participant';
export const isCircle: FilterFunction = (doc: DataDocument) =>
  doc.tag?.tag === 'circle';
export const isDocument: FilterFunction = (doc: DataDocument) =>
  !['template', 'participant', 'circle'].includes(doc.tag?.tag);
export const isPage: FilterFunction = (doc: DataDocument) =>
  doc.tag?.tag === 'page';

// Participant Filters

export const isOnline: FilterFunction = (doc: DataDocument) =>
  Boolean(isParticipant && doc.participantStatus?.online);
export const isOffline: FilterFunction = (doc: DataDocument) =>
  Boolean(isParticipant && !doc.participantStatus?.online);
export const hasStatus: CurriedFilterFunction =
  (status: string) => (doc: DataDocument) =>
    isParticipant && doc.participantStatus?.status === status;

// :participants |> where(isOnline, isNot($me.id))
export const isNot: CurriedFilterFunction =
  (id: DataId) => (doc: DataDocument) =>
    doc.id !== id;

export const is: CurriedFilterFunction = (id: DataId) => (doc: DataDocument) =>
  doc.id === id;

export const isCreatedBy: CurriedFilterFunction =
  (id: DataId) => (doc: DataDocument) =>
    doc.info.createdBy === id;

export const hasTag: CurriedFilterFunction =
  (tag: string | string[]) => (doc: DataDocument) =>
    typeof tag === 'string' ? doc.tag?.tag === tag : tag.includes(doc.tag?.tag);

export const inCircle: CurriedFilterFunction =
  (circleName: DataId) => (doc: DataDocument) =>
    doc.participantCircle?.circle === circleName;

export const hasRole =
  (roles: Record<string, DocumentRole>) =>
  (rolesToCheck: string | string[]) =>
  (doc: DataDocument) => {
    if (!roles || !rolesToCheck || Object.keys(roles).length < 1) return false;

    if (typeof rolesToCheck === 'string') {
      rolesToCheck = [rolesToCheck];
    }

    for (const role of rolesToCheck) {
      if (roles[role]?.documents?.includes(doc.id)) return true;
    }
    return false;
  };

export const documentFilters = {
  applyFilters,
  keyStartsWith,
  matchQuery,
  matches,
  matchesProperty,
  isTemplate,
  isParticipant,
  isCircle,
  isDocument,
  isOnline,
  isOffline,
  hasStatus,
  hasTag,
  isCreatedBy,
  inCircle,
  hasRole,
  isNot,
  is,
  not: _.negate,
  hasProperty: _.has,
};
