import { ModuleTypeMeta } from '../../common';

export const tagCategories = {
  content: { title: 'Content' },
  tool: { title: 'Tool' },
  input: { title: 'Input' },
  other: { title: 'Other' },
};

export type TagCategory = keyof typeof tagCategories;

export interface ModuleTagDefinition {
  tag: string;
  title: string;
  category: TagCategory;
  icon: string;
  editor?: string;
  allowedTags?: string[];
  allowedTagCategories?: TagCategory[];
  // allowedDocuments?: DataId[];
  variants?: Array<{
    key: string;
    title: string;
  }>;
}

export type TagDefinition = ModuleTagDefinition & { id: string };

export const ModuleTagDefinitionMeta: ModuleTypeMeta<ModuleTagDefinition> = {
  default: {
    tag: 'tag',
    title: 'Tag',
    category: 'content',
    icon: '',
  },
  schema: {
    tagCategories,
  },
};
