import { ModuleTypeMeta, UserId } from '../../common';

// TODO: Separate or merge Community Roles and User Roles
// i.e. Host/admin isn't clearly differentiated
export const communityRoles = {
  host: 'Host',
  admin: 'Admin',
  editor: 'Editor',
  participant: 'Participant',
  restricted: 'Restricted',
  inactive: 'Inactive',
};

export type CommunityRole = keyof typeof communityRoles;

export interface ModuleParticipantAccount {
  userId: UserId;
  role?: CommunityRole;
}

export const ModuleParticipantAccountMeta: ModuleTypeMeta<ModuleParticipantAccount> =
  {
    restrictedToTags: ['participant'],
    schema: {
      communityRoles,
    },
    default: {
      userId: null,
      role: 'participant',
    },
  };
