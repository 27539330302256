












import { type PropType, computed, defineComponent } from '@vue/composition-api';
import type { Modules, ElementVideo as ModuleElementVideo } from '@fillip/api';
import '@mux/mux-player';
import { useFillip } from '@/composables';

export default defineComponent({
  name: 'ElementExpandableImage',
  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      default: '',
    },
    modules: {
      type: Object as PropType<Modules & { element: ModuleElementVideo }>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { $me } = useFillip();
    const playbackId = computed(() => props.modules.element?.videoPlaybackId);
    const title = computed(
      () => props.modules.element?.videoTitle || props.modules.info?.title,
    );
    const viewerId = computed(() => $me.value?.id);

    return {
      playbackId,
      title,
      viewerId,
    };
  },
});
