import * as Location3D from '@/utils/location3D';
import {
  parseSizeConstraints,
  Size2D,
  DefaultSizeConstraints,
  SizeConstraints,
} from './../size';
import {
  EvaluatedModuleSize,
  PlacementAbsolute,
  PlacementFixed,
  EvaluatedModuleArrangement,
  ArrangementOriginMeta,
  PlacementFixedMeta,
  Location3DType,
  shallowEqual,
} from '@fillip/api';
import { Arrangers } from './../arrangements';
import { ComponentConfig, ComponentInstance } from '../../../core/types';
import { VNode } from './../../vnode';
import { EntityObject3D } from '../../core';
// import { isSheetActive } from '.';

const FIXED_PADDING = 8;

export const groupChildrenByPlacement = (
  children: Array<VNode>,
): {
  camera: VNode;
  absoluteChildren: Array<VNode>;
  arrangedChildren: Array<VNode>;
  fixedChildren: Array<VNode>;
  // domChildren: Array<VNode>;
} => {
  // TODO: Activate DomChildren group after figuring out how that breaks the layout

  const absoluteChildren: Array<VNode> = [];
  const arrangedChildren: Array<VNode> = [];
  const fixedChildren: Array<VNode> = [];
  // const domChildren: Array<VNode> = [];
  let camera: VNode;
  for (const child of children) {
    const placement = child.props?.placement?.type;

    if (child.props.id == 'camera') {
      camera = child;
      // } else if (!isSheetActive(child.props)) {
      //   domChildren.push(child);
    } else if (placement == 'placement.absolute') {
      absoluteChildren.push(child);
    } else if (placement == 'placement.fixed') {
      fixedChildren.push(child);
    } else {
      arrangedChildren.push(child);
    }
  }

  return {
    camera,
    absoluteChildren,
    arrangedChildren,
    fixedChildren,
    // domChildren
  };
};

export const getChildConstraints = (
  child: VNode,
  constraints: SizeConstraints,
  viewport: Size2D,
) => {
  if (child.props.size)
    return parseSizeConstraints(
      child.props.size as EvaluatedModuleSize,
      viewport,
    );
  if ((child.props.placement as PlacementAbsolute).ignoreParentConstraints)
    return DefaultSizeConstraints;
  return constraints;
};

export const layoutFixedChildren = (
  fixedChildren: VNode[],
  viewport: Size2D,
) => {
  const fixedSizeConstraints = (padding: number = FIXED_PADDING) => ({
    minWidth: 0,
    maxWidth: viewport.width - 2 * padding,
    minHeight: 0,
    maxHeight: viewport.height - 2 * padding,
    minDepth: 0,
    maxDepth: Infinity,
  });

  fixedChildren.forEach((child) => {
    const padding = (child.props.placement as PlacementFixed).padding;
    child.entity.setSizeConstraints(fixedSizeConstraints(padding));
  });

  const ZOOM_FACTOR = 0.9;

  fixedChildren.forEach((child) => {
    const {
      padding: childPadding = FIXED_PADDING,
      fixedVertical,
      fixedHorizontal,
      position,
      // offset
    } = child.props.placement as PlacementFixed;
    child.entity.layout(fixedSizeConstraints(childPadding));

    const { width, height } = child.entity.getSize();

    let x, y, z;

    if (position) {
      ({ x, y, z } = position);
    } else {
      x = (() => {
        if (fixedHorizontal == 'left') {
          return -viewport.width / 2 + childPadding + width / 2;
        } else if (fixedHorizontal == 'right') {
          return viewport.width / 2 - childPadding - width / 2;
        } else {
          return 0;
        }
      })();

      y = (() => {
        if (fixedVertical == 'top') {
          return viewport.height / 2 - childPadding - height / 2;
        } else if (fixedVertical == 'bottom') {
          return -viewport.height / 2 + childPadding + height / 2;
        } else {
          return 0;
        }
      })();

      z = -1000;
    }
    // console.log('Fixed: SetLocation for child', child.props.id, x, y, z);
    // console.log('Parent location', child.parent.entity.getTargetLocation());
    child.entity.setTargetLocation(
      Location3D.addDefaults({
        position: {
          x: x * ZOOM_FACTOR,
          y: y * ZOOM_FACTOR,
          z: z * ZOOM_FACTOR,
        },
        scale: {
          x: ZOOM_FACTOR,
          y: ZOOM_FACTOR,
          z: ZOOM_FACTOR,
        },
      }),
    );
  });
};

export const layoutAbsoluteChildren = (
  absoluteChildren: VNode[],
  viewport: Size2D,
  constraints: SizeConstraints,
) => {
  absoluteChildren.forEach((child) => {
    child.entity.setSizeConstraints(
      getChildConstraints(child, constraints, viewport),
    );
  });

  absoluteChildren.forEach((child) => {
    // ? Previously used DefaultSizeConstraints - unsure what's better...
    child.entity.layout(getChildConstraints(child, constraints, viewport));
    // console.log('Absolute: SetLocation for child', child.props.id);
    child.entity.setTargetLocation(
      (child.props.placement as PlacementAbsolute).absoluteLocation,
    );
  });
};

export const layoutArrangedChildren = (
  arrangedChildren: VNode[],
  viewport: Size2D,
  sizeConstraints: SizeConstraints,
  settings: EvaluatedModuleArrangement = ArrangementOriginMeta.default,
) => {
  const arranger = Arrangers[settings.type];

  // Legacy Arranger
  // 1. Run arranger with no sizes

  const fakeVueInstance = {
    viewport,
    sizeConstraints,
    modules: {
      arrangement: settings,
    },
  } as ComponentInstance;

  const childrenSizeConstraints = arranger(fakeVueInstance)(
    arrangedChildren.map((child) => {
      return {} as unknown as ComponentConfig;
    }),
    '',
  );

  arrangedChildren.forEach((child, index) => {
    child.entity.setSizeConstraints(
      childrenSizeConstraints[index].sizeConstraints,
    );
  });

  arrangedChildren.forEach((child, index) => {
    child.entity.layout(childrenSizeConstraints[index].sizeConstraints);
  });

  const locations = arranger(fakeVueInstance)(
    arrangedChildren.map((child) => {
      return {
        size: child.entity.getSize(),
      } as unknown as ComponentConfig;
    }),
  );

  locations.forEach((location, index) => {
    const child = arrangedChildren[index];
    // console.log('Arranged: SetLocation for child', child.props.id);
    child.entity.setTargetLocation(location.location);
  });
};

// TODO: Cleanup and activate, then remove from entity
// export const layoutChildren = (
//   children: Array<VNode>,
//   viewport: Size2D,
//   constraints: SizeConstraints,
// ) => {
//   const {
//     camera,
//     absoluteChildren,
//     arrangedChildren,
//     fixedChildren,
//     // domChildren
//   } = groupChildrenByPlacement(children);

//   if (camera) {
//     camera.entity.setSizeConstraints(constraints);
//     camera.entity.layout(constraints);
//   }

//   layoutFixedChildren(fixedChildren, viewport);
//   layoutAbsoluteChildren(absoluteChildren, viewport, constraints);
//   layoutArrangedChildren(arrangedChildren, viewport, constraints);
// };

export const isAtSamePosition = (
  location1: Location3DType,
  location2: Location3DType,
) => {
  const positionChanged = !shallowEqual(location1.position, location2.position);
  const rotationChanged = !shallowEqual(location1.rotation, location2.rotation);
  const scaleChanged = !shallowEqual(location1.scale, location2.scale);
  return {
    changed: positionChanged || rotationChanged || scaleChanged,
    positionChanged,
    rotationChanged,
    scaleChanged,
  };
};
