import { BaseElement } from './../core';
import BaseIcon from '@/components/base/BaseIcon.vue';

export const ElementIcon = {
  name: 'ElementIcon',
  mixins: [BaseElement],
  computed: {
    element() {
      return this.modules.element;
    },
  },
  render(h) {
    const iconElement = this.element.icon
      ? h(BaseIcon, {
          ref: 'el',
          attrs: {
            icon: this.element.icon,
            color: this.element.color,
            size: this.element.size,
          },
        })
      : h(
          'div',
          {
            style: {
              display: 'grid',
              placeItems: 'center',
              width: '100%',
              height: '100%',
            },
          },
          'Icon not set',
        );

    return iconElement;
  },
};
