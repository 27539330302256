























import { defineComponent, PropType, ref } from '@vue/composition-api';
import type { LocaleMessages, TranslateResult } from 'vue-i18n';
export default defineComponent({
  name: 'InputTextarea',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String as PropType<string | LocaleMessages | TranslateResult>,
      default: '',
    },
    placeholder: {
      type: String as PropType<string | LocaleMessages | TranslateResult>,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 1,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputComponent = ref(null);

    const onEnter = ($event: KeyboardEvent) => {
      $event.preventDefault();
      ($event.currentTarget as HTMLInputElement).blur();
      emit('enter', $event);
      return false;
    };
    return {
      inputComponent,
      onEnter,
    };
  },
});
