import type { Vector3Type } from './../../common/index';
import { MediaSettings } from '@fillip/api';
import { type ControlType, controlTypes } from './shared';
import type {
  URLString,
  TypeMeta,
  DataId,
  ModuleTypeMeta,
  ColorDescriptor,
} from '../../common';

type ElementTypeKeys =
  | 'element.button'
  | 'element.embed'
  | 'element.html'
  | 'element.icon'
  | 'element.image'
  | 'element.video'
  | 'element.markdown'
  | 'element.participant'
  | 'element.screenshare'
  | 'element.text'
  | 'element.chart'
  | 'element.table'
  | 'element.reveal'
  | 'element.audio'
  | 'element.svg'
  | 'element.inlineEditing'
  | 'element.line'
  | 'element.styledText';

interface ElementType<Key extends ElementTypeKeys> {
  type: Key;
}

export interface ElementTypeMeta<T extends ElementType<ElementTypeKeys>>
  extends TypeMeta<T> {
  type: T['type'];
  icon: string;
  schema?: Record<string, any>;
}

const BuiltinTextFormats = {
  h1: { title: 'element.text.h1' },
  h2: { title: 'element.text.h2' },
  h3: { title: 'element.text.h3' },
  h4: { title: 'element.text.h4' },
  h5: { title: 'element.text.h5' },
  h6: { title: 'element.text.h6' },
  p: { title: 'element.text.p' },
  div: { title: 'element.text.div' },
};

type TextFormats = keyof typeof BuiltinTextFormats;

export interface ElementText extends ElementType<'element.text'> {
  textContent: string;
  textFormat: TextFormats;
}

export const ElementTextMeta: ElementTypeMeta<ElementText> = {
  type: 'element.text',
  icon: 'text',
  schema: {
    formats: BuiltinTextFormats,
  },
  default: {
    type: 'element.text',
    textContent: '',
    textFormat: 'p',
  },
};

export const iconSizes = {
  small: { title: 'element.icon.sizes.small' },
  medium: { title: 'element.icon.sizes.medium' },
  large: { title: 'element.icon.sizes.large' },
  xl: { title: 'element.icon.sizes.xl' },
};

export interface ElementIcon extends ElementType<'element.icon'> {
  icon: string;
  size: keyof typeof iconSizes | string;
  color: ColorDescriptor;
}

export const ElementIconMeta: ElementTypeMeta<ElementIcon> = {
  type: 'element.icon',
  icon: 'icons',
  schema: {
    iconSizes,
  },
  default: {
    type: 'element.icon',
    icon: '',
    size: 'large',
    color: 'rgba(1, 1, 1, 0.54)',
  },
};

export interface ElementImage extends ElementType<'element.image'> {
  imageSrc: URLString;
  imageDescription: string;
  imageCopyright: string;
  imageTitle?: string;
  expandOnClick?: boolean;
}

export const ElementImageMeta: ElementTypeMeta<ElementImage> = {
  type: 'element.image',
  icon: 'image-polaroid',
  default: {
    type: 'element.image',
    imageSrc: '',
    imageDescription: '',
    imageCopyright: '',
    expandOnClick: false,
  },
};

export interface ElementVideoSubtitleTrack {
  title: string;
  languageCode: string;
  url: URLString;
  id?: string;
}

export interface ElementVideo extends ElementType<'element.video'> {
  videoAssetId?: string;
  videoPlaybackId?: string;
  videoTitle?: string;
  videoDescription: string;
  videoCopyright: string;
  subtitles?: ElementVideoSubtitleTrack[];
}

export const ElementVideoMeta: ElementTypeMeta<ElementVideo> = {
  type: 'element.video',
  icon: 'file-video',
  default: {
    type: 'element.video',
    videoTitle: '',
    videoDescription: '',
    videoCopyright: '',
    subtitles: [],
  },
};

export interface ElementSvg extends ElementType<'element.svg'> {
  svgSrc: URLString;
  svgCode: string;
  svgSettings: {
    svgDescription: string;
    svgCopyright: string;
    svgTitle?: string;
    svgStyles: string;
  };
}

export const ElementSvgMeta: ElementTypeMeta<ElementSvg> = {
  type: 'element.svg',
  icon: 'bezier-curve',
  default: {
    type: 'element.svg',
    svgSrc: '',
    svgCode: '',
    svgSettings: {
      svgDescription: '',
      svgCopyright: '',
      svgTitle: '',
      svgStyles: '',
    },
  },
};

export interface ElementMarkdown extends ElementType<'element.markdown'> {
  markdownContent: string;
}

export const ElementMarkdownMeta: ElementTypeMeta<ElementMarkdown> = {
  type: 'element.markdown',
  icon: 'hashtag',
  default: {
    type: 'element.markdown',
    markdownContent: '',
  },
};

export interface ElementHTML extends ElementType<'element.html'> {
  htmlContent: string;
}

export const ElementHTMLMeta: ElementTypeMeta<ElementHTML> = {
  type: 'element.html',
  icon: 'file-code',
  default: {
    type: 'element.html',
    htmlContent: '',
  },
};

export const inlineFormattingOptions = {
  none: { title: 'element.inlineEditing.formatting.none' },
  inline: { title: 'element.inlineEditing.formatting.inline' },
  basic: { title: 'element.inlineEditing.formatting.basic' },
  advanced: { title: 'element.inlineEditing.formatting.advanced' },
};

export const targetFormats = {
  html: { title: 'element.inlineEditing.targetFormat.html' },
  text: { title: 'element.inlineEditing.targetFormat.text' },
};

export interface ElementInlineEditing
  extends ElementType<'element.inlineEditing'> {
  documentId: string;
  label?: string;
  placeholder?: string;
  allowEditing: boolean | string;
  fieldPath: string;
  formatting: keyof typeof inlineFormattingOptions;
  autoSave: boolean;
  saveAsVolatile?: boolean;
  targetFormat?: keyof typeof targetFormats;
  dark?: boolean;
}

export const ElementInlineEditingMeta: ElementTypeMeta<ElementInlineEditing> = {
  type: 'element.inlineEditing',
  icon: 'pen',
  schema: {
    formatting: inlineFormattingOptions,
    targetFormats: targetFormats,
  },
  default: {
    type: 'element.inlineEditing',
    documentId: '',
    label: '',
    allowEditing: ':true',
    fieldPath: 'info.content',
    formatting: 'basic',
    autoSave: true,
    targetFormat: 'html',
    dark: false,
    saveAsVolatile: false,
  },
};

export interface ElementStyledText extends ElementType<'element.styledText'> {
  htmlContent: string;
}

export const ElementStyledTextMeta: ElementTypeMeta<ElementStyledText> = {
  type: 'element.styledText',
  icon: 'text-size',
  default: {
    type: 'element.styledText',
    htmlContent: '',
  },
};

export interface ElementEmbed extends ElementType<'element.embed'> {
  embedSrc: URLString;
  embedCode: string;
  embedMode: '1' | 'card' | '0';
  // TODO: Type correctly using iframely types
  embedMeta: Record<string, any>;
  embedOptions: Record<string, any>;
}

export const ElementEmbedMeta: ElementTypeMeta<ElementEmbed> = {
  type: 'element.embed',
  icon: 'window-frame-open',
  default: {
    type: 'element.embed',
    embedSrc: '',
    embedCode: '',
    embedMeta: null,
    embedMode: '1',
    embedOptions: {},
  },
};

export interface ElementScreenshare extends ElementType<'element.screenshare'> {
  participantId: DataId;
}

export const ElementScreenshareMeta: ElementTypeMeta<ElementScreenshare> = {
  type: 'element.screenshare',
  icon: 'arrow-alt-square-up',
  default: {
    type: 'element.screenshare',
    participantId: null,
  },
};

export const ParticipantShapes = {
  circle: { title: 'element.participant.circle' },
  square: { title: 'element.participant.square' },
  rectangle: { title: 'element.participant.rectangle' },
  cover: { title: 'element.participant.cover' },
};

type TParticipantShapes = keyof typeof ParticipantShapes;

export interface ElementParticipant extends ElementType<'element.participant'> {
  participantId: DataId;
  participantShape: TParticipantShapes | string;
  alwaysShowName: boolean | string;
  participantVideo: boolean | string;
  participantAudio: boolean | string;
  participantHideDetails?: boolean | string;
}

export const ElementParticipantMeta: ElementTypeMeta<ElementParticipant> = {
  type: 'element.participant',
  icon: 'user',
  schema: {
    shapes: ParticipantShapes,
  },
  default: {
    type: 'element.participant',
    participantId: null,
    participantShape: 'circle',
    alwaysShowName: false,
    participantVideo: true,
    participantAudio: true,
    participantHideDetails: false,
  },
};

export interface ElementButton extends ElementType<'element.button'> {
  buttonText: string;
  buttonIcon: string;
  buttonEvent: string;
  buttonEventArgs?: string;
  buttonHref?: string;
  iconOnly?: boolean;
}

export const ElementButtonMeta: ElementTypeMeta<ElementButton> = {
  type: 'element.button',
  icon: 'bullseye-pointer',
  default: {
    type: 'element.button',
    buttonText: 'Click',
    buttonIcon: '',
    buttonEvent: 'click',
    iconOnly: false,
  },
};

export type TableRow = Record<string, any>;
export interface TableColumn {
  title: string;
  path: string;
  classes?: string;
  locked?: boolean;
}

export interface ElementTable extends ElementType<'element.table'> {
  tableAllowContentEditing?: boolean;
  tableUseQuery: boolean | string;
  tableQuery: string;
  tableRowData?: TableRow[] | string;
  tableColumns?: TableColumn[] | string;
}

export const ElementTableMeta: ElementTypeMeta<ElementTable> = {
  type: 'element.table',
  icon: 'bullseye-pointer',
  default: {
    type: 'element.table',
    tableAllowContentEditing: false,
    tableUseQuery: true,
    tableQuery: '',
    tableRowData: ':[]',
    tableColumns: [{ title: 'Titel', path: 'info.title', classes: '' }],
  },
  childDefault: {
    columnDefault: { title: '', path: '', classes: '' },
  },
};

export const ChartDefaultColors = [
  {
    backgroundColor: 'rgba(143, 191, 249,0.7)',
    borderColor: 'rgba(143, 191, 249,1)',
    color: 'rgba(143, 191, 249,1)',
  },
  {
    backgroundColor: 'rgba(249, 143, 244,0.7)',
    borderColor: 'rgba(249, 143, 244)',
    color: 'rgba(249, 143, 244)',
  },
  {
    backgroundColor: 'rgba(249, 201, 143,0.7)',
    borderColor: 'rgba(249, 201, 143,1)',
    color: 'rgba(249, 201, 143,1)',
  },
  {
    backgroundColor: 'rgba(143, 249, 148,0.7)',
    borderColor: 'rgba(143, 249, 148,1)',
    color: 'rgba(143, 249, 148,1)',
  },
];
export interface PreEvalDataSet {
  chartDataY: string;
  backgroundColor: string;
  stacked: boolean;
  chartDataX: string;
  borderRadius: number;
  borderWidth: number;
  borderColor: string;
  global: {
    borderRadius: boolean;
    borderWidth: boolean;
    borderColor: boolean;
  };
  color: string;
  fit: boolean;
}

export const PreEvalChartDataDefault = {
  dataset: <PreEvalDataSet>{
    fit: true,
    chartDataY: ':[1]',
    backgroundColor: 'rgba(143, 191, 249,0.7)',
    borderColor: 'rgba(143, 191, 249,1)',
    stacked: false,
    chartDataX: 'no label',
    global: { borderRadius: true, borderWidth: true, borderColor: false },
    borderRadius: 0,
    borderWidth: 2,
    color: 'rgba(143, 191, 249,0.7)',
  },
  label: 'no label',
};

const chartTypes = {
  bar: { title: 'element.chart.chartTypes.bar' },
  line: { title: 'element.chart.chartTypes.line' },
  doughnut: { title: 'element.chart.chartTypes.doughnut' },
  pie: { title: 'element.chart.chartTypes.pie' },
  radar: { title: 'element.chart.chartTypes.radar' },
  polarArea: { title: 'element.chart.chartTypes.polarArea' },
  // bubble: { title: 'element.chart.chartTypes.bubble' },
  // scatter: { title: 'element.chart.chartTypes.scatter' },
  wordCloud: { title: 'element.chart.chartTypes.wordCloud' },
} as const;

type ChartType = keyof typeof chartTypes;

const labelPositions = {
  start: { title: 'element.chart.start' },
  center: { title: 'element.chart.center' },
  end: { title: 'element.chart.end' },
} as const;

type LabelPosition = keyof typeof labelPositions;

const ticksUnitPositions = {
  before: { title: 'element.chart.before' },
  after: { title: 'element.chart.after' },
} as const;

type TicksUnitPosition = keyof typeof ticksUnitPositions;

export interface ElementChart extends ElementType<'element.chart'> {
  data: {
    datasets: Array<PreEvalDataSet>;
    labels: Array<string> | string;
  };
  chartType: ChartType | string;
  scriptedDatasets: string;
  scriptedOptions: string;
  // sliderValue: number;
  options: {
    colorAxis: string;
    colorGridLines: string;
    labelX: string;
    labelY: string;
    ticksUnitX: string;
    ticksUnitY: string;
    showGrid: boolean;
    borderWidth: number;
    borderRadius: number;
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
    horizontal: boolean;
    beginAtZero: boolean;
    showLabel: boolean;
    labelAlign: LabelPosition;
    ticksUnitPosition: TicksUnitPosition;
    customLayers: boolean;
    layers: number;
    showAxis: boolean;
    legendFontSize?: number;
  };
  editor: {
    scriptedDatasets: boolean;
    scriptedType: boolean;
    scriptedOptions: boolean;
  };
}

export const ElementChartMeta: ElementTypeMeta<ElementChart> = {
  type: 'element.chart',
  icon: 'chart-line',
  schema: {
    labelPositions,
    ticksUnitPositions,
    chartTypes,
  },
  default: {
    type: 'element.chart',
    data: { datasets: [], labels: ":['No Label']" },
    chartType: 'bar',
    scriptedDatasets:
      ":[{data:[],backgroundColor:['rgba(143, 191, 249,0.7)'],label:'noLabel'}]",
    scriptedOptions: '',
    // sliderValue: 0,
    options: {
      customLayers: false,
      layers: 1,
      borderWidth: 2,
      borderRadius: 2,
      minX: 0,
      minY: 0,
      maxX: 10,
      maxY: 10,
      colorGridLines: 'rgba(229, 231, 235, 1)',
      colorAxis: 'rgba(158, 158, 158, 1)',
      labelX: 'X',
      labelY: 'Y',
      ticksUnitX: '',
      ticksUnitY: '',
      showGrid: true,
      horizontal: false,
      beginAtZero: false,
      showLabel: false,
      labelAlign: 'center',
      ticksUnitPosition: 'after',
      showAxis: true,
    },
    editor: {
      scriptedDatasets: false,
      scriptedType: false,
      scriptedOptions: false,
    },
  },
};

export interface RevealState {
  indexf: number;
  indexh: number;
  indexv: number;
  overview: boolean;
  paused: boolean;
}

const controlPositions = {
  'bottom-right': { title: 'element.reveal.layout.bottomRight' },
  edges: { title: 'element.reveal.layout.edges' },
} as const;

export type ControlPosition = keyof typeof controlPositions;

export interface ElementReveal extends ElementType<'element.reveal'> {
  revealContent: string;
  revealState?: RevealState | string;
  revealControlledBy?: ControlType | string;
  options: {
    controls?: boolean | string;
    controlsLayout?: ControlPosition;
  };
}

export const ElementRevealMeta: ElementTypeMeta<ElementReveal> = {
  type: 'element.reveal',
  icon: 'presentation',
  schema: {
    controlTypes,
    controlPositions,
  },
  default: {
    type: 'element.reveal',
    revealContent: '',
    revealControlledBy: 'user',
    options: {
      controls: true,
      controlsLayout: 'bottom-right',
    },
  },
};

export interface ElementAudio extends ElementType<'element.audio'> {
  audioSrc: URLString;
  audioSettings: MediaSettings;
}

export const ElementAudioMeta: ElementTypeMeta<ElementAudio> = {
  type: 'element.audio',
  icon: 'music',
  default: {
    type: 'element.audio',
    audioSrc: '',
    audioSettings: {
      controlledBy: 'host',
      startedAt: null,
      pausedAt: null,
      loop: false,
      volume: 0.5,
      startEvent: 'manual',
      controlPanel: false,
      title: '',
      description: '',
      copyright: '',
      transcript: '',
    },
  },
};

export interface ElementLine extends ElementType<'element.line'> {
  lineColor: string;
  lineWidth: string;
  lineFrom: string | Vector3Type;
  lineTo: string | Vector3Type;
  lineStyle?: 'solid' | 'dashes-small' | 'dashes-large' | 'dotted';
  arrows?: 'start' | 'end' | 'both';
}

export const ElementLineMeta: ElementTypeMeta<ElementLine> = {
  type: 'element.line',
  icon: 'project-diagram',
  default: {
    type: 'element.line',
    lineColor: 'rgb(100,100,100)',
    lineWidth: '5px',
    lineFrom: '',
    lineTo: '',
    arrows: 'end',
    lineStyle: 'solid',
  },
};

export type ModuleElement =
  | ElementText
  | ElementImage
  | ElementVideo
  | ElementIcon
  | ElementScreenshare
  | ElementEmbed
  | ElementHTML
  | ElementMarkdown
  | ElementParticipant
  | ElementButton
  | ElementChart
  | ElementTable
  | ElementReveal
  | ElementAudio
  | ElementSvg
  | ElementInlineEditing
  | ElementLine
  | ElementStyledText;

export interface ModuleElementTypeMeta extends ModuleTypeMeta<ModuleElement> {
  types: {
    [key in ElementTypeKeys]: ElementTypeMeta<ElementType<key>>;
  };
}

export const ModuleElementMeta: ModuleElementTypeMeta = {
  types: {
    'element.text': ElementTextMeta,
    'element.icon': ElementIconMeta,
    'element.image': ElementImageMeta,
    'element.video': ElementVideoMeta,
    'element.embed': ElementEmbedMeta,
    'element.screenshare': ElementScreenshareMeta,
    'element.markdown': ElementMarkdownMeta,
    'element.html': ElementHTMLMeta,
    'element.participant': ElementParticipantMeta,
    'element.button': ElementButtonMeta,
    'element.chart': ElementChartMeta,
    'element.table': ElementTableMeta,
    'element.reveal': ElementRevealMeta,
    'element.audio': ElementAudioMeta,
    'element.svg': ElementSvgMeta,
    'element.inlineEditing': ElementInlineEditingMeta,
    'element.line': ElementLineMeta,
    'element.styledText': ElementStyledTextMeta,
  },
  default: ElementTextMeta.default,
  icon: 'icons',
};
