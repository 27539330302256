













































































import Vue from 'vue';

import PrimitiveTemplateSelector from '@/components/primitive-editor/types/PrimitiveTemplateSelector.vue';
import PrimitiveDataSelector from '@/components/primitive-editor/types/PrimitiveDataSelector.vue';
import TreeTemplateSelector from '@/components/primitive-editor/types/TreeTemplateSelector.vue';
import InputBoolean from '@/components/input/boolean/InputBoolean.vue';

import { DataId, rootTemplateId, RootRoute } from '@fillip/api';
import { useData } from '@/composables/data/useData';
import { GlobalPropsNode } from '@/plugins/global-props';

import clone from 'rfdc/default';

export default Vue.extend({
  name: 'InputRoute',
  components: {
    PrimitiveTemplateSelector,
    PrimitiveDataSelector,
    InputBoolean,
    TreeTemplateSelector,
  },
  mixins: [GlobalPropsNode],
  props: {
    value: {
      type: Object,
    },
    namedSceneSelection: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { getData } = useData();
    return { getData };
  },
  data() {
    return {
      inputType: 'name',
    };
  },
  computed: {
    root() {
      const rootSegment = this.getData(
        this.value.path.slice(0)[0].scene.templateId,
      );
      return rootSegment.info?.title || rootSegment.id;
    },
    items() {
      const globalItems = this.$getGlobalProp('sceneDefinitions');
      if (!globalItems) {
        return [];
      } else {
        return globalItems.map((scene) => {
          return { value: scene.slug, text: scene.title };
        });
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue?.path[0]?.scene?.templateId != rootTemplateId) {
          this.$emit('input', clone(RootRoute));
        }
      },
    },
  },
  methods: {
    updateTemplate(id: DataId, routeSegmentIndex: number) {
      const route = clone(this.value);
      route.path[routeSegmentIndex].scene.templateId = id || rootTemplateId;
      this.$emit('input', route);
    },
    updateData(id: DataId, routeSegmentIndex: number) {
      const route = clone(this.value);
      route.path[routeSegmentIndex].scene.dataId = id;
      this.$emit('input', route);
    },
    addRouteSegment() {
      const route = clone(this.value);
      route.path.push({
        scene: { explicit: false, name: '' },
        params: {},
      });
      this.$emit('focus');
      this.$emit('input', route);
    },
    removeSegment(index) {
      const route = clone(this.value);
      route.path.splice(index, 1);
      this.$emit('input', route);
    },
    updateSegmentName(value, index) {
      const route = clone(this.value);
      route.path[index].scene.name = value;
      this.$emit('input', route);
    },
    updateSegmentType(value, index) {
      const route = clone(this.value);
      if (value) {
        route.path[index].scene = {
          explicit: true,
          templateId: '',
          dataId: '',
        };
      } else {
        route.path[index].scene = {
          explicit: false,
          name: '',
        };
      }
      this.$emit('input', route);
    },
  },
});
