import { ComponentConfig, ComponentInstance } from '../../../core/types';
import { DefaultSizeConstraints } from './../size';
import * as Location3D from '@/utils/location3D';
import { noLocation } from './utils';
import { ArrangementFlex } from '@fillip/api';

export const flexArranger =
  (vm: ComponentInstance) =>
  (input: ComponentConfig[], slot: string = '') => {
    const arrangement = vm.modules.arrangement as ArrangementFlex;
    const gutter = arrangement.flexGutter ?? 0;
    let totalWidth = input.reduce(
      (result, element) => result + (element.size ? element.size.width : 0),
      0,
    );

    if (gutter) {
      totalWidth += (input.length - 1) * gutter;
    }

    let left = 0;
    return input.map((element) => {
      if (!element.size) {
        return noLocation;
      }
      const result = {
        location: Location3D.addDefaults({
          position: {
            x: left + element.size.width / 2 - totalWidth / 2,
          },
        }),
        sizeConstraints: DefaultSizeConstraints,
      };
      left += element.size.width + gutter;
      return result;
    });
  };
