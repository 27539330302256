import {
  DataId,
  Tag,
  DataDocument,
  PresetDefinition,
  PresetOptions,
  ImportOptions,
  localPrefix,
  ModuleProperties,
  DocumentRole,
  PresetInstallResult,
  rootDataId,
} from '@fillip/api';
import store from '@/store';

import { computed } from '@vue/composition-api';
import { nanoid } from 'nanoid/non-secure';
import { useVolatileData } from './useVolatileData';
import { useFillip } from '../globals/useFillip';

export function useData() {
  const { getVolatileProp, setVolatileProp, clearVolatile } = useVolatileData();
  const { $invoke, fiStoreDispatcher } = useFillip();
  // Computeds

  const data = computed(() => {
    return fiStoreDispatcher.getCollection('data') as DataDocument[];
  });

  // Getters

  const getAll = (): DataDocument[] => {
    return fiStoreDispatcher.getCollection('data') as DataDocument[];
  };

  const getLocal = (): DataDocument[] => {
    return fiStoreDispatcher.getCollection('data', true) as DataDocument[];
  };

  const getData = (id: DataId): DataDocument => {
    return fiStoreDispatcher.getDocument('data', id) as DataDocument;
  };

  const getPath = (id: DataId): DataId[] => {
    const document = getData(id);
    if (!document || !document.parentId) return [id];
    const result = getPath(document.parentId);
    result.push(id);
    return result;
  };

  const getDataParentId = (id: DataId): DataId => {
    return getData(id).parentId;
  };

  const getDataParent = (id: DataId): DataDocument => {
    return getData(getDataParentId(id));
  };

  const getFlatDataTree = (id: DataId): DataDocument[] => {
    const document = getData(id);
    if (!document) return [];
    if (document.list?.items?.length > 0) {
      const children = document.list.items.reduce((result, { id: listId }) => {
        if (id == listId) {
          console.warn('Duplicate id in list', id);
          return result;
        }
        return result.concat(getFlatDataTree(listId));
      }, []);
      return [document, ...children];
    }
    return [document];
  };

  const getDataByTag = (tag: Tag): DataDocument[] => {
    // Use cached query tags
    if (tag === 'participant') return store.getters.participants;
    if (tag === 'tag') return store.getters.tags;
    if (tag === 'template') return store.getters.templates;
    // Or filter the documents now
    return getAll().filter((d: DataDocument) => d.tag?.tag == tag);
  };

  const getDataIdsByTag = (tag: Tag): DataId[] => {
    return getDataByTag(tag).map((d) => d.id);
  };

  const getDataByIds = (ids: DataId[]): DataDocument[] => {
    if (!ids || ids.length < 1) return null;
    return ids.map((id) => getData(id)).filter((d) => Boolean(d && d.id));
  };

  // Setters

  const pushData = async (
    id: DataId,
    tag: Tag,
    doc?: Partial<DataDocument>,
    forceOverwrite: boolean = false,
  ): Promise<DataDocument> => {
    const newId =
      doc?.id ||
      (id.startsWith(localPrefix) ? localPrefix + nanoid() : nanoid());
    const newItem = Object.assign(
      {},
      {
        parentId: id,
        info: {
          title: '',
          icon: '',
        },
        tag: {
          tag,
        },
        properties: {},
        list: { items: [] },
      },
      doc,
      {
        id: newId,
      },
    );

    await fiStoreDispatcher.pushDocument('data', id, newItem, forceOverwrite);

    return newItem;
  };
  const insertData = async (
    id: DataId,
    position: number,
    tag: Tag,
  ): Promise<DataDocument> => {
    const newItem: DataDocument = {
      id: nanoid(),
      parentId: id,
      info: {
        title: '',
        icon: '',
      },
      tag: {
        tag,
      },
      properties: {},
      list: { items: [] },
    };
    await fiStoreDispatcher.insertDocument('data', id, position, newItem);
    return newItem;
  };

  const removeData = (id: DataId) => {
    if (!id) return;
    if (getVolatileProp(rootDataId, 'selection') == id) {
      setVolatileProp(rootDataId, 'selection', null);
    }
    clearVolatile(id);
    return fiStoreDispatcher.removeDocument('data', id);
  };

  const clearChildren = (id: DataId) => {
    if (!id) {
      console.error('clearChildren: Missing id', id);
      return;
    }
    return fiStoreDispatcher.clearChildren('data', id);
  };

  const updateProperties = (id: DataId, properties: ModuleProperties) => {
    if (!id) {
      console.error('updateProperties: Missing id', id, properties);
      return;
    }
    return $invoke('dataUpdateProperties', id, properties);
  };

  const updateRoles = (id: DataId, roles: Record<string, DocumentRole>) => {
    return $invoke('dataUpdateRoles', id, roles);
  };

  const updateTitle = (id: DataId, title: string) => {
    return $invoke('dataUpdateTitle', id, title);
  };

  const updateTag = (id: DataId, tag: Tag) => {
    return $invoke('dataUpdateTag', id, tag);
  };

  const updateData = (id: DataId, newDocument: Record<string, any>) => {
    return fiStoreDispatcher.update('data', id, newDocument);
  };

  const updatePaths = (
    id: DataId,
    updates: { path: string; value: Record<string, any> }[],
    removeIfNull: boolean = true,
  ) => {
    return fiStoreDispatcher.updatePaths('data', id, updates, removeIfNull);
  };

  const patchPaths = (id: DataId, paths: Record<string, any>) => {
    return fiStoreDispatcher.patchPaths('data', id, paths);
  };

  const updatePath = (
    id: DataId,
    path: string,
    value: any,
    removeIfNull: boolean = true,
  ) => {
    return fiStoreDispatcher.updatePath('data', id, path, value, removeIfNull);
  };

  // Methods

  const importFlatDataTree = async (
    targetId: DataId,
    data: DataDocument | DataDocument[],
    options: ImportOptions = {},
  ): Promise<any> => {
    if (!data) throw new Error('No objects provided.');
    const importData = Array.isArray(data) ? data : [data];
    if (!importData || importData.length < 1)
      throw new Error('No objects provided.');
    return $invoke('importFlatObjectTree', targetId, importData, options);
  };

  const duplicateDataFromId = async (
    id: DataId,
    rootOnly: Boolean = false,
  ): Promise<any> => {
    const data = rootOnly ? [getData(id)] : getFlatDataTree(id);
    if (!data) return;
    const parent = getDataParentId(id);
    if (!parent) throw new Error('data is missing owner'); // TODO: throw Fillip Error
    return importFlatDataTree(parent, data);
  };

  const installPreset = async (
    targetId: DataId,
    preset: PresetDefinition,
    options: PresetOptions,
  ): Promise<PresetInstallResult> => {
    const { result } = await $invoke(
      'installPreset',
      targetId,
      preset,
      options,
    );
    return result as PresetInstallResult;
  };

  const maybeDoc = (docOrId: DataId | DataDocument) => {
    return typeof docOrId === 'string' ? getData(docOrId) : docOrId;
  };
  const maybeId = (docOrId: DataId | DataDocument) => {
    if (!docOrId) return undefined;
    return typeof docOrId === 'string' ? docOrId : docOrId.id;
  };

  return {
    // Computeds
    data,

    // Getters
    getData,
    getLocal,
    getAll,
    getPath,
    getFlatDataTree,
    getDataParentId,
    getDataParent,
    getDataByTag,
    getDataIdsByTag,
    getDataByIds,

    // Setters
    insertData,
    pushData,
    removeData,
    clearChildren,
    updateProperties,
    updateRoles,
    updateTitle,
    updateTag,
    updateData,
    updatePath,
    patchPaths,
    updatePaths,

    // Methods
    importFlatDataTree,
    duplicateDataFromId,
    installPreset,
    maybeDoc,
    maybeId,
  };
}
