import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagQuote: DataDocument = {
  id: 'tag-quote',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Quote',
    icon: 'quote-left',
    isProtected: true,
  },
  element: {
    type: 'element.styledText',
    htmlContent:
      ":`${$text} ${$author && '<p class=\"font-bold font-sm not-italic\">' + $author + '</p>'}`",
  },
  class: {
    class: ':`${$containerClasses}`',
    elementClass: ':`${$elementClasses}`',
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'quote',
    title: 'Quote',
    category: 'content',
    icon: 'quote-left',
    editor: 'quote',
  },
  interfaceDefinition: {
    title: '',
    inheritFrom: 'baseContent',
    props: [
      {
        title: 'Author',
        key: 'author',
        type: 'string',
        defaultValue: ":$author || properties?.author?.value || ''",
      },
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":`${$$contentTagClasses} ${$$textClasses} border-0 border-solid border-l-8 border-gray-200 pl-4 font-italic ${$containerClasses || properties?.containerClasses?.value || ''}`",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":`${$elementClasses || properties?.elementClasses?.value || ''}`",
      },
    ],
  },
};
