import { ComponentConfig, ComponentInstance } from '../../../core/types';
import * as Location3D from '@/utils/location3D';

export const absoluteArranger =
  (vm: ComponentInstance) =>
  (input: ComponentConfig[], _slot: string = '') => {
    return input.map(({ modules }) => {
      const location =
        modules?.placement?.type == 'placement.absolute'
          ? modules.placement.absoluteLocation
          : Location3D.origin;

      return {
        location,
        sizeConstraints: vm.sizeConstraints,
      };
    });
  };
