














import Vue from 'vue';
import { BaseDocumentEditor } from '@/features/editor/base/BaseDocumentEditor';
import { ElementTable } from '@/features/main/elements/ElementTable';
import { set } from 'lodash/fp';
import { nanoid } from 'nanoid';

export default Vue.extend({
  name: 'BlockEditorTable',
  components: {
    ElementTable,
  },
  mixins: [BaseDocumentEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    contentAsModule() {
      return {
        element: {
          ...this.buffer.info.content,
        },
      };
    },
    content() {
      return this.buffer?.info?.content;
    },
    columns() {
      return this.content?.tableColumns || [];
    },
    rows() {
      return this.content?.tableRowData || [];
    },
  },
  watch: {
    content: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          Vue.set(this.buffer.info, 'content', {
            type: 'element.table',
            tableUseQuery: false,
            tableQuery: '',
            tableRowData: [{ title: 'Titel' }],
            tableColumns: [{ title: 'Titel', path: 'title' }],
          });
          this.buffer.info.title = this.$t('list.table');
        }
      },
    },
  },
  methods: {
    onAddRow() {
      const row = this.columns.reduce((acc, column) => {
        acc[column.path] = '';
        return acc;
      }, {});
      this.buffer.info.content.tableRowData.push(row);
      this.immediateSave('info');
    },
    onRemoveRow(index) {
      this.buffer.info.content.tableRowData.splice(index, 1);
      this.immediateSave('info');
    },
    onAddColumn() {
      const newIndex = this.buffer.info.content.tableColumns.length + 1;
      this.buffer.info.content.tableColumns.push({
        title: this.$t('element.table.column') + ' ' + newIndex,
        path: 'column' + nanoid(5),
      });
      for (const row of this.buffer.info.content.tableRowData) {
        set('column' + newIndex, '', row);
      }
      this.immediateSave('info');
    },
    onRemoveColumn(index) {
      this.buffer.info.content.tableColumns.splice(index, 1);
      this.immediateSave('info');
    },
    onUpdateCellContent({ html, rowIndex, columnIndex }) {
      const column = this.columns[columnIndex];
      this.buffer.info.content.tableRowData[rowIndex][column.path] = html;
      this.immediateSave('info');
    },
    onUpdateColumnContent({ content, index }) {
      console.log('onUpdateColumnContent', content, index);
      this.buffer.info.content.tableColumns[index].title = content;
      this.immediateSave('info');
    },
  },
});
