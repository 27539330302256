<template>
  <div class="flex flex-col gap-4 p-4 w-full max-w-xl mx-auto">
    <p class="lead">
      {{ subtitle }}
    </p>

    <div class="flex justify-center gap-4">
      <BaseButton color="error" rounded @click="performLogout"
        >{{ $t('logout.logout') }}
      </BaseButton>
      <BaseButton rounded @click="close">{{ $t('general.close') }} </BaseButton>
    </div>

    <UpgradeForm></UpgradeForm>
  </div>
</template>

<script>
import UpgradeForm from '@/features/account/UpgradeForm.vue';
import { defineComponent, computed } from '@vue/composition-api';
import { useFillip, useI18n, useLayoutArea, useAuth } from '@/composables';

export default defineComponent({
  name: 'Upgrade',
  components: {
    UpgradeForm,
  },
  setup(props) {
    const { $user } = useFillip();
    const { tr } = useI18n();
    const { performLogout } = useAuth();

    const { close } = useLayoutArea('dialog');

    const pending = computed(() => $user.value.isVerified == 'pending');

    const subtitle = computed(() => {
      if (pending.value) {
        return tr('logout.emailNotVerified');
      }
      return tr('logout.noUserAccount');
    });

    return {
      performLogout,
      pending,
      subtitle,
      close,
    };
  },
});
</script>
