// A module to make this entities html part into a separate 3D sheet
import { ModuleTypeMeta } from './../../common';

export interface ModuleSheet {
  orientTowardsCamera: boolean;
  containerPointerNone?: boolean;
  scriptedValue?: boolean;
  value?: boolean | string;
}

export const ModuleSheetMeta: ModuleTypeMeta<ModuleSheet> = {
  default: {
    scriptedValue: false,
    orientTowardsCamera: false,
    value: ':true',
  },
  icon: 'sticky-note',
};
