





















import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import InputMedia from '@/components/input/media/InputMedia.vue';

export default {
  name: 'LoaderEditor',
  components: {
    InputMedia,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, data, save } = useSave(props, {
      path: 'model',
    });

    return {
      buffer,
      data,
      save,
    };
  },
  computed: {
    library() {
      return [
        { header: this.$t('model.loader.header3D') },
        {
          text: this.$t('model.loader.options.littleTokyo'),
          value: `${process.env.MINIO_URL}/media/models/LittlestTokyo.glb`,
        },
        {
          text: this.$t('model.loader.options.ship'),
          value: `${process.env.MINIO_URL}/media/models/ship_in_a_bottle/scene.gltf`,
        },
        {
          text: this.$t('model.loader.options.expressiveRobot'),
          value: `${process.env.MINIO_URL}/media/models/RobotExpressive.glb`,
        },
        { divider: true },
        { header: this.$t('model.loader.options.headerMaps') },
        {
          text: this.$t('model.loader.options.cartoonWorld'),
          value: `${process.env.MINIO_URL}/media/models/cartoon_low_poly_world_map/scene.gltf`,
        },
        {
          text: this.$t('model.loader.options.floatingIslands'),
          value: `${process.env.MINIO_URL}/media/models/floating-islands.glb`,
        },
      ];
    },
  },
  methods: {
    changeMediaSource(newBuffer) {
      this.buffer = newBuffer;
      this.save();
    },
  },
};
