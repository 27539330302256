
















import Vue from 'vue';
import clone from 'rfdc/default';
import { deepEqual } from 'fast-equals';
import { BaseModuleEditor } from '@/features/editor/base/BaseModuleEditor';

export default Vue.extend({
  name: 'InputVector3',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({ x: 0, y: 0, z: 0 }),
    },
  },
  data() {
    return {
      buffer: null,
    };
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (deepEqual(this.buffer, newValue)) {
          return;
        }
        this.buffer = clone(newValue);
      },
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.buffer);
    },
    onInput() {
      this.$emit('input', this.buffer);
    },
    onBlur($event) {
      this.$emit('blur', $event);
    },
  },
});
