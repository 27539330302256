import { ref, computed, watch, Ref, ComputedRef } from '@vue/composition-api';
import { isEqual, get } from 'lodash';
import clone from 'rfdc/default';
import { useData, useFillip } from '@/composables';
import { deepEqual } from '@fillip/api';

interface UseSaveOptions {
  path: string;
  defaultValue: any;
  convertToBuffer: (input: any) => any;
  convertFromBuffer: (input: any) => any;
  checkEqualityBeforeSave: boolean;
  removeIfNull: boolean; // Allows null to be stored, otherwise null means removed
}

const DefaultUseSaveOptions: UseSaveOptions = {
  path: '',
  defaultValue: null,
  convertToBuffer: clone,
  convertFromBuffer: (x) => x,
  checkEqualityBeforeSave: true,
  removeIfNull: false,
};

export function useSave<T = any>(
  props: any,
  options: Partial<UseSaveOptions> = {},
) {
  options = { ...DefaultUseSaveOptions, ...options };
  const { fiStoreDispatcher } = useFillip();

  // TODO: Infer buffer type from path
  const buffer: Ref<T> = ref(null);

  const { getData } = useData();

  const data: ComputedRef<T> = computed(() => {
    const document = getData(props.id);
    if (!document) return null;
    if (options.path == '') return document;
    return get(document, options.path, null);
  });

  watch(
    data,
    (newValue, oldValue) => {
      if (deepEqual(newValue, oldValue)) return;
      if (newValue == null) {
        buffer.value = options.convertToBuffer(options.defaultValue);
      } else {
        buffer.value = options.convertToBuffer(newValue);
      }
    },
    { immediate: true },
  );

  const save = () => {
    const dataToSave = options.convertFromBuffer(buffer.value);
    if (options.checkEqualityBeforeSave && isEqual(dataToSave, data.value))
      return;
    return fiStoreDispatcher.updatePath(
      'data',
      props.id,
      options.path,
      dataToSave,
      options.removeIfNull,
    );
  };

  const reset = () => {
    buffer.value = options.convertToBuffer(data.value);
  };

  const isDirty = computed(() => {
    return deepEqual(buffer.value, data.value);
  });

  return { save, buffer, data, reset, isDirty };
}
