import { computed, watch } from '@vue/composition-api';
import { useData, useVolatileData } from '@/composables';

export const useStepper = (props: any) => {
  const { getData, updateProperties, pushData } = useData();
  const { getVolatileProp } = useVolatileData();

  const id = computed(() => props.id);
  const doc = computed(() => getData(id.value));
  const properties = computed(() => doc.value?.properties || {});
  const phase = computed(() => (properties.value.phase.value as number) || 0);
  const phases = computed(() =>
    getData((properties.value?.phases?.value as string) || ''),
  );
  const numPhases = computed(() => phases.value?.list?.items.length || 0);

  const phasesList = computed(() =>
    (phases.value?.list?.items || []).map((item, value) => {
      const data = getData(item.id);
      const text = data.info.title || '';
      return { value, text };
    }),
  );
  const activePhaseId = computed(
    () => phases.value?.list?.items[phase.value]?.id,
  );
  const activePhase = computed(() => {
    return getData(activePhaseId.value);
  });

  const isOnSite = computed(() => {
    return getVolatileProp('stage', 'isOnSite', false);
  });

  const setPhase = (index) => {
    updateProperties(id.value, {
      phase: {
        type: 'number',
        value: index,
      },
      phaseId: {
        type: 'id',
        value: activePhaseId.value,
      },
    });
  };

  const next = () => {
    if (phase.value + 1 < numPhases.value) setPhase(phase.value + 1);
  };
  const back = () => {
    if (phase.value - 1 >= 0) setPhase(phase.value - 1);
  };

  const addPhase = async () => {
    await pushData(phases.value.id, 'collection', {
      info: {
        title: 'Neue Phase',
        icon: '',
      },
    });
    setPhase(getData(phases.value.id).list.items.length - 1);
  };

  return {
    phase,
    numPhases,
    phasesList,
    setPhase,
    next,
    back,
    addPhase,
    activePhase,
    isOnSite,
  };
};
