




















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseLayer',
  props: {
    color: String,
    overlay: { type: Boolean, default: false },
    opacity: { type: Number, default: 1 },
    transition: { type: String, default: 'fade' },
  },
});
