import {
  InvalidId,
  InvalidObject,
  ObjectWithIdAlreadyExists,
  RootNotDeletable,
  Id,
  rootDataId,
  rootTemplateId,
  templateDirectoryId,
  ModuleListItem,
  localPrefix,
} from '@fillip/api';
import { deepEqual } from 'fast-equals';
import clone from 'rfdc/default';
import { merge, omit, pick, set, unset } from 'lodash';
import { useStateBus, StateBus } from '@fillip/api';

export interface FillipStoreMethods {
  db(): Record<string, any>;
  getCollectionEntries(
    collection: string,
    local?: boolean,
  ): Record<string, any>[];
  getCollection(collection: string, local?: boolean): Record<string, any>[];
  getCollectionIds(collection: string, local?: boolean): Id[];
  getDocument(collection: string, id: Id): Record<string, any>;
  removeDocument(
    collection: string,
    id: Id,
    allowRootOverwrite?: boolean,
  ): Promise<void>;
  clearChildren(
    collection: string,
    id: Id,
    allowRootOverwrite?: boolean,
  ): Promise<void>;
  setDocument(
    collection: string,
    id: Id,
    value: Record<string, any>,
  ): Promise<void>;
  pushDocument(
    collection: string,
    id: Id,
    value: Record<string, any>,
    forceOverwrite: boolean,
  ): Promise<Id>;
  insertDocument(
    collection: string,
    id: Id,
    position: number,
    value: Record<string, any>,
  ): Promise<Id>;
  patchDocument(
    collection: string,
    id: Id,
    patch: Record<string, any>,
  ): Promise<void>;
  mergeDocument(
    collection: string,
    id: Id,
    patch: Record<string, any>,
  ): Promise<void>;
  patchPaths(collection, id: Id, paths: Record<string, any>): Promise<void>;
  update(
    collection: string,
    id: Id,
    newDocument: Record<string, any>,
  ): Promise<void>;
  updatePath(
    collection: string,
    id: Id,
    path: string,
    value: Record<string, any>,
    removeIfNull: boolean,
  ): Promise<void>;
  updatePaths(
    collection: string,
    id: Id,
    updates: { path: string; value: Record<string, any> }[],
    removeIfNull: boolean,
  ): Promise<void>;
  setBatch(collection: string, values: Record<string, any>[]): Promise<void>;
}

export class FillipStore implements FillipStoreMethods {
  constructor(public store: any, public vuexModuleName: string) {}

  db(): Record<string, any> {
    return this.store.state[this.vuexModuleName].db;
  }
  getCollectionEntries(collection: string): Record<string, any>[] {
    if (!this.db()[collection]) return [];
    return Object.entries(this.db()[collection]);
  }
  getCollection(collection: string): Record<string, any>[] {
    if (!this.db()[collection]) return [];
    return Object.values(this.db()[collection]);
  }
  getCollectionIds(collection: string): Id[] {
    if (!this.db()[collection]) return [];
    return Object.keys(this.db()[collection]);
  }
  getDocument(collection: string, id: Id): Record<string, any> {
    if (!this.db()[collection]) return null;
    return this.db()[collection][id];
  }
  async removeDocument(
    collection: string,
    id: Id,
    allowRootOverwrite = false,
  ): Promise<void> {
    if (
      !allowRootOverwrite &&
      [rootDataId, rootTemplateId, templateDirectoryId].includes(id)
    )
      throw RootNotDeletable();

    const document = this.getDocument(collection, id);
    if (!document) throw InvalidId(id, 'removeDocument');

    const parentId = document.parentId;
    if (parentId) this._detachFromParent(collection, parentId, id);

    this._removeChildrenAndSelf(collection, id, true);
  }
  async clearChildren(
    collection: string,
    id: Id,
    allowRootOverwrite: boolean = false,
  ): Promise<void> {
    if (
      !allowRootOverwrite &&
      (id == rootDataId || id == rootTemplateId || id == templateDirectoryId)
    )
      throw RootNotDeletable();

    const document = this.getDocument(collection, id);
    if (!document) throw InvalidId(id, 'clearChildren');

    const newParent = {
      ...document,
      list: {
        items: [],
      },
    };
    this.setDocument(collection, id, newParent);

    this._removeChildrenAndSelf(collection, id, false);
  }
  _detachFromParent(collection: string, parentId: Id, id: Id) {
    const parent = this.getDocument(collection, parentId);
    if (!parent) throw InvalidId(parentId, 'detachFromParent');
    // TODO: Switch to lodash methods for immutable overwrites
    const newParent = {
      ...parent,
      list: {
        ...parent.list,
        items: parent.list.items.filter(
          (item: ModuleListItem) => item.id != id,
        ),
      },
    };
    this.setDocument(collection, parentId, newParent);
  }
  _removeChildrenAndSelf(
    collection: string,
    id: Id,
    removeSelf: boolean = true,
  ) {
    const document = this.getDocument(collection, id);
    if (!document) return;
    const items = document?.list?.items;
    if (items) {
      items.forEach((item) =>
        this._removeChildrenAndSelf(collection, item.id, true),
      );
    }
    if (removeSelf) {
      if (!this.db()[collection] || !this.db()[collection][id]) return null;

      this.store.commit(`${this.vuexModuleName}/REMOVE`, {
        collection,
        id,
      });
    }
  }
  async setDocument(
    collection: string,
    id: Id,
    value: Record<string, any>,
    forceOverwrite: boolean = false,
  ): Promise<void> {
    if (!value.id) throw InvalidId(value.id, 'setDocument');
    const currentDocument = this.getDocument(collection, id);
    if (!forceOverwrite && deepEqual(value, currentDocument)) return;
    return this.store.commit(`${this.vuexModuleName}/SET`, {
      collection,
      id,
      value,
    });
  }
  async pushDocument(
    collection: string,
    parentId: Id,
    value: Record<string, any>,
    forceOverwrite: boolean = false,
  ): Promise<Id> {
    const parent = this.getDocument(collection, parentId);
    if (!parent) throw InvalidId(parentId, 'pushDocument->parent');
    if (!value.id) throw InvalidId(value.id, 'pushDocument->docToInsert');
    if (!forceOverwrite && this.getDocument(collection, value.id))
      throw ObjectWithIdAlreadyExists(value.id);

    // Better we ensure this, so we don't get resource leaks
    if (value.parentId != parentId) {
      value.parentId = parentId;
    }

    const item: ModuleListItem = { id: value.id };
    // TODO: validate data
    const newParent = {
      ...parent,
      list: {
        ...parent.list,
        items: [...(parent?.list?.items || []), item],
      },
    };
    this.setDocument(collection, value.id, value, forceOverwrite);
    this.setDocument(collection, parentId, newParent, forceOverwrite);
    return value.id;
  }
  async insertDocument(
    collection: string,
    id: Id,
    position: number,
    value: Record<string, any>,
  ): Promise<Id> {
    const parent = this.getDocument(collection, id);
    if (!parent) throw InvalidId(id, 'insertDocument->parent');
    if (!value.id) throw InvalidId(value.id, 'insertDocument->docToInsert');
    if (this.getDocument(collection, value.id))
      throw ObjectWithIdAlreadyExists(value.id);

    // Better we ensure this, so we don't get resource leaks
    if (value.parentId != id) {
      value.parentId = id;
    }

    const item: ModuleListItem = { id: value.id };
    // TODO: validate data
    const newListItems = [...(parent?.list?.items || [])];
    newListItems.splice(position, 0, item);
    const newParent = {
      ...parent,
      list: {
        ...parent.list,
        items: newListItems,
      },
    };
    this.setDocument(collection, value.id, value);
    this.setDocument(collection, id, newParent);
    return value.id;
  }
  patchDocument(
    collection: string,
    id: Id,
    patch: Record<string, any>,
  ): Promise<void> {
    const document = this.getDocument(collection, id) || {};
    if (!document) throw InvalidId(id, 'patchDocument');

    const patchedDoc = merge(clone(document), patch);
    if (deepEqual(patchedDoc, document)) return;

    this.setDocument(collection, id, patchedDoc);
  }
  async mergeDocument(
    collection: string,
    id: Id,
    patch: Record<string, any>,
  ): Promise<void> {
    const document = this.getDocument(collection, id);
    if (!document) throw InvalidId(id, 'mergeDocument');

    const newDocument = merge({}, document, patch);
    this.setDocument(collection, id, newDocument);
  }
  async patchPaths(
    collection: string,
    id: Id,
    paths: Record<string, any>,
  ): Promise<void> {
    const document = this.getDocument(collection, id);
    if (!document) throw InvalidId(id, 'patchPaths');

    const newDocument = {
      ...omit(
        document,
        Object.keys(paths).filter((key) => paths[key] == null),
      ),
      ...pick(
        paths,
        Object.keys(paths).filter((key) => paths[key] != null),
      ),
    };
    this.setDocument(collection, id, newDocument);
  }
  async update(
    collection: string,
    id: Id,
    newDocument: Record<string, any>,
  ): Promise<void> {
    const document = this.getDocument(collection, id);
    if (!document) throw InvalidId(id, 'update');

    if (newDocument.id != id) throw InvalidObject();

    this.setDocument(collection, id, newDocument);
  }
  async updatePath(
    collection: string,
    id: Id,
    path: string,
    value: Record<string, any>,
    removeIfNull: boolean = true,
  ): Promise<void> {
    if (path == '') {
      if (value == null) {
        return this.removeDocument(collection, id);
      }
      return this.update(collection, id, value);
    }
    const document = this.getDocument(collection, id);
    if (!document) throw InvalidId(id, 'updatePath');

    const newDocument = clone(document);
    if (removeIfNull && value == null) {
      unset(newDocument, path);
    } else {
      set(newDocument, path, value);
    }
    this.setDocument(collection, id, newDocument);
  }
  async updatePaths(
    collection: string,
    id: Id,
    updates: { path: string; value: Record<string, any> }[],
    removeIfNull: boolean = true,
  ): Promise<void> {
    if (!updates?.length) return;
    const document = this.getDocument(collection, id);
    if (!document) throw InvalidId(id, 'updatePath');
    const newDocument = clone(document);
    updates.forEach(({ path, value }) => {
      if (path) {
        if (removeIfNull && value == null) {
          unset(newDocument, path);
        } else {
          set(newDocument, path, value);
        }
      }
    });

    this.setDocument(collection, id, newDocument);
  }
  async setBatch(
    collection: string,
    values: Record<string, any>[],
  ): Promise<void> {
    if (
      !this.db()[collection] ||
      !values ||
      !Array.isArray(values) ||
      values.length < 1
    )
      return null;
    this.store.commit(`${this.vuexModuleName}/SET_BATCH`, {
      collection,
      values,
    });
  }
}

export class FillipStoreDispatcher implements FillipStoreMethods {
  public stateBus: StateBus;
  constructor(public self: any) {
    this.stateBus = useStateBus('localStore');
  }

  db(local: boolean = false): Record<string, any> {
    if (local) return this.self.$fiStore.db();
    return this.self.$store.getters.communityState;
  }
  getCollectionEntries(
    collection: string,
    local: boolean = false,
  ): Record<string, any>[] {
    if (local) return this.self.$fiStore.getCollectionEntries(collection);
    return Object.entries(
      this.self.$store.getters.communityState?.[collection],
    );
  }
  getCollection(
    collection: string,
    local: boolean = false,
  ): Record<string, any>[] {
    if (local) return this.self.$fiStore.getCollection();
    return Object.values(this.self.$store.getters.communityState?.[collection]);
  }
  getCollectionIds(collection: string, local: boolean = false): Id[] {
    if (local) return this.self.$fiStore.getCollectionIds();
    return Object.keys(this.self.$store.getters.communityState?.[collection]);
  }
  getDocument(collection: string, id: Id): Record<string, any> {
    if (!id || typeof id != 'string') return;
    if (id.startsWith(localPrefix)) {
      return this.self.$fiStore.getDocument(collection, id);
    }
    return this.self.$store.getters.communityState?.[collection]?.[id];
  }
  async removeDocument(
    collection: string,
    id: Id,
    allowRootOverwrite: boolean = false,
  ): Promise<void> {
    if (!id) return;
    if (id.startsWith(localPrefix))
      return this.self.$fiStore
        .removeDocument(collection, id, allowRootOverwrite)
        .then((result) => this.stateBus.requestUpdate('local:removeDocument'));
    return this.self.$invoke(
      'removeDocument',
      collection,
      id,
      allowRootOverwrite,
    );
  }
  async clearChildren(
    collection: string,
    id: Id,
    allowRootOverwrite: boolean = false,
  ): Promise<void> {
    if (!id) return;
    if (id.startsWith(localPrefix))
      return this.self.$fiStore
        .clearChildren(collection, id, allowRootOverwrite)
        .then((result) => this.stateBus.requestUpdate('local:clearChildren'));
    return this.self.$invoke(
      'clearChildren',
      collection,
      id,
      allowRootOverwrite,
    );
  }
  async setDocument(
    collection: string,
    id: Id,
    value: Record<string, any>,
  ): Promise<void> {
    if (!id) return;
    if (id.startsWith(localPrefix))
      return this.self.$fiStore
        .setDocument(collection, id, value)
        .then((result) => this.stateBus.requestUpdate('local:setDocument'));
    return this.self.$invoke('setDocument', collection, id, value);
  }
  async pushDocument(
    collection: string,
    id: Id,
    value: Record<string, any>,
    forceOverwrite: boolean = false,
  ): Promise<Id> {
    if (!id) return;
    if (id.startsWith(localPrefix))
      return this.self.$fiStore
        .pushDocument(collection, id, value, forceOverwrite)
        .then((result) => this.stateBus.requestUpdate('local:pushDocument'));
    return this.self.$invoke(
      'pushDocument',
      collection,
      id,
      value,
      forceOverwrite,
    );
  }
  async insertDocument(
    collection: string,
    id: Id,
    position: number,
    value: Record<string, any>,
  ): Promise<Id> {
    if (!id) return;
    if (id.startsWith(localPrefix))
      return this.self.$fiStore
        .pushDocument(collection, id, position, value)
        .then((result) => this.stateBus.requestUpdate('local:insertDocument'));
    return this.self.$invoke('insertDocument', collection, id, position, value);
  }
  async patchDocument(
    collection: string,
    id: Id,
    patch: Record<string, any>,
  ): Promise<void> {
    if (!id) return;
    if (id.startsWith(localPrefix))
      return this.self.$fiStore
        .patchDocument(collection, id, patch)
        .then((result) => this.stateBus.requestUpdate('local:patchDocument'));
    return this.self.$invoke('patchDocument', collection, id, patch);
  }
  async mergeDocument(
    collection: string,
    id: Id,
    value: Record<string, any>,
  ): Promise<void> {
    if (!id) return;
    if (id.startsWith(localPrefix))
      return this.self.$fiStore
        .mergeDocument(collection, id, value)
        .then((result) => this.stateBus.requestUpdate('local:mergeDocument'));
    return this.self.$invoke('mergeDocument', collection, id, value);
  }
  async patchPaths(
    collection: string,
    id: Id,
    paths: Record<string, any>,
  ): Promise<void> {
    if (!id) return;
    if (id.startsWith(localPrefix))
      return this.self.$fiStore
        .patchPaths(collection, id, paths)
        .then((result) => this.stateBus.requestUpdate('local:patchPaths'));
    return this.self.$invoke('patchPaths', collection, id, paths);
  }
  async update(
    collection: string,
    id: Id,
    newDocument: Record<string, any>,
  ): Promise<void> {
    if (!id) return;
    if (id.startsWith(localPrefix))
      return this.self.$fiStore
        .update(collection, id, newDocument)
        .then((result) => this.stateBus.requestUpdate('local:update'));
    return this.self.$invoke('update', collection, id, newDocument);
  }
  async updatePath(
    collection: string,
    id: Id,
    path: string,
    value: Record<string, any>,
    removeIfNull = true,
  ): Promise<void> {
    if (!id) return;
    if (id.startsWith(localPrefix))
      return this.self.$fiStore
        .updatePath(collection, id, path, value, removeIfNull)
        .then((result) => this.stateBus.requestUpdate('local:updatePath'));
    return this.self.$invoke(
      'updatePath',
      collection,
      id,
      path,
      value,
      removeIfNull,
    );
  }
  async updatePaths(
    collection: string,
    id: Id,
    updates: { path: string; value: Record<string, any> }[],
    removeIfNull: boolean = true,
  ): Promise<void> {
    if (!id) return;
    if (id.startsWith(localPrefix))
      return this.self.$fiStore
        .updatePaths(collection, id, updates, removeIfNull)
        .then((result) => this.stateBus.requestUpdate('local:updatePaths'));
    return this.self.$invoke(
      'updatePaths',
      collection,
      id,
      updates,
      removeIfNull,
    );
  }
  async setBatch(
    collection: string,
    values: Record<string, any>[],
    local: boolean = false,
  ): Promise<void> {
    if (local)
      return this.self.$fiStore
        .setBatch(collection, values)
        .then((result) => this.stateBus.requestUpdate('local:setBatch'));
    return this.self.$invoke('setBatch', collection, values);
  }
}
