import { DataDocument, fragmentsTemplateDirectoryId } from '@fillip/api';
export * from './TemplateFragmentHeader';
export * from './TemplateFragmentSimpleRoom';

import {
  TemplateFragmentHeader,
  TemplateFragmentHeaderChildren,
} from './TemplateFragmentHeader';
import {
  TemplateFragmentSimpleRoom,
  TemplateFragmentSimpleRoomChildren,
} from './TemplateFragmentSimpleRoom';
import { TemplateFragmentSpinner } from './TemplateFragmentSpinner';
import {
  FragmentEmptyStateCover,
  FragmentEmptyStateCoverChildren,
} from './FragmentEmptyStateCover';

export const FragmentTemplates: DataDocument[] = [
  TemplateFragmentHeader,
  TemplateFragmentSimpleRoom,
  TemplateFragmentSpinner,
  FragmentEmptyStateCover,
];

export const DefaultFragmentTemplates: DataDocument[] = [
  TemplateFragmentHeader,
  ...TemplateFragmentHeaderChildren,
  TemplateFragmentSimpleRoom,
  ...TemplateFragmentSimpleRoomChildren,
  TemplateFragmentSpinner,
  FragmentEmptyStateCover,
  ...FragmentEmptyStateCoverChildren,
];

export const FragmentsTemplateDirectory: DataDocument = {
  id: fragmentsTemplateDirectoryId,
  info: {
    title: 'Fragments',
    icon: 'puzzle-piece',
    isProtected: true,
  },
  tag: {
    tag: 'collection',
  },
  list: {
    items: FragmentTemplates.map((template) => ({
      id: template.id,
    })),
  },
};
