








































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { nanoid } from 'nanoid';

export default Vue.extend({
  name: 'RolesEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'roles',
    });

    return {
      buffer,
      save,
    };
  },
  computed: {
    roles() {
      return Object.values(this.buffer.roles);
    },
  },
  methods: {
    addRole() {
      const newRole = {
        key: nanoid(6),
        title: 'New Role',
        documents: [],
      };
      this.setRole(newRole.key, newRole);
    },
    setRole(key, role) {
      Vue.set(this.buffer.roles, key, role);
      this.save();
    },
    setKey(role) {
      Vue.delete(this.buffer.roles, role.key);
      const newRole = {
        ...role,
        key: role.newKey,
      };
      delete newRole.newKey;
      Vue.set(this.buffer.roles, newRole.key, newRole);
      this.save();
    },
    setRoleMembers(key, members) {
      this.buffer.roles[key].documents = members;
      this.save();
    },
    removeRole(role) {
      const { [role]: _, ...rest } = this.buffer.roles;
      this.buffer.roles = rest;
      this.save();
    },
  },
});
