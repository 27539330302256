import { BaseObject3D } from '../core';
import { DirectionalLight, AnimationMixer, Clock } from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export const ModelLoader = {
  name: 'ModelLoader',
  mixins: [BaseObject3D],
  data() {
    return {
      directionalLight: null,
      model: null,
      mixer: null,
      clock: null,
    };
  },
  // ! needs computed this.size
  async mounted() {
    this.size = {
      width: 1000,
      height: 1000,
      depth: 1000,
    };
  },
  created() {
    this.directionalLight = new DirectionalLight(0xffffff, 1.4);
    // this.directionalLight = new PointLight(0xffffff, 1.4);
    this.directionalLight.position.set(0, 300, 0);
  },
  beforeDestroy() {
    this.clear();
  },
  computed: {
    objectUrl() {
      return this.modules.model.loaderSrc;
    },
    animate() {
      return this.modules.model.loaderAnimate;
    },
  },
  watch: {
    inactive: {
      immediate: true,
      handler(newValue) {
        this.$object3D.visible = !newValue;
        this.canvas.value.render('webGL', true);
      },
    },
    objectUrl: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.loadModelFromUrl();
        } else {
          this.clear();
        }
      },
    },
    animate: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.startAnimation();
        } else {
          this.stopAnimation();
        }
      },
    },
  },
  methods: {
    async loadModelFromUrl() {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/threejs-assets/draco/gltf/');
      const loader = new GLTFLoader();
      loader.setDRACOLoader(dracoLoader);
      try {
        const gltf = await loader.loadAsync(
          this.objectUrl,
          this.onLoadingProgress,
        );
        this.updateModel(gltf);
      } catch (error) {
        this.onLoadingError(error);
      }
    },
    updateModel(gltf) {
      if (this.$object3D) this.$object3D.clear();
      this.model = gltf;
      this.$object3D.add(this.directionalLight);

      this.$object3D.add(this.model.scene);

      if (
        this.animate &&
        this.model.animations &&
        this.model.animations.length > 0
      ) {
        this.startAnimation();
      }
      this.canvas.value.render('webGL', true);
    },
    onLoadingProgress(xhr) {
      //console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
    },
    startAnimation() {
      if (this.mixer) return;
      this.clock = new Clock();
      if (!this.model) return;
      this.mixer = new AnimationMixer(this.model.scene);
      for (const animation of this.model.animations) {
        this.mixer.clipAction(animation).play();
      }

      this.canvas.value.renderManager.onRender(
        this.id,
        this.renderInLoop(),
        'webGL',
      );
    },
    stopAnimation() {
      if (!this.mixer) return;
      this.mixer = null;
      this.canvas.value.renderManager.removeOnRender(this.id, 'webGL');
    },

    renderInLoop() {
      return () => {
        if (!this.mixer) return;
        const delta = this.clock.getDelta();
        this.mixer.update(delta);
        return true;
      };
    },
    onLoadingError(error) {
      console.log('error loading image');
      throw new Error(error);
    },
    clear() {
      if (this.$object3D) {
        this.$object3D.clear();
        this.canvas.value.render('webGL', true);
        if (this.mixer) {
          this.stopAnimation();
        }
        this.model = null;
      }
    },
  },
  render() {
    return null;
  },
};
