
















































import Vue from 'vue';
import { useStepper } from '@/features/stepper/useStepper';
import { useTools } from '@/features/stepper/useTools';
import { ref, toRef } from '@vue/composition-api';
import { useData } from '@/composables';
import ToolEditor from './ToolEditor.vue';
import PhaseEditor from './PhaseEditor.vue';

export default Vue.extend({
  components: {
    PhaseEditor,
    ToolEditor,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const stepper = useStepper(props);
    const toolId = toRef(props, 'id');
    const tools = useTools(toolId);

    const { getData } = useData();
    const selectedToolId = ref(null);

    return {
      ...stepper,
      ...tools,
      selectedToolId,
    };
  },
});
