




















































import Vue from 'vue';
import { useSave, usePermissions } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ParticipantStatus } from '@/features/main/core';

export default Vue.extend({
  name: 'ParticipantStatusEditor',
  mixins: [BaseModuleEditor],
  inject: ['participantStatus'],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { canSwitchPrototype } = usePermissions();
    const { buffer, save } = useSave(props, {
      path: 'participantStatus',
    });
    return {
      buffer,
      save,
      canSwitchPrototype,
    };
  },
  computed: {
    availableStatus() {
      if (!this.participantStatus || !this.participantStatus.value) return null;
      return this.participantStatus.value.statusOptions;
    },
    statusOptions() {
      if (!this.availableStatus) return [];
      return Object.values(this.availableStatus).map(
        (status: ParticipantStatus) => {
          return {
            icon: status.icon,
            text: this.$t(status.title),
            value: status.value,
          };
        },
      );
    },
    currentStatus() {
      if (!this.availableStatus || !this.availableStatus[this.buffer.status])
        return this.buffer.status;
      return this.availableStatus[this.buffer.status];
    },
    currentStatusTitle() {
      return this.$t(this.currentStatus.title);
    },
  },
});
