<template>
  <div>
    <v-subheader v-if="label">{{ label }}</v-subheader>
    <v-slider
      v-model="buffer"
      :dense="dense"
      :min="min"
      :max="max"
      :step="step"
      :thumb-label="thumbLabel"
      class="cursor-pointer"
      @change="$emit('change', buffer)"
    >
      <template #append>
        <v-text-field
          v-show="hideTextfield ? false : true"
          v-model.lazy="buffer"
          class="mt-0 pt-0"
          type="number"
          style="width: 3.5rem"
          :dense="dense"
          :min="min"
          :max="max"
          :step="step"
          :readonly="readonlyTextfield"
        />
      </template>
    </v-slider>
  </div>
</template>

<script>
export default {
  name: 'InputNumberSlider',
  props: {
    value: {
      type: [Number, String],
      default: 1,
    },
    label: {
      type: String,
      default: '',
    },
    inputField: {
      type: Boolean,
      default: true,
    },
    thumbLabel: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    readonlyTextfield: {
      type: Boolean,
      default: false,
    },
    hideTextfield: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buffer: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.buffer = Number(newValue);
      },
    },
    buffer: {
      immediate: true,
      handler(newValue) {
        this.$emit('input', Number(newValue));
      },
    },
  },
};
</script>
