




import VideoTrackConsumer from '@/features/video-conferencing/VideoTrackConsumer.vue';
import { useMediasoupConsumer } from '@/composables';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'MediasoupVideoConsumer',
  components: {
    VideoTrackConsumer,
  },
  props: {
    producerId: {
      type: String,
      required: true,
    },
    participantId: {
      type: String,
      required: true,
    },
    producerName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { consumer, track } = useMediasoupConsumer(props);

    return {
      consumer,
      track,
    };
  },
});
