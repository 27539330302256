import { DataDocument, PresetDefinition } from '@fillip/api';

const DefaultPage: DataDocument = {
  id: 'main',
  info: {
    title: 'New Page',
  },
  tag: {
    tag: 'page',
  },
  list: {
    items: [{ id: 'heading' }, { id: 'paragraph' }],
  },
};

const DefaultHeading1: DataDocument = {
  id: 'heading',
  parentId: 'main',
  info: {
    title: 'Heading 1',
  },
  tag: {
    tag: 'h1',
  },
};

const DefaultParagraph: DataDocument = {
  id: 'paragraph',
  parentId: 'main',
  info: {
    title: 'Lorem ipsum',
  },
  tag: {
    tag: 'text',
  },
};

export const PresetPage: PresetDefinition = {
  id: 'presetPage',
  title: 'Page',
  icon: 'file-alt',
  group: 'data',
  version: '0.1',
  documents: [DefaultPage, DefaultHeading1, DefaultParagraph],
};
