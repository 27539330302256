





















import Vue from 'vue';
import InputString from '../string/InputString.vue';
import InputStringArray from '../string/InputStringArray.vue';
import InputTextarea from '../string/InputTextarea.vue';
import InputNumber from '../number/InputNumber.vue';
import InputBoolean from '../boolean/InputBoolean.vue';
import InputId from '../id/InputId.vue';
import InputColor from '../color/InputColor.vue';
import InputScript from '../script/InputScript.vue';
import InputDocuments from '../documents/InputDocuments.vue';
import InputDatetime from '../datetime/InputDatetime.vue';
import InputEmbed from '../embed/InputEmbed.vue';
import InputMedia from '../media/InputMedia.vue';
import InputVector3 from '../vector3/InputVector3.vue';
import InputHtml from '../html/InputHtml.vue';
import InputStyledText from '../styled-text/InputStyledText.vue';
import InputSelect from '../select/InputSelect.vue';
import InputIcon from '../icon/InputIcon.vue';
import { BuiltinTypesMeta } from '@fillip/api';

import { cloneDeep } from 'lodash';

export const InputEditors = {
  string: InputString,
  stringArray: InputStringArray,
  textarea: InputTextarea,
  number: InputNumber,
  boolean: InputBoolean,
  id: InputId,
  color: InputColor,
  script: InputScript,
  documents: InputDocuments,
  participants: InputDocuments,
  datetime: InputDatetime,
  embed: InputEmbed,
  image: InputMedia,
  html: InputHtml,
  styledText: InputStyledText,
  vector3: InputVector3,
  select: InputSelect,
  icon: InputIcon,
};

const mediaTypes = {
  image: 'image',
  audio: 'audio',
  positionalAudio: 'audio',
  video360: 'video360',
};

export default Vue.extend({
  name: 'InputAny',
  props: {
    id: {
      type: String,
    },
    value: {
      type: Object,
      default: () => ({ type: 'string', value: '' }),
    },
    type: {
      type: String,
      default: 'string',
    },
    returnAs: {
      type: String, // 'any' | 'raw'
      default: 'any',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: [Object, Array],
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    editorComponent() {
      return InputEditors[this.value.type];
    },
    mediaType() {
      return mediaTypes[this.value.type] || '';
    },
  },
  watch: {
    type(newValue, oldValue) {
      if (newValue != oldValue) {
        this.setType(newValue);
      }
    },
  },
  methods: {
    setValue(newValue) {
      const valueToSet = {
        type: this.value.type,
        value: newValue,
      };
      this.$emit('input', valueToSet);
    },
    emitUpdate(newValue) {
      const valueToSet = {
        type: this.value.type,
        value: newValue,
      };
      this.$emit('change', valueToSet);
    },
    setType(newType) {
      if (this.value.type == newType) return;
      this.$emit('input', {
        type: newType,
        value: cloneDeep(BuiltinTypesMeta[newType].default),
      });
    },
  },
});
