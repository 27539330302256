

















































































































































































































































import ParticipantVideo from '@/features/video-conferencing/ParticipantVideo.vue';
import { useParticipants } from '@/composables';
import { GlobalPropsNode } from '@/plugins/global-props';
import { EvaluatedModuleVideoConferencing } from '@fillip/api';
import SelectListControl from '@/components/control-button/CommonControls/SelectListControl.vue';
import SelectDevices from '../video-conferencing/SelectDevices.vue';
import { usePermission } from '@vueuse/core';

export default {
  // Converted in branch remerge-vc-refactor
  name: 'Me',
  components: {
    ParticipantVideo,
    SelectListControl,
    SelectDevices,
  },
  mixins: [GlobalPropsNode],
  inject: ['videoConferencingManager', 'themeManager', 'participantStatus'],
  props: {
    size: {
      type: Number,
      default: 74,
    },
  },
  setup() {
    const { getAvatarUrl } = useParticipants();
    // const micPermission = usePermission('microphone');
    // const camPermission = usePermission('camera');
    return {
      getAvatarUrl,
      // micPermission,
      // camPermission,
    };
  },
  data() {
    return {
      showAdvancedControls: false,
      area: 'status',
    };
  },
  computed: {
    isLowVideoResolution() {
      return this.videoConferencingManager.value.isLowCameraResolution;
    },
    isMuted() {
      return this.videoConferencingManager.value.isMuted;
    },
    isCameraOn() {
      return this.videoConferencingManager.value.isCameraOn;
    },
    frameStyle() {
      return {
        width: this.size + 'px',
        height: this.size + 'px',
        borderColor: this.isMuted ? 'white' : this.borderColor,
        ...(this.isMuted
          ? {
              boxShadow: `0px 0px 1px 1px rgba(0, 0, 0, 0.2)`,
            }
          : {
              boxShadow: `0 0 4px 0px ${this.borderColor}`,
            }),
      };
    },
    borderColor() {
      let color = this.$me.properties?.personalColor?.value
        ? this.$me.properties.personalColor.value
        : this.status.color
        ? this.$vuetify.theme.currentTheme[this.status.color]
        : this.$vuetify.theme.currentTheme.secondary;
      if (typeof color != 'string' && color.base) {
        color = color.base;
      }
      return color;
    },
    isConferenceAudioMuted() {
      return this.videoConferencingManager.value.isConferenceAudioMuted;
    },
    vcSettings(): EvaluatedModuleVideoConferencing {
      return this.$getGlobalProp('videoConferencing');
    },
    avatar() {
      if (!this.$me) return;
      return this.getAvatarUrl(this.$me);
    },
    status() {
      return this.availableParticipantStatus[
        this.$me?.participantStatus?.status || 'online'
      ];
    },
    availableParticipantStatus() {
      return this.participantStatus.value.statusOptions;
    },
    participantStatusControl() {
      return this.$getGlobalProp('participantStatusControl') || {};
    },
    menuAreas() {
      const areas = [
        {
          title: this.$t('videoConference.status'),
          value: 'status',
          icon: 'user',
        },
      ];
      if (this.vcSettings) {
        if (this.vcSettings.microphone.state == 'ON') {
          areas.push({
            title: this.$t('videoConference.microphone'),
            value: 'microphone',
            icon: 'microphone',
          });
        }
        if (this.vcSettings.camera.state == 'ON') {
          areas.push({
            title: this.$t('videoConference.camera'),
            value: 'camera',
            icon: 'webcam',
          });
        }
        if (
          this.vcSettings.microphone.state == 'ON' ||
          this.vcSettings.camera.state == 'ON'
        ) {
          areas.push({
            title: this.$t('videoConference.speaker'),
            value: 'speaker',
            icon: 'volume',
          });
        }
      }
      return areas;
    },
    denseAppBar() {
      return this.themeManager.value.denseAppBar;
    },
    appBarHeight() {
      return this.themeManager.value.appBarHeight;
    },
  },
  mounted() {
    this.$store.commit('videoConference/MUTE_VIDEOCONFERENCE', false);
    window.addEventListener('keydown', (e) => {
      this.addKeyboardShortcuts(e);
    });
  },
  beforeDestroy() {
    window.removeEventListener('keydown', (e) => {
      this.addKeyboardShortcuts(e);
    });
  },
  methods: {
    toggleVideoResolution() {
      this.videoConferencingManager.value.toggleVideoResolution();
    },
    async toggleMicrophone() {
      this.videoConferencingManager.value.toggleMicrophone();
    },
    async toggleCamera() {
      this.videoConferencingManager.value.toggleCamera();
    },
    close() {
      this.showAdvancedControls = false;
      this.area = 'status';
    },
    toggleConferenceAudio() {
      this.videoConferencingManager.value.toggleConferenceAudio();
    },
    addKeyboardShortcuts(e) {
      if (e.shiftKey && e.altKey) {
        e.code == 'KeyA'
          ? this.toggleMicrophone()
          : e.code == 'KeyM'
          ? this.muteVideoconference()
          : e.code == 'KeyV'
          ? this.toggleCamera()
          : null;
      }
    },
    triggerAction(control) {
      if (control.action && ['trigger', 'toggle'].includes(control.type)) {
        control.action.bind(this)(this.status);
      } else return;
    },
    areaChanged(area) {
      this.area = area;
    },
    toggleAdvancedControls() {
      this.showAdvancedControls = !this.showAdvancedControls;
    },
    openProfileEditor() {
      this.$dialog.push({ name: 'ParticipantProfile' });
      this.toggleAdvancedControls();
    },
  },
};
