


















































































































































































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import {
  CursorOptions,
  ModuleVideoConferencingMeta,
  DisplaySurfaceOptions,
  ResizeModeOptions,
  RoomSizeOptions,
} from '@fillip/api';

export default Vue.extend({
  name: 'StreamsEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: 'videoConferencing',
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: props.path,
    });

    const cursorOptions = CursorOptions;
    const displaySurfaceOptions = DisplaySurfaceOptions;
    const resizeModeOptions = ResizeModeOptions;
    const roomSize = RoomSizeOptions;

    // TODO: Replace with EditorOption and EditorSections
    const headerMedium = 'font-bold overline text-gray-600';
    const description = 'caption uppercase text-gray-600';

    const toggleInherit = (shouldInherit: boolean, prod: string) => {
      if (!shouldInherit) {
        delete buffer.value[prod];
      } else {
        buffer.value[prod] = {
          channel: '',
          roomSize: '0.5',
          state: 'ON',
          settings: ModuleVideoConferencingMeta.default[prod].settings,
        };
      }
      save();
    };

    return {
      buffer,
      save,
      cursorOptions,
      displaySurfaceOptions,
      resizeModeOptions,
      roomSize,
      toggleInherit,
      headerMedium,
      description,
    };
  },
});
