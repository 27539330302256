import { DataId } from '../common';
import { ChatMessageDocument } from './chat-message.document';
import { DataDocument } from './data.document';
import { LocationDocument } from './location.document';

export * from './chat-message.document';
export * from './circle.document';
export * from './data.document';
export * from './modules';
export * from './location.document';

export interface VmState {
  chatMessages: {
    [id: string]: ChatMessageDocument;
  };
  data: Record<DataId, DataDocument>;
  locations: Record<DataId, LocationDocument>;
  rules: any[];
}
