

























































import Vue from 'vue';
import SchemaFieldsEditor from './SchemaFieldsEditor.vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleSchemaMeta } from '@fillip/api';
import clone from 'rfdc/default';

export default Vue.extend({
  name: 'SchemaEditor',
  components: {
    SchemaFieldsEditor,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'schema',
    });

    return {
      buffer,
      save,
    };
  },
  computed: {
    loadFromOptions() {
      return [
        { value: 'ownFields', text: 'Own Schema' },
        { value: 'fields', text: 'Child Schema' },
        { value: 'entities', text: 'Entity Schema' },
      ];
    },
  },
  // TODO: Add support for arbitary schema definitions
  // TODO: Add support for schema name and
  methods: {
    updateFields(fields, area) {
      this.buffer[area] = clone(fields);
      this.save();
    },
    init() {
      this.buffer = clone(ModuleSchemaMeta.default);
      this.save();
    },
    addLoadFrom() {
      if (!this.buffer.loadFrom) Vue.set(this.buffer, 'loadFrom', []);
      this.buffer.loadFrom.push({ loadFromId: '', loadFields: ['fields'] });
    },
    removeLoadFrom(index) {
      this.buffer.loadFrom.splice(index, 1);
    },
  },
});
