










import { defineComponent, computed, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'ColumnsLayout',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object as PropType<{ maxColumns: number }>,
      default: () => ({}),
    },
  },
  setup(props) {
    const defaultOptions = {
      maxColumns: 4,
    };

    const currentOptions = computed(() => ({
      ...defaultOptions,
      ...props.options,
    }));

    const nrOfColumns = computed(() =>
      props.items.length > currentOptions.value.maxColumns
        ? currentOptions.value.maxColumns
        : props.items.length,
    );

    return {
      currentOptions,
      nrOfColumns,
    };
  },
});
