









































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleVariablesMeta } from '@fillip/api';
import clone from 'rfdc/default';

export default Vue.extend({
  name: 'VariablesEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'variables',
    });

    const init = () => {
      buffer.value = clone(ModuleVariablesMeta.default);
      save();
    };

    const defaultItem = ModuleVariablesMeta.childDefault;

    const toggleEvaluate = (index: number) => {
      const variableDefinition = buffer.value.variables[index];
      if (!variableDefinition) return;
      buffer.value.variables[index].evaluate = variableDefinition.evaluate
        ? null
        : true;
      save();
    };

    const addItem = () => {
      buffer.value.variables.push(clone(defaultItem));
      save();
    };
    const removeItem = (index) => {
      buffer.value.variables.splice(index, 1);
      save();
    };

    return {
      buffer,
      save,
      init,
      toggleEvaluate,
      addItem,
      removeItem,
    };
  },
});
