import { ModuleTypeMeta } from '../../common';

export interface ModuleParticipantStatus {
  online: boolean;
  status?: string;
  seePrototype?: boolean;
}

export const ModuleParticipantStatusMeta: ModuleTypeMeta<ModuleParticipantStatus> =
  {
    restrictedToTags: ['participant'],
    default: {
      online: false,
      status: null,
      seePrototype: false,
    },
  };
