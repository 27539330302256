<template>
  <InputCode
    :value="buffer"
    v-bind="$props"
    language="html"
    :options="currentOptions"
    autofocus
    height="150px"
    @blur="onChange"
  />
</template>

<script>
import InputCode from '@/components/input/code/InputCode.vue';

export default {
  name: 'InputHtml',
  components: {
    InputCode,
  },
  props: {
    value: {
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      buffer: '',
      defaultOptions: {},
    };
  },
  computed: {
    currentOptions() {
      return { ...this.defaultOptions, ...this.options };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.buffer = newValue;
        }
      },
    },
  },
  methods: {
    async onChange(value) {
      this.$emit('input', value);
    },
  },
};
</script>
