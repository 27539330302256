import { DataDocument, toolsTemplateDirectoryId } from '@fillip/api';

export const TemplateToolVideo: DataDocument = {
  id: 'tool-videoconference',
  info: {
    title: 'Tool: Videokonferenz',
    icon: 'webcam',
  },
  tag: {
    tag: 'template',
  },
  parentId: toolsTemplateDirectoryId,
  list: {
    items: [
      {
        id: 'tool-participant',
      },
      {
        id: 'previous-button',
      },
      {
        id: 'participant-container',
      },
      {
        id: 'next-button',
      },
      {
        id: 'next-button-container',
      },
      {
        id: 'previous-button-container',
      },
      {
        id: 'audio-only-participants',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'previous-button-container',
        query: ':[data]',
        condition: ':$$showAll==false',
        identity: '',
      },
      {
        templateId: 'participant-container',
        query: ':[data]',
      },
      {
        templateId: 'next-button-container',
        query: ':[data]',
        condition: ':$$showAll==false',
        identity: '',
      },
      {
        templateId: 'audio-only-participants',
        query: ':$$audioOnlyParticipants',
        condition: '',
        identity: '',
      },
    ],
  },
  sheet: {
    orientTowardsCamera: false,
  },
  actions: {
    actions: [
      {
        name: 'Raise Hand',
        type: 'action.button',
        icon: 'hand-paper',
        color: 'rgb(234, 142, 0)',
        script:
          '$$isRaised ? removeDocument($$raisedHandId):createDocument($$raisedHandsCollectionId,page,$me.id)',
        id: 'default',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'pageSize',
        query:
          ':$viewport.width < 1536 ? ($viewport.width > 1280 ? 12 : $viewport.width > 1024 ? 10 : $viewport.width > 768 ? 8 : $viewport.width > 640 ? 6 :  4 ): null',
      },
      {
        identifier: 'pageValue',
        query: ":getVolatileProp('participantsStrip','pageNumber')||1",
      },
      {
        identifier: 'participantsAtScene',
        query: ':getOnlineParticipants |> where(atScene($route))',
      },
      {
        identifier: 'participantsAtSceneRoot',
        query: ':wrapInFunction($$participantsAtScene) |> where(hasNoFocus)',
      },
      {
        identifier: 'numParticipants',
        query: ':$$participantsAtSceneRoot.length',
      },
      {
        identifier: 'showAll',
        query: ':!$$pageSize || $$numParticipants <= $$pageSize',
      },
      {
        identifier: 'visibleParticipants',
        query:
          ':$$showAll ? $$participantsAtSceneRoot : pipe(wrapInFunction($$participantsAtSceneRoot), page($$pageValue, $$pageSize))',
      },
      {
        identifier: 'audioOnlyParticipants',
        query:
          ":differenceBy($$visibleParticipants,'id')($$participantsAtSceneRoot)",
      },
      {
        identifier: 'raisedHandId',
        query:
          ":$$isRaised ?getParticipantList($$raisedHandsCollectionId)()[0]?.id || null : ''",
      },
    ],
  },
  arrangement: {
    type: 'conditional',
    variants: [
      {
        condition: ':$$showAll',
        value: {
          type: 'arrangement.grid',
          gridColumns: '1fr',
          gridRows: '1fr',
        },
      },
      {
        condition: '',
        value: {
          type: 'arrangement.grid',
          gridColumns: '40 1fr 40',
          gridRows: '1fr',
        },
      },
    ],
  },
};

export const TemplateToolVideoChildren: DataDocument[] = [
  {
    id: 'tool-participant',
    parentId: 'tool-videoconference',
    info: {
      title: 'ParticipantVideo',
      icon: 'user',
      isProtected: true,
    },
    tag: {
      tag: 'template',
    },
    sheet: {
      orientTowardsCamera: false,
    },
    list: {
      items: [
        {
          id: 'hand-raised-container',
        },
        {
          id: 'hand-raised',
        },
      ],
    },
    variables: {
      variables: [
        {
          identifier: 'isHost',
          query: ":hasRole($roles)('host')($me)",
        },
        {
          identifier: 'raisedHandId',
          query:
            ':getParticipantList($$raisedHandsCollectionId)()[0]?.id || null',
        },
        {
          identifier: 'isRaised',
          query: ':$$raisedHandId?.lenght',
        },
        {
          identifier: 'isMe',
          query: ':data.id == $me.id',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'hand-raised-container',
          query: ':[data]',
          identity: '',
          condition: ':!$$disableRaised &&$$isRaised',
        },
      ],
    },
    element: {
      type: 'element.participant',
      participantId: ':id',
      participantShape: 'circle',
      alwaysShowName: false,
      participantVideo: true,
      participantAudio: true,
    },
    class: {
      class: '',
      elementClass: 'p-2',
    },
  },
  {
    id: 'hand-raised-container',
    info: {
      title: 'handRaisedContainer',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-participant',
    children: {
      default: [
        {
          templateId: 'hand-raised',
          query: ':[data]',
          condition: '',
          identity: '',
        },
      ],
    },
    sheet: {
      orientTowardsCamera: false,
    },
    class: {
      class: 'bg-gray-400 bg-opacity-70 w-24 h-24 rounded-full shadow-lg',
      elementClass: '',
    },
  },
  {
    id: 'hand-raised',
    info: {
      title: 'handRaised',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-participant',
    class: {
      class:
        'p-2 grid grid-flow-cols grid-cols-1 grid-rows-1 items-center content-center h-full w-full',
      elementClass: 'h-full w-full p-4 pr-6 text-warning',
    },
    listener: {
      listeners: {
        click: {
          script: "$$isHost || $$isMe?(removeDocument($$raisedHandId) ):''",
          context: {},
        },
      },
    },
    element: {
      type: 'element.icon',
      icon: 'hand-paper',
      size: 'large',
      color: 'rgb(241, 43, 43)',
    },
  },
  {
    id: 'previous-button',
    info: {
      title: 'PreviousButton',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-videoconference',
    element: {
      type: 'element.button',
      buttonText: '',
      buttonIcon: 'chevron-left',
      buttonEvent: 'click',
    },
    listener: {
      listeners: {
        click: {
          script:
            "setVolatileProp('participantsStrip','pageNumber',$$pageValue-1)",
          context: {},
        },
      },
    },
    class: {
      class: 'mt-4 flex justify-center',
      elementClass: 'bg-primary-lightest',
    },
  },
  {
    id: 'participant-container',
    info: {
      title: 'ParticipantContainer',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-videoconference',
    list: {
      items: [],
    },
    children: {
      default: [
        {
          templateId: 'tool-participant',
          query: ':$$visibleParticipants',
          identity: '',
          condition: '',
        },
      ],
    },
    arrangement: {
      type: 'arrangement.divide',
      divideAspectRatio: '1/1',
      divideStretch: false,
    },
    sheet: {
      orientTowardsCamera: false,
    },
  },
  {
    id: 'next-button',
    info: {
      title: 'NextButton',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-videoconference',
    listener: {
      listeners: {
        click: {
          script:
            "setVolatileProp('participantsStrip','pageNumber',$$pageValue+1)",
          context: {},
        },
      },
    },
    class: {
      class: 'mt-4 flex justify-center',
      elementClass: 'bg-primary-lightest',
    },
    element: {
      type: 'element.button',
      buttonText: '',
      buttonIcon: 'chevron-right',
      buttonEvent: 'click',
    },
  },
  {
    id: 'next-button-container',
    info: {
      title: 'NextButtonContainer',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-videoconference',
    children: {
      default: [
        {
          templateId: 'next-button',
          query: ':[data]',
          condition: ':!$$isLast',
          identity: '',
        },
      ],
    },
    sheet: {
      orientTowardsCamera: false,
    },
    variables: {
      variables: [
        {
          identifier: 'isLast',
          query: ':$$pageSize * $$pageValue >= $$numParticipants',
        },
      ],
    },
    class: {
      class: 'relative grid place-content-center pr-4',
      elementClass: '',
    },
  },
  {
    id: 'previous-button-container',
    info: {
      title: 'PreviousButtonContainer',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-videoconference',
    children: {
      default: [
        {
          templateId: 'previous-button',
          query: ':[data]',
          condition: ':!$$isLast ',
          identity: '',
        },
      ],
    },
    sheet: {
      orientTowardsCamera: false,
    },
    variables: {
      variables: [
        {
          identifier: 'isLast',
          query: ':$$pageValue == 1',
        },
      ],
    },
    class: {
      class: 'relative pl-4 grid place-content-center',
      elementClass: '',
    },
  },
  {
    id: 'audio-only-participants',
    parentId: 'tool-videoconference',
    info: {
      title: 'ParticipantAudioOnly',
      icon: 'user',
      isProtected: true,
    },
    tag: {
      tag: 'template',
    },
    list: {
      items: [],
    },
    sheet: {
      orientTowardsCamera: false,
    },
    class: {
      class: 'hidden',
      elementClass: '',
    },
    element: {
      type: 'element.participant',
      participantId: ':id',
      participantShape: 'circle',
      alwaysShowName: true,
      participantVideo: false,
      participantAudio: true,
      participantHideDetails: true,
    },
  },
];
