import { FillipVueClient } from '../client';
import { IFillipVueClientModule } from '../base.client';
import {
  User,
  LoginResponse,
  RegisterLocalRequest,
  UpdateEmailRequest,
  Role,
  type RegisterWithUsernameEmailAndPasswordRequest,
  type RegisterAnonymousFrontendRequest,
} from '@fillip/api';

export class FillipVueAuthClient extends IFillipVueClientModule {
  constructor(public root: FillipVueClient) {
    super('auth', root);

    this.store.watch(
      (state) => state.client.user,
      () => this.persist(),
    );
    this.store.watch(
      (state) => state.client.access_token,
      () => this.persist(),
    );
  }

  public get user(): User {
    return this.state.user;
  }

  public async updateUserName(username: string): Promise<User> {
    try {
      const { data: user } = await this.axios.patch(
        `${this.apiUrl}/users/${this.user.id}/info`,
        {
          username,
        },
      );

      this.setUser(user, this.state.access_token);
      return user;
    } catch (error) {
      this.handleException(error);
    }
  }

  // Use this to change an E-mail and the user already had a verified account. This keeps the old address until the user confirms the new one. So the user keeps access to data via a confirmed account at all times. For other use cases check [changeConfirmationAddress].
  public async updateEmail(emailData: UpdateEmailRequest): Promise<User> {
    try {
      emailData.pendingEmail = emailData.pendingEmail.toLowerCase();
      const { data: user } = await this.axios.patch(
        `${this.apiUrl}/users/${this.user.id}/email`,
        emailData,
      );

      this.setUser(user, this.state.access_token);

      return user;
    } catch (error) {
      this.handleException(error);
    }
  }

  public async registerWithUsernameEmailAndPassword(
    userData: RegisterWithUsernameEmailAndPasswordRequest,
  ): Promise<User> {
    // TODO: Figure out when to set which role
    // console.log('registerWithUsernameEmailAndPassword', this.state);
    if (!userData.role) userData.role = Role.PARTICIPANT;
    userData.email = userData.email.toLowerCase();
    try {
      const { data: user } = await this.axios.post(
        `${this.apiUrl}/auth/register/local`,
        userData,
      );

      this.setUser(user, null);

      return user;
    } catch (error) {
      this.handleException(error);
    }
  }

  public async registerAnonymous(
    userData: RegisterAnonymousFrontendRequest,
  ): Promise<void> {
    try {
      const {
        data: { user, access_token },
      } = await this.axios.post(`${this.apiUrl}/auth/register/anonymous`, {
        ...userData,
        role: userData.restrictCommunities ? Role.RESTRICTED : Role.PARTICIPANT,
      });

      this.setUser(user, access_token);
    } catch (error) {
      this.handleException(error);
    }
  }

  //should this return something?
  public async resendEmailVerification(email: string) {
    try {
      email = email.toLowerCase();
      //replace with success notification
      await this.axios.post(`${this.apiUrl}/auth/send-email-confirmation`, {
        email,
      });
    } catch (error) {
      this.handleException(error);
    }
  }

  public async confirmEmail(email: string, token: string) {
    try {
      const {
        data: { user, access_token },
      } = await this.axios.post(`${this.apiUrl}/auth/email-confirmation/`, {
        email,
        token,
      });

      this.setUser(user, access_token);
    } catch (error) {
      this.handleException(error);
    }
  }

  public routeToConnectWithGoogle(): void {
    window.location.href = `${this.apiUrl}/auth/register/google`;
  }

  public routeToConnectWithDiscord(): void {
    window.location.href = `${this.apiUrl}/auth/register/discord`;
  }

  public async loginWithJwt(jwtProvided: string): Promise<LoginResponse> {
    // TODO: Send jwt in body via post, not as get parameter url
    if (!jwtProvided) {
      this.setUser(null, null);
      return;
    }
    try {
      const {
        data: { user, access_token },
      } = await this.axios.get(`${this.apiUrl}/auth/login/${jwtProvided}`);

      this.setUser(user, access_token);

      return {
        user,
        access_token,
      };
    } catch (error) {
      // TODO: Differentiate between errors to decide whether to log out or not.
      // this.setUser(null, null);
      this.handleException(error);
    }
  }

  // error on email not verified
  public async loginWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<LoginResponse> {
    try {
      email = email.toLowerCase();
      const {
        data: { user, access_token },
      } = await this.axios.post(`${this.apiUrl}/auth/login`, {
        email,
        password,
      });

      this.setUser(user, access_token);

      return {
        user,
        access_token,
      };
    } catch (error) {
      console.warn('error in auth client from login: ', error);
      this.handleException(error);
    }
  }

  // FORGOT PASSWORD
  public async resetForgotPassword(newPassword: string, resetToken: string) {
    try {
      //replace with success notification
      const {
        data: { access_token },
      } = await this.axios.post(`${this.apiUrl}/auth/reset-password`, {
        newPassword,
        resetToken,
      });
      this.loginWithJwt(access_token);
    } catch (error) {
      this.handleException(error);
    }
  }

  // Request E-Mail to set new password
  public async sendForgotPasswordEmail(email: string) {
    try {
      email = email.toLowerCase();
      await this.axios.post(`${this.apiUrl}/auth/forgot-password`, {
        email,
      });
    } catch (error) {
      this.handleException(error);
    }
  }

  public async updatePassword(oldPassword: string, newPassword: string) {
    try {
      const {
        data: { user, access_token },
      } = await this.axios.patch(
        `${this.apiUrl}/users/${this.user.id}/password`,
        {
          oldPassword,
          newPassword,
        },
      );
      return user;
    } catch (error) {
      this.handleException(error);
    }
  }

  public async upgradeToUserAccount(upgradeData: RegisterLocalRequest) {
    try {
      upgradeData.email = upgradeData.email.toLowerCase();
      const { data: user } = await this.axios.patch(
        `${this.apiUrl}/users/${this.user.id}/upgrade`,
        upgradeData,
      );

      this.setUser(user, this.state.access_token);

      return user;
    } catch (error) {
      this.handleException(error);
    }
  }

  public async cancelRequest() {
    try {
      const { data: user } = await this.axios.patch(
        `${this.apiUrl}/users/${this.user.id}/cancel-request`,
      );

      this.setUser(user, this.state.access_token);

      return user;
    } catch (error) {
      this.handleException(error);
    }
  }

  // Use this to update E-Mail address right away, in case the user has mistyped or similar. It changes the E-Mail address on the user entity directly. Whereas the [updateEmail] persists the old E-Mail address, until the new one has been confirmed. For other use cases check [updateEmail]
  public async changeConfirmationAddress(email: string): Promise<User> {
    try {
      email = email.toLowerCase();
      const { data: user } = await this.axios.patch(
        `${this.apiUrl}/users/${this.user.id}/upgrade-email`,
        {
          email,
        },
      );

      this.setUser(user, this.state.access_token);

      return user;
    } catch (error) {
      this.handleException(error);
    }
  }

  public async logout(): Promise<void> {
    localStorage.removeItem(this.config.LOCAL_STORAGE_KEY);

    this.setUser(null, null);

    await this.root.realtime.disconnectAllCommunities();
    this.store.commit('client/CLEAR_ALL');
  }

  public restore() {
    const storedState = localStorage.getItem(
      this.root.config.LOCAL_STORAGE_KEY,
    );
    if (!storedState) return;
    const parsedState = JSON.parse(storedState);
    if (!parsedState) return;

    const { user, access_token } = parsedState;
    this.setUser(user, access_token);
  }

  private persist() {
    localStorage.setItem(
      this.root.config.LOCAL_STORAGE_KEY,
      JSON.stringify({
        user: this.state.user,
        access_token: this.state.access_token,
      }),
    );
  }

  private setUser(user: Object, access_token: string) {
    if (access_token) {
      // console.log('Set user with access_token:', access_token);
      this.axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      delete this.axios.defaults.headers.common.Authorization;
    }
    this.store.commit('client/SET_USER', { user, access_token });
  }

  public async deleteAccount() {
    await this.axios.delete(`${this.apiUrl}/users/${this.user.id}`);
    await this.logout();
  }

  public async logoutGlobally() {
    const { data: user } = await this.axios.patch(
      `${this.apiUrl}/users/${this.user.id}/logout-globally`,
    );
    this.logout();
    return user;
  }
}
