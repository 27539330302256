import { DataId, ModuleTypeMeta } from '../../common';

export interface ModuleListItem {
  id: DataId;
}

export interface ModuleList {
  items: ModuleListItem[];
}

export const ModuleListMeta: ModuleTypeMeta<ModuleList> = {
  default: {
    items: [],
  },
};
