













































































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import ConditionalEditorContainer from '../components/ConditionalEditorContainer.vue';

import GridEditor from '../arrangements/GridEditor.vue';
import DivideEditor from '../arrangements/DivideEditor.vue';
import RectangleEditor from '../arrangements/RectangleEditor.vue';
import BlockEditor from '../arrangements/BlockEditor.vue';
import FlexEditor from '../arrangements/FlexEditor.vue';
import CircleEditor from '../arrangements/CircleEditor.vue';

import { ModuleArrangementMeta } from '@fillip/api';
import { FillipCommands } from '@/features/main/core';
import { getCurrentInstance } from '@vue/composition-api';

export default Vue.extend({
  name: 'ArrangementEditor',
  components: {
    GridEditor,
    DivideEditor,
    RectangleEditor,
    BlockEditor,
    FlexEditor,
    CircleEditor,
    ConditionalEditorContainer,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const vm = (getCurrentInstance() as any).proxy as Required<FillipCommands>;

    const { buffer, save } = useSave(props, {
      path: 'arrangement',
    });

    const items = Object.values(ModuleArrangementMeta.types).map((type) => ({
      text: vm.$t(`${type.type}.title`),
      value: type.type,
    }));

    const setType = (type: string, index: number): void => {
      if (index == 0 || index) {
        buffer.value.variants[index].value =
          ModuleArrangementMeta.types[type].default;
      } else {
        buffer.value = ModuleArrangementMeta.types[type].default;
      }
      save();
    };

    return {
      buffer,
      save,
      items,
      setType,
    };
  },
});
