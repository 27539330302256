import { DataDocument, PresetDefinition } from '@fillip/api';

const RevealInstance: DataDocument = {
  id: 'RevealInstance',
  info: {
    title: 'Reveal Presentation',
    icon: 'presentation',
    toolEditing: {
      preferredArrangement: 'side-by-side',
      allowedChildTypes: ['markdown'],
      allowMultipleChildren: false,
      editors: {
        title: {
          id: 'RevealInstance',
        },
        properties: { id: 'RevealInstance', title: 'Einstellungen' },
        list: { id: 'RevealInstance', title: 'Inhalt' },
      },
    },
  },
  tag: {
    tag: 'template',
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  list: {
    items: [
      {
        id: 'EmptyMarkdown',
      },
    ],
  },
  element: {
    type: 'element.reveal',
    revealContent: ":getDoc('EmptyMarkdown')?.info?.content || ''",
    options: {
      controls:
        ":properties.controlledByHost.value ? hasRole($roles)('host')($me) : true",
      controlsLayout: 'bottom-right',
    },
    revealControlledBy: ":properties.controlledByHost.value ? 'host' : 'user'",
    revealState: {
      indexf: undefined,
      indexv: 0,
      indexh: 0,
      paused: false,
      overview: false,
    },
  },
  properties: {
    controlledByHost: {
      type: 'boolean',
      value: true,
    },
  },
  schema: {
    fields: [],
    ownFields: [
      {
        title: 'Kontrolliert von Host',
        key: 'controlledByHost',
        type: 'boolean',
      },
    ],
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

const EmptyMarkdown: DataDocument = {
  id: 'EmptyMarkdown',
  info: {
    title: 'Presentation Content',
    icon: 'presentation',
  },
  tag: {
    tag: 'markdown',
  },
  parentId: 'RevealInstance',
};

export const PresetReveal: PresetDefinition = {
  id: 'PresetReveal',
  title: 'Reveal Presentation',
  icon: 'presentation',
  group: 'tools',
  version: '0.1',
  documents: [RevealInstance, EmptyMarkdown],
};
