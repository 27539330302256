












































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleModelMeta } from '@fillip/api';
import clone from 'rfdc/default';

import LoaderEditor from '../models/LoaderEditor.vue';
import MarkerEditor from '../models/MarkerEditor.vue';
import Video360EditorVue from '../models/Video360Editor.vue';
import ColorfulSkyEditor from '../models/ColorfulSkyEditor.vue';
import HdrTextureEditor from '../models/HdrTextureEditor.vue';
import PositionalAudioEditor from '../models/PositionalAudioEditor.vue';

const editors = {
  'model.loader': LoaderEditor,
  'model.marker': MarkerEditor,
  'model.video360': Video360EditorVue,
  'model.colorfulSky': ColorfulSkyEditor,
  'model.hdrTexture': HdrTextureEditor,
  'model.positionalAudio': PositionalAudioEditor,
};

export default Vue.extend({
  name: 'ModelEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save, data } = useSave(props, {
      path: 'model',
    });

    return {
      buffer,
      save,
      data,
    };
  },
  computed: {
    editorComponent() {
      return editors[this.data.type];
    },
    icon() {
      return ModuleModelMeta.types[this.buffer.type]?.icon;
    },
    items() {
      return Object.values(ModuleModelMeta.types).map((type) => ({
        text: this.$t(`${type.type}.title`),
        value: type.type,
        icon: type.icon,
      }));
    },
  },
  methods: {
    setType(type) {
      this.buffer = clone(ModuleModelMeta.types[type].default);
      this.save();
    },
  },
});
