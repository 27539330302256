


























import { useSave } from '@/composables';

import InputJson from '@/components/input/json/InputJson.vue';

export default {
  name: 'DocumentJsonEditor',
  components: {
    InputJson,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: '',
    },
  },
  setup(props: any) {
    const { buffer, save, reset, isDirty } = useSave(props, {
      path: props.path,
    });

    return {
      buffer,
      save,
      reset,
      isDirty,
    };
  },
  data() {
    return {
      jsonIsValid: false,
    };
  },
  computed: {},
  methods: {
    jsonChanged(newValue) {
      // Validation is handled by JSON editor,
      // so we can be sure the JSON is valid here
      this.jsonIsValid = true;
      this.buffer = newValue;
    },
  },
};
