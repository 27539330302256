export const patchStyle = (
  el: HTMLElement,
  prev: Record<string, string>,
  next: Record<string, string>,
) => {
  for (const key in next) {
    el.style[key] = next[key];
  }
  for (const key in prev) {
    if (!next[key]) {
      el.style[key] = '';
    }
  }
};
