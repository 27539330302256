import { render, staticRenderFns } from "./ActionWorkspaceControl.vue?vue&type=template&id=dc3de170&scoped=true&"
import script from "./ActionWorkspaceControl.vue?vue&type=script&lang=ts&"
export * from "./ActionWorkspaceControl.vue?vue&type=script&lang=ts&"
import style0 from "./ActionWorkspaceControl.vue?vue&type=style&index=0&id=dc3de170&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc3de170",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VCard,VDivider,VList,VListItem,VListItemAvatar,VListItemContent,VListItemGroup,VMenu})


/* vuetify-loader */
import installDirectives from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
