import type { MediasoupConnection } from './../realtime.client';
import { ConnectionManager, type FillipWsClient } from '../../helpers';
import type { FillipVueRealtimeClient } from '../realtime.client';
import type { Consumer, Device } from 'mediasoup-client/lib/types';
import {
  getFullMediasoupResourceId,
  parseFullMediasoupResourceId,
} from '@fillip/api';

export class ConsumersManager {
  consumers = new Map<string, ConnectionManager<Consumer>>();
  unwatchRequestedConsumers: () => void = null;

  constructor(
    public owner: FillipVueRealtimeClient,
    public msConnection: MediasoupConnection,
    public ws: FillipWsClient,
    public communitySlug: string,
    public device: Device,
  ) {
    this.unwatchRequestedConsumers = this.owner.store.watch(
      (state) => {
        return state.client.communities[this.communitySlug].requestedStreams
          .consumers;
      },
      (consumersObject) => {
        consumersObject = consumersObject || {};
        for (const existingConsumer of this.consumers.keys()) {
          if (!consumersObject[existingConsumer]) {
            this.removeManagerInstance(existingConsumer);
          }
        }
        for (const producerId in consumersObject) {
          if (!this.consumers.has(producerId)) {
            this.addConsumerManager(
              producerId,
              consumersObject[producerId].participantId,
              consumersObject[producerId].producerName,
            );
          }
        }
      },
      { immediate: true, deep: true },
    );
  }

  addConsumerManager(
    producerId: string,
    participantId: string,
    producerName: string,
  ) {
    const consumerManager = new ConnectionManager<Consumer>({
      connectionParameters: true,
      connect: async (parameters, onClose) => {
        // ! To apply preferredLayers on the consumer:
        // Combine rtpCapabilities with new parameters
        // consumerId and preferredLayer to new param 'options' or similar.
        // Add that object to be handed to backend.
        // Add preferred layer to the backend transport.consume call.
        let routerId;

        try {
          routerId = await this.ws.mediasoup.getRouterIdForNewConsumer(
            producerId,
            participantId,
          );

          const transport = await this.msConnection.requestTransport(
            'receive',
            routerId,
          );
          const consumerOptions = await this.ws.mediasoup.consume(
            getFullMediasoupResourceId(transport),
            producerId,
            participantId,
            producerName,
            this.device.rtpCapabilities,
          );

          // ? What does the consumerOptions Object contain (what got returned from backend). Readjust return value in backend, if needed
          // ! To add consumer options from client side:
          // spread this into a new object and add client options.
          // Similar to procedure on the producer(see line 545)
          const consumer = await transport.consume(consumerOptions);

          // Consumer observers
          consumer.observer.on('pause', () => {
            console.log(
              `%cConsumer paused (consumer.observer.on-client). ProducerId: ${producerId}`,
              'color: #ffa500',
            );
          });
          consumer.observer.on('resume', () => {
            console.log(
              `%cConsumer resumed (consumer.observer.on-client). ProducerId: ${producerId}`,
              'color: #d0f0c0',
            );
          });
          consumer.observer.on('close', () => {
            console.log(
              `%cConsumer Manager listener close (consumer.observer.on-client). ProducerId: ${producerId}`,
              'color: #ff6347',
            );
            onClose(consumer);
          });
          return consumer;
        } catch (error) {
          try {
            this.msConnection.unrequestTransport('receive', routerId);
          } catch (error) {
            console.warn(
              'consumersManager - Could not resolve unrequestTransport: ',
              error,
            );
          }
          throw error;
        }
      },
      disconnect: async (consumer: Consumer) => {
        const communitySlug = this.communitySlug;
        const producerId = consumer.producerId;

        const consumerId = getFullMediasoupResourceId(consumer);
        const { routerId } = parseFullMediasoupResourceId(consumerId);
        try {
          await this.msConnection.unrequestTransport('receive', routerId);
        } catch (error) {
          console.warn(
            'consumersManager - Could not resolve unrequestTransport: ',
            error,
          );
        }

        this.owner.store.commit('client/RESET_MEDIASOUP_CONSUMER', {
          communitySlug,
          producerId,
        });

        try {
          consumer.close();
          await this.ws.mediasoup.closeConsumer(
            getFullMediasoupResourceId(consumer),
          );
        } catch (error) {
          console.warn(
            'consumersManager - Could not resolve closeConsumer: ',
            error,
          );
        }
      },
    });

    this.consumers.set(producerId, consumerManager);

    consumerManager.on('connected', () => {
      console.log('consumerManager.on("connected")');
      const consumer = consumerManager.connection;
      // consumer ready
      this.ws.mediasoup.consumerReady(getFullMediasoupResourceId(consumer));
      this.owner.store.commit('client/INIT_MEDIASOUP_CONSUMER', {
        communitySlug: this.communitySlug,
        producerId,
        instance: consumer,
      });

      const unwatchParticipantStream = this.owner.store.watch(
        (state) => {
          const participant =
            state.client.communities[this.communitySlug].db.data[participantId];

          return participant?.streams?.[producerName]?.paused;
        },
        (shouldBePaused) => {
          if (shouldBePaused) return consumer.pause();
          return consumer.resume();
        },
        { immediate: true },
      );
      consumerManager.on('closed', unwatchParticipantStream);
    });
  }

  removeManagerInstance(producerId: string) {
    const consumerManager = this.consumers.get(producerId);
    consumerManager.close();
    this.consumers.delete(producerId);
  }

  public close() {
    this.unwatchRequestedConsumers();

    Array.from(this.consumers.keys()).forEach(
      this.removeManagerInstance.bind(this),
    );
  }
}
