























import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleElementMeta } from '@fillip/api';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'TextEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'element',
    });

    const textFormats = ModuleElementMeta.types['element.text'].schema.formats;

    return {
      buffer,
      save,
      textFormats,
    };
  },
});
