import { render, staticRenderFns } from "./StyleEditor.vue?vue&type=template&id=16519da4&"
import script from "./StyleEditor.vue?vue&type=script&lang=ts&"
export * from "./StyleEditor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VBtn,VExpansionPanelContent})
