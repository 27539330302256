































































































import {
  useData,
  useDataClipboard,
  useDataTreeView,
  useVolatileData,
  useTags,
  useDocuments,
  useI18n,
} from '@/composables';
import {
  DataId,
  DataDocument,
  rootTemplateId,
  rootDataId,
  ActionControl,
} from '@fillip/api';
import PresetSelector from './PresetSelector.vue';
import {
  defineComponent,
  computed,
  ref,
  watch,
  onMounted,
  // onBeforeUnmount,
} from '@vue/composition-api';
// import { debounce } from 'lodash';

export default defineComponent({
  name: 'Hierarchy',
  components: { PresetSelector },
  props: {
    rootId: {
      type: String,
      default: rootTemplateId,
    },
    mode: {
      type: String,
      default: 'edit',
    },
  },
  setup(props: any, { emit }) {
    const dataClipboard = useDataClipboard();
    const { dataToTreeView } = useDataTreeView();
    const {
      duplicateDataFromId,
      removeData,
      clearChildren,
      updateTitle,
      getData,
    } = useData();
    const { getDataIcon } = useTags();
    const { getVolatileProp, setVolatileProp, clearVolatile } =
      useVolatileData();
    const { tr } = useI18n();

    const {
      editInDialog,
      openExport,
      openSideEditor,
      selection,
      updateSelection,
    } = useDocuments();

    const tree = ref(null);
    const activeNodes = ref([]);
    const openNodes = ref([]);
    // const cachedOpenNodes = ref([]);
    const dataTitleEditing = ref(null);
    const inMountingState = ref(true);
    const search = ref(null);

    const items = computed(() => dataToTreeView(props.rootId).children);

    const filter = (item: any, search: any, textKey: any) => {
      if (!item) return false;
      if (item.id.includes(search)) return true;
      return item[textKey]
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase());
    };

    const updateOpenNodes = (newSelection) => {
      if (
        !newSelection ||
        newSelection == getVolatileProp(props.rootId, 'openNodes')
      )
        return;
      else if (newSelection.length == 0 && inMountingState.value) {
        inMountingState.value = false;
      } else {
        setVolatileProp(props.rootId, 'openNodes', newSelection);
      }
    };

    const syncActiveNodesWithSelection = () => {
      if (selection.value && selection.value != props.rootId) {
        activeNodes.value = [selection.value];
      } else {
        activeNodes.value = [];
      }
    };

    const updateTreeSelection = (newSelection?: any[]) => {
      if (!newSelection || newSelection.length == 0) {
        syncActiveNodesWithSelection();
      } else {
        const newSelectionId = newSelection[0];
        if (newSelectionId == selection.value) {
          if (newSelection.length != 1) {
            syncActiveNodesWithSelection();
          }
        } else {
          activate(newSelectionId);
          syncActiveNodesWithSelection();
        }
      }
    };

    const openParentNode = (docId: string) => {
      const parentId = getData(docId)?.parentId;
      if (!parentId) return;
      const nodes = getVolatileProp(props.rootId, 'openNodes', []);
      if (nodes.includes(parentId)) return;
      nodes.push(parentId);
      openNodes.value = nodes;
    };

    const activate = (documentId: string) => {
      updateSelection(documentId);
      updateTreeSelection();
    };

    const open = (dataId: DataId) => {
      emit('open', dataId);
    };

    const onClickDataTitle = (data: DataDocument) => {
      if (data && data.id == selection.value) {
        dataTitleEditing.value = data.id;
      }
    };

    const handleTitleBlurEvent = async (event, data: DataDocument) => {
      try {
        if (data?.info?.title == event.target.value) return;
        await updateTitle(data.id, event.target.value);
      } finally {
        dataTitleEditing.value = null;
      }
    };
    const removeItem = (id) => {
      // TODO: Require confirmation
      removeData(id);
    };

    const itemActions = computed(() => {
      const actions: ActionControl[] = [
        {
          type: 'action',
          title: tr('explorer.open'),
          action: (data) => open(data.id),
          icon: 'expand-alt',
        },
        {
          type: 'action',
          title: tr('explorer.edit'),
          action: (data) => openSideEditor(),
          icon: 'pencil',
        },
        {
          type: 'action',
          title: tr('explorer.duplicate'),
          action: (data) => duplicateDataFromId(data.id),
          icon: 'clone',
        },
        {
          type: 'divider',
        },
        {
          type: 'action',
          title: tr('explorer.copy'),
          action: (data) => dataClipboard.copyFromId(data.id),
          icon: 'copy',
        },
        {
          type: 'action',
          title: tr('explorer.cut'),
          action: (data) => dataClipboard.cutFromId(data.id),
          icon: 'cut',
          disabled: (data) => data.info.isProtected,
        },
        {
          type: 'action',
          title: tr('explorer.paste'),
          action: (data) => dataClipboard.paste(data.id),
          icon: 'paste',
          disabled: (data) => !dataClipboard.current.value,
        },
        {
          type: 'divider',
        },
        {
          type: 'action',
          title: tr('explorer.export'),
          action: (data) => openExport(data.id),
          icon: 'file-export',
        },
        {
          type: 'divider',
        },
        {
          type: 'action',
          title: tr('explorer.clearChildren'),
          action: (data) => clearChildren(data.id),
          icon: 'trash',
          color: 'error',
          disabled: (data) => !data.list?.items?.length,
        },
        {
          type: 'action',
          title: tr('explorer.remove'),
          action: (data) => removeItem(data.id),
          icon: 'trash',
          disabled: (data) => data.info.isProtected,
          color: 'error',
        },
      ];

      return actions;
    });

    // OLD
    // const openNodesAfterSearch =  debounce(function () {
    //   if (!cachedOpenNodes.value) {
    //     cachedOpenNodes.value = [...openNodes.value];
    //     this.$nextTick(this.$refs.tree.updateAll(true));
    //   }
    // }, 100);
    // const resetOpenNodes = () => {
    //   tree.value.updateAll(false);
    //   openNodes.value = [...cachedOpenNodes.value];
    //   updateOpenNodes(cachedOpenNodes.value);
    //   cachedOpenNodes.value = null;
    // };

    // onBeforeUnmount(() => {
    //   resetOpenNodes();
    // });

    // watch(search, (newValue) => {
    //   if (newValue) {
    //     openNodesAfterSearch();
    //   } else {
    //     resetOpenNodes();
    //   }
    // });

    onMounted(() => {
      openNodes.value = getVolatileProp(rootDataId, 'openNodes', []);
    });

    watch(selection, (newSelection) => {
      openParentNode(newSelection);
      syncActiveNodesWithSelection();
    });

    return {
      dataClipboard,
      duplicateDataFromId,
      getDataIcon,
      removeData,
      clearChildren,
      dataToTreeView,
      updateTitle,
      getVolatileProp,
      setVolatileProp,
      clearVolatile,
      getData,
      activeNodes,
      openNodes,
      dataTitleEditing,
      inMountingState,
      search,
      items,
      selection,
      filter,
      updateOpenNodes,
      updateTreeSelection,
      syncActiveNodesWithSelection,
      openParentNode,
      open,
      activate,
      openSideEditor,
      editInDialog,
      openExport,
      onClickDataTitle,
      handleTitleBlurEvent,
      removeItem,
      tree,
      itemActions,
    };
  },
});
