

























import { useI18n, useShell } from '@/composables';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AuthContainer',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { maybeTr } = useI18n();
    const { classes } = useShell();
    const translatedTitle = computed(() => maybeTr(props.title));
    const translatedSubtitle = computed(() => maybeTr(props.subtitle));

    return { translatedTitle, translatedSubtitle, classes };
  },
});
