import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagImage: DataDocument = {
  id: 'tag-image',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Image',
    icon: 'image',
    isProtected: true,
  },
  element: {
    type: 'element.image',
    imageSrc: ':info.content.imageSrc',
    imageDescription: ":info.content.imageDescription || ''",
    imageCopyright: ":info.content.copyright || ''",
  },
  class: {
    class: ':`mb-4 ${$containerClasses}`',
    elementClass: ':`max-w-full ${$elementClasses}`',
  },
  size: {
    type: 'conditional',
    variants: [
      {
        condition: ':!!$maxHeight',
        value: {
          minWidth: '',
          minHeight: '',
          minDepth: '',
          maxWidth: '',
          maxHeight: ':$maxHeight',
          maxDepth: '',
        },
      },
      {
        condition: '',
        value: {
          minWidth: '',
          minHeight: '',
          minDepth: '',
          maxWidth: '',
          maxHeight: '',
          maxDepth: '',
        },
      },
    ],
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'image',
    title: 'Image',
    category: 'content',
    icon: 'image',
    editor: 'image',
  },
  interfaceDefinition: {
    title: '',
    props: [
      {
        title: 'Image Source',
        key: 'imageSrc',
        type: 'string',
        defaultValue:
          ":'$imageSrc || info?.content?.imageSrc || properties?.imageSrc?.value || '",
      },
      {
        title: 'Description',
        key: 'imageDescription',
        type: 'string',
        defaultValue:
          ":$imageDescription || info?.content?.imageDescription || properties?.imageDescription?.value || ''",
      },
      {
        title: 'Copyright',
        key: 'copyright',
        type: 'string',
        defaultValue:
          ":$copyright || info?.content?.copyright || properties?.copyright?.value || ''",
      },
      {
        title: 'Max Height',
        key: 'maxHeight',
        type: 'string',
        defaultValue: ":$maxHeight || properties?.maxHeight?.value || 'h-96'",
      },
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":$containerClasses || properties?.containerClasses?.value || ''",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":$elementClasses || properties?.elementClasses?.value || ''",
      },
    ],
  },
};
