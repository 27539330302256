import { FillipStoreMethods } from '@fillip/api';
import { computed, getCurrentInstance } from '@vue/composition-api';
import type { FillipVueClient } from '../../../../../libs/vue-client/src/client';
import store from '@/store';
import type { ParticipantDocument } from '@/features/main/core';

export function useFillip() {
  const vm = (getCurrentInstance() as any).proxy;

  const fillip = vm.$fillip as FillipVueClient;
  const fiStore = vm.$fiStore as FillipStoreMethods;
  const fiStoreDispatcher = vm.$fiStoreDispatcher as FillipStoreMethods;
  const $invoke = vm.$invoke;
  const can = vm.$can;
  const $ability = vm.$ability;

  // const $me = computed(() => vm.$me);
  const $me = computed<ParticipantDocument>(() => store.getters.me);
  const $user = computed(() => vm.$user);
  const $community = computed(() => vm.$community);
  const $communitySlug = computed(() => vm.$communitySlug);

  const baseUrl = (window?.location?.origin || 'https://collabl.world') + '/';

  return {
    fillip,
    fiStore,
    fiStoreDispatcher,
    can,
    $ability,
    baseUrl,

    invoke: $invoke,
    me: $me,
    user: $user,
    community: $community,
    communitySlug: $communitySlug,

    // legacy exports
    $invoke,
    $me,
    $user,
    $community,
    $communitySlug,
  };
}
