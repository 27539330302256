import type { DataDocument, DataId, Id } from '@fillip/api';

export interface PresetOptions {
  [key: string]: any;
}

export interface PipelineStep {
  method: string;
  args?: any;
}

export const presetGroups = {
  data: { title: 'Data' },
  templates: { title: 'Templates' },
  global: { title: 'Global' }, // TODO: Figure out better groups
  tools: { title: 'Tools' }, // TODO: Figure out better groups
  other: { title: 'Other' },
  //apps: {title: 'Apps'},
  //scenes: {title: 'Scenes'},
};

type TPresetGroups = keyof typeof presetGroups;

export interface PresetMeta {
  id: Id;
  group: TPresetGroups;
  version: string;
  // info: {
  title: string;
  icon?: string;
  subtitle?: string;
  desription?: string;
  createdBy?: string;
  createdAt?: number;
  // };
}

type PresetMethod = string;

export interface PresetDefinition extends PresetMeta {
  documents: DataDocument[];
  patchParent?: PipelineStep[];
  install?: (
    parent: DataDocument,
    options: PresetOptions,
    documents: DataDocument[],
    environment: Record<string, any>,
  ) => Promise<Partial<PresetDefinition>>;
  // update?: (parentId: DataId, options?: PresetOptions) => Promise<boolean>;
}

export interface PresetInstallResult {
  // createdDocuments: Record<DataId, DataDocument>;
  createdDocuments: DataDocument[];
  replacements: Record<string, DataId>;
  patchedParents?: Record<DataId, PresetMethod>;
  meta?: PresetMeta;
}
