
























import { useCommunities, useFillip } from '@/composables';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'CommunityHubCard',
  props: {
    community: {
      required: true,
      type: Object,
    },
  },
  setup() {
    const { isCommunityCreatedByUser, enterCommunity, communityActions } =
      useCommunities();

    const { baseUrl } = useFillip();

    return {
      isCommunityCreatedByUser,
      enterCommunity,
      communityActions,

      baseUrl,
    };
  },
});
