
































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';

export default Vue.extend({
  name: 'StyleEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'style',
    });

    return {
      buffer,
      save,
    };
  },
  data() {
    return {
      style: [],
    };
  },
  watch: {
    buffer: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.style = Object.entries(newValue).map((entry) =>
            entry.map(String),
          );
        } else {
          this.style = [];
        }
      },
    },
  },
  methods: {
    updateStyle() {
      this.buffer = this.style
        .filter(([p, v]) => p)
        .reduce((result, [property, value]) => {
          result[property] = value;
          return result;
        }, {});
      this.save();
    },
  },
});
