

















import { computed, defineComponent, ref } from '@vue/composition-api';
import { useElementSize } from '@/composables';

import TabsLayout from './TabsLayout.vue';
import ColumnsLayout from './ColumnsLayout.vue';
import ListLayout from './ListLayout.vue';

export default defineComponent({
  name: 'AdaptiveLayout',
  components: {
    TabsLayout,
    ColumnsLayout,
    ListLayout,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    forceLayout: {
      type: String,
      default: '',
    },
    breakpoints: {
      type: Object,
      default: () => ({}),
    },
    currentChild: {
      type: Object,
      default: () => ({}),
    },
    childProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const componentRef = ref(null);
    const dimensions = useElementSize(componentRef);

    const layouts = {
      tabs: {
        name: 'tabs',
        component: 'TabsLayout',
        options: {},
      },
      'vertical-tabs': {
        name: 'vertical-tabs',
        component: 'TabsLayout',
        options: { vertical: true },
      },
      columns: {
        name: 'columns',
        component: 'ColumnsLayout',
        options: {},
      },
      list: {
        name: 'listLayout',
        component: 'ListLayout',
        options: {},
      },
      // masterDetail: {
      //   name: 'masterDetail',
      //   component: 'MasterDetailLayout',
      // },
    } as const;

    const defaultBreakpoints = {
      xs: 'tabs',
      sm: 'vertical-tabs',
      md: 'vertical-tabs',
      lg: 'columns',
      xl: 'columns',
    };

    const currentBreakpoints = computed(() => {
      return {
        ...defaultBreakpoints,
        ...props.breakpoints,
      };
    });

    const activeLayout = computed(() => {
      if (props.forceLayout) {
        if (props.forceLayout in layouts) {
          return layouts[props.forceLayout];
        } else {
          console.warn(`Unsupported layout ${props.forceLayout}`);
        }
      }
      if (dimensions?.breakpoint) {
        return layouts[currentBreakpoints.value[dimensions.breakpoint]];
      }
      return layouts[currentBreakpoints.value['xs']];
    });

    const emitChange = (target) => {
      emit('change', target);
    };

    return {
      componentRef,
      layouts,
      defaultBreakpoints,
      dimensions,
      currentBreakpoints,
      activeLayout,
      emitChange,
    };
  },
});
