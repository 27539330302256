import { Tag } from '../vm/modules/tag.module';

export * from './composables';

export type Id = string;
export type TemplateId = Id;
export type ObjectId = Id;
export type DataId = Id;
export type UserId = Id;
export type FileId = number;
export type URLString = string;
export type ColorDescriptor = string;
export type Expression = string;
export type BackendExpression = Expression;
export type FrontendExpression = Expression;

export const localhostUrls = ['localhost', '127.0.0.1'];

export interface TypeMeta<T> {
  name?: string;
  default: T;
  childDefault?: any;
}

export interface ModuleTypeMeta<T> extends TypeMeta<T> {
  icon?: string;
  schema?: Record<string, any>;
  restrictedToTags?: Tag[];
}

export interface ImportOptions {
  keepIds?: boolean;
}

export const rootTemplateId: TemplateId = '0';
export const rootObjectId: ObjectId = '0';
export const rootCircleId: DataId = 'main';
export const rootDataId: DataId = 'root';
export const participantsDirectoryId: DataId = 'participants';
export const templateDirectoryId: DataId = 'templates';
export const tagsTemplateDirectoryId: DataId = 'tags';
export const toolsTemplateDirectoryId: DataId = 'tools';
export const fragmentsTemplateDirectoryId: DataId = 'fragments';

export const localPrefix: string = 'local:';
export const localRootDataId: DataId = 'local:root';

export * from './fillip-store';
export * from './utils';

export interface Vector3Type {
  x: number;
  y: number;
  z: number;
}

export interface Location3DType {
  position: Vector3Type;
  rotation: Vector3Type;
  scale: Vector3Type;
}

interface ActionControlAction {
  type: 'action';
  title: string;
  icon: string;
  action: (data: any) => any;
  color?: string;
  disabled?: boolean | ((data: any) => boolean);
  if?: boolean | ((data: any) => boolean);
}

interface ActionControlDivider {
  type: 'divider';
  title?: string;
  icon?: string;
}

export type ActionControl = ActionControlAction | ActionControlDivider;

export const displayModes = {
  screen: {
    title: 'Screen',
  },
  print: {
    title: 'Print',
  },
  edit: {
    title: 'Edit',
  },
} as const;

export type DisplayMode = keyof typeof displayModes;
