











import ListEditorLarge from '@/features/editor/modules/ListEditorLarge.vue';
import { useVolatileData, useData } from '@/composables';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'ActionListManager',
  components: {
    ListEditorLarge,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    vPropId: {
      type: String,
      required: true,
    },
    canvasSlot: {
      type: String,
      default: 'bottom',
    },
    name: {
      type: String,
      default: '',
      // default: function () {
      //   return this.$t('action.listManager.title');
      // },
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    vMinimized: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { getVolatileProp } = useVolatileData();
    const { getData } = useData();

    const minimized = computed(() =>
      getVolatileProp(props.vPropId, props.vMinimized, true),
    );
    const doc = computed(() => getData(props.id));
    const title = computed(() => doc.value.info.title);

    return {
      getVolatileProp,
      getData,
      minimized,
      doc,
      title,
    };
  },
});
