var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.participant)?_c('div',{class:{
    'style--circle': _vm.shape == 'circle',
    'style--square': _vm.shape == 'square',
    'style--rectangle': _vm.shape == 'rectangle',
    'style--cover': _vm.shape == 'cover',
  },style:({
    width: '100%',
    height: '100%',
  })},[_c('v-responsive',{staticClass:"participant-container"},[_c('div',{staticClass:"layer layer--image",style:(_vm.imageStyle)},[_c('BaseTransition',[_c('v-img',{staticClass:"layer layer--avatar",attrs:{"aspect-ratio":1,"position":"center center","contain":"","src":_vm.getAvatarUrl(_vm.participant)}})],1),(_vm.video && !_vm.hideDetails)?_c('BaseLayer',{staticClass:"layer layer--video"},[_c('ParticipantVideo',{attrs:{"participant":_vm.participant}})],1):_vm._e(),(_vm.status.isOverlaying)?_c('BaseLayer',{staticClass:"layer--status-blocking",attrs:{"overlay":_vm.status.isOverlaying,"opacity":_vm.status.isBlocking ? 1 : 0.8,"color":_vm.status.color}},[_c('div',{staticClass:"flex flex-col items-center"},[_c('BaseIcon',{staticClass:"mb-4",attrs:{"size":"large","icon":_vm.status.icon}}),(!_vm.hideDetails)?_c('div',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.status.title))+" ")]):_vm._e()],1)]):_vm._e()],1),(!_vm.isMe && _vm.audio)?_c('ParticipantListener',{attrs:{"participant-id":_vm.participant.id}}):_vm._e(),(_vm.status.isImportant)?_c('BaseLayer',{staticClass:"layer--status--important"},[_c('BaseIcon',{staticStyle:{"position":"absolute","top":"0","right":"0"},attrs:{"color":_vm.status.color || 'grey',"size":"xl","icon":_vm.status.icon}})],1):_vm._e(),_c('BaseTransition',[(!_vm.hideDetails)?_c('v-chip',{staticClass:"chip--username",style:(_vm.alwaysShowName || _vm.showMenu ? 'opacity: 1' : ''),attrs:{"pill":""}},[_c('div',{staticClass:"flex flex-row items-center"},[(_vm.muted)?_c('BaseIcon',{staticStyle:{"margin-right":"0.5rem"},attrs:{"left":"","icon":"microphone-slash"}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.participant.info.title)+" ")]),(_vm.isHost)?_c('v-menu',{attrs:{"absolute":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.showMenu = !_vm.showMenu}}},on),[_c('BaseIcon',{attrs:{"icon":"ellipsis-v"}})],1)]}}],null,false,270821228)},[_c('v-list',{staticClass:"v-list--dense",staticStyle:{"min-width":"10rem"}},[_c('v-list-item',{on:{"click":function($event){_vm.toggleParticipantMicrophone(_vm.participant),
                    (_vm.showMenu = !_vm.showMenu)}}},[_c('BaseIcon',{staticClass:"mr-3",attrs:{"left":"","icon":!_vm.muted ? 'microphone-slash' : 'microphone',"fixed-width":""}}),_vm._v(" "+_vm._s(!_vm.muted ? _vm.$t('videoConference.muteMicrophone') : _vm.$t('videoConference.unmuteMicrophone'))+" ")],1),_c('v-list-item',{on:{"click":function($event){_vm.toggleParticipantCamera(_vm.participant), (_vm.showMenu = !_vm.showMenu)}}},[_c('BaseIcon',{staticClass:"mr-3",attrs:{"left":"","icon":_vm.cameraOn ? 'video-slash' : 'video',"fixed-width":""}}),_vm._v(" "+_vm._s(_vm.cameraOn ? _vm.$t('videoConference.stopVideo') : _vm.$t('videoConference.startVideo'))+" ")],1),_c('v-list-item',{on:{"click":function($event){_vm.moveParticipantToLobby(_vm.participant), (_vm.showMenu = !_vm.showMenu)}}},[_c('BaseIcon',{staticClass:"mr-3",attrs:{"left":"","icon":"globe","fixed-width":""}}),_vm._v(" Move participant to Lobby ")],1)],1)],1):_vm._e()],1)]):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }