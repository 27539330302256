































import PrimitiveDataSelector from '@/components/primitive-editor/types/PrimitiveDataSelector.vue';
import InputString from '@/components/input/string/InputString.vue';
import InputTextarea from '@/components/input/string/InputTextarea.vue';
import { defineComponent, computed, ref } from '@vue/composition-api';
import type { DataDocument } from '@fillip/api';
import { useData } from '@/composables';
import clone from 'rfdc/default';

export default defineComponent({
  name: 'ReassignPosts',
  components: {
    PrimitiveDataSelector,
    InputString,
    InputTextarea,
  },
  setup() {
    const selectedId = ref<string>(null);
    const { data, updateData } = useData();
    const documentsByUser = computed<DataDocument[]>(() => {
      return data.value.filter((doc) => {
        return doc.info?.createdBy === selectedId.value;
      });
    });

    const newId = ref<string>(null);

    const isValid = computed(
      () => selectedId.value && documentsByUser.value.length && newId.value,
    );
    const updateDocuments = async () => {
      if (!isValid) return;
      const updates = [];
      for (const doc of documentsByUser.value) {
        const newDoc: DataDocument = clone(doc);
        console.log('Update', selectedId.value, newId.value);
        newDoc.info.title = newDoc.info.title.replace(
          selectedId.value,
          newId.value,
        );
        newDoc.info.createdBy = newId.value;
        console.log('Updated', newDoc);
        updates.push(updateData(newDoc.id, newDoc));
      }
      return Promise.all(updates);
    };

    return { selectedId, documentsByUser, newId, isValid, updateDocuments };
  },
});
