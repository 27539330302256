import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagDocument: DataDocument = {
  id: 'tag-document',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Document',
    icon: 'file-alt',
    isProtected: true,
  },
  children: {
    default: [
      {
        templateId: 'tag-h3',
        query: ':[data]',
        condition: ':$showTitle',
      },
      {
        query: ':getList($collectionId)()',
        templateId: null,
      },
    ],
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'document',
    title: 'Document',
    category: 'content',
    icon: 'file-alt',
    editor: 'default',
  },
  class: {
    class: ':$containerClasses',
    elementClass: '$elementClasses',
  },
  interfaceDefinition: {
    title: '',
    props: [
      {
        title: 'Collection Id',
        key: 'collectionId',
        type: 'id',
        defaultValue:
          ':$collectionId || properties?.collectionId?.value || data.id',
      },
      {
        title: 'Show Title',
        key: 'showTitle',
        type: 'boolean',
        defaultValue: ':$showTitle ?? properties?.showTitle?.value ?? true',
      },
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":$containerClasses || properties?.containerClasses?.value || ''",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":$elementClasses || properties?.elementClasses?.value || ''",
      },
    ],
  },
};
