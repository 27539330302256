



























import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ElementParticipantMeta, ModuleElementMeta } from '@fillip/api';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ParticipantEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'element',
      defaultValue: ElementParticipantMeta.default,
    });

    const participantsBoxShapes =
      ModuleElementMeta.types['element.participant'].schema.shapes;

    return {
      buffer,
      save,
      participantsBoxShapes,
    };
  },
});
