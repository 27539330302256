











































import { defineComponent, computed, inject, Ref } from '@vue/composition-api';
import { useLayoutArea } from '@/composables';
import LayoutAreaHeader from '@/features/app/LayoutAreaHeader.vue';

export default defineComponent({
  name: 'Sidedrawer',
  components: {
    LayoutAreaHeader,
  },
  setup() {
    const themeManager = inject('themeManager', null) as Ref<any>;

    const appBarHeight = computed(() => {
      return themeManager?.value?.appBarHeight;
    });

    const {
      currentRoute,
      component,
      componentProps,
      heading,
      hideDefaultHeader,
      isOpen,
      close,
      expand,
    } = useLayoutArea('drawer');

    return {
      appBarHeight,
      currentRoute,
      component,
      componentProps,
      isOpen,
      close,
      expand,
      heading,
      hideDefaultHeader,
    };
  },
});
