import { DataDocument, PresetDefinition, rootDataId } from '@fillip/api';

const PollInstance: DataDocument = {
  id: 'PollInstance',
  info: {
    title: 'Umfrage',
    icon: 'poll',
    toolEditing: {
      preferredArrangement: 'top-bottom',
      allowedChildTypes: ['text'],
      editors: {
        title: {
          id: 'PollInstance',
        },
        properties: {
          id: 'PollInstance',
          title: 'Einstellungen',
        },
        list: {
          id: 'ResponseOptionsCollection',
          title: 'Antwortmöglichkeiten',
        },
      },
    },
  },
  tag: {
    tag: 'template',
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  list: {
    items: [
      {
        id: 'ResponseOptionsCollection',
      },
      {
        id: 'ResponsesCollection',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'responseOptions',
        query: 'ResponseOptionsCollection',
      },
      {
        identifier: 'responses',
        query: 'ResponsesCollection',
      },
      {
        identifier: 'pollTitle',
        query: ':info.title',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'tool-poll',
        query: ':[data]',
      },
    ],
  },
  schema: {
    fields: [],
    ownFields: [
      {
        title: 'Max. wählbare Optionen',
        key: 'maxOptions',
        type: 'number',
      },
      {
        title: 'Max. Einsendungen',
        key: 'allowedResponses',
        type: 'number',
      },
    ],
  },
  properties: {
    maxOptions: {
      type: 'number',
      value: 1,
    },
    allowedResponses: {
      type: 'number',
      value: 1,
    },
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

const ResponseCollections: DataDocument[] = [
  {
    id: 'ResponseOptionsCollection',
    info: {
      title: 'Antwortoptionen',
    },
    tag: {
      tag: 'collection',
    },
    parentId: 'PollInstance',
    list: {
      items: [
        {
          id: 'ResponseOptionA',
        },
        {
          id: 'ResponseOptionB',
        },
      ],
    },
  },
  {
    id: 'ResponseOptionA',
    info: {
      title: 'Antwort A',
    },
    tag: {
      tag: 'text',
    },
    parentId: 'ResponseOptionsCollection',
  },
  {
    id: 'ResponseOptionB',
    info: {
      title: 'Antwort B',
    },
    tag: {
      tag: 'text',
    },
    parentId: 'ResponseOptionsCollection',
  },
  {
    id: 'ResponsesCollection',
    info: {
      title: 'Antworten',
    },
    tag: {
      tag: 'collection',
    },
    schema: {
      fields: [
        {
          title: 'Antwort',
          key: 'templateId',
          type: 'id',
        },
      ],
      ownFields: [],
    },
    parentId: 'PollInstance',
    list: {
      items: [],
    },
  },
];

export const PresetPoll: PresetDefinition = {
  id: 'PresetPoll',
  title: 'Umfrage',
  icon: 'poll',
  group: 'tools',
  version: '0.1',
  documents: [PollInstance, ...ResponseCollections],
};
