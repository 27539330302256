export function colors(colorsString) {
  if (!colorsString) {
    return;
  } else {
    let colorsArray = colorsString.split(' ');
    let result = '';
    for (let color of colorsArray) {
      let suffixValidated = '';
      let colorSplit = color.split('--');
      let prefix = colorSplit[0];
      let suffix = colorSplit[1];
      switch (suffix) {
        case 'text':
          suffixValidated = '--text';
          break;
        case 'background':
          suffixValidated = '';
          break;
        default:
          suffixValidated = '';
          break;
      }
      switch (prefix) {
        case 'primary':
          result += ' ' + 'primary' + suffixValidated;
          break;
        case 'secondary':
          result += ' ' + 'secondary' + suffixValidated;
          break;
        case 'accent':
          result += ' ' + 'accent' + suffixValidated;
          break;
        case 'success':
          result += ' ' + 'success' + suffixValidated;
          break;
        case 'error':
          result += ' ' + 'error' + suffixValidated;
          break;
        case 'background':
          result += ' ' + 'background' + suffixValidated;
          break;
      }
    }
    return result;
  }
}

export function sizes(size, theme) {
  switch (size) {
    case 'small':
      return theme.small;
    case 'large':
      return theme.large;
    case 'x-large':
      return theme['x-large'];
    case 'icon':
      return theme.icon;
    default:
      return theme.medium;
  }
}
export function positions(position, theme) {
  switch (position) {
    case 'center':
      return theme.center;
    case 'right':
      return theme.right;
    default:
      return theme.left;
  }
}
