import { ModuleTypeMeta } from '../../common';

export const RoomSizeOptions = {
  '0': { title: 'videoConferencing.roomSizes.xs' },
  '0.2': { title: 'videoConferencing.roomSizes.sm' },
  '0.5': { title: 'videoConferencing.roomSizes.md' },
  '1': { title: 'videoConferencing.roomSizes.lg' },
};

type RoomSize = keyof typeof RoomSizeOptions;

type StreamState = 'ON' | 'OFF';

const screenshareTranslationPath = 'videoConferencing.settings.screen.video';

export const CursorOptions = {
  always: `${screenshareTranslationPath}.cursorOptions.always`,
  never: `${screenshareTranslationPath}.cursorOptions.never`,
  motion: `${screenshareTranslationPath}.cursorOptions.motion`,
};

export type TCursorOptions = keyof typeof CursorOptions;

export const DisplaySurfaceOptions = {
  monitor: `${screenshareTranslationPath}.displaySurface.monitor`,
  window: `${screenshareTranslationPath}.displaySurface.window`,
  applicaiton: `${screenshareTranslationPath}.displaySurface.application`,
  browser: `${screenshareTranslationPath}.displaySurface.browser`,
};

export type TDisplaySurfaceOptions = keyof typeof DisplaySurfaceOptions;

export const ResizeModeOptions = {
  none: `${screenshareTranslationPath}.resizeMode.none`,
  'crop-and-scale': `${screenshareTranslationPath}.resizeMode.crop-and-scale`,
};

export type TResizeModeOptions = keyof typeof ResizeModeOptions;

interface StreamInformation {
  channel: string;
  roomSize: RoomSize;
  state: StreamState;
}

// TODO: Better Typing
interface CameraSettings extends StreamInformation {
  settings: {
    width: { min: number; ideal: number; max: number };
    height: { min: number; ideal: number; max: number };
  };
}

interface MicrophoneSettings extends StreamInformation {
  settings: {};
}

interface ScreenSettings extends StreamInformation {
  settings: {
    audio: boolean;
    video: {
      // check strings and type accordingly
      displaySurface: string;
      logicalSurface: boolean;
      cursor: TCursorOptions;
      resizeMode: string;
      frameRate: Record<string, any>;
      width: { max: number };
      height: { max: number };
    };
  };
}

export interface EvaluatedModuleVideoConferencing {
  camera?: CameraSettings;
  microphone?: MicrophoneSettings;
  screen?: ScreenSettings;
}

export const ModuleVideoConferencingMeta: ModuleTypeMeta<EvaluatedModuleVideoConferencing> =
  {
    default: {
      camera: {
        channel: '',
        roomSize: '0.5',
        state: 'OFF',
        settings: {
          width: { min: 320, ideal: 800, max: 1024 },
          height: { min: 240, ideal: 600, max: 768 },
        },
      },
      microphone: {
        channel: '',
        roomSize: '0.5',
        state: 'OFF',
        settings: {},
      },
      screen: {
        channel: '',
        roomSize: '0.5',
        state: 'OFF',
        settings: {
          audio: false,
          video: {
            displaySurface: 'monitor',
            logicalSurface: true,
            cursor: 'always',
            resizeMode: 'none',
            width: { max: 1920 },
            height: { max: 1080 },
            frameRate: { max: 30 },
          },
        },
      },
    },
  };
