




































import AuthContainer from '@/features/auth/components/AuthContainer.vue';
import {
  defineComponent,
  computed,
  ref,
  onMounted,
} from '@vue/composition-api';
import { useFillip, useRouter, useMessage, useI18n } from '@/composables';

export default defineComponent({
  name: 'ConfirmationPending',
  components: {
    AuthContainer,
  },
  setup() {
    const { $user, fillip } = useFillip();
    const { $route, router, getRouterTarget, gotoTargetOrCommunities } =
      useRouter();
    const message = useMessage();
    const { tr } = useI18n();

    const code = ref('');
    const loading = ref(false);
    const isActive = computed(() => code.value.length === 6);

    const target = computed(() => getRouterTarget());

    const verifyCode = async () => {
      if (!isActive.value) return;
      loading.value = true;

      try {
        await fillip.auth.confirmEmail(
          $user.value.pendingEmail || $user.value.email,
          code.value.toString(),
        );
        gotoTargetOrCommunities();
        message.success(tr('confirmation.success'));
      } catch (error) {
        console.error(error);
        message.error(tr(error.messageKey || 'general.error.unknown'));

        if (error.messageKey == 'confirmation.error.userAlreadyVerified') {
          router.push({ name: 'Logout' });
        } else {
          router.push({ name: 'RequestConfirmation' });
        }
      } finally {
        loading.value = false;
      }
    };

    const requestConfirmation = () => {
      router.push({ name: 'RequestConfirmation' });
    };

    // TODO: Do we need this?
    onMounted(() => {
      if ($route.query.jwt) {
        code.value = $route.query.jwt;
      }
    });

    return {
      code,
      isActive,
      verifyCode,
      requestConfirmation,
      target,
    };
  },
});
