import { useEventBus, UseEventBusReturn } from '@vueuse/core';

export type StateBus = UseEventBusReturn<string, any> & {
  requestInterval?: (operation: string, options: { interval: number }) => void;
  requestUpdate?: (operation: string) => void;
  immediateUpdate?: (operation: string) => void;
};
export interface StateBusUpdate {
  source: string;
  operation: string;
  options?: Record<string, any>;
}

export function useStateBus(source: string) {
  const stateBus: StateBus = useEventBus<string>('state');

  stateBus.requestInterval = (
    operation: string,
    options: { interval: number },
  ): void => stateBus.emit('requestInterval', { source, operation, options });

  stateBus.requestUpdate = (operation: string): void =>
    stateBus.emit('requestUpdate', { source, operation });

  stateBus.immediateUpdate = (operation: string): void =>
    stateBus.emit('immediateUpdate', { source, operation });

  return stateBus;
}
