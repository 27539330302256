import { BaseObject3D } from '../core';
import { MeshBasicMaterial, VideoTexture, Mesh, SphereGeometry } from 'three';
import { GlobalPropsNode } from '@/plugins/global-props';
import { useMediaControls } from '@/composables';
import { ref } from '@vue/composition-api';

export const ModelVideo360 = {
  name: 'ModelVideo360',
  mixins: [BaseObject3D, GlobalPropsNode],
  inject: ['router'],
  data() {
    return {
      geo: null,
      texture: null,
      material: null,
      mesh: null,
    };
  },
  async mounted() {
    this.geo = new SphereGeometry(1000, 100, 100);
    this.geo.scale(-1, 1, 1);
  },
  beforeDestroy() {
    this.clear();
  },
  setup(props: any) {
    const videoElement = ref<HTMLMediaElement>(null);
    const templateId = props.id.split(':')[0];
    const { mediaSource, loadMediaElement } = useMediaControls(
      { ...props, id: templateId },
      {
        mediaType: 'video360',
      },
      videoElement,
    );
    return {
      videoElement,
      mediaSource,
      loadMediaElement,
    };
  },
  computed: {
    hasStationFocusWithin() {
      return this.id == this.router.value.focusedStation.id;
    },
    startOnFocus() {
      return (
        this.hasStationFocusWithin &&
        this.modules.model.video360Settings.startEvent == 'focus'
      );
    },
  },
  watch: {
    //! In order to initially load the video frame onto the mesh.
    videoElement: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.update();
          this.loadMediaElement(true);
        }
      },
    },
    mediaSource: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue == oldValue) return;
        if (!newValue) this.clear();
        else {
          this.update();
          this.loadMediaElement(true);
        }
      },
    },
  },
  methods: {
    async update() {
      if (!this.videoElement) return;
      this.clear();
      this.texture = new VideoTexture(this.videoElement);
      this.material = new MeshBasicMaterial({ map: this.texture });
      this.mesh = new Mesh(this.geo, this.material);
      this.$object3D.add(this.mesh);
      this.startAnimation();
    },
    startAnimation() {
      this.canvas.value.renderManager.onRender(
        this.id,
        () => {
          this.canvas.value.render('webGL');
        },
        'webGL',
      );
    },
    clear() {
      if (this.$object3D) {
        this.$object3D.clear();
        this.stopAnimation();
      }
    },
    stopAnimation() {
      this.canvas.value.renderManager.removeOnRender(this.id, 'webGL');
    },
  },
  render(h) {
    return h('video', {
      ref: 'videoElement',
      attrs: {
        crossorigin: 'anonymous',
        src: this.mediaSource,
      },
      style: {
        display: 'none',
      },
    });
  },
};
