import { DataDocument, toolsTemplateDirectoryId } from '@fillip/api';

export const TemplateToolBreakoutRooms: DataDocument = {
  id: 'tool-breakoutrooms',
  info: {
    title: 'Tool: Breakout-Rooms',
    icon: 'map-signs',
  },
  tag: {
    tag: 'template',
  },
  parentId: toolsTemplateDirectoryId,
  list: {
    items: [
      {
        id: 'template-breakout-room-list',
      },
      {
        id: 'BreakoutRoomsContainer',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'template-breakout-room-list',
        query: ':[data]',
      },
      {
        templateId: 'BreakoutRoomsContainer',
        query: ':[data]',
        identity: '',
      },
    ],
  },
  actions: {
    actions: [
      {
        name: 'Breakout Rooms',
        type: 'action.breakoutRooms',
        icon: 'map-signs',
        target: ': $$breakoutRoomCollection',
        breakoutRoomTemplate: ': $$breakoutRoomTemplate',
        script: '',
        roles: ": ['host']",
      },
    ],
  },
  class: {
    elementClass: '',
    class: 'w-full h-full p-6',
  },
  sheet: {
    orientTowardsCamera: false,
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
};

export const TemplateToolBreakoutRoomsChildren: DataDocument[] = [
  {
    id: 'tool-breakoutrooms',
    info: {
      title: 'Tool: Breakout-Rooms',
      icon: 'map-signs',
    },
    tag: {
      tag: 'template',
    },
    parentId: toolsTemplateDirectoryId,
    list: {
      items: [
        {
          id: 'template-breakout-room-list',
        },
        {
          id: 'BreakoutRoomsContainer',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'template-breakout-room-list',
          query: ':[data]',
        },
        {
          templateId: 'BreakoutRoomsContainer',
          query: ':[data]',
          identity: '',
        },
      ],
    },
    actions: {
      actions: [
        {
          name: 'Breakout Rooms',
          type: 'action.breakoutRooms',
          icon: 'map-signs',
          target: ': $$breakoutRoomCollection',
          breakoutRoomTemplate: ': $$breakoutRoomTemplate',
          script: '',
          roles: ": ['host']",
        },
      ],
    },
    class: {
      elementClass: '',
      class: 'w-full h-full p-6',
    },
    sheet: {
      orientTowardsCamera: false,
    },
    arrangement: {
      type: 'arrangement.absolute',
    },
    variables: {
      variables: [
        {
          identifier: 'breakoutRooms',
          query: ':$$breakoutRooms',
        },
      ],
    },
  },
  {
    id: 'template-breakout-room-list',
    info: {
      title: 'Breakout Room List',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-breakoutrooms',
    children: {
      default: [
        {
          templateId: 'template-breakout-room-list-item',
          query: ':getList($$breakoutRoomCollection)',
          identity: '',
        },
      ],
    },
    list: {
      items: [
        {
          id: 'template-breakout-room-list-item',
        },
      ],
    },
    class: {
      elementClass: '',
      class:
        'w-full h-full grid grid-cols-1 gap-4 content-start overflow-y-auto overflow-x-hidden p-4',
    },
    sheet: {
      orientTowardsCamera: false,
    },
    size: {
      minWidth: '',
      minHeight: '',
      minDepth: '',
      maxWidth: '',
      maxHeight: '100vh',
      maxDepth: '',
    },
  },
  {
    id: 'template-breakout-room-list-item',
    info: {
      title: 'Breakout Room List Item',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'template-breakout-room-list',
    listener: {
      listeners: {
        click: {
          script: 'router.focus(data.id)',
          context: {},
        },
      },
    },
    class: {
      elementClass: '',
      class:
        'bg-white shadow-md rounded-md p-4 cursor-pointer hover:bg-primary-lightest w-full',
    },
    list: {
      items: [
        {
          id: 'BreakoutRoomListItemContent',
        },
        {
          id: 'BreakoutRoomListAvatars',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'BreakoutRoomListItemContent',
          query: ':[data]',
          identity: '',
        },
        {
          templateId: 'BreakoutRoomListAvatars',
          query: ':[data]',
          identity: '',
          condition: ':$$showRoomMembers',
        },
      ],
    },
  },
  {
    id: 'BreakoutRoomListItemContent',
    info: {
      title: 'Breakout Room List Item Content',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'template-breakout-room-list-item',
    list: {
      items: [
        {
          id: 'BreakoutRoomListItemText',
        },
        {
          id: 'BreakoutRoomListItemButton',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'BreakoutRoomListItemText',
          query: ':[data]',
        },
        {
          templateId: 'BreakoutRoomListItemButton',
          query: ':[data]',
        },
      ],
    },
    class: {
      class: 'flex flex-row justify-between items-center',
      elementClass: '',
    },
  },
  {
    id: 'BreakoutRoomListItemText',
    info: {
      title: 'Breakout Room List Item Text',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'BreakoutRoomListItemContent',
    element: {
      type: 'element.text',
      textContent: ': data.info.title',
      textFormat: 'p',
    },
  },
  {
    id: 'BreakoutRoomListItemButton',
    info: {
      title: 'Breakout Room List Item Button',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'BreakoutRoomListItemContent',
    element: {
      type: 'element.button',
      buttonText: '',
      buttonIcon: 'sign-in',
      buttonEvent: 'click',
    },
  },
  {
    id: 'BreakoutRoomListAvatars',
    info: {
      title: 'Breakout Room List Avatars',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'template-breakout-room-list-item',
    list: {
      items: [
        {
          id: 'BreakoutRoomListAvatar',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'BreakoutRoomListAvatar',
          query:
            ': getOnlineParticipants |> where(atScene($route), atStation(data.id))',
          condition: '',
        },
      ],
    },
    class: {
      class: 'flex flex-row flex-wrap gap-1 mt-4',
      elementClass: '',
    },
  },
  {
    id: 'BreakoutRoomListAvatar',
    info: {
      title: 'Breakout Room List Avatar',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'BreakoutRoomListAvatars',
    element: {
      type: 'element.participant',
      participantId: ':id',
      participantShape: 'circle',
      alwaysShowName: false,
      participantVideo: false,
      participantAudio: false,
      participantHideDetails: true,
    },
    class: {
      class: 'w-12 h-12',
      elementClass: 'h-full w-full',
    },
  },
  {
    id: 'BreakoutRoomsContainer',
    info: {
      title: 'Breakout Rooms Container',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-breakoutrooms',
    children: {
      default: [
        {
          templateId: 'fragment-simple-room',
          query: ':$$breakoutRooms',
          condition: '',
          identity: '${data.id}',
        },
      ],
    },
    sheet: {
      orientTowardsCamera: false,
    },
    arrangement: {
      type: 'arrangement.slides',
    },
    placement: {
      type: 'placement.absolute',
      absoluteLocation: {
        position: {
          x: 10000,
          y: 0,
          z: 0,
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: {
          x: 1,
          y: 1,
          z: 1,
        },
      },
    },
    list: {
      items: [],
    },
  },
];
