import store from '@/store';
import { Fillip } from '@fillip/vue-client';
import { Role } from '@fillip/api';
import { checkAndUpdateAccessToken, checkCommunityAccess } from './utils';

export default [
  {
    path: '/:slug/:urlPath*',
    name: 'Community',
    meta: { requiresAuth: true },
    component: () =>
      /* webpackChunkName: "main" */ import('@/features/main/Main.vue'),
    beforeEnter: async (to, from, next) => {
      const targetCommunity = to.params.slug;
      if (targetCommunity === from?.params?.slug) {
        return next();
      }

      await checkAndUpdateAccessToken(to, from, next);

      const user = Fillip.instance.state.user;
      const shell = store.getters.shell;

      // Check access rights if user has a restricted account
      if (user.role === Role.RESTRICTED) {
        await checkCommunityAccess(user, shell, targetCommunity, false, next);
      }
      try {
        await store.dispatch('loadActiveCommunity', targetCommunity);
        await Fillip.instance.realtime.communityConnection(targetCommunity);
        return next();
      } catch (error) {
        next({ name: 'Communities' });
        store.dispatch('displayError', error);
      }
    },
  },
];
