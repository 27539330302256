import { ModuleTypeMeta } from '../../common';

export interface ModuleNotes {
  notes: string;
}

export const ModuleNotesMeta: ModuleTypeMeta<ModuleNotes> = {
  default: {
    notes: '',
  },
  icon: 'exclamation-triangle',
};
