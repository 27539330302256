import { BaseComponent } from './BaseComponent';
import { Object3D } from 'three';

export const BaseObject3D = {
  inject: ['canvas'],
  mixins: [BaseComponent],
  beforeCreate() {
    this.$object3D = new Object3D();
  },
};
