import { CommunityProfile } from './communities';

export enum Role {
  HOST = 'host',
  ADMIN = 'admin',
  EDITOR = 'editor',
  PARTICIPANT = 'participant',
  RESTRICTED = 'restricted',
  INACTIVE = 'inactive',
}

export enum Verified {
  PENDING = 'pending',
  VERIFIED = 'verified',
  NO_EMAIL = 'no-email',
}

export enum UserType {
  LOCAL = 'local',
  GOOGLE = 'google',
  DISCORD = 'discord',
  ANONYMOUS = 'anonymous',
  IMPORTED = 'imported',
}

export interface User {
  id: number;
  username: string;
  email: string;
  pendingEmail?: string;
  role: Role;
  shell?: string;
  domain?: string;
  isVerified: Verified;
  userType: UserType;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  profiles: CommunityProfile[];
  jwtInvalidation?: number;
}

export interface RegisterWithUsernameEmailAndPasswordRequest {
  username: string;
  email: string;
  password: string;
  shell: string;
  domain: string;
  role?: Role;
}

export interface RegisterAnonymousFrontendRequest {
  username: string;
  domain: string;
  shell: string;
  restrictCommunities: string[];
}
