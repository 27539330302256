import { DataDocument, PresetDefinition } from '@fillip/api';

export const TemplateOnboarding: DataDocument = {
  id: 'onboarding',
  info: {
    title: 'Onboarding',
  },
  tag: {
    tag: 'template',
  },
  list: {
    items: [
      {
        id: 'OnboardingContainer',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'OnboardingContainer',
        query: ':[data]',
        identity: '',
        condition: ':$$onboardingActive',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'onboardingActive',
        query: ":getVolatileProp('onboarding','isOn',true)",
      },
      {
        identifier: 'isDirty',
        query: ":getVolatileProp('onboarding','isDirty',false)",
      },
    ],
  },
  actions: {
    actions: [
      {
        name: 'Button',
        type: 'action.button',
        icon: 'question-circle',
        color: 'rgba(117, 117, 117,1)',
        script:
          "setVolatileProp('onboarding','isOn',true);setVolatileProp('onboarding','activeStep',0) ",
        id: 'default',
        focused: 'always',
        condition: ':!$$onboardingActive && $$isDirty',
        roles: null,
      },
    ],
  },
};

export const TemplateOnboardingChildren: DataDocument[] = [
  {
    id: 'OnboardingContainer',
    info: {
      title: 'Onboarding:Container',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'onboarding',
    list: {
      items: [
        {
          id: 'OnboardingBackdrop',
        },
        {
          id: 'OnboardingSteps',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'OnboardingBackdrop',
          query: ':[data]',
          condition: '',
          identity: '',
        },
        {
          templateId: 'OnboardingSteps',
          query: ':[data]',
          condition: '',
          identity: '',
        },
      ],
    },
    class: {
      class: 'p-6 w-full h-full grid grid-cols-1',
      elementClass: '',
    },
    actions: {
      actions: [
        {
          name: 'LastStep',
          type: 'action.button',
          icon: 'chevron-circle-left',
          color: 'rgba(117, 117, 117,1)',
          script: "setVolatileProp('onboarding','activeStep',$$activeStep-1)",
          id: 'PreviousStep',
          focused: 'always',
          condition: ':!$$isFirst && !$$onboardingDone',
        },
        {
          name: 'NextStep',
          type: 'action.button',
          icon: 'chevron-circle-right',
          color: 'rgba(117, 117, 117,1)',
          script:
            "$$isLast ? (setVolatileProp('onboarding','isOn',false),setVolatileProp('onboarding','isDirty',true)) : setVolatileProp('onboarding','activeStep',$$activeStep+1) ",
          id: 'NextStep',
          focused: 'always',
          condition: ':!$$onboardingDone',
        },
      ],
    },
    variables: {
      variables: [
        {
          identifier: 'activeStep',
          query: ":getVolatileProp('onboarding','activeStep') ||0",
        },
        {
          identifier: 'onboardingDone',
          query: ":$$activeStep  >= getList('OnboardingSteps')().length",
        },
        {
          identifier: 'isFirst',
          query: ':$$activeStep <=0',
        },
        {
          identifier: 'isLast',
          query: ":$$activeStep  >= getList('OnboardingSteps')().length-1",
        },
      ],
    },
    sheet: {
      orientTowardsCamera: false,
    },
  },
  {
    id: 'OnboardingBackdrop',
    info: {
      title: 'Onboarding:Backdrop',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'OnboardingContainer',
    class: {
      class: 'absolute bg-gray-500 bg-opacity-50',
      elementClass: '',
    },
    placement: {
      type: 'placement.absolute',
      absoluteLocation: {
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: {
          x: 1,
          y: 1,
          z: 1,
        },
      },
    },
    size: {
      minWidth: '100vw',
      minHeight: '100vh',
      minDepth: '',
      maxWidth: '100vw',
      maxHeight: '100vh',
      maxDepth: '',
    },
    sheet: {
      orientTowardsCamera: false,
    },
  },
  {
    id: 'OnboardingSteps',
    info: {
      title: 'Onboarding:Steps',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'OnboardingContainer',
    sheet: {
      orientTowardsCamera: false,
    },
    list: {
      items: [
        {
          id: 'Step1',
        },
        {
          id: 'Step2',
        },
        {
          id: 'Step3',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'Step1',
          query: ':[data]',
          condition: ':$$activeStep==0',
          identity: 'onboarding',
        },
        {
          templateId: 'Step2',
          query: ':[data]',
          condition: ':$$activeStep==1',
          identity: 'onboarding',
        },
        {
          templateId: 'Step3',
          query: ':[data]',
          condition: ':$$activeStep==2',
          identity: 'onboarding',
        },
      ],
    },
    placement: {
      type: 'conditional',
      variants: [
        {
          condition: ':$$activeStep == 0',
          value: {
            type: 'placement.fixed',
            fixedHorizontal: 'center',
            fixedVertical: 'center',
          },
        },
        {
          condition: ':$$activeStep == 1',
          value: {
            type: 'placement.fixed',
            fixedHorizontal: 'left',
            fixedVertical: 'bottom',
          },
        },
        {
          condition: '',
          value: {
            type: 'placement.fixed',
            fixedHorizontal: 'center',
            fixedVertical: 'bottom',
          },
        },
      ],
    },
  },
  {
    id: 'Step1',
    info: {
      title: 'Step 1',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'OnboardingSteps',
    list: {
      items: [
        {
          id: 'Step1Title',
        },
        {
          id: 'Step1Image',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'Step1Title',
          query: ':[data]',
        },
        {
          templateId: 'Step1Image',
          query: ':[data]',
          identity: '',
        },
      ],
    },
    properties: {},
    sheet: {
      orientTowardsCamera: false,
    },
    class: {
      class: 'bg-white p-2 w-96  rounded-lg',
      elementClass: '',
    },
  },
  {
    id: 'Step1Title',
    info: {
      title: 'Step: Title',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'Step1',
    element: {
      type: 'element.text',
      textContent: 'Herzlich willkommen',
      textFormat: 'p',
    },
    class: {
      class: '',
      elementClass: 'text-center text-primary font-bold ',
    },
  },
  {
    id: 'Step1Image',
    info: {
      title: 'Step:Image',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'Step1',
    element: {
      type: 'element.image',
      imageSrc:
        'https://fillip.ai/wp-content/uploads/2021/10/dt-21-onboarding-1.svg',
      imageDescription: '',
      imageCopyright: '',
    },
    class: {
      class: '',
      elementClass: 'h-full',
    },
  },
  {
    id: 'Step2',
    info: {
      title: 'Step 2',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'OnboardingSteps',
    list: {
      items: [
        {
          id: 'Step2Title',
        },
        {
          id: 'Step2Description1',
        },
        {
          id: 'Step2Description2',
        },
        {
          id: 'Step2Image',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'Step2Title',
          query: ':[data]',
        },
        {
          templateId: 'Step2Image',
          query: ':[data]',
          identity: '',
        },
        {
          templateId: 'Step2Description1',
          query: ':[data]',
        },
        {
          templateId: 'Step2Description2',
          query: ':[data]',
          identity: '',
        },
      ],
    },
    properties: {},
    sheet: {
      orientTowardsCamera: false,
    },
    class: {
      class: 'bg-white p-2 w-96 rounded-lg',
      elementClass: '',
    },
    placement: {
      type: 'placement.absolute',
      absoluteLocation: {
        position: {
          x: 0,
          y: 60,
          z: 0,
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: {
          x: 1,
          y: 1,
          z: 1,
        },
      },
    },
  },
  {
    id: 'Step2Title',
    info: {
      title: 'Step2: Title',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'Step2',
    element: {
      type: 'element.text',
      textContent: 'Du hast Fragen oder benötigst Hilfe? ',
      textFormat: 'p',
    },
    class: {
      class: '',
      elementClass: 'text-center text-primary font-bold ',
    },
  },
  {
    id: 'Step2Description1',
    info: {
      title: 'Step2: Description1',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'Step2',
    element: {
      type: 'element.text',
      textContent:
        '… Geh zum Infopoint, wenn du Fragen rund um das Programm hast.',
      textFormat: 'p',
    },
    class: {
      class: '',
      elementClass: 'text-layout-darkest',
    },
  },
  {
    id: 'Step2Description2',
    info: {
      title: 'Step2: Description2',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'Step2',
    element: {
      type: 'element.text',
      textContent:
        '… Geh zum Technik-Support, wenn du technischen Schwierigkeiten hast.',
      textFormat: 'p',
    },
    class: {
      class: '',
      elementClass: 'text-layout-darkest',
    },
  },
  {
    id: 'Step2Image',
    info: {
      title: 'Step:Image',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'Step2',
    element: {
      type: 'element.image',
      imageSrc:
        'https://fillip.ai/wp-content/uploads/2021/10/dt-21-onboarding-2.svg',
      imageDescription: '',
      imageCopyright: '',
    },
    class: {
      class: '',
      elementClass: 'h-full',
    },
  },
  {
    id: 'Step3',
    info: {
      title: 'Step 3',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'OnboardingSteps',
    list: {
      items: [
        {
          id: 'Step3Description',
        },
        {
          id: 'Step3Title',
        },
        {
          id: 'Step3Image',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'Step3Title',
          query: ':[data]',
        },
        {
          templateId: 'Step3Image',
          query: ':[data]',
          identity: '',
        },
        {
          templateId: 'Step3Description',
          query: ':[data]',
        },
      ],
    },
    properties: {},
    sheet: {
      orientTowardsCamera: false,
    },
    class: {
      class: 'bg-white p-2 w-96  rounded-lg',
      elementClass: '',
    },

    placement: {
      type: 'placement.absolute',
      absoluteLocation: {
        position: {
          x: 0,
          y: 60,
          z: 0,
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: {
          x: 1,
          y: 1,
          z: 1,
        },
      },
    },
  },
  {
    id: 'Step3Description',
    info: {
      title: 'Step3: Description',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'Step3',
    element: {
      type: 'element.text',
      textContent:
        'Klicke auf deinen Avatar unten, um deine Profil-, Status-, Ton- und Video-Einstellungen zu verwalten.',
      textFormat: 'p',
    },
    class: {
      class: '',
      elementClass: 'text-layout-darkest ',
    },
  },
  {
    id: 'Step3Title',
    info: {
      title: 'Step: Title',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'Step3',
    element: {
      type: 'element.text',
      textContent: 'Dein Profil, deine Kamera & dein Ton',
      textFormat: 'p',
    },
    class: {
      class: '',
      elementClass: 'text-center text-primary font-bold ',
    },
  },
  {
    id: 'Step3Image',
    info: {
      title: 'Step:Image',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'Step3',
    element: {
      type: 'element.image',
      imageSrc:
        'https://fillip.ai/wp-content/uploads/2021/10/dt-21-onboarding-3.svg',
      imageDescription: '',
      imageCopyright: '',
    },
    class: {
      class: '',
      elementClass: 'h-full',
    },
  },
];
export const PresetOnboarding: PresetDefinition = {
  id: 'PresetOnboarding',
  title: 'Onboarding',
  icon: 'question-circle',
  group: 'templates',
  version: '0.1',
  documents: [TemplateOnboarding, ...TemplateOnboardingChildren],
};
