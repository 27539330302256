import { ModuleTypeMeta } from '../../common';

export interface ModuleClass {
  class: string;
  elementClass: string;
}

export const ModuleClassMeta: ModuleTypeMeta<ModuleClass> = {
  default: {
    class: '',
    elementClass: '',
  },
};
