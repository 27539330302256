import * as acorn from 'acorn';
import {
  Node,
  BinaryExpression,
  Expression,
  MemberExpression,
  CallExpression,
  ConditionalExpression,
  TemplateLiteral,
  TemplateElement,
  ObjectExpression,
  SpreadElement,
  Property,
  ArrayExpression,
  LogicalExpression,
  UnaryExpression,
  SequenceExpression,
  ChainExpression,
  VariableDeclarator,
  ArrowFunctionExpression,
  SimpleCallExpression,
  BlockStatement,
  Literal,
  Identifier,
  ThisExpression,
  VariableDeclaration,
  ReturnStatement,
  Statement,
  ExpressionStatement,
  AssignmentExpression,
  IfStatement,
} from 'estree';
import { evaluate } from '.';

import { EvaluationContext } from './types';

function notImplemented(expression: any, message: string = '') {
  console.warn(
    `Interpreter: ${expression.type} not implemented`,
    expression,
    message,
  );
  return 'Not implemented';
}

const evaluateParsedExpression = {
  Literal: evaluateLiteral,
  Identifier: evaluateIdentifier,
  ThisExpression: evaluateThisExpression,
  BinaryExpression: evaluateBinaryExpression,
  LogicalExpression: evaluateLogicalExpression,
  CallExpression: evaluateCallExpression,
  TemplateLiteral: evaluateTemplateLiteralExpression,
  ConditionalExpression: evaluateConditionalExpression,
  ChainExpression: evaluateChainExpression,
  MemberExpression: evaluateMemberExpression,
  ObjectExpression: evaluateObjectExpression,
  ArrayExpression: evaluateArrayExpression,
  UnaryExpression: evaluateUnaryExpression,
  SequenceExpression: evaluateSequenceExpression,
  ArrowFunctionExpression: evaluateArrowFunctionExpression,
  BlockStatement: evaluateBlockStatement,
  VariableDeclaration: evaluateVariableDeclaration,
  VariableDeclarator: evaluateVariableDeclaration,
  ReturnStatement: evaluateReturnStatement,
  ExpressionStatement: evaluateExpressionStatement,
  AssignmentExpression: evaluateAssignmentExpression,
  IfStatement: evaluateIfStatement,
};

export function evaluateExpression(
  context: EvaluationContext,
  expression: Expression | Statement | Node,
) {
  // console.log('evaluateExpression', expression);
  if (evaluateParsedExpression[expression.type]) {
    return evaluateParsedExpression[expression.type](context, expression);
  }
  return notImplemented(expression);
}

function evaluateVariableDeclaration(
  context: EvaluationContext,
  declarations: VariableDeclaration,
) {
  for (const declaration of declarations.declarations) {
    const [name, result] = evaluateVariableDeclarator(context, declaration);
    context.scope[name] = result;
  }
}
function evaluateVariableDeclarator(
  context: EvaluationContext,
  declarator: VariableDeclarator,
) {
  if (declarator.id.type != 'Identifier') {
    notImplemented(declarator);
    return;
  }
  const result = evaluateExpression(context, declarator.init);
  const name = declarator.id.name;
  return [name, result];
}

function evaluateBinaryExpression(
  context: EvaluationContext,
  expression: BinaryExpression,
) {
  const left = evaluateExpression(context, expression.left);
  const right = evaluateExpression(context, expression.right);

  switch (expression.operator) {
    case '-':
      return left - right;
    case '+':
      return left + right;
    case '*':
      return left * right;
    case '/':
      return left / right;
    case '%':
      return left % right;
    case '>':
      return left > right;
    case '<':
      return left < right;
    case '>=':
      return left >= right;
    case '<=':
      return left <= right;
    case '!=':
      return left != right;
    case '==':
      return left == right;
    case '!==':
      return left !== right;
    case '===':
      return left === right;
    default:
      return notImplemented(expression);
  }
}

function evaluateLogicalExpression(
  context: EvaluationContext,
  expression: LogicalExpression,
) {
  const left = evaluateExpression(context, expression.left);
  // right is evaluated lazily since it's not needed if left is executed

  if (expression.operator == '&&') {
    return left && evaluateExpression(context, expression.right);
  }
  if (expression.operator == '||') {
    return left || evaluateExpression(context, expression.right);
  }
  if (expression.operator == '??') {
    return left ?? evaluateExpression(context, expression.right);
  }

  return notImplemented(expression);
}

function evaluateIfStatement(
  context: EvaluationContext,
  expression: IfStatement,
) {
  if (evaluateExpression(context, expression.test)) {
    return evaluateExpression(context, expression.consequent);
  } else if (expression.alternate) {
    return evaluateExpression(context, expression.alternate);
  }
}

function evaluateCallExpression(
  context: EvaluationContext,
  expression: CallExpression,
) {
  if (expression.callee.type == 'Super') return notImplemented(expression);
  const fn = evaluateExpression(context, expression.callee);
  return fn(
    ...expression.arguments.reduce((args, expr) => {
      if (expr.type == 'SpreadElement') {
        args.push(...evaluateExpression(context, expr.argument));
      } else {
        args.push(evaluateExpression(context, expr));
      }
      return args;
    }, []),
  );
}

function evaluateTemplateLiteralExpression(
  context: EvaluationContext,
  expression: TemplateLiteral,
) {
  const resolved = expression.expressions.map((exp) => {
    const result = evaluateExpression(context, exp);
    if (typeof result == 'function') return result();
    return result;
  });

  return expression.quasis
    .map((el: TemplateElement, index: number) => {
      const text = el.value.cooked || el.value.raw;
      return el.tail ? text : text + resolved[index];
    })
    .join('');
}

function evaluateConditionalExpression(
  context: EvaluationContext,
  expression: ConditionalExpression,
) {
  return Boolean(evaluateExpression(context, expression.test))
    ? evaluateExpression(context, expression.consequent)
    : evaluateExpression(context, expression.alternate);
}

function evaluateChainExpression(
  context: EvaluationContext,
  expression: ChainExpression,
) {
  return evaluateExpression(context, expression.expression);
}

function evaluateMemberExpression(
  context: EvaluationContext,
  expression: MemberExpression,
) {
  function bindIfFunction(fn, object) {
    return typeof fn == 'function' ? fn.bind(object) : fn;
  }
  if (expression.object.type == 'Super') return notImplemented(expression);
  const object = evaluateExpression(context, expression.object);
  // Support optional chaining by returning undefined if an optional object is missing
  // (instead of throwing an error)
  if (!object && expression.optional) {
    return undefined;
  }
  const lookup = expression.computed
    ? evaluateExpression(context, expression.property as any)
    : expression.property.type == 'Identifier'
    ? expression.property.name
    : undefined;

  return bindIfFunction(object[lookup], object);
}

function evaluateObjectExpression(
  context: EvaluationContext,
  expression: ObjectExpression,
) {
  return expression.properties.reduce(
    (acc: Record<string, any>, exp: SpreadElement | Property) => {
      if (exp.type == 'SpreadElement') {
        const result = evaluateExpression(context, exp.argument);
        for (const [key, value] of Object.entries(result)) {
          acc[key] = value;
        }
        return acc;
      }
      if (exp.type == 'Property') {
        if (exp.kind !== 'init')
          return notImplemented(
            expression,
            'ObjectExpressions with Getters/Setters are not supported.',
          );
        let key;
        if (exp.key.type == 'PrivateIdentifier') {
          key = exp.key.name;
        } else if (exp.key.type == 'Identifier' && !exp.computed) {
          key = exp.key.name;
        } else {
          key = evaluateExpression(context, exp.key);
        }
        acc[key] = evaluateExpression(context, exp.value as any);
        return acc;
      }
      return notImplemented(exp);
    },
    {},
  );
}

function evaluateArrayExpression(
  context: EvaluationContext,
  expression: ArrayExpression,
) {
  return expression.elements.reduce(
    (acc: Array<any>, exp: SpreadElement | Expression) => {
      if (exp.type == 'SpreadElement') {
        acc.push(...evaluateExpression(context, exp.argument));
        return acc;
      }
      acc.push(evaluateExpression(context, exp));
      return acc;
    },
    [],
  );
}

function evaluateUnaryExpression(
  context: EvaluationContext,
  expression: UnaryExpression,
) {
  const result = evaluateExpression(context, expression.argument);
  switch (expression.operator) {
    case '!':
      return !result;
    case '+':
      return +result;
    case '-':
      return -result;
    case 'typeof':
      return typeof result;
    case 'void':
      return notImplemented(expression);
    case 'delete':
      return notImplemented(expression);
    default:
      return notImplemented(expression);
  }
}

function evaluateSequenceExpression(
  context: EvaluationContext,
  expression: SequenceExpression,
) {
  for (const expr of expression.expressions) {
    evaluateExpression(context, expr);
  }
  return true;
}

function evaluateArrowFunctionExpression(
  context: EvaluationContext,
  expression: ArrowFunctionExpression,
) {
  const body = expression.expression
    ? [expression.body]
    : (expression.body as BlockStatement).body;

  function fn(...args) {
    for (let index = 0; index < expression.params.length; index++) {
      const param = expression.params[index];
      if (param.type != 'Identifier')
        throw new Error(notImplemented(param.type));
      context.scope[param.name] = args[index];
    }

    for (let index = 0; index < body.length; index++) {
      if (index == body.length - 1) {
        return evaluateExpression(context, body[index]);
      }
      evaluateExpression(context, body[index]);
    }
  }
  return fn;
}

function evaluateBlockStatement(
  context: EvaluationContext,
  expression: BlockStatement,
) {
  const body = expression.body;
  for (let index = 0; index < body.length; index++) {
    if (index == body.length - 1) {
      return evaluateExpression(context, body[index]);
    }
    evaluateExpression(context, body[index]);
  }
}

function evaluateLiteral(_context: EvaluationContext, expression: Literal) {
  return expression.value;
}

function evaluateIdentifier(
  context: EvaluationContext,
  expression: Identifier,
) {
  if (expression.name === 'context') return context;
  if (context.scope?.[expression.name] !== undefined) {
    return context.scope?.[expression.name];
  }
  if (context.data?.[expression.name] !== undefined) {
    return context.data[expression.name];
  }
  if (context.local?.[expression.name] !== undefined) {
    return context.local[expression.name];
  }
  if (context.props?.[expression.name] !== undefined) {
    return context.props[expression.name];
  }
  if (context.computeds?.[expression.name] !== undefined) {
    return context.computeds[expression.name];
  }
  if (context.variables?.[expression.name] !== undefined) {
    return context.variables[expression.name];
  }
  if (context.environment?.[expression.name] !== undefined) {
    return context.environment[expression.name];
  }
  return undefined;
}

function evaluateThisExpression(
  context: EvaluationContext,
  _expression: ThisExpression,
) {
  return context.vm;
}

function evaluateReturnStatement(
  context: EvaluationContext,
  statement: ReturnStatement,
) {
  return evaluateExpression(context, statement.argument);
}

function evaluateExpressionStatement(
  context: EvaluationContext,
  statement: ExpressionStatement,
) {
  return evaluateExpression(context, statement.expression);
}

function evaluateAssignmentExpression(
  context: EvaluationContext,
  expression: AssignmentExpression,
) {
  const { operator } = expression;
  const left = evaluateExpression(context, expression.left);
  const right = evaluateExpression(context, expression.right);
  console.log('Assignment', expression, left, right);

  switch (operator) {
    case '=':
      context.scope[left] = right;
      console.log('Context', context.scope);
      break;
    case '+=':
      context.scope[left] += right;
      break;
    case '-=':
      context.scope[left] -= right;
      break;
    case '*=':
      context.scope[left] *= right;
      break;
    case '/=':
      context.scope[left] /= right;
      break;
    case '%=':
      context.scope[left] %= right;
      break;
    case '**=':
      context.scope[left] **= right;
      break;
    case '<<=':
      context.scope[left] <<= right;
      break;
    case '>>=':
      context.scope[left] >>= right;
      break;
    case '>>>=':
      context.scope[left] >>>= right;
      break;
    case '|=':
      context.scope[left] |= right;
      break;
    case '^=':
      context.scope[left] ^= right;
      break;
    case '&=':
      context.scope[left] &= right;
      break;
    default:
      return notImplemented(expression);
  }
}
