













































import Vue from 'vue';
import SchemaFieldsEditor from './SchemaFieldsEditor.vue';
import { useData, useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleInterfaceDefinitionMeta } from '@fillip/api';
import clone from 'rfdc/default';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'InterfaceDefinitionEditor',
  components: {
    SchemaFieldsEditor,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { getData } = useData();
    const { buffer, save } = useSave(props, {
      path: 'interfaceDefinition',
    });

    const updateFields = (fields) => {
      Vue.set(buffer.value, 'props', clone(fields));
      save();
    };
    const init = () => {
      buffer.value = clone(ModuleInterfaceDefinitionMeta.default);
      save();
    };

    const inheritedProps = computed(() => {
      if (!buffer.value?.inheritFrom) return;
      return (
        getData(buffer.value?.inheritFrom)?.interfaceDefinition?.props || null
      );
    });

    return {
      buffer,
      save,
      updateFields,
      init,
      inheritedProps,
    };
  },
});
