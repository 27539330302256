import {
  Tag,
  DataDocument,
  TagCategory,
  TagDefinition,
  ModuleTagDefinition,
} from '@fillip/api';
import store from '@/store';

import { computed } from '@vue/composition-api';

const tags = computed<DataDocument[]>(() => store.getters.tags);

const icons = computed<Record<string, string>>(() => {
  return tags.value.reduce((acc, tag) => {
    acc[tag.tagDefinition.tag] = tag.tagDefinition.icon || tag.info.icon;
    return acc;
  }, {});
});

const tagDefinitions = computed<TagDefinition[]>(() =>
  tags.value.map((t: DataDocument) => ({
    id: t.id,
    ...t.tagDefinition,
  })),
);
const tagDefinitionsSelectOptions = computed<
  (TagDefinition & { value: string; text: string })[]
>(() =>
  tagDefinitions.value.map((t: TagDefinition) => ({
    value: t.tag,
    text: t.title,
    ...t,
  })),
);

export function useTags() {
  const getTagsByCategory = (category: TagCategory) =>
    tagDefinitions.value.filter((t) => t.category === category);

  const getTagByTag = (tag: Tag) =>
    tagDefinitions.value.find((t) => t.tag === tag);

  const getDocByTag = (tag: Tag): DataDocument =>
    tags.value.find((t) => t.tagDefinition.tag === tag);

  const getDataIcon = (data: DataDocument) => {
    if (data.info?.icon) return data.info.icon;

    return icons.value[data.tag.tag] || 'object-group';
  };

  const getAllowedChildTags = (
    tag: Tag,
    allowedTags: Tag[] = [],
  ): TagDefinition[] => {
    const tagDefinition = getTagByTag(tag);
    if (!tagDefinition) return [];

    let allowedCategories = tagDefinition?.allowedTagCategories || [];
    const allowedTagList = [
      ...(tagDefinition?.allowedTags || []),
      ...allowedTags,
    ];

    if (!allowedCategories.length && !allowedTags.length)
      allowedCategories = ['content'];

    return tagDefinitions.value.filter(
      (tag: ModuleTagDefinition) =>
        allowedCategories.includes(tag.category) ||
        allowedTags.includes(tag.tag),
    );
  };

  return {
    // Computeds
    tags,
    tagDefinitions,
    tagDefinitionsSelectOptions,

    // Getters
    getDataIcon,
    getTagsByCategory,
    getTagByTag,
    getDocByTag,
    getAllowedChildTags,
  };
}
