




import MediaEditor from '../components/MediaEditor.vue';

export default {
  name: 'Video360Editor',
  components: {
    MediaEditor,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    library() {
      return [
        {
          text: this.$t('model.video360.options.climateArena'),
          value: `${process.env.MINIO_URL}/media/videos-360/rundgang1.mp4`,
        },
        {
          text: this.$t('model.video360.options.tunnel'),
          value: `${process.env.MINIO_URL}/media/videos-360/pano.mp4`,
        },
        {
          text: this.$t('model.video360.options.rainforest'),
          value: `${process.env.MINIO_URL}/media/videos-360/nepada.mp4`,
        },
      ];
    },
  },
};
