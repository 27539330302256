




































import Vue from 'vue';
import { useSave, useData } from '@/composables';
import PrimitiveDataSelector from '@/components/primitive-editor/types/PrimitiveDataSelector.vue';
import { useVolatileData } from '@/composables';
export default Vue.extend({
  name: 'ActionRoleManager',
  components: {
    PrimitiveDataSelector,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    vPropId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canvasSlot: {
      type: String,
      default: 'bottom',
    },
    name: {
      type: String,
      default: function () {
        return this.$t('action.roleManager.title');
      },
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    size: {
      type: String,
    },
    vMinimized: {
      type: String,
    },
  },

  setup(props: any) {
    const { buffer, data, save } = useSave(props, {
      path: 'roles',
    });
    const { getVolatileProp } = useVolatileData();
    const { getData } = useData();

    return {
      buffer,
      data,
      save,
      getVolatileProp,
      getData,
    };
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    roles() {
      if (!this.data || !this.data.roles) return;
      return Object.values(this.data.roles);
    },
    //TODO make outsource volatileProp creation
    minimized() {
      return this.getVolatileProp(this.vPropId, this.vMinimized, true);
    },
    doc() {
      return this.getData(this.id);
    },
    title() {
      return this.doc.info.title;
    },
  },
  methods: {
    setRoleMembers(key, members) {
      (this as any).buffer.roles[key].documents = members;
      (this as any).save();
    },
  },
});
