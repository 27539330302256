






























import CommunityHubCard from './CommunityHubCard.vue';
import { useCommunities } from '@/composables';
import {
  computed,
  defineComponent,
  PropType,
  reactive,
} from '@vue/composition-api';
import { Community } from '@fillip/api';

export default defineComponent({
  name: 'CommunityHubSection',
  components: { CommunityHubCard },
  props: {
    communities: {
      type: Array as PropType<Community[]>,
      required: true,
    },
    communitySection: {
      type: String,
      required: true,
    },
    canCreateCommunity: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { gotoAddCommunity } = useCommunities();

    const defaultSize = {
      createdCommunities: 3,
      joinedCommunities: 3,
      publicCommunities: 6,
    };

    const size = reactive({ ...defaultSize });

    const showMore = () => {
      const section = props.communitySection;
      if (size[section] < props.communities.length)
        size[section] = props.communities.length;
      else size[section] = defaultSize[section];
    };

    const visibleCommunities = computed(() => {
      return props.communities.slice(0, size[props.communitySection]);
    });

    return {
      gotoAddCommunity,
      defaultSize,
      size,
      showMore,
      visibleCommunities,
    };
  },
});
