
































































































import Vue from 'vue';
import { useSave, useData, usePermissions, useTags } from '@/composables';
import InputAny from '@/components/input/any/InputAny.vue';
import { BaseModuleEditor } from '../base';
import {
  DataDocument,
  ModuleProperties,
  TypeAny,
  toAny,
  BuiltinTypesMeta,
  SchemaReference,
  SchemaField,
} from '@fillip/api';

export default Vue.extend({
  name: 'PropertiesEditorLarge',
  components: {
    InputAny,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
    schema: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'row',
    },
  },
  setup(props: any) {
    const { getData } = useData();
    const { getDocByTag } = useTags();
    const { canEditCommunity } = usePermissions();
    const { buffer, save } = useSave(props, {
      path: 'properties',
      defaultValue: {},
      checkEqualityBeforeSave: true,
      convertFromBuffer: (
        buffer: Array<{ key: string; value: TypeAny }>,
      ): ModuleProperties =>
        buffer.reduce((result, { key, value }) => {
          if (key) {
            result[key] = value;
          }
          return result;
        }, {}),
      convertToBuffer: (
        data: ModuleProperties,
      ): Array<{ key: string; value: TypeAny }> =>
        Object.keys(data).map((key) => ({
          key,
          type: data[key].type,
          value: data[key],
        })),
    });
    return {
      getData,
      getDocByTag,
      buffer,
      save,
      canEditCommunity,
    };
  },
  data() {
    return {
      panel: 0,
    };
  },
  computed: {
    properties() {
      if (!this.schemaFields) return this.buffer;
      return [...this.buffer, ...this.unsetSchemaFields];
    },
    schemaFields() {
      const schemaFields = {};
      const doc: DataDocument = this.getData(this.id);

      if (doc.parentId) {
        this.loadSchemaDefinitions(doc.parentId, ['fields'], schemaFields);
      }

      if (doc.tag?.tag) {
        const tagDoc = this.getDocByTag(doc.tag?.tag);
        this.loadSchemaDefinitions(tagDoc, ['entities'], schemaFields);
        this.loadSchemaDefinitions(tagDoc, ['interface'], schemaFields);
      }

      if (doc.schema?.loadFrom?.length) {
        (doc.schema?.loadFrom as SchemaReference[]).forEach((s) => {
          this.loadSchemaDefinitions(s.loadFromId, s.loadFields, schemaFields);
        });
      }

      if (doc.schema?.ownFields?.length) {
        this.addSchemaDefinitions(doc.schema.ownFields, schemaFields);
      }

      if (this.schema && this.schema.length) {
        this.addSchemaDefinitions(this.schema, schemaFields);
      }

      return Object.values(schemaFields);
    },
    unsetSchemaFields() {
      return this.schemaFields
        .filter(
          (field) =>
            this.buffer.findIndex((buffer) => buffer.key == field.key) == -1,
        )
        .map((field) => {
          return {
            ...field,
            isUnset: true,
            value: toAny(field.type),
          };
        });
    },
    inputTypes() {
      return Object.keys(BuiltinTypesMeta).map((value) => ({
        value,
        text: BuiltinTypesMeta[value].name,
      }));
    },
    isVertical() {
      return this.variant == 'column' || !this.$vuetify.breakpoint.mdAndUp;
    },
  },
  methods: {
    buttonAddProp() {
      if (this.panel != 0) this.panel = 0;
      this.addProperty(this.buffer.length - 1);
    },
    jumpToNextField(index) {
      // TODO: Fix to work with fields added by schema
      const nextIndex = index + 1;
      this.$refs.inputAny[nextIndex]?.focus();
    },

    async addProperty(index) {
      // ?Does Async-await on save have any impact here?
      await this.save();
      if (index == this.buffer.length - 1) {
        this.buffer.push({
          key: '',
          type: 'string',
          value: {
            type: 'string',
            value: '',
          },
        });
      }
      await this.$nextTick();
      this.jumpToNextField(index);
    },
    removeProperty(indexToRemove) {
      this.buffer = this.buffer.filter((key, index) => index != indexToRemove);
      this.save();
    },
    checkForRemoveItem(e, indexToRemove) {
      if (e.target.value) return;
      if (this.forbidEditing && !this.canEditCommunity) return;
      if (!e.target.value) {
        setTimeout(() => {
          this.removeProperty(indexToRemove);
          this.jumpToNextField(indexToRemove - 2);
        }, 5);
      }
    },
    async addFromSchema({ key, value }) {
      this.buffer.push({
        key,
        type: value.type,
        value,
      });
      this.save();
      await this.$nextTick();
      // setTimeout(() => {
      const editorComponentRef =
        this.$refs[`input-${key}`][0]?.$refs.editorComponent;
      if (editorComponentRef?.$refs.dataSelector?.$refs.inputComponent)
        editorComponentRef.$refs.dataSelector.$refs.inputComponent.focus();
      else if (editorComponentRef?.$refs.inputComponent)
        editorComponentRef.$refs.inputComponent.focus();
      // }, 25);
    },
    focus(property) {
      if (!property.isUnset) return;
      this.addFromSchema(property);
    },
    getSchemaFromKey(key: string) {
      if (!this.schemaFields) return false;
      return this.schemaFields.find((schema) => schema.key == key);
    },
    isFromSchema(property) {
      if (!this.schemaFields) return false;
      return (
        this.schemaFields.findIndex((schema) => schema.key == property.key) > -1
      );
    },
    getSchemaProperty(objectProperty, schemaProperty: string) {
      const schema = this.getSchemaFromKey(objectProperty.key);
      return schema && schema[schemaProperty] ? schema[schemaProperty] : null;
    },
    getSchemaTags(property) {
      if (['participants'].includes(property.type)) {
        return ['participant'];
      }
      return this.getSchemaProperty(property, 'tags');
    },
    loadSchemaDefinitions(
      docOrId: string | DataDocument,
      loadFields: SchemaReference['loadFields'],
      schemaFields: Record<string, any> = {},
    ) {
      if (!docOrId) return [];
      const doc = typeof docOrId === 'string' ? this.getData(docOrId) : docOrId;
      const { schema, interfaceDefinition } = doc;
      const result = [];

      for (const key of loadFields) {
        if (key === 'interface' && interfaceDefinition) {
          if (interfaceDefinition.inheritFrom) {
            const interfaceSchema = this.getData(
              interfaceDefinition.inheritFrom,
            )?.interfaceDefinition?.props;
            if (interfaceSchema) result.push(...interfaceSchema);
          }
          if (interfaceDefinition.props)
            result.push(...interfaceDefinition.props);
        }
        if (schema?.[key]) result.push(...schema[key]);
      }
      this.addSchemaDefinitions(result, schemaFields);
      return result;
    },
    addSchemaDefinitions(
      schemaDefinitions: SchemaField[],
      schemaFields: Record<string, any> = {},
    ) {
      for (const schema of schemaDefinitions) {
        schemaFields[schema.key] = schema;
      }
    },
    updateKey(index, newValue) {
      this.properties[index].key = newValue;
      this.save();
    },
  },
});
