import Vue from 'vue';
import VueRouter from 'vue-router';
import goTo from 'vuetify/es5/services/goto';
import store from '@/store';
import { Fillip } from '@fillip/vue-client';
import i18n from '@/plugins/i18n';
import authRoutes from './auth';
import communitiesRoutes from './communities';
import mainRoutes from './main';
import mainDrawerRoutes from './main-drawer';
import abstractRoutes from './abstract';

Vue.use(VueRouter);

const defaultRoute = {
  path: '/',
  redirect: 'communities',
  meta: {
    requiresAuth: true,
  },
};

export const routes = [
  // /communities
  ...communitiesRoutes,

  // /auth
  ...authRoutes,

  // Abstract Routes for Layout Areas
  ...abstractRoutes,

  // Routes designed to be used by nav drawer
  ...mainDrawerRoutes,

  // /:communitySlug
  // ! Always has to be declared last so that all named routes still work!
  ...mainRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [defaultRoute, ...routes],
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      // scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    // if (to.path != '/communities') {
    //   store.dispatch('notify', {
    //     text: `${i18n.t('auth.pleaseLogin')}`,
    //   });
    // }
    if (!Fillip.instance.state.user || !Fillip.instance.state.access_token) {
      return next({ name: 'Login', query: { target: to.fullPath } });
    }
    if (Fillip.instance.state.user?.isVerified == 'pending') {
      return next({
        name: 'ConfirmationPending',
        query: { target: to.fullPath },
      });
    }
  }

  if (
    to.matched.some((route) => route.meta.forwardTo) &&
    Fillip.instance.state.user &&
    Fillip.instance.state.user.isVerified != 'pending'
  ) {
    store.dispatch('notify', {
      type: 'info',
      text: i18n.t('auth.stillLoggedIn'),
    });
    return next({ name: 'Communities' });
  }

  if (from.query && Object.keys(from.query).length > 0) {
    const mergedQuery = { query: { ...from.query, ...to.query } };
    to = Object.assign({}, to, mergedQuery);
  }
  // TODO: Fix closing of dialog after navigation
  //Vue.prototype.$navigate('/', 'dialog');
  next();
});

router.afterEach((to, from) => {
  store.commit('SET_IS_LOADING', false);
});

export default router;
