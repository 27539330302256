


























import Vue from 'vue';
import { BaseDocumentEditor } from '@/features/editor/base/BaseDocumentEditor';
import InputVideo from '@/components/input/video/InputVideo.vue';
import { ElementVideo } from '@/features/main/elements/ElementVideo';
import { ElementVideo as TElementVideo, TypeVideo } from '@fillip/api';

export default Vue.extend({
  name: 'BlockEditorVideo',
  components: {
    InputVideo,
    ElementVideo,
  },
  mixins: [BaseDocumentEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  computed: {
    moduleVideo(): TElementVideo {
      return (
        this.buffer?.info?.content ||
        this.buffer?.properties?.video?.value ||
        {}
      );
    },
    contentAsModule() {
      return {
        element: {
          ...this.moduleVideo,
        },
      };
    },
  },
  methods: {
    updateContent(newContent: TypeVideo) {
      this.buffer.info.content = newContent;
      this.immediateSave('info');
    },
    toggleEditing() {
      if (this.isEditing) {
        this.immediateSave('info');
      }
      this.isEditing = !this.isEditing;
    },
  },
});
