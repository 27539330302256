import { DataDocument, PresetDefinition } from '@fillip/api';

const ScreenshareInstance: DataDocument = {
  id: 'ScreenshareInstance',
  info: {
    title: 'Screenshare',
    icon: 'arrow-alt-square-up',
    toolEditing: {
      preferredArrangement: 'topbar-main',
      editors: {
        title: {
          id: 'ScreenshareInstance',
        },
        properties: { id: 'ScreenshareInstance', title: 'Einstellungen' },
      },
    },
  },
  tag: {
    tag: 'template',
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  children: {
    default: [
      {
        templateId: 'tool-screenshare',
        query: ':[data]',
      },
    ],
  },
  properties: {
    onlyForHosts: {
      type: 'boolean',
      value: false,
    },
  },
  schema: {
    fields: [],
    ownFields: [
      {
        title: 'Nur für Hosts',
        key: 'onlyForHosts',
        type: 'boolean',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'templateParent',
        query: ':id',
      },
      {
        identifier: 'collectionDocument',
        query: 'ChatCollection',
      },
      {
        identifier: 'onlyForHosts',
        query: ':properties.onlyForHosts.value',
      },
    ],
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

export const PresetScreenshare: PresetDefinition = {
  id: 'PresetScreenshare',
  title: 'Screenshare',
  icon: 'arrow-alt-square-up',
  group: 'tools',
  version: '0.1',
  documents: [ScreenshareInstance],
};
