


































import Vue from 'vue';
export default Vue.extend({
  name: 'ActionButton',
  props: {
    name: {
      type: String,
    },
    color: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },
  methods: {
    submit() {
      this.$emit('submit', {});
    },
  },
});
