import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { Fillip } from '@fillip/vue-client';
import { FillipError, getShell } from '@fillip/api';
import type { Shell, DataDocument } from '@fillip/api';
import i18n from '@/plugins/i18n';

import notifications from './notifications';
import styles from './styles';
import videoConference from './video-conference';
import local from './local';
import volatile from './volatile';
import { useVolatileData } from '@/composables';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'collabl',
      paths: ['videoConference.streams', 'volatile'],
    }),
  ],
  modules: {
    notifications,
    styles,
    videoConference,
    local,
    volatile,
  },
  state: {
    activeCommunity: null,
    shell: null,
    isLoading: false,
    onlineStatus: null,
    reconnectionsExhausted: false,
    appDrawer: {
      state: 'fixed',
      width: '0',
    },
  },
  getters: {
    // These getters are used as a temporary solution to cache expensive and/or often used queries
    // This is NOT the right place to add new queries; put those in the corresponding composable

    communitySlug: (state) => {
      if (!state.activeCommunity) return null;
      return state.activeCommunity.slug;
    },
    communityStore: (state: any, getters) => {
      if (
        !state.client?.communities ||
        !getters.communitySlug ||
        !state.client.communities[getters.communitySlug]
      )
        return null;
      return state.client.communities[getters.communitySlug];
    },
    communityState: (_state, getters) => {
      if (!getters.communityStore?.db) return null;
      return getters.communityStore.db;
    },
    communityData: (_state, getters): Record<string, DataDocument> => {
      if (!getters.communityState) return null;
      return getters.communityState.data;
    },
    communityDataCollection: (_state, getters): DataDocument[] => {
      if (!getters.communityState) return null;
      return Object.values(getters.communityData);
    },
    me: (_state, getters) => {
      if (!getters.communityState) return null;
      return getters.communityData[getters.communityStore.participantId];
    },
    volatileStore: (state: any, getters) => {
      if (!getters.communitySlug || !state.volatile[getters.communitySlug])
        return null;
      return state.volatile[getters.communitySlug].db.data;
    },
    participants: (_state, getters): DataDocument[] => {
      if (!getters.communityState) return [];
      return getters.communityDataCollection.filter(
        (d: DataDocument) => d.tag?.tag == 'participant',
      );
    },
    onlineParticipants: (_state, getters): DataDocument[] => {
      if (!getters.participants?.length) return [];
      return getters.participants.filter(
        (d: DataDocument) => d.participantStatus && d.participantStatus.online,
      );
    },
    tags: (_state, getters): DataDocument[] => {
      if (!getters.communityState) return [];
      return getters.communityDataCollection.filter(
        (d) => d.tag?.tag == 'tag' && !!d.tagDefinition,
      );
    },
    templates: (_state, getters): DataDocument[] => {
      if (!getters.communityState) return [];
      return getters.communityDataCollection.filter(
        (d: DataDocument) => d.tag?.tag == 'template',
      );
    },
    shell: (state): Shell => {
      return state.shell;
    },
  },
  mutations: {
    SET_APP_DRAWER(state, newValue) {
      state.appDrawer = newValue;
    },
    SET_ACTIVE_COMMUNITY(state, community) {
      state.activeCommunity = community;
    },
    SET_SHELL(state, shell) {
      state.shell = shell;
    },
    SET_ONLINE_STATUS(state, onlineStatus) {
      state.onlineStatus = onlineStatus;
    },
    SET_RECONNECTIONS_EXHAUSTED(state, reconnectionsExhausted) {
      state.reconnectionsExhausted = reconnectionsExhausted;
    },
    SET_IS_LOADING(state, isLoading) {
      if (typeof isLoading !== 'boolean') {
        state.isLoading = !state.isLoading;
      } else {
        state.isLoading = isLoading;
      }
    },
  },
  actions: {
    async loadActiveCommunity({ commit, dispatch, state }, communitySlug) {
      if (state.activeCommunity?.slug == communitySlug) return;
      const community = await Fillip.instance.communities.getOneBySlug(
        communitySlug,
      );
      dispatch('setActiveCommunity', community);
    },
    setActiveCommunity({ commit, dispatch, state }, community) {
      if (!community) return;
      commit('SET_ACTIVE_COMMUNITY', community);
      if (community.branding) {
        dispatch('styles/setTheme', {
          theme: community.branding.colors,
          variant: 'light',
        });
        dispatch('styles/setImages', community.branding.images);
      }
      if (community.shell) {
        dispatch('setShell', community.shell);
      }
      if (community.language) {
        i18n.locale = community.language;
      }
      document.title = community.title;
      useVolatileData().initializeVolatileCommunityStore();
    },
    unloadActiveCommunity({ commit, dispatch, state }) {
      if (state.activeCommunity) {
        commit('SET_ACTIVE_COMMUNITY', null);
        dispatch('styles/recoverTheme');
      }
      dispatch('setShell', '');
    },
    setShell({ commit, dispatch, state }, shellName) {
      const shell = getShell(shellName || window.location.host);
      commit('SET_SHELL', shell);
      i18n.locale = shell.language;
      if (!state.activeCommunity) {
        document.title = shell.title;
      }
    },
    displayError({ dispatch }, error) {
      if (!(error instanceof FillipError)) {
        return console.error('fillip caught unhandled error: ', error);
      }

      if (error.meta && error.meta.internal) {
        return console.error('internal fillip error: ', error);
      }

      // TODO: handle array of multiple errors correctly
      dispatch('notify', {
        type: 'error',
        text: i18n.t(error.messageKey, error.context),
      });
    },
  },
});
