
























































import Vue from 'vue';
import { useTags, useSave } from '@/composables';
import { BaseModuleEditor } from '../base';

import PrimitiveTemplateSelector from '@/components/primitive-editor/types/PrimitiveTemplateSelector.vue';
import { computed } from '@vue/composition-api';

export default Vue.extend({
  name: 'TemplatesEditor',
  components: {
    PrimitiveTemplateSelector,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'templates',
    });

    const { tagDefinitionsSelectOptions } = useTags();

    const unsetTags = computed(() =>
      tagDefinitionsSelectOptions.value.filter(
        ({ tag }) => buffer.value?.mapping?.[tag] === undefined,
      ),
    );
    const setTags = computed(() =>
      tagDefinitionsSelectOptions.value.filter(
        ({ tag }) => buffer.value?.mapping?.[tag] !== undefined,
      ),
    );

    const setTagMapping = (tag, value) => {
      Vue.set(buffer.value.mapping, tag, value);
      save();
    };

    const removeTagMapping = (tag) => {
      Vue.delete(buffer.value.mapping, tag);
      save();
    };

    return {
      buffer,
      save,
      tagDefinitionsSelectOptions,
      setTags,
      unsetTags,
      setTagMapping,
      removeTagMapping,
    };
  },
});
