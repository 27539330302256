import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagTable: DataDocument = {
  id: 'tag-table',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Table',
    icon: 'table',
    isProtected: true,
  },
  element: {
    type: 'element.table',
    tableUseQuery: ':$tableUseQuery',
    tableQuery: ':$tableQuery',
    tableRowData: ':$tableRowData',
    tableColumns: ':$tableColumns',
  },
  class: {
    class: ':`max-w-lg ${$containerClasses}`',
    elementClass: ':` ${$elementClasses}`',
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'table',
    title: 'Table',
    category: 'content',
    icon: 'table',
    editor: 'table',
  },
  interfaceDefinition: {
    title: '',
    props: [
      {
        title: 'Use Query',
        key: 'tableUseQuery',
        type: 'string',
        defaultValue:
          ":$tableUseQuery || info?.content?.tableUseQuery || properties?.tableUseQuery?.value || ''",
      },
      {
        title: 'Query',
        key: 'tableQuery',
        type: 'string',
        defaultValue:
          ":$tableQuery || info?.content?.tableQuery || properties?.tableQuery?.value || ''",
      },
      {
        title: 'Row Data',
        key: 'tableRowData',
        type: 'string',
        defaultValue:
          ':$tableRowData || info?.content?.tableRowData || properties?.tableRowData?.value || []',
      },
      {
        title: 'Columns',
        key: 'tableColumns',
        type: 'string',
        defaultValue:
          ':$tableColumns || info?.content?.tableColumns || properties?.tableColumns?.value || []',
      },
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":$containerClasses || properties?.containerClasses?.value || ''",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":$elementClasses || properties?.elementClasses?.value || ''",
      },
    ],
  },
};
