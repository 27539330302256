











































import { defineComponent } from '@vue/composition-api';
import { useLayoutArea, useShell } from '@/composables';

export default defineComponent({
  name: 'FooterSplash',
  setup(props) {
    const { classes, content } = useShell();
    const { navigate: dialog } = useLayoutArea('dialog');

    return { content, classes, dialog };
  },
});
