import { DataDocument, toolsTemplateDirectoryId } from '@fillip/api';

export const TemplateToolImage: DataDocument = {
  id: 'tool-image',
  info: {
    title: 'Tool: Image',
    icon: 'image',
  },
  tag: {
    tag: 'template',
  },
  parentId: toolsTemplateDirectoryId,
  children: {
    default: [
      {
        templateId: 'tag-image',
        query: ':[data]',
        condition: '',
        identity: '',
      },
    ],
  },
  class: {
    elementClass: '',
    class: 'flex items-center justify-center',
  },
  arrangement: {
    type: 'arrangement.divide',
    divideAspectRatio: '16/9',
    divideStretch: false,
  },
};
