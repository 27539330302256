






import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import InputEmbed from '@/components/input/embed/InputEmbed.vue';

export default Vue.extend({
  name: 'EmbedEditor',
  components: {
    InputEmbed,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, data, save } = useSave(props, {
      path: 'element',
    });

    return {
      buffer,
      data,
      save,
    };
  },
  data() {
    return {
      error: null,
      options: {},
    };
  },
  methods: {
    update(newBuffer) {
      this.buffer = newBuffer;
      this.save();
    },
  },
});
