import { DataDocument, toolsTemplateDirectoryId } from '@fillip/api';

export const TemplateToolPoll: DataDocument = {
  id: 'tool-poll',
  info: {
    title: 'Tool: Poll',
    icon: 'poll',
  },
  tag: {
    tag: 'template',
  },
  parentId: toolsTemplateDirectoryId,
  list: {
    items: [
      {
        id: 'PollContainer',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'PollContainer',
        query: ':[data]',
        identity: '',
        condition: ':$$hideResults != true',
      },
    ],
  },
  actions: {
    actions: [
      {
        name: 'Response Select',
        type: 'action.multiSelect',
        context: {
          documentId: '',
        },
        icon: '',
        color: 'rgba(1, 1, 1, 0.54)',
        size: 'large',
        items: ':getList($$responseOptions)',
        multiple: ':$$allowMultiselect',
        title: ':$$pollTitle',
        script:
          "createMultipleDocuments($$responses,'page','info.title',input,{value:{properties:{templateId:{type:\"string\",value:'id'}}},identifier:'id'})",
        focused: 'always',
        condition:
          ':$$responsesByUser < $$allowedResponses && $$hideActions != true',
        maxOptions: ':$$maxOptions',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'maxOptions',
        query: ':properties.maxOptions.value',
      },
      {
        identifier: 'allowMultiselect',
        query: ':$$maxOptions > 1',
      },
      {
        identifier: 'allowedResponses',
        query: ':properties.allowedResponses.value',
      },
      {
        identifier: 'allResponses',
        query: ':getList($$responses)()',
      },
      {
        identifier: 'responsesByUser',
        query:
          ":pipe(wrapInFunction($$allResponses), where(matchesProperty('info.createdBy', $me.id)))().length",
      },
      {
        identifier: 'isEmpty',
        query: ':$$allResponses.length === 0',
      },
      {
        identifier: 'hideResults',
        query: ':$$hideResults',
      },
    ],
  },
  class: {
    elementClass: '',
    class: 'w-full h-full grid grid-cols-1 p-6',
  },
};

export const TemplateToolPollChildren: DataDocument[] = [
  {
    id: 'PollContainer',
    info: {
      title: 'Poll: Container',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-poll',
    list: {
      items: [
        {
          id: 'PollChart',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'tag-h3',
          query: ':[data]',
          condition: '',
          propMappings: {
            text: ':$$pollTitle',
          },
        },
        {
          templateId: 'PollChart',
          query: ':[data]',
          identity: '',
          condition: ':!$$isEmpty',
        },
        {
          templateId: 'empty-state-cover',
          query: ':[data]',
          condition: ':$$isEmpty',
          identity: '',
        },
      ],
    },
    class: {
      class: ':`${$$containerClasses} p-6 h-full grid grid-cols-1`',
      elementClass: '',
    },
  },
  {
    id: 'PollChart',
    info: {
      title: 'Poll: Chart',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'PollContainer',
    variables: {
      variables: [
        {
          identifier: 'filteredDatasets',
          query:
            ":getList($$responseOptions) |>map('id') |>map(getListFilteredBy($$responses,'templateId'))|> map('length')",
        },
        {
          identifier: 'datasetColors',
          query:
            ":$$colorSwatches || ['rgba(143, 191, 249,0.7)','rgba(249, 143, 244,0.7)','rgba(249, 201, 143,0.7)','rgba(143, 249, 148,0.7)']",
        },
        {
          identifier: 'datasetLabels',
          query:
            ":getList($$responseOptions) |> map('info.title') |> map((label) => _.chunk(getList($$responseOptions)().length == 9 ? 9 : 8)(label.split(' ')).map(l => l.join(' ')))",
        },
      ],
    },
    class: {
      class: 'w-full h-full',
      elementClass: '',
    },
    element: {
      type: 'element.chart',
      data: {
        datasets: [],
        labels: ':$$datasetLabels',
      },
      chartType: ":$$allowMultiselect ? 'bar' : 'pie'",
      scriptedDatasets:
        ':[{data:$$filteredDatasets,backgroundColor: $$datasetColors, label: $$pollTitle}]',
      scriptedOptions: '',
      options: {
        customLayers: false,
        layers: 1,
        borderWidth: 2,
        borderRadius: 2,
        minX: 0,
        minY: 0,
        maxX: 9,
        maxY: 0,
        colorGridLines: 'rgba(190, 190, 190, 1)',
        colorAxis: 'rgba(80, 80, 80, 1)',
        labelX: 'X',
        labelY: 'Y',
        ticksUnitX: '',
        ticksUnitY: '',
        showGrid: true,
        horizontal: false,
        beginAtZero: false,
        showLabel: false,
        labelAlign: 'center',
        ticksUnitPosition: 'after',
        showAxis: true,
        legendFontSize: 14,
      },
      editor: {
        scriptedDatasets: true,
        scriptedType: true,
        scriptedOptions: false,
      },
    },
  },
];
