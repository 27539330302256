import { ModuleTypeMeta } from '../../common';

// Data to be displayed, which itself is a whole document
import { DataDocument } from '../data.document';

export interface ModuleData extends DataDocument {
  isComputed?: boolean;
  computed?: Record<string, any>;
  key?: string;
}

export const ModuleDataMeta: ModuleTypeMeta<ModuleData> = {
  default: {
    id: '',
  },
  icon: 'database',
};
