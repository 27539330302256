import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCloud,
  faChartLine,
  faAddressBook,
  faAddressCard as faAddressCardLight,
  faAlignLeft,
  faAlbum,
  faAlbumCollection,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowAltCircleUp,
  faArrowAltSquareUp,
  faArrowCircleLeft,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faArrowUp,
  faSortAmountDown,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSortAmountUpAlt,
  faArrowToRight,
  faArrowToLeft,
  faArrowToTop,
  faArrowToBottom,
  faArrowFromRight,
  faArrowFromLeft,
  faArrowFromTop,
  faArrowFromBottom,
  faArrows,
  faArrowsH,
  faArrowsV,
  faBallotCheck,
  faBars,
  faBell,
  faBellOn,
  faBellSlash,
  faBezierCurve,
  faBinoculars,
  faBook,
  faBookSpells,
  faBookmark,
  faBooks,
  faBoothCurtain,
  faBolt,
  faPaperclip,
  faPersonBooth,
  faPresentation,
  faProjectDiagram,
  faBraille,
  faBrowser,
  faBullseyeArrow,
  faBullseyePointer,
  faCactus,
  faCalendar,
  faCalendarAlt,
  faCamera,
  faCalculator,
  faCaretDown,
  faCaretRight as faCaretRightLight,
  faChalkboardTeacher,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleUp,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle as faCircleLight,
  faCity,
  faClock,
  faClone,
  faClosedCaptioning,
  faCode,
  faCodeBranch,
  faCog,
  faCogs,
  faColumns,
  faComment,
  faCommentExclamation,
  faCommentMinus,
  faCommentPlus,
  faCommentTimes,
  faCommentSmile,
  faCommentSlash,
  faCommentLines,
  faCommentEdit,
  faCommentDots,
  faCommentCheck,
  faCommentAlt,
  faCommentAltExclamation,
  faCommentAltMinus,
  faCommentAltPlus,
  faCommentAltTimes,
  faCommentAltSmile,
  faCommentAltSlash,
  faCommentAltLines,
  faCommentAltEdit,
  faCommentAltDots,
  faCommentAltCheck,
  faComments,
  faCommentsAlt,
  faCompass,
  faCompress,
  faCopy,
  faCrosshairs,
  faCube,
  faCubes,
  faCut,
  faDatabase,
  faDebug,
  faDigitalTachograph,
  faDoNotEnter,
  faDoorClosed,
  faDoorOpen,
  faEdit,
  faEllipsisH,
  faEllipsisHAlt,
  faEllipsisV,
  faEllipsisVAlt,
  faEnvelope,
  faEraser,
  faExchange,
  faExclamation,
  faExclamationTriangle,
  faExpand,
  faExpandAlt,
  faExpandArrows,
  faExpandWide,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileCode,
  faFileExport,
  faFileImage,
  faFileImport,
  faFilePdf,
  faFileVideo,
  faFilmAlt,
  faFlag,
  faFlagAlt,
  faFlagCheckered,
  faPennant,
  faFolder,
  faFolderOpen,
  faFolderTree,
  faGameBoardAlt,
  faGamepadAlt,
  faGlasses,
  faGlobe,
  faGraduationCap,
  // faGrip,
  faGripHorizontal,
  faGripLinesVertical,
  faH1,
  faH2,
  faH3,
  faH4,
  faHandPaper,
  faHandPointUp,
  faHashtag,
  faHeading,
  faHeart,
  faHeartCircle,
  faHeartSquare,
  faHexagon,
  faHighlighter,
  faHome,
  faIcons,
  faIdCardAlt,
  faImage,
  faImagePolaroid,
  faInbox,
  faInfo,
  faInfoCircle,
  faJoystick,
  faKeyboard,
  faLaptopCode,
  faLevelUp,
  faLevelDown,
  faLifeRing,
  faLink,
  faList,
  faListAlt,
  faLocation,
  faLocationArrow,
  faLocationCircle,
  faLock,
  faLockOpen,
  faLongArrowLeft,
  faLongArrowRight,
  faMagic,
  faMap,
  faMapSigns,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMicrochip,
  faMicrophone,
  faMicrophoneSlash,
  faMinusCircle,
  faMinusSquare,
  faMobileAlt,
  faMousePointer,
  faMusic,
  faObjectGroup,
  faPaintBrush,
  faPaperPlane,
  faPaste,
  faPause,
  faPen,
  faPenNib,
  faPencil,
  faPencilRuler,
  faPhone,
  faPhoneLaptop,
  faPi,
  faPlay,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPoll,
  faPuzzlePiece,
  faPortalEnter,
  faPortalExit,
  faQuestion,
  faQuestionCircle,
  faQuestionSquare,
  faQuoteLeft,
  faQuoteRight,
  faRandom,
  faRedo,
  faReply,
  faRepeat,
  faRocket,
  faRulerCombined,
  faSatelliteDish,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faSendBack,
  faShapes,
  faShare,
  faSignIn,
  faSignOut,
  faSitemap,
  faSlidersH,
  faSmileBeam,
  faSortShapesDown,
  faSortShapesUp,
  faSortUp,
  faSortAlphaDown,
  faSortAlphaUp,
  faSquare as faSquareLight,
  faStar as faStarLight,
  faStarHalf,
  faStepBackward,
  faStepForward,
  faStickyNote,
  faStop,
  faStopCircle,
  faStreetView,
  faSunHaze,
  faSpinner,
  faSyncAlt,
  faTable,
  faTag,
  faText,
  faTextSize,
  faTh,
  faThLarge,
  faThList,
  faThumbsDown,
  faThumbsUp,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTransporter3,
  faTvMusic,
  faUndo,
  faUnlink,
  faUpload,
  faUser,
  faUserCheck,
  faUserCircle,
  faUserClock,
  faUserEdit,
  faUserFriends,
  faUserHeadset,
  faUserSecret,
  faUserSlash,
  faUsers,
  faUsersClass,
  faVideo,
  faVideoSlash,
  faVolume,
  faVolumeMute,
  faVoteYea,
  faWandMagic,
  faWebcam,
  faWifiSlash,
  faWindowFrameOpen,
  faWindowMaximize,
  faWindowMinimize,
} from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons/faDotCircle';
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { faStar } from '@fortawesome/pro-regular-svg-icons/faStar';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faGoogle, faDiscord } from '@fortawesome/free-brands-svg-icons';
// import {
// faTimesCircle,
// faCog,
// } from '@fortawesome/pro-duotone-svg-icons';

const libraryIcons = [
  faCloud,
  faChartLine,
  faAddressBook,
  faAddressCardLight,
  faAlbum,
  faAlbumCollection,
  faAlignLeft,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowAltCircleUp,
  faArrowAltSquareUp,
  faArrowCircleLeft,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faArrowUp,
  faSortAmountDown,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSortAmountUpAlt,
  faArrowToRight,
  faArrowToLeft,
  faArrowToTop,
  faArrowToBottom,
  faArrowFromRight,
  faArrowFromLeft,
  faArrowFromTop,
  faArrowFromBottom,
  faArrows,
  faArrowsH,
  faArrowsV,
  faBallotCheck,
  faBars,
  faBell,
  faBellOn,
  faBellSlash,
  faBezierCurve,
  faBinoculars,
  faBook,
  faBookSpells,
  faBookmark,
  faBooks,
  faBolt,
  faBoothCurtain,
  faPaperclip,
  faPersonBooth,
  faPresentation,
  faProjectDiagram,
  faBraille,
  faBrowser,
  faBullseyeArrow,
  faBullseyePointer,
  faCactus,
  faCalendar,
  faCalendarAlt,
  faCamera,
  faCalculator,
  faCaretDown,
  faCaretRight,
  faCaretRightLight,
  faChalkboardTeacher,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleUp,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleLight,
  faCity,
  faClock,
  faClone,
  faClosedCaptioning,
  faCode,
  faCodeBranch,
  faCog,
  faCogs,
  faColumns,
  faComment,
  faCommentExclamation,
  faCommentMinus,
  faCommentPlus,
  faCommentTimes,
  faCommentSmile,
  faCommentSlash,
  faCommentLines,
  faCommentEdit,
  faCommentDots,
  faCommentCheck,
  faCommentAlt,
  faCommentAltExclamation,
  faCommentAltMinus,
  faCommentAltPlus,
  faCommentAltTimes,
  faCommentAltSmile,
  faCommentAltSlash,
  faCommentAltLines,
  faCommentAltEdit,
  faCommentAltDots,
  faCommentAltCheck,
  faComments,
  faCommentsAlt,
  faCompass,
  faCompress,
  faCopy,
  faCrosshairs,
  faCube,
  faCubes,
  faCut,
  faDatabase,
  faDebug,
  faDigitalTachograph,
  faDotCircle,
  faDoNotEnter,
  faDoorClosed,
  faDoorOpen,
  faEdit,
  faEllipsisH,
  faEllipsisHAlt,
  faEllipsisV,
  faEllipsisVAlt,
  faEnvelope,
  faEraser,
  faExchange,
  faExclamation,
  faExclamationTriangle,
  faExpand,
  faExpandAlt,
  faExpandArrows,
  faExpandWide,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileCode,
  faFileExport,
  faFileImage,
  faFileImport,
  faFilePdf,
  faFileVideo,
  faFilmAlt,
  faFlag,
  faFlagAlt,
  faFlagCheckered,
  faPennant,
  faFolder,
  faFolderOpen,
  faFolderTree,
  faGameBoardAlt,
  faGamepadAlt,
  faGlasses,
  faGlobe,
  faGraduationCap,
  // faGrip,
  faGripHorizontal,
  faGripLinesVertical,
  faH1,
  faH2,
  faH3,
  faH4,
  faHandPaper,
  faHandPointUp,
  faHashtag,
  faHeading,
  faHeart,
  faHeartCircle,
  faHeartSquare,
  faHexagon,
  faHighlighter,
  faHome,
  faIcons,
  faIdCardAlt,
  faImage,
  faImagePolaroid,
  faInbox,
  faInfo,
  faInfoCircle,
  faJoystick,
  faKeyboard,
  faLaptopCode,
  faLevelUp,
  faLevelDown,
  faLifeRing,
  faLink,
  faList,
  faListAlt,
  faLocation,
  faLocationArrow,
  faLocationCircle,
  faLock,
  faLockOpen,
  faLongArrowLeft,
  faLongArrowRight,
  faMagic,
  faMap,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMapSigns,
  faMicrochip,
  faMicrophone,
  faMicrophoneSlash,
  faMinusCircle,
  faMinusSquare,
  faMobileAlt,
  faMousePointer,
  faMusic,
  faObjectGroup,
  faPaintBrush,
  faPaperPlane,
  faPaste,
  faPause,
  faPen,
  faPenNib,
  faPencil,
  faPencilRuler,
  faPhone,
  faPhoneLaptop,
  faPi,
  faPlay,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPoll,
  faPuzzlePiece,
  faPortalEnter,
  faPortalExit,
  faQuestion,
  faQuestionCircle,
  faQuestionSquare,
  faQuoteLeft,
  faQuoteRight,
  faRandom,
  faRedo,
  faReply,
  faRepeat,
  faRocket,
  faRulerCombined,
  faSatelliteDish,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faSendBack,
  faShapes,
  faShare,
  faSignIn,
  faSignOut,
  faSitemap,
  faSlidersH,
  faSmileBeam,
  faSortShapesDown,
  faSortShapesUp,
  faSortUp,
  faSortAlphaDown,
  faSortAlphaUp,
  faSquare,
  faSquareLight,
  faStar,
  faStarHalf,
  faStarLight,
  faStepBackward,
  faStepForward,
  faStickyNote,
  faStop,
  faStopCircle,
  faStreetView,
  faSunHaze,
  faSpinner,
  faSyncAlt,
  faTable,
  faTag,
  faText,
  faTextSize,
  faTh,
  faThLarge,
  faThList,
  faThumbsDown,
  faThumbsUp,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTransporter3,
  faTvMusic,
  faUndo,
  faUnlink,
  faUpload,
  faUser,
  faUserCheck,
  faUserCircle,
  faUserClock,
  faUserEdit,
  faUserFriends,
  faUserSecret,
  faUserSlash,
  faUsers,
  faUsersClass,
  faUserHeadset,
  faVideo,
  faVideoSlash,
  faVolume,
  faVolumeMute,
  faVoteYea,
  faWandMagic,
  faWebcam,
  faWifiSlash,
  faWindowFrameOpen,
  faWindowMaximize,
  faWindowMinimize,
  faGoogle,
  faDiscord,
];

library.add(...libraryIcons);

export const icons = libraryIcons.map((icon) => icon.iconName);

//light versions of default icons vuetify uses for some components. for example v-select & v-expansion-panels
export const vuetifyDefaultIcons = {
  cancel: 'fal fa-times-circle',
  checkboxIndeterminate: 'fal fa-minus-square',
  checkboxOff: 'far fa-square', // note 'far'
  checkboxOn: 'fal fa-check-square',
  chevron: 'fal fa-chevron-down',
  clear: 'fal fa-times-circle', // delete (e.g. v-chip close)
  close: 'fal fa-times',
  complete: 'fal fa-check',
  delete: 'fal fa-times-circle', // delete (e.g. v-chip close)
  delimiter: 'fal fa-circle', // for carousel
  dropdown: 'fal fa-angle-down',
  edit: 'fal fa-edit',
  error: 'fal fa-exclamation-triangle',
  expand: 'fal fa-angle-down',
  info: 'fal fa-info-circle',
  menu: 'fal fa-bars',
  next: 'fal fa-chevron-right',
  prev: 'fal fa-chevron-left',
  radioOff: 'far fa-circle',
  radioOn: 'far fa-dot-circle',
  ratingEmpty: 'fal fa-star',
  ratingFull: 'fal fa-star',
  ratingHalf: 'fal fa-star-half',
  sort: 'fal fa-sort-up',
  subgroup: 'fal fa-angle-down',
  success: 'fal fa-check-circle',
  warning: 'fal fa-exclamation',
};
