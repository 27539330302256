

























import { useFillip, useRouter } from '@/composables';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'DeleteAccount',
  setup(props) {
    const { fillip } = useFillip();
    const { router } = useRouter();
    const deletePrompt = ref(false);

    const deleteAccount = async () => {
      try {
        await fillip.auth.deleteAccount();
        router.push('/');
      } catch (e) {
        console.error(e);
      }
    };

    const cancel = () => {
      deletePrompt.value = !deletePrompt.value;
    };

    const buttons = [
      {
        label: 'user.deletePromptYes',
        method: deleteAccount,
        color: 'error',
      },
      {
        label: 'user.deletePromptNo',
        method: cancel,
      },
    ] as const;

    return { deletePrompt, buttons, cancel, deleteAccount };
  },
});
