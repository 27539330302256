


















































































import { useData, useTags, useDataTreeView } from '@/composables';
import { rootDataId } from '@fillip/api';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'TreeTemplateSelector',
  props: {
    value: {
      type: String,
    },
    rootId: {
      type: String,
      default: rootDataId,
    },
  },
  setup(props: any) {
    const { templatesInTreeview } = useDataTreeView();
    const { getData } = useData();
    const { getDataIcon } = useTags();
    return {
      getDataIcon,
      getData,
      items: computed(() => templatesInTreeview(props.rootId).children || []),
    };
  },
  data() {
    return {
      activeNode: [],
      openNodes: [],
      searchActive: false,
      search: '',
      x: 0,
      y: 0,
      errorMessage: '',
    };
  },
  computed: {
    icon() {
      return this.searchActive ? 'save' : 'search';
    },
    filter() {
      return (item, search, textKey) => {
        if (!item) return false;
        if (item.id.includes(search)) return true;
        return item[textKey]
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      };
    },
    selectedTemplate() {
      if (!this.activeNode) return;
      const data = this.getData(this.activeNode[0]);
      if (!data) return;
      return data.info?.title || this.activeNode[0];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.activeNode = [newValue];
      },
    },
    activeNode: {
      immediate: true,
      handler(newValue) {
        if (!newValue) return;
        if (this.value != newValue[0]) {
          this.checkForTemplateTag(newValue[0]);
          this.$emit('input', newValue[0]);
        }
      },
    },
  },
  mounted() {
    this.checkForTemplateTag(this.activeNode[0]);
  },
  methods: {
    async activateSearch() {
      const position = document
        .getElementById('search')
        .getBoundingClientRect();
      this.x = position.x;
      this.y = position.y + position.height;
      await this.$nextTick(() => {
        this.searchActive = true;
      });
    },
    deactivateSearch() {
      this.$refs.search.blur();
      this.searchActive = false;
    },
    updateSelection(newSelection) {
      const newSelectionId = newSelection[0];
      if (newSelectionId != this.activeNode) {
        this.activeNode = newSelection;
      }
    },
    updateOpenNodes(newSelection) {
      if (!newSelection || newSelection == this.openNodes) return;
      else {
        this.openNodes = newSelection;
      }
    },
    checkForTemplateTag(docId) {
      if (!docId) return;
      const data = this.getData(docId);
      if (!data) return;
      if (data.tag?.tag != 'template') {
        this.errorMessage = this.$t('children.invalidTag');
      } else {
        this.errorMessage = '';
      }
    },
  },
});
