import { DataDocument, PresetDefinition, rootDataId } from '@fillip/api';

const QAToolInstance: DataDocument = {
  id: 'QAToolInstance',
  info: {
    title: 'Fragen und Antworten',
    icon: 'comments',
    toolEditing: {
      preferredArrangement: 'side-by-side',
      editors: {
        title: {
          id: 'QAToolInstance',
        },
        properties: {
          id: 'QAToolInstance',
          title: 'Einstellungen',
        },
      },
    },
  },
  tag: {
    tag: 'template',
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  list: {
    items: [
      {
        id: 'QACollection',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'tool-collection',
        query: ':[data]',
      },
    ],
  },
  properties: {
    showCollectionTitle: {
      type: 'boolean',
      value: false,
    },
    showAuthor: {
      type: 'boolean',
      value: true,
    },
    showTime: {
      type: 'boolean',
      value: false,
    },
    showAvatar: {
      type: 'boolean',
      value: false,
    },
    allowReplies: {
      type: 'boolean',
      value: false,
    },
    allowPinning: {
      type: 'boolean',
      value: true,
    },
    allowAnswered: {
      type: 'boolean',
      value: true,
    },
    allowHighlight: {
      type: 'boolean',
      value: true,
    },
    allowUpvotes: {
      type: 'boolean',
      value: true,
    },
    grid: {
      type: 'boolean',
      value: false,
    },
    // sortByUpvotes: {
    //   type: 'boolean',
    //   value: true,
    // },
  },
  schema: {
    fields: [],
    ownFields: [
      {
        title: 'Zeige Collection-Titel',
        key: 'showCollectionTitle',
        type: 'boolean',
      },
      {
        title: 'Zeige Autor*in',
        key: 'showAuthor',
        type: 'boolean',
      },
      {
        title: 'Zeige Zeit',
        key: 'showTime',
        type: 'boolean',
      },
      {
        title: 'Zeige Avatar',
        key: 'showAvatar',
        type: 'boolean',
      },
      {
        title: 'Antworten erlauben',
        key: 'allowReplies',
        type: 'boolean',
      },
      {
        title: 'Pinnen erlauben',
        key: 'allowPinning',
        type: 'boolean',
      },
      {
        title: 'Highlighten erlauben',
        key: 'allowHighlight',
        type: 'boolean',
      },
      {
        title: '"Beantwortet" erlauben',
        key: 'allowAnswered',
        type: 'boolean',
      },
      {
        title: 'Upvotes erlauben',
        key: 'allowUpvotes',
        type: 'boolean',
      },
      {
        title: 'Grid-Styling',
        key: 'grid',
        type: 'boolean',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'templateParent',
        query: ':id',
      },
      {
        identifier: 'collectionDocument',
        query: 'QACollection',
      },
      {
        identifier: 'currentParent',
        query:
          ":getVolatileProp($$templateParent, 'currentParent', $$collectionDocument)",
      },
      {
        identifier: 'toolTitle',
        query: ':info.title',
      },
      {
        identifier: 'showCollectionTitle',
        query: ":getPropFrom('showCollectionTitle')(id) == true",
      },
      {
        identifier: 'showAuthor',
        query: ":getPropFrom('showAuthor')(id) == true",
      },
      {
        identifier: 'showTime',
        query: ":getPropFrom('showTime')(id) == true",
      },
      {
        identifier: 'showAvatar',
        query: ":getPropFrom('showAvatar')(id) == true",
      },
      {
        identifier: 'allowReplys',
        query: ":getPropFrom('allowReplies')(id) == true",
      },
      {
        identifier: 'allowPinning',
        query: ":getPropFrom('allowPinning')(id) == true",
      },
      {
        identifier: 'allowHighlight',
        query: ":getPropFrom('allowHighlight')(id) == true",
      },
      {
        identifier: 'allowAnswered',
        query: ":getPropFrom('allowAnswered')(id) == true",
      },
      {
        identifier: 'allowUpvotes',
        query: ":getPropFrom('allowUpvotes')(id) == true",
      },
      {
        identifier: 'sortByUpvotes',
        query: ":getPropFrom('sortByUpvotes')(id) == true",
      },
      {
        identifier: 'contributionToEdit',
        query: ": getVolatileProp($$templateParent, 'contributionToEdit', '')",
      },
      {
        identifier: 'toolsActiveFor',
        query: ": getVolatileProp($$templateParent, 'showTools', '')",
      },
      {
        identifier: 'isHost',
        query: ":hasRole($roles)('host')($me)",
      },
      {
        identifier: 'grid',
        query: ":getPropFrom('grid')(id)==true",
      },
    ],
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

const QACollection: DataDocument = {
  id: 'QACollection',
  info: {
    title: 'Beiträge',
  },
  tag: {
    tag: 'collection',
  },
  parentId: 'QAToolInstance',
  list: {
    items: [],
  },
};

export const PresetCollectionQA: PresetDefinition = {
  id: 'PresetCollectionQA',
  title: 'Q & A',
  icon: 'comment-alt-check',
  group: 'tools',
  version: '0.1',
  documents: [QAToolInstance, QACollection],
};
