import { Vector3, Object3D } from 'three';
import * as Location3D from '@/utils/location3D';
export const sphereArranger =
  (vm) =>
  (input, slot: string = '') => {
    const constraintsParent = vm.sizeConstraints;
    const maxWidthP = constraintsParent.maxWidth;
    const maxHeightP = constraintsParent.maxHeight;
    const maxDepthP = constraintsParent.maxDepth;
    const sizeConstraints = {
      minWidth: 0,
      minHeight: 0,
      minDepth: 0,
      maxWidth: Infinity,
      maxHeight: Infinity,
      maxDepth: Infinity,
    };
    // If constraints from parent,find smallest(most limiting) constraint of width, height
    // if no constraint calculate radius from child element with highest value in width,height
    // substracted 30 % from radius size because the sphere tends to get too big when there are no constraints set
    const finiteConstraints: any =
      [maxWidthP, maxHeightP, maxDepthP].filter((e) => e != Infinity) || [];
    const smallestConstraint = Math.min(...finiteConstraints);
    const elementSize: any = (() => {
      let childSize = 100;
      const maxValueWidth = Math.max(
        ...input.map((e: any) => e.size?.width || 0),
      );
      const maxValueHeight = Math.max(
        ...input.map((e: any) => e.size?.height || 0),
      );
      const maxValueOverall = Math.max(maxValueWidth, maxValueHeight);
      if (finiteConstraints.length > 0) {
        childSize = smallestConstraint / input.length;
      } else {
        childSize = maxValueOverall;
      }
      return childSize;
    })();
    const radius = (elementSize * input.length * 0.7) / (2 * Math.PI);
    if (finiteConstraints.length > 0) {
      sizeConstraints.maxWidth = elementSize;
      sizeConstraints.maxHeight = elementSize;
      sizeConstraints.maxDepth = radius;
    }
    const vector = new Vector3();
    const result = input.map((element, i) => {
      const phi = Math.acos(-1 + (2 * i) / input.length);
      const theta = Math.sqrt(input.length * Math.PI) * phi;
      const object = new Object3D();
      object.position.setFromSphericalCoords(radius, phi, theta);
      vector.copy(object.position).multiplyScalar(2);
      object.lookAt(vector);
      object.updateMatrix();
      const location = Location3D.fromMatrix(object.matrix);
      return {
        location: Location3D.addDefaults(location),
        sizeConstraints,
      };
    });
    return result;
  };
