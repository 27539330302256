















































import { computed, defineComponent } from '@vue/composition-api';
import AvatarEditor from '@/features/editor/modules/AvatarEditor.vue';
import PropertiesEditorLarge from '@/features/editor/modules/PropertiesEditorLarge.vue';
import TitleEditor from '@/features/editor/modules/TitleEditor.vue';
import { useAuth, useSchema } from '@/composables';

export default defineComponent({
  name: 'ParticipantProfile',
  components: {
    AvatarEditor,
    PropertiesEditorLarge,
    TitleEditor,
  },
  setup() {
    const { getCircleSchema } = useSchema();
    const { logout, logoutGlobally, isAnonymous, editAccount, upgradeAccount } =
      useAuth();

    const profileSchema = computed(() => getCircleSchema('main'));

    return {
      profileSchema,
      logout,
      logoutGlobally,
      isAnonymous,
      editAccount,
      upgradeAccount,
    };
  },
});
