import { defineComponent, computed, watch } from '@vue/composition-api';
import { useData } from '@/composables/data';
import { rootDataId } from '@fillip/api';

export default defineComponent({
  setup() {
    const { getData } = useData();

    const doc = computed(() => getData(rootDataId));
    const properties = computed(() => doc.value?.properties || {});

    const appBarBackground = computed(() => {
      return properties.value.appBarBackground?.value || 'white';
    });
    const appBarShadow = computed(() => {
      return properties.value.appBarShadow?.value == false ? false : true;
    });
    const mainDrawerBorder = computed(() => {
      return properties.value.mainDrawerBorder?.value == false ? false : true;
    });
    const mainDrawerBarBackground = computed(() => {
      return properties.value.mainDrawerBarBackground?.value || 'white';
    });
    const mainDrawerBackground = computed(() => {
      return properties.value.mainDrawerBackground?.value || 'white';
    });
    const canvasBackground = computed(() => {
      return (properties.value.canvasBackground?.value as string) || 'white';
    });
    const denseAppBar = computed(() => {
      return properties.value.denseAppBar?.value == true;
    });
    const appBarHeight = computed(() => {
      return (
        (denseAppBar.value ? 60 : 80) + (appBarShadow.value ? 1 : 0) + 'px'
      );
    });

    watch(
      canvasBackground,
      () => {
        document.documentElement.style.backgroundColor = canvasBackground.value;
      },
      { immediate: true },
    );

    return {
      document: doc,
      properties,
      appBarBackground,
      appBarShadow,
      mainDrawerBorder,
      mainDrawerBarBackground,
      mainDrawerBackground,
      canvasBackground,
      denseAppBar,
      appBarHeight,
    };
  },
  render() {
    return null;
  },
});
