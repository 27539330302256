



















































import AuthContainer from './components/AuthContainer.vue';
import AuthMethod from './components/AuthMethod.vue';
import AuthTerms from './components/AuthTerms.vue';

import { defineComponent, computed, ref } from '@vue/composition-api';
import { useRouter, useShell } from '@/composables';
import { useToggle } from '@vueuse/core';

export default defineComponent({
  name: 'Login',
  components: {
    AuthContainer,
    AuthMethod,
    AuthTerms,
  },
  setup() {
    const { $route, getRouterTarget } = useRouter();
    const { shell } = useShell();

    const [showLogin, toggleLogin] = useToggle();

    const allowAnonymousLogin = computed(() => {
      return shell.value.allowAnonymousLogin || $route.query?.mode == 'anonym';
    });

    const target = computed(() => getRouterTarget());

    return {
      shell,
      allowAnonymousLogin,
      showLogin,
      toggleLogin,
      target,
    };
  },
});
