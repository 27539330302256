import * as Location3D from '@/utils/location3D';

export const circleArranger =
  (vm) =>
  (input, slot: string = '') => {
    const constraintsParent = vm.sizeConstraints;
    const maxWidthP = constraintsParent.maxWidth;
    const maxHeightP = constraintsParent.maxHeight;
    const settings = vm.modules.arrangement;
    const is2D = settings.circleMode == '2D';
    const isFacingInwards = settings.facingInwards;
    const sizeConstraints = {
      minWidth: 0,
      minHeight: 0,
      minDepth: 0,
      maxWidth: Infinity,
      maxHeight: Infinity,
      maxDepth: Infinity,
    };
    const largestElement = Math.max(
      ...input.map((e: any) => e.size?.width || 0),
    );
    const tallestElement = Math.max(
      ...input.map((e: any) => e.size?.height || 0),
    );
    const elementSize = Math.max(largestElement, tallestElement);
    const numElements = input.length;
    let perimeter = numElements * elementSize;
    if (isFinite(maxWidthP) || isFinite(maxHeightP)) {
      let smallestSide = null;
      if (maxWidthP == maxHeightP) {
        smallestSide = 'maxWidth';
      } else if (isFinite(maxWidthP) && isFinite(maxHeightP)) {
        smallestSide = maxWidthP < maxHeightP ? 'maxWidth' : 'maxHeight';
      } else {
        smallestSide = isFinite(maxWidthP) ? 'maxWidth' : 'maxHeight';
      }
      perimeter = Math.PI * constraintsParent[smallestSide] - elementSize;
      sizeConstraints[smallestSide] =
        constraintsParent[smallestSide] / numElements;
    }

    const radius = perimeter / (2 * Math.PI);
    let degres = 0;
    const progression = 360 / numElements;
    const center = {
      x: 0,
      y: 0,
      z: 0,
    };
    const result = input.map((element) => {
      degres = degres + progression;
      const angle = (degres * Math.PI) / 180;
      const xPosition = center.x + radius * Math.cos(angle);
      const yPosition = center.y + radius * Math.sin(angle);
      const yRotation = isFacingInwards ? angle + (180 * Math.PI) / 180 : angle;
      return {
        location: Location3D.addDefaults({
          position: {
            x: is2D ? xPosition : yPosition,
            y: is2D ? yPosition : 0,
            z: is2D ? 0 : xPosition,
          },
          rotation: {
            x: 0,
            y: is2D ? 0 : yRotation,
            z: 0,
          },
        }),
        sizeConstraints,
      };
    });
    return result;
  };
