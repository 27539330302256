

















































import Vue from 'vue';
import { rootDataId, dayjs } from '@fillip/api';
import { useSave, useVolatileData, useData } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleInfoMeta } from '@fillip/api';
import TagEditor from '@/features/editor/modules/TagEditor.vue';
import DocumentId from './../components/DocumentId.vue';

export default Vue.extend({
  name: 'InfoEditor',
  components: {
    DocumentId,
    TagEditor,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'info',
      defaultValue: ModuleInfoMeta.default,
    });
    const { getVolatileProp, setVolatileProp } = useVolatileData();
    const { getData } = useData();

    return {
      buffer,
      save,
      getVolatileProp,
      setVolatileProp,
      getData,
    };
  },
  data() {
    return {
      locked: true,
      value: this.id,
    };
  },
  computed: {
    createdAt() {
      if (!this.buffer.createdAt) return;
      return dayjs(this.buffer.createdAt).format('YYYY-MM-DD, HH:mm');
    },
    createdBy() {
      if (!this.buffer.createdBy) return;
      return (
        this.getData(this.buffer.createdBy)?.info?.title ||
        this.buffer.createdBy
      );
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.locked = true;
        }
      },
    },
  },
  methods: {
    updateIdSelection(id) {
      if (!id) {
        this.setVolatileProp(rootDataId, 'selection', null);
      } else {
        this.setVolatileProp(rootDataId, 'selection', id);
      }
    },
  },
});
