




























import {
  useData,
  useDataClipboard,
  usePermissions,
  useDocuments,
  useTags,
  useI18n,
  useFillip,
} from '@/composables';
import { rootDataId, latestCommunityVersion } from '@fillip/api';
import PresetSelector from './PresetSelector.vue';
import Hierarchy from './Hierarchy.vue';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'Explorer',
  components: { Hierarchy, PresetSelector },
  props: {
    id: {
      type: String,
      default: rootDataId,
    },
  },
  setup(props: any) {
    const { isGlobalAdmin, canEditCommunity } = usePermissions();
    const dataClipboard = useDataClipboard();
    const { getData, getDataParentId } = useData();
    const { getDataIcon } = useTags();

    const { tr } = useI18n();
    const { $invoke } = useFillip();
    const root = computed(() => getData(props.id));
    const parentId = computed(() => getDataParentId(props.id));

    const {
      editInDialog,
      openExport,
      updateSelection,
      openInNavdrawer,
      editInSideDrawer,
    } = useDocuments();

    const gotoParent = () => {
      if (parentId.value) {
        openInNavdrawer(parentId.value);
      }
    };

    const activate = (documentIdentifier: any) => {
      if (typeof documentIdentifier == 'string')
        return updateSelection(documentIdentifier);
      try {
        const createdDocs = documentIdentifier.createdDocuments;
        if (!createdDocs) return;
        const id = createdDocs[0].id;
        return updateSelection(id);
      } catch (error) {
        console.error(
          'Cannot update selection with active element: ',
          documentIdentifier,
        );
        return;
      }
    };

    const communityVersion = computed(
      () => (getData('root').properties?.version?.value as number) || 0,
    );
    const latestVersion = latestCommunityVersion;
    const communityNeedsUpgrade = computed(() => {
      return communityVersion.value < latestVersion;
    });
    const upgradeCommunity = () => {
      $invoke('dataUpgradeCommunity');
    };

    const secondaryActions = computed(() => {
      return [
        {
          name: 'copy',
          title: tr('explorer.copy'),
          action: () => dataClipboard.copyFromId(props.id),
          icon: 'copy',
        },
        {
          name: 'paste',
          title: tr('explorer.paste'),
          action: () => dataClipboard.paste(props.id),
          icon: 'paste',
          if: () => dataClipboard.current.value,
        },
        {
          name: 'export',
          title: tr('explorer.export'),
          action: () => openExport(props.id),
          icon: 'file-export',
        },
        {
          name: 'migrations',
          title: tr('explorer.runMigrations', {
            current: communityVersion.value + 1,
            latest: latestVersion,
          }),
          action: () => upgradeCommunity,
          icon: 'sliders-h',
          if: isGlobalAdmin.value && communityNeedsUpgrade.value,
        },
      ];
    });

    return {
      dataClipboard,
      getData,
      getDataParentId,
      getDataIcon,
      isGlobalAdmin,
      root,
      parentId,
      communityVersion,
      latestVersion,
      communityNeedsUpgrade,
      secondaryActions,
      open,
      activate,
      editInSideDrawer,
      openExport,
      updateSelection,
      gotoParent,
      editInDialog,
      canEditCommunity,
    };
  },
});
