

































































































import Vue from 'vue';

import { BuiltinTypesMeta, ModuleSchemaMeta, SchemaField } from '@fillip/api';
import { computed, ref, watch } from '@vue/composition-api';
import { BaseModuleEditor } from '../base';
import clone from 'rfdc/default';

export default Vue.extend({
  name: 'SchemaFieldsEditor',
  mixins: [BaseModuleEditor],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  setup(props: any, { emit }) {
    const buffer = ref<SchemaField[]>(null);
    const defaultItem = ModuleSchemaMeta.childDefault;

    const types = BuiltinTypesMeta;

    const documentQueriesEnabled = (inputType) =>
      ['documents', 'id'].includes(inputType);

    const save = () => {
      emit('input', buffer.value);
      emit('change', buffer.value);
    };

    const addItem = () => {
      buffer.value.push(clone(defaultItem));
      save();
    };
    const removeItem = (index) => {
      buffer.value.splice(index, 1);
      save();
    };

    const addSelectItem = (index: number) => {
      if (!buffer.value[index].options)
        Vue.set(buffer.value[index], 'options', {});
      if (!buffer.value[index].options.items)
        Vue.set(buffer.value[index].options, 'items', []);
      (buffer.value[index].options.items as Record<string, any>[]).push({
        key: 'key',
        title: 'Title',
      });
      save();
    };

    const removeSelectItem = (index: number, itemIndex: number) => {
      (buffer.value[index].options.items as []).splice(itemIndex, 1);
      save();
    };

    watch(props.value, (newValue: SchemaField[]) => (buffer.value = newValue), {
      immediate: true,
    });

    return {
      buffer,
      types,
      documentQueriesEnabled,
      save,
      addItem,
      removeItem,
      addSelectItem,
      removeSelectItem,
    };
  },
});
