var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pointer-events-none"},[_c('svg',{staticClass:"pointer-events-none overflow-visible",style:({
      width: _vm.elementDimensions.width + 'px',
      height: _vm.elementDimensions.height + 'px',
    }),attrs:{"xmlns":"http://www.w3.org/2000/svg","version":"1.1","xmlns:xlink":"http://www.w3.org/1999/xlink","xmlns:svgjs":"http://svgjs.dev/svgjs","viewBox":("0 0 " + (_vm.elementDimensions.width) + " " + (_vm.elementDimensions.height))}},[_c('g',{ref:"line",staticClass:"pointer-events-none",attrs:{"stroke-width":_vm.settings.lineWidth,"stroke":_vm.settings.lineColor,"fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('line',{staticClass:"pointer-events-auto cursor-context-menu",attrs:{"x1":_vm.positions.x1,"x2":_vm.positions.x2,"y1":_vm.positions.y1,"y2":_vm.positions.y2,"stroke-dasharray":_vm.lineStyle,"marker-start":['start', 'both'].includes(_vm.settings.arrows)
            ? 'url(#marker' + _vm.id + ')'
            : '',"marker-end":['end', 'both'].includes(_vm.settings.arrows)
            ? 'url(#marker' + _vm.id + ')'
            : ''},on:{"click":function($event){return _vm.handleEvent('lineClick', $event)}}})]),_c('defs',[_c('marker',{attrs:{"id":'marker' + _vm.id,"markerWidth":"5","markerHeight":"5","refX":"2.5","refY":"2.5","viewBox":"0 0 5 5","orient":"auto-start-reverse"}},[_c('polygon',{attrs:{"points":"0,5 1.6666666666666667,2.5 0,0 5,2.5","fill":_vm.settings.lineColor},on:{"click":function($event){return _vm.handleEvent('lineClick', $event)}}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }