


































































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleDraggableMeta } from '@fillip/api';

export default Vue.extend({
  name: 'DraggableEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'draggable',
    });

    const dragTypes = ModuleDraggableMeta.schema.dragTypes;

    return {
      buffer,
      save,
      dragTypes,
    };
  },
});
