import {
  applyFilters,
  DataDocument,
  FilterFunction,
  hasRole,
  lodashFp as _,
} from '..';
import { round } from 'lodash';

// Value helpers

export const property = (key: string) => (doc: DataDocument) => {
  return _.get(maybeProperty(key), doc) || '';
};

export const checkProp = (key: string, value: any) => (doc: DataDocument) => {
  const compare = _.get(maybeProperty(key), doc);
  if (!compare && value === false) return true;
  if (!compare) return false;
  if (Array.isArray(compare)) {
    return compare.includes(value);
  }
  return compare == value;
};

export const hasProp = (key: string) => (doc: DataDocument) => {
  const compare = _.get(maybeProperty(key), doc);
  if (compare !== undefined && compare !== null) return true;
  return false;
};

export const checkPropIfSet =
  (key: string, value: any) => (doc: DataDocument) => {
    if (!hasProp(key)(doc)) return true;
    return checkProp(key, value)(doc);
  };

export const isPropTruthy = (key: string) => (doc: DataDocument) => {
  const compare = _.get(maybeProperty(key), doc);
  return !!compare;
};

export const maybeProperty = (key: string): string => {
  if (typeof key != 'string') return key;

  if (key.indexOf('.') == -1 && key != 'id') {
    key = 'properties.' + key;
  }
  if (key.startsWith('properties') && !key.endsWith('value')) {
    key = key + '.value';
  }
  return key;
};

export const wrapInFunction = (value: any) => () => value;
export const maybeWrapInFunction = (value: any) =>
  typeof value === 'function' ? value : () => value;

// Calls the provided `predicate` with the value at `path` of the provided `doc` as its argument
export const check =
  (predicate: (any) => boolean, path: string) =>
  (doc: DataDocument): boolean =>
    predicate(_.get(maybeProperty(path))(doc));

// TODO: Document and test use cases
// const getPathOrValue = (pathOrValue: unknown) => (doc?: DataDocument) => {
//   if (!doc) return pathOrValue;
//   if (typeof pathOrValue != 'string') return pathOrValue;
//   const value = _.get(maybeProperty(pathOrValue))(doc);
//   return value === undefined ? pathOrValue : value;
// };

export const compare =
  (comparator: Function, referencePath: string, ...comparePaths: string[]) =>
  (doc: DataDocument): boolean => {
    if (comparePaths.length < 1) return true;
    let accumulator = true;
    for (const comparePath of comparePaths) {
      accumulator =
        accumulator &&
        comparator(
          _.get(maybeProperty(referencePath), doc),
          _.get(maybeProperty(comparePath), doc),
        );
    }
    return accumulator;
  };

export const roundTo =
  (decimals: number = 0) =>
  (number: number) =>
    round(decimals, number);

// Collection helpers

export const where =
  (...filters: Array<FilterFunction | boolean>) =>
  (docs: DataDocument[]): DataDocument[] => {
    if (!docs || !docs.length) return [];
    return docs.filter((doc: DataDocument) => applyFilters(filters)(doc));
  };

export const or =
  (...filters: FilterFunction[]) =>
  (doc: DataDocument): boolean =>
    filters.some((filter) => !!filter(doc));

export const and =
  (...filters: FilterFunction[]) =>
  (doc: DataDocument): boolean =>
    filters.every((filter) => !!filter(doc));

type SortOrder = 'desc' | 'asc';
export const sortBy =
  (key: string | string[], order: SortOrder | SortOrder[] = 'asc') =>
  (docs: DataDocument[]) => {
    key = Array.isArray(key)
      ? key.map((k) => maybeProperty(k))
      : [maybeProperty(key)];
    return _.orderBy(
      Array.isArray(key) ? key : [key],
      Array.isArray(order) ? order : [order],
      docs,
    );
  };

export const groupBy = (key: any) => (docs: DataDocument[]) => {
  key = maybeProperty(key);
  return _.groupBy(key, docs);
};

export const extractId = (docOrId: DataDocument | string) =>
  typeof docOrId == 'string' ? docOrId : docOrId.id;

export const differenceBy =
  (values: Array<any>, identifier: any) => (docs: DataDocument[]) => {
    return _.differenceBy(identifier, docs, values);
  };

export const intersectionBy =
  (values: Array<any>, identifier: any) => (docs: DataDocument[]) => {
    return _.intersectionBy(identifier, docs, values);
  };

export const take = (size: number) => (docs: DataDocument[]) =>
  _.take(size, docs);

export const skip = (size: number) => (docs: DataDocument[]) =>
  _.drop(size, docs);

export const page =
  (page: number = 1, pageSize: number = 10) =>
  (docs: DataDocument[]) => {
    const offset = (page - 1) * pageSize;
    return docs.slice(offset).slice(0, pageSize);
  };

// TODO: Selects new sample on every recalculation, causing an infinite loop
// export const sample = (size: number) => (docs: DataDocument[]) => sampleSize(size, docs);

// TODO: Shuffles on every recalculation
// export const shuffle = (docs: DataDocument[]) => _.shuffle(docs);

// Aggregation helpers

export const meanBy = (key: any) => (docs: DataDocument[]) => {
  key = maybeProperty(key);
  return _.meanBy(key, docs);
};
export const sumBy = (key: any) => (docs: DataDocument[]) => {
  key = maybeProperty(key);
  return _.sumBy(key, docs);
};
export const countBy = (key: any) => (docs: DataDocument[]) => {
  key = maybeProperty(key);
  return _.countBy(key, docs);
};

export const minBy = (key: any) => (docs: DataDocument[]) => {
  key = maybeProperty(key);
  return _.get(key, _.minBy(key, docs));
};

export const maxBy = (key: any) => (docs: DataDocument[]) => {
  key = maybeProperty(key);
  return _.get(key, _.maxBy(key, docs));
};

export const getMinBy = (key: any) => (docs: DataDocument[]) => {
  key = maybeProperty(key);
  return _.minBy(key, docs);
};
export const getMaxBy = (key: any) => (docs: DataDocument[]) => {
  key = maybeProperty(key);
  return _.maxBy(key, docs);
};
export const gt = (other: number) => (value: number) => _.gt(value, other);
export const gte = (other: number) => (value: number) => _.gte(value, other);
export const lt = (other: number) => (value: number) => _.lt(value, other);
export const lte = (other: number) => (value: number) => _.lte(value, other);

export const checkRole =
  (rolesToCheck: string | string[], userDoc: DataDocument) =>
  (doc: DataDocument) => {
    if (!rolesToCheck || !userDoc || !doc) return false;
    const docRoles = doc.roles?.roles || {};
    return hasRole(docRoles)(rolesToCheck)(userDoc);
  };

export const checkRoleIfNot =
  (
    propKey: string,
    propValue: any,
    rolesToCheck: string | string[],
    userDoc: DataDocument,
  ) =>
  (doc: DataDocument) => {
    return (
      !checkProp(propKey, propValue)(doc) ||
      checkRole(rolesToCheck, userDoc)(doc)
    );
  };

// Object helpers

export const setObjProperty =
  (property: string, value: any) =>
  (obj: object): object =>
    Object.defineProperty(obj, property, {
      value,
      writable: true,
      enumerable: true,
    });

export const documentUtils = {
  _,

  pipe: _.pipe,
  pick: _.pick,
  getPath: _.get,
  map: _.map,
  has: _.has,
  first: _.first,
  last: _.last,

  length: _.size,
  size: _.size,
  gt,
  gte,
  lt,
  lte,

  property,
  getProp: property,
  hasProp,
  maybeProperty,
  checkProp,
  checkPropIfSet,
  check,
  compare,
  wrapInFunction,
  isPropTruthy,
  roundTo,

  where,
  or,
  and,
  sortBy,
  groupBy,
  take,
  skip,
  page,

  extractId,
  differenceBy,
  intersectionBy,
  //shuffle,
  //sample,

  meanBy,
  sumBy,
  minBy,
  maxBy,
  countBy,

  getMinBy,
  getMaxBy,

  checkRole,
  checkRoleIfNot,

  setObjProperty,
};
