<template>
  <InputCode
    :value="buffer"
    v-bind="$props"
    language="json"
    :options="currentOptions"
    autofocus
    :is-invalid="!jsonIsValid"
    @input="onChange"
  ></InputCode>
</template>

<script>
import InputCode from '@/components/input/code/InputCode.vue';

export default {
  name: 'InputJson',
  components: {
    InputCode,
  },
  props: {
    value: {
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      buffer: '',
      jsonIsValid: false,
      isDirty: false,
      defaultOptions: {},
    };
  },
  computed: {
    currentOptions() {
      return { ...this.defaultOptions, ...this.options };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.jsonIsValid = true;
        this.buffer = newValue ? JSON.stringify(newValue, null, 2) : '';
      },
    },
  },
  methods: {
    async onChange(value) {
      try {
        const validatedJson = await JSON.parse(value);
        this.jsonIsValid = true;
        this.$emit('input', validatedJson);
      } catch (error) {
        if (this.jsonIsValid) {
          this.jsonIsValid = false;
          this.$emit('invalid');
        }
      }
    },
  },
};
</script>
