import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/src/locale/de';
import en from 'vuetify/src/locale/en';
import themeStyles from '@/styles/theme';
import '@fortawesome/vue-fontawesome';
import '@/styles/overrides.scss';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { vuetifyDefaultIcons } from '@/utils/icons';

Vue.use(Vuetify);

function modifyIcons() {
  const modifiedIcons = {};
  for (const element in vuetifyDefaultIcons) {
    const value = vuetifyDefaultIcons[element];
    const prefix = value.split(' ')[0];
    const suffix = value.split('-').slice(1).join('-');
    modifiedIcons[element] = {
      component: FontAwesomeIcon,
      props: {
        icon: [prefix, suffix],
      },
    };
  }
  return modifiedIcons;
}
export default new Vuetify({
  icons: {
    values: modifyIcons(),
  },
  ...themeStyles,
  lang: {
    locales: { en, de },
    current: 'en',
  },
});
