import { render, staticRenderFns } from "./MarkerEditor.vue?vue&type=template&id=24612a65&"
import script from "./MarkerEditor.vue?vue&type=script&lang=ts&"
export * from "./MarkerEditor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VSelect,VTextarea})
