import { BaseObject3D } from '../core';
import {
  ACESFilmicToneMapping,
  MathUtils,
  PMREMGenerator,
  Vector3,
} from 'three';
import { Sky } from 'three/examples/jsm/objects/Sky';

export const ModelColorfulSky = {
  name: 'ModelColorfulSky',
  mixins: [BaseObject3D],
  created() {
    this.scene = this.canvas.value.renderManager.scene;
    this.renderer =
      this.canvas.value.renderManager.renderers.get('webGL').renderEngine;

    this.renderer.toneMapping = ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 0.5;

    this.pmremGenerator = new PMREMGenerator(this.renderer);

    this.sky = new Sky();
    this.sky.scale.setScalar(10000);
    const skyUniforms = this.sky.material.uniforms;

    skyUniforms['turbidity'].value = 5;
    skyUniforms['rayleigh'].value = 2;
    skyUniforms['mieCoefficient'].value = 0.005;
    skyUniforms['mieDirectionalG'].value = 0.8;

    const sun = new Vector3();
    this.sun = sun;

    this.updateSun(this.elevation, this.azimuth);
    this.$object3D.add(this.sky);
    this.canvas.value.render('webGL', true);
  },
  computed: {
    elevation() {
      return this.modules.model.colorfulSkySettings.elevation;
    },
    azimuth() {
      return this.modules.model.colorfulSkySettings.azimuth;
    },
  },
  watch: {
    elevation: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.updateSun(newValue, this.azimuth);
        }
      },
    },
    azimuth: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.updateSun(this.elevation, newValue);
        }
      },
    },
    // inactive: {
    //   immediate: true,
    //   deep: true,
    //   handler(newValue) {
    //     console.log('___________inactive colorfulSky: ', newValue);

    //     this.$object3D.visible = !newValue;
    //     this.canvas.value.render('webGL', true);
    //   },
    // },
  },
  methods: {
    updateSun(elevation = 30, azimuth = 60) {
      if (!this.sun) return;
      const phi = MathUtils.degToRad(90 - elevation);
      const theta = MathUtils.degToRad(azimuth - 180);
      this.sun.setFromSphericalCoords(1, phi, theta);

      this.sky.material.uniforms['sunPosition'].value.copy(this.sun);

      // TODO: employ global prop to update environment
      this.scene.environment = this.pmremGenerator.fromScene(this.sky).texture;
      this.canvas.value.render('webGL', true);
    },
  },
  render() {
    return null;
  },
};
