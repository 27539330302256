import { computed } from '@vue/composition-api';
import { useVolatileData } from '../data/useVolatileData';
import { DisplayMode } from '@fillip/api';

export function useScene() {
  const { getVolatileProp, setVolatileProp } = useVolatileData();
  const globalDisplayMode = computed<DisplayMode>(() => {
    return getVolatileProp('ui', 'displayMode', 'screen');
  });
  const setDisplayMode = (mode: DisplayMode) => {
    if (mode === globalDisplayMode.value) return;
    setVolatileProp('ui', 'displayMode', mode);
  };

  return {
    globalDisplayMode,
    setDisplayMode,
  };
}
