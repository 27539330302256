import { DataDocument, fragmentsTemplateDirectoryId } from '@fillip/api';

export const TemplateFragmentHeader: DataDocument = {
  id: 'fragment-header',
  parentId: fragmentsTemplateDirectoryId,
  info: {
    title: 'Header',
    isProtected: true,
  },
  class: {
    class: 'w-full flex flex-row items-center p-4',
    elementClass: '',
  },
  tag: {
    tag: 'template',
  },
  sheet: {
    orientTowardsCamera: false,
  },
  placement: {
    type: 'placement.fixed',
    fixedHorizontal: 'left',
    fixedVertical: 'top',
  },
  list: {
    items: [
      {
        id: 'fragment-header-back-button',
      },
      {
        id: 'fragment-header-title',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'fragment-header-back-button',
        query: ':[data]',
        condition: ':$route.path.length > 1 || getFocus($route) == $station',
      },
      {
        templateId: 'fragment-header-title',
        query: ':[data]',
      },
    ],
  },
};

export const TemplateFragementHeaderBackButton: DataDocument = {
  id: 'fragment-header-back-button',
  info: {
    title: 'Header: Back-Button',
  },
  tag: {
    tag: 'template',
  },
  parentId: 'fragment-header',
  element: {
    type: 'element.button',
    buttonText: '',
    buttonIcon: 'step-backward',
    buttonEvent: 'back',
  },
  listener: {
    listeners: {
      back: {
        script: 'getFocus($route) ? router.removeFocus() : router.gotoParent()',
        context: {},
      },
    },
  },
  class: {
    class:
      'mr-4 delay-150 transition-all duration-300 hover:-translate-x-2 hover:scale-125 ease-in-out',
    elementClass: 'text-gray-800 blurred-glass',
  },
};

const TemplateFragmentHeaderTitle: DataDocument = {
  id: 'fragment-header-title',
  info: {
    title: 'Header: Title',
  },
  tag: {
    tag: 'template',
  },
  parentId: 'fragment-header',
  element: {
    type: 'element.text',
    textContent: ':info.title',
    textFormat: 'h2',
  },
  class: {
    class:
      'flex-initial xs:text-xs md:text-sm text-gray-800 flex items-center blurred-glass px-4 py-3 rounded-lg',
    elementClass: '',
  },
};

export const TemplateFragmentHeaderChildren: DataDocument[] = [
  TemplateFragementHeaderBackButton,
  TemplateFragmentHeaderTitle,
];
