





















































import Vue from 'vue';

import { useStepper } from '@/features/stepper/useStepper';

export default Vue.extend({
  name: 'ActionStepperControl',
  props: {
    id: {
      type: String,
    },
  },
  setup(props) {
    return useStepper(props);
  },

  methods: {
    edit() {
      this.$dialog.push({ name: 'Stepper', params: { id: this.id } });
    },
  },
});
