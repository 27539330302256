import type { Size2D } from './../size';
import { Box3, Object3D, Vector3, PerspectiveCamera } from 'three';
import { clamp } from 'three/src/math/MathUtils';
import * as Location3D from '../../../../../utils/location3D';

const DEFAULT_CAMERA_DISTANCE = 1000;

interface CameraParameters {
  aspect: number;
  fov: number;
  near: number;
  far: number;
}

export const getCameraParameters = (
  { width, height }: Size2D,
  defaultDistance: number = DEFAULT_CAMERA_DISTANCE,
): CameraParameters => {
  return {
    aspect: width / height,
    fov: (2 * Math.atan((0.5 * height) / defaultDistance) * 180) / Math.PI,
    near: 0.005,
    far: 100000,
  };
};

export const applyCameraParameters = (
  camera: PerspectiveCamera,
  params: CameraParameters,
) => {
  camera.fov = params.fov;
  camera.aspect = params.aspect;
  camera.near = params.near;
  camera.far = params.far;
  camera.updateProjectionMatrix();
};

export const updateCameraAfterViewportResize = (
  camera: PerspectiveCamera,
  viewportSize: Size2D,
) => {
  applyCameraParameters(camera, getCameraParameters(viewportSize));
};

export const calculateZoomToFitLocation = (
  focusedObject: Box3 | Object3D,
  viewport: Size2D,
  options?: {
    limitZoomForScreenSize?: boolean;
    minDistance?: number;
    maxDistance?: number;
  },
) => {
  const {
    limitZoomForScreenSize = false,
    minDistance = 0,
    maxDistance = 999999,
  } = options;

  let box: Box3;

  if ('isObject3D' in focusedObject && focusedObject.isObject3D) {
    new Box3().setFromObject(focusedObject);
  } else if ('isBox3' in focusedObject && focusedObject.isBox3) {
    box = focusedObject;
  }

  const center = new Vector3();
  box.getCenter(center);
  const size = new Vector3();
  box.getSize(size);

  let width = size.x;
  let height = size.y;
  const depth = size.z;

  if (limitZoomForScreenSize) {
    width = Math.max(width, viewport.width / 2);
    height = Math.max(height, viewport.height / 2);
  }
  let distance = Math.max(
    (width / viewport.width) * 1000,
    (height / viewport.height) * 1000,
  );
  distance += depth;
  distance = clamp(
    distance,
    minDistance,
    maxDistance > 0 ? maxDistance : Infinity,
  );
  distance -= center.z;
  return Location3D.addDefaults({
    position: {
      x: center.x,
      y: center.y,
      z: distance,
    },
  });
};
