import { FillipVueMediaClient } from './modules/media.client';
import { Store } from 'vuex';
import axios from 'axios';
import { FillipVueRealtimeClient } from './modules/realtime.client';
import { FillipVueCommunitiesClient } from './modules/communities.client';
import { FillipVueAuthClient } from './modules/auth.client';
import { IFillipVueClientModule } from './base.client';
import { FillipVueFileCollectionsClient } from './modules/fileCollections.client';
import { FillipVueUtilitiesClient } from './modules/utilities.client';
import { FillipVueAiClient } from './modules/ai.client';

import clientStore from './client.store';

export interface FillipVueClientConfig {
  apiUrl?: string;
  wsUrl?: string;
  LOCAL_STORAGE_KEY?: string;
}

export class FillipVueClient {
  public config: FillipVueClientConfig;
  public modules = new Map<string, IFillipVueClientModule>();
  public axios = axios;

  // modules
  public auth: FillipVueAuthClient;
  public communities: FillipVueCommunitiesClient;
  public realtime: FillipVueRealtimeClient;
  public media: FillipVueMediaClient;
  public fileCollections: FillipVueFileCollectionsClient;
  public utilities: FillipVueUtilitiesClient;
  public ai: FillipVueAiClient;

  constructor(
    public store: Store<{ client: any }>,
    config: FillipVueClientConfig,
  ) {
    this.config = Object.assign(
      {
        apiUrl: 'http://localhost:3000',
        wsUrl: 'ws://localhost:8086',
        LOCAL_STORAGE_KEY: 'collabl-user',
      },
      config,
    );

    store.registerModule('client', clientStore);

    // Add modules
    this.auth = new FillipVueAuthClient(this);
    this.communities = new FillipVueCommunitiesClient(this);
    this.realtime = new FillipVueRealtimeClient(this);
    this.media = new FillipVueMediaClient(this);
    this.fileCollections = new FillipVueFileCollectionsClient(this);
    this.utilities = new FillipVueUtilitiesClient(this);
    this.ai = new FillipVueAiClient(this);

    this.restore();
  }

  public registerModule(name: string, clientModule: IFillipVueClientModule) {
    this.modules.set(name, clientModule);
  }

  public get state() {
    return this.store.state.client;
  }

  public restore() {
    this.modules.forEach((clientModule) => clientModule.restore());
  }
}

export type TFillipClient = typeof FillipVueClient;
