


























































































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModulePlacementMeta } from '@fillip/api';
import ConditionalEditorContainer from '../components/ConditionalEditorContainer.vue';
import { useData } from '@/composables/data/useData';
import GridEditor from '../placements/GridEditor.vue';
import AbsoluteEditor from '../placements/AbsoluteEditor.vue';
import FixedEditor from '../placements/FixedEditor.vue';

export default Vue.extend({
  name: 'PlacementEditor',
  components: {
    GridEditor,
    AbsoluteEditor,
    FixedEditor,
    ConditionalEditorContainer,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save, data } = useSave(props, {
      path: 'placement',
    });

    const { getDataParent } = useData();

    return {
      buffer,
      save,
      getDataParent,
      data,
    };
  },
  computed: {
    placementTypes() {
      return Object.values(ModulePlacementMeta.types);
    },
  },
  methods: {
    setType(type: string, index: number): void {
      if (index == 0 || index) {
        this.buffer.variants[index].value =
          ModulePlacementMeta.types[type].default;
      } else {
        this.buffer = ModulePlacementMeta.types[type].default;
      }
      this.save();
    },
  },
});
