import { BaseElement } from './../core';
import { iframely } from '@iframely/embed.js';

export const ElementEmbed = {
  name: 'ElementEmbed',
  mixins: [BaseElement],
  methods: {
    loadIFrame() {
      iframely.load();
    },
  },
  computed: {
    src() {
      return this.modules?.element.embedSrc;
    },
    embedCode() {
      return this.modules?.element?.embedCode;
    },
    embedMeta() {
      return this.modules?.element?.embedMeta;
    },
    ratio() {
      return (
        this.embedMeta?.links?.player?.[0]?.media?.['aspect-ratio'] || 4 / 3
      );
    },
    element() {
      return this.modules?.element;
    },
  },
  watch: {
    embedCode: {
      immediate: true,
      handler(oldValue, newValue) {
        if (oldValue != newValue) {
          this.$nextTick(this.loadIFrame);
        }
      },
    },
  },
  render(h) {
    const contentElement = this.embedCode
      ? h('div', {
          style: {
            width: '100%',
            height: '100%',
          },
          domProps: {
            innerHTML: this.embedCode,
          },
        })
      : this.src
      ? h('iframe', {
          attrs: {
            src: this.src,
            frameborder: '0',
            allow:
              'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
            allowfullscreen: true,
          },
          style: {
            width: '100%',
            height: '100%',
          },
        })
      : h(
          'div',
          {
            class: 'w-full min-h-full h-96 bg-gray-200 grid place-items-center',
          },
          this.$t('element.embed.noEmbedSet'),
        );

    return h(
      'div',
      {
        ref: 'el',
      },
      [contentElement],
    );
  },
};
