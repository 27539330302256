











































































import AuthSplash from './components/AuthSplash.vue';
import AuthFooter from './components/AuthFooter.vue';

import { defineComponent, onMounted } from '@vue/composition-api';
import { useShell } from '@/composables';

export default defineComponent({
  name: 'AuthLayout',
  components: {
    AuthSplash,
    AuthFooter,
  },
  setup(props) {
    const { shell, content, classes, isLoading } = useShell();

    onMounted(() => {
      const script = shell.value.script;
      if (script) {
        let tag = document.head.querySelector(`[src="${script}"`);
        if (!tag) {
          tag = document.createElement('script');
          tag.setAttribute('async', 'true');
          tag.setAttribute('defer', 'true');
          tag.setAttribute('src', script);
          tag.setAttribute('type', 'text/javascript');
          document.head.appendChild(tag);
        }
      }
    });

    return {
      shell,
      content,
      classes,
      isLoading,
    };
  },
});
