import * as Location3D from '@/utils/location3D';

export const rectangleArranger =
  (vm) =>
  (input, slot: string = '') => {
    const constraintsParent = vm.sizeConstraints;
    const maxWidthP = constraintsParent.maxWidth;
    const maxHeightP = constraintsParent.maxHeight;
    const numElements = input.length;
    const settings = vm.modules.arrangement;

    const sizeConstraints = {
      minWidth: 0,
      minHeight: 0,
      minDepth: 0,
      maxWidth: Infinity,
      maxHeight: Infinity,
      maxDepth: Infinity,
    };
    const location = {
      position: {
        x: 0,
        y: 0,
        z: 0,
      },

      rotation: {
        x: 0,
        y: 0,
        z: 0,
      },
    };

    const largestElement = Math.max(
      ...input
        .map((e: any) => e.size?.width)
        .filter(
          (e, i) =>
            i % 4 == 2 || i % 4 == 0 || (i < 4 && settings.mode != '3D'),
        ),
    );
    const largestElement3D = Math.max(
      ...input
        .map((e: any) => e.size?.width)
        .filter((e, i) => i % 4 == 1 || i % 4 == 3),
    );
    const tallestElement = Math.max(
      ...input
        .map((e: any) => e.size?.height)
        .filter(
          (e, i) =>
            i % 4 == 1 || i % 4 == 3 || (i < 4 && settings.mode != '3D'),
        ),
    );
    const elementsPerSide = Math.ceil(numElements / 4);
    const standartChildSize = (tallestElement * elementsPerSide) / 2;
    let height = (() => {
      if (
        (isFinite(maxHeightP) && !settings.keepSize) ||
        standartChildSize > maxHeightP
      ) {
        return maxHeightP;
      } else {
        return standartChildSize;
      }
    })();

    const width = (() => {
      if (
        (isFinite(maxWidthP) && !settings.keepSize) ||
        standartChildSize > maxWidthP
      ) {
        return maxWidthP;
      } else {
        return standartChildSize;
      }
    })();

    if (settings.mode == '3D')
      height = (largestElement3D * elementsPerSide) / 2;
    if (isFinite(maxWidthP) || isFinite(maxHeightP)) {
      let smallestSide = null;
      if (maxWidthP == maxHeightP) {
        smallestSide = 'maxWidth';
      } else if (isFinite(maxWidthP) && isFinite(maxHeightP)) {
        smallestSide = maxWidthP < maxHeightP ? 'maxWidth' : 'maxHeight';
      } else {
        smallestSide = isFinite(maxWidthP) ? 'maxWidth' : 'maxHeight';
      }
      sizeConstraints[smallestSide] =
        constraintsParent[smallestSide] / elementsPerSide;
    }
    const yValue = settings.mode == '3D' ? 0 : height;
    const zValue = settings.mode == '3D' ? height : 0;
    const heightAxis = settings.mode == '3D' ? 'z' : 'y';

    const corners = [
      { x: -width, y: yValue, z: zValue },
      { x: width, y: yValue, z: zValue },
      { x: width, y: -yValue, z: -zValue },
      { x: -width, y: -yValue, z: -zValue },
    ];
    const rotation = [
      { x: 0, y: 0, z: 0 },
      { x: 0, y: Math.PI * 0.5, z: 0 },
      { x: 0, y: Math.PI, z: 0 },
      { x: 0, y: Math.PI * -0.5, z: 0 },
    ];
    function roundRobin(input) {
      return input < 0 ? 3 : input > 3 ? 0 : input;
    }
    let loopIndexPerSide = -1;

    const result = input.map((element, i) => {
      const rest = (i + 1) % 4;
      const restIndex = rest - 1;
      function getRotation(index) {
        location.rotation.x = rotation[index].x;
        location.rotation.y = rotation[index].y;
        location.rotation.z = rotation[index].z;
      }
      const activeIndex = i > 3 ? rest : i;
      location.position.x = corners[activeIndex].x;
      location.position.y = corners[activeIndex].y;
      location.position.z = corners[activeIndex].z;

      function sides(input) {
        const axis = input % 2 == 0 ? 'x' : heightAxis;
        return (
          Math.abs(corners[input][axis]) +
          Math.abs(corners[roundRobin(input + 1)][axis])
        );
      }
      function fillUpSquare(side) {
        const operator = settings.mode == '3D' ? 2 : 1;
        return (
          side / elementsPerSide / operator +
          (side / elementsPerSide) * loopIndexPerSide -
          side / 2
        );
      }

      if (settings.mode == '3D' || (settings.mode != '3D' && i > 3)) {
        rest == 1 ? (loopIndexPerSide = loopIndexPerSide + 1) : '';
        const activeSide = sides(roundRobin(restIndex));
        rest % 2 == 0
          ? (location.position[heightAxis] = fillUpSquare(activeSide))
          : (location.position.x = fillUpSquare(activeSide));
        settings.mode == '3D' ? getRotation(roundRobin(restIndex)) : '';
      }
      return {
        location: Location3D.addDefaults(location),
        sizeConstraints,
      };
    });
    return result;
  };
