import { ModuleTypeMeta } from '../../common';

export interface AnimationTarget {
  px?: number;
  py?: number;
  pz?: number;
  qx?: number;
  qy?: number;
  qz?: number;
  qw?: number;
  sx?: number;
  sy?: number;
  sz?: number;
}
export interface AnimationOptions extends AnimationTarget {
  overwrite?: boolean;
  duration?: number;
  ease?: string;
  repeat?: number;
  yoyo?: boolean;
  delay?: number;
  onUpdate?: () => void;
  onComplete?: () => void;
  onInterrupt?: (event: any) => void;
}

const transitionCategories = {
  enter: { title: 'transitions.enter' },
  transform: { title: 'transitions.transform' },
  leave: { title: 'transitions.leave' },
};

const animationTargets = {
  target: { title: 'transitions.target' },
  parent: { title: 'transitions.parent' },
};

export interface Transition {
  id: string;
  title: string;
  category: keyof typeof transitionCategories;
  to?: AnimationOptions;
  from?: AnimationTarget;
}

export type TransitionOptions = boolean | Transition | string; //keyof typeof transitions ?

export interface ModuleTransitions {
  enter: string;
  enterFrom: 'target' | 'parent';
  enterDuration?: number;
  transform: string;
  transformDuration?: number;
  leave: string;
  leaveTo: 'target' | 'parent';
  leaveDuration?: number;
}

// TODO: Move to transition functions that take in entity and parent location and return the transition

const noTransition: Transition = {
  id: 'none',
  title: 'No Transition',
  category: 'transform',
};

const defaultTransition: Transition = {
  id: 'default',
  title: 'Default Transition',
  category: 'transform',
  to: {
    duration: 1.5,
    ease: 'power3.out',
  },
};

const scaleIn: Transition = {
  id: 'scaleIn',
  title: 'scaleIn',
  category: 'enter',
  from: {
    sx: 0,
    sy: 0,
    sz: 0,
  },
  to: {
    duration: 1.5,
    ease: 'power3.out',
  },
};

const scaleOut: Transition = {
  id: 'scaleOut',
  title: 'scaleOut',
  category: 'leave',
  to: {
    sx: 0,
    sy: 0,
    sz: 0,
    duration: 0.5,
    ease: 'power3.in',
  },
};

const tumbleIn: Transition = {
  id: 'tumbleIn',
  title: 'tumbleIn',
  category: 'enter',
  from: {
    px: 3000,
    py: 3000,
    qw: -1,
    qx: 0,
    qy: 0,
    qz: 1,
  },
  to: {
    qw: 1,
    qx: 0,
    qy: 0,
    qz: -0,
    px: -5000,
    duration: 4,
    ease: 'elastic.out(1, 1)',
  },
};

const tumbleOut: Transition = {
  id: 'tumbleOut',
  title: 'tumbleOut',
  category: 'leave',
  to: {
    px: -3000,
    py: -3000,
    qw: 1,
    qx: 0,
    qy: 0,
    qz: -1,
    duration: 2,
    ease: 'elastic.in(1, 1)',
  },
};

const slideOutToLeft: Transition = {
  id: 'slideOutToLeft',
  title: 'slideOutToLeft',
  category: 'leave',
  to: {
    px: -5000,
    duration: 2,
    ease: 'power3.in',
  },
};

const slideInFromRight: Transition = {
  id: 'slideInFromRight',
  title: 'slideInFromRight',
  category: 'enter',
  from: {
    px: 5000,
  },
  to: {
    duration: 2,
    ease: 'power3.out',
  },
};

const bounceTransition: Transition = {
  id: 'bounceTransition',
  title: 'bounceTransition',
  category: 'transform',
  to: {
    duration: 3,
    ease: 'elastic.out(1, 1)',
  },
};

export const transitions = {
  none: noTransition,
  default: defaultTransition,
  scaleIn,
  scaleOut,
  slideOutToLeft,
  slideInFromRight,
  bounceTransition,
  tumbleIn,
  tumbleOut,
};

export const enterTransitions = Object.values(transitions).filter(
  (t) => t.category === 'enter',
);
export const leaveTransitions = Object.values(transitions).filter(
  (t) => t.category === 'leave',
);
export const transformTransitions = Object.values(transitions).filter(
  (t) => t.category === 'transform',
);

export const ModuleTransitionsMeta: ModuleTypeMeta<ModuleTransitions> = {
  default: {
    enter: 'scaleIn',
    enterFrom: 'target',
    transform: 'default',
    leave: 'scaleOut',
    leaveTo: 'target',
  },
  schema: {
    transitionCategories,
    enterTransitions,
    leaveTransitions,
    transformTransitions,
    animationTargets,
  },
  icon: 'transporter-3',
};
