import { pipe } from 'lodash/fp';
import {
  addListItems,
  docsToListItems,
  replaceString,
  participantsDirectoryId,
} from '../common';
import { ParticipantsDirectory } from '../presets/defaults/WorldDefaultTemplates';
import type { DataDocument } from '../vm/data.document';

export interface Migration {
  world?: (store: any) => void;
  documents?: (data: DataDocument) => DataDocument;
}

export interface Migrations {
  [version: number]: Migration;
}

// ! Make sure to update the latestCommunityVersion for newly created communities in WorldDefaultTemplates.ts when adding a migration
export const migrations: Migrations = {
  0: {
    documents: (data) => {
      return pipe(
        replaceString(
          'https://media.fillip.world/',
          'https://media.collabl.world/',
        ),
        replaceString(
          'https://media.fillip.live/',
          'https://media.collabl.world/',
        ),
        replaceString(
          'https://media.fillip.world:443/',
          'https://media.collabl.world/',
        ),
        replaceString(
          'https://fillip-filestore.s3.nl-ams.scw.cloud/',
          'https://media.collabl.world/',
        ),
        replaceString(
          'https://fillip.ai/media/',
          'https://media.collabl.world/media/',
        ),
        replaceString(
          'https://media.collabl.world/media/plebs/',
          'https://media.collabl.world/media/customers/plebs/',
        ),
      )(data);
    },
  },
  1: {
    world: (store) => {
      store.setDocument('data', ParticipantsDirectory, true);
    },
    documents: (data) => {
      if (data.tag?.tag == 'participant' && !data.parentId) {
        data.parentId = participantsDirectoryId;
      }
      return data;
    },
  },
  2: {
    world: (store) => {
      const participants = store
        .getCollection('data')
        .filter((d) => d.tag?.tag == 'participant')
        .map((p) => {
          return { id: p.id };
        });
      const participantsFolder = addListItems(docsToListItems(participants))(
        store.getDocument('data', 'participants'),
      );
      store.setDocument('data', 'participants', participantsFolder);
    },
  },
  3: {
    documents: (data) => {
      if (data.properties) {
        Object.values(data.properties).forEach((property) => {
          if (property.type == 'html') {
            (property as any).type = 'styledText';
          }
        });
      }
      return data;
    },
  },
};
