




























import Vue from 'vue';
import { BaseDocumentEditor } from '@/features/editor/base/BaseDocumentEditor';
import DOMPurify from 'dompurify';
import { ElementHtml } from '@/features/main/elements/ElementHtml';
import InputCode from '@/components/input/code/InputCode.vue';

export default Vue.extend({
  name: 'BlockEditorHtml',
  components: {
    ElementHtml,
    InputCode,
  },
  mixins: [BaseDocumentEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  computed: {
    contentAsModule() {
      return {
        element: {
          htmlContent: this.buffer.info.content,
        },
      };
    },
  },
  methods: {
    updateContent() {
      this.buffer.info.content = DOMPurify.sanitize(this.buffer.info.content);
      this.buffer.info.title = this.buffer.info.content
        .slice(0, 50)
        .replace('/[\n\r]/g', '')
        .trim();
      this.immediateSave('info');
    },
    toggleEditing() {
      if (this.isEditing) {
        this.immediateSave('info');
      }
      this.isEditing = !this.isEditing;
    },
  },
});
