














import { BaseElement } from '../core';
import { useSave, useVolatileData } from '@/composables';

import 'reveal.js/dist/reveal.css';
import 'reveal.js/dist/theme/league.css';
import 'highlight.js/styles/github-dark.css';
import { deepEqual } from 'fast-equals';
import { RevealState, sleep } from '@fillip/api';
import { computed } from '@vue/composition-api';

// TODO: Refactor into loadDependency util, add loading indicator

let Reveal, Markdown, Highlight;
const loadReveal = () => {
  return new Promise((resolve) => {
    // @ts-ignore
    require(/* webpackChunkName: "reveal" */ ['reveal.js'], resolve);
  });
};
const loadMarkdown = () => {
  return new Promise((resolve) => {
    require(/* webpackChunkName: "reveal" */ [
      'reveal.js/plugin/markdown/markdown',
      // @ts-ignore
    ], resolve);
  });
};
const loadHighlight = () => {
  return new Promise((resolve) => {
    require(/* webpackChunkName: "reveal" */ [
      'reveal.js/plugin/highlight/highlight',
      // @ts-ignore
    ], resolve);
  });
};
const initializeReveal = async () => {
  // @ts-ignore
  Reveal = (await loadReveal()).default;
  Markdown = await loadMarkdown();
  Highlight = await loadHighlight();
};

export default {
  name: 'ElementReveal',
  mixins: [BaseElement],
  setup(props: any) {
    const templateId = computed(() => props.id.split(':')[0]);
    const { buffer, save } = useSave(
      { id: templateId.value },
      {
        path: 'element',
      },
    );
    const { getVolatileProp, setVolatileProp } = useVolatileData();

    return {
      buffer,
      save,
      getVolatileProp,
      setVolatileProp,
    };
  },
  data() {
    return {
      deck: null,
    };
  },
  computed: {
    content() {
      return this.modules.element.revealContent;
    },
    options() {
      return this.modules.element.options;
    },
    controlledBy() {
      return this.modules.element.revealControlledBy;
    },
    revealState() {
      return this.modules.element.revealState;
    },
    ready() {
      return !this.deck ? null : this.deck.isReady();
    },
  },
  watch: {
    content: {
      immediate: true,
      handler(newValue, oldValue) {
        if (deepEqual(newValue, oldValue) || !this.deck) return;
        this.resetRevealContent();
      },
    },
    options: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!oldValue || deepEqual(newValue, oldValue)) return;
        this.updateSetting();
      },
    },
    revealState: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!this.deck) return;
        if (!newValue || deepEqual(newValue, oldValue)) return;
        this.deck.setState(newValue);
      },
    },
  },
  async mounted() {
    await this.init();
    await this.resetRevealContent();
  },
  beforeDestroy() {
    this.deck.off('slidechanged', this.onSlideChanged);
  },
  methods: {
    async init(state?: RevealState) {
      if (!Reveal) await initializeReveal();
      try {
        if (!this.deck) {
          this.deck = new Reveal(document.querySelector('.reveal'), {
            plugins: [Markdown, Highlight],
            embedded: true,
            history: false,
          });
          await this.deck.initialize();
          this.deck.on('slidechanged', this.onSlideChanged);
        }
        this.updateSetting();
        if (state) this.updateRevealState(state);
      } catch (error) {
        console.error('could not init ElementReveal: ', error);
      }
    },
    async updateSetting() {
      if (!this.deck) {
        this.init();
      } else {
        this.deck.configure({ keyboardCondition: 'focused', ...this.options });
        await sleep(1000);
        this.deck.layout();
      }
    },
    updateRevealState(state: RevealState) {
      this.deck.setState(state);
    },
    onSlideChanged({ indexh, indexv }) {
      if (this.controlledBy == 'host') {
        if (this.options.controls) {
          this.buffer.revealState = {
            ...this.buffer.revealState,
            indexh,
            indexv,
          };
          this.save();
        }
      } else {
        this.setVolatileProp(this.id, 'revealState', this.deck.getState());
      }
    },
    getRevealTargetState(): RevealState {
      if (this.controlledBy == 'host') {
        if (this.options.controls) {
          return null;
        } else {
          return this.modules.element.revealState;
        }
      } else {
        const savedState = this.getVolatileProp(this.id, 'revealState', null);
        if (savedState) {
          return savedState;
        } else {
          return null;
        }
      }
    },
    async resetRevealContent() {
      const state: RevealState = this.getRevealTargetState();

      const containerElement = document.getElementById('reveal-container');
      if (!containerElement) return;
      const revealElement = document.getElementsByClassName(
        'reveal w-full h-full',
      )[0];
      const newReveal = (this.element = document.createElement('div'));
      this.element.className = 'reveal w-full h-full';
      this.element.innerHTML = `
        <div class="slides">
          <section data-markdown data-separator-vertical="^\r?\n--\r?\n$">
            <div data-template>
               ${this.content}
            </div>
          </section>
        </div>
        `;

      if (revealElement) {
        containerElement.removeChild(revealElement);
      }
      containerElement.appendChild(newReveal);
      this.deck.off('slidechanged', this.onSlideChanged);
      this.deck = null;
      await this.init(state);
    },
  },
};
