







































import EditorOption from '../components/EditorOption.vue';
import InputScript from '@/components/input/script/InputScript.vue';
import InputNumber from '@/components/input/number/InputNumber.vue';
import clone from 'rfdc/default';
import { deepEqual } from '@fillip/api';

export default {
  name: 'DimensionsEditor',
  components: {
    EditorOption,
    InputScript,
    InputNumber,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String],
      required: true,
    },
    dimensions: {
      type: Array,
      required: true,
    },
    defaultValues: {
      type: [Object, String],
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    // TODO: Implement isBlocked
    isBlocked: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      buffer: null,
    };
  },
  computed: {
    wrapperClasses() {
      return `grid gap-2 align-center grid-cols-${this.dimensions.length}`;
    },
    isScripted() {
      return typeof this.value == 'string';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (deepEqual(newValue, this.buffer) || deepEqual(newValue, oldValue))
          return;
        this.buffer = newValue;
      },
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.buffer);
    },
    onInput() {
      this.$emit('input', this.buffer);
    },
    onBlur($event) {
      this.$emit('blur', $event);
    },
    toggleScripted() {
      if (!this.isScripted) {
        const scripted = this.dimensions
          .map((dimension) => `${dimension}: ${this.buffer[dimension]}`)
          .join(', ');
        this.update(`:({${scripted}})`);
      } else {
        const newValue = clone(this.defaultValues);
        this.update(newValue);
      }
    },
    update(newValue) {
      this.$emit('input', newValue);
      this.$emit('change', newValue);
      this.$emit('blur');
    },
  },
};
