import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagEmbed: DataDocument = {
  id: 'tag-embed',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Embed',
    icon: 'window-frame-open',
    isProtected: true,
  },
  // TODO: What's with the rest of the settings?
  element: {
    type: 'element.embed',
    embedSrc: ':$embedSrc',
    embedCode: ':$embedCode',
    embedMeta: null,
    embedMode: '1',
    embedOptions: {},
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'embed',
    title: 'Embed',
    category: 'content',
    icon: 'window-frame-open',
    editor: 'embed',
  },
  interfaceDefinition: {
    title: '',
    props: [
      {
        title: 'Embed-URL',
        key: 'embedSrc',
        type: 'string',
        defaultValue: ':info.content.embedSrc',
      },
      {
        title: 'Embed-Code',
        key: 'embedCode',
        type: 'string',
        defaultValue: ":info.content.embedCode ? info.content.embedCode : ''",
      },
    ],
  },
};
