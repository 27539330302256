<template>
  <video
    v-show="canPlay"
    ref="video"
    class="w-full h-full object-cover"
    :class="{ 'scale-x-flip': mirror }"
  ></video>
</template>

<script>
export default {
  name: 'VideoTrackConsumer',
  props: {
    track: {
      type: MediaStreamTrack,
      required: true,
    },
    mirror: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      canPlay: false,
      isPlaying: false,
      isPaused: false,
    };
  },
  watch: {
    track: {
      immediate: true,
      handler() {
        this.$nextTick(async () => {
          const video = this.$refs.video;
          this.canPlay = false;
          const stream = new MediaStream();
          stream.addTrack(this.track);
          video.srcObject = stream;
          video.setAttribute('playsinline', '');
          video.onloadedmetadata = () => {
            this.canPlay = true;
            this.playVideo(video);
          };
        });
      },
    },
  },
  beforeDestroy() {
    this.canPlay = false;
  },
  methods: {
    async playVideo(video) {
      try {
        await video.play();
      } catch (error) {
        console.log(error);
        const startPlay = async () => {
          await video.play();
          console.log('VideoPlay');
          document.removeEventListener('click', startPlay);
        };
        document.addEventListener('click', startPlay);
      }
    },
  },
};
</script>
