







































































































import FiCircle from './Circle.vue';
import { createExplicitSegmentFromObject, Id, rootDataId } from '@fillip/api';
import { useData, useGlobalProps, usePermissions } from '@/composables';
import { truncate } from 'lodash';
import { defineComponent, computed, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'Navigation',
  components: {
    FiCircle,
  },
  setup() {
    const { canSeePrototype } = usePermissions();
    const { getData } = useData();
    const { getGlobalProp } = useGlobalProps();
    const router = inject('router') as any;

    const routePath = computed(() => router.value?.route.path || []);
    const stations = computed(() => getGlobalProp('stations')?.slice(1) || []);
    const breadcrumbs = computed(() => getGlobalProp('breadcrumbs') || []);
    const activeScene = computed(() => router.value?.activeScene || '');
    const activeSceneTitle = computed(
      () => router.value?.activeSceneTitle || '',
    );
    const focusedObject = computed(() => router.value?.focusedObject || '');
    const parentScene = computed(() => {
      if (routePath.value.length <= 1) return null;
      return routePath.value[routePath.value.length - 1];
    });
    const objectsIndex = computed(() => {
      if (!stations.value) return null;
      return stations.value.findIndex(
        (object) => object.id === focusedObject.value?.id,
      );
    });
    const previous = computed(() => {
      if (!stations.value) return null;
      if (objectsIndex.value <= 0) return null;
      return stations.value[objectsIndex.value - 1].id;
    });
    const next = computed(() => {
      if (!stations.value) return null;
      if (
        objectsIndex.value < 0 ||
        objectsIndex.value === stations.value.length - 1
      )
        return null;
      return stations.value[objectsIndex.value + 1].id;
    });
    const title = computed(() => {
      if (!focusedObject.value) return null;
      return truncate(focusedObject.value.title, { length: 20 });
    });

    const goUpParents = (steps: number) => router.value.goUpParents(steps);
    const isFocused = (id: Id) => focusedObject.value?.id === id;
    const focus = (id: Id) => {
      if (!id) return;
      router.value.focus(id);
    };
    const removeFocus = () => router.value.removeFocus();
    const gotoScene = (scene: any) => {
      if (!scene) return;
      router.value.gotoChild(createExplicitSegmentFromObject(scene));
    };
    const goToParent = () => router.value.gotoParent();

    const showCircles = computed(() => {
      const rootProperties = getData(rootDataId).properties || {};
      return rootProperties.useCircles?.value || false;
    });

    return {
      getData,
      canSeePrototype,
      router,
      stations,
      breadcrumbs,
      activeScene,
      activeSceneTitle,
      focusedObject,
      parentScene,
      objectsIndex,
      previous,
      next,
      title,
      goUpParents,
      isFocused,
      focus,
      removeFocus,
      gotoScene,
      goToParent,
      showCircles,
    };
  },
});
