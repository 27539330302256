












































import Vue from 'vue';
import axios from 'axios';
import clone from 'rfdc/default';
import InputNumberSlider from '@/components/input/number/InputNumberSlider.vue';
import InputBooleanCheckbox from '@/components/input/boolean/InputBooleanCheckbox.vue';
import InputString from '@/components/input/string/InputString.vue';
import InputSelect from '@/components/input/select/InputSelect.vue';
import InputScript from '@/components/input/script/InputScript.vue';
import { deepEqual } from 'fast-equals';
import { TypeEmbedMeta } from '@fillip/api';

const editors = {
  select: InputSelect,
  range: InputNumberSlider,
  boolean: InputBooleanCheckbox,
  string: InputString,
};

export default Vue.extend({
  name: 'InputEmbed',
  components: {
    InputString,
    InputSelect,
    InputScript,
  },
  props: {
    value: {
      type: Object,
      default: () => clone(TypeEmbedMeta.default),
    },
    showEmbedCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buffer: null,
      error: null,
      options: {},
      editors,
    };
  },
  computed: {
    embedSource() {
      return this.buffer.embedSrc;
    },
    embedOptions() {
      return this.buffer.embedOptions;
    },
    embedMode() {
      return this.buffer.embedMode;
    },
    embedModes() {
      return [
        { value: '1', text: 'iFrame' },
        { value: 'card', text: 'Card' },
        { value: '0', text: 'Provider Native' },
      ];
    },
    errorMessages() {
      return this.error ? [this.error] : [];
    },
    hasErrors() {
      return Boolean(this.error);
    },
    availableOptions() {
      if (!this.buffer?.embedMeta?.meta?.options) {
        return [];
      }
      const determineType = (option: Record<string, any>) => {
        if (option.values) {
          if (Object.keys(option.values).length > 1) {
            return 'select';
          } else {
            return 'boolean';
          }
        }
        if (option.range) return 'range';
        if (typeof option.value == 'boolean') return 'boolean';
        if (typeof option.value == 'string') return 'string';
        // if (option.placeholder) return 'string';
        return null;
      };

      return Object.entries(this.buffer.embedMeta.meta.options).map(
        ([key, option]: [string, Record<string, any>]) => {
          // eslint-disable-next-line prefer-const
          let { value, label, placeholder, range = {}, values } = option;
          let items = [];
          let trueValue;
          let falseValue;

          if (values) {
            // map options object to items array
            items = Object.entries(values).map(([ikey, ivalue]) => ({
              value: ikey,
              text: ivalue,
            }));

            // Convert options with just one selection to checkbox
            if (Object.keys(values).length == 1) {
              trueValue = Object.keys(values)[0];
              falseValue = '';
              label = Object.values(values)[0];
            }

            // Add empty default selection if not provided
            if (!Object.keys(values).includes(value)) {
              items.unshift({ value: value, text: 'Default' });
            }
          }

          return {
            key,
            type: determineType(option),
            props: {
              value:
                this.buffer.embedOptions[key] != undefined
                  ? this.buffer.embedOptions[key]
                  : value,
              label,
              placeholder,
              min: range.min,
              max: range.max,
              trueValue: trueValue || true,
              falseValue: typeof falseValue == 'string' ? falseValue : false,
              items,
            },
          };
        },
      );
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (deepEqual(this.buffer, newValue)) {
          return;
        }
        this.buffer = clone(newValue);
      },
    },
    embedSource(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.error = null;
      if (!newValue) {
        return this.resetToDefaults();
      }
      if (!newValue.match(/^https?:\/\//i)) {
        this.error = 'Invalid URL';
        this.resetToDefaults();
      }

      return this.getIframelyInfo(newValue, this.embedOptions);
    },
    embedMode(newValue, oldValue) {
      if (newValue != oldValue) {
        return this.getIframelyInfo(this.embedSource, this.embedOptions);
      }
    },
    embedOptions: {
      deep: true,
      handler(newValue) {
        if (newValue && Object.keys(newValue).length > 0) {
          return this.getIframelyInfo(this.embedSource, newValue);
        }
      },
    },
  },
  methods: {
    save() {
      const result = clone(this.buffer);
      this.$emit('input', result);
      this.$emit('blur', result);
    },
    updateSetting($event, option) {
      Vue.set(this.buffer.embedOptions, option.key, $event);
      // Check if option is actually different from default
      // If it's set to default, remove it from embedOptions
      if (
        this.query?.length > 0 &&
        this.buffer.embedOptions &&
        Object.keys(this.buffer.embedOptions).length > 0
      ) {
        Object.keys(this.buffer.embedOptions).forEach((key) => {
          if (!this.query.includes(key)) {
            delete this.buffer.embedOptions[key];
          }
        });
      }
      this.save();
    },
    resetToDefaults() {
      this.buffer.embedMeta = null;
      this.buffer.embedCode = '';
      this.buffer.embedMode = '1';
      this.save();
    },
    async getIframelyInfo(embedSrc, settings = {}) {
      const url = encodeURI(embedSrc);
      const params = {
        url,
        key: '5783e12b2315b513121288837d8d73b1',
        omit_script: 1,
        omit_css: 1,
        lazy: 1,
        iframe: this.buffer.embedMode,
        playerjs: 1,
        ...settings,
      };

      try {
        const result = await axios.get('https://iframe.ly/api/iframely/', {
          params,
        });

        const { status, error, options, html, meta, ...rest } = result.data;
        if (!status || Number(status) < 400) {
          if (options) {
            const { query = [], ...availableOptions } = options;
            meta.options = availableOptions;
            this.query = query;
          }

          this.buffer.embedMeta = { meta, ...rest };
          this.buffer.embedCode = html;
          this.save();
        } else if (Number(status) >= 500) {
          this.error = error;
        } else {
          this.error = error;
          this.resetToDefaults();
        }
      } catch (error) {
        console.error('Error while embedding', 'error', error);
        throw new Error(error);
      }
    },
  },
});
