import { calculateZoomToFitLocation } from './CameraUtils';
import {
  type Location3DType,
  type ModuleCamera,
  type Vector3Type,
  deepEqual,
} from '@fillip/api';
import type { CameraEntity } from './../../core/camera';
import type { IBaseControls } from './index';
import CameraControls from 'camera-controls';
import {
  MOUSE,
  Vector2,
  Vector3,
  Vector4,
  Quaternion,
  Matrix4,
  Spherical,
  Box3,
  Sphere,
  Raycaster,
  MathUtils,
  Clock,
  Object3D,
  PerspectiveCamera,
} from 'three';
// import { debounce } from 'lodash';

const subsetOfTHREE = {
  MOUSE: MOUSE,
  Vector2: Vector2,
  Vector3: Vector3,
  Vector4: Vector4,
  Quaternion: Quaternion,
  Matrix4: Matrix4,
  Spherical: Spherical,
  Box3: Box3,
  Sphere: Sphere,
  Raycaster: Raycaster,
  MathUtils: {
    DEG2RAD: MathUtils.DEG2RAD,
    clamp: MathUtils.clamp,
  },
};

CameraControls.install({ THREE: subsetOfTHREE });
export class Base3dControls implements IBaseControls {
  public context: CameraEntity;
  public sceneCamera: ModuleCamera;
  public sceneBoundingBox: Box3;
  public controls: CameraControls;
  public clock: Clock = null;
  public throttledSync: (...args: any) => any;

  constructor(context, sceneCamera: ModuleCamera) {
    this.context = context;
    this.sceneCamera = sceneCamera;
    this.initialize();
  }

  initialize() {
    this.controls = new CameraControls(
      this.getCamera(),
      this.context.engine.canvasElement,
    );

    // this.throttledSync = debounce(
    //   () => {
    //     console.log('throttledSync');
    //     this.context.contentTransform.sync();
    //     // this.context.syncTargetWithContent();
    //   },
    //   100,
    //   { trailing: true },
    // );

    this.controls.addEventListener('controlstart', (event) => {
      // console.log('Camera: Control start', event);
      this.context.engine.cameraIsMoving(true);
    });
    this.controls.addEventListener('controlend', (event) => {
      // console.log('Camera: Control end', event);
      this.context.engine.cameraIsMoving(false);
    });
    this.controls.addEventListener('control', (event) => {
      // console.log('Camera: Control', event);
      this.renderInLoop();
    });
    this.controls.addEventListener('update', (event) => {
      // console.log('Camera: update', event);
      // TODO: Should we activate that?
      // this.throttledSync();
    });
    // TODO: Should we activate that?
    // this.controls.addEventListener('rest', (event) => {
    //   console.log('Camera: Rest', event);
    //   // this.onRest();
    // });
    // this.controls.addEventListener('sleep', (event) => {
    //   console.log('Camera: Sleep', event);
    //   // this.onRest();
    // });

    this.clock = new Clock();
  }

  updateCamera(
    sceneCamera: ModuleCamera,
    sceneBoundingBox: Box3,
    hasParentChanged: boolean,
  ) {
    this.sceneCamera = sceneCamera;
    this.sceneBoundingBox = sceneBoundingBox;
    this.checkCameraPosition();
  }

  onRest() {
    console.log('Controls Rest');
    this.context.syncTargetWithContent();
    // this.controls.setPosition(0, 0, 0, false);
  }

  checkCameraPosition() {
    return;
  }

  truck(x: number, y: number): void {
    this.controls.truck(x, y, true);
  }

  zoom(z: number): void {
    console.warn('zoom method must be implemented by indidivdual controls');
  }

  moveTo(location: Location3DType | Vector3Type) {
    // TODO: Implement
    console.warn('moveTo not implemented');
  }

  lookAt(position: Vector3Type | number, y?: number, z?: number) {
    // TODO: Implement
    console.warn('lookAt not implemented');
    const x = typeof position == 'number' ? position : position.x;
    y = typeof position == 'number' ? y : position.y;
    z = typeof position == 'number' ? z : position.z;

    console.log('Look at', x, y, z);
    this.controls.setTarget(x, y, z);
    this.context.engine.render();
  }

  async zoomToFit(focusedObject: Box3 | Object3D) {
    const targetLocation = calculateZoomToFitLocation(
      focusedObject,
      this.context.engine.viewport,
      {
        // TODO: Add options to module
        limitZoomForScreenSize: false,
        minDistance:
          this.sceneCamera.type == 'camera.auto-zoom'
            ? this.sceneCamera.zoomMinDistance
            : 100,
        maxDistance:
          this.sceneCamera.type == 'camera.auto-zoom'
            ? this.sceneCamera.zoomMaxDistance
            : 15000,
      },
    );
    if (deepEqual(targetLocation, this.context.getCameraTargetLocation)) return;

    this.context.setCameraTarget(targetLocation);

    // this.animate();
    // await this.controls.fitToBox(focusedObject, true);
    // this.stopAnimation();
  }

  getCamera(): PerspectiveCamera {
    return this.context.content as unknown as PerspectiveCamera;
  }

  enable() {
    this.controls.enabled = true;
  }

  disable() {
    this.controls.enabled = false;
  }

  async dispose() {
    if (this.controls) {
      this.controls.removeAllEventListeners('controlstart');
      this.controls.removeAllEventListeners('controlend');
      this.controls.removeAllEventListeners('control');
      this.controls.removeAllEventListeners('update');

      this.controls.dispose();
      this.controls = null;
    }
    this.context.engine.render();
  }

  renderInLoop() {
    const delta = this.clock.getDelta();
    const updated = this.controls.update(delta);
    if (updated) {
      this.context.engine.render();
    }

    return true;
  }

  animate(force = false) {
    // if (force || this.controls.enableDamping || this.controls.autoRotate) {
    const animate = () => {
      const delta = this.clock.getDelta();
      this.controls.update(delta);
      return true;
    };

    this.context.engine.renderManager.onRender('cameraControls', animate);
    // }
  }

  stopAnimation(force = false) {
    // if (force || (!this.controls.enableDamping && this.controls.autoUpdate)) {
    this.context.engine.renderManager.removeOnRender('cameraControls');
    // }
  }
}
