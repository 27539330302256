








































































import CommunityHubSection from './CommunityHubSection.vue';
import { useCommunities, useAuth, useShell, useI18n } from '@/composables';
import { computed, defineComponent, onBeforeMount } from '@vue/composition-api';
import { useToggle } from '@vueuse/core';

export default defineComponent({
  name: 'CommunityHub',
  components: { CommunityHubSection },
  setup(props, { emit }) {
    const {
      publicCommunities,
      createdCommunities,
      joinedCommunities,
      canUserCreateCommunity,
      gotoAddCommunity,
      disconnectAllCommunities,
      enterCommunity,
      getAllCommunities,
    } = useCommunities();
    const { editAccount, logoutActions } = useAuth();
    const { tr } = useI18n();
    const { $user } = useAuth();

    const greeting = computed(
      () => `${tr('auth.hello')} ${$user.value.username}!`,
    );

    onBeforeMount(async () => {
      await disconnectAllCommunities();
      await getAllCommunities();
    });

    const { shell, toggleLoading } = useShell();
    const [showLogoutMenu, toggleLogoutMenu] = useToggle(false);

    return {
      publicCommunities,
      createdCommunities,
      joinedCommunities,
      canUserCreateCommunity,
      gotoAddCommunity,
      enterCommunity,
      shell,
      toggleLoading,
      showLogoutMenu,
      toggleLogoutMenu,
      logoutActions,
      editAccount,
      greeting,
    };
  },
});
