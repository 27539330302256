import { shallowEqual } from 'fast-equals';
import { Object3D, Box3, Vector3, Box3Helper, Color } from 'three';
import {
  CSS3DObject,
  CSS3DSprite,
} from 'three/examples/jsm/renderers/CSS3DRenderer';
import { nanoid } from 'nanoid';

import type { Engine } from './engine';
import { VNode } from './../vnode';
import type {
  Location3DType,
  EvaluatedModuleSize,
  Logger,
  ModuleDraggable,
  EvaluatedModuleArrangement,
  AnimationOptions,
  AnimationTarget,
  TransitionOptions,
  Id,
  Vector3Type,
  EvaluationContext,
  ModuleDroppable,
} from '@fillip/api';

import {
  useTrackPerformance,
  useLogger,
  LogLevels,
  evaluate,
} from '@fillip/api';
import * as Location3D from '@/utils/location3D';
import { Object3DAnimator, resolveTransition } from './../systems/animation';
import {
  applySizeConstraints,
  parseSizeConstraints,
  consolidateSizeConstraints,
  type Size2D,
  type Size3D,
  type SizeConstraints,
  DefaultSizeConstraints,
} from './../systems/size';
import {
  groupChildrenByPlacement,
  isAtSamePosition,
  layoutAbsoluteChildren,
  layoutArrangedChildren,
  layoutFixedChildren,
} from './../systems/layout';
import { isSheetActive } from '../systems';
import { addClasses, patchClasses, removeClasses } from './../systems/classes';
import { patchStyle } from './../systems/styles';
import { VueComponentInstance } from './../systems/components';

import gsap from 'gsap';
import { Draggable } from 'gsap/all';
import { isMouseEventOverElement } from '../systems/drag-drop/drag-drop.utils';

// Set the first argument to true in order to activate performance monitoring
// If set to false, trackPerfomance is a noop
const trackPerformance = useTrackPerformance(false, 'entity');

export type EntityObject3D = Object3D & { entity: Entity };
export type EntityHTMLElement = HTMLElement & { entity: Entity };
interface DraggableInstance {
  draggable: Draggable;
  hits: Record<string, EntityHTMLElement>;
  isDragged: boolean;
  dragEnded: boolean;
  dropTargets: Element[];
  droppedOn: string;
  ghost?: Element;
}

const _SIZE_VECTOR3 = new Vector3();

// TODO:
// Turn Off matrix Auto Update and instead call updateMatrix manually

export class Entity {
  public id: Id;
  public cssId: string;

  public engine: Engine;
  public vnode: VNode;

  public logger: Logger;

  public parent: Entity = null;
  public children: Entity[] = [];

  public target: EntityObject3D;
  // TODO: Should this initially be 'parent'?
  public targetMountPoint: 'camera' | 'parent' | '' = '';

  public carrier: EntityObject3D;
  public carrierTransform: Object3DAnimator;
  public carrierIsAtTarget: boolean = false;
  public carrierIsDetached: boolean = true;

  public content: EntityObject3D; // Contains this.css3DObject + children[i].carrier for attached children
  public contentTransform: Object3DAnimator;
  public contentIsAtCarrier: boolean = true;

  public isLeaving: boolean = false;
  public hasSceneSwitch: boolean = false;

  public boundingBox: Box3 = new Box3();

  public el: EntityHTMLElement; // Contains vm.$el and children[i].el (if elMountPoint == 'parent')
  // TODO: Should this initially be 'null'?
  public elMountPoint: '3D' | 'parent' = 'parent';

  public css3DObject: CSS3DObject | CSS3DSprite | null = null; // contains this.el

  public element: VueComponentInstance;
  public model: VueComponentInstance;

  public draggable: DraggableInstance = null;

  private eventListeners: { [key: string]: (event: MouseEvent) => void } = {};

  // During patch
  public nextVnode: VNode | null;
  // Up to which child index the previous and new child array overlap
  // Initially, we don't know, so it's -1 to make sure we don't regard
  // the first element as overlapping by setting overlap to 0
  public overlap: number = -1;

  constructor(engine: Engine, id: Id, object3D?: Object3D) {
    // Setup entity
    this.engine = engine;
    this.id = id;
    this.cssId = id.replace(':', '_-_');
    this.logger = useLogger(LogLevels.WARN, LogLevels.NONE, `entity::${id}`);

    // Create 3d objects
    // TODO: Move to mountAsElement3d
    this.createContent(object3D);
    this.createCarrier();
    this.createTarget();

    // Create html element
    this.createHtmlElement();

    // this.toggleBoxHelpers();

    this.vnode = new VNode({}, []);
    this.engine.setEntity(this);
  }

  private createContent(object3D?: Object3D) {
    this.content = (object3D || new Object3D()) as EntityObject3D;
    this.content.name = `content:${this.id}`;
    this.content.entity = this;
    this.contentTransform = new Object3DAnimator(this.content);
  }

  private removeContent() {
    if (gsap.isTweening(this.contentTransform)) {
      gsap.killTweensOf(this.contentTransform);
    }
    this.content.removeFromParent();
    this.content = null;
    this.contentTransform = null;
  }

  private createCarrier() {
    this.carrier = new Object3D() as EntityObject3D;
    this.carrier.name = `carrier:${this.id}`;
    this.carrier.entity = this;
    this.carrier.add(this.content);
    this.carrierTransform = new Object3DAnimator(this.carrier);
  }

  private removeCarrier() {
    if (gsap.isTweening(this.carrierTransform)) {
      gsap.killTweensOf(this.carrierTransform);
    }
    this.carrier.removeFromParent();
    this.carrier = null;
    this.carrierTransform = null;
  }

  private createTarget() {
    this.target = new Object3D() as EntityObject3D;
    this.target.name = `target:${this.id}`;
    this.target.entity = this;
  }

  private removeTarget() {
    this.target.removeFromParent();
    this.target = null;
  }

  private createHtmlElement() {
    this.el = document.createElement('div') as unknown as EntityHTMLElement;
    this.el.id = 'container_-_' + this.cssId;
    this.el.entity = this;

    this.addEventListeners();
  }

  private removeHtmlElement() {
    this.removeEventListeners();
    this.el = null;
  }

  private handleClick(event: MouseEvent) {
    if (!this.engine.isCameraMoving) {
      this.handleEvent('click', event);
    }
  }
  private handleMouseEnter(event: MouseEvent) {
    this.handleEvent('mouseenter', event);
  }
  private handleMouseLeave(event: MouseEvent) {
    this.handleEvent('mouseleave', event);
  }
  private handleDblClick(event: MouseEvent) {
    // if (!this.css3DObject) return;
    // this.engine.camera.zoomToFit(this.boundingBox);
  }
  private addEventListeners() {
    if (!this.eventListeners.click) {
      this.eventListeners.click = (event) => this.handleClick(event);
    }
    this.el.addEventListener('click', this.eventListeners.click);
    if (!this.eventListeners.dblclick) {
      // this.eventListeners.dblclick = (event) => this.handleDblclick(event);
    }
    // this.el.addEventListener('dblclick', this.eventListeners.dblclick);
    if (!this.eventListeners.mouseenter) {
      this.eventListeners.mouseenter = (event) => this.handleMouseEnter(event);
    }
    this.el.addEventListener('mouseenter', this.eventListeners.mouseenter);
    if (!this.eventListeners.mouseleave) {
      this.eventListeners.mouseleave = (event) => this.handleMouseLeave(event);
    }
    this.el.addEventListener('mouseleave', this.eventListeners.mouseleave);
  }
  private removeEventListeners() {
    this.el.removeEventListener('click', this.eventListeners.click);
    // this.el.removeEventListener('dblclick', this.eventListeners.dblClick);
    this.el.removeEventListener('mouseenter', this.eventListeners.mouseenter);
    this.el.removeEventListener('mouseleave', this.eventListeners.mouseleave);
  }

  get isAtTarget() {
    return this.carrierIsAtTarget && this.contentIsAtCarrier;
  }

  getSize(): Size3D {
    const sizeVec = this.boundingBox.getSize(_SIZE_VECTOR3);
    return {
      width: sizeVec.x,
      height: sizeVec.y,
      depth: sizeVec.z,
    };
  }

  startCarrierTransitions() {
    this.logger.debug(
      'startCarrierTransitions',
      this.carrierIsAtTarget,
    );
    if (!this.carrierIsAtTarget) {
      if (this.carrierIsDetached) {
        this.logger.debug('Carrier is detached');

        this.target.updateWorldMatrix(true, false);
      }

      const target = this.carrierIsDetached
        ? this.carrierTransform.getTargetFromMatrix4(this.target.matrixWorld)
        : this.target;
      const animationTarget: AnimationTarget =
        Object3DAnimator.fromObject3D(target);

      if (!this.carrier.parent) {
        this.enter(animationTarget);
      } else {
        if (this.carrierTransform.isAtTarget(target)) {
          // Do nothing if we are already at the target
          this.setCarrierReachedTarget();
        } else if (!this.carrierTransform.isTweeningTo(target)) {
          // check that there's not already a tween running
          this.transitionCarrier({ to: animationTarget });
        } else {
          // Nothing to do here, tween is already running
        }
      }
    }

    this.children.forEach((child) => child.startCarrierTransitions());
  }

  enter(to: AnimationOptions) {
    this.engine.scene.add(this.carrier);
    this.handleEvent('load', this.id);

    const transition = this.vnode?.props.transitions?.enter || 'scaleIn';
    const enterFrom = this.vnode?.props.transitions?.enterFrom || 'target';

    // Start the enter transition from the future world position
    // Will be partially overwritten with from options in selected transition

    const from =
      enterFrom === 'target'
        ? Object3DAnimator.fromAny(to)
        : Object3DAnimator.fromMatrix4(this.parent.target.matrixWorld);

    const onComplete = () => {
      this.setCarrierReachedTarget();
      this.handleEvent('ready', this.id);
    };

    const onInterrupt = () => {
      this.handleEvent('ready', this.id);
    };

    this.logger.debug('Enter', to, transition, from, onComplete);
    this.transitionObject({
      targetObject: this.carrierTransform,
      to,
      transition,
      from,
      onComplete,
      onInterrupt,
    });
  }

  leave() {
    this.logger.debug('Leaving');
    this.handleEvent('beforeLeave', this.id);

    this.engine.setEntityLeaving(this);

    const transition = this.vnode?.props.transitions?.leave || 'scaleOut';
    const leaveTo = this.vnode?.props.transitions?.leaveTo || 'target';
    // TODO: Often cannot find parent, so this actually behaves like 'target' most of times
    const from =
      leaveTo === 'target' || !this.parent?.target
        ? this.carrierTransform.animationTarget
        : Object3DAnimator.fromMatrix4(this.parent.target.matrixWorld);

    const to = this.carrierTransform.animationTarget;

    const onComplete = () => {
      this.delete();
    };

    // TODO: OnInterrupt is not called when the tween is killed
    const onInterrupt = () => {
      this.logger.warn('Leave interrupted');
      // this.delete();
    };

    this.transitionObject({
      targetObject: this.carrierTransform,
      to,
      transition,
      from,
      onComplete,
      onInterrupt,
    });

    this.traverseChildren((entity) => {
      this.engine.setEntityLeaving(entity);
    });
  }

  delete() {
    this.logger.debug('Delete Entity');
    this.handleEvent('beforeUnload', this.id);
    while (this.children.length) {
      this.children[this.children.length - 1].delete();
    }
   
    if (this.css3DObject) {
      this.unmountElementAs3DSheet();
    }

    if (this.element) this.element = this.element.unload();

    if (this.model) this.model = this.model.unload();


    if (this.draggable) {
      this.draggable.draggable.kill();
      this.draggable = null;
    }

    if (this.parent) {
      this.parent.removeChild(this);
    } else {
      this.el.remove();
    }

    this.removeHtmlElement();

    this.removeContent();
    this.removeCarrier();
    this.removeTarget();

    this.handleEvent('unload', this.id);

    this.parent = null;
    this.vnode = null;
    this.boundingBox = null;

    this.engine.unsetEntity(this);

    this.logger.debug('Scene after delete', this, this.engine.scene);
  }

  transitionCarrier(
    { to, transition, from, onComplete, onUpdate }: {
      to: AnimationOptions;
      transition?: TransitionOptions;
      from?: AnimationTarget;
      onComplete?: () => void;
      onUpdate?: () => void;
    },
  ) {
    const onTransitionComplete = () => {
      if (typeof onComplete === 'function') onComplete();
      this.setCarrierReachedTarget();
    };
    const onTransitionUpdate =
      typeof onUpdate === 'function' ? onUpdate : null;

    const transitionToUse = (() => {
      if (this.engine.dragged?.props.id === this.id) {
        return false;
      }
      if (transition != null) {
        return transition;
      }
      if (this.vnode?.props.transitions?.transform === 'none') {
        return false;
      }
      if (this.vnode?.props.transitions?.transform) {
        return this.vnode?.props.transitions?.transform;
      }
      return 'default';
    })();

    this.logger.debug('transitionCarrier', to, transitionToUse, from);
    this.transitionObject({
      targetObject: this.carrierTransform,
      to,
      transition: transitionToUse,
      from,
      onComplete: onTransitionComplete,
      onUpdate: onTransitionUpdate,
    });
  }

  transitionObject({
    targetObject,
    to,
    transition = 'default',
    from,
    onComplete,
    onUpdate,
    onInterrupt,
  }: {
    targetObject: Object3DAnimator;
    to: AnimationOptions;
    transition?: TransitionOptions;
    from?: AnimationTarget;
    onComplete?: () => void;
    onUpdate?: () => void;
    onInterrupt?: () => void;
  }) {
    this.logger.debug('transitionObject', targetObject, to, transition, from);

    if (!this.engine.appIsVisible) transition = false;
    const transitionToUse = resolveTransition(transition);

    const onTransitionUpdate = () => {
      targetObject.update();
      if (typeof onUpdate === 'function') onUpdate();
    };

    const onTransitionComplete = () => {
      if (typeof onComplete === 'function') onComplete();
      this.engine.render();
    };

    const onTransitionInterrupt = () => {
      if (typeof onInterrupt === 'function') onInterrupt();
      this.engine.render();
    };

    if (!transitionToUse?.to) {
      // No transition, immediately set `to`
      this.logger.debug('No transition', targetObject, to);
      gsap.set(targetObject, to).play();
      targetObject.update();
      onTransitionComplete();
      return;
    }

    const _to: AnimationOptions = {
      overwrite: true,
      onUpdate: onTransitionUpdate,
      onComplete: onTransitionComplete,
      onInterrupt: onTransitionInterrupt,
      ...to,
      ...(transitionToUse?.to || {}),
    };
    let _from: AnimationTarget = from || null;

    if ('from' in transitionToUse) {
      _from = {
        ...(_from || {}),
        ...transitionToUse.from,
      };
    }

    if (_from) {
      return gsap.fromTo(targetObject, _from, _to).play();
    }

    return gsap.to(targetObject, _to).play();
  }

  traverseChildren(callback: (entity: Entity) => void) {
    this.children.forEach((child) => {
      child.traverseChildren(callback);
      callback(child);
    });
  }

  mountElementAs3DSheet(newNode?: VNode) {
    const orientTowardsCamera = newNode?.props?.sheet?.orientTowardsCamera;

    if (this.elMountPoint === '3D') {
      // If the element is already mounted as a 3D sheet and ...
      if (
        this.vnode?.props?.sheet?.orientTowardsCamera === orientTowardsCamera
      ) {
        // ... its orientation stays the same, we need to do nothing
        return;
      }

      // ... its orientation changes, we need to unmount it first
      // ... or it needs to be unmounted for print view
      this.unmountElementAs3DSheet();
    }

    this.logger.debug('mountElementAs3DSheet');

    if (this.engine.displayMode === 'print') {
      return;
    }

    const cssElement = document.createElement('div');
    cssElement.id = `carrier_-_${this.cssId}`;
    cssElement.classList.add('css3d-carrier');
    this.css3DObject = orientTowardsCamera
      ? new CSS3DSprite(cssElement)
      : new CSS3DObject(cssElement);

    this.css3DObject.name = `css3d_-_${this.cssId}`;
    // We'll show the element again after it is mounted
    this.css3DObject.visible = false;
    this.css3DObject.element.appendChild(this.el);
    this.engine.resizeObserver.observe(this.css3DObject.element, {});

    const containerPointerNone =
      (newNode?.props?.placement?.type === 'placement.absolute' &&
        newNode.props.placement.containerPointerNone) ||
      newNode?.props?.sheet?.containerPointerNone;

    if (containerPointerNone) {
      this.css3DObject.element.style.pointerEvents = 'none';
    }

    this.content.add(this.css3DObject);
    this.elMountPoint = '3D';
  }

  unmountElementAs3DSheet() {
    this.el.remove();
    this.engine.resizeObserver.unobserve(this.css3DObject.element);
    this.css3DObject.element.remove();
    this.css3DObject.element = null;
    this.css3DObject.removeFromParent();
    this.css3DObject = null;
  }

  mountElementInParent() {
    if (this.elMountPoint === 'parent') return;
    this.logger.debug('mountElementInParent');

    if (this.css3DObject) {
      this.unmountElementAs3DSheet();
    }

    if (this.parent) {
      const index = this.parent.children.indexOf(this);

      const nextEl = this.parent.findNextMountedChildElement(index);
      this.parent.el.insertBefore(this.el, nextEl);
    } else if (this.id === 'root') {
      this.engine.canvasElement.appendChild(this.el);
    } else {
      throw new Error('No parent and not root');
    }

    this.elMountPoint = 'parent';
  }

  mountTargetInCamera() {
    this.logger.debug('mountTargetInCamera');
    this.engine.camera.cameraTarget.add(this.target);
    this.targetMountPoint = 'camera';
    this.carrierIsAtTarget = false;
    this.carrierIsDetached = true;
  }

  unmountTarget() {
    this.logger.debug('unmountTarget');
    this.target.removeFromParent();
    this.targetMountPoint = '';
  }

  // Is called during patch process to remove a child completely from its parent
  removeChild(child: Entity) {
    this.logger.debug('removeChild', child);
    // Optimization: Check if we are poping the last child
    const index =
      this.children[this.children.length - 1] === child
        ? this.children.length - 1
        : this.children.indexOf(child);
    this.children.splice(index, 1);
    child.parent = null;

    child.unmountTarget();

    if (gsap.isTweening(this.carrierTransform)) {
      gsap.killTweensOf(this.carrierTransform); // Since the target has changed, it's better to stop all tweens
    }
    if (!child.carrierIsDetached) {
      this.engine.root.content.attach(child.carrier);
      child.carrierTransform.sync();
      // Maybe more efficient than attach:
      // const child3D = child.object3D;
      // child3D.updateWorldMatrix(true, false);
      // this.object3D.remove(child3D);
      // child3D.matrix.copy(this.object3D.matrixWorld);
      // child3D.matrix.decompose(
      //   child3D.position,
      //   child3D.quaternion,
      //   child3D.scale,
      // );
      // child3D.updateWorldMatrix(false, true);
      child.carrierIsDetached = true;
      child.carrierIsAtTarget = false; // Since target doesn't exist if parent == null
    }

    if (child.elMountPoint === 'parent') {
      child.el.remove();
    }
  }

  removeFromParent() {
    this.logger.debug('removeFromParent', this.parent);
    if (this.parent) {
      this.parent.removeChild(this);
    }
  }

  appendChild(child: Entity) {
    this.logger.debug('appendChild', child);
    child.removeFromParent();

    this.target.add(child.target);
    child.targetMountPoint = 'parent';

    if (child.elMountPoint === 'parent') {
      this.el.appendChild(child.el);
    }
    this.children.push(child);
    child.parent = this;
  }

  setCarrierReachedTarget() {
    if (this.carrierIsAtTarget) return;
    this.logger.debug('carrierReachedTarget');
    this.carrierIsAtTarget = true;
    this.attachNowIfAtTarget();
  }

  setContentReachedCarrier() {
    if (this.contentIsAtCarrier) return;
    this.contentIsAtCarrier = true;
    this.attachNowIfAtTarget();
  }

  // To be called when an element reaches its target position, so it and its children can be attached
  attachNowIfAtTarget() {
    this.logger.debug(
      'attachNowIfAtTarget',
      this.targetMountPoint,
      this.carrierIsAtTarget,
      this.carrierIsDetached,
    );
    if (!this.carrierIsAtTarget) return;
    if (this.carrierIsDetached && this.parent && this.parent.isAtTarget) {
      if (this.targetMountPoint === 'parent') {
        this.parent.content.attach(this.carrier);
      } else if (this.targetMountPoint === 'camera') {
        this.engine.camera.content.attach(this.carrier);
      }
      this.carrierTransform.sync();
      this.carrierIsDetached = false;
    }

    // TODO: Optimize this with a flag
    this.children.forEach((child) => {
      child.attachNowIfAtTarget();
    });
  }

  private findNextMountedChildElement(index): EntityHTMLElement | null {
    for (let i = index; i < this.children.length; ++i) {
      if (this.children[i].elMountPoint === 'parent') {
        return this.children[i].el;
      }
    }
    return null;
  }

  public handleEvent(
    name: string,
    event: any,
    hops: number = 0,
    source?: string,
  ): void {
    const handler = this.vnode.props.listener?.listeners?.[name];
    const context = this.vnode.props.context;

    this.logger.debug('handleEvent', name, event, hops, source, handler);

    if (
      handler &&
      (!source ||
        (handler.listenToChildren &&
          (!handler.maxDepth || hops <= handler.maxDepth)))
    ) {
      if (typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }
      this.engine.emit('event', {
        name,
        event,
        script: handler.script,
        context: {
          ...handler.context,
          ...context,
          ...context.$vars,
          ...context.$props,
          ...context.$computeds,
        },
        entity: this,
      });
    }

    if (!handler?.stopPropagation && this.parent) {
      this.parent.handleEvent(name, event, hops + 1, this.id);
    }
  }

  private toggleBoxHelpers() {
    // TODO: Make this toggable
    this.target.add(new Box3Helper(this.boundingBox, new Color('green')));
    this.carrier.add(new Box3Helper(this.boundingBox, new Color('blue')));
    this.content.add(new Box3Helper(this.boundingBox, new Color('red')));
  }

  private patchElement(newNode: VNode) {
    this.element = VueComponentInstance.patch(
      newNode.props.id,
      'element',
      this.vnode?.props?.element,
      newNode.props.element,
      this.element,
      this,
    );
    if (this.element)
      this.element.load({ classes: newNode.props.class?.elementClass });
  }

  private patchModel(newNode: VNode) {
    this.model = VueComponentInstance.patch(
      newNode.props.id,
      'model',
      this.vnode?.props?.model,
      newNode.props.model,
      this.model,
      this,
    );
    if (this.model) this.model.load();
  }

  applyPatch() {
    this.logger.debug(
      this.id,
      'applyPatch',
      this.nextVnode,
      this.targetMountPoint,
      this.overlap,
    );
    if (!this.nextVnode) {
      this.logger.warn('Duplicate VNode');
      return;
    }

    if (
      this.nextVnode.props?.placement?.type !== 'placement.fixed' &&
      this.targetMountPoint === 'camera'
    ) {
      this.unmountTarget();
    }

    if (
      this.nextVnode.props?.placement?.type === 'placement.fixed' &&
      this.targetMountPoint !== 'camera'
    ) {
      this.mountTargetInCamera();
    }

    if (!this.targetMountPoint) {
      // console.log('No target mount point, mount in parent', this.id, this.parent)
      if (this.parent?.target) {
        this.parent.target.add(this.target);
      }
      this.targetMountPoint = 'parent';
    }

    // Only remove children if we had any
    if (this.children.length > 0) {
      // Remove all children after the overlap, accounting for overlap being a zero-based index
      while (this.children.length - 1 !== this.overlap) {
        const child = this.children[this.children.length - 1];
        this.removeChild(child);
      }
    }

    // Only add children if we will have any in next
    if (this.nextVnode.children.length > 0) {
      // Add children in next starting after the last overlapping child
      for (let i = this.overlap + 1; i < this.nextVnode.children.length; ++i) {
        this.appendChild(this.nextVnode.children[i].entity);
      }
    }

    this.vnode = this.nextVnode;
    this.nextVnode = null;
    this.children.forEach((child) => child.applyPatch());
  }

  diff(newNode: VNode) {
    this.nextVnode = newNode;
    this.logger.debug('Start diff', newNode);

    this.patchElement(newNode);
    this.patchModel(newNode);

    this.patchSheet(newNode);

    this.patchStyle(newNode);
    this.patchClass(newNode);

    this.patchDroppable(newNode);
    this.patchDraggable(newNode);

    this.compareCurrentAndNextChildren();

    newNode.children.forEach((child) => {
      child.entity.diff(child);
    });
  }

  private compareCurrentAndNextChildren() {
    let foundMismatch = false;
    const maxNumChildren = Math.max(
      this.nextVnode.children.length,
      this.vnode.children.length,
    );
    for (let i = 0; i < maxNumChildren; ++i) {
      const next = this.nextVnode.children[i];
      const current = this.vnode.children[i];

      // match from start until first mismatch
      if (
        next &&
        current &&
        !foundMismatch &&
        next.props.id === current.props.id
      ) {
        // Children arrays overlap up to and including index i
        this.overlap = i;
        next.entity = current.entity;
      } else {
        if (!foundMismatch) {
          // If no mismatch occured until now,  the last overlapping index was i - 1
          this.overlap = i - 1;
          foundMismatch = true;
        }

        if (current && !current.entity.nextVnode) {
          this.engine.setEntityObsolete(current.entity);
        }

        if (next) {
          // look for already existing node with same id
          if (next.props.id && this.engine.hasEntity(next.props.id)) {
            this.engine.unsetEntityObsolete(next.props.id);
            this.engine.unsetEntityLeaving(next.props.id);
            next.entity = this.engine.getEntity(next.props.id);
          } else {
            // create new node
            next.entity = new Entity(this.engine, next.props.id || nanoid());
          }

          next.entity.nextVnode = next;
        }
      }
    }
  }

  private patchClass(newNode: VNode) {
    const oldProps = this.vnode?.props?.class;
    const newProps = newNode?.props?.class;

    if (!oldProps && !newProps) return;

    patchClasses(this.el, oldProps?.class || '', newProps?.class || '');
    if (this.element?.vueInstance) {
      patchClasses(
        this.element.vueInstance.$el as HTMLElement,
        oldProps?.elementClass || '',
        newProps?.elementClass || '',
      );
    }
  }

  private patchStyle(newNode: VNode) {
    const oldProps = this.vnode?.props?.style;
    const newProps = newNode?.props?.style;

    if (!oldProps && !newProps) return;

    patchStyle(this.el, oldProps || {}, newProps || {});
  }

  private patchSheet(newNode: VNode) {
    if (isSheetActive(newNode.props) && this.engine.displayMode !== 'print') {
      this.mountElementAs3DSheet(newNode);
    } else {
      this.mountElementInParent();
    }
  }

  setSizeConstraints(constraints?: SizeConstraints) {
    trackPerformance.start(`layout::setSizeConstraints::${this.id}`);

    if (this.engine.displayMode === 'print') {
      constraints = DefaultSizeConstraints;
    } else if (this.vnode.props.size) {
      constraints = consolidateSizeConstraints(
        parseSizeConstraints(
          this.vnode.props.size as EvaluatedModuleSize,
          this.engine.viewport,
        ),
        constraints,
      );
    }
    if (!constraints) constraints = DefaultSizeConstraints;
    applySizeConstraints(this.el, constraints);
    if (this.css3DObject?.element) {
      const el = this.css3DObject.element;
      applySizeConstraints(el, constraints);
    }
    if (this.element?.vueInstance?.$el) {
      applySizeConstraints(this.element.vueInstance.$el as any, constraints);
    }

    trackPerformance.stop(`layout::setSizeConstraints::${this.id}`);
    return constraints;
  }

  setTargetLocation(location: Location3DType, force = false) {
    const { position, rotation, scale } = location;
    const {
      position: currentPosition,
      rotation: currentRotation,
      scale: currentScale,
    } = this.target;

    const { changed, positionChanged, rotationChanged, scaleChanged } = isAtSamePosition(location, this.target);

    if (!force && this.draggable?.dragEnded && this.engine.dragged?.props.id === this.id) {
      if (!changed) {
        this.carrierIsAtTarget = true;
        this.resetDraggable();
      }
      return;
    }

    if (positionChanged) {
      this.target.position.set(position.x, position.y, position.z);
    }
    if (
      rotationChanged
    ) {
      this.target.rotation.set(rotation.x, rotation.y, rotation.z, 'YXZ');
    }

    if (scaleChanged) {
      this.target.scale.set(scale.x, scale.y, scale.z);
    }
    if (changed) {
      this.logger.debug('targetLocationChanged', location, {currentPosition, currentRotation, currentScale})

      this.target.updateMatrix();
      this.carrierIsAtTarget = false;
    }
  }

  moveCarrierToLocation(
    location: Partial<Location3DType>,
    transition: TransitionOptions,
    onComplete?: () => void,
    onUpdate?: () => void,
  ) {
    const carrierLocation = this.getCarrierLocation();

    const target = Object3DAnimator.fromLocation({
      ...carrierLocation,
      ...location,
    });
    this.logger.debug(
      'moveCarrierToLocation',
      carrierLocation,
      location,
      target,
    );

    this.transitionCarrier({ to: target, transition, from: null, onComplete, onUpdate });
  }

  getTargetLocation(): Location3DType {
    return Location3D.getObjectLocation(this.target);
  }
  getTargetWorldPosition(): Vector3Type {
    return this.target.getWorldPosition(new Vector3());
  }
  getCarrierLocation(): Location3DType {
    return Location3D.getObjectLocation(this.carrier);
  }
  getContentLocation(): Location3DType {
    return Location3D.getObjectLocation(this.content);
  }


  // UNUSED
  moveContentToLocation(
    location: Location3DType,
    transition: TransitionOptions,
  ) {
    const to = Object3DAnimator.fromLocation(location);
    this.logger.debug('moveContentToLocation', to);
    const onComplete = () => {
      this.logger.debug('Content Moved');
      // TODO: This isn't right
      this.setContentReachedCarrier();
    };
    this.transitionObject({
      targetObject: this.contentTransform,
      to,
      transition,
      onComplete,
    });
  }

  // UNUSED
  moveContentToCarrier(transition: TransitionOptions) {
    const to = this.carrierTransform.animationTarget;
    const onComplete = () => {
      this.setContentReachedCarrier();
    };
    this.transitionObject({
      targetObject: this.contentTransform,
      to,
      transition,
      onComplete,
    });
  }

  // UNUSED
  moveCarrierToContent(transition: TransitionOptions) {
    const to = this.contentTransform.animationTarget;
    const onComplete = () => {
      this.setContentReachedCarrier();
    };
    this.carrierIsAtTarget = false;
    this.transitionObject({
      targetObject: this.carrierTransform,
      to,
      transition,
      onComplete,
    });
  }

  // UNUSED
  syncTargetWithContent() {
    this.contentTransform.sync();
    this.setTargetLocation(this.contentTransform.location);
  }

  layout(constraints: SizeConstraints = DefaultSizeConstraints) {
    this.logger.debug('Start layout', constraints);
    trackPerformance.start(`layout::${this.id}`);

    this.boundingBox.makeEmpty();
    constraints = this.setSizeConstraints(constraints);

    if (this.css3DObject) {
      this.setCss3DBoundingBox();
    }

    trackPerformance.start(`layout::sortChildren::${this.id}`);
    const { camera, absoluteChildren, arrangedChildren, fixedChildren } =
      groupChildrenByPlacement(this.vnode.children);
    trackPerformance.stop(`layout::sortChildren::${this.id}`);

    if (absoluteChildren.length) {
      trackPerformance.start(`layout::absoluteChildren::${this.id}`);
      layoutAbsoluteChildren(
        absoluteChildren,
        this.engine.viewport,
        constraints,
      );
      trackPerformance.stop(`layout::absoluteChildren::${this.id}`);
    }

    if (arrangedChildren.length) {
      trackPerformance.start(`layout::arrangedChildren::${this.id}`);
      layoutArrangedChildren(
        arrangedChildren,
        this.engine.viewport,
        constraints,
        this.vnode.props?.arrangement as EvaluatedModuleArrangement,
      );
      trackPerformance.stop(`layout::arrangedChildren::${this.id}`);
    }

    if (fixedChildren.length) {
      trackPerformance.start(`layout::fixedChildren::${this.id}`);
      layoutFixedChildren(fixedChildren, this.engine.viewport);
      trackPerformance.stop(`layout::fixedChildren::${this.id}`);
    }

    this.computeBoundingBox();

    if (camera) {
      this.updateSceneCamera(camera);
    }

    if (this.css3DObject && this.engine.displayMode !== 'print') {
      this.css3DObject.visible = true;
    }

    trackPerformance.stop(`layout::${this.id}`);
  }

  setCss3DBoundingBox() {
    trackPerformance.start(`layout::setCss3DBoundingBox::${this.id}`);
    const el = this.css3DObject.element;

    const width = el.offsetWidth;
    const height = el.offsetHeight;

    this.boundingBox.set(
      new Vector3(-width / 2, -height / 2, 0),
      new Vector3(width / 2, height / 2, 0),
    );
    trackPerformance.stop(`layout::setCss3DBoundingBox::${this.id}`);
  }

  computeBoundingBox() {
    trackPerformance.start(`layout::computeBoundingBox::${this.id}`);

    const childBounds = new Box3();
    this.vnode.children.forEach((child) => {
      if (child.entity.vnode?.props?.placement?.type !== 'placement.fixed')
        childBounds.copy(child.entity.boundingBox);

      // ! Removed because it leads to screwed up childBounds
      // Reimplement if needed
      // childBounds.applyMatrix4(child.entity.target.matrix);

      this.boundingBox.union(childBounds);
    });

    trackPerformance.stop(`layout::computeBoundingBox::${this.id}`);
  }

  updateSceneCamera(camera) {
    trackPerformance.start(`layout::updateSceneCamera::${this.id}`);

    const sceneCamera = this.engine.globalProps.sceneCamera?.[
      this.engine.globalProps.sceneCamera.length - 1
    ] || {
      type: 'camera.fixed',
    };

    this.engine.camera.updateCamera(
      sceneCamera,
      this.engine.currentPatch?.hasSceneSwitch,
    );

    trackPerformance.stop(`layout::updateSceneCamera::${this.id}`);
  }

  getElSize(): Size2D {
    const { width, height } = this.el.getBoundingClientRect();
    return { width, height };
  }

  private patchDraggable(newNode: VNode) {
    const oldProps = this.vnode?.props?.draggable;
    const newProps = newNode.props.draggable;
    
    if (!newProps && !this.draggable) return;
    if (newProps && this.draggable && shallowEqual(newProps, oldProps)) return;

    if (this.draggable) {
      removeClasses(this.el, newProps?.draggableClasses);
      removeClasses(this.el, oldProps?.draggableClasses);
      this.draggable.draggable.kill();
      this.draggable = null;
    }
    if (!newProps?.isActive) return;

    // TODO: Set cursor and activeCursor via Draggable
    // TODO: Make handler configurable
    // TODO: Add auto scroll
    // TODO: Make snap and liveSnap configurable

    // FIXME
    // TODO: Fix bounds: Element is not found because it is not initialized yet when we run document.querySelector!
    const bounds = newProps.bounds
      ? typeof newProps.bounds === 'string' &&
        (newProps.bounds.startsWith('.') || newProps.bounds.startsWith('#'))
        ? document.querySelector(newProps.bounds)
        : newProps.bounds
      : null;

    // TODO: Create draggable instance, but only initiate the Gsap draggable after entities have loaded
    const draggable = Draggable.create(this.el, {
      type: newProps.dragType,
      bounds,
      inertia: newProps.inertia ?? false,
      callbackScope: this,
      autoScroll: 1, //newProps.autoScroll ?? 1,
      dragClickables: false,
      snap: {
        x: function (endValue) {
          return Math.round(endValue);
        },
        y: function (endValue) {
          return Math.round(endValue);
        },
      },
      liveSnap: true,
      onDragStart: this.onDragStart,
      onDragEnd: newProps.inertia ? null : this.onDragEnd,
      onThrowComplete: newProps.inertia ? this.onDragEnd : null,
      onDrag: this.onDrag,
      onPress: this.onPress,
      onRelease: this.onRelease,
      clickableTest: (element: HTMLElement) => {
        // Allow for draggable items to also be clicked
        return false;
      },
      allowEventDefault: true,
    });

    this.draggable = {
      isDragged: false,
      dragEnded: false,
      dropTargets: [],
      hits: {},
      draggable: draggable[0],
      droppedOn: null,
    };
    addClasses(this.el, newProps.draggableClasses);
  }

  private onPress(event: MouseEvent | PointerEvent) {
    this.engine.camera.disableControls();
    event.preventDefault();
  }

  private onRelease(event: MouseEvent | PointerEvent) {
    this.engine.camera.enableControls();
    event.preventDefault();
  }

  private onDragStart(event: MouseEvent | PointerEvent) {
    const dragProps = this.vnode.props.draggable;
    if (!dragProps?.isActive) return;
    this.logger.debug('make entity draggable', this, this.elMountPoint);
    // if (this.elMountPoint === '3D') {
    //   this.engine.scene.add(this.css3DObject);
    // }
    this.engine.dragged = this.vnode;
    this.engine.render();
    addClasses(this.el, dragProps.draggedClasses);

    if (dragProps.onDragStart) {
      this.emitDragDropEvent('dragStart', dragProps.onDragEnd, event);
    }
    if (dragProps.droppableIdentifier) {
      this.draggable.dropTargets = Array.from(
        document.getElementsByClassName(dragProps.droppableIdentifier),
      );

      this.performHitTest(event);
    }
  }

  private onDragEnd(event: MouseEvent | PointerEvent) {
    this.logger.debug('onDragEnd', this.draggable.draggable, this.draggable.dragEnded);
    const dragProps = this.vnode.props.draggable;
    if (!dragProps?.isActive) return;

    const { pointerX, pointerY, endX, endY, deltaX, deltaY } =
      this.draggable.draggable;

    const { position } = this.getTargetLocation();

    // console.log('Target Position', position);

    const targetPosition = {
      x: position.x + endX,
      y: position.y - endY,
      z: position.z,
    };

    if (dragProps.droppableIdentifier) {
      // TODO: Reuse exising hit targets
      // for (const target of Object.values(this.draggable.hits)) {
      //   target.entity.onDrop(event, this.draggable.draggable);
      // }
      const targets = this.performImmediateHitTest(event);
      // TODO: Do we want to envoke onDrop on every target?
      if (targets.length > 0) {
        targets[0].entity.onDrop(event, this.draggable.draggable);
      }
    }
    this.draggable.dragEnded = true;

    this.logger.debug(
      'onDragEnd',
      event,
      this.draggable,
      dragProps.droppableIdentifier,
    );

    removeClasses(this.el, dragProps.draggedClasses);
    // TODO: Reset changes made by gsap, i.e. z-index

    this.logger.debug('Dropped', this.draggable, event);

    this.emitDragDropEvent('dragEnd', dragProps.onDragEnd, event, {
      targetPosition,
      pointerX,
      pointerY,
      endX,
      endY,
      deltaX,
      deltaY,
      rotation: -(endX * Math.PI) / 180,
    });

    const onComplete = () => {
      this.logger.debug('Move carrier after dragEnd complete');

      if (!this.draggable.droppedOn) {
        this.clearDraggable();
      }
    };

    const targetLocation = Location3D.addDefaults({
      position: targetPosition,
    });
    const carrierTarget = Object3DAnimator.fromLocation(targetLocation);
    this.logger.debug('Drag ended, carrierTarget: ', carrierTarget);

    if (dragProps.stayAtPosition) {
      this.setTargetLocation(targetLocation, true);
    }
    this.transitionCarrier({ to: carrierTarget, transition: false, from: null, onComplete });

    this.draggable.dropTargets = null;
    this.draggable.hits = {};
  }

  private onDrag(event) {
    const dragProps = this.vnode.props.draggable;

    if (dragProps.droppableIdentifier) {
      this.performHitTest(event);
    }
  }

  private performImmediateHitTest($event: MouseEvent | PointerEvent): EntityHTMLElement[] {
    const dragProps = this.vnode.props?.draggable;
    const testElement = dragProps.hitTarget
      ? document.querySelector(dragProps.hitTarget)
      : this.el;
    const hits = [];
    this.draggable.dropTargets.forEach((target: EntityHTMLElement) => {
      if (
        Draggable.hitTest(
          testElement,
          target,
          dragProps.droppableOverlap ?? '50%',
        )
      ) {
        hits.push(target);
      }
    });

    if (hits.length > 1) {
      return hits.filter((el) =>
        isMouseEventOverElement($event, el, dragProps.scrollableParent),
      );
    }
    return hits;
  }

  private performHitTest($event: MouseEvent | PointerEvent) {
    const dragProps = this.vnode.props?.draggable;
    const hits: Record<string, EntityHTMLElement> = {};
    const testElement = dragProps.hitTarget
      ? document.querySelector(dragProps.hitTarget)
      : this.el;
    this.draggable.dropTargets.forEach((target: EntityHTMLElement) => {
      if (
        Draggable.hitTest(
          testElement,
          target,
          dragProps.droppableOverlap ?? '50%',
        )
      ) {
        const id = target.entity.vnode.props.id;
        this.logger.debug('target', target.entity);
        hits[id] = target;
      }
    });

    // Object.values(hits).forEach((newTarget) => {
    //   const id = newTarget.entity.vnode.props.id;
    //   if (!(id in this.draggable.hits)) {
    //     this.draggable.hits[id] = newTarget;
    //     newTarget.entity.onDragEnter($event, this.draggable.draggable);
    //   }
    // });

    const length = Object.values(hits).length;
    Object.values(hits).forEach((newTarget) => {
      const isHit =
        length > 1
          ? isMouseEventOverElement(
              $event,
              newTarget,
              dragProps.scrollableParent,
            )
          : true;
      if (isHit) {
        const id = newTarget.entity.vnode.props.id;
        if (!(id in this.draggable.hits)) {
          this.draggable.hits[id] = newTarget;
          newTarget.entity.onDragEnter($event, this.draggable.draggable);
        }
      } else {
        if (newTarget.entity.vnode.props.id in this.draggable.hits) {
          newTarget.entity.onDragLeave($event, this.draggable.draggable);
        }
      }
    });

    Object.values(this.draggable.hits).forEach((oldTarget) => {
      const id = oldTarget.entity.vnode.props.id;
      if (!(id in hits)) {
        this.draggable.hits[id] = null;
        delete this.draggable.hits[id];
        oldTarget.entity.onDragLeave($event, this.draggable.draggable);
      }
    });
    return;
  }

  private clearDraggable() {
    if (!this.draggable) return;
    gsap.set(this.el, { clearProps: 'transform' });
  }

  private resetDraggable() {
    if (!this.draggable) return;
    this.draggable.dragEnded = false;
    this.draggable.isDragged = false;
  }

  // Droppable

  private patchDroppable(newNode: VNode) {
    const oldProps = this.vnode?.props?.droppable;
    const newProps = newNode.props.droppable;

    if (oldProps && newProps && !shallowEqual(oldProps, newProps)) {
      patchClasses(
        this.el,
        oldProps.droppableIdentifier || 'droppable',
        newProps.droppableIdentifier || 'droppable',
      );
    }
    if (newProps && !oldProps) {
      addClasses(this.el, newProps.droppableIdentifier);
    }
    if (oldProps && !newProps) {
      removeClasses(this.el, oldProps.droppableIdentifier);
    }
  }

  private onDragEnter(event: MouseEvent | PointerEvent, draggable: Draggable) {
    this.logger.debug('onDragenter', event, draggable, this.engine);
    const dropProps = this.vnode?.props?.droppable;
    if (!dropProps || !this.checkDropCondition(dropProps, draggable)) {
      return;
    }

    addClasses(this.el, this.vnode.props.droppable.droppableClasses);
    if (dropProps.onDragEnter) {
      this.emitDragDropEvent('dragEnter', dropProps.onDragEnter, event);
    }
  }

  private checkDropCondition(dropProps: ModuleDroppable, draggable: Draggable) {
    if (!this.engine.dragged) {
      return false;
    }
    return Boolean(dropProps.condition
      ? evaluate(
          {
            environment: {
              dragged: this.engine.dragged.props,
              target: this.vnode.props,
              ...this.vnode.props.context,
            },
            data: this.vnode.props,
            variables: this.vnode.props.context.$vars,
            props: this.vnode.props.context.$props,
            computeds: this.vnode.props.context.$computeds,
            vm: {},
            local: {},
          } as EvaluationContext,
          ':' + dropProps.condition,
          ':',
        )
      : true);
  }

  private onDragLeave(event: MouseEvent | PointerEvent, draggable: Draggable) {
    this.logger.debug('onDragleave', event, draggable);
    const dropProps = this.vnode?.props?.droppable;
    if (!dropProps?.condition) return;
    removeClasses(this.el, this.vnode.props.droppable.droppableClasses);
    if (dropProps.onDragLeave) {
      this.emitDragDropEvent('dragLeave', dropProps.onDragLeave, event);
    }
  }

  private onDrop(event: MouseEvent | PointerEvent, draggable: Draggable) {
    const dropProps = this.vnode?.props?.droppable;
    this.logger.debug(
      'Handle dropped',
      event,
      this.el,
      draggable,
      dropProps,
      dropProps.onDrop,
    );
    if (!dropProps || !this.checkDropCondition(dropProps, draggable)) {
      return;
    }

    this.engine.dragged.entity.draggable.droppedOn = this.id;

    // TODO: Calculate correct target position
    const targetPosition = {
      x: -((event.target as HTMLElement).offsetWidth / 2) + event.offsetX,
      y: -(-((event.target as HTMLElement).offsetHeight / 2) + event.offsetY),
      z: 0,
    };
    removeClasses(this.el, dropProps.droppableClasses);
    addClasses(this.el, dropProps.droppedClasses);
    setTimeout(() => removeClasses(this.el, dropProps.droppedClasses), 1000);

    if (dropProps.onDrop) {
      this.emitDragDropEvent('dropped', dropProps.onDrop, event, {
        targetPosition,
      });
    }
  }

  private emitDragDropEvent(
    name: string,
    script: string,
    event: any,
    context: Record<string, any> = {},
  ) {
    this.engine.emit('event', {
      name,
      event,
      script,
      context: {
        dragged: this.engine.dragged.props,
        target: this.vnode.props,
        ...context,
        ...this.vnode.props.context,
        ...this.vnode.props.context.$vars,
        ...this.vnode.props.context.$props,
        ...this.vnode.props.context.$computeds,
      },
      entity: this,
    });
  }
}
