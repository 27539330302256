var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"h-full p-1",staticStyle:{"z-index":"7"},style:({
    backgroundColor: _vm.themeManager.mainDrawerBarBackground,
    'border-right': _vm.themeManager.mainDrawerBorder
      ? '1px solid rgba(0, 0, 0, 0.1)'
      : 'none',
    'border-bottom': _vm.themeManager.appBarShadow
      ? 'none'
      : '1px solid rgba(0, 0, 0, 0.1)',
  })},[_c('div',{staticClass:"h-full flex flex-col mt-1 items-center"},_vm._l((_vm.actionAreas),function(area,index){return _c('div',{key:index,class:area.classes},[_vm._l((area.actions),function(action,actionIndex){return [(!('condition' in action) || action.condition)?_c('BaseButton',{key:actionIndex,attrs:{"icon":action.icon,"title":action.title || action.name,"color":_vm.activeRoute == action.name ? action.color : '',"active":_vm.activeRoute == action.name,"size":"icon","class-prop":"my-1","outlined":action.outlined},on:{"click":function($event){action.script ? _vm.execute(action, $event) : action.action()}}},[_vm._v(" "+_vm._s(action.text)+" ")]):_vm._e()]})],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }