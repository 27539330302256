import { FrontendExpression, ModuleTypeMeta } from '../../common';

export interface ComputedDefinition {
  key: string;
  expression: FrontendExpression;
  evaluate?: boolean;
}

export interface ModuleComputeds {
  scoped: ComputedDefinition[];
}

export const ModuleComputedsMeta: ModuleTypeMeta<ModuleComputeds> = {
  default: {
    scoped: [
      { key: 'settings', expression: `:({\n  x: ''\n })`, evaluate: false },
      {
        key: 'variants',
        expression: `:({\n  default: '',\n  hovered: ''\n})`,
        evaluate: false,
      },
      {
        key: 'currentVariant',
        expression: `:$variants[$variant || 'default']`,
        evaluate: false,
      },
      {
        key: 'l10n',
        expression: `:({ \n  title: ''\n })`,
        evaluate: false,
      },
    ],
  },
  childDefault: {
    key: 'computed',
    expression: ':',
    evaluate: false,
  },
};
