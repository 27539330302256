import {
  TypeName,
  TypeString,
  TypeStringArray,
  TypeNumber,
  TypeBoolean,
  TypeRoute,
  TypeId,
  TypeDocuments,
  TypeColor,
  TypeScript,
  TypesDictionary,
  TypeDatetime,
  TypeEmbed,
  TypeImage,
  TypeVideo,
  TypeHtml,
  TypeVector3,
  TypeSelect,
  TypeStyledText,
  BuiltinTypesMeta,
  TypeParticipants,
  TypeIcon,
  TypeVideo360,
  TypeAudio,
  TypePositionalAudio,
} from './types';
import clone from 'rfdc/default';

export type TypeAny =
  | { type: 'string'; value: TypeString }
  | { type: 'number'; value: TypeNumber }
  | { type: 'boolean'; value: TypeBoolean }
  | { type: 'id'; value: TypeId }
  | { type: 'documents'; value: TypeDocuments }
  | { type: 'participants'; value: TypeParticipants }
  | { type: 'color'; value: TypeColor }
  | { type: 'script'; value: TypeScript }
  | { type: 'route'; value: TypeRoute }
  | { type: 'datetime'; value: TypeDatetime }
  | { type: 'embed'; value: TypeEmbed }
  | { type: 'image'; value: TypeImage }
  | { type: 'video'; value: TypeVideo }
  | { type: 'audio'; value: TypeAudio }
  | { type: 'positionalAudio'; value: TypePositionalAudio }
  | { type: 'video360'; value: TypeVideo360 }
  | { type: 'html'; value: TypeHtml }
  | { type: 'vector3'; value: TypeVector3 }
  | { type: 'select'; value: TypeSelect }
  | { type: 'styledText'; value: TypeStyledText }
  | { type: 'icon'; value: TypeIcon }
  | { type: 'stringArray'; value: TypeStringArray };

export type AnyType<T extends TypeName> = {
  type: T;
  value: TypesDictionary[TypeName];
};

export type TypeAnyValue = TypeAny['value'];
export type TypeAnyType = TypeAny['type'];

export const toAny = <T extends TypeName>(
  type: T,
  value: TypesDictionary[T] = BuiltinTypesMeta[type]?.default,
) => {
  if (!BuiltinTypesMeta[type]) {
    throw new Error('Unknown type');
  }

  return {
    type,
    value: clone(value),
  };
};
