<template>
  <BaseCard class="p-4" size="large" :title="$t('user.changeEmail')">
    <div v-if="pendingEmail" class="px-8">
      <p>{{ $t('confirmation.confirmationPending') }}</p>
      <BaseButton rounded @click="gotoConfirmation"
        >{{ $t('confirmation.confirm') }}
      </BaseButton>
    </div>
    <BaseForm ref="formRef" v-model="isValid" @submit.prevent="submit">
      <EmailInput
        v-model="formData.pendingEmail"
        :label="$t('user.newEmail')"
        :disabled="pendingEmail"
        autocomplete="username"
        @focus="edit"
      />
      <PasswordInput
        v-if="!pendingEmail"
        v-model="formData.password"
        @focus="edit"
      />
      <v-card-actions>
        <v-expand-transition>
          <SaveAndCancelButtons
            v-if="isEditing"
            justify="center"
            rounded
            :is-valid="isValid"
            @cancel="cancel"
          />
        </v-expand-transition>
      </v-card-actions>
      <div v-if="pendingEmail">
        <v-row align="center">
          <v-col cols="12" md="8" offset-md="2" class="flex justify-center">
            {{ $t('logout.revertDecision') }}
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="8" offset-md="2" class="flex justify-center">
            <BaseButton rounded @click="abortChangeEmail"
              >{{ $t('user.abortChangeEmail') }}
            </BaseButton>
          </v-col>
        </v-row>
      </div>
    </BaseForm>
  </BaseCard>
</template>

<script>
import SaveAndCancelButtons from '@/components/form-inputs/SaveAndCancelButtons.vue';
import PasswordInput from '@/components/form-inputs/PasswordInput.vue';
import EmailInput from '@/components/form-inputs/EmailInput.vue';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import {
  useFillip,
  useI18n,
  useMessage,
  useRouter,
  useLayoutArea,
} from '@/composables';

export default defineComponent({
  name: 'EmailForm',
  components: {
    SaveAndCancelButtons,
    PasswordInput,
    EmailInput,
  },
  props: {
    inFocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { fillip, $user } = useFillip();
    const { tr } = useI18n();
    const message = useMessage();
    const { $router } = useRouter();
    const { close: closeDialog } = useLayoutArea('dialog');

    const isValid = ref(false);
    const isEditing = ref(false);
    const formData = ref({
      pendingEmail: '',
      password: '',
    });
    const formRef = ref(null);
    const pendingEmail = computed(() =>
      Boolean(fillip.state.user.pendingEmail),
    );

    const edit = () => {
      isEditing.value = true;
      emit('focused');
    };

    const cancel = () => {
      isEditing.value = false;
      formData.value = {
        pendingEmail: $user.value.pendingEmail || $user.value.email,
        password: '',
      };
      isEditing.value = false;
      formRef.value.resetValidation();
    };
    const submit = async () => {
      if (!formRef.value.validate()) {
        return;
      }

      await fillip.auth.updateEmail(formData.value);
      isEditing.value = false;
      message.success('user.emailChanged');
      closeDialog();
      $router.push({ name: 'ConfirmationPending' });
    };

    const abortChangeEmail = async () => {
      await fillip.auth.cancelRequest();
      message.info(tr('user.emailChangeCancelled'));
    };

    const gotoConfirmation = () => {
      closeDialog();
      $router.push({ name: 'ConfirmationPending' });
    };

    watch(
      () => $user.value.pendingEmail,
      (newValue) => {
        formData.value.pendingEmail = newValue || $user.value.email;
      },
      { immediate: true },
    );

    watch(
      () => props.inFocus,
      (newValue) => {
        if (!newValue && isEditing.value) {
          cancel();
        }
      },
    );

    return {
      isValid,
      isEditing,
      formData,
      formRef,
      pendingEmail,
      edit,
      cancel,
      submit,
      abortChangeEmail,
      gotoConfirmation,
    };
  },
});
</script>
