


















import Vue from 'vue';
import InputCode from '@/components/input/code/InputCode.vue';

export default Vue.extend({
  name: 'InputScript',
  components: {
    InputCode,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      default: 'js',
    },
    height: {
      type: String,
      default: 'auto',
    },
    label: {
      type: String,
      default: '',
    },
  },
});
