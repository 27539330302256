import { render, staticRenderFns } from "./ColorfulSkyEditor.vue?vue&type=template&id=2431617b&"
import script from "./ColorfulSkyEditor.vue?vue&type=script&lang=ts&"
export * from "./ColorfulSkyEditor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VSubheader,VSwitch})
