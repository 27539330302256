import { computed } from '@vue/composition-api';
import { Access, ActionControl, Community, Role, Status } from '@fillip/api';
import { useI18n } from '../i18n/useI18n';
import { useShell } from './../globals/useShell';
import { useFillip } from '../globals/useFillip';
import { useRouter } from '../globals/useRouter';
import { useNav } from '../globals/useNav';
import { useMessage } from '../globals/useMessage';

export function useCommunities() {
  const { tr } = useI18n();
  const { $user, fillip } = useFillip();
  const { $dialog } = useNav();
  const { router, $route } = useRouter();
  const message = useMessage();

  const { shell, toggleLoading } = useShell();
  const sortByDate = (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt);

  const canUserCreateCommunity = computed(() => {
    if (!$user.value) return false;
    return ['host', 'admin'].includes($user.value.role);
  });
  const isRestrictedUser = computed(() => $user.value.role === Role.RESTRICTED);

  const isCommunityAccessible = (community) => {
    if (isRestrictedUser && shell.value.restrictCommunities)
      return shell.value.restrictCommunities.includes(community.slug);

    return true;
  };
  const isCommunityActive = (community) => community.status === Status.ACTIVE;

  const isCommunityPublic = (community) => community.access === Access.PUBLIC;

  const isCommunityCreatedByUser = (community) =>
    community.createdById === $user.value.id;

  const isCommunityInCurrentShell = (community) =>
    community.shell === shell.value.name;

  const hasUserJoinedCommunity = (community) =>
    $user.value.profiles
      .map((p) => p.community.id)
      .some((id) => id === community.id);

  const getAllCommunities = async () => {
    return fillip.communities.getAll();
  };

  const publicCommunities = computed<Community[]>(() => {
    return fillip.state.allCommunities
      .filter(
        (community) =>
          isCommunityAccessible(community) &&
          isCommunityActive(community) &&
          isCommunityPublic(community) &&
          isCommunityInCurrentShell(community) &&
          !isCommunityCreatedByUser(community) &&
          !hasUserJoinedCommunity(community),
      )
      .sort(sortByDate);
  });

  const communitiesWithProfile = computed<number[]>(() => {
    if (!$user.value.profiles?.length) return [];
    return $user.value.profiles.map((p) => p.community?.id).filter(Boolean);
  });

  const profileCommunities = computed<Community[]>(() => {
    return fillip.state.allCommunities.filter((community: Community) =>
      communitiesWithProfile.value.includes(community.id),
    );
  });

  const createdCommunities = computed(() => {
    if (!profileCommunities.value?.length) return [];
    return profileCommunities.value
      .filter((community) => !!community && isCommunityCreatedByUser(community))
      .sort(sortByDate);
  });

  const joinedCommunities = computed(() => {
    if (!profileCommunities.value?.length) return [];

    return profileCommunities.value
      .filter(
        (community) =>
          !!community &&
          !isCommunityCreatedByUser(community) &&
          isCommunityActive(community),
      )
      .sort(sortByDate);
  });

  const disconnectAllCommunities = async () => {
    await fillip.communities.disconnectAll();
  };

  const enterCommunity = (community: Community | string) => {
    if (!community) return;
    toggleLoading(true);
    const slug =
      typeof community === 'string' ? community.toLowerCase() : community.slug;
    if (!slug) return;
    return router
      .push({
        name: 'Community',
        params: {
          slug: slug,
        },
      })
      .finally(() => toggleLoading(false));
  };

  const editCommunity = (community: Community) => {
    if (!community) return;

    return $dialog.push({
      name: 'CommunityEditor',
      query: { slug: community.slug },
    });
  };

  const gotoAddCommunity = () => {
    return router.push({
      name: 'AddCommunity',
      params: {
        slug: $route.params.slug,
      },
    });
  };

  const deleteCommunity = async (community: Community) => {
    if (!isCommunityCreatedByUser(community)) {
      message.error(
        tr('communities.communityDeleted', {
          title: community.title,
        }),
      );
      return;
    }

    await fillip.communities.deleteCommunity(community.slug);
    message.success(
      tr('communities.communityDeleted', {
        title: community.title,
      }),
    );
  };

  const communityActions = computed(() => {
    const actions: ActionControl[] = [
      {
        type: 'action',
        icon: 'pen',
        title: tr('communities.editCommunity'),
        action: (community: any) => editCommunity(community),
        if: (community) => isCommunityCreatedByUser(community),
      },
      {
        type: 'action',
        icon: 'trash',
        title: tr('communities.delete'),
        action: (community) => deleteCommunity(community),
        color: 'error',
        if: (community) => isCommunityCreatedByUser(community),
      },
    ];
    return actions;
  });

  return {
    isCommunityCreatedByUser,
    disconnectAllCommunities,
    deleteCommunity,
    enterCommunity,
    editCommunity,
    gotoAddCommunity,
    getAllCommunities,

    publicCommunities,
    createdCommunities,
    joinedCommunities,
    canUserCreateCommunity,
    communityActions,
  };
}
