import { ModuleTypeMeta } from '../../common';

export interface ModuleDroppable {
  condition: string;
  droppableIdentifier: string;
  droppableClasses: string;
  droppedClasses: string;
  onDrop: string;
  onDragEnter: string;
  onDragLeave: string;
}

export const ModuleDroppableMeta: ModuleTypeMeta<ModuleDroppable> = {
  default: {
    condition: ':true',
    onDrop: 'notify(`Dropped element ${dragged.id}`)',
    onDragEnter: '',
    onDragLeave: '',
    droppableClasses: 'bg-gray-300',
    droppedClasses: 'bg-gray-300',
    droppableIdentifier: 'droppable',
  },
  icon: 'bullseye-arrow',
};
