import { render, staticRenderFns } from "./BaseEditorText.vue?vue&type=template&id=2e057c2a&scoped=true&"
import script from "./BaseEditorText.vue?vue&type=script&lang=ts&"
export * from "./BaseEditorText.vue?vue&type=script&lang=ts&"
import style0 from "./BaseEditorText.vue?vue&type=style&index=0&id=2e057c2a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e057c2a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VDivider,VList,VListItem,VListItemGroup,VListItemTitle,VMenu,VSheet,VTextarea})
