










































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleComputedsMeta } from '@fillip/api';
import clone from 'rfdc/default';

export default Vue.extend({
  name: 'ComputedsEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'computeds',
    });

    const init = () => {
      buffer.value = clone(ModuleComputedsMeta.default);
      save();
    };

    const defaultItem = ModuleComputedsMeta.childDefault;

    const addItem = () => {
      buffer.value.scoped.push(clone(defaultItem));
      save();
    };
    const removeItem = (index) => {
      buffer.value.scoped.splice(index, 1);
      save();
    };

    const toggleEvaluate = (index: number) => {
      const computedDefinition = buffer.value.scoped[index];
      if (!computedDefinition) return;
      buffer.value.scoped[index].evaluate = computedDefinition.evaluate
        ? null
        : true;
      save();
    };

    return {
      buffer,
      save,
      toggleEvaluate,
      init,
      addItem,
      removeItem,
    };
  },
});
