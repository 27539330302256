



























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ListLayout',
  props: {
    currentChild: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const itemSelected = (item) => {
      emit('input', item);
    };

    return {
      itemSelected,
    };
  },
});
