import { ComponentConfig, ComponentInstance } from '../../../core/types';
import { DefaultSizeConstraints } from './../size';

import * as Location3D from '@/utils/location3D';

export const defaultArranger =
  (vm: ComponentInstance) =>
  (input: ComponentConfig[], slot: string = '') => {
    return input.map(() => ({
      location: Location3D.origin,
      sizeConstraints: vm.sizeConstraints || DefaultSizeConstraints,
    }));
  };
