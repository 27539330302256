

































import {
  useRouter,
  useShell,
  useFillip,
  useI18n,
  useMessage,
} from '@/composables';
import { computed, defineComponent, ref, unref } from '@vue/composition-api';
import UsernameInput from '@/components/form-inputs/UsernameInput.vue';
import { RegisterAnonymousFrontendRequest, sleep } from '@fillip/api';

export default defineComponent({
  name: 'AuthMethodAnonymous',
  components: {
    UsernameInput,
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    isPrimary: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { shell, toggleLoading } = useShell();
    const { fillip } = useFillip();
    const { tr } = useI18n();
    const message = useMessage();
    const { gotoTargetOrCommunities, extractCommunitySlugFromTarget } =
      useRouter();

    const displayName = ref('');

    const displayedText = computed(
      () =>
        props.buttonText ||
        shell.value.content.anonymousLoginAction ||
        tr('login.loginAnonymously'),
    );

    const triggerLogin = async () => {
      toggleLoading(true);

      // If anonymous users in a shell that restricts communites login from a community,
      // they should be restricted to that community
      const targetCommunity = extractCommunitySlugFromTarget();

      const restrictCommunities = shell.value.restrictCommunities
        ? targetCommunity
          ? [targetCommunity]
          : shell.value.restrictCommunities
        : undefined;

      const userData: RegisterAnonymousFrontendRequest = {
        username: unref(displayName) || tr('user.anonymous'),
        domain: window.location.hostname,
        shell: shell.value.name,
        restrictCommunities,
      };
      try {
        await fillip.auth.registerAnonymous(userData);
        await gotoTargetOrCommunities();
      } catch (e) {
        message.error(e.messageKey);
        console.error(e);
        toggleLoading(false);
      }
    };
    return {
      triggerLogin,
      shell,
      displayName,
      displayedText,
    };
  },
});
