import { DataDocument, PresetDefinition } from '@fillip/api';

export const DefaultTemplate: DataDocument = {
  id: 'DefaultTemplate',
  info: {
    title: 'New Template',
  },
  tag: {
    tag: 'template',
  },
};

export const PresetDefaultTemplate: PresetDefinition = {
  id: 'PresetDefaultTemplate',
  title: 'Empty Template',
  icon: 'album',
  group: 'templates',
  version: '0.1',
  documents: [DefaultTemplate],
};
