






import { rootTemplateId, rootDataId } from '@fillip/api';
import ObjectEditor from './ObjectEditor.vue';
import { usePermissions, useVolatileData } from '@/composables';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'Editor',
  components: {
    ObjectEditor,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { getVolatileProp } = useVolatileData();
    const { canEditCommunity } = usePermissions();

    const selection = computed(() => {
      return (
        props.id ||
        getVolatileProp(rootDataId, 'selection', null) ||
        rootTemplateId
      );
    });
    return {
      getVolatileProp,
      selection,
      canEditCommunity,
    };
  },
});
