




import Vue from 'vue';

import { useClipboard } from '@vueuse/core';

export default Vue.extend({
  name: 'DocumentId',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const systemClipboard = useClipboard();

    return {
      systemClipboard,
    };
  },
  methods: {
    async copyId() {
      await this.systemClipboard.copy(this.id);
      this.$store.dispatch('notify', {
        type: 'success',
        text: this.$t('info.idCopiedToClipboard') + ': ' + this.id,
      });
    },
  },
});
