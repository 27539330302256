



























import Vue from 'vue';
import { useSave, usePermissions } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleParticipantAccountMeta } from '@fillip/api';

export default Vue.extend({
  name: 'ParticipantAccountEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { canEditRoles } = usePermissions();
    const { buffer, save } = useSave(props, {
      path: 'participantAccount',
    });
    return {
      buffer,
      save,
      canEditRoles,
    };
  },
  computed: {
    roleItems() {
      return Object.keys(
        ModuleParticipantAccountMeta.schema.communityRoles,
      ).map((value) => ({
        value,
        text: this.$t(`participantAccount.${value}`),
      }));
    },
  },
});
