






import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseTransition',
  props: {
    name: {
      type: String,
      default: 'fade',
    },
  },
});
