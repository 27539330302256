import {
  ModuleVideoConferencingMeta,
  useLogger,
  LogLevels,
  useTrackPerformance,
  useStateBus,
  StateBusUpdate,
} from '@fillip/api';
import { Scene, PerspectiveCamera, AxesHelper } from 'three';
import {
  useData,
  useTags,
  useVolatileData,
  useEnvironment,
  useParticipants,
  useScene,
} from '@/composables';
import { Engine } from '../engine';
import { renderScene, RenderContext } from './render';
import clone from 'rfdc/default';
import Vue from 'vue';
import { throttle } from 'lodash';
import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store';

const logger = useLogger(LogLevels.INFO, LogLevels.NONE, 'scene');
const trackPerformance = useTrackPerformance(false, 'scene');

export default Vue.extend({
  inject: ['canvas', 'router', 'viewport', 'componentFactory', 'actions'],
  provide() {
    return {
      engine: () => this.engine,
    };
  },
  setup() {
    const { environment } = useEnvironment();
    const { getData } = useData();
    const { tagDefinitions } = useTags();
    const { getParticipant } = useParticipants();

    const { getVolatile, getVolatileProp } = useVolatileData();

    const { globalDisplayMode } = useScene();

    const participant = computed(() => getParticipant(store.getters.me.id));

    const stateBus = useStateBus('scene');

    const hasState = computed(() =>
      Boolean(
        store.getters.communityState && store.getters.communityState.data,
      ),
    );

    const stateChanged = ref(false);
    const immediateRerender = ref(false);
    const requestedInterval = ref(0);
    const timeout = ref(null);
    const requestInterval = (newInterval: number) => {
      const oldInterval = requestedInterval.value;
      if (newInterval === 0 && timeout.value) {
        clearTimeout(timeout.value);
        return;
      }

      if (!oldInterval || (oldInterval > 0 && newInterval < oldInterval)) {
        if (timeout.value) {
          clearTimeout(timeout.value);
        }
        timeout.value = setTimeout(
          () => (stateChanged.value = true),
          newInterval,
        );
        return;
      }
    };

    const onStateUpdate = (event: string, data: StateBusUpdate) => {
      // console.log(`Update requested: ${event}`, data);
      if (event === 'requestUpdate') {
        stateChanged.value = true;
      } else if (event === 'requestInterval') {
        requestInterval(data.options.interval);
      } else if (event === 'immediateUpdate') {
        immediateRerender.value = true;
      } else {
        console.warn(`stateBus received unknown event ${event}`);
      }
    };

    const _unsubscribeStateUpdates = stateBus.on(onStateUpdate);

    const getCurrentTags = () =>
      tagDefinitions.value.reduce((acc, tag) => {
        acc[tag.tag] = tag.id;
        return acc;
      }, {});

    return {
      environment,
      getData,
      getCurrentTags,
      getParticipant,
      getVolatile,
      getVolatileProp,
      participant,
      hasState,
      stateChanged,
      immediateRerender,
      globalDisplayMode,
    };
  },
  data() {
    return {
      shouldImmediatelyRerender: false,
      isInRender: false,
      scene: null,
      lastRender: 0,
    };
  },
  computed: {
    appIsVisible() {
      return this.canvas.value.renderManager.manualTickInterval === null;
    },
  },
  watch: {
    stateChanged: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          // await this.doRender();
          this.throttledRender();
          this.stateChanged = false;
        }
      },
    },
    immediateRerender: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          await this.doRender();
          this.immediateRerender = false;
        }
      },
    },
    viewport: {
      immediate: true,
      deep: true,
      handler(newSize) {
        logger.debug('Viewport changed');
        if (!newSize || !this.engine) return;
        (this.engine as Engine).setViewportSize({ ...newSize });
        this.throttledRender();
      },
    },
    globalDisplayMode: {
      immediate: true,
      handler(newMode, oldMode) {
        if (newMode === oldMode) return;
        if (newMode === 'print') {
          document.documentElement.classList.add('print-pdf');
        } else {
          document.documentElement.classList.remove('print-pdf');
        }

        // this.throttledRender();
      },
    },
  },
  created() {
    const scene = this.canvas.value.$object3D as Scene;
    const renderManager = this.canvas.value.renderManager;
    const render = () => {
      this.canvas.value.render();
    };
    this.throttledRender = throttle(
      async () => {
        // console.log('Debounced Render');
        await this.doRender();
      },
      200,
      {
        leading: true,
        trailing: true,
      },
    );
    // this.interval = setInterval(this.renderInInterval, 5000);
    const viewport = { ...this.viewport };
    const factory = this.componentFactory.value;
    const cameraElement = renderManager.getCameraHTMLElement();
    const canvasElement = this.canvas.value.getCanvasDomElement();
    const engine = new Engine({
      scene,
      render,
      renderManager,
      viewport,
      factory,
      cameraElement,
      globalPropsPlugin: this.$globalProps,
      canvasElement,
    });

    this.engine = engine;

    this.engine.on('event', ({ name, event, script, context, entity }) => {
      this.actions.value.execute(script, {
        event,
        ...context,
        ...entity.vnode.props,
        self: entity.vnode.props,
      });
    });

    this.cameraProxy = {
      get: (): PerspectiveCamera => engine.getPerspectiveCamera(),
    };

    renderManager.onRender('engine', () => {
      trackPerformance.start('engineTick');
      engine.tick();
      trackPerformance.stop('engineTick');
    });

    // TODO: Add toggle to dynamically activate/deactivate
    // const axesHelper = new AxesHelper();
    // scene.add(axesHelper);

    // axesHelper.scale.set(100, 100, 100);
    this.throttledRender();
  },
  async beforeDestroy() {
    this.canvas.value.renderManager.removeOnRender('engine');
    clearInterval(this.interval);
    this.$emit('mounted', null);
    await (this.engine as Engine).shutdown();
  },
  mounted() {
    this.$emit('mounted', this.cameraProxy);
  },
  methods: {
    renderInInterval() {
      if (!this.isInRender && Date.now() - this.lastRender > 1000) {
        console.log('renderInInterval');
        // setTimeout(this.doRender, 0);
        this.throttledRender();
      }
    },
    async doRender() {
      if (!this.hasState) return;
      logger.debug('doRender');
      trackPerformance.start('doRender');
      if (this.isInRender) {
        this.shouldImmediatelyRerender = true;
        logger.debug('shouldImmediatelyRerender');
        trackPerformance.stop('doRender');
        return;
      }
      this.isInRender = true;

      const context: RenderContext = {
        vueInstance: this,
        route: clone(this.router.value.route),
        out: {
          globalProps: {
            videoConferencing: clone(ModuleVideoConferencingMeta.default),
          },
          focusedVNode: null,
        },
        env: {
          computedTemplates: this.getCurrentTags(),
          pathSegment: 0,
          isWithinFocus: false,
          hasFocusWithin: false,
          isActiveScene: false,
          isFocused: false,
          $station: '',
          $route: clone(this.router.value.route),
          $me: clone(this.participant),
          $id: '',
          templateId: '',
          $viewport: { ...this.viewport },
          $props: {},
          $computeds: {},
          $vars: {},
          $roles: {},
          $displayMode: this.globalDisplayMode,
        },
      };

      trackPerformance.start('renderScene');
      const { vnode, globalProps } = renderScene(context);
      trackPerformance.stop('renderScene');
      trackPerformance.start('patchEngine');
      await (this.engine as Engine).patch(
        {
          globalProps,
          vnode,
        },
        this.appIsVisible,
        this.globalDisplayMode,
      );
      trackPerformance.stop('patchEngine');
      trackPerformance.stop('doRender');
      trackPerformance.log();
      trackPerformance.reset();

      this.isInRender = false;
      this.lastRender = Date.now();
      if (this.shouldImmediatelyRerender) {
        this.shouldImmediatelyRerender = false;
        this.throttledRender();
      }
    },
  },
  render(h) {
    return null;
  },
});
