













import { defineComponent, computed } from '@vue/composition-api';
import { useData, useFillip, useStore } from '@/composables';

import MediasoupAudioConsumer from '@/features/video-conferencing/MediasoupAudioConsumer.vue';

export default defineComponent({
  name: 'GlobalAudioMixer',
  components: {
    MediasoupAudioConsumer,
  },
  setup() {
    const { getData } = useData();
    const { store } = useStore();
    const { me } = useFillip();

    const muted = computed(() => store.state.videoConference.audioMuted);
    const listeners = computed<Record<string, number>>(
      () => store.state.videoConference.listeners,
    );

    const requestedProducers = computed(() => {
      return Object.entries(listeners.value)
        .filter(([key, value]) => value > 0)
        .map(([key, value]) => key)
        .map((participantId) => getData(participantId))
        .filter(
          (participant) =>
            participant.streams?.microphone?.producerId &&
            participant.id != me.value.id,
        )
        .map((participant) => participant.streams.microphone);
    });

    return {
      getData,
      muted,
      listeners,
      requestedProducers,
    };
  },
});
