export interface SizeConstraints {
  maxWidth: number;
  maxHeight: number;
  maxDepth: number;
  minWidth: number;
  minHeight: number;
  minDepth: number;
}

export interface Size2D {
  width: number;
  height: number;
}

export interface Size3D {
  width: number;
  height: number;
  depth: number;
}

export const DefaultSizeConstraints: SizeConstraints = {
  maxWidth: Infinity,
  maxHeight: Infinity,
  maxDepth: Infinity,
  minWidth: 0,
  minHeight: 0,
  minDepth: 0,
};
