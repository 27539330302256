









import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';

import DOMPurify from 'dompurify';
import InputCode from '@/components/input/code/InputCode.vue';

export default Vue.extend({
  name: 'MarkdownEditor',
  components: {
    InputCode,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'element',
    });

    return {
      buffer,
      save,
    };
  },
  beforeDestroy() {
    this.$emit('deactivate', this.buffer);
  },
  methods: {
    sanitize() {
      this.buffer.markdownContent = DOMPurify.sanitize(
        this.buffer.markdownContent,
      );
      this.save();
    },
  },
});
