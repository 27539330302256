













































import { useDataClipboard, useData, useTags } from '@/composables';

import { useClipboard } from '@vueuse/core';

import InputJson from '@/components/input/json/InputJson.vue';
import { rootDataId } from '@fillip/api';
import { useVolatileData } from '@/composables';

export default {
  name: 'DataClipboard',
  components: {
    InputJson,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  setup() {
    const dataClipboard = useDataClipboard();
    const systemClipboard = useClipboard();
    const { setVolatileProp } = useVolatileData();
    const { getData, importFlatDataTree } = useData();
    const { getDataIcon } = useTags();

    return {
      dataClipboard,
      systemClipboard,
      getData,
      importFlatDataTree,
      getDataIcon,
      setVolatileProp,
    };
  },
  data() {
    return {
      jsonIsValid: false,
    };
  },
  computed: {
    currentTarget() {
      const id = this.dataClipboard.targetId.value;
      if (!id) return null;
      const document = this.getData(id);
      if (!document) return null;
      return {
        id: id,
        title: document.info.title,
        icon: this.getDataIcon(document),
      };
    },
    clipboardContent() {
      return this.dataClipboard.current.value;
    },
    clipboardOptions() {
      return this.dataClipboard.options?.value || {};
    },
    keepIds() {
      return Boolean(this.dataClipboard.options?.value?.keepIds);
    },
  },
  methods: {
    close() {
      this.dataClipboard.clear();
      this.navigate('/');
    },
    async copyToClipboard() {
      await this.systemClipboard.copy(this.dataClipboard.toJSON());
      this.$store.dispatch('notify', {
        type: 'success',
        text: this.$t('templateClipboard.copiedToClipboard'),
      });
    },
    jsonChanged(newValue) {
      // Validation is handled by JSON editor,
      // so we can be sure the JSON is valid here
      this.jsonIsValid = true;
      this.dataClipboard.copy(newValue);
    },
    async importFromDataClipboard() {
      try {
        const result = await this.importFlatDataTree(
          this.currentTarget.id,
          this.clipboardContent,
          this.clipboardOptions,
        );
        if (result && result.result) {
          this.$store.dispatch('notify', {
            type: 'success',
            text: this.$t('templateClipboard.importSuccessful'),
          });
          this.setVolatileProp(
            rootDataId,
            'selection',
            result.result.result[0].id,
          );
          this.close();
        } else {
          this.$store.dispatch('notify', {
            type: 'error',
            text: 'Import failed',
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    keepIdsChanged(newValue) {
      this.dataClipboard.setOptions({ keepIds: newValue });
    },
  },
};
