



























































































import Vue from 'vue';
import { BaseModuleEditor } from '../base';
import { ModuleSceneDefinitionsMeta } from '@fillip/api';
import { GlobalPropsNode } from '@/plugins/global-props';
import clone from 'rfdc/default';
import { useSave } from '@/composables';
import PrimitiveDataSelector from '@/components/primitive-editor/types/PrimitiveDataSelector.vue';

export default Vue.extend({
  name: 'SceneDefinitionsEditor',
  components: { PrimitiveDataSelector },
  mixins: [BaseModuleEditor, GlobalPropsNode],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'sceneDefinitions',
    });
    return {
      buffer,
      save,
    };
  },
  methods: {
    addSceneDefinition() {
      this.buffer.sceneDefinitions.push(
        clone(ModuleSceneDefinitionsMeta.childDefault),
      );
      this.save();
    },
    removeSceneDefinition(index) {
      this.buffer.sceneDefinitions.splice(index, 1);
      this.save();
    },
  },
});
