export default {
  editor: {
    clear: 'Zurücksetzen',
    min: 'Min',
    max: 'Max',
    condition: 'Bedingung hinzufügen',
    conditional: 'Bedingt:',
    save: 'Speichern',
    if: 'if',
    elseIf: 'else if ',
    else: 'else',
    dark: 'Dunkel',
  },
  actions: {
    title: 'Aktionen',
  },
  action: {
    actionName: 'Aktionsname',
    id: 'ID',
    add: 'Hinzufügen',
    script: 'Skript',
    document: 'Globales Dokument',
    localDoc: 'Lokales Dokument',
    minimizeIcon: 'Benutzerdefiniertes Icon',
    defaultActionText: 'Was denken Sie?',
    placeholder: 'Platzhalter',
    restricions: 'Beschränkungen',
    showIfLocation: 'Anzeigen, wenn Standort',
    showForRoles: 'Für Rollen anzeigen',
    condition: 'Bedingung',
    multiple: 'Mehrfachauswahl erlauben',
    maxOptions: 'Optionen beschränken auf',
    title: 'Titel der Frage',
    items: 'Antwortmöglichkeiten',
    targetId: 'Ziel',
    template: 'Vorlage',
    multiSelect: {
      title: 'Einfach-/Mehrfach-Auswahl',
      targetId: 'Ziel ID',
      template: 'Vorlage',
    },
    allowLinebreaks: 'Zeilenumbrüche erlauben',
    allowFormatting: 'Formatierung erlauben',
    breakoutRooms: {
      title: 'Breakout Rooms',
      breakoutRoom: 'Raum:',
      addBreakoutRoom: 'Breakout Room hinzufügen',
      pickRandomly: 'Teilnehmer:innen für die Räume zufällig auswählen',
      pick: 'Auswählen',
      reset: 'Zurücksetzen',
      noData: 'Sie haben alle möglichen Teilnehmer:innen ausgewählt.',
      selectParticipants: 'Teilnehmer:innen auswählen',
      distribute: 'Räume erstellen:',
      pullAllBack: 'Rückkehr aller zur Lobby',
      participants: 'Teilnehmer:innen',
      lobby: 'Gerade in der Lobby:',
      nobodyLobby: 'Niemand in der Lobby',
      remove: 'Breakout Room entfernen',
      room: 'Raum',
      distributeParticipants:
        'Breakout Räume erstellen und Teilnehmer:innen verteilen.',
    },
    button: {
      title: 'Button',
    },
    textfield: {
      title: 'Textfeld',
    },
    stepper: {
      title: 'Stepper',
    },
    roleManager: {
      title: 'Rollenverwaltung',
      selectParticipants: 'Teilnehmer:in auswählen',
      selectRole: 'Rolle auswählen',
    },
    propertiesManager: {
      title: 'Properties Manager',
    },
    listManager: {
      title: 'List Manager',
    },
    documentManager: {
      defaultTitle: 'Document Manager',
      minimize: 'Minimieren',
    },
    workspace: {
      title: 'Workspace',
    },
    mediaControls: {
      title: 'Media Controls',
    },
  },
  arrangement: {
    title: 'Arrangement',
    type: 'Arrangement',
    absolute: { title: 'Absolut' },
    slides: { title: 'Folien' },
    grid: {
      title: 'Raster',
      gridColumns: 'Spalten',
      gridRows: 'Zeilen',
    },
    block: { title: 'Block', keepSize: 'Größe behalten', gutter: 'Abstand' },
    flex: { title: 'Flex', stretchHeight: 'Höhe ausfüllen', gutter: 'Gutter' },
    origin: { title: 'Origin' },
    divide: {
      title: 'Geteilt',
      aspectRatio: 'Aspect Ratio (1 / 1)',
      stretch: 'Stretch',
    },
    circle: {
      title: 'Kreis',
      '3d': '3D',
      '2d': '2D',
      facingInwards: 'Nach innen gerichtet',
    },
    rectangle: {
      title: 'Rechteck',
      '3d': '3D',
      '2d': '2D',
      keepSize: 'Größe behalten',
    },
    sphere: { title: 'Kugel' },
  },
  class: {
    title: 'Klasse',
    container: 'Container Klassen: ',
    element: 'Element Klassen: ',
  },
  computeds: {
    title: 'Computeds',
    scoped: 'Scoped Computeds',
    key: 'Key',
  },
  placement: {
    title: 'Platzierung',
    parentArrangement: 'Parent-Arrangement',
    rotation: 'Rotation',
    position: 'Position',
    scale: 'Skalierung',
    area: 'Area',
    ignoreParentConstraints: 'Ignoriere Eltern-Größe',
    fixed: {
      left: 'Links',
      center: 'Zentriert',
      right: 'Rechts',
      top: 'Oben',
      bottom: 'Unten',
      horizontal: 'Horizontal',
      vertical: 'Vertikal',
    },
  },
  props: {
    title: 'Eigenschaften',
  },
  station: {
    title: 'Station',
  },
  camera: {
    title: 'Kamera',
    type: 'Kamera-Typ',
    'pan-and-zoom': {
      title: 'Schwenken und Zoomen',
    },
    orbit: {
      title: 'Orbit',
    },
    fixed: {
      title: 'Fixiert',
    },
    'auto-zoom': {
      title: 'Auto-Zoom',
    },
    'first-person': {
      title: 'First Person',
    },
    panorama: {
      title: 'Panorama',
    },
    customPosition: 'Eigene Kameraposition',
    zoomLimits: 'Zoom-Grenzen',
    limitZoomForScreenSize: 'Zoom auf Screen limitieren',
    azimuthAngle: 'Azimuth-Winkel',
    polarAngle: 'Polar-Winkel',
    controlIfLoaded: 'Control if loaded',
  },
  size: {
    title: 'Größe',
  },
  style: {
    title: 'Style',
    addProperty: 'Eigenschaft hinzufügen',
  },
  sheet: {
    title: 'Sheet',
    orientTowardsCamera: 'Zur Kamera orientieren',
    scriptedType: 'Scripted Type',
  },
  children: {
    title: 'Children',
    template: 'Vorlage',
    data: 'Daten',
    useDefault: '- Standardeinstellungen verwenden -',
    query: 'Query',
    import: 'Import',
    remove: 'Entfernen',
    addQuery: 'Query hinzufügen',
    invalidTag:
      'Das Dokument ist keine Vorlage. Bitte wählen Sie ein Dokument vom Typ Vorlage aus. Diese erkennen Sie im Auswahlfeld an einem Icon vor dem Dokumentnamen.',
    mapping: 'Mapping',
  },
  template: {
    title: 'Vorlage',
  },
  templates: {
    title: 'Vorlagen',
  },
  info: {
    title: 'Info',
    idCopiedToClipboard: 'ID in Zwischenablage kopiert',
  },
  notes: {
    title: 'Notes',
  },
  tag: {
    hint: 'Tag',
    title: 'Tag',
    variant: 'Variante',
  },
  tagDefinition: {
    title: 'Tag Definition',
    definition: 'Definition',
    tag: 'Tag',
    titleField: 'Titel',
    category: 'Kategorie',
    icon: 'Icon',
    editor: 'Editor',
    allowedChildren: 'Erlaubte Kinder',
    variants: 'Varianten',
    addVariant: 'Neue Variante',
    allowedTagCategories: 'Child Categories',
    allowedTags: 'Child Tags',
  },
  interfaceDefinition: {
    title: 'Interface',
    inheritFrom: 'Lade Props',
    inheritFromId: 'Id',
    props: 'Props',
  },
  elementModule: {
    title: 'Element',
  },
  list: {
    title: 'Inhalt',
    h1: 'H1',
    h2: 'H2',
    h3: 'H3',
    h4: 'H4',
    h5: 'H5',
    h6: 'H6',
    text: 'Text',
    styledText: 'Gestylter Text',
    reference: 'Querverweis',
    embed: 'Einbettung',
    image: 'Bild',
    quote: 'Zitat',
    markdown: 'Markdown',
    html: 'Html',
    page: 'Seite',
    document: 'Dokument',
    collection: 'Sammlung',
    template: 'Vorlage',
    participant: 'Teilnehmer:in',
    circle: 'Kreis',
    noSearchResults: 'Keine Suchergebnisse',
    placeholder: 'Ihr Text ...',
    table: 'Table',
  },
  listener: {
    title: 'Listener',
    on: 'Event',
    goto: 'Gehe zu',
    add: 'Hinzufügen',
    script: 'Script',
    stopPropagation: 'Propagation stoppen',
    listenToChildren: 'Auf Kinder hören',
    maxDepth: 'Max. Tiefe',
  },
  element: {
    title: 'Element',
    text: {
      title: 'Text',
      subtitle: 'Text anzeigen',
      description: 'Text anzeigen',
      textContent: 'Inhalt',
      textFormat: 'Format',
      p: 'Absatz (<p>)',
      div: 'Div',
      h1: 'Überschrift 1',
      h2: 'Überschrift 2',
      h3: 'Überschrift 3',
      h4: 'Überschrift 4',
      h5: 'Überschrift 5',
      h6: 'Überschrift 6',
    },
    icon: {
      title: 'Icon',
      sizes: {
        small: 'Klein',
        medium: 'Medium',
        large: 'Groß',
        xl: 'Sehr Groß',
      },
    },
    image: {
      title: 'Bild',
      subtitle: 'Bilder hochladen',
      description: 'Bilder hochladen',
      imageSrc: 'Bild-URL',
      imageDescription: 'Beschreibung',
      imageCopyright: 'Copyright',
      noImageSet: 'Kein Bild',
      expandOnClick: 'Bei Klick vergrößern',
    },
    embed: {
      title: 'Einbetten',
      subtitle: 'Externe Inhalte einbinden',
      description: 'Externe Inhalte einbinden',
      embedSrc: 'Embed URL',
      embedCode: 'Embed Code',
      preferredEmbedMode: 'Bevorzugter Modus zum Einbetten',
      noEmbedSet: 'Keine URL zum einbetten',
    },
    participant: {
      title: 'Teilnehmer:in',
      subtitle: 'Teilnehmer*in einbinden',
      description: 'Teilnehmer*in einbinden',
      boxShapes: 'Videodarstellung',
      circle: 'Rund',
      square: 'Quadratisch',
      rectangle: 'Rechteckig',
      cover: 'Cover',
      alwaysShowName: 'Namen immer anzeigen',
      hideDetails: 'Details verstecken',
      video: 'Video aktiv',
      audio: 'Audio aktiv',
      id: 'Id',
    },
    screenshare: {
      title: 'Screenshare',
      subtitle: 'Bildschirm teilen',
      description: 'Teilen Sie Ihren Bildschirm',
    },
    html: {
      title: 'HTML',
      subtitle: 'Code Eingabefeld',
      description: 'HTML Code schreiben und darstellen',
    },
    styledText: {
      title: 'Formatierter (HTML) Text',
    },
    markdown: {
      title: 'Markdown',
      subtitle: 'Code Eingabefeld',
      description: 'Markdown Code schreiben und darstellen',
      loadFromUrl: 'Von externer Url laden (nur .md)',
      loadingFailed:
        'Fehler beim Laden des externen Inhalts. Bitte prüfen Sie die URL.',
      onlyMarkdownSupported:
        'Derzeit werden nur Markdown-Dateien (Dateiendung .md) unterstützt.',
    },
    button: {
      title: 'Button',
      text: 'Text',
      icon: 'Icon',
      event: 'Event',
      args: 'Argumente',
      href: 'Ziel-URL',
    },
    chart: {
      title: 'Diagramm',
      chartType: 'Typ',
      colorGridLines: 'Farbe',
      colorAxis: 'Farbe',
      showGrid: 'Anzeigen',
      direction: 'Horizontal',
      axisX: 'X-Achse',
      axisY: 'Y-Achse',
      axis: 'Achse',
      showAxis: 'Achse anzeigen',
      labelsY: 'Beschriftung Y-Achse',
      labelsX: 'Beschriftung X-Achse',
      ticksUnit: 'Einheit der Achsen',
      ticksUnitY: 'Einheit der Y-Achse',
      ticksUnitX: 'Einheit der X-Achse',
      datasets: 'Datensätze',
      label: 'Beschriftung',
      values: 'Werte',
      backgroundColor: 'Farbe',
      borderColor: 'Farbe',
      stacked: 'Gestapelt',
      beginAtZero: 'Bei 0 beginnen',
      max: 'Max',
      min: 'Min',
      borderRadius: 'Radius',
      borderWidth: 'Weite',
      showLabel: 'Beschriftung',
      labelPosition: 'Position der Beschriftung',
      ticksUnitPosition: 'Position der Achseneinheit',
      start: 'Start',
      center: 'Mittelpunkt',
      end: 'Ende',
      general: 'Allgemein',
      grid: 'Grid',
      numLayers: 'Menge',
      customLayers: 'Benutzerdefiniert',
      layers: 'Ebenen',
      background: 'Hintergrund',
      border: 'Rahmen',
      scriptedDatasets: 'Scripted Datasets',
      scriptedType: 'Scripted Type',
      scriptedOptions: 'Scripted Options',
      options: 'Optionen',
      legendFontSize: 'Schriftgröße Legende',
    },
    table: {
      title: 'Tabelle',
      useQuery: 'Query verwenden',
      tableQuery: 'Query',
      tableRowData: 'Zeilen-Inhalt',
      columns: 'Spalten',
      columnTitle: 'Spaltentitel',
      columnPath: 'Inhalts-Pfad',
      cellClasses: 'Zellen-Klassen',
      column: 'Spalte',
      allowContentEditing: 'Bearbeitung erlauben',
      lockEditing: 'Bearbeitung sperren',
    },
    reveal: {
      title: 'Revealjs',
      noRevealCodeSet: 'Kein Code eingesetzt',
      revealContent: 'Markdown Content',
      controls: 'Zeige Navigation: ',
      controlsLayout: 'Platzierung der Navigation: ',
      layout: { bottomRight: 'Unten rechts', edges: 'An den Seiten' },
    },
    audio: {
      title: 'Audio',
    },
    svg: {
      title: 'SVG',
      svgCode: 'SVG-Code',
      svgStyles: 'SVG CSS-Styles',
    },
    inlineEditing: {
      title: 'Inline Editing',
      documentId: 'Dokument-ID',
      label: 'Beschriftung',
      placeholder: 'Platzhalter',
      allowEditing: 'Editieren erlauben',
      fieldPath: 'Pfad zum Feld',
      formatting: {
        title: 'Formatierung',
        none: 'Keine',
        inline: 'Inline',
        basic: 'Basic',
        advanced: 'Erweitert',
      },
      autoSave: 'Automatisch speichern',
      saveAsVolatile: 'Als Volatile speichern',
      targetFormat: {
        title: 'Text-Format',
        html: 'HTML',
        text: 'Text',
      },
    },
    line: {
      title: 'Line',
      lineColor: 'Color',
      lineWidth: 'Width',
      lineFrom: 'From',
      lineTo: 'To',
    },
    video: {
      title: 'Video',
      videoSrc: 'Video URL',
      videoAssetId: 'Asset ID',
      videoPlaybackId: 'Playback ID',
      videoDescription: 'Beschreibung',
      videoTitle: 'Titel',
      videoCopyright: 'Copyright',
      subtitles: 'Untertitel',
      subtitleTitle: 'Titel',
      subtitleLanguage: 'Sprache',
      subtitleUrl: 'URL',
    },
  },
  model: {
    title: 'Modell',
    loader: {
      title: 'Object-Loader',
      subtitle: '3D Objekte laden',
      description: '3D Objekte laden',
      library: 'Auswahl',
      animate: 'Animieren',
      loaderSrc: 'Eigene 3D-Objekt-URL',
      allowedFormats: 'Nur .gftl und .glb Dateien',
      header3D: '3D Objekte',
      options: {
        littleTokyo: 'Little Tokyo',
        ship: 'Buddelschiff',
        expressiveRobot: 'Roboter',
        headerMaps: 'Karten & Hintergründe',
        cartoonWorld: 'Low Poly Cartoon Weltkarte',
        floatingIslands: 'Fliegende Inseln',
      },
    },
    marker: {
      title: 'Marker',
      library: 'Auswahl',
      markerContent: 'Inhalt',
      animate: 'Animieren',
      headerMarkers: 'Marker',
    },
    video360: {
      title: '360° Video',
      subtitle: '360° Video einbinden',
      description: '360° Video einbinden',
      library: 'Auswahl',
      videoSrc: 'Eigene 360-Video-URL',
      settings: {
        controlledBy: 'Kontrolliert von',
        controlledByHost: 'Host',
        controlledByUser: 'User',
        controlPanel: 'Bedienoberfläche',
        startEvent: 'Start',
        startManually: 'Manuell',
        startOnFocus: 'Fokus',
        loop: 'Endlosschleife',
      },
      options: {
        climateArena: 'Rundgang Klimaarena',
        tunnel: 'Tunnel-Tour',
        rainforest: 'Regenwald-Tour',
      },
    },
    scenery: {
      title: 'Szene',
      subtitle: 'Neue Szene',
      description:
        'Fassen Sie gemeinsam genutzte Objekte in einer Szene zusammen',
      sceneryOptions: 'Szenen',
      options: {
        emptyScene: 'Leere Szene',
        ocean: 'Ozean',
        colorfulSky: 'Himmel',
        hdrTexture: 'HDR Bild',
        simpleFloorAndBackground: 'Farbiger Raum',
        ambientLight: 'Lichtquelle',
      },
      animate: 'Animieren',
      sunPosition: 'Sonnenstand',
      azimuth: 'Azimutwinkel',
      elevation: 'Zenitwinkel ',
      colors: 'Farben',
      baseColor: 'Grundfarbe',
      backgroundColor: 'Hintergrundfarbe',
      image: 'Bild',
      hdrImagesLibrary: 'HDR Auswahl',
      customHdrUrl: 'Benutzderdefinierte URL',
    },
    colorfulSky: {
      title: 'Farbiger Himmel',
    },
    hdrTexture: {
      title: 'HDR Textur',
      library: 'HDR Bilder',
      allowedFormats: 'Kompatible Formate',
      customUrl: 'Benutzerdefinierte URL',
      options: {
        starsAndSky: 'Sterne und Himmel',
        nebula: 'Nebel',
        satara: 'Satara',
        outdoors: 'Outdoor',
        greenField: 'Grünes Feld',
        lakeview: 'Seeblick',
        lakeside: 'Platz am See',
        veniceSunset: 'Venedig',
        otherSunset: 'Sonnenuntergang',
        indoors: 'Indoor',
        machineShop: 'Werkstatt',
        machineShop2: 'Volle Werkstatt',
        studio: 'Studio',
        decorShop: 'Deko Shop',
        entranceHall: 'Eingangshalle',
        comfyCafe: 'Café',
        tvStudio: 'TV Studio',
        oldSchoolTheater: 'Altes Theater',
      },
    },
    positionalAudio: {
      title: '3D Audio',
    },
    audioLoader: {
      title: 'Audio-Loader',

      options: {
        christmans: 'Weihnachten',
        inspirationalMusic: 'Inspirierende Musik',
      },
      settings: {
        playback: 'Abspielen',
        controlledBy: 'Kontrolliert von',
        controlledByHost: 'Host',
        controlledByUser: 'User',
        volume: 'Lautstärke',
        loop: 'Dauerschleife',
        startEvent: 'Auslöser',
        startOnLoad: 'Geladen',
        startOnFocus: 'Fokus',
        startManually: 'Manuell',
        controlPanel: 'Bedienoberfläche',
      },
      error: {
        noValidMediaSource:
          'Die angegebene URL stellt keine gültige Mediendatei bereit.',
      },
    },
  },
  input: {
    inputMedia: {
      mediaSrc: 'URL',
      mediaTitle: 'Titel',
      mediaCopyright: 'Copyright',
      mediaDescription: 'Beschreibung',
      library: 'Auswahl',
      upload: 'Datei hochladen',
      files: 'Dateien von Fillip',
      ownUploads: 'Eigene Uploads',
    },
  },
  navigation: {
    title: 'Navigation',
    linkName: 'Link Name: ',
    displaySlot: 'Anzeige auf: ',
    'Navigation-tree': 'Nav-baum',
    'Main-drawer top': 'Hauptmenu oben',
    'Main-drawer bottom': 'Hauptmenu unten',
    sortingArea: 'Sortierbereich',
    enableSorting: 'Sortieren erlauben',
    index: 'Index',
    icon: 'Icon: ',
    linkTo: 'Root: ',
    removeLink: 'Link entfernen',
    addLink: 'Link hinzufügen',
    addSegment: 'route-segment hinzufügen',
    removeSegment: 'route-segment entfernen',
    explicit: 'Ausdrücklich',
    segment: 'Segment',
    tooltips: {
      home: 'Startseite',
      navigation: 'Navigation',
      chat: 'Chat',
      activity: 'Aktivitäten',
      search: 'Suchen',
      explorer: 'Erkunden',
      communityOverview: 'Community-Überblick',
      help: 'Hilfe',
      trash: 'Papierkorb',
      extensions: 'Erweiterungen',
    },
  },
  participantAccount: {
    title: 'Benutzerkonto',
    userId: 'Benutzer-Id',
    role: 'Rolle',
    host: 'Community-Verwalter:in',
    admin: 'Community-Admin',
    editor: 'Redaktuer:in',
    participant: 'Teilnehmer:in',
    restricted: 'Teilnehmer:in mit eigeschränkten Rechten',
    inactive: 'Inaktive:r Teilnehmer:in',
  },
  participantStatusEditor: {
    title: 'Status',
    currentlyOnline: 'Derzeit online',
    currentlyOffline: 'Derzeit offline',
    seePrototype: 'See prototypes',
  },
  participantVc: {
    title: 'Videokonferenz',
  },
  avatar: {
    title: 'Avatar',
  },
  participantCircle: {
    title: 'Aktueller Circle',
    currentCircle: 'Aktueller Circle',
  },
  location: {
    title: 'Standort',
  },
  roles: {
    title: 'Rollen',
  },
  variables: {
    title: 'Variablen',
    identifier: 'Identifier',
  },
  schema: {
    title: 'Schema',
    childSchema: 'Child Schema',
    ownSchema: 'Eigenes Schema',
    entities: 'Entity Schema',
    loadFrom: 'Lade Schema from',
    loadFromId: 'Id',
    loadFields: 'Felder',
    selectItems: 'Select Items',
    addSelectItem: 'Add select item',
    removeSelectItem: 'Remove select item',
  },
  streams: {
    title: 'Streams',
  },
  circleSyncLocation: {
    title: 'Circle-Standort',
  },
  videoConferencing: {
    title: 'Videokonferenz',
    camera: 'Kamera',
    microphone: 'Mikrofon',
    screen: 'Screenshare',
    roomSize: 'Raumgröße',
    channel: 'Channel',
    state: 'Stand',
    global: 'global',
    roomSizes: {
      xs: '< 4 Teilnehmer:innen',
      sm: '4 - 8 Teilnehmer:innen',
      md: '8 - 15 Teilnehmer:innen',
      lg: '15-30 Teilnehmer:innen',
    },
    settings: {
      resolutionConstraints: {
        title: 'Auflösungsbeschränkungen',
        width: 'Weite',
        height: 'Höhe',
      },
      screen: {
        title: 'Screenshare Einstellungen',
        audio: 'Audio',
        video: {
          title: 'Video',
          cursor: 'Cursor',
          cursorOptions: {
            always: 'Immer',
            never: 'Nie',
            motion: 'Bewegung',
          },
          resizeMode: {
            title: 'Größenanpassungsmodus',
            none: 'keiner',
            'crop-and-scale': 'Zuschneiden und Skalieren',
          },
          frameRate: 'Bildrate',
          logicalSurface: 'Logical Surface',
          displaySurface: {
            title: 'Display Surface',
            monitor: 'Monitor',
            window: 'Fenster',
            application: 'Anwendung',
            browser: 'Browser',
          },
        },
      },
    },
  },
  objectEditor: {
    add: 'Module hinzufügen',
    activeModules: 'Aktive Module',
    noModulesSelected: 'Keine aktiven Module',
  },
  documentEditor: {
    document: 'Dokument-Editor',
    object: 'Objekt-Editor',
    json: 'JSON-Editor',
  },
  properties: {
    title: 'Eigenschaften',
  },
  schemaFieldsEditor: {
    title: 'Titel',
    key: 'Key',
    type: 'Typ',
    restrictions: 'Einschränkungen',
    filter: 'Aus den Sammlungen',
    tags: 'Inbegriffene Tags',
    defaultValue: 'Standardwert',
    required: 'Erforderlich',
  },
  sceneDefinitions: {
    title: 'Szenen-Definitionen',
    sceneName: 'Szenen-Name ',
    removeScene: 'Szene entfernen',
    addScene: 'Szene hinzufügen',
    sceneTemplate: 'Vorlage: ',
    sceneData: 'Data: ',
    sceneSlug: 'Slug: ',
    isDynamic: 'Dynamisch?',
  },
  draggable: {
    title: 'Draggable',
    settings: 'Einstellungen',
    isActive: 'Aktiv?',
    dragType: 'Drag-Typ',
    inertia: 'Inertia?',
    bounds: 'Grenzen',
    classes: 'Classes',
    draggableClasses: 'Draggable',
    draggedClasses: 'Dragged',
    droppable: 'Droppable',
    droppableIdentifier: 'Identifier',
    hitTarget: 'Hit-Target',
    stayAtPosition: 'An Position bleiben',
    droppableOverlap: 'Overlap',
    actions: 'Action-Scripts',
    onDragStart: 'onDragStart',
    onDragEnd: 'onDragEnd',
    dragTypes: {
      x: 'X (horizontal)',
      y: 'Y (vertikal)',
      'x,y': '2D (X & Y)',
      rotation: 'Drehung',
    },
  },
  droppable: {
    title: 'Droppable',
    settings: 'Einstellungen',
    condition: 'Bedingung',
    droppableIdentifier: 'Identifier',
    classes: 'Klassen',
    droppableClasses: 'Droppable',
    droppableOverlap: 'Overlap-Grenzwert',
    droppedClasses: 'Dropped',
    actions: 'Aktion-Scripts',
    onDrop: 'onDrop',
    onDragEnter: 'onDragEnter',
    onDragLeave: 'onDragLeave',
  },
  transitions: {
    title: 'Transitions',
    enter: 'Enter',
    transform: 'Transform',
    leave: 'Leave',
    enterFrom: 'Enter from',
    leaveTo: 'Leave to',
    target: 'Target',
    parent: 'Parent',
  },
  editorErrors: {
    invalidUrl: 'Ungültige URL',
  },
};
