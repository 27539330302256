import { DataDocument, PresetDefinition } from '@fillip/api';

export const WorkspaceInstance: DataDocument = {
  id: 'WorkspaceInstance',
  info: {
    title: 'Workspace',
    toolEditing: {
      allowedChildTypes: ['text'],
      editors: {
        title: {
          id: 'WorkspaceInstance',
        },
        properties: {
          id: 'WorkspaceSettings',
          title: 'Einstellungen',
        },
      },
    },
  },
  tag: {
    tag: 'template',
  },
  sheet: {
    orientTowardsCamera: false,
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  list: {
    items: [
      {
        id: 'WorkspaceToolCollection',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'tool-workspace',
        query: ":get('WorkspaceInstance')",
      },
    ],
  },
  properties: {
    tools: {
      type: 'id',
      value: 'WorkspaceToolCollection',
    },
    activeTool: {
      type: 'id',
      value: '',
    },
    vcTemplate: {
      type: 'id',
      value: 'VideoInstance',
    },
    // vcDataId:{
    //   type:'id',
    //   value:'VideoInstance'
    // },
    readOnly: {
      type: 'boolean',
      value: false,
    },
    autoReadOnly: {
      type: 'boolean',
      value: false,
    },
  },
  schema: {
    fields: [],
    ownFields: [
      {
        title: 'readOnly',
        key: 'readOnly',
        type: 'boolean',
      },
      {
        title: 'Auto Read Only',
        key: 'autoReadOnly',
        type: 'boolean',
      },
      {
        key: 'tools',
        title: 'Tool Collection',
        type: 'id',
        restrictions: 'hidden',
      },
      {
        key: 'activeTool',
        title: 'Aktives Tool',
        type: 'id',
        restrictions: 'hidden',
      },
      {
        key: 'vcTemplate',
        title: 'Videoconference-Template',
        type: 'id',
        restrictions: 'hidden',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'readOnlyProp',
        query: ":getPropFrom('readOnly')(id)",
      },
      {
        identifier: 'autoReadOnlyProp',
        query: ":getPropFrom('autoReadOnly')(id)",
      },
      {
        identifier: 'lessonHasPassed',
        query: ':true',
      },
      {
        identifier: 'isReadOnly',
        query: ':$$autoReadOnlyProp ? $$lessonHasPassed : $$readOnlyProp ',
      },
      {
        identifier: 'activeToolVolatile',
        query: ":getVolatileProp(id,'activeToolVolatile')",
      },
      {
        identifier: 'workspaceToolsId',
        query: ':id',
      },
      {
        identifier: 'vcTemplateId',
        query: ":getPropFrom('vcTemplate')(id)",
      },
    ],
  },
  roles: {
    roles: {
      host: {
        key: 'host',
        title: 'Host',
        documents: [],
      },
    },
  },
};

export const WorkspaceToolCollectionTemplate: DataDocument = {
  id: 'WorkspaceToolCollection',
  info: {
    title: 'Workspace Tools',
  },
  tag: {
    tag: 'collection',
  },
  parentId: 'WorkspaceInstance',
  list: {
    items: [{ id: 'VideoInstance' }],
  },
  children: {
    default: [
      {
        templateId: 'VideoInstance',
        query: ':[data]',
      },
    ],
  },
};

const VideoInstance: DataDocument = {
  id: 'VideoInstance',
  parentId: 'WorkspaceToolCollection',
  info: {
    title: 'Videokonferenz',
    icon: 'webcam',
  },
  tag: {
    tag: 'template',
  },
  sheet: {
    orientTowardsCamera: false,
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  list: {
    items: [{ id: 'RaisedHands' }],
  },
  children: {
    default: [
      {
        templateId: 'tool-videoconference',
        query: ':[data]',
      },
      {
        templateId: 'RaisedHands',
        query: ':[data]',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'raisedHandsCollectionId',
        query: 'RaisedHands',
      },
      {
        identifier: 'isRaised',
        query:
          ":pipe(getList($$raisedHandsCollectionId),where(matchesProperty('info.createdBy',$me.id)))().length > 0",
      },
      {
        identifier: 'raisedHandId',
        query:
          ":$$isRaised ?pipe(getList($$raisedHandsCollectionId),where(matchesProperty('info.createdBy',$me.id)))()[0].id :''",
      },
    ],
  },
};
const RaisedHands: DataDocument[] = [
  {
    id: 'RaisedHands',
    info: {
      title: 'Raised Hands',
    },
    tag: {
      tag: 'collection',
    },
    parentId: 'VideoInstance',
    list: {
      items: [],
    },
  },
];

export const PresetWorkspace: PresetDefinition = {
  id: 'PresetWorkspace',
  title: 'Workspace',
  icon: 'album',
  group: 'templates',
  version: '0.1',
  documents: [
    WorkspaceInstance,
    WorkspaceToolCollectionTemplate,
    VideoInstance,
    ...RaisedHands,
  ],
};
