import { DataDocument, fragmentsTemplateDirectoryId } from '@fillip/api';

export const TemplateFragmentSimpleRoom: DataDocument = {
  id: 'fragment-simple-room',
  info: {
    title: 'Simple Breakout Room',
    icon: 'person-booth',
    isProtected: true,
  },
  tag: {
    tag: 'template',
  },
  parentId: fragmentsTemplateDirectoryId,
  list: {
    items: [
      {
        id: 'fragment-room-body',
      },
      {
        id: 'fragment-focus-videoconference',
      },
      {
        id: 'fragment-focus-screenshare',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'fragment-header',
        query: ':[data]',
        condition: ':!!isFocused',
      },
      {
        templateId: 'fragment-room-body',
        query: ':[data]',
      },
    ],
  },
  videoConferencing: {
    camera: {
      channel: '',
      roomSize: '0',
      state: 'ON',
      settings: {
        width: { min: 320, ideal: 640, max: 640 },
        height: { min: 240, ideal: 480, max: 480 },
      },
    },
    microphone: {
      channel: '',
      roomSize: '0',
      state: 'ON',
      settings: {},
    },
    screen: {
      channel: '',
      roomSize: '0',
      state: 'ON',
      settings: {
        audio: false,
        video: {
          displaySurface: 'monitor',
          logicalSurface: true,
          cursor: 'always',
          resizeMode: 'none',
          width: { max: 1920 },
          height: { max: 1080 },
          frameRate: { max: 30 },
        },
      },
    },
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  size: {
    minWidth: '100vw',
    minHeight: '100vh',
    minDepth: '',
    maxWidth: '100vw',
    maxHeight: '100vh',
    maxDepth: '',
  },
  sheet: {
    orientTowardsCamera: false,
  },
  class: {
    elementClass: '',
    class: '',
  },
  variables: {
    variables: [
      {
        identifier: 'activeScreenshares',
        query:
          ": pipe(participants, where(atScene($route), atStation($station), has('streams.screen.producerId')))().length",
      },
      {
        identifier: 'hasOwnScreenshare',
        query:
          ": pipe(participants, where( is($me.id), has('streams.screen.producerId')))().length > 0",
      },
    ],
  },
  station: {},
};

const TemplateFragementSimpleRoomBody: DataDocument = {
  id: 'fragment-room-body',
  info: {
    title: 'Room Body',
  },
  tag: {
    tag: 'template',
  },
  parentId: 'fragment-simple-room',
  children: {
    default: [
      {
        templateId: 'fragment-focus-videoconference',
        query: ':[data]',
      },
      {
        templateId: 'fragment-focus-screenshare',
        query: ':[data]',
      },
    ],
  },
  arrangement: {
    type: 'arrangement.grid',
    gridColumns: ": $$activeScreenshares > 0 ? '2fr 8fr' : '1fr'",
    gridRows: '1fr',
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

const TemplateFragmentFocusVideoconference: DataDocument = {
  id: 'fragment-focus-videoconference',
  info: {
    title: 'Fokus-Videokonferenz',
    icon: 'webcam',
  },
  tag: {
    tag: 'template',
  },
  parentId: 'fragment-simple-room',
  children: {
    default: [
      {
        templateId: null,
        query:
          ':participants  |> where(isOnline, atScene($route), atStation($station))',
        identity: '',
        condition: ':$station == getFocus($route)',
      },
    ],
  },
  arrangement: {
    type: 'arrangement.divide',
    divideAspectRatio: '1/1',
    divideStretch: false,
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

const TemplateFragmentFragmentFocusScreenshare: DataDocument = {
  id: 'fragment-focus-screenshare',
  info: {
    title: 'Fokus-Screenshare',
    icon: 'arrow-alt-square-up',
  },
  tag: {
    tag: 'template',
  },
  parentId: 'fragment-simple-room',
  list: {
    items: [],
  },
  children: {
    default: [
      {
        templateId: 'ToolScreenshareScreen',
        query:
          ":pipe(participants, where(atScene($route), atStation($station), _.has('streams.screen.producerId')))()",
        identity: '',
      },
    ],
  },
  arrangement: {
    type: 'arrangement.divide',
    divideAspectRatio: '16/9',
    divideStretch: false,
  },
  sheet: {
    orientTowardsCamera: false,
  },
  variables: {
    variables: [
      {
        identifier: 'activeScreenshares',
        query:
          ": pipe(participants, where(atScene($route), atStation($station), has('streams.screen.producerId')))().length",
      },
      {
        identifier: 'hasOwnScreenshare',
        query:
          ": pipe(participants, where( is($me.id), has('streams.screen.producerId')))().length > 0",
      },
    ],
  },
  actions: {
    actions: [
      {
        name: ": $$hasOwnScreenshare ? 'Teilen beenden' : 'Bildschirm teilen'",
        type: 'action.button',
        icon: 'arrow-alt-square-up',
        color: '',
        script: 'toggleScreenshare()',
        slot: 'bottom',
        focused: 'station',
        roles: ":$$onlyForHosts ? ['host'] : []",
      },
    ],
  },
  class: {
    elementClass: '',
    class: 'w-full h-full grid grid-cols-1',
  },
};

export const TemplateFragmentSimpleRoomChildren: DataDocument[] = [
  TemplateFragementSimpleRoomBody,
  TemplateFragmentFocusVideoconference,
  TemplateFragmentFragmentFocusScreenshare,
];
