import { ActionControl, DataDocument, DataId, rootDataId } from '@fillip/api';
import { computed, inject } from '@vue/composition-api';
import { useNav } from '../globals/useNav';
import { useI18n } from '../i18n/useI18n';
import { usePermissions } from '../permissions/usePermissions';
import { useData } from './useData';
import { useDataClipboard } from './useDataClipboard';
import { useVolatileData } from './useVolatileData';
import { useMessage } from '../globals';

export function useDocuments() {
  const { getVolatileProp, setVolatileProp, clearVolatile } = useVolatileData();
  const { getData } = useData();
  const { $drawer, $dialog, $navdrawer } = useNav();
  const dataClipboard = useDataClipboard();
  const { canEditCommunity } = usePermissions();
  const { tr } = useI18n();
  const { navigate } = inject('layoutArea') as any;
  const { error } = useMessage();
  const selection = computed(() =>
    getVolatileProp(rootDataId, 'selection', null),
  );

  const updateSelection = (id: DataId) => {
    if (!id) {
      setVolatileProp(rootDataId, 'selection', null);
    } else {
      if (!getData(id) || getVolatileProp(rootDataId, 'selection') == id)
        return;
      setVolatileProp(rootDataId, 'selection', id);
    }
  };

  const editInDialog = (id: DataId, select: boolean = false) => {
    if (select) updateSelection(id);

    $dialog.push({ name: 'DocumentEditor', params: { id } });
  };

  const openSideEditor = () => {
    if ($drawer.currentRoute.fullPath != '/editor') {
      $drawer.push({ name: 'Editor' });
    }
  };

  const editInSideDrawer = (id: DataId, select: boolean = false) => {
    if (!id || id.startsWith('function:')) return;
    if (!getData(id)) {
      error("Find document failed, document doesn't exist");
      console.error(
        "Document doesn't exist, check for falty 'parentId' in your template",
      );
      return;
    }
    if (select) {
      updateSelection(id);
    }

    return $drawer.push({ name: 'Editor', params: { id } });
  };

  const openInNavdrawer = (id: DataId) => {
    updateSelection(id);
    $navdrawer.push({
      name: 'Explorer',
      params: { id },
    });
  };

  const openImport = (parentId: DataId) => {
    dataClipboard.clear();
    dataClipboard.setTarget(parentId);
    $dialog.push('/clipboard/import');
  };

  const openExport = (dataId: DataId) => {
    dataClipboard.copyFromId(dataId);
    $dialog.push('/clipboard/export');
  };

  const openExportForDocuments = (documents: DataDocument[]) => {
    dataClipboard.copy(documents);
    $dialog.push('/clipboard/export');
  };

  const openParticipant = (
    participantId: string,
    target: string = 'drawer',
  ) => {
    $navdrawer.push(
      {
        name: 'ParticipantView',
        params: { participantId: participantId },
      },
      target,
    );
  };

  const documentActionCollection = computed<Record<string, ActionControl>>(
    () => {
      return {
        editInSideDrawer: {
          type: 'action',
          title: tr('explorer.edit'),
          action: (doc) => editInSideDrawer(doc.id),
          icon: 'pencil',
          if: canEditCommunity.value,
        },
        editInDialog: {
          type: 'action',
          title: tr('explorer.editDocument'),
          action: (doc) => editInDialog(doc.id),
          icon: 'file',
          if: canEditCommunity.value,
        },
        exportDocument: {
          type: 'action',
          title: tr('explorer.export'),
          action: (doc) => openExport(doc.id),
          icon: 'file-export',
          if: canEditCommunity.value,
        },
      };
    },
  );

  const defaultDocumentActions = computed<ActionControl[]>(() => {
    const actions: ActionControl[] = [];
    if (canEditCommunity.value) {
      actions.push(
        ...[
          documentActionCollection.value.editInSideDrawer,
          documentActionCollection.value.editInDialog,
          documentActionCollection.value.exportDocument,
        ],
      );
    }

    return actions;
  });

  const editDocumentActions = computed<ActionControl[]>(() => {
    const actions: ActionControl[] = [];
    if (canEditCommunity.value) {
      actions.push(
        ...[
          documentActionCollection.value.editInSideDrawer,
          documentActionCollection.value.editInDialog,
        ],
      );
    }

    return actions;
  });

  return {
    editInDialog,
    openSideEditor,
    editInSideDrawer,
    openExport,
    openExportForDocuments,
    selection,
    updateSelection,
    openImport,
    openParticipant,
    openInNavdrawer,
    documentActionCollection,
    defaultDocumentActions,
    editDocumentActions,
  };
}
