import type { IBaseControls } from './index';
import { BaseCameraControls } from './BaseCameraControls';
import type { CameraAutoZoom } from '@fillip/api';
import type { Box3 } from 'three';
import * as Location3D from '@/utils/location3D';

export class AutoZoomControls
  extends BaseCameraControls
  implements IBaseControls
{
  initialize(): void {
    super.initialize();
  }

  updateCamera(
    sceneCamera: CameraAutoZoom,
    sceneBoundingBox: Box3,
    hasParentChanged: boolean,
  ) {
    super.updateCamera(sceneCamera, sceneBoundingBox, hasParentChanged);
  }

  checkCameraPosition() {
    if (Location3D.hasBox3Size(this.sceneBoundingBox)) {
      this.zoomToFit(this.sceneBoundingBox);
    }
  }
}
