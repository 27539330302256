





























import Vue from 'vue';

import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';

export default Vue.extend({
  name: 'ConditionalEditorContainer',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: props.path,
    });

    return {
      buffer,
      save,
    };
  },
});
