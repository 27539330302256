var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"app-bar px-1 flex items-center w-full print:hidden",staticStyle:{"z-index":"7","transition":"all 2s"},style:({
    background: _vm.themeManager.value.appBarBackground,
    height: _vm.denseAppBar ? '60px' : '80px',
  })},[(_vm.themeManager.value.appBarShadow)?_c('div',{staticClass:"shadow-container"}):_vm._e(),_c('div',{staticClass:"control-bar-content w-full h-full"},[_c('div',{staticClass:"flex justify-self-start"},[_c('MainControl'),_c('Navigation')],1),_c('div',{staticClass:"items-center flex justify-self-center"},[(_vm.canSeePrototype)?_c('div',[_c('BaseButton',{attrs:{"icon":"map-marker-alt","size":"icon"}})],1):_vm._e(),(
          _vm.denseAppBar && _vm.vcSettings && _vm.vcSettings.microphone.state == 'ON'
        )?_c('div',[_c('BaseButton',{attrs:{"icon":_vm.isMuted ? 'microphone-slash' : 'microphone',"size":"icon","title":_vm.$t('videoConference.shortcuts.toggleAudio')},on:{"click":_vm.toggleMicrophone}})],1):_vm._e(),_c('div',{staticClass:"mx-1 mb-1 relative",staticStyle:{"width":"100px","height":"70px","margin-top":"-20px"},style:({
          'margin-top': _vm.denseAppBar ? '-20px' : '0',
        })},[_c('Me')],1),(_vm.canSeePrototype)?_c('div',[_c('ControlButton',{attrs:{"control":_vm.participantStatusControl,"button-style":"flat"}})],1):_vm._e(),(_vm.denseAppBar && _vm.vcSettings && _vm.vcSettings.camera.state == 'ON')?_c('div',[_c('BaseButton',{attrs:{"icon":_vm.isCameraOn ? 'video' : 'video-slash',"size":"icon","title":_vm.$t('videoConference.shortcuts.toggleVideo')},on:{"click":_vm.toggleCamera}})],1):_vm._e()]),_c('div',{staticClass:"flex items-center justify-end"},[_c('div',{staticClass:"flex justify-end",class:_vm.denseAppBar ? 'flex-row' : 'flex-col'},[_c('BaseButton',{attrs:{"icon":"ellipsis-v","size":"icon"},on:{"click":function($event){_vm.showMainMenu = !_vm.showMainMenu}}}),(_vm.canEditCommunity)?_c('BaseButton',{attrs:{"icon":"sliders-h","size":"icon"},on:{"click":_vm.toggleEditor}}):_vm._e()],1)])]),_c('MainMenu',{model:{value:(_vm.showMainMenu),callback:function ($$v) {_vm.showMainMenu=$$v},expression:"showMainMenu"}}),_c('div',{staticClass:"flex justify-content-center fixed top-0 left-0 right-0 items-end pointer-events-none",staticStyle:{"bottom":"86px"}},[_c('v-slide-y-reverse-transition',[(_vm.show)?_c('div',{staticClass:"px-3 pt-4 pb-0 container mx-auto blurred-glass",staticStyle:{"max-width":"500px !important","height":"auto","border-radius":"0.25rem","pointer-events":"initial"}},[_c('Prompt',{on:{"submit":function($event){_vm.show = false}}}),_c('div',{staticClass:"flex justify-center"},[_c('BaseButton',{staticStyle:{"margin-top":"-1rem"},attrs:{"icon":"angle-down","size":"icon","height":"1.5rem","width":"1.5rem"},on:{"click":function($event){_vm.show = false}}})],1)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }