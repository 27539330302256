





























import {
  useCircles,
  usePermissions,
  useDocuments,
  useI18n,
} from '@/composables';
import { ActionControl } from '@fillip/api';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'CircleOverview',
  setup() {
    const { canSeePrototype, canEditCommunity } = usePermissions();
    const { circles } = useCircles();
    const { tr } = useI18n();

    const { openImport, openExportForDocuments, defaultDocumentActions } =
      useDocuments();

    const globalActions = computed(() => {
      const actions: ActionControl[] = [
        {
          type: 'action',
          title: tr('explorer.import'),
          action: () => openImport('circles'),
          icon: 'file-import',
        },
        {
          type: 'action',
          title: tr('explorer.export'),
          action: () => openExportForDocuments(circles.value),
          icon: 'file-export',
        },
      ];
      return actions;
    });

    return {
      circles,
      canSeePrototype,
      canEditCommunity,
      defaultDocumentActions,
      globalActions,
    };
  },
});
