






















































import {
  useParticipants,
  useLocations,
  useData,
  useCircles,
  usePermissions,
  useI18n,
  useNav,
  useDocuments,
  useSchema,
} from '@/composables';
import {
  defineComponent,
  computed,
  inject,
  onMounted,
  Ref,
} from '@vue/composition-api';

export default defineComponent({
  name: 'ParticipantView',
  props: {
    participantId: {
      type: String,
      default: '',
    },
  },
  setup(props: any) {
    const { canEditCommunity } = usePermissions();
    const { getParticipant, getAvatarUrl } = useParticipants();
    const { getParticipantLocationTitle } = useLocations();
    const { getData } = useData();
    const { getCircle } = useCircles();
    const { tr } = useI18n();
    const { $navdrawer } = useNav();
    const { editDocumentActions } = useDocuments();
    const { getCircleSchema } = useSchema();

    const closeParticipant = () => {
      $navdrawer.push({ name: 'CommunityOverview' });
    };

    onMounted(() => {
      if (!props.participantId) {
        closeParticipant();
      }
    });

    const participant = computed(() => getParticipant(props.participantId));
    const participantStatus = inject('participantStatus', null) as Ref<any>;

    // TODO: Move to participant status composable
    const currentStatus = computed(() => {
      if (!participantStatus?.value)
        return participant.value.participantStatus.status;
      const available = participantStatus.value.statusOptions;
      return tr(
        available[participant.value.participantStatus.status || 'offline']
          .title,
      );
    });

    const locationTitle = computed(() => {
      return getParticipantLocationTitle(props.participantId);
    });

    const schemaAndProps = computed(() => {
      const schema = getCircleSchema('main');
      const filteredCircleSchema = [];
      if (!schema) return;

      // TODO: Use proper display elements for Any types
      // Maybe move to separate component / composable
      schema.fields.forEach((field) => {
        if (Object.keys(participant.value.properties).includes(field.key)) {
          let value = [];
          if (Array.isArray(participant.value.properties[field.key].value)) {
            (participant.value.properties[field.key].value as any[]).forEach(
              (element) => {
                value.push(getData(element).info.title);
              },
            );
          } else {
            value = [participant.value.properties[field.key].value];
          }

          filteredCircleSchema.push({
            key: field.title,
            value,
          });
        }
      });
      return filteredCircleSchema;
    });

    return {
      getAvatarUrl,
      participant,
      getData,
      getCircle,
      canEditCommunity,
      closeParticipant,
      currentStatus,
      locationTitle,
      schemaAndProps,
      editDocumentActions,
    };
  },
});
