import { computed } from '@vue/composition-api';
import { useData } from '@/composables';

export const useTools = (id: any) => {
  const { getData, updateProperties, pushData } = useData();

  const doc = computed(() => getData(id.value));
  const properties = computed(() => doc.value?.properties || {});

  const toolsFolder = computed(
    () => (properties.value?.tools?.value as string) || '',
  );

  const tools = computed(() => getData(toolsFolder.value));

  const library = computed(() =>
    getData((properties.value?.library?.value as string) || ''),
  );

  const toolsList = computed(() =>
    (tools.value?.list?.items || []).map((item, value) => {
      const data = getData(item.id);
      const text = data.info.title || '';
      return { value, text, id: item.id };
    }),
  );

  // const libraryList = computed(() =>
  //   (library.value?.list?.items || []).map((item, value) => {
  //     const data = getData(item.id);
  //     const text = data.info.title || '';
  //     return { value, text };
  //   }),
  // );

  const addTool = () => {
    return pushData(tools.value.id, 'collection', {
      info: {
        title: 'Untitled Tool',
        icon: '',
      },
    });
  };

  return { toolsFolder, toolsList, addTool };
};
