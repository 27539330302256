import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagButton: DataDocument = {
  id: 'tag-button',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Button',
    icon: 'bullseye-pointer',
    isProtected: true,
  },
  element: {
    type: 'element.button',
    buttonText: ':$text',
    buttonIcon: ':$icon',
    buttonEvent: ':$event',
    buttonEventArgs: ':$eventArgs',
    buttonHref: ':$href',
  },
  class: {
    class: ':`${$containerClasses}`',
    elementClass: ':$consolidatedElementClasses',
  },
  listener: {
    listeners: {
      click: {
        script: 'execute(context, $script)',
        context: {},
        stopPropagation: false,
      },
    },
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'button',
    title: 'Button',
    category: 'content',
    icon: 'bullseye-pointer',
    editor: 'default',
  },
  interfaceDefinition: {
    title: '',
    inheritFrom: 'baseContent',
    props: [
      {
        title: 'Variante',
        key: 'variant',
        type: 'string',
        defaultValue: ":properties?.variant?.value || 'default'",
      },
      {
        title: 'Icon',
        key: 'icon',
        type: 'icon',
        defaultValue: ":properties?.icon?.value || ''",
      },
      {
        title: 'Event Name',
        key: 'event',
        type: 'string',
        defaultValue: ':properties?.event?.value || undefined',
      },
      {
        title: 'Event Arguments',
        key: 'eventArgs',
        type: 'script',
        defaultValue: ':properties?.eventArgs?.value || undefined',
      },
      {
        title: 'Externer Link',
        key: 'href',
        type: 'string',
        defaultValue: ":properties?.href?.value || ''",
      },
      {
        title: 'Action Script',
        key: 'script',
        type: 'script',
        defaultValue: ':$$buttonScript || properties?.script?.value || null',
      },
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":$containerClasses || properties?.containerClasses?.value || ''",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":$elementClasses || properties?.elementClasses?.value || ''",
      },
    ],
  },
  computeds: {
    scoped: [
      // TODO: Move to variants
      {
        key: 'variants',
        expression: ":({\n  default: '',\n  hovered: ''\n})",
        evaluate: false,
      },
      {
        key: 'currentVariant',
        expression: ":$variants[$variant || 'default']",
        evaluate: false,
      },
      {
        key: 'consolidatedElementClasses',
        expression:
          ":$variant === 'primary' ? `${$color} normal-case before:hidden border-0 bg-amber-300 motion-reduce:transition-none transition duration-150 hover:bg-amber-400 hover:scale-110 ${$elementClasses}` : $variant === 'quiet' ? `normal-case border-0 ${$elementClasses}`: `normal-case ${$elementClasses}`",
        evaluate: false,
      },
      {
        key: 'l10n',
        expression: ":({ \n  title: ''\n })",
        evaluate: false,
      },
    ],
  },
};
