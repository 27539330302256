






import { defineComponent, onMounted } from '@vue/composition-api';
import { useRouter, useFillip } from '@/composables';
import AuthContainer from './components/AuthContainer.vue';

export default defineComponent({
  name: 'Logout',
  components: {
    AuthContainer,
  },
  setup() {
    const { router } = useRouter();
    const { fillip } = useFillip();

    const performLogout = async () => {
      try {
        await fillip.auth.logout();
        router.push({ name: 'Login' });
      } catch (error) {
        console.error('Error logging out: ', error);
      }
    };

    onMounted(async () => {
      // TODO: Bind loading indicator
      // TODO: Catch and return errors
      // TODO: Show errors in UI

      await performLogout();
    });

    return { performLogout };
  },
});
