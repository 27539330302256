






































































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ElementTableMeta } from '@fillip/api';
import clone from 'rfdc/default';

export default Vue.extend({
  name: 'TableEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'element',
    });

    const init = () => {
      buffer.value = clone(ElementTableMeta.default);
      save();
    };

    const defaultItem = ElementTableMeta.childDefault.columnDefault;

    const addItem = () => {
      buffer.value.tableColumns.push(clone(defaultItem));
      save();
    };
    const removeItem = (index) => {
      buffer.value.tableColumns.splice(index, 1);
      save();
    };

    return {
      buffer,
      save,
      defaultItem,
      addItem,
      removeItem,
    };
  },
});
