import {
  computed,
  watch,
  reactive,
  toRefs,
  onBeforeUnmount,
  Ref,
  ref,
  nextTick,
  unref,
  set,
  del,
  getCurrentInstance,
} from '@vue/composition-api';
import { ignorableWatch, useEventListener } from '@vueuse/core';
import { useSave, useVolatileData, useGlobalProps } from '@/composables';
import clone from 'rfdc/default';
import { FillipCommands } from '@/features/main/core/types';
import { ModuleActionsMeta } from '@fillip/api';

const mediaTypes = {
  audio: 'audioSettings',
  positionalAudio: 'positionalAudioSettings',
  video360: 'video360Settings',
};
type MediaTypeNames = keyof typeof mediaTypes;
interface UseControlsOptions {
  mediaType: MediaTypeNames;
}

//* singleton state throughout all instances
const mediaElements = reactive({});
const emits = reactive({});

//* COMPOSABLE
export function useMediaControls(
  props: any,
  options: Partial<UseControlsOptions> = {},
  target?: Ref<HTMLMediaElement | null | undefined>,
  emit?: (...args: any) => void,
) {
  const bufferType = computed(() =>
    options.mediaType == 'audio' ? 'element' : 'model',
  );
  const { buffer, save } = useSave(props, {
    path: bufferType.value,
  });
  const { bindGlobalProp } = useGlobalProps();
  const { getVolatileProp, setVolatileProp } = useVolatileData();
  onBeforeUnmount(async () => {
    if (target) {
      //! this blocks proper functionality, when changing from video360 to audio or vice versa. When all instances of useMediaControls get unmounted, the list is being reset anyway. Is that enough for a cleanup?
      // del(unref(mediaElements), props.id);
      removeMediaControls();
      setStop();
    }
  });
  const vm = (getCurrentInstance() as any).proxy as Required<FillipCommands>;
  if (!vm) throw new Error('Vue instance not found!');

  //* reactive data
  const state = reactive({
    id: null,
  });
  const trackDuration = ref(0);
  const currentTrackPosition = ref(0);
  const percentage = ref(0);
  const buffered = ref(false);
  const mediaControlsAction = ref(null);
  const errorMessage = ref<string>(null);
  bindGlobalProp('actions.canvas', () => mediaControlsAction.value);
  state.id = props.id;

  //* props-dependant instance setup
  const bufferPath = mediaTypes[options.mediaType];
  if (target) set(mediaElements, props.id, target);
  if (emit) set(emits, props.id, emit);
  const mediaElement = computed<HTMLMediaElement>(() => {
    const el = unref(mediaElements[props.id]);
    if (!el) return null;
    return el;
  });
  const registerTrackData = () => {
    if (!mediaElement.value) return;
    trackDuration.value = unref(mediaElement).duration;
    currentTrackPosition.value = unref(mediaElement).currentTime;
    buffered.value = true;
    errorMessage.value = null;
  };

  //*

  //* helpers
  const getValue = (key: string, fallback?: any) => {
    return controlledByHost.value
      ? buffer.value?.[bufferPath]?.[key]
      : getVolatileProp(state.id, key, fallback);
  };
  const setValue = (key: string, value: any) => {
    if (controlledByHost.value) {
      buffer.value[bufferPath][key] = value;
      save();
    } else {
      setVolatileProp(state.id, key, value);
    }
  };

  const emitEvent = (...args): void => {
    (emits[state.id] || vm.$emit)(...args);
  };

  //* setting timestamps, allowing async playback
  const setPosition = () => {
    const currentTime = Date.now();
    const newPositionInS = trackDuration.value * (percentage.value / 100);
    const newStart = currentTime - newPositionInS * 1000;
    if (shouldBePlaying.value) {
      setValue('startedAt', newStart);
    } else {
      setValue('pausedAt', currentTime);
      setValue('startedAt', newStart);
    }
    skipToPosition(newStart);
  };
  const setPlay = () => {
    if (!pausedAt.value && !startedAt.value) {
      setValue('startedAt', Date.now());
    } else if (pausedAt.value) {
      setValue(
        'startedAt',
        Date.now() - (getValue('pausedAt') - getValue('startedAt')),
      );
      setValue('pausedAt', 0);
    }
  };
  const setPause = () => {
    setValue('pausedAt', Date.now());
  };
  const setStop = () => {
    setValue('startedAt', null);
    setValue('pausedAt', null);
  };
  const setVolume = (newValue) => {
    setValue('volume', newValue);
  };
  const setLoop = (newValue) => {
    setValue('loop', newValue);
  };

  //* COMPUTED DATA

  //* persistent, relying on mediaElement
  const mediaSource = computed(() => {
    const result = buffer.value?.[`${options.mediaType + 'Src'}`];
    return result;
  });
  const uiDuration = computed((): string =>
    trackDuration.value ? formatTime(trackDuration.value) : '00:00:00',
  );
  const uiPosition = computed((): string =>
    currentTrackPosition.value
      ? formatTime(currentTrackPosition.value)
      : '00:00:00',
  );

  const transcript = computed((): string => {
    return buffer.value?.[bufferPath]?.transcript;
  });

  //* Maybe needed for three.js audio, as this doesn't emit trackEnded event
  // const trackIsPlaying = computed((): boolean =>
  //   getGlobalProp(state.id + ':trackIsPlaying'),
  // );
  //* watch
  // watch(trackIsPlaying, (newValue, oldValue) => {
  //   if (oldValue && !newValue && startedAt.value && !pausedAt.value) {
  //     setStop();
  //   }
  // });
  //*

  //* for allowing async playback functionality
  const controlledByHost = computed(
    () => buffer.value?.[bufferPath]?.controlledBy == 'host',
  );
  const startEvent = computed(() => buffer.value?.[bufferPath]?.startEvent);
  const shouldBePlaying = computed(() =>
    Boolean(startedAt.value && !pausedAt.value),
  );
  const startedAt = computed((): number => getValue('startedAt', 0));
  const pausedAt = computed((): number => getValue('pausedAt', 0));
  const volume = computed((): number => getValue('volume', 0.5));
  const loop = computed((): boolean =>
    startOnFocus.value
      ? buffer.value?.[bufferPath]?.loop
      : getValue('loop', false),
  );
  const manualControlsEnabled = computed(() =>
    controlledByHost.value
      ? buffer.value?.[bufferPath]?.controlPanel
      : buffer.value?.[bufferPath]?.startEvent == 'manual',
  );
  const error = computed(() => mediaElement?.value?.error);

  //* start on focus logic
  const startOnFocus = computed((): boolean => startEvent.value == 'focus');
  const isFocused = computed((): boolean => {
    if (!vm?.router?.value) return null;
    return props.id == vm.router.value.focusedStation.templateId;
  });
  const shouldBePlayingOnFocus = computed((): boolean =>
    Boolean(startOnFocus.value && isFocused.value),
  );

  //* start on load logic
  const startOnLoad = computed((): boolean => startEvent.value == 'load');

  //* meta-data //TODO: update entities to save meta information
  const title = computed(() => buffer.value?.[bufferPath]?.title);
  const description = computed(() => buffer.value?.[bufferPath]?.description);
  const copyright = computed(() => buffer.value?.[bufferPath]?.copyright);
  const actionIcon = computed(() => {
    switch (options.mediaType) {
      case 'audio':
        return 'music';
      case 'positionalAudio':
        return 'music';
      case 'video360':
        return 'film-alt';
      default:
        return 'play';
    }
  });

  //* utilities
  const formatTime = (seconds) => {
    return new Date(seconds * 1000).toISOString().substring(11, 19);
  };
  const calcOffset = (newStartTime?) => {
    const currentTime = Date.now();
    const startTime = newStartTime
      ? newStartTime
      : startedAt.value || currentTime;
    const endTime = startTime + trackDuration.value * 1000;
    let offsetInS: number = 0;
    if (currentTime < endTime) {
      offsetInS = (currentTime - startTime) / 1000;
    } else if (loop.value) {
      offsetInS =
        (((currentTime - startTime) / 1000) % trackDuration.value) *
        trackDuration.value;
    }
    return offsetInS;
  };

  //* actual playback controls on the mediaElement
  const loadMediaElement = async (startInPaused = false) => {
    const el = unref(mediaElement.value);
    if (!el) {
      console.warn('No valid media source!');
      return;
    }
    stopMediaElement();
    el.load();
    el.oncanplay = async () => {
      if (shouldBePlaying.value) startMediaElement();
      else startMediaElement(startInPaused);
    };
  };

  const startMediaElement = async (startInPaused = false) => {
    const el = unref(mediaElement.value);
    if (!el || !buffered.value) return;
    try {
      el.loop = loop.value;
      el.volume = volume.value;
      await el.play();
      await nextTick();
      if (startInPaused) el.pause();
    } catch (e) {
      console.error('Cannot play MediaElement: ', e);
      const startPlay = async () => {
        await el.play();
        await nextTick();
        if (startInPaused) el.pause();
        document.removeEventListener('click', startPlay);
      };
      document.addEventListener('click', startPlay);
    }
  };
  const skipToPosition = async (newStartTime?) => {
    if (!mediaElement.value || !buffered.value) return;
    const offsetInS = calcOffset(newStartTime);
    mediaElement.value.currentTime = offsetInS;
  };
  const stopMediaElement = () => {
    const el = unref(mediaElement.value);
    if (!el || !buffered.value) return;
    el.pause();
    if (!pausedAt.value) {
      el.load();
      buffered.value = false;
    }
  };

  //* action-layer injection of controls
  const addMediaControls = () => {
    mediaControlsAction.value = {
      ...clone(ModuleActionsMeta.types['action.mediaControls'].default),
      id: state.id,
      mediaType: options.mediaType,
      icon: actionIcon.value,
    };
  };
  const removeMediaControls = () => {
    mediaControlsAction.value = undefined;
  };

  //* WATCHER
  watch(
    mediaElement,
    (newValue, oldValue) => {
      if (newValue && newValue != oldValue) {
        registerTrackData();
        loadMediaElement(startOnLoad.value ? false : true);
      }
    },
    { immediate: true },
  );
  watch(
    shouldBePlayingOnFocus,
    (newValue, oldValue) => {
      if (newValue == oldValue) return;
      if (newValue) {
        setPlay();
      } else if (oldValue) {
        setStop();
      }
    },
    { immediate: true },
  );
  watch(controlledByHost, (newValue, oldValue) => {
    if (newValue != oldValue) setStop();
  });
  watch(
    mediaSource,
    (newValue, oldValue) => {
      if (newValue == oldValue) return;
      errorMessage.value = null;
      setStop();
      if (newValue) {
        loadMediaElement(startOnLoad.value ? false : true);
        if (manualControlsEnabled.value && target) addMediaControls();
      } else {
        stopMediaElement();
        removeMediaControls();
      }
    },
    { immediate: true },
  );
  watch(shouldBePlaying, (newValue, oldValue) => {
    if (newValue == oldValue) return;
    if (newValue) {
      startMediaElement(false);
    } else stopMediaElement();
  });
  //! Resets the progress bar, when stopping in paused Mode.
  watch(startedAt, (newValue, oldValue) => {
    if (oldValue == newValue) return;
    else if (!newValue) {
      stopMediaElement();
    }
  });
  watch(volume, (newValue) => {
    if (!mediaElement.value) return;
    mediaElement.value.volume = newValue;
  });
  watch(loop, (newValue) => {
    if (!mediaElement.value) return;
    mediaElement.value.loop = newValue;
  });

  const endTrack = () => {
    emitEvent('event', {
      name: 'trackEnded',
      event: { id: state.id },
    });
    setStop();
    if (manualControlsEnabled.value) {
      setVolatileProp(state.id, 'vMinimized', true);
    }
  };

  //* HTMLMediaElement event-listeners (to broadcast events to all components pointing to the respective mediaElement)
  useEventListener(mediaElement, 'loadedmetadata', () => registerTrackData());
  useEventListener(mediaElement, 'loadeddata', () => (buffered.value = true));
  useEventListener(mediaElement, 'durationchange', () => registerTrackData());
  useEventListener(mediaElement, 'waiting', () => (buffered.value = false));
  useEventListener(mediaElement, 'seeking', () => (buffered.value = false));
  useEventListener(mediaElement, 'seeked', () => (buffered.value = true));
  useEventListener(mediaElement, 'timeupdate', () =>
    ignoreCurrentTimeUpdates(() => {
      currentTrackPosition.value = unref(mediaElement)!.currentTime;
      if (!currentTrackPosition.value) {
        percentage.value = 0;
      } else {
        percentage.value =
          (currentTrackPosition.value / trackDuration.value) * 100;
      }
    }),
  );
  useEventListener(mediaElement, 'ended', () => {
    if (target && startedAt) {
      endTrack();
    }
  });
  useEventListener(mediaElement, 'error', () => {
    if (mediaSource.value)
      errorMessage.value = vm.$t('model.audioLoader.error.noValidMediaSource');
  });

  //* METHODS

  const { ignoreUpdates: ignoreCurrentTimeUpdates } = ignorableWatch(
    currentTrackPosition,
    (time) => {
      const el = unref(target);
      if (!el) return;

      el.currentTime = time;
    },
  );

  watch(
    manualControlsEnabled,
    (newValue) => {
      if (!mediaSource.value) return;
      if (newValue && target) addMediaControls();
      else if (target) removeMediaControls();
    },
    { immediate: true },
  );
  watch(
    error,
    (newValue) => {
      if (newValue && mediaSource.value) {
        errorMessage.value = vm.$t(
          'model.audioLoader.error.noValidMediaSource',
        );
        removeMediaControls();
      } else errorMessage.value = null;
    },
    { immediate: true },
  );

  return {
    state: toRefs(state),
    buffered,
    bufferType,
    bufferPath,
    errorMessage,

    //* control parameters
    mediaSource,
    volume,
    loop,
    trackDuration,
    uiDuration,
    uiPosition,
    percentage,

    //* async indicators
    controlledByHost,
    manualControlsEnabled,
    startEvent,
    startOnFocus,
    shouldBePlaying,
    startedAt,
    pausedAt,

    //* info
    title,
    description,
    copyright,

    //* methods
    setPlay,
    setPause,
    setStop,
    setPosition,
    setVolume,
    setLoop,
    loadMediaElement,
    startMediaElement,
    stopMediaElement,
    skipToPosition,
    endTrack,
    transcript,
  };
}
