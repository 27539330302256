import { BaseObject3D } from '../core';
import {
  MeshBasicMaterial,
  Mesh,
  BackSide,
  ImageBitmapLoader,
  SphereGeometry,
  CanvasTexture,
  LinearFilter,
  ReinhardToneMapping,
  sRGBEncoding,
} from 'three';

export const ModelHdrTexture = {
  name: 'ModelHdrTexture',
  mixins: [BaseObject3D],
  inject: ['canvas'],
  created() {
    this.scene = this.canvas.value.renderManager.scene;
    this.renderer =
      this.canvas.value.renderManager.renderers.get('webGL').renderEngine;

    // this.renderer.toneMapping = ReinhardToneMapping;
    // this.renderer.toneMappingExposure = 1;

    this.texture = null;
    this.geo = new SphereGeometry(5000, 100, 100);
    this.mesh = null;
    this.material = null;
    this.loader = new ImageBitmapLoader();
    this.loader.setOptions({ imageOrientation: 'flipY' });

    this.isInitialized = false;
    this.isLoading = false;

    if (this.image) {
      this.loadImage(this.image);
    }
  },
  computed: {
    image() {
      return this.modules.model.hdrTextureSrc;
    },
  },
  watch: {
    image: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          if (
            (!this.isInitialized && !this.isLoading) ||
            newValue != oldValue
          ) {
            this.isLoading = true;
            this.loadImage(newValue);
          }
        } else {
          this.clear();
        }
      },
    },
    // inactive: {
    //   immediate: true,
    //   handler(newValue, oldValue) {
    //     this.$object3D.visible = !newValue;
    //     this.canvas.value.render('webGL', true);
    //   },
    // },
  },
  methods: {
    async loadImage(url: string) {
      if (!this.loader) return;
      this.loader.load(
        url,
        (image) => this.updateImage(image),
        (xhr) => {
          // console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
        },
        (error) => {
          console.error('Error loading image', error);
        },
      );
    },
    updateImage(image) {
      // TODO: Reuse existing mesh when refactoring sceneries
      if (this.isInitialized) {
        this.scene.remove(this.mesh);
      }
      this.texture = new CanvasTexture(image);
      this.texture.encoding = sRGBEncoding;
      this.texture.minFilter = LinearFilter;
      this.texture.magFilter = LinearFilter;
      this.texture.flipY = true;

      this.material = new MeshBasicMaterial({
        color: 0xffffff,
        map: this.texture,
        side: BackSide,
        opacity: 1,
      });

      this.mesh = new Mesh(this.geo, this.material);
      this.mesh.scale.set(-1, -1, -1);
      this.mesh.rotateZ(Math.PI);
      this.scene.add(this.mesh);
      this.isInitialized = true;
      this.isLoading = false;

      this.$object3D.add(this.mesh);
      this.canvas.value.render('webGL', true);
    },
    clear() {
      this.scene?.remove(this.mesh);
      this.isInitialized = false;
      if (this.$object3D) this.$object3D.clear();
    },
  },
  beforeDestroy() {
    this.clear();
  },
  render() {
    return null;
  },
};
