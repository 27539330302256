import { FillipVueClient } from '../client';
import { IFillipVueClientModule } from '../base.client';
import {
  CouldNotFindAllCollections,
  CreateFileRequest,
  CreateCollectionRequest,
  CouldNotFindCollectionGeneric,
  CouldNotFindCollection,
  CouldNotCreateCollection,
  UpdateCollectionRequest,
  CouldNotCreateFile,
  CouldNotGetAllFilesOfCommunity,
  CouldNotGetAllFilesInCollectionGeneric,
  CouldNotFindFileGeneric,
  CouldNotFindFile,
} from '@fillip/api';

export class FillipVueFileCollectionsClient extends IFillipVueClientModule {
  constructor(public root: FillipVueClient) {
    super('academy', root);
  }

  async getAllCollections(communitySlug: string) {
    try {
      const { data } = await this.axios.get(
        `${this.apiUrl}/${communitySlug}/file-collections`,
      );

      return data;
    } catch (error) {
      this.handleException(CouldNotFindAllCollections());
    }
  }
  async getAllCollectionsPopulated(communitySlug: string) {
    try {
      const { data } = await this.axios.get(
        `${this.apiUrl}/${communitySlug}/file-collections/populated`,
      );

      return data;
    } catch (error) {
      this.handleException(CouldNotFindAllCollections());
    }
  }

  async getAllCollectionsOfUser(communitySlug: string) {
    try {
      const { data } = await this.axios.get(
        `${this.apiUrl}/${communitySlug}/file-collections/my-collections`,
      );
      return data;
    } catch (error) {
      this.handleException(CouldNotFindAllCollections());
    }
  }

  async getAllCollectionsOfUserPopulated(communitySlug: string) {
    try {
      const { data } = await this.axios.get(
        `${this.apiUrl}/${communitySlug}/file-collections/my-collections/populated`,
      );
      return data;
    } catch (error) {
      this.handleException(CouldNotFindAllCollections());
    }
  }

  async getCollectionById(communitySlug: string, collectionId: number) {
    try {
      const { data } = await this.axios.get(
        `${this.apiUrl}/${communitySlug}/file-collections/${collectionId}`,
      );
      return data;
    } catch (error) {
      this.handleException(CouldNotFindCollectionGeneric());
    }
  }

  async getCollectionByName(communitySlug: string, collectionName: string) {
    try {
      const { data } = await this.axios.get(
        `${this.apiUrl}/${communitySlug}/file-collections/name/${collectionName}`,
      );
      return data;
    } catch (error) {
      this.handleException(CouldNotFindCollection(collectionName));
    }
  }

  async findCollection(communitySlug: string, needle: Record<string, any>) {
    try {
      const { data } = await this.axios.post(
        `${this.apiUrl}/${communitySlug}/file-collections/find`,
        needle,
      );
      return data;
    } catch (error) {
      this.handleException(CouldNotFindCollection(JSON.stringify(needle)));
    }
  }

  async createCollection(collection: CreateCollectionRequest) {
    try {
      const { data } = await this.axios.post(
        `${this.apiUrl}/${collection.communitySlug}/file-collections`,
        collection,
      );
      //this.getAllCollections(collection.communitySlug);
      return data;
    } catch (error) {
      this.handleException(CouldNotCreateCollection(collection.name));
    }
  }

  async updateCollection(
    collectionData: UpdateCollectionRequest,
    collectionId: number,
  ) {
    try {
      const { data } = await this.axios.patch(
        `${this.apiUrl}/${collectionData.communitySlug}/file-collections/${collectionId}`,
        collectionData,
      );
      return data;
    } catch (error) {
      // interfers with error thrown in service, if user is unauthorized
      // this.handleException(CouldNotUpdateCollection());
      this.handleException(error);
    }
  }

  async softDeleteCollection(communitySlug: string, collectionId: number) {
    try {
      const { data } = await this.axios.delete(
        `${this.apiUrl}/${communitySlug}/file-collections/${collectionId}`,
      );
      return data;
    } catch (error) {
      this.handleException(error);
    }
  }

  async createFile(fileData: CreateFileRequest) {
    try {
      const { data } = await this.axios.post(
        `${this.apiUrl}/${fileData.communitySlug}/file-collections/file`,
        fileData,
      );

      //this.getAllCollections(fileData.communitySlug);
      return data;
    } catch (error) {
      this.handleException(CouldNotCreateFile(fileData.name));
    }
  }

  async updateFile(fileData: CreateFileRequest, fileId: number) {
    try {
      const { data } = await this.axios.patch(
        `${this.apiUrl}/${fileData.communitySlug}/file-collections/file/${fileId}`,
        fileData,
      );
      return data;
    } catch (error) {
      this.handleException(error);
    }
  }

  async getAllFilesOfCommunity(communitySlug: string) {
    try {
      const { data } = await this.axios.get(
        `${this.apiUrl}/${communitySlug}/file-collections/all-files-of-community`,
      );
      return data;
    } catch (error) {
      this.handleException(CouldNotGetAllFilesOfCommunity(communitySlug));
    }
  }

  async getAllFilesInCollection(communitySlug: string, collectionId: number) {
    try {
      const { data } = await this.axios.get(
        `${this.apiUrl}/${communitySlug}/file-collections/files/${collectionId}`,
      );
      return data;
    } catch (error) {
      this.handleException(CouldNotGetAllFilesInCollectionGeneric());
    }
  }

  async getMultipleFiles(communitySlug: string, queryIds: number[]) {
    try {
      const { data } = await this.axios.post(
        `${this.apiUrl}/${communitySlug}/file-collections/files/multiple`,
        queryIds,
      );
      return data;
    } catch (error) {
      this.handleException(error);
    }
  }

  async getFileById(communitySlug: string, fileId: number) {
    try {
      const { data } = await this.axios.get(
        `${this.apiUrl}/${communitySlug}/file-collections/file/${fileId}`,
      );
      return data;
    } catch (error) {
      this.handleException(CouldNotFindFileGeneric());
    }
  }

  async getFileByName(communitySlug: string, fileName: string) {
    try {
      const { data } = await this.axios.get(
        `${this.apiUrl}/${communitySlug}/file-collections/file/${fileName}`,
      );
      return data;
    } catch (error) {
      this.handleException(CouldNotFindFile(fileName));
    }
  }

  async findFile(communitySlug: string, needle: Record<string, any>) {
    try {
      const { data } = await this.axios.post(
        `${this.apiUrl}/${communitySlug}/file-collections/file/find`,
        needle,
      );
      return data;
    } catch (error) {
      this.handleException(error);
    }
  }

  async softDeleteFile(communitySlug: string, fileId: number) {
    try {
      const { data } = await this.axios.delete(
        `${this.apiUrl}/${communitySlug}/file-collections/file/${fileId}`,
      );
      return data;
    } catch (error) {
      this.handleException(error);
    }
  }

  async queryFileByName(communitySlug: string, query: string) {
    try {
      const { data } = await this.axios.post(
        `${this.apiUrl}/${communitySlug}/file-collections/file-query`,
        query,
      );
      return data;
    } catch (error) {
      this.handleException(error);
    }
  }
}
