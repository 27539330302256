

















import Vue from 'vue';
import { useData } from '@/composables';
import { computed } from '@vue/composition-api';
import { functionTemplates } from '@/features/main/function-templates';
import { frontendPrefixMarker } from '@fillip/api';

export default Vue.extend({
  name: 'TemplateSelector',
  props: {
    value: {
      type: String,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: [String, Boolean],
      default: undefined,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    allowCustom: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { getDataByTag } = useData();
    return {
      templates: computed(() => {
        return [...getDataByTag('template'), ...getDataByTag('tag')];
      }),
    };
  },
  data() {
    return {
      buffer: null,
    };
  },
  computed: {
    functionTemplates() {
      return Object.entries(functionTemplates).map(([key, value]) => {
        return {
          value: 'function:' + key,
          text: value.name,
        };
      });
    },
    items() {
      const empty = { value: null, text: this.$t('children.useDefault') };
      const functionHeader = { header: 'Function Templates' };
      const functions = this.functionTemplates;
      const itemHeader = { header: 'Custom Templates' };
      const items = this.templates.map((item) => {
        return {
          value: item.id,
          text: item.info?.title || item.id,
        };
      });
      const result = [functionHeader, ...functions, itemHeader, ...items];
      if (this.allowEmpty) {
        result.unshift(empty);
      }
      return result;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue === null) {
          this.buffer = { value: null, text: this.$t('children.useDefault') };
          return;
        }
        if (newValue.startsWith(frontendPrefixMarker)) {
          this.buffer = newValue;
          return;
        }
        if (newValue.startsWith('function:')) {
          const templateFunction = this.functionTemplates.find(
            (t) => t.value == newValue,
          );

          this.buffer = {
            ...templateFunction,
          };
          return;
        }
        const template = this.templates.find((t) => t.id == newValue);
        this.buffer = {
          value: template.id,
          text: template.info?.title || template.id,
        };
      },
    },
  },
  methods: {
    updateBuffer(newValue) {
      if (this.value != newValue) {
        if (
          typeof newValue == 'string' &&
          newValue.startsWith(frontendPrefixMarker)
        ) {
          this.$emit('input', newValue);
          return;
        }

        this.$emit('input', newValue.value);
      }
    },
  },
});
