
import { useStore } from '@/composables';
import { defineComponent, watch, onBeforeUnmount } from '@vue/composition-api';

export default defineComponent({
  name: 'ParticipantListener',
  props: {
    participantId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { store } = useStore();

    const addParticipantListener = (participantId: string) => {
      store.commit('videoConference/ADD_PARTICIPANT_LISTENER', participantId);
    };
    const removeParticipantListener = (participantId: string) => {
      store.commit(
        'videoConference/REMOVE_PARTICIPANT_LISTENER',
        participantId,
      );
    };

    watch(
      () => props.participantId,
      (newValue, oldValue) => {
        if (oldValue && oldValue !== newValue) {
          removeParticipantListener(oldValue);
        }
        if (newValue) {
          addParticipantListener(newValue);
        }
      },
      { immediate: true },
    );

    onBeforeUnmount(() => {
      removeParticipantListener(props.participantId);
    });
  },
  render: null,
});
