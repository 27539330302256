











































import Vue from 'vue';
import { useVolatileData, useData, usePermissions } from '@/composables';

export default Vue.extend({
  name: 'ActionTextfield',
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: function () {
        return this.$t('action.defaultActionText');
      },
    },
    id: {
      type: String,
      required: true,
    },
    vPropId: {
      type: String,
      required: true,
    },
    canvasSlot: {
      type: String,
      default: 'bottom',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    allowLinebreaks: {
      type: Boolean,
      default: false,
    },
    allowFormatting: {
      type: Boolean,
      default: false,
    },
    vMinimized: {
      type: String,
      default: '',
    },
    docId: {
      type: String,
      default: '',
    },
    areaMeta: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { canSeePrototype } = usePermissions();
    const { getVolatileProp, setVolatileProp } = useVolatileData();
    const { getData } = useData();
    return {
      canSeePrototype,
      setVolatileProp,
      getVolatileProp,
      getData,
    };
  },
  data() {
    return {
      input: '',
      autoGrowHack: 1,
    };
  },
  computed: {
    isMinimized() {
      return this.getVolatileProp(this.vPropId, this.vMinimized, false);
    },
    document() {
      if (!this.docId) return null;
      return this.getData(this.docId);
    },
  },
  watch: {
    isMinimized: {
      handler(oldValue, newValue) {
        if (!oldValue) {
          this.$nextTick(() => {
            this.$refs.textArea.focus();
          });
        }
      },
    },
    docId: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue == oldValue) return;
        if (!newValue) return;
        this.input = this.document.info.title;
        this.forceRerender();
      },
    },
  },
  methods: {
    maybeSubmit() {
      if ((this as any).allowLinebreaks) return;
      (this as any).submit();
    },
    submit() {
      if (!(this as any).input) return;
      this.$emit('submit', { input: (this as any).input });
      (this as any).input = '';
    },
    cancel() {
      this.$emit('submit', { input: (this as any).document.info.title });
      (this as any).vMinimize((this as any).vPropId);
    },
    minimized(targetId) {
      return this.getVolatileProp(targetId, this.areaMeta.vMinimized, true);
    },
    vMinimize(targetId) {
      (this as any).areaMeta.all.forEach((id) => {
        if (id == targetId) return;
        (this as any).setVolatileProp(
          id,
          (this as any).areaMeta.vMinimized,
          true,
        );
      });

      (this as any).setVolatileProp(
        targetId,
        (this as any).areaMeta.vMinimized,
        !(this as any).minimized(targetId),
      );
    },
    forceRerender() {
      (this as any).autoGrowHack = (this as any).autoGrowHack == 1 ? 2 : 1;
    },
  },
});
