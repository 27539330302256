import { computed } from '@vue/composition-api';
import { DataId } from '@fillip/api';
import store from '@/store';
import { Fillip } from '@fillip/vue-client';

export function useVolatileData() {
  const $user = computed(() => Fillip.instance.state.user);

  const communitySlug = store.state.activeCommunity?.slug
    ? store.state.activeCommunity?.slug
    : null;
  const volatileCommunityStore = (store.state as any).volatile?.[
    $user.value.id
  ]?.[communitySlug];
  const collection = volatileCommunityStore?.db?.data;

  const initializeVolatileCommunityStore = (initialSettings?) => {
    const settings = initialSettings ? initialSettings : {};
    if (!volatileCommunityStore || !collection) {
      const hasUserStore = Boolean(
        (store.state as any).volatile[$user.value.id],
      );
      if (!hasUserStore) {
        store.commit('volatile/SET', {
          object: (store.state as any).volatile,
          key: $user.value.id,
          value: { [communitySlug]: { db: { data: settings } } },
        });
      } else {
        store.commit('volatile/SET', {
          object: (store.state as any).volatile[$user.value.id],
          key: communitySlug,
          value: { db: { data: settings } },
        });
      }
    }
  };
  const removeVolatileCommunityStore = () => {
    if (!volatileCommunityStore) return;
    store.commit('volatile/REMOVE', {
      object: (store.state as any).volatile[$user.value.id],
      key: communitySlug,
    });
  };
  const getVolatile = (id: DataId): Record<string, any> => {
    return collection[id] || {};
  };

  const setVolatile = (id: DataId, value: Record<string, any>) => {
    if (!volatileCommunityStore) initializeVolatileCommunityStore();
    store.commit('volatile/SET', {
      object: collection,
      key: id,
      value,
    });
  };

  const clearVolatile = (id: DataId) => {
    const volatileId = collection[id];
    if (!collection || !volatileId) return;
    store.commit('volatile/REMOVE', {
      object: collection,
      key: id,
    });
  };
  const renameVolatile = (newId: DataId, oldId) => {
    setVolatile(newId, getVolatile(oldId));
    clearVolatile(oldId);
  };

  const getVolatileStore = () => {
    return store.getters.volatileStore;
  };

  const getVolatileProp = (
    id: DataId,
    prop: string,
    defaultValue: any = undefined,
  ) => {
    const doc = getVolatile(id);
    if (!doc) return defaultValue;
    const value = doc[prop];
    if (value === undefined) {
      return defaultValue;
    }
    return value;
  };

  const setVolatileProp = (id: DataId, prop: string, value: any) => {
    setVolatile(id, {
      ...getVolatile(id),
      [prop]: value,
    });
  };

  const pushToVolatile = (id: DataId, prop: string, value: any) => {
    const current = getVolatile(id);
    setVolatile(id, {
      ...current,
      [prop]: [...(current[prop] || []), value],
    });
  };

  const removeFromVolatile = (id: DataId, prop: string, value: any) => {
    const current = getVolatile(id);
    setVolatile(id, {
      ...current,
      [prop]: [...(current[prop] || []).filter((x) => x != value)],
    });
  };

  const renameVolatileProp = (id: DataId, oldProp: string, newProp: string) => {
    setVolatile(id, {
      ...getVolatile(id),
      [newProp]: getVolatileProp(id, oldProp),
      [oldProp]: null,
    });
  };

  const removeVolatileProp = (id: DataId, prop: string) => {
    const volatileId = collection[id];
    if (!collection || !volatileId) return;
    store.commit('volatile/REMOVE', { object: volatileId, key: prop });
  };
  return {
    initializeVolatileCommunityStore,
    removeVolatileCommunityStore,
    getVolatile,
    setVolatile,
    clearVolatile,
    renameVolatile,
    getVolatileStore,
    getVolatileProp,
    setVolatileProp,
    removeVolatileProp,
    clearVolatileProp: removeVolatileProp,
    renameVolatileProp,
    pushToVolatile,
    removeFromVolatile,
  };
}
