import { ModuleTypeMeta } from '../../common';

export interface DocumentVariable {
  identifier?: string;
  query?: string;
  evaluate?: boolean;
}

export interface ModuleVariables {
  variables: DocumentVariable[];
}

export const ModuleVariablesMeta: ModuleTypeMeta<ModuleVariables> = {
  default: {
    variables: [],
  },
  childDefault: { identifier: 'variable', query: ':' },
};
