import { FillipCommands } from '@/features/main/core';
import { provide, inject } from '@vue/composition-api';
const ProviderSymbol = Symbol();

export default function useRoot() {
  const provideRoot = (context) => {
    provide<FillipCommands>(ProviderSymbol, context.root);
  };
  const injectRoot = (): FillipCommands => inject(ProviderSymbol);

  return {
    provideRoot,
    injectRoot,
  };
}
