























































































































import Vue from 'vue';
import { GlobalPropsNode } from '@/plugins/global-props';
import PresetSelector from '@/features/explorer/PresetSelector.vue';
import { useWorkspace } from '@/features/workspace/useWorkspace';
import { useData, useTags } from '@/composables';
import { Ref, toRef } from '@vue/composition-api';

export default Vue.extend({
  name: 'ActionWorkspaceControl',
  components: {
    PresetSelector,
  },
  mixins: [GlobalPropsNode],
  props: {
    id: {
      type: String,
    },
    canvasSlot: {
      type: String,
      default: 'bottom',
    },
    action: {
      type: Object,
    },
  },
  setup(props) {
    const id: Ref<string> = toRef(props, 'id') as Ref<string>;
    const {
      toolsFolder,
      vcTemplate,
      tools,
      removeTool,
      activateTool,
      activeToolId,
      doc,
    } = useWorkspace(id);
    const { getData } = useData();
    const { getDataIcon } = useTags();
    return {
      toolsFolder,
      vcTemplate,
      tools,
      removeTool,
      activateTool,
      activeToolId,
      doc,
      getData,
      getDataIcon,
    };
  },
  data() {
    return {
      controlIsOpen: false,
    };
  },
  computed: {
    filteredTools() {
      return this.tools.filter((tool) => tool.id != this.vcTemplate.id);
    },
    isTouch() {
      return window.matchMedia('(any-hover: none)').matches;
    },
    isHorizontal() {
      return this.canvasSlot == 'bottom' || this.canvasSlot == 'top';
    },
    selectedTool() {
      if (!this.activeToolId) return 'none';
      return this.activeToolId;
    },
    readOnly() {
      return this.action?.context?.$vars?.$$isReadOnly;
    },

    isHost() {
      if (!this.$getGlobalProp('roles')) return;
      let isHost;
      this.$getGlobalProp('roles').forEach((role) => {
        if (!role['host']) return;
        isHost = role?.['host']?.documents.includes(this.$me.id);
      });
      return isHost;
    },
  },
  methods: {
    toggleControl(action, event) {
      if (!action || !event) {
        this.controlIsOpen = false;
      } else if (
        (event?.type == 'mouseenter' || event?.type == 'mouseleave') &&
        this.isTouch
      )
        return;
      else if (action == 'open') {
        this.controlIsOpen = true;
      } else if (action == 'close') this.controlIsOpen = false;
      else if (action == 'toggle') {
        this.controlIsOpen = !this.controlIsOpen;
      }
    },
    editTool(id, meta) {
      if (!id && !meta) {
        this.$dialog.push({
          name: 'ToolEditor',
          params: {
            workspaceId: this.id,
          },
        });
      }
      this.$dialog.push({
        name: 'ToolEditor',
        params: {
          workspaceId: this.id,
          id,
          meta,
        },
      });
    },
    remove(id) {
      this.removeTool(id);
      this.activateTool('');
    },
    toolAdded({ createdDocuments }) {
      this.editTool(createdDocuments[0].id, 'isNew');
    },
  },
});
