import { getCurrentInstance, onBeforeUnmount } from '@vue/composition-api';
import { mixerPickFirst, wrapGetter } from '@/plugins/global-props';
import { nanoid } from 'nanoid';

export function useGlobalProps() {
  const vm = (getCurrentInstance() as any).proxy;
  const id = nanoid();

  vm.$globalProps.registerNode(id);
  onBeforeUnmount(() => vm.$globalProps.unregisterNode(id));

  const bindGlobalProp = (path, getter) => {
    vm.$globalProps.broadcast(id, path, wrapGetter(getter));
  };
  const getGlobalProp = (path, mixer = mixerPickFirst) => {
    vm.$globalProps.ensureListen(id, path, mixer);
    return vm.$globalProps.getValue(id, path);
  };

  return { bindGlobalProp, getGlobalProp };
}
