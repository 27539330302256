
















































import Vue from 'vue';

import { useSave, useI18n } from '@/composables';
import { BaseModuleEditor } from '../base';
import { computed } from '@vue/composition-api';

export default Vue.extend({
  name: 'PlacementFixedEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: 'placement',
    },
  },
  setup(props: any) {
    const { t } = useI18n();
    const { buffer, save } = useSave(props, {
      path: props.path,
    });

    const verticalItems = [
      { value: 'top', text: t('placement.fixed.top') },
      { value: 'center', text: t('placement.fixed.center') },
      { value: 'bottom', text: t('placement.fixed.bottom') },
    ];

    const horizontalItems = [
      { value: 'left', text: t('placement.fixed.left') },
      { value: 'center', text: t('placement.fixed.center') },
      { value: 'right', text: t('placement.fixed.right') },
    ];

    const hasCustomPosition = computed(() => !!buffer.value.position);

    const toggleFixedPosition = () => {
      if (buffer.value.position) {
        Vue.delete(buffer.value, 'position');
      } else {
        Vue.set(buffer.value, 'position', { x: 0, y: 0, z: 0 });
      }
      save();
    };

    return {
      buffer,
      save,
      verticalItems,
      horizontalItems,
      hasCustomPosition,
      toggleFixedPosition,
    };
  },
});
