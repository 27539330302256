
























































































































import { useMediaControls, useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import InputMedia from '@/components/input/media/InputMedia.vue';
import InputStyledText from '@/components/input/styled-text/InputStyledText.vue';
import MediaControls from '@/features/editor/components/MediaControls.vue';

export default {
  name: 'MediaEditor',
  components: {
    InputMedia,
    MediaControls,
    InputStyledText,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
    mediaType: {
      type: String,
      required: true,
    },
    library: {
      type: Array,
      default: () => [],
    },
  },
  setup(props: any) {
    const {
      errorMessage,
      bufferType,
      bufferPath,
      mediaSource,
      controlledByHost,
      startOnFocus,
      manualControlsEnabled,
    } = useMediaControls(props, {
      mediaType: props.mediaType,
    });
    const { buffer, data, save } = useSave(props, {
      path: bufferType.value,
    });
    return {
      errorMessage,
      bufferPath,
      mediaSource,
      controlledByHost,
      startOnFocus,
      manualControlsEnabled,
      buffer,
      data,
      save,
    };
  },
  computed: {
    hideControls() {
      return Boolean(!this.controlledByHost && !this.startOnFocus);
    },
  },
  methods: {
    changeMediaSource(inputBuffer: any) {
      this.buffer = inputBuffer;
      this.save();
    },
    updateTranscript({ html }) {
      this.buffer[this.bufferPath].transcript = html;
      this.save();
    },
  },
};
