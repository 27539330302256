import * as Location3D from '@/utils/location3D';
import type { CameraFixed } from '@fillip/api';
import type { Box3 } from 'three';
import { BaseCameraControls } from './BaseCameraControls';
import type { IBaseControls } from './index';

export class FixedControls extends BaseCameraControls implements IBaseControls {
  initialize(): void {
    super.initialize();
  }

  updateCamera(
    sceneCamera: CameraFixed,
    sceneBoundingBox: Box3,
    hasParentChanged: boolean,
  ) {
    super.updateCamera(sceneCamera, sceneBoundingBox, hasParentChanged);
    const location = Location3D.addDefaults(
      sceneCamera.position || { position: { x: 0, y: 0, z: 1000 } },
    );
    this.context.setCameraTarget(location);
  }
}
