



















































































import { computed, defineComponent, type PropType } from '@vue/composition-api';
import type {
  Modules,
  ElementLine as ModuleElementLine,
  Vector3Type,
} from '@fillip/api';

export default defineComponent({
  name: 'ElementLine',
  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      default: '',
    },
    modules: {
      type: Object as PropType<Modules & { element: ModuleElementLine }>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const settings = computed(() => props.modules.element);

    const lineTo = computed(
      () =>
        (settings.value.lineTo as Vector3Type) || {
          x: 0,
          y: 0,
          z: 0,
        },
    );
    const lineFrom = computed(
      () =>
        (settings.value.lineFrom as Vector3Type) || {
          x: 0,
          y: 0,
          z: 0,
        },
    );
    const lineWidth = computed(() => Number(settings.value?.lineWidth ?? 0));

    const dimensions = computed(() => {
      const width =
        Math.abs(lineTo.value.x - lineFrom.value.x) + lineWidth.value;
      // console.log('settings.value.lineFrom', settings.value.lineFrom.y);
      // console.log('settings.value.lineTo', settings.value.lineTo.y);
      const height =
        Math.abs(lineTo.value.y - lineFrom.value.y) + lineWidth.value;

      return { width, height };
    });

    const lineStyle = computed(() => {
      const styles = {
        solid: '1,1',
        'dashes-small': '10,20',
        'dashes-large': '20,20',
        dotted: '1,20',
      };
      return styles[settings.value.lineStyle || 'solid'];
    });

    const positions = computed(() => {
      const x1 =
        lineFrom.value.x > lineTo.value.x
          ? dimensions.value.width - lineWidth.value / 2
          : lineWidth.value / 2;
      const x2 =
        lineFrom.value.x > lineTo.value.x
          ? lineWidth.value / 2
          : dimensions.value.width - lineWidth.value / 2;
      const y1 =
        lineFrom.value.y > lineTo.value.y
          ? lineWidth.value / 2
          : dimensions.value.height - lineWidth.value / 2;
      const y2 =
        lineFrom.value.y > lineTo.value.y
          ? dimensions.value.height - lineWidth.value / 2
          : lineWidth.value / 2;

      return {
        x1,
        x2,
        y1,
        y2,
      };
    });

    const getElementDimension = (dimension: 'width' | 'height') =>
      Math.max(dimensions.value[dimension], lineWidth.value);

    const elementDimensions = computed(() => ({
      height: getElementDimension('height'),
      width: getElementDimension('width'),
    }));

    const handleEvent = (type, $event) => {
      emit('event', {
        name: type,
        event: {
          event: $event,
        },
      });
    };

    return {
      settings,
      dimensions,
      positions,
      elementDimensions,
      handleEvent,
      lineStyle,
    };
  },
});
