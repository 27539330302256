import { DataDocument, fragmentsTemplateDirectoryId } from '@fillip/api';
export const FragmentEmptyStateCover: DataDocument = {
  id: 'fragment-empty-state-cover',
  info: {
    title: 'Empty State Cover',
  },
  tag: {
    tag: 'template',
  },
  parentId: fragmentsTemplateDirectoryId,
  class: {
    class:
      'p-6 flex justify-center items-stretch w-full h-full place-self-stretch',
    elementClass: '',
  },
  list: {
    items: [
      {
        id: 'empty-state-content',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'empty-state-content',
        query: ':[data]',
      },
    ],
  },
};

export const FragmentEmptyStateCoverChildren: DataDocument[] = [
  {
    id: 'fragment-empty-state-content',
    info: {
      title: 'Tool: EmptyStateCover Content',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'empty-state-cover',
    element: {
      type: 'element.image',
      imageSrc:
        'https://fillip.ai/wp-content/uploads/2021/10/dt-21-empty-state-square-1.svg',
      imageDescription: '',
      imageCopyright: '',
    },
    class: {
      class: '',
      elementClass: 'opacity-60 w-full h-full',
    },
  },
];
