import { PresetDefinition, DataDocument } from '@fillip/api';

export const BreakoutRoomInstance: DataDocument = {
  id: 'BreakoutRoomInstance',
  info: {
    title: 'Breakout Rooms',
    icon: 'map-signs',
    toolEditing: {
      preferredArrangement: 'main-side',
      allowedChildTypes: ['text'],
      // toolTemplate: 'tool-breakoutrooms',
      editors: {
        title: {
          id: 'BreakoutRoomInstance',
        },
        properties: {
          id: 'BreakoutRoomInstance',
          title: 'Einstellungen',
        },
        list: {
          id: 'BreakoutRoomInstance',
          title: 'Breakout-Rooms:',
        },
      },
    },
  },
  tag: {
    tag: 'collection',
  },
  list: {
    items: [],
  },
  children: {
    default: [
      {
        templateId: 'tool-breakoutrooms',
        query: ':[data]',
      },
    ],
  },
  properties: {
    showRoomMembers: {
      type: 'boolean',
      value: true,
    },
  },
  schema: {
    fields: [],
    ownFields: [
      {
        title: 'Zeige Avatare in Räumen',
        key: 'showRoomMembers',
        type: 'boolean',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'breakoutRoomCollection',
        query: ':id',
      },
      {
        identifier: 'breakoutRoomTemplate',
        query: 'fragment-simple-room',
      },
      {
        identifier: 'toolTemplate',
        query: 'tool-breakoutrooms',
      },
      {
        identifier: 'showRoomMembers',
        query: ":getPropFrom('showRoomMembers')(id) == true",
      },
    ],
  },
  sheet: {
    orientTowardsCamera: false,
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
};

export const PresetBreakoutRoom: PresetDefinition = {
  id: 'presetBreakoutRoom',
  title: 'Breakout Rooms',
  icon: 'map-signs',
  group: 'tools',
  version: '0.1',
  documents: [BreakoutRoomInstance],
};
