import { ComponentConfig, ComponentInstance } from '../../../core/types';
import * as Location3D from '@/utils/location3D';
import { PlacementGrid } from '@fillip/api';
import { clone } from 'lodash/fp';

//TODO -Refactor => separate calculations & centering, bundle parseInts at one place, check if empty array checks are still necessary
// figure why grid is slighty offpositioned on y axis
// figure how grid should be sanitized when sum of grid values of one side exceed the described grid size
// either include old grid again or include absolute positioning in new one
// do propper typing => correct placement typing to new format
// change default values in editor
// grid is already calculated => get child placements => sanitize childe placements => child placements define on which of the existing cells the content is placed
// position child placements first => store index of child placements => position other elements & have a check to determine wheater these cells already have been occupied, if yes skip,
export const gridArranger =
  (vm: ComponentInstance) =>
  (input: ComponentConfig[], slot: string = '') => {
    const viewport = vm.viewport;
    const settings: any = vm.modules.arrangement;
    const constraintsParent = vm.sizeConstraints;
    // elementIndex is used, why is it displayed as unused ?

    const fixedGridValues = {
      width: [],
      height: [],
    };
    const fractionedGridValues: any = {
      width: [],
      height: [],
    };
    // find better way to check for empty arrays

    const gridWidth =
      constraintsParent.maxWidth == Infinity
        ? viewport.width
        : constraintsParent.maxWidth;
    const gridHeight =
      constraintsParent.maxHeight == Infinity
        ? viewport.height
        : constraintsParent.maxHeight;
    function fr() {
      const sumWidthFixedValues =
        fixedGridValues.width.length > 0
          ? fixedGridValues.width.reduce((a, b) => a + b)
          : 0;
      const sumHeightFixedValues =
        fixedGridValues.height.length > 0
          ? fixedGridValues.height.reduce((a, b) => a + b)
          : 0;
      const sumWidthFrValues =
        fractionedGridValues.width.length > 0
          ? fractionedGridValues.width.reduce((a, b) => a + b)
          : 0;
      const sumHeigthFrValues =
        fractionedGridValues.height.length > 0
          ? fractionedGridValues.height.reduce((a, b) => a + b)
          : 0;

      // What should happen if this returns a negative number ?
      const width =
        fractionedGridValues.width.length > 0
          ? (gridWidth - sumWidthFixedValues) / sumWidthFrValues
          : 0;

      const height =
        fractionedGridValues.height.length > 0
          ? (gridHeight - sumHeightFixedValues) / sumHeigthFrValues
          : 0;

      return {
        width,
        height,
      };
    }
    const allAutoCellIndices = {
      column: [],
      row: [],
    };
    const columns =
      settings.gridColumns.length > 0
        ? settings.gridColumns.split(' ')
        : settings.gridColumns;

    const rows =
      settings.gridRows.length > 0
        ? settings.gridRows.split(' ')
        : settings.gridRows;

    let numAllRows = rows.length;
    const numExplicitGridCells = columns.length * rows.length;

    //result of parseInput should be an array with all of the pixel values for either column or row
    function parseInput(e, side) {
      const indexOfFrValues = [];
      const resultWithoutFr = e.map((description, index) => {
        //find better way to check for vp & fr and split into suffix & prefix
        const split = (() => {
          const vp = description.indexOf('vp');
          const fr = description.indexOf('fr');
          if (vp != -1) return vp;
          else if (fr != -1) return fr;
          else return -1;
        })();
        const prefix =
          split != -1 ? description.substring(0, split) : description;
        const suffix = split != -1 ? description.substring(split) : null;
        if (!parseInt(prefix) && description != 'auto') {
          console.warn(
            'invalid input: use number or number with suffix vp or fr => example: 2fr // 70vw ',
          );
          return 0;
        } else if (description == 'auto') {
          const localAutoCellIndices = [];
          if (side == 'width') {
            for (let indexRow = 0; indexRow < numAllRows; indexRow++) {
              if (indexRow <= rows.length) {
                localAutoCellIndices.push(index + columns.length * indexRow);
              }
              allAutoCellIndices.column.push(index + columns.length * indexRow);
            }
            const sizes: any = localAutoCellIndices.map((e) => {
              if (!input[e] || !input[e].size) {
                return 0;
              } else {
                return input[e].size.width;
              }
            });
            const biggestSize = Math.max(...sizes);
            fixedGridValues.width.push(biggestSize);
            return biggestSize;
          } else {
            const startIndex = columns.length * index;
            const endIndex = startIndex + columns.length - 1;
            const sizes: any = input
              .filter((e, index) => {
                const range = index >= startIndex && index <= endIndex;
                if (range) {
                  localAutoCellIndices.push(index);
                  allAutoCellIndices.row.push(index);
                  return e;
                } else return;
              })
              .map((e) => (!e.size ? 0 : e.size.height));
            const biggestSize = Math.max(...sizes);
            fixedGridValues.height.push(biggestSize);
            return biggestSize;
          }
        }
        //if input is only a stringed number
        else if (split == -1) {
          fixedGridValues[side].push(parseInt(description));
          return parseInt(description);
        }
        // returns a fraction of the viewport
        // should vp sizes be sanitized if the sum of them exceeds 100 % ?
        else if (suffix == 'vp') {
          const size = (viewport[side] * parseInt(prefix)) / 100;

          fixedGridValues[side].push(size);
          return size;
        }
        // cannot return a value until all of the fixed values have been set, stores index and multiplicator of fraction to overrite all fr values once fr is determined
        else if (suffix == 'fr') {
          fractionedGridValues[side].push(parseInt(prefix));
          indexOfFrValues.push({ index, value: parseInt(prefix) });
        }
      });
      // fr.value describes how many times fr is contained in the element
      if (indexOfFrValues.length > 0) {
        indexOfFrValues.forEach((fraction) => {
          resultWithoutFr.splice(
            fraction.index,
            1,
            fraction.value * fr()[side],
          );
        });
        return resultWithoutFr;
      } else return resultWithoutFr;
    }
    // length of all cells occupied + num of elements that have areas

    let numAreas = 0;
    const allOccupiedCellIndices = [];
    const cellsOccupiedByAreas: any = input
      .filter((element, inputIndex) => element.modules?.placement)
      .map((element) => {
        numAreas++;
        const id = element.id;
        // TODO figure how to use parseInt on placements
        const placement = element.modules.placement as PlacementGrid;
        let left = placement.gridLeft * 1;
        let width = placement.gridWidth * 1;
        let height = placement.gridHeight * 1;
        let top = placement.gridTop * 1;

        Number.isNaN(left) ? (left = 0) : left;
        Number.isNaN(width) ? (width = 0) : width;
        Number.isNaN(height) ? (height = 0) : height;
        Number.isNaN(top) ? (top = 0) : top;
        const numColumns = columns.length - 1;
        const numRows = rows.length - 1;
        if (left < 0) left = 0;
        if (left > numColumns) left = numColumns;
        if (top < 0) top = 0;
        if (top > numRows) top = numRows;
        if (left + width > numColumns) width = numColumns - left;
        if (top + height > numRows) height = numRows - top;

        const startCell: any = left + columns.length * top;
        const columnIndices = [];
        const rowIndices = [];

        for (let i = startCell; i <= startCell + width; i++) {
          // allOccupiedCellIndices.push(i);
          columnIndices.push(i);
        }
        for (let i = 0; i <= height; i++) {
          const cellIndex = startCell + columns.length * i;
          // allOccupiedCellIndices.push(i);
          rowIndices.push(cellIndex);
        }
        //left: 1 top:0 width:1 height 1 // startCell = 1,2
        let start = startCell;
        for (let i = 0; i <= height; i++) {
          for (let i = start; i <= start + width; i++) {
            allOccupiedCellIndices.push(i);
          }
          start += columns.length * (i + 1);
        }
        return { id, startCell, columnIndices, rowIndices };
      });
    const numCellsOccupiedByAreas = allOccupiedCellIndices.length - numAreas;
    const numImplicitGridCells =
      input.length + numCellsOccupiedByAreas - numExplicitGridCells;
    const implicitRows = Math.ceil(numImplicitGridCells / columns.length);
    numAllRows += implicitRows;
    // returns columns with parsed & calculated pixel values
    const parsedGridColumns: any = parseInput(columns, 'width');

    // returns parsedGridRows with parsed & calculated pixel values
    const parsedGridRows: any = parseInput(rows, 'height');
    // all indices && indices of width & height separate

    if (numImplicitGridCells > 0) {
      for (let i = 0; i < implicitRows; i++) {
        parsedGridRows.push(parsedGridRows[parsedGridRows.length - 1]);
      }
    }
    const gridCells = [];
    const lastContainerBorders = {
      x: 0,
      y: 0,
    };
    let indexAllCells = -1;
    // General Rule: auto tries to always fit it the element & auto tries to be as small as possible !
    // Recalculate auto for cells with grid areas
    // find biggest auto element
    // take respected grid area out of calc for auto
    // if new calc auto value + cell values of grid area < size of grid area =>
    // else auto = size grid area - cell values of grid area => set auto
    // Where to calculate ?
    // How to deal with multiple elements with grid areas & auto
    let elementIndex = 0;
    parsedGridRows.forEach((column, indexColumns) => {
      const height = parsedGridRows[indexColumns];
      parsedGridColumns.forEach((row, indexRows) => {
        indexAllCells++;
        const widthIsAuto = allAutoCellIndices.column.includes(indexAllCells);
        //check if cell is set to auto, if yes const element expand infinitly
        const heightIsAuto = allAutoCellIndices.row.includes(indexAllCells);
        const width = parsedGridColumns[indexRows];

        gridCells.push({
          widthIsAuto,
          heightIsAuto,
          lastContainerBorders: clone(lastContainerBorders),
          width,
          height,
          position: {
            x: width / 2 + lastContainerBorders.x - gridWidth * 0.5,
            y: -(height / 2 + lastContainerBorders.y) + gridHeight * 0.5,
            z: 0,
          },
          sizeConstraints: {
            minWidth: widthIsAuto ? 0 : width,
            minHeight: heightIsAuto ? 0 : height,
            minDepth: 0,
            maxWidth: widthIsAuto ? Infinity : width,
            maxHeight: heightIsAuto ? Infinity : height,
            maxDepth: 0,
          },
        });
        lastContainerBorders.x += width;
        elementIndex += 1;
      });
      lastContainerBorders.x = 0;
      lastContainerBorders.y += height;
    });
    let freeCellsIndex = 0;
    return input.map((e, index) => {
      let width = 0;
      let height = 0;
      let position = null;
      let sizeConstraints = null;
      if (e.modules?.placement) {
        const widthIsAuto = false;
        const heightIsAuto = false;
        const occupiedCells = cellsOccupiedByAreas.find(
          (element) => element.id == e.id,
        );
        const startCell = gridCells[occupiedCells.startCell];
        width = (() => {
          const cellValues = gridCells.filter((cell, index) =>
            occupiedCells.columnIndices.includes(index),
          );
          const width = cellValues.map((cell) => {
            //if any of the cells occupied by the area has auto, the whole areas sizeConstraints have to change to auto
            if (cell.heightIsAuto) heightIsAuto;
            if (cell.widthIsAuto) widthIsAuto;
            return cell.width;
          });
          const sum = width.reduce((a, b) => a + b);
          return sum;
        })();
        height = (() => {
          const cellValues = gridCells
            .filter((cell, index) => occupiedCells.rowIndices.includes(index))
            .map((cell) => {
              if (cell.heightIsAuto) heightIsAuto;
              if (cell.widthIsAuto) widthIsAuto;
              return cell.height;
            });
          const sum = cellValues.reduce((a, b) => a + b);
          return sum;
        })();
        position = {
          x: width / 2 + startCell.lastContainerBorders.x - gridWidth * 0.5,
          y:
            -(height / 2 + startCell.lastContainerBorders.y) + gridHeight * 0.5,
          z: 0,
        };
        sizeConstraints = {
          minWidth: widthIsAuto ? 0 : width,
          minHeight: heightIsAuto ? 0 : height,
          minDepth: 0,
          maxWidth: widthIsAuto ? width : width,
          maxHeight: heightIsAuto ? height : height,
          maxDepth: 0,
        };
      } else {
        const freeGridLocations = gridCells.filter(
          (cell, index) => !allOccupiedCellIndices.includes(index),
        );
        const cell = freeGridLocations[freeCellsIndex];
        height = cell.height;
        width = cell.width;
        position = cell.position;
        sizeConstraints = cell.sizeConstraints;
        freeCellsIndex += 1;
      }
      return {
        location: Location3D.addDefaults({ position }),
        sizeConstraints,
      };
    });
  };
