import { ComponentConfig, ComponentInstance } from '../../../core/types';
import * as Location3D from '@/utils/location3D';
import { ExactSizeConstraints } from './../size';

const widthBreakpoints = [512, 768, 1024, 1536, 2048];
const heightBreakpoints = [800, 1600, 2000];
const getNextLargerBreakpoint = (size, breakpoints) => {
  return (
    breakpoints.find((breakpoint) => breakpoint > size) ||
    breakpoints[breakpoints.length - 1]
  );
};

export const slidesArranger =
  (vm: ComponentInstance) =>
  (input: ComponentConfig[], _slot: string = '') => {
    const areaSize: any = () => {
      const width = getNextLargerBreakpoint(
        vm.viewport.width,
        widthBreakpoints,
      );
      const height = getNextLargerBreakpoint(
        vm.viewport.height,
        heightBreakpoints,
      );
      return {
        width,
        height,
      };
    };
    const { width: areaWidth, height: areaHeight } = areaSize();
    const elementWidth = Math.min(vm.viewport.width, areaWidth);
    const elementHeight = Math.min(vm.viewport.height, areaHeight);
    const totalWidth = areaWidth * input.length;

    return input.map((_element, index) => {
      const sizeConstraints = ExactSizeConstraints({
        width: elementWidth,
        height: elementHeight,
        depth: 0,
      });

      return {
        location: Location3D.addDefaults({
          position: {
            x: index * areaWidth - 0.5 * totalWidth + 0.5 * areaWidth,
          },
          rotation: {},
        }),
        sizeConstraints,
      };
    });
  };
