import { ColorDescriptor, Id, TypeMeta } from './../../common/index';
import { ModuleTypeMeta } from './../../common';

export const defaultId = 'default';
type ActionTypeKeys =
  | 'action.breakoutRooms'
  | 'action.button'
  | 'action.textfield'
  | 'action.stepper'
  | 'action.roleManager'
  | 'action.propertiesManager'
  | 'action.listManager'
  | 'action.workspace'
  | 'action.multiSelect'
  | 'action.mediaControls';

export interface ActionType<Key extends ActionTypeKeys> {
  type: Key;
  name: string;
  id?: string;
  slot?: ActionSlot;
  focused?: LocationRestriction;
  roles?: string | string[];
  script?: string;
  condition?: string | boolean;
  context?: Record<string, any>;
  icon?: string;
}

const actionSlots = {
  top: { title: 'action.slots.top' },
  right: { title: 'action.slots.right' },
  bottom: { title: 'action.slots.bottom' },
  left: { title: 'action.slots.left' },
};

export type ActionSlot = keyof typeof actionSlots;

const locationRestrictions = {
  focused: { title: 'action.locationRestrictions.focused' },
  station: { title: 'action.locationRestrictions.station' },
  always: { title: 'action.locationRestrictions.always' },
};

export type LocationRestriction = keyof typeof locationRestrictions;

export interface ActionTypeMeta<T extends ActionType<ActionTypeKeys>>
  extends TypeMeta<T> {
  type: T['type'];
  category?: TActionCategories;
}

const ActionCategories = {
  managers: 'managers',
  controls: 'controls',
  interactions: 'interactions',
};

export type TActionCategories = keyof typeof ActionCategories;

export interface ActionBreakoutRooms
  extends ActionType<'action.breakoutRooms'> {
  icon: string;
  target: string;
  breakoutRoomTemplate: string;
}

export const ActionBreakoutRoomsMeta: ActionTypeMeta<ActionBreakoutRooms> = {
  type: 'action.breakoutRooms',
  category: 'interactions',
  default: {
    name: 'Button',
    type: 'action.breakoutRooms',
    icon: '',
    target: '',
    breakoutRoomTemplate: '',
  },
};
export interface ActionButton extends ActionType<'action.button'> {
  icon: string;
  color: ColorDescriptor;
  script: string;
}

export const ActionButtonMeta: ActionTypeMeta<ActionButton> = {
  type: 'action.button',
  category: 'interactions',
  default: {
    name: 'Button',
    type: 'action.button',
    icon: '',
    color: 'rgba(117, 117, 117,1)',
    script: '',
    id: defaultId,
  },
};

interface ActionTextfield extends ActionType<'action.textfield'> {
  placeholder?: string;
  script: string;
  color: ColorDescriptor;
  docId?: string;
  allowLinebreaks?: boolean;
  allowFormatting?: boolean;
}
export const ActionTextfieldMeta: ActionTypeMeta<ActionTextfield> = {
  type: 'action.textfield',
  category: 'interactions',
  default: {
    name: 'Textfield',
    type: 'action.textfield',
    script: '',
    id: defaultId,
    color: 'rgba(117, 117, 117,1)',
    allowLinebreaks: false,
    allowFormatting: false,
  },
};

export interface ActionStepper extends ActionType<'action.stepper'> {}

export const ActionStepperMeta: ActionTypeMeta<ActionStepper> = {
  type: 'action.stepper',
  category: 'controls',
  name: 'Button',
  default: {
    name: 'Stepper',
    type: 'action.stepper',
    id: defaultId,
  },
};

export interface ActionWorkspace extends ActionType<'action.workspace'> {}

export const ActionWorkspaceMeta: ActionTypeMeta<ActionWorkspace> = {
  type: 'action.workspace',
  category: 'controls',
  name: 'Button',
  default: {
    name: 'Workspace',
    type: 'action.workspace',
  },
};

interface ActionRoleManager extends ActionType<'action.roleManager'> {
  context: { documentId: Id };
  icon?: string;
  color: string;
  size: string;
}
export const ActionRoleManagerMeta: ActionTypeMeta<ActionRoleManager> = {
  type: 'action.roleManager',
  category: 'managers',
  default: {
    name: 'Role Manager',
    type: 'action.roleManager',
    context: { documentId: '' },
    icon: '',
    color: 'rgba(117, 117, 117,1)',
    size: 'large',
    id: defaultId,
  },
};

interface ActionPropertiesManager
  extends ActionType<'action.propertiesManager'> {
  context: { documentId: Id };
  icon?: string;
  color: string;
  size: string;
}
export const ActionPropertiesManagerMeta: ActionTypeMeta<ActionPropertiesManager> =
  {
    type: 'action.propertiesManager',
    category: 'managers',
    default: {
      name: 'Properties Manager',
      type: 'action.propertiesManager',
      context: { documentId: '' },
      icon: '',
      color: 'rgba(117, 117, 117,1)',
      size: 'large',
      id: defaultId,
    },
  };

interface ActionListManager extends ActionType<'action.listManager'> {
  context: { documentId: Id };
  icon?: string;
  color: string;
  size: string;
}

export const ActionListManagerMeta: ActionTypeMeta<ActionListManager> = {
  type: 'action.listManager',
  category: 'managers',
  default: {
    name: 'List Manager',
    type: 'action.listManager',
    context: { documentId: '' },
    icon: '',
    color: 'rgba(117, 117, 117,1)',
    size: 'large',
    id: defaultId,
  },
};

interface ActionMultiSelect extends ActionType<'action.multiSelect'> {
  context: { documentId: Id };
  icon?: string;
  color: string;
  size: string;
  items: Array<any> | string;
  multiple: boolean | string;
  title: string;
  maxOptions?: number | string;
  id?: string;
}
export const ActionMultiSelectMeta: ActionTypeMeta<ActionMultiSelect> = {
  type: 'action.multiSelect',
  category: 'interactions',
  default: {
    name: 'Multi Select',
    type: 'action.multiSelect',
    context: { documentId: '' },
    icon: '',
    color: 'rgba(117, 117, 117, 1)',
    size: 'large',
    items: ':[]',
    multiple: ':false',
    title: 'Umfrage',
    id: defaultId,
  },
};

interface ActionMediaControls extends ActionType<'action.mediaControls'> {
  icon?: string;
  color: string;
  size: string;
  id?: string;
  mediaType: string;
}
export const ActionMediaControlsMeta: ActionTypeMeta<ActionMediaControls> = {
  type: 'action.mediaControls',
  category: 'interactions',
  default: {
    name: 'Media Controls',
    type: 'action.mediaControls',
    icon: 'play',
    color: 'rgba(117, 117, 117, 1)',
    size: 'large',
    slot: 'bottom',
    id: defaultId,
    mediaType: '',
  },
};

export type Action =
  | ActionBreakoutRooms
  | ActionButton
  | ActionTextfield
  | ActionStepper
  | ActionRoleManager
  | ActionPropertiesManager
  | ActionListManager
  | ActionWorkspace
  | ActionMultiSelect
  | ActionMediaControls;

export interface ModuleActions {
  actions: Action[];
}

export interface ModuleActionsTypeMeta extends ModuleTypeMeta<ModuleActions> {
  types: {
    [key in ActionTypeKeys]: ActionTypeMeta<ActionType<key>>;
  };
}

export const ModuleActionsMeta: ModuleActionsTypeMeta = {
  schema: {
    actionSlots,
    locationRestrictions,
  },
  types: {
    'action.breakoutRooms': ActionBreakoutRoomsMeta,
    'action.button': ActionButtonMeta,
    'action.textfield': ActionTextfieldMeta,
    'action.stepper': ActionStepperMeta,
    'action.roleManager': ActionRoleManagerMeta,
    'action.propertiesManager': ActionPropertiesManagerMeta,
    'action.listManager': ActionListManagerMeta,
    'action.workspace': ActionWorkspaceMeta,
    'action.multiSelect': ActionMultiSelectMeta,
    'action.mediaControls': ActionMediaControlsMeta,
  },
  default: { actions: [] },
};
