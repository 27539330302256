







































import { FillipCommands } from '@/features/main/core/types';
import {
  defineComponent,
  computed,
  getCurrentInstance,
} from '@vue/composition-api';

export default defineComponent({
  name: 'InputSelect',
  props: {
    value: {
      type: [String, Array],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: [Array, Object],
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, { emit }) {
    const vm = (getCurrentInstance() as any).proxy as Required<FillipCommands>;

    const allowMultiple = computed(() => {
      if (props.multiple) return props.multiple;
      if (props.options?.multiple) return props.options?.multiple;
      return false;
    });

    const extractValue = (value: any) => {
      const result =
        typeof value === 'string'
          ? value
          : value.info?.title || value.title || value.text || value.name;
      return result.includes('.') ? vm.$t(result) : result;
    };

    const extractKeysAndValues = (
      input: [string, Record<string, any>] | Record<string, any>,
    ) => {
      const value = Array.isArray(input) ? input[1] : input;
      const key = Array.isArray(input)
        ? input[0]
        : value.key || value.value || value.id;
      return {
        value: key,
        text: extractValue(value) || key,
      };
    };

    const availableItems = computed(() => {
      if (props.items && props.options.items) {
        console.error(
          'InputSelect: Declared items via items prop and via options',
        );
      }
      const items = props.items || props.options.items || [];
      return (Array.isArray(items) ? items : Object.entries(items)).map(
        extractKeysAndValues,
      );
    });

    const onBlur = ($event) => {
      emit('blur', $event);
    };
    const onInput = ($event) => {
      emit('input', $event);
    };
    const onChange = ($event) => {
      emit('change', $event);
    };
    const onFocus = ($event) => {
      emit('focus', $event);
    };

    return {
      allowMultiple,
      availableItems,
      onBlur,
      onInput,
      onChange,
      onFocus,
    };
  },
});
