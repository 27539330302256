import { ModuleTypeMeta, DataId } from '../../common';

export interface ModuleParticipantCircle {
  circle: DataId;
}

export const ModuleParticipantCircleMeta: ModuleTypeMeta<ModuleParticipantCircle> =
  {
    restrictedToTags: ['participant'],
    default: {
      circle: 'main',
    },
  };
