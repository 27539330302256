export interface ErrorInstance {
  type: string;
  source: string;
  meta?: Record<string, any>;
  context?: Record<string, any>;
}

export class FillipError {
  constructor(public error) {
    console.log('fillip error constructor with error: ', error);
  }

  get meta() {
    return this.error.meta || {};
  }

  get context() {
    return this.error.context || {};
  }

  get messageKey() {
    return this.error.type;
  }

  getHttpStatus() {
    return 400;
  }

  toJSON() {
    return {
      error: this.error,
      $$$fillipError: true,
    };
  }
}
