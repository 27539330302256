





























import Vue from 'vue';
import { BaseDocumentEditor } from '@/features/editor/base/BaseDocumentEditor';
import InputEmbed from '@/components/input/embed/InputEmbed.vue';
import { ElementEmbed } from '@/features/main/elements/ElementEmbed';
import { TypeEmbed } from '@fillip/api';

export default Vue.extend({
  name: 'BlockEditorEmbed',
  components: {
    InputEmbed,
    ElementEmbed,
  },
  mixins: [BaseDocumentEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      options: {
        placeholder: this.$t('list.placeholder'),
      },
    };
  },
  computed: {
    contentAsModule() {
      if (!this.embedSrc) return null;
      return {
        element: {
          embedSrc: this.buffer.info.content.embedSrc,
          embedCode: this.buffer.info.content.embedCode,
        },
      };
    },
    embedSrc() {
      return this.buffer?.info?.content?.embedSrc;
    },
  },
  watch: {
    embedSrc: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!newValue) {
          this.isEditing = true;
        }
      },
    },
  },
  methods: {
    updateContent(newContent: TypeEmbed) {
      this.buffer.info.title = newContent.embedMeta?.meta?.title || 'Embed';
      this.buffer.info.content = newContent;
      this.immediateSave('info');
    },
    toggleEditing() {
      if (this.isEditing) {
        this.immediateSave('info');
      }
      this.isEditing = !this.isEditing;
    },
  },
});
