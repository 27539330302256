import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    streams: {
      camera: {
        device: null,
        msConfig: null,
        maxResolutionLayer: 3,
      },
      microphone: {
        device: null,
        msConfig: null,
      },
      screen: {},
    },
    speaker: { device: null, active: true },
    listeners: {},
    audioMuted: false,
  },
  mutations: {
    SET_STREAMING_DEVICE(state, { type, device }) {
      if (state.streams[type].device == device) return;
      state.streams[type].device = device;
    },
    SET_DEVICE(state, { type, device }) {
      if (state[type].device == device) return;
      state[type].device = device;
    },
    REMOVE_DEVICE(state, { type }) {
      state[type].device = null;
    },
    ADD_PARTICIPANT_LISTENER(state, participantId) {
      if (!state.listeners[participantId]) {
        Vue.set(state.listeners, participantId, 1);
      } else {
        state.listeners[participantId] += 1;
      }
    },
    REMOVE_PARTICIPANT_LISTENER(state, participantId) {
      state.listeners[participantId] -= 1;
      if (state.listeners[participantId] < 1) {
        Vue.delete(state.listeners, participantId);
      }
    },
    MUTE_VIDEOCONFERENCE(state, shouldBeMuted) {
      state.audioMuted = shouldBeMuted;
    },
    SET_RESOLUTION_LAYER(state, resolution) {
      state.streams.camera.maxResolutionLayer = resolution;
    },
  },
  actions: {
    applyDeviceSettings({ state, commit }, deviceSettings) {
      if (!deviceSettings) return;
      if (
        deviceSettings.camera &&
        deviceSettings.camera != state.streams.camera.device
      ) {
        commit('SET_STREAMING_DEVICE', {
          type: 'camera',
          device: deviceSettings.camera,
        });
      }
      if (
        deviceSettings.microphone &&
        deviceSettings.microphone != state.streams.microphone.device
      ) {
        commit('SET_STREAMING_DEVICE', {
          type: 'microphone',
          device: deviceSettings.microphone,
        });
      }
      if (
        deviceSettings.speaker &&
        deviceSettings.speaker != state.speaker.device
      ) {
        commit('SET_DEVICE', {
          type: 'speaker',
          device: deviceSettings.speaker,
        });
      }
    },
  },
};
