












import Vue from 'vue';
import BaseEditorText from '../base/BaseEditorText.vue';

export default Vue.extend({
  name: 'BlockEditorHeading2',
  components: {
    BaseEditorText,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      styling: { fontSize: '1.8rem', fontWeight: '400' },
    };
  },
});
