










































import Vue from 'vue';
import { BaseDocumentEditor } from '@/features/editor/base/BaseDocumentEditor';
import DOMPurify from 'dompurify';
import { ElementMarkdown } from '@/features/main/elements/ElementMarkdown';
import InputString from '@/components/input/string/InputString.vue';
import InputCode from '@/components/input/code/InputCode.vue';

export default Vue.extend({
  name: 'BlockEditorMarkdown',
  components: {
    ElementMarkdown,
    InputString,
    InputCode,
  },
  mixins: [BaseDocumentEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  computed: {
    contentAsModule() {
      return {
        element: {
          markdownContent: this.buffer.info.content,
        },
      };
    },
  },
  mounted() {
    if (!this.buffer.info.content) {
      this.toggleEditing();
    }
  },
  methods: {
    updateContent() {
      this.buffer.info.content = DOMPurify.sanitize(this.buffer.info.content);
      this.buffer.info.title = this.buffer.info.content
        .split('\n', 1)[0] // take first line, limit split to first match to improve perfomance
        .trim()
        .slice(0, 30);
      this.immediateSave('info');
    },
    toggleEditing() {
      if (this.isEditing) {
        this.immediateSave('info');
      }
      this.isEditing = !this.isEditing;
    },
    async loadExternalContent() {
      const url: string = this.buffer.info.contentFrom;
      if (!url) return;
      if (!url.startsWith('http') || !url.endsWith('md')) {
        this.$store.dispatch('notify', {
          type: 'error',
          text: this.$t('element.markdown.onlyMarkdownSupported'),
        });
        return;
      }
      try {
        const { data } = await this.$fillip.utilities.getFromExternal(url);
        this.buffer.info.content = data;
        this.updateContent();
      } catch (error) {
        console.error('Error getFromExternal', error);
        this.$store.dispatch('notify', {
          type: 'error',
          text: this.$t('element.markdown.loadingFailed'),
        });
      }
    },
  },
});
