import { DataDocument, PresetDefinition } from '@fillip/api';

const EmbedInstance: DataDocument = {
  id: 'EmbedInstance',
  info: {
    title: 'Embed',
    icon: 'window-frame-open',
    toolEditing: {
      preferredArrangement: 'topbar-main',
      allowedChildTypes: ['embed'],
      allowMultipleChildren: false,
      editors: {
        title: {
          id: 'EmbedInstance',
        },
        properties: { id: 'EmbedInstance', title: 'Einstellungen' },
        list: { id: 'EmbedInstance', title: 'Embed' },
      },
    },
  },
  tag: {
    tag: 'template',
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  children: {
    default: [
      {
        templateId: 'tool-embed',
        query: ':getList(id)',
      },
    ],
  },
  list: {
    items: [
      {
        id: 'EmptyEmbed',
      },
    ],
  },
  properties: {
    showTitle: {
      type: 'boolean',
      value: false,
    },
  },
  schema: {
    fields: [],
    ownFields: [
      {
        title: 'Zeige Titel',
        key: 'showTitle',
        type: 'boolean',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'templateParent',
        query: ':id',
      },
      {
        identifier: 'showTitle',
        query: ':properties.showTitle.value',
      },
    ],
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

const EmptyEmbed: DataDocument = {
  id: 'EmptyEmbed',
  info: {
    title: 'Embed',
    icon: 'window-frame-open',
  },
  tag: {
    tag: 'embed',
  },
  parentId: 'EmbedInstance',
};

export const PresetEmbed: PresetDefinition = {
  id: 'PresetEmbed',
  title: 'Embed',
  icon: 'window-frame-open',
  group: 'tools',
  version: '0.1',
  documents: [EmbedInstance, EmptyEmbed],
};
