









import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';

import DOMPurify from 'dompurify';
import InputCode from '@/components/input/code/InputCode.vue';

export default {
  name: 'HtmlEditor',
  components: {
    InputCode,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
    sanitize: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'element',
    });

    return {
      buffer,
      save,
    };
  },
  beforeDestroy() {
    this.$emit('deactivate', this.buffer);
  },
  methods: {
    update() {
      if (this.sanitize) {
        this.buffer.htmlContent = DOMPurify.sanitize(this.buffer.htmlContent);
      }
      this.save();
    },
  },
};
