






























import {
  useData,
  useFillip,
  useI18n,
  useNav,
  useParticipants,
  usePermissions,
  useRouter,
} from '@/composables';
import CircleOverview from './CircleOverview.vue';
import ParticipantOverview from './ParticipantOverview.vue';
import { ActionControl, rootDataId } from '@fillip/api';
import { defineComponent, computed, ref, unref } from '@vue/composition-api';

export default defineComponent({
  name: 'CommunityOverview',
  components: {
    CircleOverview,
    ParticipantOverview,
  },
  setup() {
    const { canSeePrototype, canEditCommunity, canSwitchCommunity } =
      usePermissions();
    const { getData } = useData();
    const { getAvatarUrl } = useParticipants();
    const { $community, $me } = useFillip();
    const { $dialog } = useNav();
    const { router } = useRouter();
    const { tr } = useI18n();

    const currentTitle = computed(() => $community?.value.title || '');

    const openProfile = () => $dialog.push({ name: 'ParticipantProfile' });

    const communityActions = computed<ActionControl[]>(() => {
      return [
        {
          type: 'action',
          icon: 'pen',
          title: tr('communities.editCommunity'),
          if: canEditCommunity.value,
          action: () => $dialog.push({ name: 'CommunityEditor' }),
        },
        {
          type: 'action',
          icon: 'repeat',
          title: tr('communities.switchCommunity'),
          if: canSwitchCommunity.value,
          action: () => router.push({ name: 'Communities' }),
        },
      ];
    });

    const avatar = computed(() => {
      if (!$me) return;
      return getAvatarUrl($me.value);
    });

    const showCircles = computed(() => {
      const rootProperties = getData(rootDataId).properties || {};
      return rootProperties.useCircles?.value || false;
    });

    return {
      getData,
      getAvatarUrl,
      canSeePrototype,
      canEditCommunity,
      canSwitchCommunity,
      avatar,
      showCircles,
      currentTitle,
      communityActions,
      openProfile,
    };
  },
});
