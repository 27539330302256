<template>
  <div>
    <v-text-field
      :id="id"
      ref="BaseTextInput"
      :value="value"
      :label="label"
      :rules="translatedRules"
      :type="type"
      :dense="dense"
      :disabled="disabled"
      :autocomplete="autocomplete"
      required
      :outlined="outlined"
      :filled="filled"
      :solo="solo"
      :dark="dark"
      :placeholder="placeholder"
      :height="height"
      @input="$emit('input', $event)"
      @blur="blur"
      @focus="$emit('focus', $event)"
    >
      <template v-if="icon" #prepend>
        <BaseIcon :icon="icon"></BaseIcon>
      </template>
      <template v-if="appendIcon" #append>
        <div>
          <BaseIcon
            v-if="hint"
            class="mx-2"
            icon="info-circle"
            style="cursor: pointer"
            @click="showHint = !showHint"
          />
        </div>
        <div class="pointer" @click="$emit('appendclick', $event)">
          <BaseIcon :icon="appendIcon"></BaseIcon>
        </div>
      </template>
    </v-text-field>

    <v-expand-transition>
      <div
        v-if="showHint || translatedRules.length > 0"
        class="ml-7 text-caption"
        >{{ hintText }}</div
      >
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'BaseTextInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
    },
    icon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    hint: {
      type: Boolean,
      default: false,
    },
    hintText: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dirty: false,
      showHint: false,
    };
  },
  computed: {
    translatedRules() {
      if (!this.dirty) return [];
      if (!this.rules) return [];
      return this.rules.map((rule) => (input) => {
        const result = rule(input);
        if (typeof result === 'string') return this.$t(result);
        return result;
      });
    },
    toggleHint() {
      if (!this.$refs.BaseTextInput) {
        return false;
      }
      return !this.$refs.BaseTextInput.valid;
    },
  },
  watch: {
    translatedRules() {
      this.$nextTick(() => {
        this.$refs.BaseTextInput.validate();
      });
    },
  },
  methods: {
    blur(event) {
      this.dirty = true;
      this.showHint = false;
      this.$emit('blur', event);
    },
    focus() {
      this.$refs.BaseTextInput.focus();
    },
  },
};
</script>
<style lang="css" scoped>
.pointer {
  cursor: pointer;
}
</style>
