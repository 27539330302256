import { DataDocument, PresetDefinition } from '@fillip/api';

const ContentInstance: DataDocument = {
  id: 'ContentInstance',
  info: {
    title: 'Content',
    icon: 'align-left',
    toolEditing: {
      preferredArrangement: 'side-by-side',
      allowedChildTypes: [
        'h1',
        'h2',
        'h3',
        'h4',
        'text',
        'styledText',
        'markdown',
        'image',
        'embed',
      ],
      allowMultipleChildren: true,
      editors: {
        title: {
          id: 'ContentInstance',
        },
        properties: { id: 'ContentInstance', title: 'Einstellungen' },
        list: { id: 'ContentInstance', title: 'Inhalte' },
      },
    },
  },
  tag: {
    tag: 'template',
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  children: {
    default: [
      {
        templateId: 'tool-content',
        query: ':[data]',
      },
    ],
  },
  list: {
    items: [
      {
        id: 'EmptyContent',
      },
    ],
  },
  properties: {
    verticalCentered: {
      type: 'boolean',
      value: false,
    },
    horizontalCentered: {
      type: 'boolean',
      value: false,
    },
  },
  schema: {
    fields: [],
    ownFields: [
      {
        title: 'Vertikal zentriert',
        key: 'verticalCentered',
        type: 'boolean',
      },
      {
        title: 'Horizontal zentriert',
        key: 'horizontalCentered',
        type: 'boolean',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'templateParent',
        query: ':id',
      },
      {
        identifier: 'verticalCentered',
        query: ':properties.verticalCentered.value == true',
      },
      {
        identifier: 'horizontalCentered',
        query: ':properties.horizontalCentered.value == true',
      },
    ],
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

const EmptyContent: DataDocument = {
  id: 'EmptyContent',
  info: {
    title: 'Überschrift',
    icon: 'h2',
  },
  tag: {
    tag: 'h2',
  },
  parentId: 'ContentInstance',
};

export const PresetContent: PresetDefinition = {
  id: 'PresetContent',
  title: 'Content',
  icon: 'align-left',
  group: 'tools',
  version: '0.1',
  documents: [ContentInstance, EmptyContent],
};
