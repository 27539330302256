import Vue from 'vue';
import { deepEqual } from 'fast-equals';
import {
  RootRoute,
  Route,
  ObjectId,
  withFocus,
  getFocus,
  RouteSegment,
  addPathSegment,
  removeLastPathSegments,
  withoutFocus,
  resolveSceneDefinition,
  parseRoute,
  stringifyRoute,
  parseRouteSegment,
  Id,
  NavigationReason,
  getScene,
} from '@fillip/api';
import { useLocations } from '@/composables';
import { GlobalPropsNode } from '@/plugins/global-props';

export default Vue.extend({
  mixins: [GlobalPropsNode],
  setup() {
    const { getLocation } = useLocations();
    return { getLocation };
  },
  data() {
    return {
      noLocationError: false,
      noLocationTimeout: null,
      lastNavigationReason: null,
      initialized: false,
    };
  },
  computed: {
    route() {
      return (
        this.$store.getters.communityState.locations?.[this.$me?.id]?.route ||
        RootRoute
      );
    },
    sceneDefinitions() {
      return this.$getGlobalProp('sceneDefinitions') || [];
    },
    stations() {
      return this.$getGlobalProp('stations') || [];
    },
    siblingStations() {
      if (!this.activeScene) return null;
      // Remove parent station
      return this.stations.slice(1);
    },
    goToActions() {
      return this.actions?.value.sidebar.filter((action) => {
        return action.displaySlots.includes('Navigation-tree');
      });
    },
    activeSceneObjectId() {
      const scene = getScene(this.route);
      const { templateId, dataId } = resolveSceneDefinition(
        scene,
        this.sceneDefinitions,
      );
      const result = `${templateId}${dataId ? ':' + dataId : ''}`;
      return result;
    },
    activeScene() {
      if (!this.activeSceneObjectId) return null;
      const result = this.stations.find(
        (object) => object.id === this.activeSceneObjectId,
      );
      // console.log('activeScene', this.stations, result);
      return result;
    },
    activeSceneTitle() {
      // TODO:
      // - If is namedSceneDefintion -> Use name
      // If hasFocus -> get Focus Title
      // Get data title
      // Use template title
      return this.activeScene?.title || this.$t('worldNavigation.noLocation');
    },
    focusedId() {
      return getFocus(this.route);
    },
    focusedObjectId() {
      return getFocus(this.route) || this.activeSceneObjectId;
    },
    focusedObject() {
      if (this.focusedObjectId === undefined || this.focusedObjectId === null)
        return null;
      return this.stations.find((object) => object.id === this.focusedObjectId);
    },
    focusedObjectIndex() {
      if (!this.focusedObjectId) return -1;
      return this.siblingStations.findIndex(
        (object) => object.id === this.focusedObjectId,
      );
    },
    focusedObjectTitle() {
      return this.focusedObject?.title || this.$t('worldNavigation.noLocation');
    },
    nextStation() {
      if (!this.siblingStations) return;
      if (
        this.focusedObjectIndex < 0 ||
        this.focusedObjectIndex === this.siblingStations.length - 1
      )
        return;
      return this.siblingStations[this.focusedObjectIndex + 1]?.id;
    },
    previousStation() {
      if (!this.siblingStations) return;
      if (this.focusedObjectIndex <= 0) return;
      return this.siblingStations[this.focusedObjectIndex - 1]?.id;
    },
    hasFocus() {
      return Boolean(getFocus(this.route));
    },
    hasNoLocation() {
      if (this.hasFocus && !this.focusedObject) {
        // console.log('hasNoLocation 1', this.hasFocus, this.focusedObject);
        return true;
      }
      if (!this.activeScene) {
        // console.log('hasNoLocation 2', this.activeScene);

        return true;
      }
      // console.log('Has location');
      return false;
    },
    focusedStation() {
      return this.stations.filter((station) => {
        return station.id === this.focusedObjectId;
      })[0];
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler(newValue, oldValue) {
        // console.log('Router route changed', newValue);
        if (this.lastNavigationReason === NavigationReason.FILLIP) {
          this.lastNavigationReason = null;
          return;
        }
        const { urlPath } = newValue.params;

        if (oldValue && oldValue.params?.urlPath === newValue?.params?.urlPath)
          return;

        const target = urlPath ? parseRoute(urlPath) : RootRoute;
        if (deepEqual(this.route, target)) {
          this.initialized = true;
          return;
        }
        this.lastNavigationReason = NavigationReason.BROWSER;
        await this.goto(target);
        this.initialized = true;
      },
    },
    route: {
      immediate: false,
      handler(newValue) {
        if (!this.initialized) return;
        // console.log('internal route changed', newValue);
        if (this.lastNavigationReason === NavigationReason.BROWSER) {
          this.lastNavigationReason = null;
          return;
        }
        let { slug, urlPath } = this.$route.params; // eslint-disable-line prefer-const
        urlPath = urlPath ? '/' + urlPath : '';
        const target = stringifyRoute(newValue);

        if (urlPath === decodeURIComponent(target)) {
          return;
        }
        this.lastNavigationReason = NavigationReason.FILLIP;
        this.$router.push({
          path: '/' + slug + target,
          query: this.$route.query,
        });
      },
    },
    hasNoLocation: {
      immediate: true,
      handler(newValue) {
        if (!this.initialized) {
          // console.log('Not initialized');
          return;
        }
        // console.log('Has no Location', newValue, this.noLocationError);
        if (newValue && !this.noLocationError) {
          // console.log('No location timeout started');
          this.noLocationError = true;
          this.noLocationTimeout = setTimeout(() => {
            // console.log('No location timeout executed');
            if (this.hasFocus) {
              return this.removeFocus();
            } else {
              return this.gotoParent();
            }
          }, 8000);
        }
        if (!newValue && this.noLocationError) {
          // console.log('No location timeout reset');
          this.noLocationError = false;
          clearTimeout(this.noLocationTimeout);
          this.noLocationTimeout = null;
        }
      },
    },
  },
  methods: {
    async focus(objectId: ObjectId, participantId: Id = this.$me.id) {
      const newRoute = withFocus(
        this.getParticipantRoute(participantId),
        objectId,
      );
      return this.goto(newRoute, participantId);
    },
    async removeFocus(participantId: Id = this.$me.id) {
      const newRoute = withoutFocus(this.getParticipantRoute(participantId));
      return this.goto(newRoute, participantId);
    },
    async focusNext(
      moveToParent: boolean = false,
      participantId: Id = this.$me.id,
    ) {
      if (!this.nextStation && moveToParent)
        return this.removeFocus(participantId);
      if (!this.hasFocus) return this.focusFirst(participantId);
      return this.focus(this.nextStation, participantId);
    },
    async focusPrevious(
      returnToParent: boolean = false,
      participantId: Id = this.$me.id,
    ) {
      if (!this.previousStation && returnToParent)
        return this.removeFocus(participantId);
      return this.focus(this.previousStation, participantId);
    },
    async focusFirst(participantId: Id = this.$me.id) {
      if (!this.siblingStations || this.siblingStations.length < 1) return;
      if (this.focusedObjectIndex === 0) return;
      return this.focus(this.siblingStations[0].id, participantId);
    },
    async focusLast(participantId: Id = this.$me.id) {
      if (!this.siblingStations || this.siblingStations.length < 1) return;
      if (this.focusedObjectIndex === this.siblingStations.length - 1) return;
      return this.focus(
        this.siblingStations[this.siblingStations.length - 1].id,
        participantId,
      );
    },
    async goto(route: Route | string, participantId: Id = this.$me.id) {
      if (typeof route === 'string') {
        route = parseRoute(route);
      }

      if (
        deepEqual(route, this.getParticipantRoute(participantId)) &&
        !deepEqual(route, RootRoute)
      )
        return;
      return this.$invoke('participantSetRoute', participantId, route);
    },
    async gotoChild(
      segment: RouteSegment | string,
      participantId: Id = this.$me.id,
    ) {
      return this.goto(
        addPathSegment(this.route, this.resolveSegment(segment)),
        participantId,
      );
    },
    async gotoParent(participantId: Id = this.$me.id) {
      const route = this.getLocation(participantId);
      const target = removeLastPathSegments(route || this.route);

      return this.goto(target, participantId);
    },
    async goUpParents(steps: number) {
      return this.goto(removeLastPathSegments(this.route, steps));
    },
    async gotoSibling(segment: RouteSegment | string) {
      const target = removeLastPathSegments(this.route);

      return this.goto(addPathSegment(target, this.resolveSegment(segment)));
    },
    async gotoRoot() {
      return this.goto(RootRoute);
    },
    resolveSegment(segment: RouteSegment | string): RouteSegment {
      if (typeof segment === 'string') {
        return parseRouteSegment(segment);
      }
      return segment;
    },
    getParticipantRoute(participantId: Id) {
      return participantId === this.$me.id
        ? this.route
        : this.getLocation(participantId);
    },
    // TODO: Move to composable, merge wih activeSceneTitle
    getLocationTitle(route: Route) {
      const focusedObject = getFocus(route);

      if (focusedObject) {
        return (
          this.$getGlobalProp('stations').find(
            (object) => object.id === focusedObject,
          )?.title || ''
        );
      }

      const scene = getScene(route);
      if (!scene) return '';

      const { dataId, templateId } = resolveSceneDefinition(
        scene,
        this.sceneDefinitions,
      );

      return dataId
        ? this.getData(dataId).info.title
        : this.getData(templateId).info.title;
    },
  },
  render() {
    return null;
  },
});
