import { DataDocument, PresetDefinition, rootDataId } from '@fillip/api';

export const FunctionTemplate: DataDocument = {
  id: 'FunctionTemplateId',
  info: {
    title: 'Template: Stepper',
  },
  tag: {
    tag: 'template',
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  children: {
    default: [{ templateId: 'function:stepper', query: ":get('StepperId')" }],
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

export const Stepper: DataDocument = {
  id: 'StepperId',
  info: {
    title: 'Stepper',
  },
  tag: {
    tag: 'collection',
  },
  list: { items: [{ id: 'ToolsId' }, { id: 'PhasesId' }] },
  properties: {
    phase: {
      type: 'number',
      value: 0,
    },
    phases: {
      type: 'id',
      value: 'PhasesId',
    },
    tools: {
      type: 'id',
      value: 'ToolsId',
    },
    library: {
      type: 'string',
      value: '',
    },
  },
  roles: {
    roles: {
      host: {
        key: 'host',
        title: 'Hosts',
        documents: [],
      },
    },
  },
};

export const Phases: DataDocument = {
  id: 'PhasesId',
  parentId: 'StepperId',
  info: {
    title: 'Phases',
  },
  tag: {
    tag: 'collection',
  },
  list: {
    items: [
      {
        id: 'Phase_0Id',
      },
      {
        id: 'Phase_1Id',
      },
    ],
  },
};

export const Tools: DataDocument = {
  id: 'ToolsId',
  parentId: 'StepperId',
  info: {
    title: 'Tools',
  },
  tag: {
    tag: 'collection',
  },
  list: {
    items: [
      {
        id: 'Tool_0Id',
      },
      {
        id: 'Tool_1Id',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'Tool_0Id',
        query: ':[data]',
      },
      {
        templateId: 'Tool_1Id',
        query: ':[data]',
      },
    ],
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

export const Tool_0: DataDocument = {
  id: 'Tool_0Id',
  parentId: 'ToolsId',
  info: {
    title: 'tool-0',
  },
  list: {
    items: [],
  },
  tag: {
    tag: 'template',
  },
  element: {
    type: 'element.text',
    textContent: 'tool 1',
    textFormat: 'p',
  },
  properties: {},
};
export const Tool_1: DataDocument = {
  id: 'Tool_1Id',
  parentId: 'ToolsId',
  info: {
    title: 'tool-1',
  },
  list: {
    items: [],
  },
  tag: {
    tag: 'template',
  },
  element: {
    type: 'element.text',
    textContent: 'tool 2',
    textFormat: 'p',
  },
  properties: {},
};

export const Phase_0: DataDocument = {
  id: 'Phase_0Id',
  parentId: 'PhasesId',
  info: {
    title: 'phase 0',
  },
  tag: {
    tag: 'page',
  },
  list: {
    items: [],
  },
  sheet: {
    orientTowardsCamera: false,
  },
  properties: {
    layout: {
      type: 'string',
      value: 'side-by-side',
    },
    'tool-0': {
      type: 'id',
      value: 'Tool_0Id',
    },
    'tool-1': {
      type: 'id',
      value: 'Tool_1Id',
    },
  },
};
export const Phase_1: DataDocument = {
  id: 'Phase_1Id',
  parentId: 'PhasesId',
  info: {
    title: 'phase 1',
  },
  tag: {
    tag: 'page',
  },
  list: {
    items: [],
  },
  sheet: {
    orientTowardsCamera: false,
  },
  properties: {
    layout: {
      type: 'string',
      value: 'side-by-side',
    },
    'tool-0': {
      type: 'id',
      value: 'Tool_1Id',
    },
    'tool-1': {
      type: 'id',
      value: 'Tool_0Id',
    },
  },
};

export const PresetStepper: PresetDefinition = {
  id: 'StepperId',
  title: 'Stepper',
  icon: 'album',
  group: 'templates',
  version: '0.1',
  documents: [
    FunctionTemplate,
    Stepper,
    Phases,
    Phase_0,
    Phase_1,
    Tools,
    Tool_0,
    Tool_1,
  ],
};
