




















































































import Vue from 'vue';
import { useSave, useData } from '@/composables';
import { useTools } from './useTools';
import { computed, toRef } from '@vue/composition-api';
import ArrangementEditor from '@/features/editor/modules/ArrangementEditor.vue';
import TitleEditor from '@/features/editor/modules/TitleEditor.vue';
import { DefaultLayouts } from '@fillip/api';
import PresetSelector from '../explorer/PresetSelector.vue';

export default Vue.extend({
  name: 'PhaseEditor',
  components: {
    TitleEditor,
    PresetSelector,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, data, save } = useSave(props, {
      path: 'properties',
      defaultValue: {
        layout: {
          type: 'string',
          value: 'single',
        },
      },
      convertToBuffer: (input) => {
        return {
          layout: {
            type: 'string',
            value: 'single',
          },
          ...input,
        };
      },
    });
    const { getData } = useData();
    const toolId = toRef(props, 'parentId');
    const { toolsFolder, toolsList, addTool } = useTools(toolId);

    const layouts = Object.entries(DefaultLayouts).map(
      ([value, { title: text }]) => {
        return {
          value,
          text,
        };
      },
    );

    const activeLayout = computed(() => {
      return (
        DefaultLayouts[buffer.value.layout?.value] || DefaultLayouts.single
      );
    });

    const updateLayout = (layout) => {
      Vue.set(buffer.value, 'layout', {
        type: 'string',
        value: layout,
      });
      save();
    };

    const tools = computed(() =>
      [0, 1, 2, 3]
        .map((index) => 'tool-' + index)
        .map((prop) => buffer.value[prop]?.value)
        .map((id) => getData(id)),
    );

    const setPhaseTool = (index, id) => {
      Vue.set(buffer.value, 'tool-' + index, {
        type: 'id',
        value: id,
      });

      save();
    };

    const removePhaseTool = (index) => {
      Vue.delete(buffer.value, 'tool-' + index);
      save();
    };

    const createToolAndAddToPhase = async (index) => {
      const { id } = await addTool();
      setPhaseTool(index, id);
      return id;
    };

    return {
      layouts,
      data,
      buffer,
      save,
      activeLayout,
      updateLayout,
      tools,
      setPhaseTool,
      toolsList,
      toolsFolder,
      removePhaseTool,
      createToolAndAddToPhase,
    };
  },
  computed: {
    layoutStyle() {
      const { gridRows: gridTemplateRows, gridColumns: gridTemplateColumns } =
        this.activeLayout.arrangement;
      return {
        gridTemplateRows,
        gridTemplateColumns,
      };
    },
  },
  methods: {
    async createToolAndSelect(index) {
      const id = await this.createToolAndAddToPhase(index);
      this.$emit('select', id);
    },
    async presetAdded({ createdDocuments }, index) {
      const id = createdDocuments[0].id;
      this.setPhaseTool(index - 1, id);
      this.$emit('select', id);
    },
  },
});
