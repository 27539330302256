<template>
  <div>
    <v-menu
      origin="bottom center"
      transition="slide-y-reverse-transition"
      absolute
      top
      offset-y
      content-class="elevation-0 blurred-glass-container"
    >
      <template #activator="{ attrs, on: menu }">
        <BaseButton
          :class="{
            'v-list-item--active': status && control.type == 'toggle',
          }"
          v-bind="attrs"
          :icon="icon"
          :size="buttonStyle == 'flat' ? 'icon' : ''"
          :flat="buttonStyle == 'flat'"
          :title="$t(control.title)"
          :color="iconColor"
          v-on="menuComponent ? menu : ''"
          @click="triggerAction(control)"
        />
      </template>
      <component
        :is="menuComponent"
        v-if="menuComponent"
        :control="control"
        :status="status"
      />
    </v-menu>
  </div>
</template>

<script>
import ActionListControl from './CommonControls/ActionListControl';
import SelectListControl from './CommonControls/SelectListControl';
export default {
  name: 'ControlButton',
  components: { ActionListControl, SelectListControl },
  props: {
    control: {
      type: Object,
      required: true,
    },
    buttonStyle: {
      type: String,
      default: 'regular',
    },
  },
  data() {
    return {
      controlButton: null,
    };
  },
  computed: {
    menuComponent() {
      switch (this.control.type) {
        case 'actionList':
          return ActionListControl;
        case 'selectList':
          return SelectListControl;
        case 'custom':
        default:
          if (this.control.component) {
            return this.control.component;
          }
          return null;
      }
    },
    status() {
      return this.control.statusQuery ? this.control.statusQuery() : null;
    },
    icon() {
      if (this.status != undefined && this.status != null) {
        const option = this.control.options.find(
          (option) => option.value == this.status,
        );
        return option ? option.icon : this.control.iconDefault.icon;
      }
      return this.control.iconDefault && this.control.iconDefault.icon
        ? this.control.iconDefault.icon
        : 'question-circle';
    },
    iconColor() {
      // TODO: find a more elegant solution and figure out why the using the 'secondary' color did cause problems
      if (this.status == 'beRightBack') return 'warning';
      else if (this.status == 'busy') return '#86124c';
      else if (this.status == 'needHelp') return '#ff5252';
      else {
        return 'grey';
      }
    },
  },
  methods: {
    triggerAction(control) {
      if (control.action && ['trigger', 'toggle'].includes(control.type)) {
        control.action.bind(this)(this.status);
      } else return;
    },
  },
};
</script>
