import {
  CommandResponse,
  MediasoupRequests,
  VmRequests,
  useLogger,
  LogLevels,
} from '@fillip/api';
import { Repository, BaseRepository, Commit } from '@fillip/json-db';
import { nanoid } from 'nanoid/non-secure';
import WebSocket from 'isomorphic-ws';
import { EventEmitter } from 'eventemitter3';

export interface WebSocketRequest {
  resolve(payload: any): void;
  reject(error: any): void;
  requestId: string;
  timestamp: number;
}

const HEARTBEAT_INTERVAL = 1000;

const logger = useLogger(LogLevels.INFO, LogLevels.NONE, 'wsClient');

export class FillipWsClient extends EventEmitter {
  public mediasoup: MediasoupRequests;
  public vm: VmRequests;

  public id = nanoid();
  public ws: WebSocket = null;
  public requests = new Map<string, WebSocketRequest>();
  public transactionListeners = [] as Array<{
    rev: number;
    resolve: () => void;
  }>;
  public requestHandler?: (data: Record<string, any>) => any;
  public heartBeatInterval: any = null;

  constructor(
    public connection: { url: string; jwt: string; communitySlug: string },
    public repository: BaseRepository = new Repository('wsClient'),
  ) {
    super();

    this.mediasoup = this.createWrapper<MediasoupRequests>('mediasoup::');
    this.vm = this.createWrapper<VmRequests>('vm::');
  }

  public async connect(): Promise<CommandResponse> {
    try {
      this.ws = await this.createWs(this.connection.url);

      // Works with request
      logger.debug('connecting', this.connection.communitySlug);
      const result = this.$requestAndSync('connect', {
        jwt: this.connection.jwt,
        communitySlug: this.connection.communitySlug,
        webSocketId: this.id,
      });
      this.heartBeatInterval = setInterval(() => {
        this.$emit('heartbeat', {});
      }, HEARTBEAT_INTERVAL);
      this.ws.addEventListener('close', () => {
        clearInterval(this.heartBeatInterval);
        this.emit('close');
      });
      return await result;
    } catch (error) {
      if (this.ws) {
        this.ws.close();
      }
      throw error;
    }
  }

  public disconnect(): Promise<void> {
    if (this.ws.readyState == WebSocket.OPEN) {
      return new Promise((resolve) => {
        this.ws.onclose = () => resolve();
        this.ws.close(1000);
      });
    } else if (this.ws.readyState == WebSocket.CONNECTING) {
      return new Promise((resolve) => {
        this.ws.onopen = () => {
          this.ws.onclose = () => resolve();
          this.ws.close(1000);
        };
      });
    }

    return Promise.resolve();
  }

  public $emit(event: string, data: Record<string, any>): void {
    this.ws.send(
      JSON.stringify({
        event,
        data,
      }),
    );
  }

  public createWrapper<T>(prefix = ''): T {
    return new Proxy(
      {},
      {
        get: (target: unknown, prop: string) => {
          return (...args: any[]): any => {
            return this.$requestAndSync('command', {
              args,
              command: prefix + prop,
            });
          };
        },
      },
    ) as T;
  }

  public $request(event: string, data: Record<string, any>): Promise<any> {
    logger.debug(`Request ${event}`, data.command);
    return new Promise<any>((resolve, reject) => {
      if (this.ws.readyState != this.ws.OPEN) {
        reject(
          new Error(
            `Websocket is currently in readyState ${this.ws.readyState} and unable to accept requests!`,
          ),
        );
        return;
      }
      const requestId = nanoid();
      const request: WebSocketRequest = {
        requestId,
        resolve,
        reject,
        timestamp: Date.now(),
      };

      this.requests.set(requestId, request);
      this.ws.send(
        JSON.stringify({
          event,
          data: { ...data, requestId },
        }),
      );
    });
  }

  public async $requestAndSync(
    event: string,
    data: Record<string, any>,
  ): Promise<any> {
    const response = await this.$request(event, data);
    if (!response || !response.rev) return response;
    await this.waitForTransaction(response.rev);
    return response;
  }

  public waitForTransaction(rev: number): Promise<void> {
    if (this.repository.rev >= rev) return Promise.resolve();

    return new Promise((resolve) => {
      this.transactionListeners.push({ rev, resolve });
    });
  }

  public onRequest(requestHandler: (data: Record<string, any>) => any) {
    this.requestHandler = requestHandler;
  }

  public async createWs(url: string): Promise<WebSocket> {
    return new Promise((resolve, reject) => {
      const ws = new WebSocket(url);
      ws.onerror = ($event) => {
        reject($event.error);
      };
      ws.onopen = () => {
        ws.onerror = ($event) => {
          this.onError($event.error);
        };
        ws.onmessage = ($event) => {
          this.onMessage($event.data);
        };
        ws.onclose = () => {
          this.requests.forEach((request) => {
            request.reject(
              new Error(
                'Web Socket connection closed before response was received',
              ),
            );
          });
        };
        resolve(ws);
      };
    });
  }

  public onError(error: any): void {
    logger.error(error);
    this.requests.forEach((req) => req.reject(error));
  }

  public resolveListeners(rev: number): void {
    if (this.transactionListeners.some((listener) => listener.rev <= rev)) {
      const newListeners = [];
      const resolvableListeners = [];
      for (const listener of this.transactionListeners) {
        if (listener.rev <= rev) {
          resolvableListeners.push(listener);
        } else {
          newListeners.push(listener);
        }
      }
      this.transactionListeners = newListeners;
      resolvableListeners.forEach((listener) => listener.resolve());
    }
  }

  public async onMessage(buffer: WebSocket.Data): Promise<void> {
    const { event, data } = JSON.parse(String(buffer));

    logger.debug('Websocket Message: ', event, data);

    if (event == 'response') {
      const { requestId, response } = data;
      if (response && response.result && response.result.error) {
        this.requests.get(requestId).reject(response.result.error);
      } else {
        this.requests.get(requestId).resolve(response);
      }
    } else if (event == 'error') {
      const { requestId, error } = data;
      this.requests.get(requestId).reject(error);
    } else if (event == 'request') {
      const { requestId, ...payload } = data;
      if (this.requestHandler) {
        try {
          const response = await this.requestHandler(payload);
          this.$emit('response', { requestId, response });
        } catch (error) {
          this.$emit('error', { requestId, error });
        }
      } else {
        this.$emit('error', { requestId, error: 'no request handler found' });
      }
    } else if (event == 'commit') {
      const commit = data;
      this.repository.applyCommit(commit);
      this.resolveListeners(commit.rev);
      this.emit('requestUpdate', 'commit');
    } else if (event == 'commit.batch') {
      const commits = data as Commit[];
      // TODO @ISTA
      // Replace with Promise.all
      for (const commit of commits) {
        await this.repository.applyCommit(commit);
        this.resolveListeners(commit.rev);
      }
      this.emit('requestUpdate', 'commit');
    } else if (event == 'snapshot') {
      const snapshot = data;
      this.repository.loadSnapshot(snapshot);
      this.resolveListeners(snapshot.rev);
      this.emit('requestUpdate', 'snapshot');
    } else if (event == 'heartbeat') {
      // TODO: Respond
    } else {
      logger.info('Invalid message received', { event, data });
    }
  }
}
