






import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from '@vue/composition-api';
import { useFillip, useRouter } from '@/composables';
import AuthContainer from './components/AuthContainer.vue';

export default defineComponent({
  name: 'ProviderLoginSuccess',
  components: {
    AuthContainer,
  },
  setup() {
    const { fillip } = useFillip();
    const { $route, gotoTargetOrCommunities } = useRouter();
    const loading = ref(false);

    const providerTitle = computed(() => {
      return `login.${$route.query.provider || ''}.loginSuccessfull`;
    });

    // TODO: Bind loading indicator
    // TODO: Catch and return errors
    // TODO: Show errors in UI

    const triggerLogin = async () => {
      loading.value = true;
      try {
        await fillip.auth.loginWithJwt($route.query.jwt);
        gotoTargetOrCommunities();
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      triggerLogin();
    });

    return {
      loading,
      triggerLogin,
      providerTitle,
    };
  },
});
