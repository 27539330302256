

























































































































import { ModuleCamera, ModuleCameraMeta } from '@fillip/api';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { computed, defineComponent } from '@vue/composition-api';
import Vue from 'vue';

export default defineComponent({
  name: 'CameraEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { buffer, data, save } = useSave(props, {
      path: 'camera',
    });

    const cameraTypes = ModuleCameraMeta.types;

    const setType = (type: ModuleCamera['type']) => {
      buffer.value = ModuleCameraMeta.types[type].default;
      save();
    };

    const hasCustomPosition = computed(() => !!buffer.value.position);

    const toggleFixedPosition = () => {
      if (!['camera.fixed', 'camera.auto-zoom'].includes(buffer.value.type))
        return;
      if (buffer.value.position) {
        Vue.delete(buffer.value, 'position');
      } else {
        Vue.set(buffer.value, 'position', {
          position: {
            x: 0,
            y: 0,
            z: 1000,
          },
          rotation: {
            x: 0,
            y: 0,
            z: 0,
          },
          scale: {
            x: 1,
            y: 1,
            z: 1,
          },
        });
      }
      save();
    };

    return {
      buffer,
      data,
      save,
      cameraTypes,
      setType,
      toggleFixedPosition,
      hasCustomPosition,
    };
  },
});
