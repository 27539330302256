

































import { deepEqual } from '@fillip/api';

import DimensionsEditor from './DimensionsEditor.vue';

const withinEpsilon = (a, b) => {
  return Math.abs(a - b) < 0.0001;
};

export default {
  name: 'Location3dEditor',
  components: {
    DimensionsEditor,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      buffer: null,
      dimensions: ['x', 'y', 'z'],
    };
  },
  computed: {
    rotationScripted() {
      return typeof this.bufferAbsoluteRotation == 'string';
    },
    currentRotationValue() {
      if (this.rotationScripted) return this.bufferAbsoluteRotation;
      return {
        x: (this.bufferAbsoluteRotation.x * 180) / Math.PI,
        y: (this.bufferAbsoluteRotation.y * 180) / Math.PI,
        z: (this.bufferAbsoluteRotation.z * 180) / Math.PI,
      };
    },
    bufferAbsoluteRotation() {
      if (!this.buffer) return null;
      return this.buffer.rotation;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (deepEqual(newValue, this.buffer) || deepEqual(newValue, oldValue))
          return;
        this.buffer = newValue;
      },
    },
  },
  methods: {
    updateRotation(newValue) {
      if (typeof newValue == 'string') {
        this.buffer.rotation = newValue;
        this.update();
      } else {
        const rotationInRadians = {
          x: (newValue.x * Math.PI) / 180,
          y: (newValue.y * Math.PI) / 180,
          z: (newValue.z * Math.PI) / 180,
        };
        if (
          !withinEpsilon(rotationInRadians.x, this.bufferAbsoluteRotation.x) ||
          !withinEpsilon(rotationInRadians.y, this.bufferAbsoluteRotation.y) ||
          !withinEpsilon(rotationInRadians.z, this.bufferAbsoluteRotation.z)
        ) {
          this.buffer.rotation = rotationInRadians;
          this.update();
        }
      }
    },
    update(newValue) {
      this.$emit('input', this.buffer);
      this.$emit('change', this.buffer);
      this.$emit('blur');
    },
  },
};
