import { FillipError } from './fillip-error';

// * helper functions
function FillipBackendError(
  type: string,
  internal?: boolean,
  context?: Record<string, any>,
) {
  const error = {
    type,
    source: 'backend',
    meta: {
      internal: internal || false,
    },
    context,
  };
  return new FillipError(error);
}

function FillipValidationError(
  type: string,
  internal?: boolean,
  context?: Record<string, any>,
) {
  const error = {
    type,
    // ! Might want to pass on the requestTarget here. See comment in ValidationError.
    source: 'class-validator',
    meta: {
      internal: internal || false,
    },
    context,
  };
  return new FillipError(error);
}

// ! Arguments as keys in the context key

// * Error throwing functions below, sorted by domain as in locales
// * General
export function UnknownError(): FillipError {
  return FillipBackendError('general.error.unknown', true);
}

export function TypeError(): FillipError {
  return FillipBackendError('general.error.typeError', true);
}

export function NetworkError(): FillipError {
  return FillipBackendError('general.error.networkError', true);
}

// * Signup
export function EmailAlreadyExists() {
  return FillipBackendError('signup.error.emailAlreadyExists');
}

export function CouldNotSendVerificationMail() {
  return FillipBackendError('signup.error.couldNotSendVerificationMail');
}

// * Login
export function EmailNotVerified(): FillipError {
  return FillipBackendError('login.error.emailNotVerified');
}

export function InvalidPassword(): FillipError {
  return FillipBackendError('login.error.invalidPassword');
}

export function NoLocalUserWithThisEmail(): FillipError {
  return FillipBackendError('login.error.noLocalUserWithThisEmail');
}

export function EmailOrPasswordNotCorrect(): FillipError {
  return FillipBackendError('login.error.emailOrPasswordNotCorrect');
}

export function CouldNotLoginOAuth(): FillipError {
  return FillipBackendError('login.error.couldNotLoginOAuth');
}

// * Confirmation
export function InvalidEmailToken(): FillipError {
  return FillipBackendError('confirmation.error.invalidEmailToken');
}

export function UserAlreadyVerified(): FillipError {
  return FillipBackendError('confirmation.error.userAlreadyVerified');
}

// * User
export function UserNotFound(): FillipError {
  return FillipBackendError('user.error.userNotFound');
}

export function CurrentPasswordWrong(): FillipError {
  return FillipBackendError('user.error.currentPasswordWrong');
}

export function NewPasswordMatchesOldPassword(): FillipError {
  return FillipBackendError('user.error.newPasswordMatchesOldPassword');
}

export function CouldNotSendEmail(): FillipError {
  return FillipBackendError('user.error.couldNotSendEmail');
}

export function CannotChangePasswordOfNotLocalUser(): FillipError {
  return FillipBackendError('user.error.cannotChangePasswordOfNotLocalUser');
}

export function CannotChangeEmailOfNotLocalUser(): FillipError {
  return FillipBackendError('user.error.cannotChangeEmailOfNotLocalUser');
}

export function CouldNotChangeUserData(): FillipError {
  return FillipBackendError('user.error.couldNotChangeUserData');
}

// * auth
export function LoggedInUserRequired(): FillipError {
  return FillipBackendError('auth.error.loggedInUserRequired');
}

export function RegisteredUserRequired(): FillipError {
  return FillipBackendError('auth.error.registeredUserRequired');
}

export function InvalidJwt(): FillipError {
  return FillipBackendError('auth.error.invalidJwt');
}

export function jwtExpired(): FillipError {
  return FillipBackendError('auth.error.jwtExpired');
}

export function UnauthorizedToChangeOtherUsersData(): FillipError {
  return FillipBackendError('auth.error.unauthorizedToChangeOtherUsersData');
}

export function UnauthorizedToAccessApi(): FillipError {
  return FillipBackendError('auth.error.unauthorizedToAccessApi');
}

export function CouldNotUpgradeUser(): FillipError {
  return FillipBackendError('auth.error.couldNotUpgrade');
}

// * Communities
export function ValidationError(validationErrors): FillipError {
  const allTargets: string[] = [];
  const requestConstraints = {};
  const args = {};

  for (const validationError of validationErrors) {
    const { target, value, property, constraints } = validationError;
    const requestTarget = target.constructor.name as string;

    if (!allTargets.includes(requestTarget)) {
      allTargets.push(requestTarget);
    }

    for (const [constraint, message] of Object.entries(constraints)) {
      requestConstraints[property] = {
        ...requestConstraints[property],
        [constraint]: message,
      };
    }
    args[requestTarget] = requestConstraints;
  }
  // TODO: change locale to new locales concept
  // TODO: filter and display custom constraint messages to user
  // ? Pass on the requestTarget as source i.e. Reistration / login / communities, etc? Most of the time, there should only be one requestTarget. That way we can differentiate quite good for the locales & error handling?!
  return FillipValidationError(
    `general.validation.classValidator`,
    false,
    args,
  );
}

export function SlugAlreadyExists(): FillipError {
  return FillipBackendError('communities.error.slugAlreadyExists');
}

export function NotAuthorizedToEdit(): FillipError {
  return FillipBackendError('communities.error.notAuthorizedToEdit');
}

export function NotAuthorizedToDelete(): FillipError {
  return FillipBackendError('communities.error.notAuthorizedToDelete');
}

export function NoCommunityFoundForSlug(slug: string): FillipError {
  return FillipBackendError(
    'communities.error.noCommunityFoundForSlug',
    false,
    { argument: slug },
  );
}

export function MissingSlug(): FillipError {
  return FillipBackendError('communities.error.missingSlug');
}

export function LoggedInUserRequiredCommunities(): FillipError {
  return FillipBackendError('communities.error.loggedInUserRequired');
}

export function CouldNotCreate(communityTitle: string): FillipError {
  return FillipBackendError('communities.error.couldNotCreate', false, {
    argument: communityTitle,
  });
}
export function CouldNotUpdate(slug: string): FillipError {
  return FillipBackendError('communities.error.couldNotUpdate', false, {
    argument: slug,
  });
}

export function CouldNotFindAll(): FillipError {
  return FillipBackendError('communities.error.couldNotFindAll');
}

// * media

export function UploadFetchFailed(): FillipError {
  return FillipBackendError('media.error.uploadFetchFailed');
}

export function NotAuthorizedToDeleteMedia(): FillipError {
  return FillipBackendError('media.error.notAuthorizedToDelete');
}

// * fileCollections

export function CouldNotFindAllCollections(): FillipError {
  return FillipBackendError('collections.error.couldNotFindAllCollections');
}

export function CouldNotFindCollectionGeneric(): FillipError {
  return FillipBackendError('collections.error.couldNotFindCollectionGeneric');
}

export function CouldNotFindCollection(needle: string): FillipError {
  return FillipBackendError('collections.error.couldNotFindCollection', false, {
    argument: needle,
  });
}

export function CouldNotCreateCollection(collectionName: string) {
  return FillipBackendError(
    'fileCollections.error.couldNotCreateCollection',
    false,
    { argument: collectionName },
  );
}

export function UnauthorizedToEditCollection(collection: string) {
  return FillipBackendError(
    'fileCollections.error.unauthorizedToEditCollection',
    false,
    { argument: collection },
  );
}

export function UnauthorizedToDeleteCollection(collection: string) {
  return FillipBackendError(
    'fileCollections.error.unauthorizedToDeleteCollection',
    false,
    { argument: collection },
  );
}

export function CouldNotCreateFile(file: string) {
  return FillipBackendError('fileCollections.error.couldNotCreateFile', false, {
    argument: file,
  });
}

export function UnauthorizedToEditFile(file: string) {
  return FillipBackendError(
    'fileCollections.error.unauthorizedToEditFile',
    false,
    { argument: file },
  );
}

export function CouldNotGetAllFilesOfCommunity(community: string): FillipError {
  return FillipBackendError(
    'collections.error.couldNotGetAllFilesOfCommunity',
    false,
    {
      argument: community,
    },
  );
}

export function CouldNotGetAllFilesInCollectionGeneric(): FillipError {
  return FillipBackendError(
    'collections.error.couldNotGetAllFilesInCollection',
  );
}

export function CouldNotFindFileGeneric(): FillipError {
  return FillipBackendError('collections.error.couldNotFindFileGeneric');
}

export function CouldNotFindFile(needle: any): FillipError {
  return FillipBackendError('collections.error.couldNotFindFile', false, {
    argument: needle,
  });
}

export function UnauthorizedToAccessPrivateFile(needle: string) {
  return FillipBackendError(
    'fileCollections.error.unauthorizedToAccessPrivateFile',
    false,
    { argument: needle },
  );
}

export function UnauthorizedToDeleteFile(file: string) {
  return FillipBackendError(
    'fileCollections.error.unauthorizedToDeleteFile',
    false,
    { argument: file },
  );
}

// * vm commands

export function InvalidVmCommand(command: string) {
  return FillipBackendError('vm.error.InvalidVmCommand', true, {
    argument: command,
  });
}

export function MethodOnNonExistentNode(instanceId: string) {
  return FillipBackendError('vm.error.MethodOnNonExistentNode', false, {
    argument: instanceId,
  });
}

export function UnknownMethod(method: string) {
  return FillipBackendError('vm.error.UnknownMethod', false, {
    argument: method,
  });
}

export function InfiniteLoopDetected() {
  return FillipBackendError('vm.error.InfiniteLoopDetected', true);
}

export function ObjectCannotHaveChildren(object: string) {
  return FillipBackendError('vm.error.ObjectCannotHaveChildren', false, {
    argument: object,
  });
}

export function RootNotDeletable() {
  return FillipBackendError('vm.error.RootNotDeletable');
}

export function InvalidId(id: string, operation: string = 'unknown') {
  return FillipBackendError('vm.error.InvalidId', true, {
    argument: id,
    operation,
  });
}

export function NodeDoesNotHaveParent() {
  return FillipBackendError('vm.error.NodeDoesNotHaveParent', true);
}

export function InvalidObject() {
  return FillipBackendError('vm.error.InvalidObject');
}

export function InvalidLink() {
  return FillipBackendError('vm.error.InvalidLink');
}

export function ObjectWithTitleAlreadyExists() {
  return FillipBackendError('vm.error.ObjectWithTitleAlreadyExists');
}

export function ObjectNeedsTitle() {
  return FillipBackendError('vm.error.ObjectNeedsTitle');
}

export function ObjectWithSlugAlreadyExists() {
  return FillipBackendError('vm.error.ObjectWithSlugAlreadyExists');
}

export function ObjectWithIdAlreadyExists(id: string) {
  return FillipBackendError('vm.error.ObjectWithIdAlreadyExists', false, {
    argument: id,
  });
}

export function ImportFailed(id: string) {
  return FillipBackendError('Import failed', false, {
    argument: id,
  });
}

export function RootOverrideNotAllowed() {
  return FillipBackendError(
    'Root imports only allowed in empty communities',
    false,
    {},
  );
}
