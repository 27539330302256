export default {
  themeName: 'default',
  version: '0.0.2',
  meta: {
    appTitle: 'collabl.ai',
    appSubTitle: 'Empowering human interaction',
  },
  options: {
    thumbnailStyle: 'tile',
  },
  images: {
    logo: `${process.env.MINIO_URL}/media/illustrations/fillip/fillip-h-dark.svg`,
    wait: `${process.env.MINIO_URL}/media/illustrations/fillip/fi-wait.svg`,
    addNewEmpty: `${process.env.MINIO_URL}/media/illustrations/fillip/fi-add-new-empty.svg`,
    emptySend: `${process.env.MINIO_URL}/media/illustrations/fillip/fi-empty-send.svg`,
    noResults: `${process.env.MINIO_URL}/media/illustrations/fillip/fi-no-results.svg`,
    speechBubbles: `${process.env.MINIO_URL}/media/illustrations/fillip/sprechblase.svg`,
  },
  theme: {
    themes: {
      light: {
        // primary: '#06ab71',
        primary: {
          base: '#06ab71',
          darken3: '#00663C',
          darken1: '#007F4B',
          lighten1: '#59c29a',
          lighten3: '#b2e3d0',
        },
        primaryContrast: '#fff',
        secondary: '#0085b9',
        accent: '#f7d100',
        layout: '#c4c4c4',
        error: '#e35a3e',
        info: '#b8b2a2',
        success: '#06ab71',
        warning: '#ea8e00',
        background: '#ecf0f5',
      },
      dark: {
        primary: '#06ab71',
        primaryContrast: '#fff',
        secondary: '#0085b9',
        accent: '#f7d100',
        layout: '#c4c4c4',
        error: '#e35a3e',
        info: '#secondary',
        success: '#06ab71',
        warning: '#ea8e00',
        background: '#555d6a',
      },
    },
    dark: false,
    options: {
      customProperties: true,
    },
  },
};
