









































import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'SelectListControl',
  props: {
    control: { type: Object, default: () => ({}) },
    status: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const selectItem = (option: any) => {
      if (typeof props.control.action === 'function') {
        props.control.action(option.value);
      }
      emit('selectionChange', option);
    };
    const indexOfActive = computed(() => {
      return props.control.options.findIndex(
        (option) => option.value == props.status.value,
      );
    });

    return { selectItem, indexOfActive };
  },
});
