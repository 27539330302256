

























import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import type { Modules, ElementImage as ModuleElementImage } from '@fillip/api';

export default defineComponent({
  name: 'ElementExpandableImage',
  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      default: '',
    },
    modules: {
      type: Object as PropType<Modules & { element: ModuleElementImage }>,
      default: () => ({}),
    },
  },
  setup(props) {
    const src = computed(() => props.modules.element?.imageSrc);
    const title = computed(() => props.modules.info?.title);
    const description = computed(() => props.modules.element?.imageDescription);
    const copyright = computed(() => props.modules.element?.imageCopyright);
    const expandOnClick = computed(() => props.modules.element?.expandOnClick);

    const isExpanded = ref(false);
    const expandImage = () => {
      if (!expandOnClick.value) {
        return;
      }
      isExpanded.value = !isExpanded.value;
    };

    return {
      src,
      title,
      description,
      copyright,
      expandOnClick,
      isExpanded,
      expandImage,
    };
  },
});
