<template>
  <v-expansion-panels v-model="panel" flat class="editor mt-2">
    <v-expansion-panel>
      <v-card outlined class="module-container p-2">
        <slot></slot>
      </v-card>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    hideActions: {
      default: true,
      type: Boolean,
    },
    mountExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panel: undefined,
    };
  },
  mounted() {
    if (this.mountExpanded) {
      this.panel = 0;
    }
  },
};
</script>
