import Vue from 'vue';

// Usage with Mixin
// Add GlobalPropsNode as a mixin to a Vue Component
// To access a global prop inside computed use this.$getGlobalProp("globalPropName", optionalMixFunction)
// To broadcast a value to a global prop call inside mounted this.$bindGlobalProp("globalPropName", () => {return this.someReactiveProperty})

// Low Level API
// Call this.$globalProps.registerNode and this.$globalProps.unregisterNode before and after you do anything
// To start listening call this.$globalProps.listen and pass in a mixerFunction (see type definition). Afterwards you can use this.$globalProps.getValue to access the current value of the prop and this.$globalProps.get to get both the value and any meta information the mixer might have returned.
// To start broadcasting call this.$globalProps.broadcast and pass in a getter. The low level api expects a function returning an object of type ValueWithMeta, so make sure to wrap your results. If you are lazy you can also use the wrapGetter helper from this file.
// You can use this.$globalProps.stopListen and stopBroadcast if you want to dynamically change what you are listening / broadcasting. But note that they will automatically be called upon unregisterNode.

type VueGlobal = typeof Vue;

interface NodeEntry {
  listeningTo: Set<string>;
  broadcastingTo: Set<string>;
}

// To be defined
type Meta = Record<string, any>;

interface ValueWithMeta {
  value: any;
  meta?: Meta;
}

export interface Broadcaster {
  nodeId: string;
  getter: () => ValueWithMeta;
}

type MixFn = (inputs: Record<string, Broadcaster>) => ValueWithMeta;

interface Listener {
  nodeId: string;
  mixer: MixFn;
}

interface Prop {
  path: string;
  broadcasters: Record<string, Broadcaster>;
  listeners: Record<string, Listener>;
}

export function wrapGetter(getter: () => any): () => ValueWithMeta {
  return () => {
    const result = getter();
    if (
      result == null ||
      typeof result != 'object' ||
      result.value == undefined
    ) {
      return {
        value: result,
      };
    }
    return result;
  };
}

export class GlobalPropsPlugin {
  store: Map<string, Prop>;
  nodes: Map<string, NodeEntry>;

  constructor() {
    this.store = new Map();
    this.nodes = new Map();
  }

  registerNode(nodeId) {
    this._checkNodeExists(nodeId, false);

    const entry: NodeEntry = {
      listeningTo: new Set(),
      broadcastingTo: new Set(),
    };
    this.nodes.set(nodeId, entry);
  }

  unregisterNode(nodeId) {
    this._checkNodeExists(nodeId);
    const entry = this.nodes.get(nodeId);

    for (const path of entry.listeningTo.values()) {
      this.stopListen(nodeId, path);
    }

    for (const path of entry.broadcastingTo.values()) {
      this.stopBroadcast(nodeId, path);
    }

    this.nodes.delete(nodeId);
  }

  listen(nodeId: string, path: string, mixer: MixFn) {
    this._checkNodeExists(nodeId);
    this._checkNodeIsListeningTo(nodeId, path, false);
    this._ensurePropEntryExists(path);

    const listener: Listener = {
      nodeId,
      mixer,
    };

    Vue.set(this.store.get(path).listeners, nodeId, listener);
    this.nodes.get(nodeId).listeningTo.add(path);
  }

  ensureListen(nodeId: string, path: string, mixer: MixFn) {
    this._checkNodeExists(nodeId);
    if (this.nodes.get(nodeId).listeningTo.has(path)) {
      if (this.store.get(path).listeners[nodeId].mixer != mixer) {
        console.warn(
          'Mixer changed in ensureListen, check if this is really a change of the mixing algorithm and not just a pointer change because of a locally created function',
        );
        this.stopListen(nodeId, path);
        this.listen(nodeId, path, mixer);
      }
    } else {
      this.listen(nodeId, path, mixer);
    }
  }

  stopListen(nodeId: string, path: string) {
    this._checkNodeExists(nodeId);
    this._checkNodeIsListeningTo(nodeId, path);

    Vue.delete(this.store.get(path).listeners, nodeId);
    this.nodes.get(nodeId).listeningTo.delete(path);

    this._removePropIfEmpty(path);
  }

  broadcast(nodeId: string, path: string, getter: () => ValueWithMeta) {
    this._checkNodeExists(nodeId);
    if (this._checkNodeIsBroadcastingTo(nodeId, path, false)) {
      this._ensurePropEntryExists(path);

      const broadcaster: Broadcaster = {
        nodeId,
        getter,
      };

      Vue.set(this.store.get(path).broadcasters, nodeId, broadcaster);
      this.nodes.get(nodeId).broadcastingTo.add(path);
    }
  }

  isBroadcasting(nodeId: string, path: string) {
    if (!this.nodes.get(nodeId)) return false;
    return this.nodes.get(nodeId).broadcastingTo.has(path);
  }

  stopBroadcast(nodeId: string, path: string) {
    this._checkNodeExists(nodeId);
    if (this._checkNodeIsBroadcastingTo(nodeId, path)) {
      Vue.delete(this.store.get(path).broadcasters, nodeId);
      this.nodes.get(nodeId).broadcastingTo.delete(path);

      this._removePropIfEmpty(path);
    }
  }

  get(nodeId: string, path: string): ValueWithMeta {
    this._checkNodeExists(nodeId);
    this._checkNodeIsListeningTo(nodeId, path);

    const { listeners, broadcasters } = this.store.get(path);
    return listeners[nodeId].mixer(broadcasters);
  }

  getValue(nodeId: string, path: string): any {
    return this.get(nodeId, path).value;
  }

  _checkNodeExists(nodeId, shouldExist = true) {
    if (this.nodes.has(nodeId) != shouldExist) {
      throw new Error(
        shouldExist
          ? `Node ${nodeId} doesn't exist`
          : `Node ${nodeId} already exists`,
      );
    }
  }

  _checkNodeIsListeningTo(nodeId, path, shouldBeListening = true) {
    if (this.nodes.get(nodeId).listeningTo.has(path) != shouldBeListening) {
      throw new Error(
        shouldBeListening
          ? `Node ${nodeId} is not listening to ${path}`
          : `Node ${nodeId} is already listening to ${path}`,
      );
    }
  }

  _checkNodeIsBroadcastingTo(nodeId, path, shouldBeBroadcasting = true) {
    return (
      this.nodes.get(nodeId).broadcastingTo.has(path) == shouldBeBroadcasting
    );
  }

  _ensurePropEntryExists(path) {
    if (!this.store.has(path)) {
      const propEntry: Prop = Vue.observable({
        path,
        broadcasters: {},
        listeners: {},
      });
      this.store.set(path, propEntry);
    }
  }

  _removePropIfEmpty(path) {
    if (
      Object.keys(this.store.get(path).listeners).length == 0 &&
      Object.keys(this.store.get(path).broadcasters).length == 0
    ) {
      this.store.delete(path);
    }
  }
}

export default {
  install(Vue) {
    Vue.prototype.$globalProps = new GlobalPropsPlugin();
  },
};

export const mixerPickFirst = (inputs: Record<string, Broadcaster>) => {
  if (Object.keys(inputs).length == 0)
    return {
      value: null,
      meta: {
        source: 'default',
      },
    };
  else {
    return Object.values(inputs)[0].getter();
  }
};

export const GlobalPropsNode = {
  beforeCreate() {
    this.$globalProps.registerNode(this._uid);
  },
  beforeDestroy() {
    this.$globalProps.unregisterNode(this._uid);
  },
  methods: {
    $bindGlobalProp(path, getter) {
      this.$globalProps.broadcast(this._uid, path, wrapGetter(getter));
    },
    $getGlobalProp(path, mixer = mixerPickFirst) {
      this.$globalProps.ensureListen(this._uid, path, mixer);
      return this.$globalProps.getValue(this._uid, path);
    },
  },
};

export const mapGlobalProps = function (
  props: Array<string | { path: string; mixer: MixFn }>,
) {
  return props.reduce((computed, next) => {
    const path = typeof next == 'object' ? next.path : next;
    const mixer =
      typeof next == 'object' && next.mixer ? next.mixer : mixerPickFirst;

    computed[path] = () => {
      return this.$getGlobalProp(path, mixer);
    };

    return computed;
  }, {});
};
