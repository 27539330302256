import AuthLayout from '@/features/auth/AuthLayout.vue';

import { Fillip } from '@fillip/vue-client';
import { Role } from '@fillip/api';
import store from '@/store';
import { checkAndUpdateAccessToken, checkCommunityAccess } from './utils';

export default [
  {
    path: '/communities',
    name: 'Communities',
    redirect: 'communities/hub',
    component: AuthLayout,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: async (to, from, next) => {
      await checkAndUpdateAccessToken(to, from, next);

      const targetCommunity = to.fullPath.split('/')?.[0];
      const user = Fillip.instance.state.user;
      const shell = store.getters.shell;

      // Check access rights if user has a restricted account
      if (user.role === Role.RESTRICTED) {
        await checkCommunityAccess(user, shell, targetCommunity, true, next);
      }

      next();
    },

    children: [
      {
        path: 'hub',
        name: 'CommunityHub',
        meta: { title: 'communities.communityHub' },
        component: () =>
          import(
            /* webpackChunkName: "communities" */ '@/features/communities/CommunityHub.vue'
          ),
      },
      {
        path: 'add-community',
        name: 'AddCommunity',
        meta: { title: 'communities.addCommunity' },
        component: () =>
          import(
            /* webpackChunkName: "communities" */ '@/features/communities/CreateCommunity.vue'
          ),
      },
    ],
  },
];
