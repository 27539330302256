import { ModuleTypeMeta } from '../../common';

export interface ModuleStyle {
  [cssProperty: string]: string;
}

export const ModuleStyleMeta: ModuleTypeMeta<ModuleStyle> = {
  default: {},
  icon: 'paint-brush',
};
