import { computed, defineComponent } from '@vue/composition-api';
import DOMPurify from 'dompurify';

export const ElementText = defineComponent({
  name: 'ElementText',
  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      default: '',
    },
    modules: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const text = computed(() => {
      const textContent = props.modules.element.textContent;
      return typeof textContent == 'object'
        ? JSON.stringify(textContent)
        : DOMPurify.sanitize(String(textContent));
    });

    return { text };
  },
  render(h) {
    return h(this.modules.element.textFormat || 'div', {
      // TODO: Remove after vue 3 migration

      class: 'element-text', // Needed to remove default vuetify margin on html tags
      ref: 'el',
      domProps: { innerHTML: this.text },
    });
  },
});
