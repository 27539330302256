





















import {
  DataId,
  rootTemplateId,
  DataPresets,
  PresetDefinition,
  PresetDefaultTemplate,
  PresetStepper,
  PresetCollection,
  PresetCollectionTool,
  PresetWorkspace,
  PresetPoll,
  PresetScreenshare,
  PresetEmbed,
  PresetBreakoutRoom,
  PresetCollectionQA,
  PresetCollectionDiscussion,
  PresetCollectionChat,
  PresetCollectionBrainstorming,
  PresetWordCloud,
  PresetContent,
  PresetLibraryContent,
  PresetOnboarding,
  PresetReveal,
} from '@fillip/api';
import { useData, useDataClipboard } from '@/composables';

export default {
  name: 'PresetSelector',
  props: {
    group: {
      type: String,
      default: 'all',
    },
    parentId: {
      type: String,
      default: rootTemplateId,
    },
    allowImport: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const dataClipboard = useDataClipboard();
    const { importFlatDataTree, installPreset } = useData();

    return {
      dataClipboard,
      importFlatDataTree,
      installPreset,
    };
  },
  computed: {
    builtinPresets() {
      return [
        PresetDefaultTemplate,
        ...Object.values(DataPresets),
        PresetStepper,
        PresetCollection,
        PresetWorkspace,
        PresetPoll,
        PresetScreenshare,
        PresetEmbed,
        PresetBreakoutRoom,
        PresetCollectionTool,
        PresetCollectionQA,
        PresetCollectionDiscussion,
        PresetCollectionChat,
        PresetCollectionBrainstorming,
        PresetWordCloud,
        PresetContent,
        PresetLibraryContent,
        PresetOnboarding,
        PresetReveal,
      ];
    },
    filteredPresets() {
      if (this.group == 'all') {
        return this.builtinPresets;
      }
      return this.builtinPresets.filter((t) => t.group == this.group);
    },
  },
  methods: {
    openImport(parentId: DataId): void {
      this.dataClipboard.clear();
      this.dataClipboard.setTarget(parentId);
      this.$dialog.push('/clipboard/import');
    },
    async startInstallPreset(parent: DataId, preset: PresetDefinition) {
      const { result } = await this.installPreset(parent, preset, {});
      this.$emit('added', result);
    },
  },
};
