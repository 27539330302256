import {
  IsBoolean,
  IsJSON,
  IsObject,
  IsOptional,
  IsString,
} from 'class-validator';

export interface FileCollectionSettings {
  // Default: true
  // When true, the collection is persisted in Postgres
  // When set to false, the collection only lives in the vm
  persist: boolean;

  // default: false
  // When set to true, the collection will be recreated
  // each time the component becomes active again
  perInstance: boolean;

  // Whether the document should be kept in sync via the vm
  // When set to false, the frontend component loads the
  // document via the rest api when it's mounted
  // ! Private documents should never be synced
  sync: true;

  // Describe the document content for editing purposes
  fields?: Array<FileCollectionField>;
}

export interface Collection {
  id?: number;
  name: string;
  communitySlug: string;
  settings: FileCollectionSettings;
  isPrivate: boolean;
}

export interface File {
  id: number;
  name: string;
  primaryCollection: Collection;
  communitySlug: string;
  isPrivate: boolean;
}

// This should match the types definition for the vm types
export interface FileCollectionField {
  key: string;
  name: string;
  type: string;
  defaultValue?: string;
  props?: Record<string, any>;
}

export class CreateCollectionRequest {
  @IsString()
  name: string;

  @IsString()
  communitySlug: string;

  @IsOptional()
  isPrivate?: boolean;

  @IsOptional()
  settings?: FileCollectionSettings;
  // TODO: Add class validation
}

export class UpdateCollectionRequest {
  @IsString()
  @IsOptional()
  name?: string;

  @IsOptional()
  settings?: FileCollectionSettings;

  @IsString()
  @IsOptional()
  communitySlug?: string;
  // TODO: Add class validation
}

export class CreateFileRequest {
  @IsString()
  name: string;

  primaryCollectionId: number;

  @IsOptional()
  collections?: Collection[];

  @IsObject()
  @IsOptional()
  meta?: Object;

  @IsObject()
  document: Object;

  @IsString()
  communitySlug: string;

  @IsBoolean()
  @IsOptional()
  isPrivate: boolean;
}

export class UpdateFileRequest {
  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  primaryCollection?: Collection;

  @IsOptional()
  collections?: Collection[];

  @IsObject()
  @IsOptional()
  meta?: Object;

  @IsObject()
  @IsOptional()
  document?: Object;
  // TODO: Add class validation

  @IsString()
  @IsOptional()
  communitySlug: string;
}
