







































import { defineComponent } from '@vue/composition-api';
import { useShell } from '@/composables';

export default defineComponent({
  name: 'AuthSplash',
  setup(props) {
    const { shell, classes, content } = useShell();

    return { shell, classes, content };
  },
});
