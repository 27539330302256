




























































import {
  defineComponent,
  ref,
  computed,
  inject,
  watch,
  onMounted,
  nextTick,
} from '@vue/composition-api';
import NavigationBar from '@/features/app/NavigationBar.vue';
import { useLayoutArea, useStore, useVuetify } from '@/composables';

export default defineComponent({
  name: 'MainDrawer',
  components: {
    NavigationBar,
  },
  setup() {
    const { store } = useStore();
    const themeManager = inject('themeManager', null);

    const width = ref(360);
    const iconBarWidth = ref(45);
    const previousWidth = ref(0);

    const appBarHeight = computed(() => {
      return themeManager?.value?.appBarHeight;
    });

    const { route, component, componentProps, navigate } =
      useLayoutArea('navdrawer');

    const maybeActivate = () => {
      if (
        route.value.fullPath == '/' &&
        (!route.value.query || Object.keys(route.value.query).length < 1)
      ) {
        navigate({
          path: '/',
          query: { minimal: 1 },
        });
      }
    };

    const vuetify = useVuetify();
    onMounted(() => {
      if (vuetify.breakpoint.mdAndUp) {
        maybeActivate();
      }
    });

    const state = computed(() => {
      // START, MINIMAL, FULL, HIDDEN
      if (route.value.fullPath == '/') {
        return 'START';
      }
      if (route.value.query.hidden) {
        return 'HIDDEN';
      }
      if (route.value.query.minimal) {
        return 'MINIMAL';
      }
      return 'FULL';
    });

    const effectiveWidth = computed(() => {
      if (state.value == 'FULL') {
        return width.value;
      } else if (state.value == 'MINIMAL') {
        return iconBarWidth.value;
      } else {
        return 0;
      }
    });

    const startResize = (event) => {
      const start = event.clientX;
      const startWidth = width.value;
      const minWidth = 300;
      const maxWidth = 1000;
      const onMouseMove = (event) => {
        const x = event.clientX;
        const newWidth = x - start + startWidth;
        width.value = Math.min(maxWidth, Math.max(minWidth, newWidth));
      };
      document.addEventListener(
        'mouseup',
        () => {
          document.removeEventListener('mousemove', onMouseMove);
        },
        { once: true },
      );
      document.addEventListener('mousemove', onMouseMove);
    };

    // Transitions

    const slideInTransition = async (el, distance, pixelPerMs = 0.5) => {
      const durationInMs = Math.round(distance / pixelPerMs);

      el.style.transition = `transform ${durationInMs}ms ease`;
      el.style.transform = `translateX(${-distance}px)`;
      await nextTick();
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          el.style.transform = 'translateX(0)';
        });
      });
    };
    const slideOutTransition = (el, distance, pixelPerMs = 0.5) => {
      const durationInMs = Math.round(distance / pixelPerMs);
      el.style.transition = `transform ${durationInMs}ms ease`;
      el.style.transform = `translateX(${-distance}px)`;
    };

    const enter = (el) => {
      slideInTransition(
        el,
        effectiveWidth.value,
        effectiveWidth.value < 50 ? 0.25 : 0.5,
      );
    };
    const leave = (el) => {
      slideOutTransition(
        el,
        previousWidth.value,
        previousWidth.value < 50 ? 0.25 : 0.5,
      );
    };
    const expand = (el) => {
      slideInTransition(el, width.value - iconBarWidth.value);
    };
    const collapse = (el) => {
      slideOutTransition(el, width.value - iconBarWidth.value);
    };

    watch(
      effectiveWidth,
      (newValue, oldValue) => {
        previousWidth.value = oldValue;
        if (store.state.appDrawer.state == 'fixed') {
          store.commit('SET_APP_DRAWER', {
            state: 'fixed',
            width: newValue,
          });
        }
      },
      { immediate: true },
    );

    return {
      state,
      width,
      iconBarWidth,
      previousWidth,
      startResize,
      enter,
      leave,
      expand,
      collapse,
      effectiveWidth,
      route,
      themeManager,
      appBarHeight,
      component,
      componentProps,
    };
  },
});
