<template>
  <div ref="el" class="h-full w-full relative">
    <canvas id="myChart" ref="chart"></canvas>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import { BaseElement } from './../core';

// TODO: Refactor into loadDependency util, add loading indicator
let Chart, WordCloudController, WordElement;
const loadChartJs = () => {
  return new Promise((resolve) => {
    require(/* webpackChunkName: "chart" */ ['chart.js/auto'], resolve);
  });
};
const loadWorldcloud = () => {
  return new Promise((resolve) => {
    require(/* webpackChunkName: "chart" */ [
      'chartjs-chart-wordcloud',
    ], resolve);
  });
};
const initializeChartJs = async () => {
  ({ default: Chart } = await loadChartJs());
  ({ WordCloudController, WordElement } = await loadWorldcloud());
  Chart.register(WordCloudController, WordElement);
};

const formatTicks = function (
  value,
  index,
  ticks,
  ticksUnit,
  ticksUnitPosition,
) {
  const formattedTick = Object.getPrototypeOf(
    this,
  ).constructor.defaults.ticks.callback.apply(this, [value, index, ticks]);

  if (!ticksUnit) return formattedTick;

  return (
    (ticksUnitPosition === 'before' ? ticksUnit + ' ' : '') +
    (typeof formattedTick === 'string'
      ? formattedTick
      : formattedTick.join(' ')) +
    (ticksUnitPosition !== 'before' ? ' ' + ticksUnit : '')
  );
};

export default {
  name: 'ElementChart',
  mixins: [BaseElement],
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    maxLayers() {
      return Math.max(
        ...this.modules.element.data.datasets.map((dataset) => {
          return dataset.chartDataY.length;
        }),
      );
    },

    datasets() {
      const options = this.modules.element.options;
      const datasets = this.modules.element.data.datasets;
      if (this.modules.element.editor.scriptedDatasets) {
        return this.modules.element.scriptedDatasets;
      }
      if (datasets.length == 0) return datasets;
      if (this.modules.element.chartType == 'wordCloud') {
        // TODO The scale factor is necessary to make the wordCloud dynamically grow and shrink depending on the size of it's content,
        // to avoid overlapping or to small words and to prevent the algorithm from breaking when the inputs values are to large.
        // However it is only partially solving the problem, leaving wierd results for inputs with few values but high value difference.
        // In general the wordCloud algorithm doesn't seem to be very robust, and we should probably consider switching the library
        const scaleFactor = (() => {
          const highestValue = Math.max(
            ...datasets.reduce((newArray, dataset) => {
              newArray.push(...dataset.chartDataY);
              return newArray;
            }, []),
          );
          const longestWord = Math.max(
            ...this.modules.element.data.labels.map((label) => label.length),
          );
          const width = this.$refs.chart ? this.$refs.chart.clientWidth : 0;
          const height = this.$refs.chart ? this.$refs.chart.clientHeight : 0;
          const maxWordLength = Math.max(width, height);
          const factor = maxWordLength / highestValue / longestWord;
          return factor < 0 ? 1 : factor;
        })();

        const datasset = (() => {
          const newDataset = {
            color: [],
            data: [],
            // label: [],
            //fit doesn't seem to work
            fit: true,
          };
          let strikes = 0;
          while (strikes < datasets.length) {
            datasets.forEach((dataset, index) => {
              if (dataset.chartDataY[0] === undefined) {
                strikes++;
                datasets.splice(index, 1);
                return;
              }
              const chartDataY =
                dataset.chartDataY[0] <= 0
                  ? 1
                  : dataset.chartDataY[0] * scaleFactor;
              newDataset.data.push(chartDataY);
              console.log('color', dataset.backgroundColor);
              newDataset.color.push(dataset.backgroundColor);
              // newDataset.label.push(dataset.chartDataX);
              dataset.chartDataY.shift();
            });
          }
          return [newDataset];
        })();
        return datasset;
      } else if (
        !['doughnut', 'pie', 'polarArea'].includes(
          this.modules.element.chartType,
        )
      ) {
        return this.modules.element.data.datasets.map((e) => {
          return {
            label: e.chartDataX,
            data: e.chartDataY,
            backgroundColor: e.backgroundColor,
            borderWidth: e.global.borderWidth
              ? options['borderWidth']
              : e.borderWidth,
            borderColor: e.global.borderColor
              ? options['borderColor']
              : e.borderColor,
            borderRadius: e.global.borderRadius
              ? options['borderRadius']
              : e.borderRadius,
          };
        });
      } else {
        //TODO find way to give back max value to editor
        const layers = (() => {
          if (!options.customLayers) return this.maxLayers;
          if (options.layers > this.maxLayers) return this.maxLayers;
          if (options.layers < 0) return 0;
          else return options.layers;
        })();
        const newDatasets = [];
        const oldDatasets = this.modules.element.data.datasets;
        for (let i = 0; i < layers; i++) {
          const label = 'dataset' + i;
          const data = [];
          const backgroundColor = [];
          const borderWidth = [];
          const borderRadius = [];
          const borderColor = [];
          oldDatasets.forEach((dataset) => {
            data.push(dataset.chartDataY[i]);
            backgroundColor.push(dataset.backgroundColor);

            borderColor.push(
              dataset.global.borderColor
                ? options['borderColor']
                : dataset.borderColor,
            );
            borderWidth.push(
              dataset.global.borderWidth
                ? options['borderWidth']
                : dataset.borderWidth,
            );
            borderRadius.push(
              dataset.global.borderRadius
                ? options['borderRadius']
                : dataset.borderRadius,
            );
          });
          newDatasets.push({
            label,
            data,
            backgroundColor,
            borderWidth,
            borderColor,
            borderRadius,
          });
        }
        return newDatasets;

        // For the slider option
        // return this.modules.element.chartData.map((e) => {
        //   if (Array.isArray(e.chartData)) {
        //     return e.chartData[this.sliderValue]
        //       ? e.chartData[this.sliderValue]
        //       : 0;
        //   } else {
        //     return e.chartData;
        //   }
        // });
      }
    },
    showAxis() {
      return !['doughnut', 'polarArea', 'pie', 'wordcloud'].includes(
        this.modules.element.chartType,
      );
    },
    labels() {
      if (
        ['doughnut', 'polarArea', 'pie'].includes(
          this.modules.element.chartType,
        ) &&
        !this.modules.element.scriptedDatasets
      ) {
        return this.modules.element.data.datasets.map((dataset) => {
          return dataset.chartDataX;
        });
      }
      const labels = [...this.modules.element.data.labels];
      const largestDataset = Math.max(
        ...this.datasets.map((set) => {
          return set.data.length;
        }),
      );
      const diff = largestDataset - labels.length;
      const noLabel = 'No Label';
      if (diff <= 0) return labels;
      for (let i = 0; i < diff; i++) {
        labels.push(noLabel);
      }
      return labels;
    },

    chartType() {
      return this.modules.element.chartType == 'wordCloud'
        ? 'wordCloud'
        : this.modules.element.chartType;
    },
    options() {
      if (this.modules.element.editor.scriptedOptions)
        return {
          ...this.modules.element.scriptedOptions,
          onClick: (event) => this.emitClickEvent(event),
        };
      const options = this.modules.element.options;
      return {
        plugins: {
          legend: {
            display: this.chartType == 'wordCloud' ? false : true,
            position: 'top',
            align: 'start',
            labels: {
              boxHeight: options.legendFontSize
                ? options.legendFontSize * 1.5
                : 48,
              boxWidth: 12,
              borderRadius: '6px',
              padding: 20,
              font: {
                size: options.legendFontSize || 18,
                // style: 'bold',
              },
            },
          },
        },
        borderWidth: options.borderWidth,
        borderRadius: options.borderRadius,
        indexAxis: options.horizontal ? 'y' : 'x',
        scales: this.showAxis
          ? {
              xAxes: {
                title: {
                  align: options.labelAlign,
                  display: options.showLabel,
                  text: options.labelX,
                  color: options.colorAxis,
                },
                grid: {
                  display: options.showGrid,
                  borderColor: options.colorAxis,
                  color: options.colorGridLines,
                },
                beginAtZero: options.beginAtZero,
                stacked: options.stacked,
                suggestedMin: options.minX,
                suggestedMax: options.maxX,
                display: options.showAxis,
                ticks: {
                  color: options.colorAxis,
                  precision: 0,
                  callback: function (value, index, ticks) {
                    return formatTicks.call(
                      this,
                      value,
                      index,
                      ticks,
                      options.ticksUnitX,
                      options.ticksUnitPosition,
                    );
                  },
                },
              },

              yAxes: {
                title: {
                  align: options.labelAlign,
                  display: options.showLabel,
                  text: options.labelY,
                  color: options.colorAxis,
                },
                grid: {
                  display: options.showGrid,
                  borderColor: options.colorAxis,
                  color: options.colorGridLines,
                },
                beginAtZero: options.beginAtZero,
                stacked: options.stacked,
                suggestedMin: options.minY,
                suggestedMax: options.maxY,
                display: options.showAxis,
                ticks: {
                  color: options.colorAxis,
                  precision: 0,
                  callback: function (value, index, ticks) {
                    return formatTicks.call(
                      this,
                      value,
                      index,
                      ticks,
                      options.ticksUnitY,
                      options.ticksUnitPosition,
                    );
                  },
                },
              },
            }
          : {},
        responsive: true,
        aspectRatio: 1.5,
        maintainAspectRatio: false,
        onClick: (event) => this.emitClickEvent(event),
      };
    },

    sliderValue() {
      return this.modules.element.sliderValue
        ? this.modules.element.sliderValue * 1
        : 0;
    },
    chartObject() {
      return {
        data: {
          datasets: this.datasets,
          labels: this.labels,
        },
        options: this.options,
        type: this.chartType,
        plugins: [
          {
            beforeInit(chart) {
              const originalFit = chart.legend.fit;
              chart.legend.fit = function fit() {
                originalFit.bind(chart.legend)();
                this.height += 20;
              };
            },
          },
        ],
      };
    },
  },
  watch: {
    chartObject: {
      deep: true,
      handler(newValue, oldValue) {
        if (!newValue) return;
        if (!isEqual(newValue, oldValue)) this.updateChart(newValue, oldValue);
      },
    },
  },
  mounted() {
    this.createChart();
  },
  methods: {
    emitClickEvent($event) {
      this.$emit('event', {
        name: 'itemClick',
        event: { event: $event, clicked: Chart.getElementAtEvent($event) },
      });
    },
    async createChart() {
      if (this.chart) this.chart.destroy();
      const ctx = this.$refs.chart.getContext('2d');

      await initializeChartJs();

      this.chart = new Chart(ctx, this.chartObject);
    },
    updateChart(newData, oldData) {
      //Credits to vue-chart.js
      if (oldData) {
        const chart = this.chart;

        // Get new and old DataSet Labels
        const newDatasetLabels = newData.data.datasets.map((dataset) => {
          return dataset.label;
        });

        const oldDatasetLabels = oldData.data.datasets.map((dataset) => {
          return dataset.label;
        });

        // Stringify 'em for easier compare
        const oldLabels = JSON.stringify(oldDatasetLabels);
        const newLabels = JSON.stringify(newDatasetLabels);

        // Check if Labels are equal and if dataset length is equal

        if (
          newLabels === oldLabels &&
          oldData.data.datasets.length === newData.data.datasets.length
        ) {
          newData.data.datasets.forEach((dataset, i) => {
            // Get new and old dataset keys
            const oldDatasetKeys = Object.keys(oldData.data.datasets[i]);
            const newDatasetKeys = Object.keys(dataset);

            // Get keys that aren't present in the new data
            const deletionKeys = oldDatasetKeys.filter((key) => {
              return key !== '_meta' && newDatasetKeys.indexOf(key) === -1;
            });

            // Remove outdated key-value pairs
            deletionKeys.forEach((deletionKey) => {
              delete chart.data.datasets[i][deletionKey];
            });

            // Update attributes individually to avoid re-rendering the entire chart
            for (const attribute in dataset) {
              if (Object.prototype.hasOwnProperty.call(dataset, attribute)) {
                chart.data.datasets[i][attribute] = dataset[attribute];
              }
            }
          });

          if (Object.prototype.hasOwnProperty.call(newData.data, 'labels')) {
            chart.data.labels = newData.data.labels;
          }

          if (Object.prototype.hasOwnProperty.call(newData, 'type')) {
            chart.config._config.type = newData.type;
          }

          if (Object.prototype.hasOwnProperty.call(newData, 'options')) {
            chart.options = newData.options;
          }
          chart.update();
        } else {
          if (chart) {
            chart.destroy();
          }
          this.createChart();
        }
      } else {
        if (this.chart) {
          this.chart.destroy();
        }
        this.createChart();
      }
    },
  },
};
</script>
