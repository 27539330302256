











export default {
  name: 'EditorOption',
  props: {
    label: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    // TODO: Implement isBlocked
    isBlocked: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
      default: 'w-20',
    },
    fullWidthLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      classes: {
        slot: 'self-stretch flex justify-end',
      },
    };
  },
  computed: {
    wrapperClasses() {
      return [
        'grid gap-2 items-center mb-2',
        this.fullWidthLabel ? 'grid-cols-1' : 'grid-cols-auto-1fr',
      ];
    },
    descriptionClasses() {
      return [
        'mb-0 caption uppercase text-gray-600',
        this.fullWidthLabel ? 'w-full' : this.labelWidth,
      ];
    },
  },
};
