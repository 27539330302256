















import { computed } from '@vue/composition-api';
import { useI18n, useLayoutArea, useShell } from '@/composables';
import { defineComponent } from '@vue/composition-api';
import { ActionControl } from '@fillip/api';

export default defineComponent({
  name: 'Legal',
  props: {
    content: {
      type: String,
      default: 'imprint',
    },
  },
  setup(props) {
    const { content } = useShell();
    const { tr } = useI18n();
    const { close } = useLayoutArea('dialog');

    const overlayContent = computed(() => {
      return content.value[props.content];
    });

    const actions = computed<ActionControl[]>(() => {
      return [
        {
          type: 'action',
          name: 'close',
          title: tr('general.close'),
          action: () => close(),
          icon: 'times',
        },
      ];
    });

    return { overlayContent, actions };
  },
});
