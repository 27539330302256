















import AuthContainer from '@/features/auth/components/AuthContainer.vue';
import PasswordInput from '@/components/form-inputs/PasswordInput.vue';
import { defineComponent, ref } from '@vue/composition-api';
import { useRouter, useFillip } from '@/composables';

export default defineComponent({
  name: 'NewPassword',
  components: {
    AuthContainer,
    PasswordInput,
  },
  setup() {
    const password = ref('');
    const form = ref(null);
    const { $route, router } = useRouter();

    const { fillip } = useFillip();

    // TODO: Check if token is active before sending request
    const submit = async () => {
      await fillip.auth.resetForgotPassword(password.value, $route.query.token);
      router.push('/');
    };

    return {
      password,
      form,
      submit,
    };
  },
});
