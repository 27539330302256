<template>
  <v-list :subheader="Boolean(header)" :two-line="hasSubtitles">
    <v-subheader v-if="Boolean(header)">{{ header }}</v-subheader>
    <v-slide-y-transition v-if="items.length === 0" class="py-0">
      <slot name="empty">
        <v-list-item
          class="cursor-pointer flex-col content-center"
          @click.capture="$emit('addItem')"
        >
          <v-img
            class="mx-auto"
            max-width="50%"
            :src="$styles.images.addNewEmpty"
          ></v-img>
        </v-list-item>
      </slot>
    </v-slide-y-transition>
    <v-slide-y-transition v-if="items && items.length > 0" class="py-0" group>
      <v-list-item
        v-for="item in items"
        :key="item.id"
        :value="item.id"
        :title="item.title || ''"
        :class="{ 'v-list-item--active': highlightActive && item.active }"
        link
        @click="itemSelected(item.id)"
      >
        <slot name="prepend" :item="item"></slot>

        <v-list-item-avatar
          v-if="item.icon || item.number"
          :color="item.color || item.active ? 'primary' : ''"
        >
          <BaseIcon
            v-if="item.icon"
            :icon="item.icon"
            :color="item.color || item.active ? 'white' : ''"
          />
          <span
            v-if="item.number"
            :class="{ 'white--text': item.color || item.active }"
            class="headline"
          >
            {{ item.number }}
          </span>
        </v-list-item-avatar>

        <slot :item="item">
          <v-list-item-content>
            <v-list-item-title>{{ item.title || item.name }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.subtitle">
              {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </slot>

        <slot :slot-scope="{ item }" name="append"></slot>

        <template v-if="itemActions && itemActions.length > 0">
          <v-list-item-action v-for="action in itemActions" :key="action.name">
            <BaseButton
              v-if="action.if == undefined || action.if"
              :key="action.name"
              size="icon"
              :icon="action.icon"
              :title="action.title"
              @click.capture.stop="triggerAction(action, item)"
            ></BaseButton>
          </v-list-item-action>
        </template>

        <v-list-item-action v-if="subActions && subActions.length > 0">
          <v-menu bottom right>
            <template #activator="{ on, attrs }">
              <BaseButton
                size="icon"
                color="grey"
                v-bind="attrs"
                icon="ellipsis-v"
                :title="$t('general.more')"
                v-on="on"
              />
            </template>
            <v-list>
              <v-list-item v-for="action in subActions" :key="action.name" link>
                <v-list-item-avatar v-if="action.icon">
                  <BaseIcon :icon="action.icon" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    @click.capture="triggerAction(action, item)"
                  >
                    {{ action.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-slide-y-transition>
  </v-list>
</template>

<script>
export default {
  name: 'BaseList',

  props: {
    header: String,
    highlightActive: Boolean,
    items: {
      type: Array,
      default: () => [],
    },
    itemActions: {
      type: Array,
      default: () => [],
    },
    subActions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasSubtitles() {
      return Boolean(this.items && this.items[0] && this.items[0].subtitle);
    },
  },
  methods: {
    itemSelected(itemId) {
      this.$emit('itemSelected', itemId);
    },
    triggerAction(action, item) {
      if (action.action) {
        action.action.bind(this)(item.id);
      } else {
        return this.$emit('click');
      }
    },
  },
};
</script>
