var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditorContainer',[_c('ModuleHeader',{attrs:{"title":_vm.$t('children.title'),"is-active":_vm.buffer != null}},[_c('ActivateToggleEditor',{attrs:{"id":_vm.id,"module-name":"children"}})],1),_c('v-expansion-panel-content',[(_vm.buffer)?_c('DraggableArrayInput',{attrs:{"variant":"rows"},on:{"change":_vm.save,"add":_vm.addItem,"remove":_vm.removeItem},scopedSlots:_vm._u([{key:"itemHeader",fn:function(ref){
var index = ref.index;
return [_vm._v(" # "+_vm._s(index)+" ")]}},{key:"itemBody",fn:function(ref){
var index = ref.index;
return [_c('div',{staticClass:"flex m-2"},[_c('PrimitiveTemplateSelector',{staticClass:"v-select--minimal",attrs:{"value":_vm.buffer.default[index].templateId},on:{"input":function($event){(_vm.buffer.default[index].templateId = $event), _vm.save()}}}),(
              _vm.buffer.default[index].templateId &&
              !_vm.buffer.default[index].templateId.startsWith('function:')
            )?_c('BaseButton',{attrs:{"icon-only":"","icon":"external-link","small":""},on:{"click":function($event){return _vm.openTemplate(_vm.buffer.default[index].templateId)}}}):_vm._e()],1),_c('div',{staticClass:"m-2"},[_c('InputScript',{attrs:{"label":"Query"},on:{"blur":_vm.save},model:{value:(_vm.buffer.default[index].query),callback:function ($$v) {_vm.$set(_vm.buffer.default[index], "query", $$v)},expression:"buffer.default[index].query"}})],1),_c('div',{staticClass:"m-2"},[_c('InputScript',{attrs:{"label":"Condition"},on:{"blur":_vm.save},model:{value:(_vm.buffer.default[index].condition),callback:function ($$v) {_vm.$set(_vm.buffer.default[index], "condition", $$v)},expression:"buffer.default[index].condition"}})],1),_c('div',{staticClass:"m-2"},[_c('InputScript',{attrs:{"label":"Identity"},on:{"blur":_vm.save},model:{value:(_vm.buffer.default[index].identity),callback:function ($$v) {_vm.$set(_vm.buffer.default[index], "identity", $$v)},expression:"buffer.default[index].identity"}})],1),(_vm.getInterfaceProps(_vm.buffer.default[index].templateId))?_c('EditorSection',{attrs:{"label":"children.mapping"}},_vm._l((_vm.getInterfaceProps(
              _vm.buffer.default[index].templateId
            )),function(prop){return _c('div',{key:prop.key,staticClass:"mb-4 rounded-md border-1 border-1 border-gray-300"},[_c('div',{staticClass:"flex justify-between mb-2"},[_c('strong',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(prop.title))]),_c('v-chip',{attrs:{"pill":"","x-small":""}},[_vm._v(_vm._s(prop.type))])],1),_c('div',{staticClass:"grid grid-cols-1fr-auto gap-2"},[(
                  _vm.buffer.default[index].propMappings &&
                  _vm.buffer.default[index].propMappings[prop.key]
                )?[_c('InputScript',{on:{"blur":_vm.save},model:{value:(_vm.buffer.default[index].propMappings[prop.key]),callback:function ($$v) {_vm.$set(_vm.buffer.default[index].propMappings, prop.key, $$v)},expression:"buffer.default[index].propMappings[prop.key]"}}),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.removeCustomMapping(index, prop)}}},[_c('BaseIcon',{attrs:{"icon":"times","size":"medium"}})],1)]:[_c('code',[_vm._v(_vm._s(prop.defaultValue))]),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.setCustomMapping(index, prop)}}},[_c('BaseIcon',{attrs:{"icon":"pencil","size":"medium"}})],1)]],2)])}),0):_vm._e()]}}],null,false,3051508381),model:{value:(_vm.buffer.default),callback:function ($$v) {_vm.$set(_vm.buffer, "default", $$v)},expression:"buffer.default"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }