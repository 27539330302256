




















import { useLayoutArea, useShell } from '@/composables';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AuthTerms',
  setup() {
    const { content, classes } = useShell();
    const { navigate: dialog } = useLayoutArea('dialog');

    return {
      content,
      classes,
      dialog,
    };
  },
});
