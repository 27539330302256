



































































import {
  useParticipants,
  useLocations,
  usePermissions,
  useI18n,
  useFillip,
  useDocuments,
} from '@/composables';
import { ActionControl } from '@fillip/api';
import { computed, defineComponent, inject, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'ParticipantOverview',
  setup() {
    const { canSeePrototype, canEditCommunity } = usePermissions();
    const { getAllParticipants, getParticipantsAtScene, getAvatarUrl } =
      useParticipants();
    const { getLocation } = useLocations();
    const { tr } = useI18n();
    const { $me } = useFillip();

    const {
      openImport,
      openExportForDocuments,
      openParticipant,
      defaultDocumentActions,
    } = useDocuments();

    const filters = ['all', 'online', 'scene']; // ['all', 'online', 'circle', 'scene'],
    const activeFilter = ref('online');

    const globalActions = computed(() => {
      const actions: ActionControl[] = [
        {
          type: 'action',
          title: tr('explorer.import'),
          action: () => openImport('participants'),
          icon: 'file-import',
        },
        {
          type: 'action',
          title: tr('explorer.export'),
          action: () => openExportForDocuments(getAllParticipants(true)),
          icon: 'file-export',
        },
      ];
      return actions;
    });

    const filteredParticipants = computed(() => {
      if (activeFilter.value == 'all') return getAllParticipants(true);
      // if (activeFilter.value == 'circle')
      //   return getParticipantsInCircle(true, $me.value.participantCircle.circle);
      if (activeFilter.value == 'scene') {
        return getParticipantsAtScene(false, getLocation($me.value.id));
      }

      return getAllParticipants(false);
    });

    return {
      getAllParticipants,
      getParticipantsAtScene,
      getAvatarUrl,
      getLocation,
      canSeePrototype,
      canEditCommunity,
      filters,
      activeFilter,
      defaultDocumentActions,
      filteredParticipants,
      openParticipant,
      openImport,
      globalActions,
    };
  },
});
