var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.elementTable)?_c('div',{staticClass:"w-full overflow-x-auto print:overflow-x-visible",class:{ 'grid items-start grid-cols-1fr-auto': _vm.allowEditing }},[_c('table',{staticClass:"w-full table-auto border-collapse"},[_c('thead',[_c('tr',{class:{ 'bg-gray-100': _vm.allowEditing }},[(_vm.allowEditing)?_c('th',{staticClass:"w-12 bg-gray-100"}):_vm._e(),_vm._l((_vm.columns),function(column,columnIndex){return _c('th',{key:((column.path) + "-header"),staticClass:"font-bold",class:[
            _vm.classes.cells,
            _vm.allowEditing ? 'cursor-text' : '',
            _vm.isColumnEditing(columnIndex) ? 'bg-gray-200' : '' ],attrs:{"scope":"col"},on:{"click":function($event){return _vm.toggleColumnEditing(columnIndex)}}},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex-grow"},[(_vm.isColumnEditing(columnIndex))?_c('div',{staticClass:"cellContainerClasses"},[_c('InputStyledText',{attrs:{"focus-on-mount":"","value":column.title,"bounds":".table"},on:{"blur":function($event){return _vm.updateColumnHeader($event, columnIndex)}}})],1):_c('div',{staticClass:"cellContainerClasses",domProps:{"innerHTML":_vm._s(
                  _vm.sanitizeContent(
                    typeof column.title == 'string'
                      ? column.title
                      : column.title.html
                  )
                )}})]),(_vm.allowEditing)?_c('BaseButton',{attrs:{"icon":"trash","size":"icon","x-small":""},on:{"click":function($event){return _vm.removeColumn(columnIndex)}}}):_vm._e()],1)])})],2)]),_c('tbody',_vm._l((_vm.rows),function(row,rowIndex){return _c('tr',{key:'r-' + rowIndex},[(_vm.allowEditing)?_c('td',{staticClass:"w-14 px-2 bg-gray-100",class:_vm.classes.cells},[_vm._v(" "+_vm._s(rowIndex + 1)+" "),_c('BaseButton',{staticClass:"ml-2",attrs:{"icon":"trash","size":"icon","x-small":""},on:{"click":function($event){return _vm.removeRow(rowIndex)}}})],1):_vm._e(),_vm._l((row),function(cell,cellIndex){return _c('td',{key:'c-' + rowIndex + '-' + cellIndex,class:[
            _vm.getCellClasses(cellIndex),
            _vm.isCellEditing(rowIndex, cellIndex) ? 'bg-gray-200' : '',
            _vm.canColumnBeEdited(cellIndex)
              ? 'hover:bg-gray-50 cursor-text'
              : '' ],on:{"click":function($event){return _vm.toggleCellEditing(rowIndex, cellIndex)}}},[(_vm.isCellEditing(rowIndex, cellIndex))?_c('div',{class:_vm.cellContainerClasses},[_c('InputStyledText',{attrs:{"focus-on-mount":"","value":cell,"bounds":".table","options":{}},on:{"blur":function($event){return _vm.updateCellContent($event, rowIndex, cellIndex)}}})],1):_c('div',{class:_vm.cellContainerClasses,domProps:{"innerHTML":_vm._s(_vm.sanitizeContent(cell))}})])})],2)}),0)]),(_vm.allowEditing)?_c('button',{staticClass:"w-8 h-12 rounded-r-lg bg-gray-100 hover:bg-gray-200 cursor-pointer grid justify-center items-center",on:{"click":_vm.addColumn}},[_c('BaseIcon',{attrs:{"icon":"plus"}})],1):_vm._e(),(_vm.allowEditing)?_c('button',{staticClass:"w-14 h-8 rounded-b-lg bg-gray-100 hover:bg-gray-200 cursor-pointer grid justify-center items-center",on:{"click":_vm.addRow}},[_c('BaseIcon',{attrs:{"icon":"plus"}})],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }