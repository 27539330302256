import { absoluteArranger } from './absoluteArranger';
// import {backgroundArranger } from './backgroundArranger';
import { blockArranger } from './blockArranger';
import { circleArranger } from './circleArranger';
import { defaultArranger } from './defaultArranger';
import { divideArranger } from './divideArranger';
import { flexArranger } from './flexArranger';
import { gridArranger } from './gridArranger';
// import { gridArranger } from './newGridArranger';
// import {oldGridArranger } from './oldGridArranger';
import { originArranger } from './originArranger';
import { rectangleArranger } from './rectangleArranger';
import { slidesArranger } from './slidesArranger';
import { sphereArranger } from './sphereArranger';

export const Arrangers = {
  'arrangement.absolute': absoluteArranger,
  // 'arrangement.background': backgroundArranger,
  'arrangement.block': blockArranger,
  'arrangement.circle': circleArranger,
  'arrangement.default': defaultArranger,
  'arrangement.divide': divideArranger,
  'arrangement.flex': flexArranger,
  'arrangement.grid': gridArranger,
  // 'arrangement.grid': newGridArranger,
  // 'arrangement.grid': oldGridArranger,
  'arrangement.origin': originArranger,
  'arrangement.rectangle': rectangleArranger,
  'arrangement.slides': slidesArranger,
  'arrangement.sphere': sphereArranger,
};
