































import Vue from 'vue';

import { DataDocument } from '@fillip/api';
import { useParticipants } from '@/composables';

export default Vue.extend({
  name: 'ParticipantSelector',
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    participants: {
      type: Array,
      default: () => [],
    },
    breakoutRoom: {
      type: Object,
      default: () => ({}),
    },
    template: {
      type: String,
    },
    route: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getParticipantsWithSameFocus } = useParticipants();
    return {
      getParticipantsWithSameFocus,
    };
  },
  data() {
    return {
      numberOfParticipants: 1,
    };
  },
  computed: {
    participantsInBreakoutRoom(): DataDocument[] {
      // const breakoutRoomLobbyRoute = this.route;
      // const breakoutRoomSegment = createRouteSegment(
      //   this.template,
      //   this.breakoutRoom.id,
      // );
      // const breakoutRoomRoute = addPathSegment(
      //   breakoutRoomLobbyRoute,
      //   breakoutRoomSegment,
      // );
      return this.getParticipantsWithSameFocus(false, this.breakoutRoom.id);
    },
  },
  methods: {
    participantsChanged(newParticipants) {
      const oldParticipants = this.participantsInBreakoutRoom;

      if (oldParticipants.length > newParticipants.length) {
        for (const participant of oldParticipants) {
          if (
            !newParticipants.some(
              (newParticipant) => newParticipant == participant.id,
            )
          ) {
            return this.$emit('remove', participant.id);
          }
        }
      } else {
        for (const newParticipant of newParticipants) {
          if (
            !oldParticipants.some(
              (participant) => participant.id == newParticipant,
            )
          ) {
            return this.$emit('move', newParticipant);
          }
        }
      }
    },
  },
});
