import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagSubline: DataDocument = {
  id: 'tag-subline',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Subline',
    icon: 'ellipsis-h-alt',
    isProtected: true,
  },
  element: {
    type: 'element.text',
    textFormat: 'h4',
    textContent: ':$text',
  },
  class: {
    class: ':`${$containerClasses}`',
    elementClass: ':`${$elementClasses}`',
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'subline',
    title: 'Subline',
    category: 'content',
    icon: 'ellipsis-h-alt',
    editor: 'default',
  },
  interfaceDefinition: {
    title: '',
    inheritFrom: 'baseContent',
    props: [
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":`${$$contentTagClasses} ${$$headlineClasses} flex-initial text-lg uppercase tracking-wider ${$containerClasses || properties?.containerClasses?.value || ''}`",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":`font-medium ${$elementClasses || properties?.elementClasses?.value || ''}",
      },
    ],
  },
};
