


































import SideDrawer from '@/features/app/SideDrawer.vue';
import AppBar from '@/features/app-bar/AppBar.vue';
import MainDrawer from '@/features/app/MainDrawer.vue';

import Canvas from '@/features/main/canvas/Canvas.vue';

import GlobalAudioMixer from '@/features/video-conferencing/GlobalAudioMixer.vue';
import ParticipantStatus from '@/features/participant/ParticipantStatus';
import ActionsManager from '@/features/main/actions/ActionsManager';
import Scene from '@/features/main/scene/Scene';
import Router from '@/features/main/router/Router';
import ComponentFactory from '@/features/main/component-factory/ComponentFactory';
import ThemeManager from '@/features/main/theme/ThemeManager';
import VideoConferencingManager from '@/features/main/video-conferencing/VideoConferencingManager';
import useRoot from '@/composables/data/useRoot';

import { defineComponent, provide, ref, reactive } from '@vue/composition-api';

export default defineComponent({
  name: 'Main',
  components: {
    ActionsManager,
    AppBar,
    Canvas,
    Router,
    Scene,
    ComponentFactory,
    GlobalAudioMixer,
    MainDrawer,
    ParticipantStatus,
    SideDrawer,
    ThemeManager,
    VideoConferencingManager,
  },
  setup(props, context) {
    const { provideRoot } = useRoot();
    provideRoot(context);
    const actions = ref(null);
    const camera = ref(null);
    const canvas = ref(null);
    const router = ref(null);
    const world = ref(null);
    const themeManager = ref(null);
    const componentFactory = ref(null);
    const participantStatus = ref(null);
    const videoConferencingManager = ref(null);
    const sceneComponent = ref(null);

    const viewport = reactive({ width: 0, height: 0 });

    const onCanvasResize = ({ width, height }) => {
      viewport.width = width;
      viewport.height = height;
    };

    const onCameraMounted = (newCamera) => {
      camera.value = newCamera;
    };

    provide('actions', actions);
    provide('camera', camera);
    provide('canvas', canvas);
    provide('router', router);
    provide('componentFactory', componentFactory);
    provide('viewport', viewport);
    provide('participantStatus', participantStatus);
    provide('themeManager', themeManager);
    provide('videoConferencingManager', videoConferencingManager);
    provide('scene', sceneComponent);

    return {
      actions,
      camera,
      canvas,
      componentFactory,
      onCameraMounted,
      onCanvasResize,
      participantStatus,
      router,
      themeManager,
      videoConferencingManager,
      world,
      sceneComponent,
    };
  },
});
