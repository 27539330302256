




























































import { useI18n } from '@/composables';
import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    actions: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    subActions: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    icon: {
      type: String,
      default: '',
    },
    back: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    glass: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    subactionTrigger: {
      type: String,
      default: 'ellipsis-v',
    },
    itemArgument: {
      type: [Object, Array, String, Number, Boolean],
      default: null,
    },
  },
  setup(props: any, { emit }) {
    const wrapperClasses = computed(() => {
      return [
        props.large ? 'px-6 py-4' : 'p-2',
        {
          'shadow-lg': !props.flat,
          'border-0 border-b-1 border-solid border-gray-300 mb-px':
            props.flat && !props.color && !props.glass,
          'blurred-glass': props.glass,
        },
      ];
    });

    const textClasses = computed(() => {
      return {
        'text-white': props.color == 'primary' || props.dark,
      };
    });

    const isDark = computed(() => {
      if (props.color == 'primary') {
        return true;
      }
      return props.dark;
    });

    const triggerAction = (action: any) => {
      if (action.action) {
        action.action(props.itemArgument);
      } else {
        emit('click');
      }
    };

    const { maybeTr } = useI18n();
    const translatedTitle = computed(() => maybeTr(props.title));

    return {
      isDark,
      triggerAction,
      wrapperClasses,
      textClasses,
      translatedTitle,
    };
  },
});
