import { FillipVueClient } from '../client';
import { IFillipVueClientModule } from '../base.client';
import {
  CouldNotCreate,
  CouldNotUpdate,
  CouldNotFindAll,
  CreateCommunityRequest,
  NoCommunityFoundForSlug,
  UpdateCommunityRequest,
  Community,
  //UpdateCommunityBrandingRequest,
} from '@fillip/api';

export class FillipVueCommunitiesClient extends IFillipVueClientModule {
  constructor(public root: FillipVueClient) {
    super('communities', root);
  }

  // TODO: Refactor to use abstract REST query
  async getAll(): Promise<Community[]> {
    try {
      const { data } = await this.axios.get(`${this.apiUrl}/communities`);
      this.store.commit('client/SET_COMMUNITIES', data);
      return this.state.allCommunities;
    } catch (error) {
      console.warn('Error getAll in communities.client: ', error);
      this.handleException(CouldNotFindAll());
    }
  }

  public async createOne(
    community: CreateCommunityRequest,
  ): Promise<Community> {
    try {
      const { data } = await this.axios.post(
        `${this.apiUrl}/communities`,
        community,
      );
      this.store.commit('client/ADD_COMMUNITY', data);
      return data;
    } catch (error) {
      console.warn('Error CreateOne in communities.client: ', error);
      if (error.message == 'Request failed with status code 500') {
        this.handleException(CouldNotCreate(community.title));
      } else this.handleException(error);
    }
  }

  public async updateOne(
    updatedCommunity: UpdateCommunityRequest,
    slug: string,
  ): Promise<Community> {
    try {
      const { data } = await this.axios.patch(
        `${this.apiUrl}/communities/${slug}`,
        updatedCommunity,
      );
      this.getAll();
      return data;
    } catch (error) {
      console.warn('Error UpdateOne in communities.client', error);
      if (error.message == 'Request failed with status code 500') {
        this.handleException(CouldNotUpdate(slug));
      } else this.handleException(error);
    }
  }

  private findCommunityBySlug(slug: string): Community {
    return this.state.allCommunities.find(
      (community) => community.slug === slug,
    );
  }

  public async getOneBySlug(slug: string): Promise<Community> {
    let community = this.findCommunityBySlug(slug);
    if (community) {
      return community;
    }
    await this.getAll();
    // TODO: Add backend route to get one by slug
    community = this.findCommunityBySlug(slug);
    if (!community) {
      this.handleException(NoCommunityFoundForSlug(slug));
    }

    return community;
  }

  public async getBySlug(slug: string): Promise<Community> {
    try {
      const { data } = await this.axios.get(
        `${this.apiUrl}/communities/${slug}/public`,
      );
      return data;
    } catch (error) {
      console.warn('Error: getBySlug in communities.client', error);
      this.handleException(error);
    }
  }

  public async getIdFromSlug(slug: string): Promise<number> {
    return (await this.getOneBySlug(slug)).id;
  }

  public async disconnectAll() {
    return await this.root.realtime.disconnectAllCommunities();
  }

  public async deleteCommunity(communitySlug: string): Promise<void> {
    try {
      const { data } = await this.axios.delete(
        `${this.apiUrl}/communities/${communitySlug}`,
      );
      this.getAll();
      return data;
    } catch (error) {
      console.warn('Error DeleteOne in communities.client: ', error);
      this.handleException(error);
    }
  }
}
