











































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleTransitionsMeta } from '@fillip/api';

export default Vue.extend({
  name: 'TransitionsEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'transitions',
    });

    const transitionCategories = Object.keys(
      ModuleTransitionsMeta.schema.transitionCategories,
    );

    const enterTransitions = ModuleTransitionsMeta.schema.enterTransitions;
    const transformTransitions =
      ModuleTransitionsMeta.schema.transformTransitions;
    const leaveTransitions = ModuleTransitionsMeta.schema.leaveTransitions;

    const animationTargets = ModuleTransitionsMeta.schema.animationTargets;

    return {
      buffer,
      save,
      transitionCategories,
      transitions: {
        enter: enterTransitions,
        transform: transformTransitions,
        leave: leaveTransitions,
      },
      animationTargets,
    };
  },
});
