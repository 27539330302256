import ElementExpandableImage from './ElementImage.vue';

import { PropType, computed, defineComponent, h } from '@vue/composition-api';
import type { Modules, ElementImage as ModuleElementImage } from '@fillip/api';

export const ElementImage = defineComponent({
  name: 'ElementImage',
  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      default: '',
    },
    modules: {
      type: Object as PropType<Modules & { element: ModuleElementImage }>,
      default: () => ({}),
    },
  },
  setup(props) {
    const src = computed(() => props.modules.element?.imageSrc);
    const title = computed(() => props.modules.info?.title);
    const description = computed(() => props.modules.element?.imageDescription);
    const copyright = computed(() => props.modules.element?.imageCopyright);
    const expandOnClick = computed(() => props.modules.element?.expandOnClick);

    return () => {
      if (!src.value) {
        return h('div', {}, '');
      }
      if (expandOnClick.value) {
        return h(ElementExpandableImage, {
          attrs: {
            modules: props.modules,
            id: props.id,
          },
          ref: 'el',
        });
      }
      return h(
        'img',
        {
          attrs: {
            src: src.value,
            alt: description.value,
            title: copyright.value || title.value,
          },
          style: {
            maxWidth: '100%',
            maxHeight: '100%',
          },
          ref: 'el',
        },
        [],
      );
    };
  },
});
