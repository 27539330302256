



































































































import Vue from 'vue';
import { useDocuments, useSave, useVolatileData, useData } from '@/composables';
import { BaseModuleEditor } from '../base';
import {
  frontendPrefixMarker,
  ModuleChildrenMeta,
  rootDataId,
  PropsDefinition,
} from '@fillip/api';
import clone from 'rfdc/default';
import PrimitiveTemplateSelector from '@/components/primitive-editor/types/PrimitiveTemplateSelector.vue';
import store from '@/store';

export default Vue.extend({
  name: 'ChildrenEditor',
  components: {
    PrimitiveTemplateSelector,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'children',
    });
    const { getData } = useData();
    const { setVolatileProp } = useVolatileData();
    const { editInSideDrawer } = useDocuments();

    const init = () => {
      buffer.value = clone(ModuleChildrenMeta.default);
      save();
    };

    const defaultItem = ModuleChildrenMeta.childDefault;

    // TODO: Move in DraggableArrayInput again
    const addItem = () => {
      buffer.value.default.push(clone(defaultItem));
      save();
    };
    const removeItem = (index) => {
      buffer.value.default.splice(index, 1);
      save();
    };

    const openTemplate = (id: string) => editInSideDrawer(id, true);

    const getInterfaceProps = (id: string) => {
      if (!id || id.startsWith(frontendPrefixMarker)) return;
      const interfaceDefinition = getData(id)?.interfaceDefinition;
      const result = [];
      if (interfaceDefinition?.inheritFrom) {
        const interfaceSchema = getData(interfaceDefinition.inheritFrom)
          ?.interfaceDefinition?.props;
        if (interfaceSchema) result.push(...interfaceSchema);
      }
      if (interfaceDefinition?.props) result.push(...interfaceDefinition.props);
      return result;
    };

    const setCustomMapping = (index: number, prop: PropsDefinition): void => {
      const item = buffer.value.default[index];
      if (!item.propMappings) Vue.set(item, 'propMappings', {});
      Vue.set(item.propMappings, prop.key, prop.defaultValue);
      save();
    };

    const removeCustomMapping = (
      index: number,
      prop: PropsDefinition,
    ): void => {
      const item = buffer.value.default[index];
      Vue.delete(item.propMappings, prop.key);
      if (!Object.keys(item.propMappings).length)
        Vue.delete(item, 'propMappings');
      save();
    };

    return {
      buffer,
      save,
      init,
      openTemplate,
      getInterfaceProps,
      setCustomMapping,
      removeCustomMapping,
      addItem,
      removeItem,
    };
  },
});
