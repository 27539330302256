type Update =
  | { op: 'ADD'; collection: string; id: string; value: any }
  | { op: 'REPLACE'; collection: string; id: string; value: any; oldValue: any }
  | { op: 'REMOVE'; collection: string; id: string; oldValue: any }
  | { op: 'RESET'; workingTree: any; oldWorkingTree: any };

export interface Commit {
  rev: number;
  updates: Update[];
}

export interface Snapshot {
  rev: number;
  workingTree: Record<string, any>;
}

export interface StorageAdapter {
  state: any;
  add(collection: string, id: string, value: any): Promise<void>;
  remove(collection: string, id: string): Promise<void>;
  replace(collection: string, id: string, value: any): Promise<void>;
  reset(workingTree: Record<string, any>): Promise<void>;
}

export abstract class BaseRepository {
  public abstract rev: number;
  public abstract state: any;
  public abstract applyCommit(commit: Commit): Promise<Commit> | Commit;
  public abstract loadSnapshot(snapshot: Snapshot): Promise<void> | void;
}

export class ReadOnlyRepository extends BaseRepository {
  public rev = 0;
  history: Commit[] = [];
  constructor(public adapter: StorageAdapter) {
    super();
  }

  public get state() {
    return this.adapter.state;
  }

  private async applyUpdate(update: Update) {
    if (update.op == 'ADD') {
      await this.adapter.add(update.collection, update.id, update.value);
    } else if (update.op == 'REPLACE') {
      await this.adapter.replace(update.collection, update.id, update.value);
    } else if (update.op == 'REMOVE') {
      await this.adapter.remove(update.collection, update.id);
    } else if (update.op == 'RESET') {
      await this.adapter.reset(update.workingTree);
    }
  }

  private async revertUpdate(update: Update) {
    if (update.op == 'ADD') {
      await this.adapter.remove(update.collection, update.id);
    } else if (update.op == 'REPLACE') {
      await this.adapter.replace(update.collection, update.id, update.oldValue);
    } else if (update.op == 'REMOVE') {
      await this.adapter.add(update.collection, update.id, update.oldValue);
    } else if (update.op == 'RESET') {
      await this.adapter.reset(update.oldWorkingTree);
    }
  }

  public async applyCommit(commit: Commit): Promise<Commit> {
    if (this.rev >= commit.rev) {
      // TODO: Throw error again after fixing duplicated commit revs
      console.error(
        `Invalid commit in ReadOnlyRepository: Commit ${commit.rev} is older than current revision ${this.rev}`,
      );
      // throw new Error('Invalid commit (older than current revision)');
    }

    const appliedUpdates = new Array<Update>();
    // CHECK @ISTA

    await Promise.all(
      commit.updates.map(async (update) => {
        try {
          await this.applyUpdate(update);
          appliedUpdates.push(update);
        } catch {
          // CHECK @ISTA
          await Promise.all(
            appliedUpdates.map(async (appliedUpdate) => {
              try {
                await this.revertUpdate(appliedUpdate);
              } catch {
                console.error(`Error reverting update`, appliedUpdate);
              }
            }),
          );
        }
      }),
    );

    this.history.push(commit);
    // TODO: Remove if guard after fixing duplicated commit revs
    if (this.rev < commit.rev) {
      this.rev = commit.rev;
    }
    return commit;
  }

  public async loadSnapshot(snapshot: Snapshot): Promise<void> {
    this.history = [];
    this.rev = snapshot.rev;
    await this.adapter.reset(snapshot.workingTree);
  }
}

export class Repository extends BaseRepository {
  constructor(context: string = '') {
    super();
    this.context = context;
  }

  public rev = 0;
  history: Commit[] = [];
  public workingTree: Record<string, any> = {};
  stagingArea: Update[] = [];
  public context: string;

  public getSnapshot(): Snapshot {
    return {
      rev: this.rev,
      workingTree: this.workingTree,
    };
  }

  public loadSnapshot(snapshot: Snapshot): void {
    if (this.stagingArea.length) {
      throw new Error('You have uncommited changes');
    }
    this.history = [];
    this.rev = snapshot.rev;
    this.workingTree = snapshot.workingTree;
  }

  public get state(): Record<string, any> {
    return this.workingTree;
  }

  public add(collection: string, id: string, value: Record<string, any>): void {
    let collectionMap = this.workingTree[collection];
    if (collectionMap && collectionMap[id]) {
      console.warn('Trying to add document that already exists', id);
      return this.replace(collection, id, value);
    }
    if (!collectionMap) {
      collectionMap = this.workingTree[collection] = {};
    }
    collectionMap[id] = value;

    this.stagingArea.push({
      op: 'ADD',
      collection,
      id,
      value,
    });
  }

  public replace(
    collection: string,
    id: string,
    value: Record<string, any>,
  ): void {
    const collectionMap = this.workingTree[collection];
    if (!collectionMap || !collectionMap[id]) {
      // throw new Error('Document doesnt exist');
      console.warn('Trying to replace non-existing document', id);
      return this.add(collection, id, value);
    }

    const oldValue = collectionMap[id];
    collectionMap[id] = value;
    this.stagingArea.push({
      op: 'REPLACE',
      collection,
      id,
      value,
      oldValue,
    });
  }

  public remove(collection: string, id: string): void {
    const collectionMap = this.workingTree[collection];
    if (!collectionMap || !collectionMap[id]) {
      throw new Error('Document doesnt exist');
    }

    const oldValue = collectionMap[id];
    delete collectionMap[id];
    this.stagingArea.push({
      op: 'REMOVE',
      collection,
      id,
      oldValue,
    });
  }

  public reset(workingTree: any): void {
    const oldWorkingTree = this.workingTree;
    this.workingTree = workingTree;
    this.stagingArea.push({
      op: 'RESET',
      workingTree,
      oldWorkingTree,
    });
  }

  public hasChanges(): boolean {
    return this.stagingArea.length > 0;
  }

  public commit(): Commit {
    if (this.stagingArea.length == 0) {
      throw new Error('Nothing to commit');
    }
    this.rev += 1;
    const commit = {
      rev: this.rev,
      updates: this.stagingArea,
    };
    this.stagingArea = [];
    this.history.push(commit);
    return commit;
  }

  private applyUpdate(update: Update): void {
    if (update.op == 'ADD') {
      let collectionMap = this.workingTree[update.collection];
      if (collectionMap && collectionMap[update.id]) {
        throw new Error('Invalid Insert, this should never happen');
      }
      if (!collectionMap) {
        collectionMap = this.workingTree[update.collection] = {};
      }
      collectionMap[update.id] = update.value;
    } else if (update.op == 'REPLACE') {
      const collectionMap = this.workingTree[update.collection];
      if (!collectionMap || !collectionMap[update.id]) {
        throw new Error('Invalid replace, this should never happen');
      }
      collectionMap[update.id] = update.value;
    } else if (update.op == 'REMOVE') {
      const collectionMap = this.workingTree[update.collection];
      if (!collectionMap || !collectionMap[update.id]) {
        throw new Error('Invalid remove, this should never happen');
      }
      delete collectionMap[update.id];
    } else if (update.op == 'RESET') {
      this.workingTree = update.workingTree;
    }
  }

  public revertUpdate(update: Update): void {
    if (update.op == 'ADD') {
      const collectionMap = this.workingTree[update.collection];
      if (!collectionMap || !collectionMap[update.id]) {
        throw new Error('Invalid Add revert, this should never happen');
      }
      delete collectionMap[update.id];
    } else if (update.op == 'REPLACE') {
      const collectionMap = this.workingTree[update.collection];
      if (!collectionMap || !collectionMap[update.id]) {
        throw new Error('Invalid replace revert, this should never happen');
      }
      collectionMap[update.id] = update.oldValue;
    } else if (update.op == 'REMOVE') {
      let collectionMap = this.workingTree[update.collection];
      if (collectionMap && collectionMap[update.id]) {
        throw new Error('Invalid Remove revert, this should never happen');
      }
      if (!collectionMap) {
        collectionMap = this.workingTree[update.collection] = {};
      }
      collectionMap[update.id] = update.oldValue;
    } else if (update.op == 'RESET') {
      this.workingTree = update.oldWorkingTree;
    }
  }

  public applyCommit(commit: Commit): Commit {
    if (this.stagingArea.length != 0) {
      throw new Error('Uncommited changes');
    }
    if (this.rev >= commit.rev) {
      // TODO: Throw error again after fixing duplicated commit revs
      console.error(
        `Invalid commit in Repository ${this.context}: Commit ${commit.rev} is older than current revision ${this.rev}`,
        commit,
      );
      // throw new Error('Invalid commit (older than current revision)');
    }

    const appliedUpdates = [] as Update[];
    for (const update of commit.updates) {
      try {
        this.applyUpdate(update);
        appliedUpdates.push(update);
      } catch {
        appliedUpdates.forEach(this.revertUpdate.bind(this));
      }
    }

    this.history.push(commit);
    // TODO: Remove if guard after fixing duplicated commit revs
    if (this.rev < commit.rev) {
      this.rev = commit.rev;
    }
    return commit;
  }
}
