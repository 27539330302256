import { ComponentConfig, ComponentInstance } from '../../../core/types';
import * as Location3D from '@/utils/location3D';
import { applyConstraints, ExactSizeConstraints } from './../size';

export const divideArranger =
  (vm: ComponentInstance) =>
  (input: ComponentConfig[], slot: string = '') => {
    const arrangement = vm.modules.arrangement;
    if (arrangement.type != 'arrangement.divide') throw new Error('');

    const stretch = arrangement.divideStretch;

    const targetAspectRatio = (() => {
      const defaultAspectRatio = 1 / 1;
      const aspectRatioDescription = arrangement.divideAspectRatio;
      if (!aspectRatioDescription) return defaultAspectRatio;

      const parts = aspectRatioDescription
        .split('/')
        .map((p) => parseInt(p))
        .filter(Boolean);
      if (parts.length != 2) {
        console.warn('invalid aspect ratio', aspectRatioDescription);
        return defaultAspectRatio;
      }
      return parts[0] / parts[1];
    })();

    const divisions = () => {
      const { width, height } = applyConstraints(
        {
          width: vm.viewport.width,
          height: vm.viewport.height,
          depth: 0,
        },
        vm.sizeConstraints,
      );
      const numChildren = input.length;
      let cols = 1;
      let rows = 1;

      while (cols * rows < numChildren) {
        const aspectRatioAfterVerticalSplit =
          width / (cols + 1) / (height / rows);
        const aspectRatioAfterHorizontalSplit =
          width / cols / (height / (rows + 1));

        const verticalDistance = Math.abs(
          aspectRatioAfterVerticalSplit - targetAspectRatio,
        );
        const horizontalDistance = Math.abs(
          aspectRatioAfterHorizontalSplit - targetAspectRatio,
        );

        if (verticalDistance == horizontalDistance) {
          if (cols > rows) {
            rows += 1;
          } else {
            cols += 1;
          }
        } else if (verticalDistance < horizontalDistance) {
          cols += 1;
        } else {
          rows += 1;
        }
      }

      const containerAspectRatio = width / height;
      const contentRatio = (cols / rows) * targetAspectRatio;
      const constrainingDimension =
        containerAspectRatio > contentRatio ? 'height' : 'width';

      const numFilledRows = Math.ceil(numChildren / cols);
      return {
        width,
        height,
        cols,
        rows,
        targetAspectRatio,
        constrainingDimension,
        numChildren,
        numFilledRows,
      };
    };

    const { width, height, cols, rows, constrainingDimension, numFilledRows } =
      divisions();

    const maxCellWidth = width / cols;
    const maxCellHeight = height / numFilledRows;

    const cellWidth =
      constrainingDimension == 'width' || stretch
        ? maxCellWidth
        : Math.min(targetAspectRatio * maxCellHeight, maxCellWidth);
    const cellHeight =
      constrainingDimension == 'height' || stretch
        ? maxCellHeight
        : Math.min(maxCellWidth / targetAspectRatio, maxCellHeight);
    const contentWidth = cellWidth * cols;
    const contentHeight = cellHeight * numFilledRows;
    const sizeConstraints = ExactSizeConstraints({
      width: cellWidth,
      height: cellHeight,
      depth: 0,
    });

    const getArea = (colIndex, rowIndex) => {
      return {
        location: Location3D.addDefaults({
          position: {
            x: -contentWidth / 2 + cellWidth / 2 + colIndex * cellWidth,
            y: -(-contentHeight / 2 + cellHeight / 2 + rowIndex * cellHeight),
          },
        }),
        sizeConstraints,
      };
    };

    return input.map((element, index) => {
      const colIndex = index % cols;
      const rowIndex = Math.floor(index / cols);
      return getArea(colIndex, rowIndex);
    });
  };
