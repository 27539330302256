


















































































































































import { GlobalPropsNode } from '@/plugins/global-props';
import ParticipantVideo from '@/features/video-conferencing/ParticipantVideo.vue';
import ParticipantListener from '@/features/video-conferencing/ParticipantListener.vue';

import { computed } from '@vue/composition-api';
import { useParticipants, useData } from '@/composables';

export default {
  name: 'ParticipantBox',
  components: {
    ParticipantVideo,
    ParticipantListener,
  },
  mixins: [GlobalPropsNode],
  inject: ['participantStatus', 'videoConferencingManager', 'router'],
  props: {
    participantId: {
      type: String,
      required: true,
    },
    audio: {
      type: Boolean,
      default: true,
    },
    video: {
      type: Boolean,
      default: true,
    },
    shape: {
      type: String,
      default: 'rectangle',
    },
    alwaysShowName: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    // TODO: Should be replaced with this.size?
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  setup(props: any) {
    const { getAvatarUrl } = useParticipants();
    const { getData } = useData();
    return {
      getAvatarUrl,
      participant: computed(() => getData(props.participantId)),
    };
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    isHost() {
      if (!this.$getGlobalProp('roles')) return;
      let isHost;
      this.$getGlobalProp('roles').forEach((role) => {
        if (!role['host']) return;
        isHost = role?.['host']?.documents.includes(this.$me.id);
      });
      return isHost;
    },
    availableParticipantStatus() {
      return this.participantStatus.value.statusOptions;
    },
    ratio() {
      return { circle: 1 / 1, rectangle: 4 / 3, square: 1 }[this.shape];
    },
    status() {
      return this.availableParticipantStatus[
        this.participant?.participantStatus?.status || 'online'
      ];
    },
    muted() {
      return Boolean(!this.participant?.participantVc?.microphone);
    },
    cameraOn() {
      return Boolean(this.participant?.participantVc?.camera);
    },
    isMe() {
      if (!this.participantId || !this.participant) return false;
      return this.participant.id == this.$me.id;
    },
    color() {
      let color = this.muted
        ? 'rgba(0, 0, 0, 0.1)'
        : this.participant.properties?.personalColor?.value
        ? this.participant.properties.personalColor.value
        : this.status.color
        ? this.$vuetify.theme.currentTheme[this.status.color]
        : this.$vuetify.theme.currentTheme.secondary;
      if (typeof color != 'string' && color.base) {
        color = color.base;
      }
      return color;
    },
    imageStyle() {
      return {
        borderColor: this.color,
        //opacity: this.muted ? 0.8 : 1,
        // TODO: Boxshadow and border size should grow relative to the total size
        boxShadow: this.muted
          ? '0 0 2px 1px rgba(0, 0, 0, 0.1)'
          : `0 0 10px 2px ${this.color}`,
      };
    },
  },
  methods: {
    async toggleParticipantMicrophone(participant) {
      this.videoConferencingManager.value.setVc(participant.id, {
        microphone: !participant.participantVc.microphone,
      });
    },
    async toggleParticipantCamera(participant) {
      this.videoConferencingManager.value.setVc(participant.id, {
        camera: !participant.participantVc.camera,
      });
    },
    moveParticipantToLobby(participant) {
      const route = { path: [{ scene: { explicit: true, templateId: '0' } }] };
      this.router.value.goto(route, participant.id);
    },
  },
};
