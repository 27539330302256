import { User, Role, UserType } from './user';
import { DataDocument } from '@fillip/api';
import {
  IsEmail,
  IsString,
  IsOptional,
  IsNotEmpty,
  MinLength,
  IsEnum,
  registerDecorator,
  ValidationOptions,
  IsNumber,
  IsObject,
  IsArray,
} from 'class-validator';

export function isPasswordStrong(password) {
  return (
    /[a-z]/.test(password) && // checks for a-z
    /[A-Z]/.test(password) && // checks for a-z
    /[0-9]/.test(password) && // checks for 0-9
    /\W|_/.test(password) && // checks for special char
    password.length >= 10
  );
}

export function passwordCheck(validationOptions: ValidationOptions) {
  return function (object: Record<string, any>, propertyName: string): void {
    registerDecorator({
      name: 'passwordCharacterCheck',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate: isPasswordStrong,
      },
    });
  };
}

export class LoginRequest {
  @IsString()
  // @MinLength(10)
  // @passwordCheck({
  //   message: 'auth.error.passwordRequirements',
  // })
  password: string;

  @IsNotEmpty({ message: 'auth.error.emailRequired' })
  @IsEmail()
  email: string;
}

export interface LoginResponse {
  access_token: string;

  user: User;
}

export class RegisterLocalRequest {
  @IsString()
  @IsNotEmpty({ message: 'auth.error.usernameRequired' })
  username: string;

  @IsString()
  @MinLength(10)
  @passwordCheck({
    message: 'auth.error.passwordRequirements',
  })
  password: string;

  @IsNotEmpty({ message: 'auth.error.emailRequired' })
  @IsEmail()
  email: string;

  @IsOptional()
  @IsString()
  @IsEnum(Role)
  role: Role;

  @IsString()
  shell: string;

  @IsString()
  domain: string;

  @IsOptional()
  @IsString()
  community?: string;
}

export class RegisterApiRequest {
  @IsOptional()
  @IsString()
  username: string;

  @IsNotEmpty({ message: 'auth.error.passwordRequired' })
  @IsString()
  hashedPassword: string;

  @IsNotEmpty({ message: 'auth.error.emailRequired' })
  @IsEmail()
  email: string;

  @IsOptional()
  participant: DataDocument;

  @IsOptional()
  @IsString()
  @IsEnum(Role)
  role: Role;

  @IsOptional()
  @IsString()
  shell?: string;

  @IsNotEmpty({ message: 'auth.error.apiTokenRequired' })
  @IsString()
  apiToken: string;
}

export class InvokeRequest {
  @IsString()
  @IsNotEmpty()
  method: string;

  @IsArray()
  @IsOptional()
  args: any[];

  @IsString()
  @IsNotEmpty()
  apiToken: string;

  @IsString()
  @IsNotEmpty()
  participantId: string;
}

export class RegisterAnonymousRequest {
  @IsString()
  username: string;

  @IsString()
  role: Role;

  @IsString()
  shell: string;

  @IsString()
  domain: string;
}

export class UpdateUserRequest {
  @IsOptional()
  @IsString()
  @IsNotEmpty({ message: 'auth.error.usernameRequired' })
  username: string;
}

export class UpdatePasswordRequest {
  @IsString()
  @MinLength(10)
  @passwordCheck({
    message: 'auth.error.passwordRequirements',
  })
  oldPassword: string;

  @IsString()
  @MinLength(10)
  @passwordCheck({
    message: 'auth.error.passwordRequirements',
  })
  newPassword: string;
}

export class UpdateEmailRequest {
  @IsString()
  @MinLength(10)
  @passwordCheck({
    message: 'auth.error.passwordRequirements',
  })
  password: string;

  @IsEmail()
  pendingEmail: string;
}
