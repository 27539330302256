import Vuetify from '@/plugins/vuetify';
import themeStyles from '../styles/theme';

export default {
  namespaced: true,
  state: {
    themeName: '',
    version: '',
    meta: {},
    components: {},
    icons: {},
    images: {},
    positions: {},
    options: {},
    theme: {
      themes: {
        light: {},
        dark: {},
      },
      dark: false,
      options: {
        customProperties: true,
      },
    },
    dark: false,
  },
  mutations: {
    SET_OPTION(state, { key, value }) {
      state[key] = value;
    },
    SET_THEME_VARIANT(state, isDark) {
      state.theme.dark = isDark;
    },
    SET_THEME(state, { theme, variant }) {
      state.theme.themes[variant] = theme;
    },
    SET_THEME_OPTIONS(state, options) {
      state.theme.options = options;
    },
    SET_IMAGES(state, images) {
      state.images = images;
    },
  },
  actions: {
    recoverTheme({ dispatch }) {
      dispatch('setStyle', themeStyles);
    },
    setStyle({ commit, dispatch, state }, style) {
      Object.keys(style).forEach((key) => {
        if (state[key] == null) {
          throw new Error(`${key} missing in state Object`);
        }
        if (key === 'theme') {
          const theme = style[key];
          dispatch('setThemeVariant', theme.dark);
          dispatch('setThemeOptions', theme.options);
          Object.keys(theme.themes).forEach((themeVariant) => {
            const themeToApply = theme.themes[themeVariant];
            dispatch('setTheme', {
              theme: themeToApply,
              variant: themeVariant,
            });
          });
        } else {
          commit('SET_OPTION', { key, value: style[key] });
        }
      });
    },
    setThemeVariant({ commit }, variant) {
      Vuetify.framework.theme.dark = variant;
      commit('SET_THEME_VARIANT', variant);
    },
    setThemeOptions({ commit }, options) {
      Vuetify.framework.theme.options = options;
      commit('SET_THEME_OPTIONS', options);
    },
    setImages({ commit }, images) {
      commit('SET_IMAGES', images);
    },
    setTheme({ commit }, { theme, variant = 'light' }) {
      commit('SET_THEME', { theme, variant });
      Object.keys(theme).forEach((colorName) => {
        Vuetify.framework.theme.themes[variant][colorName] = theme[colorName];
      });
    },
  },
  getters: {
    currentThemeColors(state) {
      return state.theme.themes.light;
    },
  },
};
