
























































































































































































































































































































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { PreEvalChartDataDefault, ChartDefaultColors } from '@fillip/api';
import { computed } from '@vue/composition-api';

export default Vue.extend({
  name: 'ChartEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'element',
    });

    const isRoundChart = computed(() =>
      ['doughnut', 'polarArea', 'pie'].includes(buffer.value.chartType),
    );

    const activeClass = 'blue';
    const labelPositions = ['start', 'center', 'end'];
    const ticksUnitPositions = ['before', 'after'];
    const chartTypes = [
      'bar',
      'line',
      'doughnut',
      'pie',
      'radar',
      'polarArea',
      // 'bubble',
      // 'scatter',
      'wordCloud',
    ];

    const getNextColor = () => {
      const indexEntry = buffer.value.data.datasets.length;
      const colors = ChartDefaultColors;
      return colors[indexEntry % colors.length];
    };
    const toggleGlobalOptions = (option, index) => {
      buffer.value.data.datasets[index].global[option] =
        !buffer.value.data.datasets[index].global[option];
      save();
    };
    const addChildEntry = () => {
      const colors = getNextColor();
      buffer.value.data.datasets.push({
        ...PreEvalChartDataDefault.dataset,
        ...colors,
      });
      save();
    };
    const removeChildEntry = (index: number) => {
      buffer.value.data.datasets.splice(index, 1);
      save();
    };

    return {
      buffer,
      save,
      isRoundChart,
      activeClass,
      labelPositions,
      ticksUnitPositions,
      chartTypes,
      getNextColor,
      toggleGlobalOptions,
      addChildEntry,
      removeChildEntry,
    };
  },
});
