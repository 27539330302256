





















export default {
  name: 'MainControl',
  inject: ['router'],
  computed: {
    parentScene() {
      if (this.routePath.length <= 1) return null;
      return this.routePath[this.routePath.length - 1];
    },
    routePath() {
      return this.router?.value.route.path || [];
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKey);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKey);
  },
  methods: {
    onKey(e) {
      if (e.shiftKey && e.altKey) {
        e.code == 'KeyX' ? this.toggleDrawer() : null;
      }
    },
    goBack() {
      this.router?.value.gotoParent();
    },
    isFocused(id) {
      return this.focusedObject?.id == id;
    },
    toggleDrawer() {
      const currentRoute = this.$navdrawer.route;
      if (currentRoute.fullPath == '/') {
        this.$navdrawer.push({ path: '/', query: { minimal: 1 } });
      } else if (currentRoute.query.hidden) {
        const { hidden, ...queryWithoutHidden } = currentRoute.query;
        this.$navdrawer.push({ ...currentRoute, query: queryWithoutHidden });
      } else {
        this.$navdrawer.push({
          ...currentRoute,
          query: { ...currentRoute.query, hidden: 1 },
        });
      }
    },
  },
};
