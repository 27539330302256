import {
  DataId,
  Expression,
  FrontendExpression,
  ModuleTypeMeta,
} from './../../common';
import { ModuleData } from './data.module';

export type PropKey = string;
export type PropMappings = Record<PropKey, FrontendExpression>;
export type ConsolidatedPropMappings = {
  key: PropKey;
  expression: FrontendExpression;
}[];

export interface ChildTemplate {
  query: ModuleData[] | Expression;
  templateId?: DataId;
  condition?: string | boolean; // The actual type is a string, which should evaluate to a boolean false if the child should not be displayed
  identity?: string;
  propMappings?: PropMappings;
}

export interface ModuleChildren {
  default: ChildTemplate[];
}

export const ModuleChildrenMeta: ModuleTypeMeta<ModuleChildren> = {
  default: {
    default: [],
  },
  childDefault: {
    templateId: null,
    query: ':[data]',
  },
};
