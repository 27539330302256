import { DataId } from './../../common/index';
import { ModuleTypeMeta } from './../../common';

// A module to specify which template should be used based on the tags of children

export type ModuleTemplates = {
  mapping: Record<string, DataId>;
};

export const ModuleTemplatesMeta: ModuleTypeMeta<ModuleTemplates> = {
  default: {
    mapping: {},
  },
};
