














import Vue from 'vue';
import { useSave, useCircles } from '@/composables';
import { BaseModuleEditor } from '../base';

export default Vue.extend({
  name: 'ParticipantCircleEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'participantCircle',
    });
    const { getCircle } = useCircles();
    return {
      buffer,
      save,
      getCircle,
    };
  },
  computed: {
    currentCircle() {
      return this.getCircle(this.buffer.circle);
    },
  },
});
