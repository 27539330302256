import { sequence } from './sequence';
import { stepper } from './stepper';
import { workspace } from './workspace';
import { FunctionTemplate } from './types';

export interface FunctionTemplateEntry {
  name: string;
  template: FunctionTemplate;
}

export const functionTemplates: Record<string, FunctionTemplateEntry> = {
  sequence: {
    name: 'Sequence Tool',
    template: sequence,
  },
  stepper: {
    name: 'Stepper',
    template: stepper,
  },
  workspace: {
    name: 'Workspace',
    template: workspace,
  },
};
