


























import PrimitiveDataSelector from '@/components/primitive-editor/types/PrimitiveDataSelector.vue';
import { useData, useDocuments, usePermissions } from '@/composables';
import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'InputId',
  components: {
    PrimitiveDataSelector,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { getData } = useData();
    const { editInSideDrawer } = useDocuments();
    const { canEditCommunity } = usePermissions();

    const doc = computed(() => getData(props.value));

    const openDocument = (id: string) => editInSideDrawer(id, true);

    return { doc, openDocument, canEditCommunity };
  },
});
