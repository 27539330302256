export * from './ModelLoader';
export * from './ModelMarker';
export * from './ModelColorfulSky';
export * from './ModelHdrTexture';
export * from './ModelVideo360';
export * from './ModelPositionalAudio';

import { ModelLoader } from './ModelLoader';
import { ModelMarker } from './ModelMarker';
import { ModelColorfulSky } from './ModelColorfulSky';
import { ModelHdrTexture } from './ModelHdrTexture';
import { ModelVideo360 } from './ModelVideo360';
import { ModelPositionalAudio } from './ModelPositionalAudio';

export const ModelComponents = {
  'model.loader': ModelLoader,
  'model.marker': ModelMarker,
  'model.colorfulSky': ModelColorfulSky,
  'model.hdrTexture': ModelHdrTexture,
  'model.video360': ModelVideo360,
  'model.positionalAudio': ModelPositionalAudio,
};
