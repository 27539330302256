import { Store } from 'vuex';
import { ReadOnlyRepository, StorageAdapter } from '@fillip/json-db';
import {
  VmState,
  MissingSlug,
  LoggedInUserRequired,
  getFullMediasoupResourceId,
  localhostUrls,
} from '@fillip/api';

import { ConnectionManager, ConnectionState, FillipWsClient } from '../helpers';
import { FillipVueClient } from '../client';
import { IFillipVueClientModule } from '../base.client';
import * as mediasoupClient from 'mediasoup-client';
import {
  Transport,
  Device,
  TransportOptions,
} from 'mediasoup-client/lib/types';
import i18n from '../../../../apps/frontend/src/plugins/i18n';
import { ProducersManager } from './connection-managers/producersManager';
import { ConsumersManager } from './connection-managers/consumersManager';

import { useStateBus } from '@fillip/api';
const stateBus = useStateBus('communityState');

const iceServers: () => RTCIceServer[] = () => {
  const host = window.location.hostname;
  if (localhostUrls.includes(host)) {
    return [
      {
        urls: [
          `turn:${host}:${process.env.VUE_TURN_PORT || '18087'}?transport=tcp`,
        ],
        username: 'fillip',
        credential: 'password',
        credentialType: 'password',
      } as RTCIceServer,
    ];
  }
  return [
    {
      urls: [`turns:turn.${host}:443?transport=tcp`],
      username: 'fillip',
      credential: 'password',
      credentialType: 'password',
    } as RTCIceServer,
  ];
};

export class FillipVueRealtimeClient extends IFillipVueClientModule {
  private webSockets = new Map<string, ConnectionManager<VueWebSocketClient>>();
  private mediasoupConnections = new Map<
    string,
    ConnectionManager<MediasoupConnection>
  >();
  //How many reload attempts are reasonable?
  private reloadAttempts = 2;

  constructor(public root: FillipVueClient) {
    super('realtime', root);
  }

  // To be used inside a vue template / computed
  public reactiveQuery(
    communitySlug: string,
    getter: (state: Record<string, any>) => Array<any>,
    fallback: any = [],
    path: string = 'db',
  ): Array<any> {
    if (!this.state.communities[communitySlug]) {
      this.connectCommunity(communitySlug);
      console.log('No community connection');
      return fallback;
    }
    try {
      return getter(this.state.communities[communitySlug][path]);
    } catch (error) {
      console.warn('Error during reactive query', error);
      return fallback;
    }
  }

  public async connectCommunity(communitySlug: string): Promise<void> {
    await this.communityConnection(communitySlug);
  }

  public async communityConnection(
    communitySlug: string,
  ): Promise<VueWebSocketClient> {
    if (!communitySlug) {
      this.handleException(MissingSlug());
    }
    if (!this.state.access_token) {
      this.handleException(LoggedInUserRequired());
    }

    let wsConnectionManager = this.webSockets.get(communitySlug);
    if (!wsConnectionManager) {
      // TODO: Check why first entry on communities sometimes goes wrong!
      this.store.commit('client/INIT_COMMUNITY', {
        communitySlug,
      });

      wsConnectionManager = new ConnectionManager<VueWebSocketClient>({
        connectionParameters: {
          url: this.config.wsUrl,
          jwt: this.state.access_token,
          communitySlug,
        },
        connect: async ({ url, jwt, communitySlug }, onConnectionLost) => {
          const ws = new VueWebSocketClient(
            { url, jwt, communitySlug },
            new VuexStorageAdapter(this.store, communitySlug),
          );
          const { doc } = await ws.connect();
          this.store.commit('client/SET_USER', {
            user: doc ? doc.user : null,
            access_token: doc ? this.state.access_token : null,
          });
          this.store.commit('client/SET_PARTICIPANT', {
            participantId: doc.participantId,
            communitySlug,
          });
          ws.addListener('close', () => {
            ws.removeListener('requestUpdate');
            ws.removeListener('immediateUpdate');
            onConnectionLost(ws);
          });
          ws.addListener('requestUpdate', (operation: string) => {
            stateBus.requestUpdate(operation);
          });
          ws.addListener('immediateUpdate', (operation: string) => {
            stateBus.immediateUpdate(operation);
          });
          return ws;
        },
        disconnect: async (ws) => {
          await ws.disconnect().catch((error) => {
            console.error('Failure while closing connection', ws, error);
          });
        },
      });

      const doReconnect = () => {
        wsConnectionManager.triggerReconnectAttemptNow();
      };

      wsConnectionManager.on('automaticReconnectFailed', () => {
        let reloadAttempts: any = localStorage.getItem('reload_counter');
        if (reloadAttempts === null) {
          reloadAttempts = 0;
        } else if (reloadAttempts >= this.reloadAttempts) {
          console.warn('Reload attempts exhaused', this.store);
          this.store.dispatch('notify', {
            type: 'error',
            text: i18n.t('general.error.reconnectionFailed'),
            action: {
              action: 'reload',
              buttonText: i18n.t('general.reload'),
              color: 'white',
            },
          });
          this.store.commit('SET_RECONNECTIONS_EXHAUSTED', true);

          return;
        }
        reloadAttempts++;
        localStorage.setItem('reload_counter', `${reloadAttempts}`);
        location.reload();
      });

      wsConnectionManager.on('change', (connectionState) => {
        this.store.commit('client/SET_CONNECTION_STATE', {
          connectionState,
          communitySlug,
        });
        if (connectionState == ConnectionState.PENDING_RECONNECT) {
          // According to the MDN Docs, addEventListener is idempotent so a
          // CONNECTING -> PENDING_RECONNECT -> CONNECTING -> ... cycle is ok
          window.addEventListener('online', doReconnect);
          window.addEventListener('focus', doReconnect);
        }
        if (connectionState == ConnectionState.DISCONNECTED) {
          window.removeEventListener('online', doReconnect);
          window.removeEventListener('focus', doReconnect);
        }
      });

      wsConnectionManager.on('connected', () => {
        localStorage.removeItem('reload_counter');
        const msConnectionManager = new ConnectionManager<MediasoupConnection>({
          connectionParameters: true,
          connect: async (params, onClose) => {
            const connection = new MediasoupConnection(
              this,
              await wsConnectionManager.getConnection(5000),
              communitySlug,
            );
            await connection.init();

            return connection;
          },
          disconnect: async (connection) => {
            await connection.close();
          },
        });
        this.mediasoupConnections.set(communitySlug, msConnectionManager);
        this.store.commit('client/SET_MEDIASOUP_CONNECTION_STATE', {
          connectionState: msConnectionManager.state,
          communitySlug,
        });
        msConnectionManager.on('change', (connectionState) => {
          this.store.commit('client/SET_MEDIASOUP_CONNECTION_STATE', {
            connectionState,
            communitySlug,
          });
        });
        msConnectionManager.on('automaticReconnectFailed', () => {
          let reloadAttempts: any = localStorage.getItem('reload_counter');
          if (reloadAttempts === null) {
            reloadAttempts = 0;
          }
          if (reloadAttempts >= this.reloadAttempts) {
            return;
          }
          reloadAttempts++;
          localStorage.setItem('reload_counter', `${reloadAttempts}`);
          location.reload();
        });
      });

      wsConnectionManager.on('disconnected', () => {
        const msConnectionManager =
          this.mediasoupConnections.get(communitySlug);
        this.mediasoupConnections.delete(communitySlug);
        msConnectionManager.close();
      });

      this.store.commit('client/SET_CONNECTION_STATE', {
        connectionState: wsConnectionManager.state,
        communitySlug,
      });

      this.webSockets.set(communitySlug, wsConnectionManager);
    }

    // ! Increase timeout if communities get too big and Nats Streaming can't load them in time
    // In that case, also check if the snapshotting policy should be altered
    return wsConnectionManager.getConnection(30000);
  }

  async disconnectAllCommunities() {
    for (const communitySlug of this.webSockets.keys()) {
      const connectionManager = this.webSockets.get(communitySlug);
      this.webSockets.delete(communitySlug);
      this.store.commit('client/DISCONNECT_COMMUNITY', {
        communitySlug,
      });
      connectionManager.close();
    }
  }
}

// Adapter for FillipWsClient

export class VuexStorageAdapter implements StorageAdapter {
  public get state(): any {
    return this.store.state.client.communities[this.communitySlug].db;
  }
  constructor(
    public store: Store<{ client: any }>,
    public communitySlug: string,
  ) {}
  async add(collection: string, id: string, value: any): Promise<void> {
    this.store.commit('client/ADD', {
      communitySlug: this.communitySlug,
      collection,
      id,
      value,
    });
  }
  async remove(collection: string, id: string): Promise<void> {
    this.store.commit('client/REMOVE', {
      communitySlug: this.communitySlug,
      collection,
      id,
    });
  }

  async replace(collection: string, id: string, value: any): Promise<void> {
    this.store.commit('client/REPLACE', {
      communitySlug: this.communitySlug,
      collection,
      id,
      value,
    });
  }

  async reset(workingTree: Record<string, any>): Promise<void> {
    this.store.commit('client/RESET', {
      communitySlug: this.communitySlug,
      workingTree,
    });
  }
}

// Realtime Connection Objects

export class VueWebSocketClient extends FillipWsClient {
  constructor(
    connection: { url: string; jwt: string; communitySlug: string },
    public storage,
  ) {
    super(connection, new ReadOnlyRepository(storage));
  }

  get state() {
    return this.storage.state as VmState;
  }
}

type WebRTCTransportConnectionManager = ConnectionManager<Transport> & {
  refCount: number;
};

const createWebRTCTransportConnectionManager = (
  mediasoupConnection: MediasoupConnection,
  type: TransportType,
  channelOrRouterId: string,
  roomSize: number,
): WebRTCTransportConnectionManager => {
  const { ws, device } = mediasoupConnection;
  const manager = new ConnectionManager<Transport>({
    connect: async (connectionParameters, onClose) => {
      const transportInfo: TransportOptions =
        type == 'send'
          ? await ws.mediasoup.createProducerTransport(
              channelOrRouterId,
              roomSize,
            )
          : await ws.mediasoup.createConsumerTransport(channelOrRouterId);

      if (!transportInfo) {
        throw new Error(
          `Could not get transportInfo ${type} transport for id ${channelOrRouterId}`,
        );
      }

      if (iceServers()) {
        transportInfo.iceServers = iceServers();
        console.log('TransportInfo', transportInfo, device);
      }

      const transport =
        type == 'send'
          ? device.createSendTransport(transportInfo)
          : device.createRecvTransport(transportInfo);

      if (!transport) {
        throw new Error(
          `Could not create ${type} transport for id ${transportInfo.id}`,
        );
      }

      const transportId = getFullMediasoupResourceId(transport);

      transport.on('connect', ({ dtlsParameters }, onHandled, onError) => {
        console.log('Connecting Transport');
        ws.mediasoup
          .connectWebRtcTransport(transportId, dtlsParameters)
          .then(() => {
            console.log('connectWebRtcTransport command handled');
            onHandled();
          })
          .catch((error) => {
            console.warn('connectWebRtcTransport failed on server', error);
            onError(error);
          });
      });

      transport.on('produce', (parameters, onHandled, onError) => {
        ws.mediasoup
          .produce(transportId, parameters)
          .then(onHandled)
          .catch((error) => {
            console.warn('produce failed on server', error);
            onError(error);
          });
      });

      transport.observer.on('close', () => {
        onClose(transport);
      });

      return transport;
    },
    disconnect: (transport) => {
      transport.close();
      if (
        mediasoupConnection.owner.state.communities[
          mediasoupConnection.communitySlug
        ].connectionState == ConnectionState.CONNECTED
      ) {
        ws.mediasoup.closeTransportsById([
          getFullMediasoupResourceId(transport),
        ]);
      }
    },
    connectionParameters: {},
  }) as WebRTCTransportConnectionManager;
  manager.refCount = 1;
  return manager;
};

type RouterId = string;
type Channel = string;
type TransportType = 'send' | 'receive';

// While the producers are indexed by channel, because we use exactly one transport per channel, the consumers are indexed by routerId,
// This is because the backend might decide to use different servers for two producers broadcasting on the same channel.
export class MediasoupConnection {
  public device: Device = null;
  public producersManager: ProducersManager = null;
  public consumersManager: ConsumersManager = null;
  public producerTransports: Map<Channel, WebRTCTransportConnectionManager> =
    new Map();
  public consumerTransports: Map<RouterId, WebRTCTransportConnectionManager> =
    new Map();

  constructor(
    public owner: FillipVueRealtimeClient,
    public ws: FillipWsClient,
    public communitySlug: string,
  ) {}

  get state() {
    return this.owner.state.communities[this.communitySlug].mediasoup;
  }

  async requestTransport(
    type: TransportType,
    channelOrRouterId: string,
    roomSize?: number,
  ): Promise<Transport> {
    const transports =
      type == 'send' ? this.producerTransports : this.consumerTransports;
    let transportManager: WebRTCTransportConnectionManager;
    if (transports.has(channelOrRouterId)) {
      transportManager = transports.get(channelOrRouterId);
      transportManager.refCount += 1;
    } else {
      transportManager = createWebRTCTransportConnectionManager(
        this,
        type,
        channelOrRouterId,
        roomSize,
      );
      transports.set(channelOrRouterId, transportManager);
    }

    try {
      return await transportManager.getConnection(5000);
    } catch (error) {
      console.error(
        `Getting ${type} transport failed for channelOrRouterId ${channelOrRouterId}`,
        error,
      );
      throw error;
    }
  }

  async unrequestTransport(type: TransportType, channelOrRouterId: string) {
    const transports =
      type == 'send' ? this.producerTransports : this.consumerTransports;
    const manager = transports.get(channelOrRouterId);
    if (manager.refCount == 1) {
      transports.delete(channelOrRouterId);
      await manager.close();
    } else {
      manager.refCount -= 1;
    }
  }

  async init(): Promise<void> {
    // TODO: Exception Safety: Close Device, Close Transports
    // TODO: Make Mediasoup Device Global Singleton
    const routerRtpCapabilities =
      await this.ws.mediasoup.getRouterRtpCapabilities();
    const device = new mediasoupClient.Device({});
    await device.load({ routerRtpCapabilities });

    this.device = device;
    this.producersManager = new ProducersManager(
      this,
      this.owner,
      this.ws,
      this.communitySlug,
    );

    this.consumersManager = new ConsumersManager(
      this.owner,
      this,
      this.ws,
      this.communitySlug,
      this.device,
    );
  }

  async close() {
    const allTransports = [
      ...this.producerTransports.values(),
      ...this.consumerTransports.values(),
    ];
    await Promise.all([
      allTransports.map((t) => t.close()),
      this.producersManager.close(),
      this.consumersManager.close(),
    ]);

    // TODO: close transports
    this.producersManager = null;
    this.consumersManager = null;
  }
}
