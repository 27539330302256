









































import LayoutAreaHeader from '@/features/app/LayoutAreaHeader.vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useLayoutArea } from '@/composables';

export default defineComponent({
  name: 'RouterDialog',
  components: {
    LayoutAreaHeader,
  },
  setup() {
    const {
      currentRoute,
      component,
      componentProps,
      heading,
      hideDefaultHeader,
      isOpen,
      close,
      collapse,
    } = useLayoutArea('dialog');

    const shouldBeOpen = ref(false);

    watch(shouldBeOpen, (value) => {
      if (!value) {
        close();
      }
    });

    watch(isOpen, (value) => {
      shouldBeOpen.value = value;
    });

    const persistent = computed(() => currentRoute.value.meta.persistent);

    return {
      currentRoute,
      component,
      componentProps,
      heading,
      hideDefaultHeader,
      isOpen,
      close,
      collapse,
      persistent,
      shouldBeOpen,
    };
  },
});
