import {
  DataDocument,
  rootCircleId,
  rootDataId,
  rootTemplateId,
  templateDirectoryId,
  tagsTemplateDirectoryId,
  toolsTemplateDirectoryId,
  fragmentsTemplateDirectoryId,
  participantsDirectoryId,
} from '@fillip/api';

import { DefaultTagTemplates } from './tags';

export const latestCommunityVersion = 3;

export const DefaultRootData: DataDocument = {
  id: rootDataId,
  parentId: null,
  tag: {
    tag: 'collection',
  },
  info: {
    title: 'Root',
    icon: 'sitemap',
    isProtected: true,
  },
  properties: {
    version: {
      type: 'number',
      value: latestCommunityVersion,
    },
    primaryColor: {
      type: 'string',
      value: 'rgba(8,10,10,var(--tw-bg-opacity))',
    },
    accentColor: {
      type: 'string',
      value: 'rgba(247,209,0,var(--tw-bg-opacity))',
    },
    headlineColor: {
      type: 'string',
      value: 'rgba(8,10,10,var(--tw-bg-opacity))',
    },
    textColor: {
      type: 'string',
      value: 'rgba(8,10,10,var(--tw-bg-opacity))',
    },
    containerClasses: {
      type: 'string',
      value: '',
    },
    hoverContainerClasses: {
      type: 'string',
      value: '',
    },
    contentTagClasses: {
      type: 'string',
      value: 'mb-4 break-words hyphens-manual select-text',
    },
    headlineClasses: {
      type: 'string',
      value: 'mt-2 leading-snug',
    },
    textClasses: {
      type: 'string',
      value: '',
    },
    sublineClasses: {
      type: 'string',
      value: '',
    },
    colorSwatches: {
      type: 'script',
      value: ":['#06ab71','#0085b9','#f7d100','#ea8e00', '#e35a3e', '#c4c4c4']",
    },
    brandColors: {
      type: 'script',
      value: ":['primary', 'accent', 'warning', 'error', 'info']",
    },
  },
  list: {
    items: [
      {
        id: templateDirectoryId,
      },
      { id: rootTemplateId },
      { id: 'worldData' },
      { id: 'hostData' },
      { id: 'contributions' },
    ],
  },
  schema: {
    ownFields: [
      {
        title: 'Migrations Version',
        key: 'version',
        type: 'number',
      },
      {
        title: 'Canvas Background',
        key: 'canvasBackground',
        type: 'string',
      },
      {
        title: 'AppBar Background',
        key: 'appBarBackground',
        type: 'string',
      },
      {
        title: 'AppBar Shadow',
        key: 'appBarShadow',
        type: 'boolean',
      },
      {
        title: 'Dense AppBar',
        key: 'denseAppBar',
        type: 'boolean',
      },
      {
        title: 'MainDrawer Bar Background',
        key: 'mainDrawerBarBackground',
        type: 'string',
      },
      {
        title: 'MainDrawer Background',
        key: 'mainDrawerBackground',
        type: 'string',
      },
      {
        title: 'MainDrawer Border',
        key: 'mainDrawerBorder',
        type: 'boolean',
      },
      {
        title: 'Color Swatches',
        key: 'colorSwatches',
        type: 'string',
      },
      {
        title: 'Brand Colors',
        key: 'brandColors',
        type: 'string',
      },
      {
        title: 'Primary Color',
        key: 'primaryColor',
        type: 'string',
      },
      {
        title: 'Accent Color',
        key: 'accentColor',
        type: 'string',
      },
      {
        title: 'Headline Color',
        key: 'headlineColor',
        type: 'string',
      },
      {
        title: 'Text Color',
        key: 'textColor',
        type: 'string',
      },
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
      },
      {
        title: 'Hover Container Classes',
        key: 'hoverContainerClasses',
        type: 'string',
      },
      {
        title: 'Content Tag Classes',
        key: 'contentTagClasses',
        type: 'string',
      },
      {
        title: 'Headline Classes',
        key: 'headlineClasses',
        type: 'string',
      },
      {
        title: 'Text Classes',
        key: 'textClasses',
        type: 'string',
      },
      {
        title: 'Subline Classes',
        key: 'sublineClasses',
        type: 'string',
      },
    ],
    fields: [],
  },
};

export const DefaultRootTemplate: DataDocument = {
  id: rootTemplateId,
  parentId: rootDataId,
  tag: {
    tag: 'template',
  },
  info: {
    title: 'World',
    icon: 'globe',
    isProtected: true,
  },
  station: {},
  sceneDefinitions: { sceneDefinitions: [] },
  navigation: {
    links: [
      {
        title: 'Home',
        displaySlots: ['Navigation-tree', 'Main-drawer top'],
        sortingIndex: {},
        icon: 'home',
        route: {
          path: [
            {
              scene: {
                explicit: true,
                templateId: '0',
              },
              params: {},
            },
          ],
        },
      },
    ],
  },
  roles: {
    roles: {
      host: {
        key: 'host',
        title: 'Host',
        documents: [],
      },
      admin: {
        key: 'admin',
        title: 'Admin',
        documents: [],
      },
    },
  },
  variables: {
    variables: [
      {
        identifier: 'language',
        query: 'de',
      },
      {
        identifier: 'today',
        query: ':dayjs().format()',
      },
      {
        identifier: 'contributionCollectionId',
        query: 'contributions',
      },
      {
        identifier: 'rootDoc',
        query: ":getDoc('root')",
      },
      {
        identifier: 'worldDataId',
        query: 'worldData',
      },
      {
        identifier: 'worldDataDoc',
        query: ':getDoc($$worldDataId)',
      },
      {
        identifier: 'lobbyDoc',
        query: ":getDoc('lobby')",
      },
      {
        identifier: 'contributionCollectionId',
        query: 'contributions',
      },
      {
        identifier: 'primaryColor',
        query: ":$$rootDoc.properties.primaryColor.value || ''",
      },
      {
        identifier: 'accentColor',
        query: ":$$rootDoc.properties.accentColor.value || ''",
      },
      {
        identifier: 'headlineColor',
        query: ":$$rootDoc.properties.headlineColor.value || ''",
      },
      {
        identifier: 'textColor',
        query: ":$$rootDoc.properties.textColor.value || ''",
      },
      {
        identifier: 'containerClasses',
        query: ":$$rootDoc.properties.containerClasses.value || ''",
      },
      {
        identifier: 'hoverContainerClasses',
        query: ":$$rootDoc.properties.hoverContainerClasses.value || ''",
      },
      {
        identifier: 'contentTagClasses',
        query: ":$$rootDoc.properties.contentTagClasses.value || ''",
      },
      {
        identifier: 'headlineClasses',
        query: ":$$rootDoc.properties.headlineClasses.value || ''",
      },
      {
        identifier: 'textClasses',
        query: ":$$rootDoc.properties.textClasses.value || ''",
      },
      {
        identifier: 'sublineClasses',
        query: ":$$rootDoc.properties.sublineClasses.value || ''",
      },
      {
        identifier: 'colorSwatches',
        query:
          ":['#06ab71','#0085b9','#f7d100','#ea8e00', '#e35a3e', '#c4c4c4']",
      },
      {
        identifier: 'brandColors',
        query: ":['primary', 'accent', 'warning', 'error', 'info']",
      },
    ],
  },
};

export const TemplateDirectory: DataDocument = {
  id: templateDirectoryId,
  parentId: rootDataId,
  tag: {
    tag: 'collection',
  },
  info: {
    title: 'Templates',
    icon: 'album-collection',
    isProtected: true,
  },
  list: {
    items: [
      { id: tagsTemplateDirectoryId },
      { id: toolsTemplateDirectoryId },
      { id: fragmentsTemplateDirectoryId },
    ],
  },
};

export const DefaultRootCircle: DataDocument = {
  id: rootCircleId,
  parentId: null,
  tag: {
    tag: 'circle',
  },
  info: {
    title: 'Main Circle',
    icon: 'location-circle',
    isProtected: true,
  },
  circleSyncLocation: {
    leader: null,
  },
  list: {
    items: [],
  },
};

export const ParticipantsDirectory: DataDocument = {
  id: participantsDirectoryId,
  parentId: null,
  tag: {
    tag: 'collection',
  },
  info: {
    title: 'Participants',
    icon: 'users',
    isProtected: true,
  },
  list: {
    items: [],
  },
};

export const HostDataDirectory: DataDocument = {
  id: 'hostData',
  parentId: rootDataId,
  tag: {
    tag: 'collection',
  },
  info: {
    title: 'Host Data',
    isProtected: true,
  },
  list: {
    items: [],
  },
};

export const UserContributionDirectory: DataDocument = {
  id: 'contributions',
  parentId: rootDataId,
  tag: {
    tag: 'collection',
  },
  info: {
    title: 'User Contributions',
    isProtected: true,
  },
  list: {
    items: [],
  },
};

export const worldDataDoc: DataDocument = {
  id: 'worldData',
  parentId: rootDataId,
  tag: {
    tag: 'collection',
  },
  info: {
    title: 'World Data',
    isProtected: true,
  },
  // list: {
  //   items: [{ id: 'lobbyData' }],
  // },
  properties: {
    keyvisual: {
      type: 'image',
      value: {
        imageSrc: '',
        imageDescription: '',
        imageCopyright: '',
      },
    },
    title: {
      type: 'string',
      value: '',
    },
    subtitle: {
      type: 'string',
      value: '',
    },
    organizerLogo: {
      type: 'image',
      value: {
        imageSrc: '',
        imageDescription: '',
        imageCopyright: '',
        imageTitle: '',
      },
    },
  },
  schema: {
    fields: [],
    ownFields: [
      {
        title: 'Title',
        key: 'title',
        type: 'string',
        defaultValue: '',
      },
      {
        title: 'Untertitel',
        key: 'subtitle',
        type: 'string',
        defaultValue: '',
      },
      {
        title: 'Key Visual',
        key: 'keyvisual',
        type: 'image',
        defaultValue: '',
      },
      {
        title: 'Organizer Logo',
        key: 'organizerLogo',
        type: 'image',
        defaultValue: '',
      },
      {
        title: 'Organizer Link',
        key: 'organizerLink',
        type: 'string',
        defaultValue: '',
      },
    ],
  },
};

export const LobbyDoc: DataDocument = {
  id: 'lobby',
  parentId: ' ',
  tag: {
    tag: 'collection',
  },
  info: {
    title: 'Lobby',
    icon: 'home',
    isProtected: true,
  },
  list: {
    items: [],
  },
};

export const DefaultWorldTemplates = [
  DefaultRootData,
  DefaultRootTemplate,
  TemplateDirectory,
  DefaultRootCircle,
  ParticipantsDirectory,
  HostDataDirectory,
  UserContributionDirectory,
  worldDataDoc,
  LobbyDoc,
];
