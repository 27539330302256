











import { computed } from '@vue/composition-api';

export default {
  name: 'EditorSection',
  props: {
    label: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    // TODO: Implement isBlocked
    isBlocked: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const wrapperClasses = computed(() => ({
      'border-0 pb-2': true,
      'border-0 border-b-1 border-solid border-gray-300': !props.isLast,
    }));
    const headerClasses = 'mb-0 font-bold overline text-gray-600';
    const slotClasses = '';

    return {
      wrapperClasses,
      headerClasses,
      slotClasses,
    };
  },
};
