import { ModuleTypeMeta, DataId, Vector3Type } from '../../common';

export interface ModuleCircleSyncLocation {
  leader: DataId;
  cameraLocation?: Vector3Type;
}

export const ModuleCircleSyncLocationMeta: ModuleTypeMeta<ModuleCircleSyncLocation> =
  {
    restrictedToTags: ['circle'],
    default: {
      leader: null,
      cameraLocation: null,
    },
  };
