




































































































import Vue from 'vue';
import { BaseModuleEditor } from '@/features/editor/base/BaseModuleEditor';
import clone from 'rfdc/default';
import { deepEqual } from 'fast-equals';

export default Vue.extend({
  name: 'InputMedia',
  mixins: [BaseModuleEditor],

  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    mediaType: {
      type: String,
      required: true,
    },
    serverFiles: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      buffer: null,
      uploading: false,
      uploadedFiles: [],
      fileFormats: {
        image: 'image/*',
        audio: 'audio/*',
        positionalAudio: 'audio/*',
        video360: 'video/*',
        loader: '.glb,.gltf',
        hdrTexture: 'image/*',
        svg: '.svg',
      },
      paths: {
        //! Temporary solution to convert the buffer received by the value-watcher into this component properly
        image: null,
        audio: 'audioSettings',
        positionalAudio: 'positionalAudioSettings',
        video360: 'video360Settings',
        svg: 'svgSettings',
        //TODO: Add settings to modules with the same properties as other media types
        loader: null,
        hdrTexture: null,
      },
      icons: {
        image: 'file-image',
        audio: 'music',
        positionalAudio: 'music',
        video360: 'film-alt',
        loader: 'cubes',
        hdrTexture: 'braille',
        svg: 'bezier-curve',
      },
      mediaBuckets: {
        image: 'image',
        audio: 'audio',
        positionalAudio: 'audio',
        video360: 'video360',
        loader: 'loader',
        hdrTexture: 'hdr',
        svg: 'svg',
      },
    };
  },
  computed: {
    errorMessage() {
      if (!this.hasErrors) return '';
      return this.error || this.$t('editorErrors.invalidUrl');
    },
    hasErrors() {
      if (this.error) return true;
      if (!this.buffer[this.mediaSrc]) return false;
      return !this.buffer[this.mediaSrc].match(/^(https?:\/\/|:)/i);
    },
    bucketName() {
      return this.mediaBuckets[this.mediaType];
    },
    acceptedFileFormat() {
      return this.fileFormats[this.mediaType];
    },
    icon() {
      return this.icons[this.mediaType];
    },
    mediaSrc() {
      return `${this.mediaType + 'Src'}`;
    },
    bufferPath() {
      return this.paths[this.mediaType];
    },
    library() {
      const results = [];
      if (this.serverFiles.length) {
        results.push({ header: this.$t('input.inputMedia.files') });
        results.push(...this.serverFiles);
      }
      if (this.uploadedFiles.length) {
        results.push({
          divider: true,
          header: this.$t('input.inputMedia.ownUploads'),
        });
        results.push(...this.uploadedFiles);
      }
      return results;
    },
    hasSource() {
      return !!this.buffer[this.mediaSrc];
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (deepEqual(this.buffer, newValue)) {
          return;
        }
        this.buffer = clone(newValue);
      },
    },
  },
  mounted() {
    this.syncUploadedFiles();
  },
  methods: {
    save(target?) {
      if (target == 'source') {
        const oldInput = this.value[this.mediaSrc];
        if (oldInput && this.buffer[this.mediaSrc] == oldInput) return;
      }
      const result = clone(this.buffer);
      this.$emit('change', result);
      this.$emit('input', result);
      this.$emit('blur', result);
      this.syncUploadedFiles();
    },
    async handleFileInput(mediaFile) {
      if (!mediaFile) return false;
      try {
        this.uploading = true;
        const { url } = await this.$fillip.media.uploadMedia(
          mediaFile,
          this.bucketName,
        );
        this.buffer[this.mediaSrc] = url;
        this.save(this.buffer);
      } catch (error) {
        console.error('Error uploading file: ', error);
        this.$store.dispatch('notify', {
          type: 'error',
          text: this.$t('media.error.uploadFetchFailed'),
        });
      } finally {
        this.$refs.fileInput.clearableCallback();
        this.uploading = false;
        this.syncUploadedFiles();
      }
    },
    async syncUploadedFiles() {
      const participantsUploadedFiles =
        await this.$fillip.media.getAllMediaInBucket(this.bucketName);
      participantsUploadedFiles.forEach((file) => {
        if (!file.uploadFinished) return;
        const filename = file.filename.split('--')[1];
        this.uploadedFiles.push({ text: filename, value: file.url });
      });
    },
  },
});
