import Vue from 'vue';
import { ConnectionState } from './helpers';

export default {
  namespaced: true,
  modules: {},
  state: {
    user: null,
    access_token: null,
    allCommunities: [],
    communities: {},
  },
  getters: {
    participantId: (state) => (communitySlug) => {
      if (!state.communities[communitySlug]) return null;
      return state.communities[communitySlug].participantId;
    },
  },
  mutations: {
    ADD(state, { communitySlug, collection, id, value }) {
      if (!state.communities[communitySlug].db[collection]) {
        Vue.set(state.communities[communitySlug].db, collection, {});
      }
      Vue.set(state.communities[communitySlug].db[collection], id, value);
    },
    REMOVE(state, { communitySlug, collection, id }) {
      Vue.delete(state.communities[communitySlug].db[collection], id);
    },
    REPLACE(state, { communitySlug, collection, id, value }) {
      state.communities[communitySlug].db[collection][id] = value;
    },
    RESET(state, { communitySlug, workingTree }) {
      state.communities[communitySlug] = {
        ...state.communities[communitySlug],
        db: { ...workingTree },
      };
    },
    SET_USER(state, { user, access_token }) {
      state.user = user;
      state.access_token = access_token;
    },
    SET_COMMUNITIES(state, allCommunites) {
      Vue.set(state, 'allCommunities', allCommunites);
    },
    ADD_COMMUNITY(state, community) {
      state.allCommunities.push(community);
    },
    CLEAR_ALL(state) {
      state.user = null;
      state.access_token = null;
      state.allCommunities = [];
      state.communities = {};
    },
    INIT_COMMUNITY(state, { communitySlug }) {
      state.communities = {
        ...state.communities,
        [communitySlug]: {
          participantId: null,
          db: {},
          connectionState: ConnectionState.DISCONNECTED,
          mediasoup: {
            connectionState: ConnectionState.DISCONNECTED,
            producers: {},
            consumers: {},
          },
          requestedStreams: {
            producers: {
              camera: false,
              microphone: false,
              screen: false,
            },
            consumers: {},
          },
        },
      };
    },
    DISCONNECT_COMMUNITY(state, communitySlug) {
      const { [communitySlug]: communityToRemove, ...rest } = state.communities;
      state.communities = rest;
    },
    SET_CONNECTION_STATE(state, { connectionState, communitySlug }) {
      if (!state.communities[communitySlug]) {
        return;
      }
      state.communities[communitySlug].connectionState = connectionState;
    },
    SET_MEDIASOUP_CONNECTION_STATE(state, { connectionState, communitySlug }) {
      if (!state.communities[communitySlug]) return;
      state.communities[communitySlug].mediasoup.connectionState =
        connectionState;
    },
    INIT_MEDIASOUP_PRODUCER(
      state,
      { communitySlug, name, instance, mediaStream },
    ) {
      Vue.set(state.communities[communitySlug].mediasoup.producers, name, {
        name,
        instance,
        mediaStream,
      });
    },
    RESET_MEDIASOUP_PRODUCER(state, { communitySlug, name }) {
      Vue.delete(state.communities[communitySlug].mediasoup.producers, name);
    },
    SET_REQUESTED_PRODUCERS(state, { communitySlug, producers }) {
      state.communities[communitySlug].requestedStreams.producers = producers;
    },
    INIT_MEDIASOUP_CONSUMER(state, { communitySlug, producerId, instance }) {
      Vue.set(
        state.communities[communitySlug].mediasoup.consumers,
        producerId,
        instance,
      );
    },
    PAUSE_MEDIASOUP_CONSUMER(
      state,
      { communitySlug, producerId, shouldBePaused },
    ) {
      const consumer =
        state.communities[communitySlug].mediasoup.consumers[producerId];
      if (!consumer) return null;
      consumer.paused = shouldBePaused;
    },
    RESET_MEDIASOUP_CONSUMER(state, { communitySlug, producerId }) {
      Vue.delete(
        state.communities[communitySlug].mediasoup.consumers,
        producerId,
      );
    },
    REQUEST_MEDIASOUP_CONSUMER(
      state,
      { communitySlug, producerId, participantId, producerName },
    ) {
      const requestedConsumers =
        state.communities[communitySlug].requestedStreams.consumers;

      if (!requestedConsumers[producerId]) {
        Vue.set(requestedConsumers, producerId, {
          count: 1,
          participantId,
          producerName,
        });
      } else {
        requestedConsumers[producerId].count += 1;
      }
    },
    UNREQUEST_MEDIASOUP_CONSUMER(state, { communitySlug, producerId }) {
      if (!state.communities[communitySlug]) {
        return;
      }
      const requestedConsumers =
        state.communities[communitySlug].requestedStreams.consumers;
      if (
        requestedConsumers[producerId] &&
        requestedConsumers[producerId].count == 1
      ) {
        requestedConsumers[producerId].count -= 1;
        Vue.delete(requestedConsumers, producerId);
      } else {
        requestedConsumers[producerId].count -= 1;
      }
    },
    SET_PARTICIPANT(state, { participantId, communitySlug }) {
      if (!state.communities[communitySlug]) {
        return;
      }
      state.communities[communitySlug].participantId = participantId;
    },
  },
};
