























import Vue from 'vue';
import { BaseDocumentEditor } from '@/features/editor/base/BaseDocumentEditor';
import InputString from '@/components/input/string/InputString.vue';
// import InputScript from '@/components/input/script/InputScript.vue';
import InputDocuments from '@/components/input/documents/InputDocuments.vue';

export default Vue.extend({
  name: 'BlockEditorReference',
  components: {
    InputString,
    // InputScript,
    InputDocuments,
  },
  mixins: [BaseDocumentEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  computed: {
    content() {
      return this.buffer?.info?.content;
    },
  },
  watch: {
    content: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          Vue.set(this.buffer.info, 'content', {
            text: 'Weitere Informationen:',
            script: '',
            documents: [],
          });
        }
      },
    },
  },
  methods: {
    updateContent() {
      this.buffer.info.title =
        this.buffer?.info?.content?.text?.slice(0, 50) || 'Reference';
      this.immediateSave('info');
    },
    toggleEditing() {
      if (this.isEditing) {
        this.immediateSave('info');
      }
      this.isEditing = !this.isEditing;
    },
  },
});
