









































import { Action } from '@fillip/api';
import { useFillip, useI18n, useNav, usePermissions } from '@/composables';
import { defineComponent, computed, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'NavigationBar',
  setup() {
    const { canSeePrototype, canEditCommunity } = usePermissions();
    const themeManager = inject('themeManager', null);
    const { tr } = useI18n();
    const { $navdrawer } = useNav();
    const { $community } = useFillip();

    const activeRoute = computed(() => {
      const baseMatch = $navdrawer.route.matched[0];
      if (!baseMatch) return '';
      return baseMatch.name;
    });

    const toggle = (name: string) => {
      const route = $navdrawer.route;
      if (route.name != name) {
        $navdrawer.push({ name });
      } else {
        if (route.query.minimal) {
          const { minimal, ...queryWithoutMinimal } = route.query;
          $navdrawer.push({ ...route, query: queryWithoutMinimal });
        } else {
          $navdrawer.push({
            path: '/',
            query: { minimal: 1 },
          });
        }
      }
    };

    const actionsManager = inject('actions', null);

    const execute = (action: Action, data: Record<string, any> = {}) => {
      return actionsManager?.value?.execute(action.script, {
        ...action.context,
        ...data,
      });
    };

    // TODO: Only is color if route is active
    //if (name != this.activeRoute || this.$route.query.minimal) {

    const actionAreas = computed(() => {
      return [
        {
          area: 'Main-drawer start',
          classes: 'flex flex-col items-center',
          actions: [
            {
              name: 'CommunityOverview',
              title: tr('navigation.tooltips.communityOverview'),
              icon: $community.value.icon ? $community.value.icon : null,
              color: 'blue',
              text: $community.value.icon
                ? ''
                : $community.value.title.substring(0, 2).toUpperCase(),
              action: () => toggle('CommunityOverview'),
              outlined: true,
            },
            // {
            //   name: 'Home',
            //   title: tr('navigation.tooltips.home'),
            //   icon: 'globe',
            //   color: 'blue',
            //   action: () => toggle('Home')
            //   condition: null
            // },
            {
              name: 'Explorer',
              title: tr('navigation.tooltips.explorer'),
              icon: 'folder',
              color: 'blue',
              action: () => toggle('Explorer'),
              condition: canEditCommunity.value,
            },
            {
              name: 'Navigation',
              title: tr('navigation.tooltips.navigation'),
              icon: 'compass',
              color: 'blue',
              action: () => toggle('Navigation'),
              condition: canEditCommunity.value,
            },
            {
              name: 'Chat',
              title: tr('navigation.tooltips.chat'),
              icon: 'comments',
              color: 'blue',
              action: () => toggle('Chat'),
              condition: canSeePrototype.value,
            },
            {
              name: 'Activity',
              title: tr('navigation.tooltips.activity'),
              icon: 'bell',
              color: 'blue',
              action: () => toggle('Activity'),
              condition: canSeePrototype.value,
            },
            {
              name: 'Search',
              title: tr('navigation.tooltips.search'),
              icon: 'search',
              color: 'blue',
              action: () => toggle('Search'),
              condition: canSeePrototype.value,
            },
          ],
        },
        {
          area: 'Main-drawer top',
          classes: 'flex flex-col items-center flex-grow',
          actions: actionsManager.value.getNavigationLinks('Main-drawer top'),
        },
        {
          area: 'Main-drawer top',
          classes: 'flex flex-col items-center',
          actions:
            actionsManager.value.getNavigationLinks('Main-drawer bottom'),
        },
      ];
    });

    return {
      canSeePrototype,
      canEditCommunity,
      activeRoute,

      toggle,
      execute,
      actionAreas,
      themeManager,
    };
  },
});
