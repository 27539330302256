import { DataId, ModuleTypeMeta } from '../../common';
import { SchemaField } from './schema.module';

export interface PropsDefinition extends SchemaField {}

export interface ModuleInterfaceDefinition {
  title: string;
  inheritFrom?: DataId;
  props?: PropsDefinition[];
}

export const ModuleInterfaceDefinitionMeta: ModuleTypeMeta<ModuleInterfaceDefinition> =
  {
    default: {
      title: '',
      props: [],
    } as ModuleInterfaceDefinition,
    childDefault: {
      title: 'New Prop',
      key: 'newProp',
      type: 'string',
      defaultValue: ':',
      settings: {},
    } as PropsDefinition,
  };
