


















































































































import { Access, Status, CreateCommunityRequest } from '@fillip/api';
import SaveAndCancelButtons from '@/components/form-inputs/SaveAndCancelButtons.vue';
import { defineComponent, computed, reactive, ref } from '@vue/composition-api';
import { useFillip, useRouter } from '@/composables';

export default defineComponent({
  components: {
    SaveAndCancelButtons,
  },
  setup() {
    const { fillip, baseUrl } = useFillip();
    const { router } = useRouter();

    const accessTypes = [Access.PRIVATE, Access.PUBLIC];

    const languages = [
      {
        value: 'en',
        text: 'English',
      },
      {
        value: 'de',
        text: 'Deutsch',
      },
    ];

    const fileRef = ref(null);
    const imageToUpload = ref(null);
    const imagePreview = ref(null);

    const handleFileInput = async (image) => {
      imageToUpload.value = image.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        imagePreview.value = e.target.result;
      };
    };

    const triggerUpload = () => {
      console.log('fileRef', fileRef);
      fileRef.value.click();
    };

    const uploadImage = async () => {
      try {
        const { id, url } = await fillip.media.uploadMedia(
          imageToUpload.value,
          'communities',
        );
      } catch {
        throw new Error('error');
      }
    };

    const cancel = () => router.push({ name: 'Communities' });

    const community = reactive<CreateCommunityRequest>({
      title: '',
      subtitle: '',
      status: Status.ACTIVE,
      slug: '',
      description: '',
      access: Access.PUBLIC,
      language: 'de',
    });

    const createCommunity = async () => {
      const createdCommunity = await fillip.communities.createOne(community);
      if (imagePreview.value) await uploadImage();

      router.push({
        name: 'Community',
        params: { slug: createdCommunity.slug },
      });
    };

    const isValid = computed(() => !!community.title && !!community.slug);

    return {
      baseUrl,
      accessTypes,
      languages,
      cancel,
      isValid,
      uploadImage,
      triggerUpload,
      fileRef,
      imagePreview,
      community,
      createCommunity,
      handleFileInput,
    };
  },
});
