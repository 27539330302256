export function isRequired(string) {
  if (!string) {
    return 'general.validation.isRequired';
  }
  return true;
}

export function displayNameRequired(displayName) {
  if (!displayName) {
    return 'profile.displayNameRequired';
  }
  return true;
}

export function emailRequired(email) {
  if (!email) {
    return 'signup.emailRequired';
  }
  return true;
}

export function emailValid(email) {
  // if (!isEmail(email)) {
  //   return 'signup.emailInvalid';
  // }
  return true;
}

export function passwordRequired(password) {
  return Boolean(password) || 'signup.passwordRequired';
}

function isPasswordStrong(password) {
  return (
    /[a-z]/.test(password) && // checks for a-z
    /[A-Z]/.test(password) && // checks for a-z
    /[0-9]/.test(password) && // checks for 0-9
    /\W|_/.test(password) && // checks for special char
    password.length >= 10
  );
}

export function passwordStrong(password) {
  if (isPasswordStrong(password)) {
    return true;
  }
  return 'signup.passwordInvalid';
}

export function usernameRequired(input) {
  return Boolean(input) || 'signup.usernameRequired';
}
