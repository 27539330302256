import { ModuleTypeMeta } from '../../common';

export interface ModuleParticipantVc {
  camera: boolean;
  microphone: boolean;
  screen: boolean;
}

export const ModuleParticipantVcMeta: ModuleTypeMeta<ModuleParticipantVc> = {
  restrictedToTags: ['participant'],
  default: {
    camera: false,
    microphone: false,
    screen: false,
  },
};
