
















































import { ActionControl } from '@fillip/api';
import { defineComponent, PropType, watch } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseMenu',
  props: {
    title: {
      type: String,
      default: '',
    },
    menuTitle: {
      type: String,
      default: '',
    },
    menuSubtitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'ellipsis-v',
    },
    color: {
      type: String,
      default: '',
    },
    actions: {
      type: Array as PropType<ActionControl[]>,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: true,
    },
    itemArgument: {
      type: [Object, Array, String, Number, Boolean],
      default: undefined,
    },
    small: {
      type: Boolean,
      default: false,
    },
    activator: {
      type: String as PropType<'button' | 'listItem'>,
      default: 'button',
    },
  },
  setup(props) {
    return {};
  },
});
