


















































































































import { GlobalPropsNode } from '@/plugins/global-props';
import Prompt from '@/features/app-bar/Prompt.vue';
import MainMenu from '@/features/app-bar/MainMenu.vue';
import MainControl from '@/features/app-bar/MainControl.vue';
import Me from '@/features/app-bar/Me.vue';
import Navigation from '@/features/app-bar/Navigation.vue';
import ControlButton from '@/components/control-button/ControlButton.vue';
import { EvaluatedModuleVideoConferencing } from '@fillip/api';
import { usePermissions } from '@/composables';

export default {
  // Converted in branch remerge-vc-refactor
  name: 'AppBar',
  components: {
    Navigation,
    MainControl,
    Prompt,
    MainMenu,
    Me,
    ControlButton,
  },
  mixins: [GlobalPropsNode],
  inject: ['themeManager', 'videoConferencingManager'],
  setup() {
    const { canEditCommunity, canSeePrototype } = usePermissions();

    return {
      canEditCommunity,
      canSeePrototype,
    };
  },
  data() {
    return {
      show: false,
      showToolbar: false,
      showMainMenu: false,
      cameraMoveable: false,
    };
  },
  // setup() {
  // const micPermission = usePermission('microphone');
  // const camPermission = usePermission('camera');
  // return {
  //   micPermission,
  //   camPermission,
  // };
  // },
  computed: {
    // micPermissionDenied() {
    //   return this.micPermission == 'denied';
    // },
    // camPermissionDenied() {
    //   return this.camPermission == 'denied';
    // },
    participantStatusControl() {
      return this.$getGlobalProp('participantStatusControl') || {};
    },
    isMuted() {
      return this.videoConferencingManager.value.isMuted;
    },
    isCameraOn() {
      return this.videoConferencingManager.value.isCameraOn;
    },
    denseAppBar() {
      return this.themeManager.value.denseAppBar;
    },
    vcSettings(): EvaluatedModuleVideoConferencing {
      return this.$getGlobalProp('videoConferencing');
    },
  },
  mounted() {
    this.$bindGlobalProp('cameraMoveable', () => this.cameraMoveable);
    window.addEventListener('keydown', (e) => {
      this.addKeyboardShortcuts(e);
    });
  },
  beforeDestroy() {
    window.removeEventListener('keydown', (e) => {
      this.addKeyboardShortcuts(e);
    });
  },
  methods: {
    toggleSearch() {
      const route = this.$navdrawer.route;
      if (
        route.name == 'Search' &&
        !route.query.hidden &&
        !route.query.minimal
      ) {
        this.$navdrawer.push({ ...route, query: { minimal: 1 } });
      } else {
        this.$navdrawer.push({ name: 'Search' });
      }
    },
    toggleEditor() {
      if (this.$drawer.route.fullPath != '/') {
        this.$drawer.push('/');
      } else {
        this.$drawer.push({ name: 'Editor' });
      }
    },
    close() {
      if (this.show) {
        return (this.show = false);
      } else if (this.showToolbar) {
        return (this.showToolbar = false);
      } else if (this.showMainMenu) {
        return (this.showMainMenu = false);
      } else return;
    },
    addKeyboardShortcuts(e) {
      if (e.shiftKey && e.altKey) {
        e.code == 'KeyE' ? this.toggleEditor() : null;
      }
    },
    async toggleMicrophone() {
      this.videoConferencingManager.value.toggleMicrophone();
    },
    async toggleCamera() {
      this.videoConferencingManager.value.toggleCamera();
    },
  },
};
