


















import { defineComponent } from '@vue/composition-api';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';

export default defineComponent({
  name: 'PlacementGridEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: 'placement',
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: props.path,
    });

    return {
      buffer,
      save,
    };
  },
});
