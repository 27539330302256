



















import Vue from 'vue';
import { BaseDocumentEditor } from '@/features/editor/base/BaseDocumentEditor';
import InputStyledText from '@/components/input/styled-text/InputStyledText.vue';

export default Vue.extend({
  name: 'BlockEditorStyledText',
  components: {
    InputStyledText,
  },
  mixins: [BaseDocumentEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    updateContent({ html, text }) {
      this.buffer.info.content = html;
      this.buffer.info.title = text
        .slice(0, 50)
        .replace('/[\n\r]/g', '')
        .trim();
      this.immediateSave('info');
    },
  },
});
