import { DataDocument, PresetDefinition } from '@fillip/api';

const DefaultDocument: DataDocument = {
  id: 'main',
  info: {
    title: 'New Document',
  },
  tag: {
    tag: 'document',
  },
};

export const PresetDocument: PresetDefinition = {
  id: 'presetDocument',
  title: 'Document',
  icon: 'file-alt',
  group: 'data',
  version: '0.1',
  documents: [DefaultDocument],
};
