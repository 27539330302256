import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagPage: DataDocument = {
  id: 'tag-page',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Page',
    icon: 'file-alt',
    isProtected: true,
  },
  size: {
    minWidth: '100vw',
    maxWidth: '100vw',
    minHeight: '100vh',
    maxHeight: '100vh',
    minDepth: '0',
    maxDepth: '0',
  },
  children: {
    default: [
      {
        templateId: 'fragment-header',
        query: ':[data]',
      },
      {
        templateId: 'tag-document',
        query: ':[data]',
      },
    ],
  },
  class: {
    class: 'overflow-y-auto overflow-x-hidden',
    elementClass: '',
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'page',
    title: 'Page',
    category: 'content',
    icon: 'file-alt',
    editor: 'default',
  },
  interfaceDefinition: {
    title: '',
    inheritFrom: 'tag-document',
  },
};
