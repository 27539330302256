import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);
// Vue.config.devtools = true;
// Vue.config.productionTip = false;
// Vue.config.performance = true;

import App from './App.vue';
// TODO: Enable again in the future
// Then also re-add in package.json:
// "@vue/cli-plugin-pwa": "~4.5.13",
// import './registerServiceWorker';

import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';

// Plugins

import { abilitiesPlugin } from '@casl/vue';
import { Ability } from '@casl/ability';

// Custom Plugins

import BaseComponents from './plugins/base';
import layoutRouter from './plugins/layoutRouter';
import GlobalProps from './plugins/global-props';
import { Fillip } from '@fillip/vue-client';
import {
  FillipStore,
  FillipStoreDispatcher,
  rootDataId,
  localPrefix,
  localhostUrls,
} from '@fillip/api';

// Global Mixins
import MainMixin from './mixins/main-mixin';

import themeStyles from './styles/theme';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@/utils/icons';

const apiUrl = (() => {
  const host = window.location.hostname;
  if (localhostUrls.includes(host)) {
    return `http://${host}:${process.env.VUE_API_PORT || '18001'}/api`;
  }
  return `https://api.${host}/api`;
})();

const wsUrl = (() => {
  const host = window.location.hostname;
  if (localhostUrls.includes(host)) {
    return `ws://${host}:${process.env.VUE_WSS_PORT || '18086'}`;
  }
  return `wss://ws.${host}`;
})();

Vue.use(Fillip, {
  apiUrl,
  wsUrl,
  store,
});
Vue.use(BaseComponents);
Vue.use(layoutRouter as any, router);
Vue.use(abilitiesPlugin, new Ability(), {
  useGlobalProperties: true,
});
Vue.use(GlobalProps);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.errorHandler = (error, vm) => {
  console.log('Vue.config.errorHandler', error, vm);
  store.dispatch('displayError', error);
};

Vue.prototype.$fiStore = new FillipStore(store, 'local');

Vue.mixin(MainMixin);

const instance = new Vue({
  i18n,
  store,
  beforeCreate() {
    this.$store.dispatch('styles/setStyle', themeStyles);
    this.$store.commit('local/RESET_STATE', {});
  },
  created() {
    this.ensureDocumentExists('data', {
      id: localPrefix + rootDataId,
      parentId: null,
      tag: {
        tag: 'collection',
      },
      info: {
        title: 'Root',
        icon: '',
      },
      properties: {},
      list: {
        items: [],
      },
    });
  },
  methods: {
    ensureDocumentExists(collection, document) {
      if (!this.$fiStore.getDocument(collection, document.id)) {
        this.$fiStore.setDocument(collection, document.id, document);
      }
    },
  },
  router,
  vuetify,

  render: (h) => h(App),
} as any);

Vue.prototype.$fiStoreDispatcher = new FillipStoreDispatcher(instance);

if (document.getElementById('app')) {
  instance.$mount('#app');
}
