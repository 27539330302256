










































































































import VideoTrackConsumer from '@/features/video-conferencing/VideoTrackConsumer.vue';
import { getCurrentInstance, ref, watch } from '@vue/composition-api';
import { useDevicesList, usePermission, useUserMedia } from '@vueuse/core';

export default {
  name: 'SelectDevices',
  components: {
    VideoTrackConsumer,
  },
  props: {
    deviceCategory: {
      type: String,
      default: 'all',
    },
  },

  setup() {
    const vm = (getCurrentInstance() as any).proxy;
    const vcState = vm.$store.state.videoConference;
    const currentCamera = ref<string>(null);
    const currentMicrophone = ref<string>(null);
    const currentSpeaker = ref<string>(null);
    const micPermission = usePermission('microphone');
    const camPermission = usePermission('camera');
    const speakPermission = usePermission('speaker');
    //! Separation of useDevicesList calls, so the permissions of camera and mic / audio do not interfere with e.o.
    // For the same reason we check each device independently via usePermission
    const { audioInputs: microphones, audioOutputs: speakers } = useDevicesList(
      {
        constraints: { audio: true, video: false },
        onUpdated() {
          if (
            !microphones.value.find(
              (i) => i.deviceId === currentMicrophone.value,
            )
          )
            currentMicrophone.value = microphones.value[0]?.deviceId;
          if (!speakers.value.find((i) => i.deviceId === currentSpeaker.value))
            currentSpeaker.value = speakers.value[0]?.deviceId;
        },
      },
    );
    const { videoInputs: cameras } = useDevicesList({
      constraints: { audio: false, video: true },
      onUpdated() {
        if (!cameras.value.find((i) => i.deviceId === currentCamera.value))
          currentCamera.value = cameras.value[0]?.deviceId;
      },
    });

    currentCamera.value =
      vcState.streams.camera.device || cameras.value[0]?.deviceId;
    currentMicrophone.value =
      vcState.streams.microphone.device || microphones.value[0]?.deviceId;
    currentSpeaker.value =
      vcState.speaker.device || speakers.value[0]?.deviceId;

    const submitDeviceSettings = () => {
      const newSettings = {
        camera: currentCamera.value || cameras.value[0]?.deviceId,
        microphone: currentMicrophone.value || microphones.value[0]?.deviceId,
        speaker: currentSpeaker.value || speakers.value[0]?.deviceId,
      };
      vm.$store.dispatch('videoConference/applyDeviceSettings', newSettings);
      if (vm.layout.area != 'global') {
        vm.navigate('/');
      }
    };
    submitDeviceSettings();
    const { stream } = useUserMedia({
      videoDeviceId: currentCamera,
      audioDeviceId: false,
      enabled: true,
      autoSwitch: true,
    });

    watch(
      [currentCamera, currentMicrophone, currentSpeaker],
      ([newCam, newMic, newSpeak]) => {
        submitDeviceSettings();
      },
    );

    return {
      micPermission,
      camPermission,
      speakPermission,
      cameras,
      microphones,
      speakers,
      stream,
      currentCamera,
      currentMicrophone,
      currentSpeaker,
    };
  },
  data() {
    return {
      errorMessage: null,
    };
  },
  computed: {
    showCameraSelection() {
      return ['all', 'camera'].includes(this.deviceCategory);
    },
    showMicrophoneSelection() {
      return ['all', 'microphone'].includes(this.deviceCategory);
    },
    showSpeakerSelection() {
      return ['all', 'speaker'].includes(this.deviceCategory);
    },
  },
  watch: {
    camPermission: {
      handler(newValue, oldValue) {
        this.errorMessage =
          newValue == 'prompt'
            ? this.$t('videoConference.permissionDenied')
            : null;
        if (newValue == oldValue) return;
        if (newValue == 'granted' && !this.currentCamera)
          this.$emit('permissionGranted');
      },
    },
  },
};
