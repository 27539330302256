import { DataDocument, toolsTemplateDirectoryId } from '@fillip/api';

export const TemplateToolWordCloud: DataDocument = {
  id: 'word-cloud',
  info: {
    title: 'Tool: WordCloud',
    icon: 'cloud',
  },
  tag: {
    tag: 'template',
  },
  parentId: toolsTemplateDirectoryId,
  list: {
    items: [
      {
        id: 'WordcloudContainer',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'WordcloudContainer',
        query: ':[data]',
      },
    ],
  },
  actions: {
    actions: [
      {
        name: 'Textfield',
        type: 'action.textfield',
        script:
          "createDocument($$responses,'page',input,{properties:{wordValue:{type:'number',value:50}}})",
        id: 'default',
        color: 'rgba(117, 117, 117,1)',
        focused: 'always',
        condition: ':$$responsesByUser < $$allowedResponses',
        roles: null,
        placeholder:
          ":`Was denken Sie? ${$$maxLength ? `(max. ${$$maxLength} Zeichen)` : ''}`",
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'allowMultiselect',
        query: ':$$maxOptions > 1',
      },
      {
        identifier: 'allowedResponses',
        query:
          ':properties.allowedResponses.value >= 0 ? properties.allowedResponses.value : 1',
      },
      {
        identifier: 'allResponses',
        query: ':getList($$responses)()',
      },
      {
        identifier: 'responsesByUser',
        query:
          ":pipe(wrapInFunction($$allResponses), where(matchesProperty('info.createdBy', $me.id)))().length",
      },
      {
        identifier: 'isEmpty',
        query: ':$$allResponses.length == 0',
      },
      {
        identifier: 'maxLength',
        query: ':properties?.maxLabelLength?.value || 30;',
      },
    ],
  },
  class: {
    elementClass: '',
    class: 'w-full h-full grid grid-cols-1 p-12',
  },
};

export const TemplateToolWordCloudChildren: DataDocument[] = [
  {
    id: 'WordcloudContainer',
    info: {
      title: 'WordCloud: Container',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'word-cloud',
    list: {
      items: [
        {
          id: 'WordCloudChart',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'tag-h3',
          query: ':[data]',
          propMappings: {
            text: ':$$wordCloudTitle',
          },
        },
        {
          templateId: 'WordCloudChart',
          query: ':[data]',
          identity: '',
          condition: ':!$$isEmpty',
        },
        {
          templateId: 'empty-state-cover',
          query: ':[data]',
          condition: ':$$isEmpty',
          identity: '',
        },
      ],
    },
    class: {
      class: ':`${$$containerClasses} p-6 h-full grid grid-cols-1`',
      elementClass: '',
    },
  },
  {
    id: 'WordCloudChart',
    info: {
      title: 'WordCloud: Chart',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'WordcloudContainer',
    variables: {
      variables: [
        {
          identifier: 'numberOfResponses',
          query: ':$$allResponses.length',
        },
        {
          identifier: 'noResponses',
          query: ':$$numberofResponses <= 0',
        },
        {
          identifier: 'datasetColors',
          query:
            ":$$colorSwatches || ['rgba(143, 191, 249,0.7)','rgba(249, 143, 244,0.7)','rgba(249, 201, 143,0.7)','rgba(143, 249, 148,0.7)']",
        },
        {
          identifier: 'groupedResponses',
          query:
            ':_.groupBy(response => _.trim(response.info.title).toLowerCase())($$allResponses)',
        },
        {
          identifier: 'consolidatedResponses',
          query:
            ':Object.keys($$groupedResponses).map((key) => ({\nlabel: $$allResponses.find(r => _.trim(r.info.title).toLowerCase() === key).info.title,\ncount: $$groupedResponses[key].length\n}))',
        },
        {
          identifier: 'labels',
          query: ':$$consolidatedResponses.map((response) => response.label)',
        },
        {
          identifier: 'datasetLabels',
          query:
            ":$$noResponses ? [''] : $$labels.map(l => l.length > $$maxLength ? l.substring(0,$$maxLength-3) + '...' : l)",
        },
        {
          identifier: 'scaleMultiplier',
          query: ':1/(Math.log($$numberOfResponses * 0.75 + 2))',
        },
        {
          identifier: 'values',
          query:
            ': $$consolidatedResponses.map((response) => {\n  return response.count * 50 * $$scaleMultiplier;\n});',
        },
        {
          identifier: 'datasetValues',
          query: ':$$noResponses ? [0]: $$values ',
        },
      ],
    },
    class: {
      class: 'w-full h-full',
      elementClass: '',
    },
    element: {
      type: 'element.chart',
      data: {
        datasets: [],
        labels: ':$$datasetLabels',
      },
      chartType: ":'wordCloud'",
      scriptedDatasets:
        ':[{data:$$datasetValues,color: $$datasetColors, label: $$wordCloudTitle}]',
      scriptedOptions:
        ':({\n  plugins: {\n    legend: {\n      display: false,\n    },\n  },\n  minRotation: 0,\n  maxRotation: 0,\n  padding: 4\n})',
      options: {
        customLayers: false,
        layers: 1,
        borderWidth: 2,
        borderRadius: 2,
        minX: 0,
        minY: 0,
        maxX: 9,
        maxY: 0,
        colorGridLines: 'rgba(229, 231, 235, 1)',
        colorAxis: 'rgb(66, 59, 59)',
        labelX: 'X',
        labelY: 'Y',
        ticksUnitX: '',
        ticksUnitY: '',
        showGrid: false,
        horizontal: false,
        beginAtZero: false,
        showLabel: false,
        labelAlign: 'center',
        ticksUnitPosition: 'after',
        showAxis: false,
      },
      editor: {
        scriptedDatasets: true,
        scriptedType: true,
        scriptedOptions: false,
      },
    },
  },
];
