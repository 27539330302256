import type { Shell } from './../types';
import { internalPrivacy, generateExternalPrivacyPolicy } from '../utils';

export const plebs: Shell = {
  name: 'plebs',
  title: 'PLEBS',
  language: 'de',
  defaultUrl: 'plebs.schule',
  // logo: 'https://media.collabl.space/image/AN0EXRFMjtqg--plebs-logo-trans.png',
  logo: 'https://media.collabl.world/image/pEV4_qyMkpoc--lmu-schulgelaende-1200.png',
  allowSignup: true,
  allowLogin: false,
  allowGoogleLogin: false,
  allowDiscordLogin: false,
  allowAnonymousLogin: true,
  hideUsernameInput: true,
  restrictCommunities: [
    'wise22',
    'plebs',
    'demo-sose22',
    'plebs-wise22',
    'demo-wise22',
    'demo',
    'plebs-wise23',
    'wise23',
  ],
  communityDesignation: {
    singular: 'Kurs',
    plural: 'Kurse',
  },
  classes: {
    siteContainer: 'bg-[rgba(47,185,188,0.1)] min-h-full flex flex-col',
    headerContainer:
      'p-8 pb-20 md:pt-12 md:px-24 md:pb-32 p-10 flex flex-col justify-center items-center bg-[rgba(63,127,130,0.25)]',
    headerContentContainer: 'flex flex-col justify-center my-4',
    headerRow: 'flex flex-col md:flex-row justify-center items-center gap-8',
    siteContent: '-mt-20 md:-mt-24',
    contentCardContainer:
      'h-full w-full flex flex-col justify-items-start md:justify-center pl-0 md:pl-24',
    contentCard: 'relative bg-white rounded-lg p-6 shadow-lg-od',
    contentBox: 'bg-white rounded-lg p-6 shadow-lg',
    title:
      'font-extrabold text-[rgba(63,127,130,1)] text-5xl md:text-6xl xl:text-8xl mb-2',
    subtitle: 'text-xl md:text-2xl lg:text-3xl mb-4 text-gray-600',
    contentContainer: 'p-10 mb-4',
    contentWrapper:
      'p-10 flex flex-col justify-center max-w-4xl mx-auto w-full flex justify-center',
    sectionWrapper: 'mb-16',
    sectionContent: 'mx-auto max-w-full lg:max-w-4xl xl:max-w-6xl',
    content: 'prose',
    contentTitle: 'text-xl md:text-2xl lg:text-3xl font-bold text-gray-600',
    logoContainer: '',
    logo: 'w-auto h-48 lg:h-96',
    imageContainer: '',
    image: 'h-48 lg:h-96',
    primaryButtonClasses: 'bg-[rgba(47,185,188,1)] text-white',
    legalContainer: 'self-center',
    legal: 'text-gray-600 hover:text-underline',
    footerContainer: 'flex flex-col justify-center text-center p-4',
    logobarContainer:
      'bg-white rounded-lg shadow-sm p-2 md:flex md:flex-row justify-center gap-4 max-w-full mb-6 text-center',
    logobarLogo: 'inline-flex max-w-full',
    loginTitle: 'text-2xl font-semibold mb-4',
    link: 'text-underline text-[rgba(47,185,188,1)] hover:text-[rgba(63,127,130,1)]',
  },
  content: {
    title: 'PLEBS',
    subtitle:
      'Die Plattform für Lehrerinnen und Lehrer als Demokratiebotschafter.',
    leadText:
      'Das Ziel der Plattform PLEBS ist es, die professionelle Kompetenz von Lehrkräften in der Aus- und Weiterbildung zu fördern, sodass diese befähigt sind die Förderung von Demokratiekompetenzen in Schule und Unterricht zu integrieren.',
    content: [
      {
        title:
          'PLEBS, die Plattform für Lehrer*innen als Demokratiebotschafter*innen',
        type: 'full-width',
        text: '<p>Das Ziel der Plattform PLEBS ist es, die professionelle Kompetenz von  Lehrkräften in der Aus- und Weiterbildung zu fördern, sodass diese befähigt sind die Förderung von Demokratiekompetenzen in Schule und Unterricht zu integrieren.</p><p>Die Schule ist ein wichtiger Lern- und Lebensort der Demokratie. PLEBS betrachtet deshalb die verschiedenen Lernorte der Schule, an denen  Demokratie gelernt und gelebt werden kann. Ausgehend von  unterschiedlichen Situationen erhalten die Bearbeitenden die Möglichkeit ihre eigenen Kompetenzen zu erweitern, alltägliche Situationen in der  Schule auf ihre demokratiebildenden Aspekte hin zu reflektieren und  konkrete Möglichkeiten, wie sie auf demokratiefördernde Weise mit vergleichbaren Situationen in ihrem Alltag eingehen können.</p><p>Auf der Startseite der Plattform sind im Schulkomplex verschiedene Fälle angelegt, die jeweils einen Aspekt des demokratischen Lebens und Lernens  in der Schule beleuchten. Jeder Fall ist dabei in sich geschlossen, welche Fälle und in welcher  Reihenfolge diese bearbeitet werden, kann von den User*innen selbst  bestimmt werden, doch sollte ein angefangener Fall abgeschlossen werden,  bevor ein neuer bearbeitet wird.</p><p><strong>Machen Sie sich doch selbst ein Bild und erkunden die Plattform!</p>',
        image:
          'https://media.collabl.space/image/AN0EXRFMjtqg--plebs-logo-trans.png',
        imagePosition: 'left',
      },
      {
        title: 'Entwicklung der Plattform',
        type: 'full-width',
        text: '<p>Wissenschaftliche Studien zeigen, dass Lehrende aller Schularten demokratischer und politischer Bildung eine hohe Bedeutung beimessen, sich aber als hierfür unzulänglich ausgebildet sehen. Mit dem Anliegen diese Lücke zu schließen konzipieren und entwickeln seit 2019 das Team von PLEBS die inhaltliche, didaktische und technische Infrastruktur der <strong>P</strong>lattform für <strong>L</strong>ehrer*innen als D<strong>e</strong>mokratie<strong>b</strong>ot<strong>s</strong>chafter*innen.</p><p>Inhalte, Konzepte und didaktische Umsetzung basieren dabei auf Methoden und Konzepten der Fachdidaktik, durchlaufen mehrstufige Überprüfungsprozesse und werden stetig quantitativ und qualitativ weiterentwickelt. Dazu gehört ein konstanter Auf - und Ausbau der Inhalte sowie wiederholte Durchgänge (Evaluationsschleifen) mit Studierenden des Lehramtes der LMU München.</p>',
        image:
          'https://media.collabl.space/image/Z3eDeMNKAoAA--lmu-evaluation-hero-respo.svg',
        imagePosition: 'right',
      },
    ],
    imprint:
      '<h3>Anbieter</h3><h4>Angaben gemäß § 5 TMG:</h4><p><strong>Das digitale Bildungsmaterial PLEBS wird angeboten von:</strong></p><p>Ludwig-Maximilians-Universität München<br>Geschwister-Scholl-Institut für Politikwissenschaft<br>Lehreinheit Politische Bildung und Didaktik der Sozialkunde<br>Prof. Dr. Markus Gloe<br>Oettingenstr. 67<br>80538 München<br>Telefon: +49(0)89 2180-9009<br>Fax: +49(0)89 2180-9013<br>E-Mail: Markus.Gloe@gsi.uni-muenchen.de</p><p>Das vollständige Impressum der LMU München finden Sie <a href="https://www.gsi.uni-muenchen.de/funktionen/impressum/index.html" target="_blank">hier</a>.</p><h4>Inhaltlich Verantwortlicher gem. § 55 II RStV:</h4><p>Prof. Dr. Markus Gloe<br>Anschrift wie oben</p><h3>Technische Umsetzung und Betrieb</h3><p><strong>Das digitale Bildungsmaterial PLEBS wird auf der Plattform collabl.world umgesetzt. Diese wird betrieben von:</strong></p><p><strong>be-beta.io GbR</strong><br>Sappho Beck &amp; Josef Blank<br>Könnigder Dorfstr. 28<br>39629 Bismark (Altmark)<br>Telefon: +49 39089 319306<br>E-Mail: <a href="hello@bebeta.io">hello@bebeta.io</a> | <a href="mailto:kontakt@beteiligungsagentur.de">kontakt@beteiligungsagentur.de</a><br><a href="http://www.bebeta.io/">www.bebeta.io</a> | <a href="http://www.beteiligungsagentur.de/">www.beteiligungsagentur.de</a></p><p><strong>Vertretungsberechtigte Gesellschafter:innen:</strong><br>Sappho Beck, Josef Blank</p><p><strong>Umsatzsteuer-Identifikationsnummer gem. § 27a UStG:</strong><br>DE 263056854</p><p>',
    privacy: internalPrivacy,
    loginHeader: 'Erkunden Sie die Plattform PLEBS',
    loginInfo:
      'Sie können eine Demo-Version von PLEBS als Gast erkunden. Wenn Sie an der Lehrveranstaltung von Prof. Markus Gloe teilnehmen, erstellen Sie bitte ein Benutzerkonto.',
    anonymousLoginTitle: ' ',
    anonymousLoginAction: 'Ausprobieren',
    loginTitle: 'Mit PLEBS-Account anmelden',
    loginContent:
      'Plattformlogik verstehen: Nutzung der Plattform nach Bearbeitungslogik, Einblick in didaktische Aufbereitung der Fälle',
    logos: [
      {
        logo: 'https://media.collabl.world/image/-taTXqVRmKQe--logo-plebs-lehrerbildung-lmu-h195.png',
        title:
          'PLEBS - Plattform für Lehrkräfte als Demokratiebotschafter an Schulen',
      },
      {
        logo: 'https://media.collabl.world/image/FwEdwINeR6GS--logo-lmu-h195.png',
        title: 'Ludwigs-Maximilians-Universität München',
      },
      {
        logo: 'https://media.collabl.world/image/vPh0KDmzWhwD--logo-lehrerbildung-lmu-bmbf-h195.png',
        title:
          'Lehrerbildung @ LMU / Gefördert vom Bundesministerium für Bildung und Forschung',
      },
    ],
  },
};
