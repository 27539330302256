


































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';

export default Vue.extend({
  name: 'ClassEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'class',
    });
    return {
      buffer,
      save,
    };
  },
});
