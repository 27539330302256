import { render, staticRenderFns } from "./Me.vue?vue&type=template&id=6030cf20&scoped=true&"
import script from "./Me.vue?vue&type=script&lang=ts&"
export * from "./Me.vue?vue&type=script&lang=ts&"
import style0 from "./Me.vue?vue&type=style&index=0&id=6030cf20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6030cf20",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBottomNavigation,VBtn,VDivider,VSheet,VSlideYReverseTransition})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
