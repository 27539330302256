var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-4 hidden sm:flex items-center"},[_c('div',{staticClass:"flex items-center"},[_c('v-menu',{attrs:{"offset-y":"","top":"","tile":"","nudge-top":8,"nudge-left":12},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h1',_vm._g(_vm._b({staticClass:"text-body-1"},'h1',attrs,false),on),[_vm._v(" "+_vm._s(_vm.title)+" ")])]}}])},[_c('v-list',{staticClass:"context-menu v-list-item-group",attrs:{"dense":""}},[_vm._l((_vm.stations),function(object){return _c('v-list-item',{key:object.id,class:{
            'v-item--active v-list-item--active':
              _vm.focusedObject && object.id === _vm.focusedObject.id,
          },style:(_vm.focusedObject && object.id === _vm.focusedObject.id
              ? { color: 'blue' }
              : {}),attrs:{"link":"","color":"primary"},on:{"click":function($event){_vm.isFocused(object.id) ? _vm.removeFocus() : _vm.focus(object.id)}}},[_c('v-list-item-content',[_vm._v(" "+_vm._s(object.title)+" ")]),_c('v-list-item-action',[(_vm.focusedObject && object.id != _vm.focusedObject.id)?_c('BaseButton',{attrs:{"x-small":"","size":"icon","icon":"search-plus","title":_vm.$t('worldNavigation.focus')},on:{"click":function($event){return _vm.focus(object.id)}}}):_c('BaseButton',{attrs:{"x-small":"","size":"icon","icon":"search-minus","title":_vm.$t('worldNavigation.unfocus')},on:{"click":function($event){return _vm.removeFocus()}}})],1)],1)}),_c('v-divider'),(_vm.activeScene)?_c('v-list-item',[_c('v-list-item-content',[_c('div',{staticClass:"flex items-center"},[_vm._l((_vm.breadcrumbs),function(crumb,index){return _c('div',{key:index},[(_vm.breadcrumbs)?[_c('a',{attrs:{"link":""},on:{"click":function($event){return _vm.goUpParents(_vm.breadcrumbs.length - index)}}},[_vm._v(_vm._s(crumb.title))]),_c('span',[_vm._v(" / ")])]:_vm._e()],2)}),_c('a',{staticClass:"ml-1",on:{"click":function($event){return _vm.removeFocus()}}},[_vm._v(" "+_vm._s(_vm.activeSceneTitle))])],2)]),_c('v-list-item-action',[(_vm.parentScene)?_c('BaseButton',{staticClass:"mr-2",attrs:{"icon":"long-arrow-left","title":_vm.$t('worldNavigation.back'),"x-small":"","flat":"","size":"icon"},on:{"click":_vm.goToParent}}):_vm._e()],1)],1):_vm._e()],2)],1),(_vm.canSeePrototype)?_c('div',{staticClass:"flex"},[_c('BaseButton',{attrs:{"icon-only":"","icon":"angle-left","disabled":!_vm.previous,"small":"","style-prop":"height: 28px"},on:{"click":function($event){return _vm.focus(_vm.previous)}}}),_c('BaseButton',{attrs:{"icon-only":"","icon":"angle-right","disabled":!_vm.next,"small":"","style-prop":"height: 28px"},on:{"click":function($event){return _vm.focus(_vm.next)}}})],1):_vm._e(),(_vm.showCircles)?_c('FiCircle'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }