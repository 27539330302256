
















import Vue from 'vue';
import { BaseElement } from './../core';
import DOMPurify from 'dompurify';
import axios from 'axios';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  ref,
  watch,
} from '@vue/composition-api';

export default Vue.extend({
  name: 'ElementSvg',
  mixins: [BaseElement],
  setup(props: any, { emit }) {
    const styleContainer = ref(null);
    const svgContainer = ref(null);
    const isLoading = ref(false);

    const clickables = ref([]);

    const handleEvent = (type, $event) => {
      const data = $event.target.dataset.interactive;
      emit('event', {
        name: type,
        event: {
          input: data,
          event: $event,
        },
      });
    };

    const remoteSrc = computed(() => props?.modules?.element.svgSrc);
    const remoteSvg = ref(null);

    const getRemoteSvg = async (src: string) => {
      if (!src || !src.startsWith('https://')) {
        console.warn('SVG src not valid');
        return;
      }
      isLoading.value = true;
      try {
        const { data: result } = await axios.get(src, {
          transformRequest: (data, headers) => {
            delete headers.common['Authorization'];
            return data;
          },
        });

        remoteSvg.value = result;
        isLoading.value = false;
      } catch (error) {
        console.error('Error getting svg', error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        remoteSvg.value = null;
      }
    };

    watch(
      remoteSrc,
      (newValue, oldValue) => {
        if (!newValue && oldValue) {
          remoteSvg.value = null;
          return;
        }
        if (!newValue || newValue == oldValue) return;

        getRemoteSvg(newValue);
      },
      { immediate: true },
    );

    const svgContent = computed(() => {
      if (remoteSvg.value) return DOMPurify.sanitize(remoteSvg.value);
      return DOMPurify.sanitize(props.modules.element.svgCode || '');
    });

    watch(
      svgContent,
      () => {
        nextTick().then(() => {
          clickables.value.forEach((node, index) => {
            node.removeEventListener('click', ($event) =>
              handleEvent('svgclick', $event),
            );
            node.removeEventListener('mouseenter', ($event) =>
              handleEvent('svgenter', $event),
            );
            node.removeEventListener('mouseleave', ($event) =>
              handleEvent('svgleave', $event),
            );
            clickables.value.splice(index, 1);
          });

          clickables.value = Array.from(
            svgContainer.value.getElementsByClassName('interactive') || [],
          );
          clickables.value.forEach((node: any) => {
            node.addEventListener('click', ($event) =>
              handleEvent('svgclick', $event),
            );
            node.addEventListener('mouseenter', ($event) =>
              handleEvent('svgenter', $event),
            );
            node.addEventListener('mouseleave', ($event) =>
              handleEvent('svgleave', $event),
            );
            clickables.value.push(node);
          });
        });
      },
      { immediate: false },
    );

    onBeforeUnmount(() => {
      clickables.value.forEach((node) => {
        node.removeEventListener('click', ($event) =>
          handleEvent('svgclick', $event),
        );
        node.removeEventListener('mouseenter', ($event) =>
          handleEvent('svgenter', $event),
        );
        node.removeEventListener('mouseleave', ($event) =>
          handleEvent('svgleave', $event),
        );
      });
    });

    const svgStyles = computed(() => {
      return props.modules.element.svgSettings.svgStyles;
    });
    return {
      styleContainer,
      svgContainer,
      isLoading,
      svgContent,
      svgStyles,
      handleEvent,
    };
  },
});
