import { ModuleTypeMeta } from './../../common';
export interface ModuleSceneDefinitions {
  sceneDefinitions: TSceneDefinition[];
}

export interface TSceneDefinition {
  title: string;
  isDynamic: boolean;
  template: string;
  data: string;
  slug: string;
}

export const ModuleSceneDefinitionsMeta: ModuleTypeMeta<ModuleSceneDefinitions> =
  {
    default: {
      sceneDefinitions: [],
    },
    icon: 'bookmark',
    childDefault: {
      title: 'New Scene',
      isDynamic: false,
      template: '',
      data: '',
      slug: '',
    },
  };
