// import { NavigatePlugin, LayoutRouterPlugin } from '@/plugins/layoutRouter';
import { FillipCommands } from '@/features/main/core';
import { getCurrentInstance } from '@vue/composition-api';

export function useNav() {
  const vm = (getCurrentInstance() as any).proxy as Required<FillipCommands>;

  // const $dialog = vm.$dialog as LayoutRouterPlugin;
  // const $drawer = vm.$drawer as LayoutRouterPlugin;
  // const $navdrawer = vm.$navdrawer as LayoutRouterPlugin;
  // const $window = vm.$window as LayoutRouterPlugin;
  // const $navigate = vm.$navigate as NavigatePlugin;
  const $dialog = vm.$dialog;
  const $drawer = vm.$drawer;
  const $navdrawer = vm.$navdrawer;
  const $navigate = vm.$navigate;

  return {
    $dialog,
    $drawer,
    $navdrawer,
    $navigate,
  };
}
