import { ModuleTypeMeta } from '../../common';

export type SizeValueAbsolute = string; // 12.34
export type SizeValueViewport = string; // 12.34vw or 12.34vh
export type SizeDescriptor = SizeValueAbsolute | SizeValueViewport;

export interface EvaluatedModuleSize {
  minWidth: SizeDescriptor;
  minHeight: SizeDescriptor;
  minDepth: SizeDescriptor;
  maxWidth: SizeDescriptor;
  maxHeight: SizeDescriptor;
  maxDepth: SizeDescriptor;
}

export const ModuleSizeMeta: ModuleTypeMeta<EvaluatedModuleSize> = {
  default: {
    minWidth: '',
    minHeight: '',
    minDepth: '',
    maxWidth: '',
    maxHeight: '',
    maxDepth: '',
  },
  icon: 'expand-arrows',
};
