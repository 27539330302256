import { BaseElement } from './../core';
import DOMPurify from 'dompurify';
import 'highlight.js/styles/github-dark.css';

// TODO: Refactor into loadDependency util, add loading indicator

let marked, hljs;
const loadMarked = () => {
  return new Promise((resolve) => {
    // @ts-ignore
    require(/* webpackChunkName: "marked" */ ['marked'], resolve);
  });
};
const loadHighlight = () => {
  return new Promise((resolve) => {
    require(/* webpackChunkName: "marked" */ [
      'highlight.js/lib/common',
      // @ts-ignore
    ], resolve);
  });
};
const initializeMarked = async () => {
  // @ts-ignore
  marked = await loadMarked();
  hljs = await loadHighlight();
  marked.setOptions({
    renderer: new marked.Renderer(),
    highlight: function (code, lang) {
      const hljs = require('highlight.js');
      const language = hljs.getLanguage(lang) ? lang : 'plaintext';
      return hljs.highlight(code, { language }).value;
    },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
    pedantic: false,
    gfm: true,
    breaks: false,
    sanitize: false,
    smartLists: true,
    smartypants: false,
    xhtml: false,
  });
};

export const ElementMarkdown = {
  name: 'ElementMarkdown',
  mixins: [BaseElement],
  data() {
    return {
      isInitialized: false,
    };
  },
  async mounted() {
    await initializeMarked();
    this.isInitialized = true;
  },
  computed: {
    content() {
      return this.isInitialized
        ? marked.parse(DOMPurify.sanitize(this.modules.element.markdownContent))
        : '';
    },
  },
  render(h) {
    return h('div', {
      ref: 'el',
      domProps: {
        innerHTML: this.content,
      },
    });
  },
};
