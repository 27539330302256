<template>
  <BaseButtonRow
    :buttons="buttons"
    :justify="justify"
    :is-valid="isValid"
    @cancel="$emit('cancel')"
  />
</template>

<script>
export default {
  name: 'SaveAndCancelButtons',
  props: {
    justify: String,
    isValid: Boolean,
    saveLabel: String,
  },
  computed: {
    buttons() {
      return [
        {
          label: this.saveLabel || this.$t('general.saveChanges'),
          type: 'submit',
          color: 'primary',
          block: true,
        },
        {
          label: this.$t('general.cancel'),
          method: 'cancel',
          block: true,
        },
      ];
    },
  },
};
</script>
