import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagMetaTag: DataDocument = {
  id: 'tag-metatag',
  info: {
    title: 'Meta Tag',
    icon: 'info-circle',
    isProtected: true,
  },
  tag: {
    tag: 'tag',
  },
  parentId: tagsTemplateDirectoryId,
  computeds: {
    scoped: [
      {
        key: 'variants',
        expression:
          ":({\n  default: {\n    iconSize: 'medium', chipClasses: ':`py-2 px-6 rounded-full flex items-center gap-4 flex text-${$color}-800`',\n    iconClasses: '',\n    iconElementClasses: '',\n    textClasses: 'px-2 items-center text-sm text-${$color}-700',\n  },\n  chip: {\n    iconSize: 'medium', chipClasses: ':`py-2 px-6 rounded-full flex items-center gap-4 text-${$color}-800 bg-${$color}-100`',\n    iconClasses: ':`w-6 h-6 text-center bg-${$color}-300 rounded-full`',\n    iconElementClasses: ':`text-${$color}-800`',\n    textClasses: 'px-2 items-center text-sm text-${$color}-700',\n  },\n  largeChip: {\n    iconSize: 'large', chipClasses: `py-2 px-6 rounded-full flex items-center gap-4`,\n    iconClasses: 'w-5 h-5 lg:w-10 lg:h-10 grid place-content-center',\n    iconElementClasses: 'max-w-[1.25rem] max-h-5 lg:max-w-[2.5rem] lg:max-h-10',\n    textClasses: 'text-sm lg:text-base',\n  },\n})",
        evaluate: false,
      },
      {
        key: 'currentVariant',
        expression: ":$variants[$props?.variant || 'default']",
        evaluate: false,
      },
    ],
  },
  class: {
    class: ":`mr-2 mb-2 flex text-${$color}-800 ${$variant === 'chip' && ``}`",
    elementClass: '',
  },
  children: {
    default: [
      {
        templateId: 'tag-icon',
        query: ':[data]',
        condition: ':$showIcon',
        identity: '${parentId}-${templateId}:${data.id}-icon',
        propMappings: {
          size: ':$currentVariant.iconSize',
          containerClasses: ':$currentVariant.iconClasses',
          elementClasses: ':$currentVariant.iconElementClasses',
        },
      },
      {
        templateId: 'tag-text',
        query: ':[data]',
        identity: '${parentId}-${templateId}:${data.id}-text',
        propMappings: {
          text: ':$text',
          containerClasses: ':$currentVariant.textClasses',
          elementClasses: '',
        },
      },
    ],
  },
  tagDefinition: {
    tag: 'meta',
    title: 'Meta-Tag',
    category: 'content',
    icon: 'info-circle',
    editor: 'default',
    variants: [
      {
        key: 'text',
        title: 'Text',
      },
      {
        key: 'chip',
        title: 'Chip',
      },
      {
        key: 'largeChip',
        title: 'Large Chip',
      },
    ],
  },
  interfaceDefinition: {
    title: '',
    props: [
      {
        title: 'Variante',
        key: 'variant',
        type: 'select',
        options: {
          items: [
            {
              key: 'text',
              title: 'Text',
            },
            {
              key: 'chip',
              title: 'Chip',
            },
            {
              key: 'largeChip',
              title: 'Large Chip',
            },
          ],
        },
        defaultValue: ":$variant || properties?.variant?.value || 'default'",
      },
      {
        title: 'Text',
        key: 'text',
        type: 'string',
        defaultValue: ':$text || properties?.title?.value || info.title',
      },
      {
        title: 'Icon',
        key: 'icon',
        type: 'icon',
        defaultValue: ":$icon || properties?.icon?.value || 'info'",
      },
      {
        title: 'Color Key',
        key: 'color',
        type: 'string',
        defaultValue: '$color || properties?.color?.value || gray',
      },
      {
        title: 'Show Icon',
        key: 'showIcon',
        type: 'boolean',
        defaultValue: ':$showIcon || properties?.showIcon?.value || true',
      },
    ],
  },
};
