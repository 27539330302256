


























































import Vue from 'vue';
import { BaseDocumentEditor } from '@/features/editor/base/BaseDocumentEditor';
import { useTags } from '@/composables';
import { computed } from '@vue/composition-api';

export default Vue.extend({
  mixins: [BaseDocumentEditor],
  props: {
    placeholder: {
      type: String,
      default: '/',
    },
    styling: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const { getTagsByCategory } = useTags();
    const tags = computed(() => getTagsByCategory('content'));
    return { tags };
  },
  data() {
    return {
      showOptions: false,
      filteredTags: [],
      prefix: '',
      lastIndexSlash: null,
      searchCount: 0,
    };
  },
  computed: {
    indexTag() {
      const tag = this.buffer.tag.tag;
      return this.tags.indexOf(tag);
    },
  },
  methods: {
    enterSave() {
      this.immediateSave('info');
      if (this.showOptions) {
        return;
      }
      this.$emit('enterSave', this.buffer.tag);
    },
    async patchTag(tag) {
      //remove search input from buffer
      this.buffer.info.title = this.prefix;
      this.immediateSave('info');
      if (this.buffer.tag.tag == tag) return;
      this.buffer.tag.tag = tag;
      this.$emit('tagChange', this.id);
      this.immediateSave('tag');
    },
    checkForSlash(event) {
      if (
        (event.key == 'Enter' || event.key == 'Backspace') &&
        !this.showOptions
      )
        return;
      const inputValue = event.target.value;
      // open the options
      // set lastIndexSlash to allow search with slash
      if (event.key == '/' && !this.showOptions) {
        this.lastIndexSlash = inputValue.lastIndexOf('/');
        this.showOptions = true;
      }
      if (!this.showOptions) return;
      // ? is there a better way to focus through the items?
      const menu = this.$refs.menuFocus;
      menu.changeListIndex(event);

      // split and filter
      const filterValue = inputValue.slice(this.lastIndexSlash + 1);
      this.prefix = inputValue.slice(0, this.lastIndexSlash);
      const filteredTags = this.tags.filter((tag) =>
        tag.startsWith(filterValue),
      );

      // TODO: Which line is the right one?
      this.filteredTags = filterValue.length > 0 ? filteredTags : this.tags;
      this.filteredTags = filteredTags;

      // focus through options and patch selected on enter

      if (event.key == 'Enter') {
        if (filteredTags.length == 0) {
          this.showOptions = false;
        } else if (filterValue || filterValue.length == 0) {
          this.showOptions = false;
          this.patchTag(this.filteredTags[0]);
        }
      }
      // close menu when initial slash is deleted
      if (
        event.key == 'Backspace' &&
        inputValue.charAt(this.lastIndexSlash) != '/'
      ) {
        this.showOptions = false;
      }
      // close menu after search result has been empty for 4 consecutive inputs => take this as an indicator that the user wants to type slash rather than using the menu
      if (event.key != 'Backspace' && filteredTags.length == 0) {
        this.searchCount += 1;
      } else {
        this.searchCount = 0;
      }
      if (this.searchCount >= 4) {
        this.showOptions = false;
        this.searchCount = 0;
      }
      // close menu and prevent the whole editor to be closed right after
      if (event.key == 'Escape') {
        setTimeout(() => {
          this.showOptions = false;
        }, 300);
        return;
      }
    },
  },
});
