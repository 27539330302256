import { deepEqual } from 'fast-equals';
import Vue from 'vue';
import { GlobalPropsNode } from '../../plugins/global-props';
import { ParticipantStatus } from '@/features/main/core';
import { useVolatileData } from '@/composables';

// TODO: add inkognito status & add grace period of "offline"-status
export default Vue.extend({
  name: 'ParticipantStatus',
  mixins: [GlobalPropsNode],
  inject: ['videoConferencingManager'],
  setup() {
    const { getVolatileProp, setVolatileProp } = useVolatileData();

    return {
      getVolatileProp,
      setVolatileProp,
    };
  },
  data() {
    return {
      defaultIcon: {
        icon: 'question-circle',
        color: 'primary',
      },
      defaultStatusOptions: {
        online: {
          name: 'online',
          title: 'participantStatus.online',
          icon: 'user',
          color: 'primary',
          value: 'online',
          isOverlaying: false,
          isBlocking: false,
          isImportant: false,
          // actionIcons: ['video', 'microphone'],
        },
        beRightBack: {
          name: 'beRightBack',
          title: 'participantStatus.beRightBack',
          icon: 'user-clock',
          value: 'beRightBack',
          color: 'warning',
          isOverlaying: true,
          isBlocking: true,
          isImportant: false,
          actionIcons: ['video-slash', 'microphone-slash'],
        },
        busy: {
          name: 'busy',
          title: 'participantStatus.busy',
          icon: 'user-edit',
          value: 'busy',
          color: 'secondary',
          isOverlaying: true,
          isBlocking: false,
          isImportant: false,
          actionIcons: ['video', 'microphone-slash'],
        },
        needHelp: {
          name: 'needHelp',
          title: 'participantStatus.needHelp',
          icon: 'life-ring',
          value: 'needHelp',
          color: 'error',
          isOverlaying: false,
          isBlocking: false,
          isImportant: true,
        },
        // offline: {
        //   name: 'offline',
        //   title: 'participantStatus.offline',
        //   icon: 'wifi-slash',
        //   value: 'offline',
        //   color: 'error',
        //   isOverlaying: false,
        //   isBlocking: true,
        //   isImportant: true,
        // },
      },
    };
  },
  computed: {
    statusQuery() {
      // TODO: Add offline property as condition OR additional computed with watcher
      return this.$me?.participantStatus?.status || 'default';
    },
    statusControl() {
      return {
        type: 'selectList',
        name: 'setStatus',
        title: 'participantStatus.setStatus',
        subtitle: 'participantStatus.chooseYourStatus',
        iconDefault: { icon: 'question-circle' },
        options: Object.values(this.statusOptions),
        statusQuery: () => {
          return this.statusQuery;
        },
        action: this.setStatus,
      };
    },
    statusOptions() {
      return { ...this.defaultStatusOptions, ...this.addedStatusOptions };
    },
    addedStatusOptions() {
      // TODO: Add merge mixer
      return this.$getGlobalProp('participantStatusAvailableStatus');
    },
    participantVideoConferencingSettings() {
      return this.$me.participantVc;
    },
  },
  watch: {
    statusQuery: {
      immediate: true,
      handler(newValue) {
        this.reactToStatusChange(newValue);
      },
    },
    participantVideoConferencingSettings: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (deepEqual(newValue, oldValue)) return;
        if (this.$me.participantStatus.status == 'beRightBack') {
          if (
            (newValue.camera && newValue.camera != oldValue.camera) ||
            (newValue.microphone && newValue.microphone != oldValue.microphone)
          )
            await this.setStatus('online');
        }
      },
    },
  },

  mounted() {
    this.$bindGlobalProp('participantStatusControl', () => this.statusControl);
  },
  methods: {
    // Participant Status
    getStatus(status) {
      if (!status || !this.statusOptions[status]) {
        return {};
      }
      return this.statusOptions[status] as ParticipantStatus;
    },
    async setStatus(status) {
      if (this.statusQuery == this.statusOptions['online'].name) {
        this.setVolatileProp(
          this.$me.id,
          'previousParticipantVc',
          this.$me.participantVc,
        );
      }
      return this.$invoke('participantSetStatus', this.$me.id, status);
    },
    reactToStatusChange(status) {
      if (!this.videoConferencingManager.value) return;
      if (status === 'online') {
        const previousParticipantVc = this.getVolatileProp(
          this.$me.id,
          'previousParticipantVc',
        );
        this.videoConferencingManager.value.setConferenceAudioState(true);

        this.videoConferencingManager.value.setMicrophoneState(
          previousParticipantVc.microphone || this.$me.participantVc.microphone,
        );
        this.videoConferencingManager.value.setCameraState(
          previousParticipantVc.camera || this.$me.participantVc.camera,
        );
      } else if (status === 'beRightBack') {
        this.videoConferencingManager.value.setMicrophoneState(false);
        this.videoConferencingManager.value.setCameraState(false);
      } else if (status === 'busy') {
        this.videoConferencingManager.value.setMicrophoneState(false);
      }
    },
  },
  render() {
    return null;
  },
});
