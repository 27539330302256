import { extractFromParamsAndMeta } from '@fillip/api';

export default [
  {
    path: '/explorer/:id?',
    name: 'Explorer',
    props: (route) => extractFromParamsAndMeta(route),
    component: () => import('@/features/explorer/Explorer.vue'),
    meta: { permission: 'community:edit' },
  },
  {
    path: '/community-overview',
    name: 'CommunityOverview',
    props: (route) => extractFromParamsAndMeta(route),
    component: () => import('@/features/community/CommunityOverview.vue'),
  },
  {
    path: '/navigation',
    name: 'Navigation',
    props: (route) => extractFromParamsAndMeta(route),
    component: () => import('@/features/app/Navigation.vue'),
  },
];
