




































import AuthContainer from '@/features/auth/components/AuthContainer.vue';
import EmailInput from '@/components/form-inputs/EmailInput.vue';
import { defineComponent, ref, unref } from '@vue/composition-api';
import { useFillip, useMessage, useI18n, useShell } from '@/composables';

export default defineComponent({
  name: 'RequestPasswordReset',
  components: {
    AuthContainer,
    EmailInput,
  },
  setup() {
    const { fillip } = useFillip();
    const message = useMessage();
    const { tr } = useI18n();
    const { shell } = useShell();

    const email = ref('');
    const form = ref(null);
    const submitted = ref(false);
    const loading = ref(false);

    const submit = async () => {
      loading.value = true;
      try {
        await fillip.auth.sendForgotPasswordEmail(unref(email));
        submitted.value = true;
        message.success(tr('auth.passwordResetSentHeading'));
      } catch (e) {
        console.error(e);
        // TODO: Show error message
      } finally {
        loading.value = false;
      }
    };

    const submitAgain = () => {
      submitted.value = false;
    };

    return {
      email,
      form,
      submit,
      submitted,
      submitAgain,
      loading,
      shell,
    };
  },
});
