













import Vue from 'vue';
import { useTags, useSave } from '@/composables';
import { BaseModuleEditor } from '../base';

export default Vue.extend({
  name: 'TagEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'tag',
      defaultValue: '',
    });

    const { tagDefinitionsSelectOptions } = useTags();

    return {
      buffer,
      save,
      tagDefinitionsSelectOptions,
    };
  },
});
