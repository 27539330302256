import Vue from 'vue';

export default {
  namespaced: true,
  state: { db: {} },
  mutations: {
    RESET_STATE(state, db) {
      state.db = db;
    },
    SET(state, { collection, id, value }) {
      if (!state.db[collection]) {
        Vue.set(state.db, collection, {});
      }
      Vue.set(state.db[collection], id, value);
    },
    REMOVE(state, { collection, id }) {
      if (!state.db[collection] || !state.db[collection][id]) return;
      Vue.delete(state.db[collection], id);
    },
    SET_BATCH(state, { collection, values }) {
      if (!state.db[collection]) {
        Vue.set(state.db, collection, {});
      }
      values.forEach((value: any) => {
        Vue.set(state.db[collection], value.id, value);
      });
    },
  },
};
