import { ModuleTypeMeta } from '../../common';
import { Route, RootRoute } from '../../route';

export const NavigationSlots = {
  'Navigation-tree': 'Navigation Tree',
  'Main-drawer top': 'Main-drawer top',
  'Main-drawer bottom': 'Main-drawer bottom',
};

export type TNavigationSlot = keyof typeof NavigationSlots;
export interface Link {
  title: string;
  route: Route;
  displaySlots: TNavigationSlot[];
  sortingIndex: {
    [key in TNavigationSlot]?: number;
  };
  icon: string;
}

export interface ModuleNavigation {
  links: Link[];
}

export interface NavigationTypeMeta extends ModuleTypeMeta<ModuleNavigation> {
  slots: {
    [key in TNavigationSlot]: string;
  };
  defaultLink: Link;
}

export const ModuleNavigationMeta: NavigationTypeMeta = {
  default: {
    links: [],
  },
  icon: 'compass',
  slots: NavigationSlots,
  defaultLink: {
    title: '',
    displaySlots: [],
    sortingIndex: {},
    icon: 'link',
    route: RootRoute,
  },
};
