import { FillipVueClient } from '../client';
import { IFillipVueClientModule } from '../base.client';
import type {
  CreateCompletionRequest,
  CreateImageRequest,
  CreateEditRequest,
  CreateEmbeddingRequest,
} from 'openai';
import {
  AibotRequest,
  DocSearchRequest,
  DocSearchResponse,
  PositionRequest,
  PositionResponse,
} from '@fillip/api';

export class FillipVueAiClient extends IFillipVueClientModule {
  constructor(public root: FillipVueClient) {
    super('ai', root);
  }

  public async createCompletion(
    completionRequest: CreateCompletionRequest,
  ): Promise<string> {
    console.log('createImage in client', completionRequest, this.state.user);
    // TODO: Validate request
    completionRequest.user = this.state.user.id.toString();
    try {
      const result = await this.axios.post(
        `${this.config.apiUrl}/ai/create-completion`,
        completionRequest,
      );
      if (result.status >= 200 && result.status < 300) {
        return result.data;
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      this.handleException(error);
    }
  }

  public async createEdit(editRequest: CreateEditRequest): Promise<string> {
    console.log('createImage in client', editRequest, this.state.user);
    // TODO: Validate request
    try {
      const result = await this.axios.post(
        `${this.config.apiUrl}/ai/create-edit`,
        editRequest,
      );
      if (result.status >= 200 && result.status < 300) {
        return result.data;
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      this.handleException(error);
    }
  }

  public async createImage(
    imageRequest: CreateImageRequest,
  ): Promise<string[]> {
    console.log('createImage in client', imageRequest, this.state.user);
    // TODO: Validate request
    imageRequest.user = this.state.user.id.toString();
    try {
      const result = await this.axios.post(
        `${this.config.apiUrl}/ai/create-image`,
        imageRequest,
      );
      if (result.status >= 200 && result.status < 300) {
        return result.data;
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      this.handleException(error);
    }
  }

  public async createEmbedding(
    embeddingRequest: CreateEmbeddingRequest,
  ): Promise<Array<number>> {
    console.log('createImage in client', embeddingRequest, this.state.user);
    // TODO: Validate request
    embeddingRequest.user = this.state.user.id.toString();
    try {
      const result = await this.axios.post(
        `${this.config.apiUrl}/ai/create-embeddings`,
        embeddingRequest,
      );
      if (result.status >= 200 && result.status < 300) {
        return result.data;
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      this.handleException(error);
    }
  }

  public async createAiBotAnswer(
    aibotRequest: AibotRequest,
  ): Promise<Record<string, any>> {
    console.log('createAiBotAnswer in client', aibotRequest, this.state.user);
    // TODO: Validate request
    // aibotRequest.user = this.state.user.id.toString();
    try {
      const result = await this.axios.post(
        `${this.config.apiUrl}/ai/create-answer`,
        aibotRequest,
      );
      if (result.status >= 200 && result.status < 300) {
        return result.data;
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      this.handleException(error);
    }
  }

  public async findDocuments(
    docSearchRequest: DocSearchRequest,
  ): Promise<DocSearchResponse> {
    console.log(
      'createAiBotAnswer in client',
      docSearchRequest,
      this.state.user,
    );
    // TODO: Validate request
    // docSearchRequest.user = this.state.user.id.toString();
    try {
      const result = await this.axios.post(
        `${this.config.apiUrl}/ai/find-documents`,
        docSearchRequest,
      );
      if (result.status >= 200 && result.status < 300) {
        return result.data;
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      this.handleException(error);
    }
  }

  public async getPosition(
    docSearchRequest: PositionRequest,
  ): Promise<PositionResponse> {
    console.log('getPosition in client', docSearchRequest, this.state.user);
    // TODO: Validate request
    // docSearchRequest.user = this.state.user.id.toString();
    try {
      const result = await this.axios.post(
        `${this.config.apiUrl}/ai/get-position`,
        docSearchRequest,
      );
      if (result.status >= 200 && result.status < 300) {
        return result.data;
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      this.handleException(error);
    }
  }
}
