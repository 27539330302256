import { BaseElement } from './../core';
import ParticipantBox from '@/features/participant/ParticipantBox.vue';
import { ElementParticipant as TElementParticipant } from '@fillip/api';

export const ElementParticipant = {
  name: 'ElementParticipant',
  mixins: [BaseElement],
  render(h) {
    const el = this.modules.element as TElementParticipant;

    const hasParticipantId = el.participantId;
    if (!hasParticipantId) {
      return h('div', { ref: 'el' });
    }

    return h(
      'div',
      {
        class: 'grid grid-cols-1 justify-stretch place-items-center',
        style: {
          height: '100%',
        },
        ref: 'el',
      },
      [
        h(ParticipantBox, {
          props: {
            alwaysShowName: el.alwaysShowName,
            hideDetails: el.participantHideDetails,
            participantId: el.participantId,
            audio: el.participantAudio,
            shape: el.participantShape,
            video: el.participantVideo,
          },
        }),
      ],
    );
  },
};
