










const CodeMirrorWrapper = function (resolve) {
  require(/* webpackChunkName: "codeeditor" */ [
    '@/components/editors/code-editor/CodeMirrorWrapper.vue',
  ], resolve);
};

import Vue from 'vue';

export default Vue.extend({
  name: 'InputCode',
  components: {
    CodeMirrorWrapper,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: '60vh',
    },
    width: {
      type: String,
      default: '100%',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'light',
    },
    language: {
      type: String,
      default: 'json',
    },
    //  ? Is there a way to remove the autofocus on 'div.v-dialog__content.v-dialog__content--active' to be able to focus Json-editor, when opened?
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
});
