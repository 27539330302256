



























































































































import type { DataDocument, Id, TableColumn, TableRow } from '@fillip/api';
import { get } from 'lodash/fp';
import DOMPurify from 'dompurify';
import { useData } from '@/composables';
import InputStyledText from '@/components/input/styled-text/InputStyledText.vue';
import type { Modules, ElementTable as ModuleElementTable } from '@fillip/api';
import { defineComponent, PropType, ref, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'ElementTable',
  components: {
    InputStyledText,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      default: '',
    },
    modules: {
      type: Object as PropType<Modules & { element: ModuleElementTable }>,
      default: () => ({}),
    },
    allowEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { getData, updateProperties, updatePath } = useData();

    const editingCell = ref(null);
    const editingColumn = ref(null);
    const cellContainerClasses =
      'w-full min-h-full flex flex-col whitespace-pre-wrap';

    const elementTable = computed(() => {
      return props.modules?.element;
    });

    const useQuery = computed(() => {
      return elementTable?.value?.tableUseQuery;
    });

    const allowContentEditing = computed(() => {
      return Boolean(elementTable?.value?.tableAllowContentEditing);
    });

    const query = computed<DataDocument[]>(() => {
      return (elementTable?.value?.tableQuery || []) as DataDocument[];
    });

    const rowData = computed<TableRow[]>(() => {
      return (elementTable?.value?.tableRowData || []) as TableRow[];
    });

    const columns = computed<TableColumn[]>(() => {
      return (elementTable?.value?.tableColumns || []) as TableColumn[];
    });

    const rows = computed<TableRow[]>(() => {
      return (useQuery.value ? query.value : rowData.value).map(
        (record: Record<string, any>) => {
          return columns.value.map((column: TableColumn) => {
            return get(column.path, record) || '';
          });
        },
      );
    });

    const documentId = computed<Id>(() => {
      const [templateId, dataId] = props.id.split(':');
      if (templateId == dataId) return templateId;
      return dataId;
    });

    const classes = computed(() => {
      return {
        cells:
          'px-5 py-3 border-solid border-0 border-b border-b-2 border-gray-200 text-gray-800 text-left text-sm align-top',
      };
    });

    const canColumnBeEdited = (columnIndex: number | string) => {
      if (!props.allowEditing && !allowContentEditing.value) return false;
      if (columns.value[columnIndex].locked) return false;
      return true;
    };

    const isCellEditing = (rowIndex: number, columnIndex: number | string) => {
      return (
        editingCell.value?.[0] === rowIndex &&
        editingCell.value?.[1] === columnIndex
      );
    };

    const isColumnEditing = (columnIndex: number | string) => {
      return editingColumn.value === columnIndex;
    };

    const toggleColumnEditing = (columnIndex: number | string) => {
      if (!props.allowEditing) return false;
      if (columnIndex === undefined) {
        editingColumn.value = null;
        return;
      }
      if (isColumnEditing(columnIndex)) return;
      if (editingCell.value) editingCell.value = null;

      editingColumn.value = columnIndex;
    };

    const toggleCellEditing = (
      rowIndex: number,
      columnIndex: number | string,
    ) => {
      if (!canColumnBeEdited(columnIndex)) return false;
      if (isCellEditing(rowIndex, columnIndex)) return false;
      if (editingColumn.value) toggleColumnEditing(columnIndex);
      editingCell.value = [rowIndex, columnIndex];
    };

    const updateCellContent = async (
      { html }: { html: string },
      rowIndex: number,
      columnIndex: string | number,
    ) => {
      if (useQuery.value) {
        const id = query.value[rowIndex].id;
        if (!id) return;
        await updatePath(id, columns.value[columnIndex].path, html, false);
      } else {
        emit('updateCellContent', { html, rowIndex, columnIndex });
      }
      editingCell.value = null;
    };

    const updateColumnHeader = ({ html }: { html: string }, index: number) => {
      if (!props.allowEditing) return;
      emit('updateColumn', { content: html, index });
      editingColumn.value = null;
    };

    const addRow = () => {
      if (!props.allowEditing) return;
      emit('addRow');
    };

    const removeRow = (index: number) => {
      if (!props.allowEditing) return;
      emit('removeRow', index);
    };

    const addColumn = () => {
      if (!props.allowEditing) return;
      emit('addColumn');
    };

    const removeColumn = (index: number | string) => {
      if (!props.allowEditing) return;
      emit('removeColumn', index);
    };

    const getCellClasses = (columnIndex: number | string) => {
      return [classes.value.cells, columns.value[columnIndex]?.classes || ''];
    };

    const sanitizeContent = (content: string) => {
      return DOMPurify.sanitize(content);
    };

    return {
      getData,
      updateProperties,
      updatePath,
      editingCell,
      editingColumn,
      cellContainerClasses,
      elementTable,
      useQuery,
      allowContentEditing,
      query,
      rowData,
      columns,
      rows,
      documentId,
      classes,
      canColumnBeEdited,
      isCellEditing,
      isColumnEditing,
      toggleCellEditing,
      toggleColumnEditing,
      updateCellContent,
      updateColumnHeader,
      addRow,
      removeRow,
      addColumn,
      removeColumn,
      sanitizeContent,
      getCellClasses,
    };
  },
});
