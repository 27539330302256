import Vue from 'vue';

import { useStateBus } from '@fillip/api';
const stateBus = useStateBus('volatile');

// Store for per document, per user volatile state, that's only stored on the local machine
export default {
  namespaced: true,
  state: {
    db: {
      data: {},
    },
  },
  mutations: {
    SET(state, { object, key, value }) {
      Vue.set(object, key, value);
      stateBus.requestUpdate('SET');
    },
    REMOVE(state, { object, key }) {
      Vue.delete(object, key);
      stateBus.requestUpdate('REMOVE');
    },
  },
};
