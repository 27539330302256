import { DataId, ModuleTypeMeta } from '../../common';

export interface DocumentRole {
  key: string;
  title: string;
  documents?: DataId[];
}

export interface ModuleRoles {
  roles: {
    [key: string]: DocumentRole;
  };
}

export const ModuleRolesMeta: ModuleTypeMeta<ModuleRoles> = {
  default: {
    roles: {},
  },
};
