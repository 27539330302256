


































































import Vue from 'vue';
import PropertiesEditorLarge from '@/features/editor/modules/PropertiesEditorLarge.vue';
import TitleEditor from '@/features/editor/modules/TitleEditor.vue';
import ListEditorLarge from '@/features/editor/modules/ListEditorLarge.vue';
import { useData } from '@/composables';
import { useWorkspace } from '@/features/workspace/useWorkspace';
import { Ref, toRef } from '@vue/composition-api';

export default Vue.extend({
  name: 'ToolEditor',
  components: {
    TitleEditor,
    PropertiesEditorLarge,
    ListEditorLarge,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    workspaceId: {
      type: String,
      required: true,
    },
    meta: {
      type: String,
    },
  },
  setup(props) {
    const { getData } = useData();
    const wsId: Ref<string> = toRef(props, 'workspaceId') as Ref<string>;
    const { activateTool } = useWorkspace(wsId);
    return { getData, activateTool };
  },
  computed: {
    doc() {
      return this.getData(this.id);
    },
    editingSettings() {
      if (this.doc?.info?.toolEditing) {
        return this.doc.info.toolEditing;
      }
      return {};
    },
    editors() {
      if (this.doc?.info?.toolEditing?.editors) {
        return this.doc.info.toolEditing.editors;
      }
      if (this.workspaceId == this.id) {
        return {
          title: {
            id: this.id,
          },
          properties: {
            id: this.id,
            title: 'Einstellungen',
          },
        };
      }
      return {};
    },
  },
  methods: {
    activateToolInstance() {
      this.activateTool(this.id);
      this.navigate('/');
    },
  },
});
