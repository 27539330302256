export default {
  signup: {
    signup: 'Create an Account',
    hasAccount: 'Already registered?',
    login: 'Login',
    fillForm: 'Fill out the following fields to create an account.',
    itemRequired: 'Field required',
    usernameRequired: 'Username required',
    emailRequired: 'Email required',
    emailInvalid: 'Invalid email',
    passwordRequired: 'Password required',
    passwordInvalid: 'Not a valid password',
    passwordValidationRequired: 'Password confirmation required',
    passwordValidation: 'The passwords you have entered do not match.',
    terms: 'By entering or creating an account, you agree to ',
    termsEnd: '.',
    privacy: 'our Privacy Policy',
    termsOfService: 'our Terms of Use',
    createAccount: 'Create Account',
    error: {
      emailAlreadyExists: 'An account with this email address already exists.',
      couldNotSendVerificationMail:
        'A verification email could not be delivered to your given address. Please check your inputs and try again.',
    },
  },
  login: {
    pleaseLogin: 'Please login with your account',
    takePart: 'Take part',
    login: 'Login with account',
    loginWithEmail: 'Login with Email',
    forgotPassword: 'Forgot password?',
    noAccountYet: 'No account yet?',
    signup: 'Signup here',
    invalidPassword: 'Please check your password',
    loginAnonymously: 'Join',
    loginWithoutAccount: 'Continue without an account',
    useExistingAccount: 'Login with account',
    google: {
      login: 'Login with Google',
      loginSuccessfull: 'Google login successful',
      loginFailed: 'Google login failed. Please try again.',
    },
    discord: {
      login: 'Login with Discord',
      loginSuccessfull: 'Discord login successful',
      loginFailed: 'Discord login failed. Please try again.',
    },
    gotoLogin: 'Log in with your account',
    error: {
      emailNotVerified: 'Please verify your email to login.',
      invalidPassword: 'User or password incorrect',
      noLocalUserWithThisEmail:
        'An account registered with the given email address could not be found. Please check if you have registered anonymously or with Google.',
      emailOrPasswordNotCorrect:
        'The email or password that you provided are not correct. Please check your inputs.',
      couldNotLoginOAuth: 'Third party login failed. Please try again.',
    },
  },
  logout: {
    logout: 'Logout',
    logoutGlobally: 'Logout on all devices',
    logoutLocally: 'Logout on this device',
    logoutDecision: 'How do you want to continue?',
    emailNotVerified:
      'Watch out! You have upgraded from an anonymous account to a user account, but you have not verified your email address yet. Please check that you have received the verification email and confirm your email address. Otherwise, you may lose access to all of your data!',
    noUserAccount:
      'Watch out! You are currently logged in as an anonymous user. Upon logging out, you will lose access to all of your data. Please consider upgrading to a user account, or remaining logged in with this browser.',
    upgrade: 'Upgrade to User Account',
    upgradeInProcess: 'Upgrade in Process - Please verify your email.',
    abortUpgrade: 'Abort Upgrade Process',
    escape: 'Back to Communities',
    revertDecision: 'Changed your mind?',
    secureData: 'Keep access to all of your data by creating a user account.',
    logoutSuccessful: 'You have successfully logged out.',
  },
  confirmation: {
    heading: 'A verification email has been sent to your email address.',
    emailSent:
      'A verification email has been sent. Please check your inbox for a verification link.',
    confirmationPending:
      'Please check your email for a confirmation message. Be sure to also check your spam email. Please enter the verification code here:',
    code: 'Verification Code',
    confirm: 'Confirm',
    emailNotReceived: 'Verification email not received?',
    requestNewConfirmationEmail: 'Request a new verification email',
    requestNewHeading: 'Worry not!',
    confirmationRequest:
      'Request a new verification email if you did not receive one or the link in it is invalid',
    resendCTA: 'Resend Verification Email',
    confirmationInProgress: 'Verifying email',
    success: 'You have successfully verified your email.',
    backToLogin: 'Back to Login',
    emailAlreadyConfirmed:
      'Your email has already been verified; please check your password. Your password may be reset if you cannot remember it or your account has been lost.',
    error: {
      invalidEmailToken: 'Your email address could not be verified.',
      userAlreadyVerified:
        'Your email address has already been verified. Please login.',
    },
  },
  user: {
    account: 'Your Account',
    username: 'Username',
    email: 'Email',
    newEmail: 'New Email',
    password: 'Password',
    changePassword: 'Change your password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    passwordRequirements:
      'Minimum 10 characters, 1 uppercase letter, 1 number, 1 special character ',
    changeEmail: 'Change your email',
    abortChangeEmail: 'Cancel change of email',
    changeUsername: 'Change your username',
    deleteAccountWarning:
      'Once your account has been deleted, you will be removed from all communities you have joined and your event participation history will be deleted. Be warned that this action is irreversible.',
    deleteAccount: 'Delete Account',
    deletePrompt: 'Are you sure you would like to delete your account?',
    deletePromptYes: 'Yes, delete my account',
    deletePromptNo: 'No, do not delete my account',
    usernameChanged: 'Your username has been successfully updated.',
    emailChanged:
      'Your email has been changed. Please check your email for a verification link.',
    emailChangeCancelled:
      'Your request to change the email registered with your account has been cancelled.',
    passwordChanged:
      'Password successfully updated. To guarantee the change you will be logged out on all devices.',
    upgradeSuccess:
      'Your account has been created. Please check your email for a verification link. Your old account will be logged out on all devices.',
    upgradeAborted: 'The upgrade process has been aborted.',
    provideDisplayName:
      'Please provide a display name, so others may identify you.',
    anonymous: 'Anonymous',
    error: {
      userNotFound:
        'An account with your given credentials could not be found. Please check your inputs or register.',
      currentPasswordWrong: 'Current password incorrect',
      newPasswordMatchesOldPassword:
        'Your new password matches your old password. Please choose a new password.',
      couldNotSendEmail:
        'A verification email could not be delivered to your given address. Please check your inputs and try again.',
      cannotChangePasswordOfNotLocalUser:
        'Passwords cannot be set or changed for anonymous users. Please upgrade your account to a full user account to change your password.',
      cannotChangeEmailOfNotLocalUser:
        'An email address cannot be set or changed for anonymous users. Please upgrade your account to a full user account to change your email address.',
      couldNotChangeUserData:
        'Sorry, something went wrong while changing your account information. Please try again.',
    },
  },
  auth: {
    hello: 'Hello',
    imprint: 'Imprint',
    privacy: 'Privacy',
    createNewPw: 'Create New Password',
    confirmNewPw: 'Set new password',
    forgotPw: 'Forgot password?',
    forgotPwCTA: 'Enter your email below to request a password reset link',
    forgotPwButton: 'Reset password',
    passwordResetSentHeading: 'Password reset link sent',
    passwordResetSentContent: 'Check your inbox to reset your password',
    passwordResetNotReceived: "Didn't receive an email?",
    requestNewPasswordReset: 'Request again',
    backToLogin: 'Back to Login',
    stillLoggedIn:
      'You are still logged in. Please logout first, if you wish to continue with another user account.',
    pleaseLogin: 'Please login to access',
    error: {
      loggedInUserRequired:
        'You need to be logged in with a valid user account to access this page.',
      registeredUserRequired:
        'You need to be logged in with a registered user account to access this page.',
      invalidJwt: 'A valid user account is required.',
      jwtExpired: 'Session expired. Please log in again.',
      passwordRequirements:
        'The password entered does not match the password security requirements. Please enter a different one. ',
      emailRequired: 'Email is required',
      userNameRequired: 'Username is required',
      passwordRequired: 'Password is required',
      unauthorizedToChangeOtherUsersData:
        'You do not have permission to change this information.',
      unauthorizedToAccessApi:
        'You do not have permission to access the requested API.',
      apiTokenRequired:
        'You need to provide a valid API-token to make this request.',
      couldNotUpgrade:
        'Something went wrong upgrading your user account. Please try again!',
    },
  },
};
