<template>
  <audio ref="audio" autoplay playsinline :controls="false"></audio>
</template>

<script>
export default {
  name: 'AudioTrackConsumer',
  props: {
    track: {
      type: MediaStreamTrack,
      required: true,
    },
    muted: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    track: {
      immediate: true,
      handler() {
        this.$nextTick(async () => {
          const audio = this.$refs.audio;
          const stream = new MediaStream();
          stream.addTrack(this.track);
          audio.srcObject = stream;
          try {
            await audio.play();
          } catch (error) {
            console.log(error);
            const startPlay = () => {
              audio.play();
              document.removeEventListener('click', startPlay);
            };
            document.addEventListener('click', startPlay);
          }
        });
      },
    },
    muted: {
      immediate: true,
      handler(newValue) {
        this.$nextTick(async () => {
          const audio = this.$refs.audio;
          if (!newValue) audio.play();
          else if (newValue) audio.pause();
        });
      },
    },
  },
};
</script>
