import { ConnectionManager, type FillipWsClient } from '../../helpers';
import { Producer, ProducerOptions } from 'mediasoup-client/lib/types';
import type {
  FillipVueRealtimeClient,
  MediasoupConnection,
} from '../realtime.client';
import {
  getFullMediasoupResourceId,
  getMediasoupResourceRouterId,
} from '@fillip/api';
import { useDisplayMedia, useUserMedia } from '@vueuse/core';
import { unref } from 'vue-demi';

export class ProducersManager {
  public producers = new Map<string, ConnectionManager<Producer>>();

  constructor(
    public msConnection: MediasoupConnection,
    public owner: FillipVueRealtimeClient,
    public ws: FillipWsClient,
    public communitySlug: string,
  ) {
    // TODO: refactor this to watch meControls of participant  && if videoConference is enabled in community - then respectively add producers like consumers pattern
    ['camera', 'microphone', 'screen'].forEach((producerName) => {
      this.addProducerManager(producerName);
    });
  }

  addProducerManager(name: string) {
    const producerManager = new ConnectionManager<Producer>({
      connectionParameters: null,
      connect: async (parameters, onClose) => {
        const participantId = this.owner.store.getters['client/participantId'](
          this.communitySlug,
        );
        try {
          const getMediaStream = await this.defaultStreamSetup[
            name
          ].streamSelectorCreator(parameters);

          const { mediaStream, track } = await getMediaStream();
          try {
            const options = await this.defaultStreamSetup[name].options;
            const shouldBePaused = (this.owner.store.state as any)
              .videoConference.streams?.[name]?.paused;

            const channel = parameters.channel;
            const roomSize = parameters.roomSize;

            const transport = await this.msConnection.requestTransport(
              'send',
              channel || 'global',
              roomSize || 0.2,
            );

            const routerId = getMediasoupResourceRouterId(transport);
            const producerOptions: ProducerOptions = {
              track,
              appData: { name, shouldBePaused, routerId, channel, roomSize },
              ...options,
            };

            try {
              const producer = await transport.produce(producerOptions);

              const producerId = getFullMediasoupResourceId(producer);
              producer.on('transportclose', () => {
                console.log(
                  `%cProducer transport closed (producer.on-client). ProducerId: ${producerId} `,
                  'color: #da614e;',
                );
                producer.close();
              });
              producer.on('trackended', () => {
                console.log(
                  `%cProducer track ended (producer.on-client). ProducerId: ${producerId}`,
                  'color: #ff6e4a;',
                );
                this.ws.vm.invoke('participantSetVc', participantId, {
                  [name]: false,
                });
              });

              producer.observer.on('pause', () => {
                console.log(
                  `%cProducer paused (producer.observer.on-client). ProducerId: ${producerId}`,
                  'color: #ff8c00;',
                );
                this.ws.mediasoup.pauseProducer(producerId);
              });
              producer.observer.on('resume', () => {
                console.log(
                  `%cProducer resumed (producer.observer.on-client). ProducerId: ${producerId}`,
                  'color: #90ee90;',
                );
                this.ws.mediasoup.resumeProducer(producerId);
              });

              this.owner.store.commit('client/INIT_MEDIASOUP_PRODUCER', {
                communitySlug: this.communitySlug,
                name,
                instance: producer,
                mediaStream,
              });
              producer.observer.on('close', () => {
                console.log(
                  `%cProducer Manager listener on close (producer.observer.on). ProducerId: ${producerId}`,
                  'color: #ff4500',
                );
                onClose(producer);
              });
              return producer;
            } catch (error) {
              try {
                this.msConnection.unrequestTransport('send', channel);
              } catch (error) {
                console.warn(
                  'producersManager - Could not resolve unrequestTransport: ',
                  error,
                );
              }
              throw error;
            }
          } catch (error) {
            mediaStream.getTracks().forEach((track) => {
              track.stop();
            });
            throw error;
          }
        } catch (error) {
          if (error.name === 'NotAllowedError') {
            this.ws.vm.invoke('participantSetVc', participantId, {
              [name]: false,
            });
            console.warn('Permissions denied');
          }
          throw error;
        }
      },
      disconnect: async (connection: Producer) => {
        const producer = this.msConnection.state.producers[name];

        const { instance, mediaStream } = producer;
        this.owner.store.commit('client/RESET_MEDIASOUP_PRODUCER', {
          communitySlug: this.communitySlug,
          name,
        });

        const channel = connection.appData.channel as any as string;

        try {
          instance.close();
          await this.ws.mediasoup.closeProducer(
            getFullMediasoupResourceId(instance),
          );
          await this.msConnection.unrequestTransport('send', channel);
        } catch (error) {
          console.warn(
            'producersManager - Could not resolve closeProducer and unrequestTransport: ',
            error,
          );
          return;
        }

        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      },
    });
    this.producers.set(name, producerManager);

    const unwatchSettings = this.owner.store.watch(
      (state) => {
        const requested =
          state.client.communities?.[this.communitySlug]?.requestedStreams
            ?.producers?.[name];

        if (!requested) return null;

        const channel = requested.channel || 'global';
        const settings = requested.settings;
        const roomSize = requested.roomSize;

        const device = (state as any).videoConference.streams[name].device;
        return device
          ? { deviceId: device, channel, settings, roomSize }
          : { channel, settings, roomSize };
      },
      (newValue) => {
        producerManager.setConnectionParameters(newValue);
      },
      { immediate: true },
    );

    producerManager.on('connected', () => {
      const producer = producerManager.connection;
      console.log(`Producer ${producer.id} (${producer.kind}) connected`);
      const unwatchProducerPaused = this.owner.store.watch(
        (state) => {
          return state.client.communities?.[this.communitySlug]
            ?.requestedStreams.producers?.[name]?.paused;
        },
        (shouldBePaused) => {
          if (shouldBePaused) {
            producer.pause();
          } else {
            producer.resume();
          }
        },
        { immediate: true },
      );

      if (producer.kind == 'video') {
        const unwatchResolutionLayer = this.owner.store.watch(
          (state) => {
            const cameraStream = (state as any).videoConference.streams.camera;
            if (!cameraStream) return null;
            const maxResolutionLayer = cameraStream.maxResolutionLayer;
            if (!maxResolutionLayer) return 2;
            return maxResolutionLayer;
          },
          (newValue) => {
            producer.setMaxSpatialLayer(newValue);
          },
          { immediate: true },
        );
        producerManager.on('closed', unwatchResolutionLayer);
      }
      producerManager.on('closed', unwatchProducerPaused);
    });
    producerManager.on('closed', unwatchSettings);
  }

  removeManagerInstance(name: string) {
    const producerManager = this.producers.get(name);
    producerManager.close();
    this.producers.delete(name);
  }

  public close() {
    Array.from(this.producers.keys()).forEach(
      this.removeManagerInstance.bind(this),
    );
  }

  public defaultStreamSetup = {
    camera: {
      streamSelectorCreator({ deviceId, settings }) {
        return async () => {
          const resolutionConstraints = {
            ...settings,
            frameRate: { ideal: 25 },
          };
          const cameraStream = await navigator.mediaDevices.getUserMedia({
            // ! To further apply customization of video quality, add constraints here. Procedure similar to change of device (i.e. start a new producer, close the old one)
            video: deviceId
              ? {
                  deviceId: { ideal: deviceId },
                  ...resolutionConstraints,
                }
              : resolutionConstraints,
          });
          return {
            mediaStream: cameraStream,
            track: cameraStream.getVideoTracks()[0],
          };
        };
      },
      options: {
        encodings: [
          { scaleResolutionDownBy: 4, maxBitrate: 100000 },
          // { scaleResolutionDownBy: 3, maxBitrate: 250000 },
          { scaleResolutionDownBy: 2, maxBitrate: 300000 },
          { scaleResolutionDownBy: 1, maxBitrate: 650000 },
          // { maxBitrate: 850000 },
        ],
        codecOptions: {
          videoGoogleStartBitrate: 1000,
        },
      },
    },
    screen: {
      streamSelectorCreator({ settings }) {
        return async () => {
          const { stream, start } = useDisplayMedia(settings);
          await start();
          return {
            mediaStream: unref(stream),
            track: unref(stream).getVideoTracks()[0],
          };
        };
      },
      options: {
        encodings: [{ maxBitrate: 900000 }],
        codecOptions: {
          videoGoogleStartBitrate: 1000,
        },
      },
    },
    microphone: {
      streamSelectorCreator({ deviceId }) {
        return async () => {
          const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: deviceId
              ? ({
                  deviceId,
                  echoCancellation: true,
                  autoGainControl: false,
                  noiseSuppression: true,
                } as any)
              : {
                  echoCancellation: true,
                  autoGainControl: false,
                  noiseSuppression: true,
                },
          });
          return {
            mediaStream: audioStream,
            track: audioStream.getAudioTracks()[0],
          };
        };
      },
      options: {
        codecOptions: {
          opusStereo: 1,
          opusDtx: 1,
        },
      },
    },
  };
}
