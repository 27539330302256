import { ModuleTypeMeta } from '../../common';

export interface Stream {
  producerId: string;
  participantId: string;
  name: string;
  kind: 'video' | 'audio';
  paused: boolean;
}

export interface ModuleStreams {
  [streamId: string]: Stream;
}

export const ModuleStreamsMeta: ModuleTypeMeta<ModuleStreams> = {
  default: {},
};
