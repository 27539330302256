





















import { useSave } from '@/composables';
import { ModulesMeta } from '@fillip/api';

export default {
  name: 'ConditionalToggleEditor',
  props: {
    id: {
      type: String,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: props.moduleName,
    });

    return {
      buffer,
      save,
    };
  },
  methods: {
    toggleConditional(newValue) {
      if (newValue) {
        this.buffer = {
          type: 'conditional',
          variants: [
            { condition: '', value: ModulesMeta[this.moduleName].default },
            { condition: '', value: ModulesMeta[this.moduleName].default },
          ],
        };
      } else {
        this.buffer = ModulesMeta[this.moduleName].default;
      }
      this.save();
    },
    addCondition() {
      this.buffer.variants.splice(this.buffer.variants.length - 1, 0, {
        condition: '',
        value: ModulesMeta[this.moduleName].default,
      });
      this.save();
    },
  },
};
