




















































































import Vue from 'vue';
import { useSave, useParticipants } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleAvatarMeta } from '@fillip/api';

export default Vue.extend({
  name: 'AvatarEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'avatar',
    });
    const { getDefaultAvatar } = useParticipants();

    return {
      buffer,
      save,
      getDefaultAvatar,
    };
  },
  data() {
    return {
      uploadingImage: false,
      selectedFile: null,
      preview: null,
      myImages: [],
    };
  },
  computed: {
    showPreview() {
      return Boolean(this.selectedFile);
    },
    defaultAvatar() {
      let avatar;
      if (this.$me.avatarUrl) {
        avatar = this.$me.avatarUrl;
      } else {
        avatar = this.getDefaultAvatar(this.id).url;
      }
      return {
        id: 0,
        url: avatar,
      };
    },
    pictures() {
      if (this.defaultAvatar) {
        return [this.defaultAvatar, ...this.myImages];
      } else {
        return this.myImages;
      }
    },
  },
  mounted() {
    if (this.alwaysActive && !this.buffer) {
      this.buffer = ModuleAvatarMeta.default;
      this.save();
    }
    return this.fetchMyImages();
  },
  methods: {
    async selectAvatar(picture) {
      this.buffer = {
        id: picture.id,
        url: picture.url,
      };
      this.save();
    },
    async fetchMyImages() {
      this.myImages = (
        await this.$fillip.media.getMyMediaInBucket('profile')
      ).reverse();
    },
    async uploadImage() {
      try {
        this.uploadingImage = true;
        const { id, url } = await this.$fillip.media.uploadMedia(
          this.selectedFile,
          'profile',
        );
        this.myImages.unshift({ id, url });
        this.selectAvatar({ id, url });
        this.closePreview();
      } finally {
        this.uploadingImage = false;
      }
    },
    closePreview() {
      this.selectedFile = null;
      this.preview = null;
    },
    async handleFileInput(event) {
      if (!event.target.files.length) return;
      this.selectedFile = event.target.files[0];

      const reader = new FileReader();

      reader.onload = (e) => {
        this.preview = e.target.result;
      };

      reader.readAsDataURL(this.selectedFile);
    },
    triggerFileInput() {
      this.$refs.file.click();
    },
  },
});
