<template>
  <font-awesome-icon
    :class="[iconSize, color + '--text', fw, classProp]"
    :icon="iconName"
    :color="color"
    @click="$emit('click', $event)"
  />
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    fixedWidth: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'light',
    },
    classProp: {
      type: String,
      default: '',
    },
  },
  computed: {
    fw() {
      return this.fixedWidth ? 'fa-fw' : '';
    },
    iconSize() {
      if (this.size == 'xl') {
        return 'fa-3x';
      }
      if (this.size == 'large') {
        return 'fa-2x';
      }
      if (this.size == 'small') {
        return 'fa-sm';
      }
      if (this.size == 'x-small') {
        return 'fa-xs';
      }
      if (this.size == 'medium') {
        return '';
      }
      return 'fa-lg';
    },
    iconType() {
      if (this.variant == 'light') {
        return 'fal';
      }
      if (this.variant == 'solid') {
        return 'fas';
      }
      if (this.variant == 'brand') {
        return 'fab';
      }
      return 'fad';
    },
    iconName() {
      return [this.iconType, this.icon];
    },
  },
};
</script>
