<template>
  <div>
    <v-expansion-panel-header :hide-actions="hideActions">
      <div class="flex flex-col"
        ><div class="flex justify-between items-center">
          <div
            class="font-bold overline"
            :style="{
              opacity: isActive ? 1.0 : 0.4,
            }"
          >
            {{ title }}
          </div>
          <slot> </slot>
        </div>
      </div>
    </v-expansion-panel-header>
    <slot name="conditional"> </slot>
  </div>
</template>

<script>
export default {
  name: 'ModuleHeader',
  props: {
    hideActions: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
