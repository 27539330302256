












import { useSave } from '@/composables';
import { ModulesMeta } from '@fillip/api';

export default {
  name: 'ActivateToggleEditor',
  props: {
    id: {
      type: String,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: props.moduleName,
      removeIfNull: true,
    });

    return {
      buffer,
      save,
    };
  },
  computed: {
    isActive() {
      return Boolean(this.buffer);
    },
  },
  methods: {
    toggleActive(newValue) {
      if (newValue) {
        if (!this.buffer) {
          this.buffer = ModulesMeta[this.moduleName].default;
          this.save();
        }
      } else {
        if (this.buffer) {
          this.buffer = null;
          this.save();
        }
      }
    },
  },
};
