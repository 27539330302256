import { BaseElement } from './../core';
import BaseButton from '@/components/base/BaseButton.vue';
import MediasoupVideoConsumer from '@/features/video-conferencing/MediasoupVideoConsumer.vue';
import { useData } from '@/composables';
import { ElementScreenshare as TElementScreenshare } from '@fillip/api';
import { ref } from '@vue/composition-api';
import { useFullscreen } from '@vueuse/core';

export const ElementScreenshare = {
  name: 'ElementScreenshare',
  mixins: [BaseElement],
  setup() {
    const { getData } = useData();
    const screenshareHTMLElement = ref<HTMLElement>(null);
    const {
      isFullscreen,
      isSupported: isFullScreenSupported,
      enter: enterFullscreen,
      exit: exitFullscreen,
    } = useFullscreen(screenshareHTMLElement);
    return {
      getData,
      screenshareHTMLElement,
      isFullscreen,
      isFullScreenSupported,
      enterFullscreen,
      exitFullscreen,
    };
  },
  computed: {
    participant() {
      const el = this.modules.element as TElementScreenshare;
      return this.getData(el.participantId);
    },
  },
  render(h) {
    const hasProducer = this.participant?.streams?.screen?.producerId;

    if (!hasProducer) {
      return h('div', { ref: 'screenshareHTMLElement' });
    }
    return h(
      'div',
      {
        ref: 'screenshareHTMLElement',
        style: {
          width: '100%',
          height: '100%',
          display: 'flex',
          'flex-direction': 'column',
          'justify-content': 'center',
        },
      },
      [
        h(
          'div',
          {
            class: 'max-w-full max-h-full',
          },
          [
            h(MediasoupVideoConsumer, {
              props: {
                key: this.participant.id + '-share',
                producerId: this.participant.streams.screen.producerId,
                participantId: this.participant.id,
                producerName: this.participant.streams.screen.name,
              },
              style: {
                'object-fit': 'contain',
              },
            }),
            // h(BaseButton, {
            //   props: {
            //     title: this.isFullscreen
            //       ? this.$t('videoConference.exitFullscreen')
            //       : this.$t('videoConference.enterFullscreen'),
            //     icon: this.isFullscreen ? 'compress' : 'expand',
            //     classProp: 'absolute top-2 right-2',
            //   },
            //   on: {
            //     click: this.isFullscreen
            //       ? this.exitFullscreen
            //       : this.enterFullscreen,
            //   },
            // }),
          ],
        ),
      ],
    );
  },
};
