<template>
  <v-container>
    <v-row :justify="justify">
      <v-col
        v-for="button in buttons"
        :key="button.index"
        cols="auto"
        align-self="center"
      >
        <BaseButton
          :block="button.block"
          :text="button.text ? true : false"
          :size="button.size"
          :icon="button.size == 'icon'"
          :small="button.size == 'small'"
          :large="button.size == 'large'"
          :x-large="button.size == 'x-large'"
          :rounded="rounded"
          :color="button.color"
          :elevation="button.elevation"
          :plain="button.plain"
          :outlined="button.outlined"
          :margin="button.margin"
          :disabled="
            (button.shouldBeDisabled || button.type == 'submit') && !isValid
          "
          :type="button.type"
          :loading="button.loading"
          :fab="button.fab"
          @click="
            button.type == 'submit'
              ? $emit('submit', $event)
              : $emit(button.method)
          "
        >
          {{ button.label }}
        </BaseButton>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BaseButtonRow',
  props: {
    rows: {
      type: Number,
      default: 1,
    },
    buttons: {
      type: Array,
      default: function () {
        [];
      },
    },
    justify: {
      type: String,
      default: 'center',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    isValid: Boolean,
  },
};
</script>
