import { PresetDefinition } from './../preset.document';

export * from './PresetCollection';
export * from './PresetPage';

import { PresetCollection } from './PresetCollection';
import { PresetPage } from './PresetPage';
import { PresetDocument } from './PresetDocument';

export const DataPresets: Record<string, PresetDefinition> = {
  presetCollection: PresetCollection,
  presetDocument: PresetDocument,
  presetPage: PresetPage,
};
