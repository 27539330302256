




import MediaEditor from '../components/MediaEditor.vue';

export default {
  name: 'PositionalAudioEditor',
  components: {
    MediaEditor,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    library() {
      return [
        {
          text: this.$t('model.audioLoader.options.christmas'),
          value: `${process.env.MINIO_URL}/media/audio/christmas.mp3`,
        },
        {
          text: this.$t('model.audioLoader.options.inspirationalMusic'),
          value: `${process.env.MINIO_URL}/media/audio/inspire.mp3`,
        },
      ];
    },
  },
};
