import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagHtml: DataDocument = {
  id: 'tag-html',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'HTML',
    icon: 'file-code',
    isProtected: true,
  },
  element: {
    type: 'element.markdown',
    markdownContent: ':$text',
  },
  class: {
    class: ':`${$containerClasses}`',
    elementClass: ':`${$elementClasses}`',
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'html',
    title: 'HTML',
    category: 'content',
    icon: 'file-code',
    editor: 'html',
  },
  interfaceDefinition: {
    title: '',
    inheritFrom: 'baseContent',
    props: [
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":`${$$contentTagClasses} ${$$textClasses} ${$containerClasses || properties?.containerClasses?.value || ''}`",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":`${$elementClasses || properties?.elementClasses?.value || ''}`",
      },
    ],
  },
};
