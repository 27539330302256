
















import { BaseModuleEditor } from '../base';
import InputMedia from '@/components/input/media/InputMedia.vue';
import { useSave } from '@/composables';

export default {
  name: 'ImageEditor',
  components: {
    InputMedia,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, data, save } = useSave(props, {
      path: 'element',
    });
    return {
      buffer,
      data,
      save,
    };
  },
  computed: {
    library() {
      return [];
    },
  },
  methods: {
    changeMediaSource(newBuffer) {
      this.buffer = newBuffer;
      this.save();
    },
  },
};
