export default {
  signup: {
    signup: 'Registrieren',
    hasAccount: 'Schon registriert?',
    login: 'Anmelden',
    fillForm:
      'Bitte füllen Sie die folgenden Felder aus, um einen Account zu erstellen:',
    itemRequired: 'Pflichtfeld',
    usernameRequired: 'Benutzername ist erforderlich',
    emailRequired: 'E-Mail Adresse ist erforderlich',
    emailInvalid: 'Keine gültige E-Mail Adresse',
    passwordRequired: 'Passwort ist erforderlich',
    passwordInvalid: 'Kein gültiges Passwort',
    passwordValidationRequired: 'Passwortbestätigung erforderlich',
    passwordValidation: 'Die eingegebenen Passwörter stimmen nicht überein',
    terms: 'Mit Ihrer Teilnahme stimmen Sie ',
    termsEnd: ' zu.',
    privacy: 'unserer Datenschutzerklärung',
    termsOfService: 'unseren Nutzungsbedingungen',
    createAccount: 'Account erstellen',
    error: {
      emailAlreadyExists:
        'Es existiert bereits ein Benutzerkonto mit dieser E-Mail Adresse.',
      couldNotSendVerificationMail:
        'Die Bestätigungsmail konnte leider nicht zugestellt werden. Bitte prüfen Sie Ihre Angaben und versuchen Sie es erneut.',
    },
  },
  login: {
    pleaseLogin: 'Bitte melden Sie sich mit Ihren Zugangsdaten an',
    takePart: 'Jetzt teilnehmen',
    login: 'Mit Account anmelden',
    loginWithEmail: 'Anmelden',
    forgotPassword: 'Passwort vergessen?',
    noAccountYet: 'Noch kein Benutzerkonto?',
    signup: 'Hier registrieren',
    invalidPassword: 'Bitte überprüfen Sie Ihr Passwort',
    loginAnonymously: 'Teilnehmen',
    loginWithoutAccount: 'Ohne Account teilnehmen',
    useExistingAccount: 'Login mit Account',
    google: {
      login: 'Mit Google anmelden',
      loginSuccessfull: 'Login mit Google erfolgreich',
      loginFailed:
        'Login mit Google fehlgeschlagen. Bitte versuchen Sie es erneut.',
    },
    discord: {
      login: 'Mit Discord anmelden',
      loginSuccessfull: 'Login mit Discord erfolgreich',
      loginFailed:
        'Login mit Discord fehlgeschlagen. Bitte versuchen Sie es erneut.',
    },
    gotoLogin: 'Mit Account anmelden',
    error: {
      emailNotVerified:
        'Bitte verifizieren Sie E-Mail Adresse, um sich anmelden zu können.',
      invalidPassword: 'Benutzername oder Passwort falsch',
      noLocalUserWithThisEmail:
        'Unter dieser E-Mail Adresse ist kein Benutzerkonto vorhanden. Bitte prüfen Sie, ob Sie sich anonym oder mit Ihrem Google Account registriert haben.',
      emailOrPasswordNotCorrect:
        'Die E-Mail Adresse oder das Passwort sind nicht korrekt. Bitte prüfen Sie Ihre Angaben.',
      couldNotLoginOAuth:
        'Die Anmeldung über den Drittanbieter ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
    },
  },
  logout: {
    logout: 'Abmelden',
    logoutGlobally: 'Auf allen Geräten abmelden',
    logoutLocally: 'Auf diesem Gerät abmelden',
    logoutDecision: 'Wie möchten Sie fortfahren?',
    emailNotVerified:
      'Vorsicht! Sie haben ein Benutzerkonto erstellt, aber Ihre E-Mail Adresse noch nicht verifiziert. Bitte prüfen Sie, ob Sie eine Bestätigungsmail von fillip erhalten haben und bestätigen Sie Ihre E-Mail Adresse. Andernfalls verlieren Sie den Zugriff auf alle Ihre Daten!',
    noUserAccount:
      'Vorsicht! Sie nutzen die Plattform derzeit ohne Benutzerkonto. Durch das Abmelden verlieren Sie den Zugriff auf alle Ihre Daten und Einträge. Bitte überlegen Sie, ob Sie ein Benutzerkonto anlegen oder mit diesem Browser eingeloggt bleiben wollen.',
    upgrade: 'Benutzerkonto erstellen',
    upgradeInProcess:
      'Ihr Benutzerkonto ist erstellt - Bitte bestätigen Sie Ihre E-Mail Adresse.',
    abortUpgrade: 'Benutzerkonto nicht erstellen',
    escape: 'Zurück zu den Communities',
    revertDecision: 'Meinung geändert?',
    secureData:
      'Behalten Sie Zugang zu allen von Ihnen gespeicherten Daten, indem Sie ein Benutzerkonto erstellen.',
    logoutSuccessful: 'Sie haben sich erfolgreich abgemeldet.',
  },
  confirmation: {
    heading: 'Bestätigen Sie Ihre E-Mail-Adresse',
    emailSent:
      'Eine Bestätigungsmail wurde versandt. Bitte schauen Sie in Ihrem Postfach nach dem Bestätigungscode.',
    confirmationPending:
      'Sie haben eine E-Mail mit dem Bestätigungscode an die angegebene Adresse erhalten. Prüfen Sie auch Ihren Spam-Ordner! Bitte geben Sie den Code hier ein.',
    code: 'Bestätigungs-Code',
    confirm: 'Bestätigen',
    emailNotReceived:
      'Haben Sie keine Bestätigungsmail erhalten? Dann prüfen Sie bitte auch Ihren Spam-Ordner oder fordern eine neue Bestätigungs-E-Mail an.',
    requestNewConfirmationEmail: 'Neue Bestätigungsmail versenden',
    requestNewHeading: 'Keine Sorge!',
    confirmationRequest:
      'Fordern Sie eine neue Bestätigungsmail an, falls Sie bisher keine erhalten haben oder der Bestätigungslink abgelaufen ist.',
    resendCTA: 'Bestätigungsmail anfordern',
    confirmationInProgress: 'E-Mail Adresse wird bestätigt',
    success: 'Sie haben Ihre E-Mail Adresse erfolgreich bestätigt.',
    backToLogin: 'Zurück zum Login',
    emailAlreadyConfirmed:
      'Ihre E-Mail Adresse wurde bereits bestätigt. Bitte überprüfen Sie Ihr Passwort. Wenn Sie sich nicht an Ihr Passwort erinnern können oder Ihr Benutzerkonto verloren ging, kann Ihr Passwort zurückgesetzt werden.',
    error: {
      invalidEmailToken: 'Ihre E-Mail Adresse konnte nicht bestätigt werden',
      userAlreadyVerified:
        'Ihre E-Mail Adresse wurde bereits bestätigt. Bitte loggen Sie sich ein!',
    },
  },
  user: {
    account: 'Ihr Benutzerkonto',
    username: 'Benutzername',
    email: 'E-Mail',
    newEmail: 'Neue E-Mail-Adresse',
    password: 'Passwort',
    changePassword: 'Passwort ändern',
    currentPassword: 'Aktuelles Passwort',
    newPassword: 'Neues Passwort',
    confirmPassword: 'Passwort bestätigen',
    passwordRequirements:
      'mindestens 10 Zeichen, 1 Großbuchstabe, 1 Zahl, 1 Sonderzeichen',
    changeEmail: 'E-Mail Adresse ändern',
    abortChangeEmail: 'Änderung der E-Mail Adresse abbrechen',
    changeUsername: 'Benutzernamen ändern',
    deleteAccountWarning:
      'Sobald Ihr Benutzerkonto gelöscht wurde, werden Sie aus allen Commmunities, an denen Sie beteiligt waren, entfernt. Alle Daten werden unwiederbringlich gelöscht. Dies kann nicht rückgängig gemacht werden.',
    deleteAccount: 'Benutzerkonto löschen',
    deletePrompt:
      'Sind Sie sich sicher, dass Sie Ihr Benutzerkonto löschen möchten?',
    deletePromptYes: 'Ja, Benutzerkonto löschen',
    deletePromptNo: 'Nein, Benutzerkonto behalten',
    usernameChanged: 'Ihr Benutzername wurde erfolgreich geändert.',
    emailChanged:
      'Ihre E-Mail Adresse wurde geändert. Bitte schauen Sie in Ihrem Postfach nach dem Bestätigungslink.',
    emailChangeCancelled:
      'Sie haben die Änderung Ihrer E-Mail Adresse abgebrochen.',
    passwordChanged:
      'Passwort erfolgreich geändert. Um die Änderung sicherzustellen, werden Sie auf allen Geräten abgemeldet.',
    upgradeSuccess:
      'Ihr Benutzerkonto wurde erstellt. Bitte schauen Sie in Ihrem Postfach nach dem Bestätigungslink. Ihr vorheriges Benutzerkonto wird auf allen Geräten abgemeldet.',
    upgradeAborted: 'Das Erstellen des Benutzerkontos wurde abgebrochen.',
    provideDisplayName:
      'Bitte geben Sie einen Benutzernamen ein, damit andere Sie wiedererkennen können.',
    anonymous: 'Anonym',
    error: {
      userNotFound:
        'Es konnte kein Benutzerkonto mit den von Ihnen eingegebenen Anmeldedaten gefunden werden. Bitte überprüfen Sie die eingegebenen Daten oder legen Sie ein neues Benutzerkonto an.',
      currentPasswordWrong: 'Das eingegebene Passwort ist falsch.',
      newPasswordMatchesOldPassword:
        'Ihr neues Passwort darf nicht mit dem alten übereinstimmen. Bitte geben Sie ein neues Passwort ein.',
      couldNotSendEmail:
        'Die Bestätigungsmail konnte leider nicht zugestellt werden. Bitte überprüfen Sie Ihre Eingaben und fordern Sie eine neue E-Mail an.',
      cannotChangePasswordOfNotLocalUser:
        'Für anonyme Benutzer können keine Passwörter festgelegt oder geändert werden. Bitte erstellen Sie zunächst ein Benutzerkonto mit Ihrer E-Mail Adresse.',
      cannotChangeEmailOfNotLocalUser:
        'Für anonyme Benutzer kann keine E-Mail Adresse festegelegt oder geändert werden. Bitte erstellen Sie zunächst ein Benutzerkonto mit Ihrer E-Mail Adresse.',
      couldNotChangeUserData:
        'Leider ist bei der Änderung Ihrer Nutzerdaten ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    },
  },
  auth: {
    hello: 'Hallo',
    imprint: 'Impressum ',
    privacy: 'Datenschutz',
    createNewPw: 'Neues Passwort erstellen',
    confirmNewPw: 'Passwort bestätigen',
    forgotPw: 'Passwort vergessen?',
    forgotPwCTA:
      'Geben Sie unten Ihre E-Mail Adresse ein, um einen Link zum Zurücksetzen Ihres Passworts anzufordern.',
    forgotPwButton: 'Passwort zurücksetzen',
    passwordResetSentHeading: 'Link zum Zurücksetzen des Passworts gesendet.',
    passwordResetSentContent:
      'Prüfen Sie Ihren Posteingang, um Ihr Passwort zurückzusetzen.',
    passwordResetNotReceived: 'E-Mail nicht erhalten?',
    requestNewPasswordReset: 'Erneut anfordern',
    backToLogin: 'Zurück zum Login',
    stillLoggedIn:
      'Sie sind noch angemeldet. Bitte melden Sie sich zunächst ab, wenn Sie mit einem anderen Benutzerkonto fortfahren möchten.',
    pleaseLogin: 'Bitte melden Sie sich an, um Zugriff zu erhalten.',
    error: {
      loggedInUserRequired:
        'Sie müssen mit einem gültigen Benutzerkonto eingeloggt sein, um auf diese Seite zugreifen zu können.',
      registeredUserRequired:
        'Sie müssen mit einem nicht anonymen Benutzerkonto angemeldet sein, um auf diese Seite zugreifen zu können.',
      invalidJwt: 'Gültiges Benutzerkonto erforderlich',
      jwtExpired:
        'Die Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
      passwordRequirements:
        'Das eingegebene Passwort erfüllt die Sicherheitsanforderungen nicht. Bitte wählen Sie ein anderes Passwort.',
      emailRequired: 'E-Mail Adresse erforderlich',
      userNameRequired: 'Benutzername erforderlich',
      passwordRequired: 'Passwort erforderlich',
      unauthorizedToChangeOtherUsersData:
        'Sie haben nicht die Berechtigung, um diese Informationen zu ändern.',
      unauthorizedToAccessApi:
        'Sie haben nicht die Berechtigung, um auf diese API zuzugreifen.',
      apiTokenRequired:
        'Sie müssen einen gültigen API-Token angeben, um diese Anfrage zu stellen',
      couldNotUpgrade:
        'Beim Erstellen Ihres Benutzerkontos ist etwas schiefgelaufen. Bitte versuchen Sie es erneut!',
    },
  },
};
