































import { defineComponent, computed, reactive, ref } from '@vue/composition-api';
import { useStore } from '@/composables';

import AdaptiveLayout from '@/components/adaptive-layout/AdaptiveLayout.vue';
import ProducerStats from '@/features/video-conferencing/ProducerStats.vue';
import ConsumerStats from '@/features/video-conferencing/ConsumerStats.vue';
import ReassignPosts from './ReassignPosts.vue';

export default defineComponent({
  name: 'WorldDebug',
  components: {
    AdaptiveLayout,
    ProducerStats,
    ConsumerStats,
    ReassignPosts,
  },
  setup() {
    const currentChild = ref('reassignPosts');
    const changeSubroute = (route: string) => (currentChild.value = route);
    const { store } = useStore();

    const open = reactive({});
    const tabs = {
      reassignPosts: {
        name: 'reassignPosts',
        title: 'debug.reassignPosts',
      },
      locations: {
        name: 'locations',
        title: 'debug.locations',
      },
      producer: {
        name: 'producer',
        title: 'debug.producer',
      },
      consumer: { name: 'consumer', title: 'debug.consumer' },
    };

    const locations = computed(() => {
      return store.getters.communityState?.locations || {};
    });
    return {
      currentChild,
      changeSubroute,
      tabs,
      open,
      locations,
    };
  },
});
