































import Vue from 'vue';
import { BaseDocumentEditor } from '@/features/editor/base/BaseDocumentEditor';
import InputMedia from '@/components/input/media/InputMedia.vue';
import { ElementImage } from '@/features/main/elements/ElementImage';
import { ElementImage as TElementImage, TypeImage } from '@fillip/api';

export default Vue.extend({
  name: 'BlockEditorImage',
  components: {
    InputMedia,
    ElementImage,
  },
  mixins: [BaseDocumentEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  computed: {
    moduleImage(): TElementImage {
      return (
        this.buffer?.info?.content ||
        this.buffer?.properties?.image?.value ||
        {}
      );
    },
    contentAsModule() {
      return {
        element: {
          ...this.moduleImage,
        },
      };
    },
    imageSrc() {
      return this.moduleImage.imageSrc || '';
    },
  },
  methods: {
    updateContent(newContent: TypeImage) {
      this.buffer.info.title = newContent.imageSrc
        .split('/')
        .slice(-1)[0]
        .split('--')
        .slice(-1)[0]
        .slice(0, 50);
      this.buffer.info.content = newContent;
      this.immediateSave('info');
    },
    toggleEditing() {
      if (this.isEditing) {
        this.immediateSave('info');
      }
      this.isEditing = !this.isEditing;
    },
  },
});
