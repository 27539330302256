import {
  PlacementAbsoluteMeta,
  PlacementSlidesMeta,
  PlacementGridMeta,
  PlacementFlexMeta,
  PlacementDivideMeta,
  PlacementCircleMeta,
  PlacementRectangleMeta,
  PlacementTypeMeta,
  PlacementSphereMeta,
  PlacementBlockMeta,
  PlacementOriginMeta,
} from './placement.module';
import { TypeMeta, ModuleTypeMeta } from '../../common';

type ArrangementTypeKeys =
  | 'arrangement.absolute'
  | 'arrangement.slides'
  | 'arrangement.grid'
  | 'arrangement.flex'
  | 'arrangement.divide'
  | 'arrangement.circle'
  | 'arrangement.rectangle'
  | 'arrangement.sphere'
  | 'arrangement.block'
  | 'arrangement.origin';

interface ArrangementType<Key extends ArrangementTypeKeys> {
  type: Key;
}

interface ArrangementTypeMeta<T extends ArrangementType<ArrangementTypeKeys>>
  extends TypeMeta<T> {
  type: T['type'];
  name: string;
  icon?: string;
  placement: PlacementTypeMeta<any>;
  schema?: Record<string, any>;
}

export interface ArrangementAbsolute
  extends ArrangementType<'arrangement.absolute'> {}

export const ArrangementAbsoluteMeta: ArrangementTypeMeta<ArrangementAbsolute> =
  {
    type: 'arrangement.absolute',
    name: 'Absolute',
    placement: PlacementAbsoluteMeta,
    default: {
      type: 'arrangement.absolute',
    },
  };
export interface ArrangementSphere
  extends ArrangementType<'arrangement.sphere'> {}

export const ArrangementSphereMeta: ArrangementTypeMeta<ArrangementSphere> = {
  type: 'arrangement.sphere',
  name: 'Sphere',
  placement: PlacementSphereMeta,
  default: {
    type: 'arrangement.sphere',
  },
};
export interface ArrangementOrigin
  extends ArrangementType<'arrangement.origin'> {}
export const ArrangementOriginMeta: ArrangementTypeMeta<ArrangementOrigin> = {
  type: 'arrangement.origin',
  name: 'Origin',
  placement: PlacementOriginMeta,
  default: {
    type: 'arrangement.origin',
  },
};

export interface ArrangementSlides
  extends ArrangementType<'arrangement.slides'> {}

export const ArrangementSlidesMeta: ArrangementTypeMeta<ArrangementSlides> = {
  type: 'arrangement.slides',
  name: 'Slides',
  placement: PlacementSlidesMeta,
  default: {
    type: 'arrangement.slides',
  },
};

export interface ArrangementGrid extends ArrangementType<'arrangement.grid'> {
  gridColumns: string;
  gridRows: string;
}

export const ArrangementGridMeta: ArrangementTypeMeta<ArrangementGrid> = {
  type: 'arrangement.grid',
  name: 'Grid',
  placement: PlacementGridMeta,
  default: {
    type: 'arrangement.grid',
    gridColumns: '1fr 1fr',
    gridRows: '1fr 1fr',
  },
};

export interface ArrangementFlex extends ArrangementType<'arrangement.flex'> {
  stretchHeight?: boolean;
  flexGutter?: number;
}

export const ArrangementFlexMeta: ArrangementTypeMeta<ArrangementFlex> = {
  type: 'arrangement.flex',
  name: 'Flex',
  placement: PlacementFlexMeta,
  default: {
    type: 'arrangement.flex',
    stretchHeight: false,
    flexGutter: 0,
  },
};
export interface ArrangementBlock extends ArrangementType<'arrangement.block'> {
  blockKeepSize?: boolean;
  blockGutter?: number;
}

export const ArrangementBlockMeta: ArrangementTypeMeta<ArrangementBlock> = {
  type: 'arrangement.block',
  name: 'Block',
  placement: PlacementBlockMeta,
  default: {
    type: 'arrangement.block',
    blockKeepSize: false,
    blockGutter: 0,
  },
};
export interface ArrangementDivide
  extends ArrangementType<'arrangement.divide'> {
  divideAspectRatio: string;
  divideStretch: boolean;
}

export const ArrangementDivideMeta: ArrangementTypeMeta<ArrangementDivide> = {
  type: 'arrangement.divide',
  name: 'Divide',
  placement: PlacementDivideMeta,
  default: {
    type: 'arrangement.divide',
    divideAspectRatio: '1/1',
    divideStretch: true,
  },
};

const arrangementModes = {
  '3D': { title: 'arrangement.rectangle.3d' },
  '2D': { title: 'arrangement.rectangle.2d' },
} as const;

export interface ArrangementCircle
  extends ArrangementType<'arrangement.circle'> {
  circleMode: keyof typeof arrangementModes;
  facingInwards: boolean;
}

export const ArrangementCircleMeta: ArrangementTypeMeta<ArrangementCircle> = {
  type: 'arrangement.circle',
  name: 'Circle',
  placement: PlacementCircleMeta,
  schema: {
    arrangementModes,
  },
  default: {
    type: 'arrangement.circle',
    circleMode: '2D',
    facingInwards: true,
  },
};

export interface ArrangementRectangle
  extends ArrangementType<'arrangement.rectangle'> {
  rectangleMode: keyof typeof arrangementModes;
  rectangleKeepSize: boolean;
}

export const ArrangementRectangleMeta: ArrangementTypeMeta<ArrangementRectangle> =
  {
    type: 'arrangement.rectangle',
    name: 'Rectangle',
    placement: PlacementRectangleMeta,
    schema: {
      arrangementModes,
    },
    default: {
      type: 'arrangement.rectangle',
      rectangleMode: '3D',
      rectangleKeepSize: true,
    },
  };

export type EvaluatedModuleArrangement =
  | ArrangementAbsolute
  | ArrangementSlides
  | ArrangementGrid
  | ArrangementFlex
  | ArrangementDivide
  | ArrangementCircle
  | ArrangementRectangle
  | ArrangementSphere
  | ArrangementOrigin
  | ArrangementBlock;

export interface ModuleArrangementTypeMeta
  extends ModuleTypeMeta<EvaluatedModuleArrangement> {
  types: {
    [key in ArrangementTypeKeys]: ArrangementTypeMeta<ArrangementType<key>>;
  };
}

export interface DefaultArrangement {
  title: string;
  numTools: number;
  arrangement: EvaluatedModuleArrangement;
}

export const DefaultLayouts: Record<string, DefaultArrangement> = {
  single: {
    title: 'Single',
    numTools: 1,
    arrangement: {
      type: 'arrangement.grid',
      gridRows: '1fr',
      gridColumns: '1fr',
    },
  },
  'side-by-side': {
    title: 'Side by side',
    numTools: 2,
    arrangement: {
      type: 'arrangement.grid',
      gridRows: '1fr',
      gridColumns: '1fr 1fr',
    },
  },
  'top-bottom': {
    title: 'Top Bottom Split',
    numTools: 2,
    arrangement: {
      type: 'arrangement.grid',
      gridRows: '2fr 3fr',
      gridColumns: '1fr',
    },
  },
  'main-side': {
    title: 'Main Side',
    numTools: 2,
    arrangement: {
      type: 'arrangement.grid',
      gridRows: '1fr',
      gridColumns: '7fr 3fr',
    },
  },
  'side-main': {
    title: 'Side Main',
    numTools: 2,
    arrangement: {
      type: 'arrangement.grid',
      gridRows: '1fr',
      gridColumns: '3fr 9fr',
    },
  },
  workspace: {
    title: 'Columns',
    numTools: 3,
    arrangement: {
      type: 'arrangement.grid',
      gridRows: '1fr',
      gridColumns: '1fr 1fr 1fr',
    },
  },
  'topbar-main': {
    title: 'Topbar Main',
    numTools: 2,
    arrangement: {
      type: 'arrangement.grid',
      gridRows: '1fr 4fr',
      gridColumns: '1fr',
    },
  },
};

export interface VuetifyBreakpointObject {
  // Breakpoints
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;

  // Conditionals
  xsOnly: boolean;
  smOnly: boolean;
  smAndDown: boolean;
  smAndUp: boolean;
  mdOnly: boolean;
  mdAndDown: boolean;
  mdAndUp: boolean;
  lgOnly: boolean;
  lgAndDown: boolean;
  lgAndUp: boolean;
  xlOnly: boolean;

  // true if screen width < mobileBreakpoint
  mobile: boolean;
  mobileBreakpoint: number;

  // Current breakpoint name (e.g. 'md')
  name: string;

  // Dimensions
  height: number;
  width: number;

  // Thresholds
  // Configurable through options
  tresholds?: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
  };

  // Scrollbar
  scrollBarWidth: number;
}

export const determineLayoutForBreakpoint = (
  layoutName: TDefaultLayout,
  breakpoint: VuetifyBreakpointObject,
) => {
  if (!layoutName) layoutName = 'side-by-side';
  if (layoutName == 'side-by-side') {
    if (breakpoint.smAndDown) {
      return 'top-bottom';
    }
    // if (breakpoint.xlOnly) {
    //   return 'main-side';
    // }
  }
  if (layoutName == 'topbar-main') {
    if (breakpoint.smAndDown) {
      return 'top-bottom';
    }
    if (breakpoint.lgAndUp) {
      return 'side-main';
    }
  }
  if (layoutName == 'top-bottom') {
    // if (breakpoint.lgAndUp) {
    //   return 'side-by-side';
    // }
  }
  if (layoutName == 'main-side') {
    if (breakpoint.smAndDown) {
      return 'top-bottom';
    }
    if (breakpoint.mdAndDown) {
      return 'side-by-side';
    }
  }
  return layoutName;
};

export type TDefaultLayout = keyof typeof DefaultLayouts;

export const ModuleArrangementMeta: ModuleArrangementTypeMeta = {
  types: {
    'arrangement.absolute': ArrangementAbsoluteMeta,
    'arrangement.slides': ArrangementSlidesMeta,
    'arrangement.grid': ArrangementGridMeta,
    'arrangement.flex': ArrangementFlexMeta,
    'arrangement.divide': ArrangementDivideMeta,
    'arrangement.circle': ArrangementCircleMeta,
    'arrangement.rectangle': ArrangementRectangleMeta,
    'arrangement.sphere': ArrangementSphereMeta,
    'arrangement.block': ArrangementBlockMeta,
    'arrangement.origin': ArrangementOriginMeta,
  },
  default: ArrangementAbsoluteMeta.default,
  icon: 'game-board-alt',
};
