import { BaseElement } from './../core';
import { GlobalPropsNode } from '@/plugins/global-props';
import { useMediaControls } from '@/composables';
import { ref } from '@vue/composition-api';

export const ElementAudio = {
  name: 'ElementAudio',
  mixins: [BaseElement, GlobalPropsNode],
  inject: ['router'],
  setup(props: any, { emit }) {
    const audioElement = ref<HTMLMediaElement>(null);
    const templateId = props.id.split(':')[0];
    const { mediaSource } = useMediaControls(
      { ...props, id: templateId },
      {
        mediaType: 'audio',
      },
      audioElement,
      emit,
    );
    return {
      audioElement,
      mediaSource,
    };
  },
  render(h) {
    return h('audio', {
      ref: 'audioElement',
      attrs: {
        crossorigin: 'anonymous',
        src: this.mediaSource,
      },
      style: {
        display: 'none',
      },
    });
  },
};
