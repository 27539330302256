
















import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseAvatar',
  props: {
    margin: { type: String, default: '' },
    size: { type: String, default: 'default' },
    src: { type: String, default: '' },
    border: { type: Boolean, default: false },
    icon: { type: String, default: '' },
    clickable: { type: Boolean, default: false },
  },
  setup(props: any) {
    const calculatedSize = computed(() => {
      const sizes = {
        'x-small': '24',
        small: '48',
        default: '56',
        medium: '82',
        large: '128',
        'x-large': '256',
      };
      return sizes[props.size || 'default'];
    });
    const calculatedBorder = computed(() => {
      const borderWidths = {
        none: '0px',
        'x-small': '1px',
        small: '1px',
        medium: '2px',
        large: '2px',
        'x-large': '4px',
      };
      return {
        borderWidth: borderWidths[props.border || 'none'],
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.1)',
      };
    });

    return {
      calculatedSize,
      calculatedBorder,
    };
  },
});
