import { DataDocument, PresetDefinition, rootDataId } from '@fillip/api';

const WordCloudInstance: DataDocument = {
  id: 'WordCloudInstance',
  info: {
    title: 'Wordcloud',
    icon: 'cloud',
    toolEditing: {
      preferredArrangement: 'side-by-side',
      allowedChildTypes: ['text'],
      editors: {
        title: {
          id: 'WordCloudInstance',
        },
        properties: {
          id: 'WordCloudInstance',
          title: 'Einstellungen',
        },
      },
    },
  },
  tag: {
    tag: 'template',
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  list: {
    items: [
      {
        id: 'Responses',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'responses',
        query: 'Responses',
      },
      {
        identifier: 'wordCloudTitle',
        query: ':info.title',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'word-cloud',
        query: ':[data]',
      },
    ],
  },
  schema: {
    fields: [],
    ownFields: [
      {
        title: 'Max. Einsendungen',
        key: 'allowedResponses',
        type: 'number',
      },
    ],
  },
  properties: {
    allowedResponses: {
      type: 'number',
      value: 1,
    },
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

const ResponseCollections: DataDocument[] = [
  {
    id: 'Responses',
    info: {
      title: 'Antworten',
    },
    tag: {
      tag: 'collection',
    },
    schema: {
      fields: [
        {
          title: 'Antwort',
          key: 'templateId',
          type: 'id',
        },
      ],
      ownFields: [],
    },
    parentId: 'WordCloudInstance',
  },
];

export const PresetWordCloud: PresetDefinition = {
  id: 'PresetWordCloud',
  title: 'Wordcloud',
  icon: 'cloud',
  group: 'tools',
  version: '0.1',
  documents: [WordCloudInstance, ...ResponseCollections],
};
