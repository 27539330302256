
















import { BlockEditorComponent } from './types';
import { nanoid } from 'nanoid/non-secure';
import { useData, useDocuments, useTags } from '@/composables';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'BlockEditor',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any, { emit }) {
    const { getTagByTag } = useTags();
    const { getData } = useData();
    const { editInDialog: open } = useDocuments();
    const randomId = nanoid();

    const document = computed(() => {
      return getData(props.id);
    });

    const tagDefinition = computed(() => {
      return getTagByTag(document.value?.tag?.tag);
    });

    const editorComponent = computed(() => {
      return BlockEditorComponent(tagDefinition.value?.editor);
    });

    return {
      document,
      randomId,
      editorComponent,
      open,
    };
  },
});
