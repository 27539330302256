import {
  DataDocument,
  hasRole,
  DefaultLayouts,
  Action,
  objectPush,
  TDefaultLayout,
  determineLayoutForBreakpoint,
} from '@fillip/api';
import { RenderContext, renderTemplate } from './../scene/render';
import { VNode, f } from '@/features/main/engine';

import {
  FunctionTemplate,
  FunctionTemplateRenderMode,
  RenderResult,
} from './types';

export const workspace: FunctionTemplate = (
  context: RenderContext,
  mode: FunctionTemplateRenderMode,
  data?: DataDocument,
): RenderResult => {
  const variables = context.env.$vars;
  const props = context.env.$props;
  const computeds = context.env.$computeds;
  const vcTemplate = variables.$$vcTemplateId
    ? variables.$$vcTemplateId
    : data?.properties?.vcTemplate?.value || 'tool-videoconference';
  const activeToolId = context?.env?.$vars.$$isReadOnly
    ? context?.env?.$vars.$$activeToolVolatile
    : (data?.properties?.activeTool?.value as string);
  const activeTool = activeToolId
    ? context.vueInstance.getData(activeToolId)
    : '';

  const activeLayoutName = activeTool
    ? determineLayoutForBreakpoint(
        activeTool.info?.toolEditing?.preferredArrangement,
        context.vueInstance.$vuetify.breakpoint,
      ) || 'main-side'
    : 'single';

  const activeLayout =
    DefaultLayouts[activeLayoutName] || DefaultLayouts.single;

  const control: Action = {
    name: 'workspace',
    type: 'action.workspace',
    context: {
      documentId: data.id,
      ...context.env,
    },
    slot: 'left',
    roles: ['host'],
  };
  if (
    hasRole(context.env.$roles)('host')(context.env.$me) ||
    (data?.roles?.roles &&
      hasRole(data.roles.roles)('host')(context.env.$me)) ||
    context.env.$vars.$$isReadOnly
  ) {
    // TODO: Make space for component?
    objectPush(context.out.globalProps, 'actions.canvas', control);
  }

  const children: VNode[] = [];

  if (context.env.isWithinFocus) {
    children.push(
      renderTemplate(
        'ws-vc-' + context.env.$id,
        context,
        vcTemplate,
        context.vueInstance.getData(vcTemplate),
      ),
    );
  }

  if (activeTool) {
    children.push(
      renderTemplate(activeTool.id, context, activeTool.id, activeTool),
    );
  }
  const result = f(
    {
      id: 'workspace-function',
      sheet: {
        orientTowardsCamera: false,
      },
      arrangement: activeLayout.arrangement,
      roles: data?.roles,
      station: {},
    },
    children,
  );
  return { variables, props, computeds, template: result };
};
