import { Tag } from './tag.module';
import { ModuleTypeMeta, DataId } from '../../common';
import { TDefaultLayout } from './arrangement.module';

export interface ModuleInfo {
  title: string;
  icon?: string;
  content?: any;
  contentFrom?: any;
  createdBy?: DataId;
  createdAt?: Date;
  isProtected?: Boolean;
  toolEditing?: ToolEditingInfo;
  clonedFrom?: DataId;
  originalParentId?: DataId;
}

type TEditors = 'title' | 'properties' | 'list';

export interface ToolEditorSettings {
  id: DataId;
  title?: string;
}
export interface ToolEditingInfo {
  editors?: { [key in TEditors]?: ToolEditorSettings };
  allowedChildTypes?: Tag[];
  allowMultipleChildren?: boolean;
  preferredArrangement?: TDefaultLayout;
}

export const ModuleInfoMeta: ModuleTypeMeta<ModuleInfo> = {
  default: {
    title: '',
  },
  icon: 'info-circle',
};
