















import { useStore } from '@/composables';
import { computed, defineComponent } from '@vue/composition-api';
import Notification from './Notification.vue';

export default defineComponent({
  name: 'Notifications',
  components: {
    Notification,
  },
  setup() {
    const { store } = useStore();

    const notifications = computed(() => {
      return store.state.notifications.notifications;
    });

    const removeNotification = (notificationId: string) => {
      store.commit('notifications/removeNotification', {
        notificationId,
      });
    };
    return {
      notifications,
      removeNotification,
    };
  },
});
