import { computed } from '@vue/composition-api';
import { Shell } from '@fillip/api';
import { useFillip } from './useFillip';
import { useStore } from './useStore';

export function useShell() {
  const { store } = useStore();
  const { $user } = useFillip();

  const shell = computed<Shell>(() => {
    return store.getters.shell;
  });

  const isLoading = computed(() => {
    return store.state.isLoading;
  });
  const toggleLoading = (loading?: boolean) => {
    store.commit('SET_IS_LOADING', loading);
  };

  const content = computed(() => shell.value.content);
  const classes = computed(() => shell.value.classes);

  const setShell = (shell: string) => {
    store.dispatch('setShell', shell);
  };

  const setDefaultShell = () => {
    if ($user.value?.shell) {
      setShell($user.value.shell);
    } else {
      setShell(null);
    }
  };

  return {
    shell,
    content,
    classes,
    setShell,
    setDefaultShell,
    isLoading,
    toggleLoading,
  };
}
