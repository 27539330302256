import {
  DataDocument,
  DataId,
  Route,
  SceneDefinition,
  getFocus,
  ObjectId,
  inCircle,
  ModuleAvatar,
  where,
  isOnline,
} from '@fillip/api';
import { pipe } from 'lodash/fp';

import { ParticipantDocument } from '@/features/main/core';
import { useData, useLocations, useShell } from '@/composables';

import { computed } from '@vue/composition-api';

export function useParticipants() {
  const { getData, getAll, getDataByTag, getDataByIds } = useData();
  const { shell } = useShell();
  const {
    getLocation,
    atView,
    atPath,
    atDocument,
    atStation,
    atScene,
    hasSceneInPath,
    hasNoFocus,
  } = useLocations();

  const participants = computed(() => {
    return getAllParticipants();
  });

  const getAllParticipants = (
    includeOffline: boolean = false,
  ): DataDocument[] => {
    if (includeOffline) return getDataByTag('participant');
    return getOnlineParticipants();
  };

  const getParticipant = (id: DataId): ParticipantDocument => {
    const participant = getData(id);
    if (!participant || participant.tag?.tag != 'participant') return null;
    const route = getLocation(id);
    const locationId = route ? getFocus(route) : null;
    const location = null; //locationId ? getObject(locationId) : null;

    return {
      ...participant,
      tag: { tag: 'participant' },
      route,
      locationId,
      location,
    };
  };

  const getParticipantsByIds = (ids: DataId[]): DataDocument[] => {
    return getDataByIds(ids);
  };

  const getOnlineParticipants = (): DataDocument[] => {
    return (getDataByTag('participant') || []).filter(
      (participant) => participant.participantStatus.online,
    );
  };

  // Example use case: All 'spectators' focusing the same object
  const getParticipantsWithSameView = (
    includeOffline: boolean,
    route: Route,
  ): DataDocument[] => {
    return getAllParticipants(includeOffline).filter(atView(route));
  };

  const getParticipantsAtScene = (includeOffline: boolean, route: Route) => {
    return getAllParticipants(includeOffline).filter(atScene(route));
  };

  const getParticipantsLookingAtSameDocument = (
    includeOffline: boolean,
    scene: SceneDefinition,
  ): DataDocument[] => {
    if (!scene.explicit || !scene.dataId) return null;
    return getAllParticipants(includeOffline).filter(atDocument(scene));
  };

  const getParticipantsAtSamePath = (includeOffline: boolean, route: Route) => {
    const path = route.path.map((p) => p.scene);
    return getAllParticipants(includeOffline).filter(atPath(path));
  };

  const getParticipantsWithSameFocus = (
    includeOffline: boolean,
    objectId: ObjectId,
  ) => getAllParticipants(includeOffline).filter(atStation(objectId));

  const getParticipantsWithNoFocus = (route: Route) =>
    pipe(where(isOnline, atScene(route), hasNoFocus))(getAll());

  const getParticipantsInCircle = (includeOffline: boolean, circle: DataId) =>
    getAllParticipants(includeOffline).filter(inCircle(circle));

  const getParticipantsWithSceneInPath = (
    scene: SceneDefinition,
  ): DataDocument[] => {
    return pipe(where(isOnline, hasSceneInPath(scene)))(getAll());
  };

  const getAvatarUrl = (
    participant: DataDocument,
    style: 'robots' | 'peeps' = 'peeps',
    useDefault: boolean = false,
  ): string => {
    if (!participant?.id) return undefined;
    if (shell.value.defaultAvatarUrl) return shell.value.defaultAvatarUrl;
    if (!useDefault && participant.avatar && participant.avatar.url) {
      return participant.avatar.url;
    } else {
      return getDefaultAvatar(participant.id, style).url;
    }
  };

  const getDefaultAvatar = (
    id: DataId,
    style: 'robots' | 'peeps' = 'peeps',
  ): ModuleAvatar => {
    const hash = (Number(hashId(id).toString().slice(0, 3)) - 100).toString();
    const getBaseAvatarUrl = (hash, style) => {
      switch (style) {
        case 'peeps':
          return `${process.env.MINIO_URL}/media/peeps/peep-${hash}.svg`;
        case 'robots':
        default:
          return `https://robohash.org/${hash}.png`;
      }
    };
    return { id: 0, url: getBaseAvatarUrl(hash, style) };
  };

  const hashId = (id: string) => {
    let hash = 0,
      i,
      chr;
    if (id.length === 0) return hash;
    for (i = 0; i < id.length; i++) {
      chr = id.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  return {
    participants,
    getParticipant,
    getAllParticipants,
    getParticipantsByIds,
    getOnlineParticipants,
    getParticipantsAtScene,
    getParticipantsWithSceneInPath,
    getParticipantsWithSameFocus,
    getParticipantsWithSameView,
    getParticipantsWithNoFocus,
    getParticipantsLookingAtSameDocument,
    getParticipantsAtSamePath,
    getLocation,
    getParticipantsInCircle,
    getAvatarUrl,
    getDefaultAvatar,
  };
}
