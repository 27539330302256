import Vue from 'vue';

// Helper with a single keyed child to make sure, that Vue won't interfere with us stealing this.$el from the parent
const Wrapper = Vue.extend({
  render(h) {
    return h('div', this.$slots.default);
  },
});

interface FillipEvent {
  name: string;
  event: Record<string, any>;
}

export default Vue.extend({
  name: 'ComponentFactory',
  data() {
    return {
      // TODO: optimize into array with empty spots record and delay clearing into nextTick
      components: {},
    };
  },
  methods: {
    create(
      key: string,
      component: any,
      props: Record<string, any>,
      on: (name: string, event: any) => void,
    ): Promise<any> {
      return new Promise((resolve) => {
        const entry = {
          key,
          component,
          props,
          resolve,
          on,
        };
        Vue.set(this.components, key, entry);
      });
    },
    remove(key: string) {
      Vue.delete(this.components, key);
    },
    componentMounted(key: string) {
      const entry = this.components[key];
      if (!entry) {
        console.warn('Missing entry for key', key);
      }
      entry.resolve(this.$refs[key]);
    },
    handleEvent(key: string, payload: FillipEvent) {
      const entry = this.components[key];
      if (!entry) {
        console.warn('Missing entry for key', key);
      }
      entry.on(payload.name, payload.event);
    },
  },
  render(h) {
    const components = Object.values(this.components).map(
      ({ key, component, props }) => {
        const vnode = h(component, {
          props,
          key,
          ref: key,
          on: {
            'hook:mounted': () => this.componentMounted(key),
            event: (payload) => this.handleEvent(key, payload),
          },
        });
        return h(Wrapper, { key }, [vnode]);
      },
    );

    return h('div', {}, components);
  },
});
