





















import UsernameInput from '@/components/form-inputs/UsernameInput.vue';
import SaveAndCancelButtons from '@/components/form-inputs/SaveAndCancelButtons.vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { useFillip, useI18n, useMessage } from '@/composables';

export default defineComponent({
  name: 'UsernameForm',
  components: {
    UsernameInput,
    SaveAndCancelButtons,
  },
  props: {
    inFocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const message = useMessage();
    const { fillip, $user } = useFillip();
    const { tr } = useI18n();

    const isValid = ref(false);
    const isEditing = ref(false);
    const formData = ref({
      username: '',
    });
    const form = ref(null);

    const resetValidation = () => {
      return form.value.resetValidation();
    };

    const editing = () => {
      isEditing.value = true;
      emit('focused');
    };

    const cancel = () => {
      formData.value.username = $user.value.username;

      isEditing.value = false;
      resetValidation();
    };

    const submit = async () => {
      const user = formData.value;

      if (user.username != $user.value.username) {
        await fillip.auth.updateUserName(user.username);
        message.success(tr('user.usernameChanged'));
      }
      isEditing.value = false;
    };

    watch(
      $user,
      () => {
        formData.value.username = $user.value.username;
      },
      { immediate: true },
    );

    watch(
      () => props.inFocus,
      (newValue) => {
        if (!newValue && isEditing.value) {
          cancel();
        }
      },
    );

    return {
      isValid,
      isEditing,
      formData,
      editing,
      cancel,
      submit,
      resetValidation,
    };
  },
});
