<template>
  <v-tooltip top open-delay="300" :disabled="!icon || !title">
    <template #activator="{ on }">
      <v-btn
        ref="vBtn"
        :block="block"
        :text="text ? true : false"
        :size="size"
        :icon="size == 'icon' || iconOnly"
        :x-small="xSmall"
        :small="size == 'small' || small"
        :large="size == 'large'"
        :x-large="size == 'x-large' || size == 'xl'"
        :rounded="rounded"
        :elevation="calculatedElevation"
        :plain="plain"
        :class="[margin, classProp]"
        :color="!invisible ? selectedColor : 'transparent'"
        :style="styleProp"
        :disabled="disabled"
        :type="type"
        :loading="loading"
        :fab="fab"
        :max-width="width"
        :max-height="height"
        exact
        :outlined="outlined"
        :value="value"
        :input-value="active"
        :dark="dark"
        @click="$emit('click', $event)"
        @mouseenter="$emit('mouseenter', $event)"
        @mouseleave="$emit('mouseleave', $event)"
        @input="input"
        v-on="on"
      >
        <BaseIcon
          v-if="icon && iconPosition == 'before'"
          :icon="icon"
          :size="iconSize"
          :color="color"
          :variant="iconVariant"
          :class="{ 'mr-2': size != 'icon' && !iconOnly }"
        />
        <slot> </slot>
        <BaseIcon
          v-if="icon && iconPosition == 'after'"
          :icon="icon"
          :size="iconSize"
          :color="color"
          :variant="iconVariant"
          :class="{ 'ml-2': size != 'icon' && !iconOnly }"
        />
      </v-btn>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>

<script>
import * as baseFunction from './main.js';
export default {
  name: 'BaseButton',
  props: {
    fab: Boolean,
    text: Boolean,
    type: String,
    size: String,
    rounded: Boolean,
    color: String,
    shadow: Boolean,
    elevation: String,
    invisible: Boolean,
    plain: Boolean,
    outlined: Boolean,
    flat: Boolean,
    icon: [Object, Boolean, String],
    margin: String,
    disabled: Boolean,
    width: String,
    height: String,
    value: String,
    block: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
    title: String,
    active: {
      type: Boolean,
    },
    classProp: String,
    styleProp: String,
    xSmall: Boolean,
    small: Boolean,
    iconOnly: Boolean,
    iconVariant: {
      type: String,
      default: 'light',
    },
    iconPosition: {
      type: String,
      default: 'after',
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconSize() {
      if (this.size == 'x-large' || this.size == 'xl') return 'large';
      if (this.size == 'large') return 'large';
      if (this.size == 'medium') return '';
      if (this.size == 'small') return 'small';
      if (this.size == 'x-small') return 'x-small';
      return '';
    },
    computedShadow() {
      return this.isinvisble ? false : this.shadow;
    },
    computedColors() {
      return baseFunction.colors(this.color);
    },
    selectedColor() {
      if (!this.color) {
        return '';
      }
      return this.color;
    },
    calculatedElevation() {
      if (this.invisible) return '0';
      if (this.shadow) return '2';
      return '0';
    },
  },
  methods: {
    input(event) {
      if (event != this.active) {
        this.$refs.vBtn.isActive = this.active;
      }
    },
  },
};
</script>
