import type {
  DataDocument,
  DataId,
  SchemaDefinition,
  SchemaField,
  SchemaReferenceKey,
} from '@fillip/api';
import { useI18n } from '../i18n/useI18n';
import { useData } from './useData';

export function useSchema() {
  const { maybeDoc } = useData();
  const { tr } = useI18n();

  const getInterfaceProps = (docOrId: DataId | DataDocument): SchemaField[] => {
    const result: SchemaField[] = [];
    if (!docOrId) return result;
    const doc = maybeDoc(docOrId);

    const interfaceDefinition = doc?.interfaceDefinition;
    if (!interfaceDefinition) return result;

    if (interfaceDefinition.inheritFrom) {
      const parentProps = getInterfaceProps(interfaceDefinition.inheritFrom);
      if (parentProps) {
        result.push(...parentProps);
      }
    }

    if (interfaceDefinition.props) {
      result.push(...interfaceDefinition.props);
    }

    return result;
  };

  const getSchemaProps = (
    docOrId: DataId | DataDocument,
    referenceKey: SchemaReferenceKey,
  ): SchemaField[] => {
    if (!docOrId) return [];
    const doc = maybeDoc(docOrId);
    if (!doc?.schema?.[referenceKey]) return [];
    return doc.schema?.[referenceKey] || [];
  };

  const getSchemaDefinition = (
    docOrId: DataId | DataDocument,
    referenceKey: SchemaReferenceKey,
  ): SchemaDefinition | undefined => {
    if (!docOrId) return;
    const doc = maybeDoc(docOrId);
    if (!doc) return;

    // Custom and props are not schema definitions
    if (['custom', 'props'].includes(referenceKey)) return;

    if (referenceKey === 'interface') {
      const fields = getInterfaceProps(doc);
      if (!fields?.length) return;
      return {
        title: doc.interfaceDefinition?.title || 'Interface',
        fromId: doc.id,
        key: referenceKey,
        fields: fields,
      };
    } else {
      const fields = getSchemaProps(doc, referenceKey);
      if (!fields?.length) return;
      return {
        title: tr('schema.referenceKeys.' + referenceKey),
        fromId: doc.id,
        key: referenceKey,
        fields: getSchemaProps(doc, referenceKey),
      };
    }
  };

  const getCircleSchema = (
    docOrId: DataId | DataDocument,
  ): SchemaDefinition => {
    const schema = getSchemaDefinition(docOrId, 'fields');
    if (schema) return schema;

    // TODO: Get default schema from module meta
    return {
      title: 'Main Circle',
      key: 'fields',
      fromId: 'default',
      fields: [
        {
          title: tr('profile.description'),
          key: 'description',
          type: 'string',
        },
        {
          title: tr('profile.personalColor'),
          key: 'personalColor',
          type: 'color',
          options: {
            colorMode: 'rgb',
          },
        },
      ],
    };
  };

  return {
    getInterfaceProps,
    getSchemaProps,
    getSchemaDefinition,
    getCircleSchema,
  };
}
