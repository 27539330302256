import type { RenderEnvironment } from './../../scene/render';
import type { Modules } from '@fillip/api';
import type { Entity } from '../core';

export interface VNodeProps extends Modules {
  id?: string;
  context?: RenderEnvironment;
}

export class VNode {
  public parent: VNode = null;
  public entity?: Entity = null;

  constructor(public props: VNodeProps, public children: VNode[]) {
    children.forEach((child, index) => {
      child.parent = this;
    });
  }

  push(child: VNode) {
    this.children.push(child);
    child.parent = this;
  }

  toString(): string {
    return this._toString();
  }
  private _toString(indentation: string = '') {
    const attributes = [];
    if (this.props.id) {
      attributes.push(`id="${this.props.id}"`);
    }
    if (this.props.element) {
      attributes.push(`element="${this.props.element.type}"`);
    }

    const attributesString = attributes.map((s) => ' ' + s).join('');

    if (this.children.length) {
      return `${indentation}<entity${attributesString}>\n${this.children
        .map((child) => child._toString(indentation + '   '))
        .join('')}${indentation}</entity>`;
    } else {
      return indentation + `<entity${attributesString}/>\n`;
    }
  }
}

export const f = (props: VNodeProps, children: VNode[] = []): VNode => {
  return new VNode(props, children);
};

export const p = (content: string): VNode => {
  return f({
    element: {
      type: 'element.text',
      textContent: content,
      textFormat: 'p',
    },
  });
};

export const img = (src: string, alt: string = ''): VNode => {
  return f({
    element: {
      type: 'element.image',
      imageSrc: src,
      imageDescription: alt,
      imageCopyright: '',
    },
  });
};

export const sheet = (children: VNode[]): VNode => {
  return f(
    {
      sheet: {
        orientTowardsCamera: false,
      },
    },
    children,
  );
};

export const camera = (): VNode => {
  return f({ id: 'camera' });
};

export const page = sheet([p('Hello World'), p('here is some text')]);

export const slides = f(
  {
    arrangement: {
      type: 'arrangement.slides',
    },
  },
  [
    sheet([p('Slide 1'), camera()]),
    sheet([p('Slide 2')]),
    sheet([p('Slide 3')]),
  ],
);
