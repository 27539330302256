import { BaseObject3D } from '../core';
import { AudioListener, AudioLoader, Audio } from 'three';
import { GlobalPropsNode } from '@/plugins/global-props';
import { useMediaControls, useVolatileData } from '@/composables';

export const ModelPositionalAudio = {
  name: 'ModelPositionalAudio',
  mixins: [BaseObject3D, GlobalPropsNode],
  inject: ['router'],
  data() {
    return {
      listener: null,
      AudioLoader: null,
      audio: null,
      progressInterval: null,
      currentTrackPosition: 0,
    };
  },
  mounted() {
    this.$bindGlobalProp(
      this.templateId + ':trackIsPlaying',
      () => this.isPlaying,
    );
    this.$bindGlobalProp(
      this.templateId + ':trackDuration',
      () => this.trackDurationInS,
    );
    this.$bindGlobalProp(
      this.templateId + ':currentTrackPosition',
      () => this.currentTrackPosition,
    );
  },
  beforeDestroy() {
    this.stopPlayback();
    this.clear();
  },
  setup(props: any) {
    const templateId = props.id.split(':')[0];
    const {
      shouldBePlaying,
      controlledByHost,
      startedAt,
      pausedAt,
      manualControlsEnabled,
      volume,
      loop,
    } = useMediaControls(
      { ...props, id: templateId },
      {
        mediaType: 'audio',
      },
    );
    const { getVolatileProp, setVolatileProp } = useVolatileData();

    return {
      volume,
      loop,
      shouldBePlaying,
      controlledByHost,
      startedAt,
      pausedAt,
      manualControlsEnabled,
      getVolatileProp,
      setVolatileProp,
    };
  },
  computed: {
    templateId() {
      return this.id.split(':')[0];
    },
    audioSrc(): string {
      return this.modules.model.audioSrc;
    },
    trackDurationInS(): number {
      if (this.audio && this.audio.buffer) {
        return this.audio.buffer.duration;
      } else {
        return 0;
      }
    },
    isPlaying(): Boolean {
      if (this.audio && this.shouldBePlaying && this.audio.isPlaying) {
        return true;
      }
      return false;
    },
    hasStationFocusWithin() {
      return this.id == this.router.value.focusedStation.id;
    },
    startOnFocus() {
      return (
        this.hasStationFocusWithin &&
        this.modules.model.positionalAudioSettings.startEvent == 'focus'
      );
    },
  },
  watch: {
    audioSrc: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.stopPlayback();
        } else {
          this.clear();
        }
        this.loadAudioFromUrl();
      },
    },
    shouldBePlaying: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && this.audio && newValue != oldValue) {
          this.syncPlayback();
        } else {
          this.stopPlayback();
        }
      },
    },
    startOnFocus: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (
          newValue &&
          newValue != oldValue &&
          !this.controlledByHost &&
          !this.audio?.isPlaying
        ) {
          if (this.audio) {
            this.syncPlayback();
          } else {
            console.warn('No audio provided');
          }
        } else {
          this.stopPlayback();
          this.clear();
        }
      },
    },
    loop: {
      immediate: true,
      handler(newValue) {
        if (this.audio) {
          this.audio.setLoop(newValue);
        }
      },
    },
    volume: {
      immediate: true,
      handler(newValue) {
        if (this.audio) {
          this.audio.setVolume(newValue);
        }
      },
    },
    //! to reset progress bar, when stopping in paused Mode
    startedAt(newValue, oldValue) {
      if (oldValue == newValue) return;
      else if (!newValue) {
        this.stopPlayback();
      }
    },
    skipPosition(newValue, oldValue) {
      if (newValue == oldValue) return;
      if (newValue && this.shouldBePlaying)
        //! Dirty fix: If we don't pass new Value, in syncPlayback the newStart value and this.modules.model.positionalAudioSettings.startedAt are out of sync.
        this.syncPlayback(newValue);
    },
  },
  methods: {
    async loadAudioFromUrl() {
      if (this.audioSrc) {
        this.audioLoader = new AudioLoader();
        try {
          const audioBuffer = await this.audioLoader.loadAsync(
            this.audioSrc,
            (xhr) => this.onLoadingProgress(xhr),
          );
          this.updateAudio(audioBuffer);
        } catch (error) {
          this.onLoadingError(error);
        }
      } else {
        if (this.audio) {
          this.audio.setBuffer(null);
        }
        console.warn('no audio url');
      }
    },
    async updateAudio(buffer) {
      await this.initAudio(buffer);
      if (
        this.shouldBePlaying ||
        (!this.controlledByHost && this.startOnFocus) ||
        (!this.controlledByHost &&
          this.modules.model.positionalAudioSettings.startEvent == 'load')
      ) {
        this.syncPlayback();
      } else {
        this.stopPlayback();
      }
    },
    async initAudio(buffer) {
      try {
        this.prepareThreeAudio(buffer);
      } catch (error) {
        console.error('Async initAudio error: ', error);
        const initAudio = () => {
          this.prepareThreeAudio(buffer);
          document.removeEventListener('click', initAudio);
        };
        document.addEventListener('click', initAudio);
      }
    },
    prepareThreeAudio(buffer) {
      this.listener = new AudioListener();
      this.$object3D.add(this.listener);
      this.audio = new Audio(this.listener);
      // TODO: add switch for positional Audio
      // this.audio = new PositionalAudio(this.listener);

      this.audio.setBuffer(buffer);
      this.audio.setLoop(this.loop);
      this.audio.setVolume(this.volume);
    },
    onLoadingProgress(xhr) {
      // console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
    },
    onLoadingError(error) {
      throw new Error(error);
    },
    async syncPlayback(newStartTime?) {
      if (this.audio?.isPlaying) {
        this.audio.stop();
        clearInterval(this.progressInterval);
      }
      const offsetInS = this.calcOffset(newStartTime);
      this.audio.offset = offsetInS || null;

      try {
        await this.audio.play();
      } catch (error) {
        console.log(error);
        const startPlay = () => {
          this.audio.play();
          document.removeEventListener('click', startPlay);
        };
        document.addEventListener('click', startPlay);
      }
      this.setCurrentTrackPosition();
    },
    stopPlayback() {
      if (this.audio) {
        if (this.audio.isPlaying) {
          this.audio.stop();
        }
        clearInterval(this.progressInterval);
        if (!this.pausedAt) {
          this.currentTrackPosition = 0;
        }
      }
    },
    clear() {
      clearInterval(this.progressInterval);
      this.currentTrackPosition = 0;
      if (this.$object3D) {
        // this.$object3D.clear();
        this.$object3D.remove(this.listener);
      }
    },
    setCurrentTrackPosition() {
      this.progressInterval = setInterval(() => {
        const position =
          this.audio.context.currentTime -
          this.audio._startedAt +
          this.audio.offset;

        if (position > this.trackDurationInS) {
          this.currentTrackPosition = position % this.trackDurationInS;
        } else {
          this.currentTrackPosition = position;
        }
      }, 100);
    },
  },
  render() {
    return null;
  },
};
