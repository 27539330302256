import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagParticipant: DataDocument = {
  id: 'tag-participant',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Participant',
    icon: 'user',
    isProtected: true,
  },
  element: {
    type: 'element.participant',
    participantId: ':$participantId',
    participantShape: ':$participantShape',
    alwaysShowName: ':$alwaysShowName',
    participantVideo: ':$participantVideo',
    participantAudio: ':$participantAudio',
  },
  class: {
    elementClass: ':`p-4 ${$containerClasses}`',
    class: ':` ${$elementClasses}`',
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'participant',
    title: 'Participant',
    category: 'other',
    icon: 'user',
    editor: 'default',
  },
  interfaceDefinition: {
    title: '',
    props: [
      {
        title: 'participantId',
        key: 'participantId',
        type: 'string',
        defaultValue:
          ":$participantId || properties?.participantId?.value || ''",
      },
      {
        title: 'Form',
        key: 'participantShape',
        type: 'select',
        options: {
          items: [
            { key: 'circle', title: 'element.participant.circle' },
            { key: 'square', title: 'element.participant.square' },
            { key: 'rectangle', title: 'element.participant.rectangle' },
            { key: 'cover', title: 'element.participant.cover' },
          ],
        },
        defaultValue:
          ":$participantShape || properties?.participantShape?.value || 'circle'",
      },
      {
        title: 'Always show name',
        key: 'alwaysShowName',
        type: 'boolean',
        defaultValue:
          ':$alwaysShowName ?? properties?.alwaysShowName?.value ?? false',
      },
      {
        title: 'Participant Video',
        key: 'participantVideo',
        type: 'boolean',
        defaultValue:
          ':$participantVideo ?? properties?.participantVideo?.value ?? true',
      },
      {
        title: 'Participant Audio',
        key: 'participantAudio',
        type: 'boolean',
        defaultValue:
          ':$participantAudio ?? properties?.participantAudio?.value ?? true',
      },
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":$containerClasses || properties?.containerClasses?.value || ''",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":$elementClasses || properties?.elementClasses?.value || ''",
      },
    ],
  },
  // TODO: Make sheet configurable
  sheet: {
    orientTowardsCamera: false,
  },
};
