import AuthLayout from '@/features/auth/AuthLayout.vue';
export default [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: {
          forwardTo: true,
        },
        component: () =>
          import(/* webpackChunkName: "auth" */ '@/features/auth/Login.vue'),
      },
      {
        path: 'request-confirmation',
        name: 'RequestConfirmation',
        component: () =>
          import(
            /* webpackChunkName: "auth" */ '@/features/auth/RequestConfirmation.vue'
          ),
      },
      {
        path: 'confirm-email',
        name: 'ConfirmationPending',
        component: () =>
          import(
            /* webpackChunkName: "auth" */ '@/features/auth/ConfirmationPending.vue'
          ),
      },
      {
        path: 'request-password-reset',
        name: 'ForgotPassword',
        component: () =>
          import(
            /* webpackChunkName: "auth" */ '@/features/auth/RequestPasswordReset.vue'
          ),
      },
      {
        path: 'new-password',
        name: 'NewPassword',
        component: () =>
          import(
            /* webpackChunkName: "auth" */ '@/features/auth/NewPassword.vue'
          ),
      },
      {
        path: 'login/success',
        name: 'SuccessLogin',
        component: () =>
          import(
            /* webpackChunkName: "auth" */ '@/features/auth/ProviderLoginSuccess.vue'
          ),
      },
      {
        path: 'register',
        name: 'Register',
        meta: {
          forwardTo: true,
        },
        component: () =>
          import(/* webpackChunkName: "auth" */ '@/features/auth/Signup.vue'),
      },
      {
        path: 'logout',
        name: 'Logout',
        meta: {},
        component: () =>
          import(/* webpackChunkName: "auth" */ '@/features/auth/Logout.vue'),
      },
    ],
  },
];
