
















































import draggable from 'vuedraggable';
import EditorSection from '../components/EditorSection.vue';

import { computed } from '@vue/composition-api';

export default {
  name: 'DraggableArrayInput',
  components: {
    draggable,
    EditorSection,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    // TODO: Implement isBlocked
    isBlocked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    defaultItem: {
      type: Object,
      default: () => ({}),
    },
    variant: {
      type: String, // 'cards' | 'rows'
      default: 'cards',
    },
  },
  setup(props: any, { emit }) {
    const slotClasses = '';
    const itemClasses = computed(() => {
      const base = 'my-2 hover:cursor-move';
      if (props.variant === 'cards')
        return base + ' p-2 rounded-md border-1 border-solid border-gray-300 ';
      if (props.variant === 'rows')
        return base + ' border-0 border-b-1 border-solid border-gray-300 ';
      return base;
    });
    const itemHeaderClasses = 'grid grid-cols-1fr-auto gap-2 items-baseline';
    const itemToolsClasses = 'flex gap-1';
    const itemBodyClasses = '';
    const itemFooterClasses = '';
    const dragOptions = {
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
      handle: '.handle',
    };

    const currentValue = computed(() => props.value);

    const addItem = () => emit('add');

    const removeItem = (index: number) => emit('remove', index);

    const onDragStart = () => emit('focus');
    const onDragEnd = () => emit('blur');

    const update = ($event) => {
      emit('input', $event);
      emit('change', $event);
    };

    return {
      slotClasses,
      itemClasses,
      itemHeaderClasses,
      itemToolsClasses,
      itemBodyClasses,
      itemFooterClasses,
      dragOptions,
      currentValue,
      addItem,
      removeItem,
      onDragStart,
      onDragEnd,
      update,
    };
  },
};
