import type { IBaseControls } from './index';
import { Base3dControls } from './Base3dControls';
import type { CameraPanAndZoom } from '@fillip/api';
import CameraControls from 'camera-controls';
import * as Location3D from '@/utils/location3D';
import type { Box3 } from 'three';

export class PanZoomControls extends Base3dControls implements IBaseControls {
  public isInitialized = false;

  initialize(options?: Record<string, any>): void {
    super.initialize();
    this.controls.setPosition(0, 0, 1000);
    this.controls.mouseButtons.left = CameraControls.ACTION.TRUCK;
    this.controls.touches.one = CameraControls.ACTION.TOUCH_TRUCK;

    this.controls.dollyToCursor = true;
    this.controls.infinityDolly = false;
    this.controls.minPolarAngle = Math.PI / 2;
    this.controls.maxPolarAngle = Math.PI / 2;
    this.controls.minAzimuthAngle = 0;
    this.controls.maxAzimuthAngle = 0;

    // this.renderInLoop();
    // this.controls.addEventListener('control', () => this.renderInLoop());
  }
  updateCamera(
    sceneCamera: CameraPanAndZoom,
    sceneBoundingBox: Box3,
    hasParentChanged: boolean,
  ): void {
    super.updateCamera(sceneCamera, sceneBoundingBox, hasParentChanged);
    this.controls.minDistance = sceneCamera.zoomMinDistance || 300;
    this.controls.maxDistance =
      sceneCamera.zoomMaxDistance > 0 ? sceneCamera.zoomMaxDistance : 5000;
  }
  checkCameraPosition() {
    if (!this.isInitialized && Location3D.hasBox3Size(this.sceneBoundingBox)) {
      this.zoomToFit(this.sceneBoundingBox);
      this.isInitialized = true;
    }
  }
}
