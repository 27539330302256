<template>
  <v-sheet
    :style="{ display: minimized ? 'none' : '' }"
    rounded
    class="m-2 blurred-glass overflow-y-auto overflow-x-hidden"
    style="width: 400px; max-width: 80vw; max-height: 75vh"
    outlined
  >
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <h4 class="text-gray-700 leading-normal text-lg">{{ title }}</h4>
        </v-list-item-content>
      </v-list-item>
      <v-list-item-group
        v-model="value"
        :multiple="multiple"
        :max="calculatedMaxOptions"
        color="primary"
      >
        <v-list-item
          v-for="(option, index) in items"
          :key="index"
          :color="`${option.color}`"
          ripple
          link
          @click="submitSingle"
        >
          <v-list-item-icon v-if="option.icon">
            <BaseIcon :icon="option.icon" :color="option.color"></BaseIcon>
          </v-list-item-icon>
          <v-list-item-content>{{ option.info.title }}</v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-if="multiple">
      <v-divider></v-divider>
      <div class="flex justify-center p-2">
        <BaseButton rounded outlined block @click="submit">{{
          $t('general.submit')
        }}</BaseButton>
      </div>
    </template>
  </v-sheet>
</template>

<script>
import Vue from 'vue';
import { useData } from '@/composables';
import { useVolatileData } from '@/composables';
export default Vue.extend({
  name: 'ActionTextfield',
  props: {
    name: String,
    label: {
      type: String,
      default: function () {
        return this.$t('action.defaultActionText');
      },
    },
    id: {
      type: String,
      required: true,
    },
    canvasSlot: {
      type: String,
      default: 'bottom',
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    size: {
      type: String,
    },
    items: {
      type: Array,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxOptions: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
    },
    vPropId: {
      type: String,
      required: true,
    },
    vMinimized: {
      type: String,
    },
  },
  setup() {
    const { getVolatileProp } = useVolatileData();

    const { updateProperties, pushData } = useData();
    return { updateProperties, pushData, getVolatileProp };
  },

  data() {
    return {
      value: null,
    };
  },

  computed: {
    selectedItems() {
      return this.multiple
        ? this.items.filter((_, index) => this.value.includes(index))
        : this.items[this.value];
    },
    calculatedMaxOptions() {
      return this.multiple ? this.maxOptions : 1;
    },
    minimized() {
      return this.getVolatileProp(this.vPropId, this.vMinimized, false);
    },
  },
  watch: {
    multiple: {
      immediate: true,
      handler() {
        this.resetValue();
      },
    },
  },
  methods: {
    async submitSingle() {
      await this.$nextTick();
      if (this.multiple) return;
      this.submit();
    },
    submit() {
      if (this.value === null) return;
      this.$emit('submit', { input: this.selectedItems });
      this.resetValue();
    },
    resetValue() {
      this.value = this.multiple ? [] : null;
    },
  },
});
</script>
