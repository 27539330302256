





































































import UsernameInput from '@/components/form-inputs/UsernameInput.vue';
import PasswordInput from '@/components/form-inputs/PasswordInput.vue';
import EmailInput from '@/components/form-inputs/EmailInput.vue';
import SaveAndCancelButtons from '@/components/form-inputs/SaveAndCancelButtons.vue';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { useFillip, useI18n, useMessage, useRouter } from '@/composables';
import { Role } from '@fillip/api';

export default defineComponent({
  name: 'UpgradeForm',
  components: {
    UsernameInput,
    PasswordInput,
    EmailInput,
    SaveAndCancelButtons,
  },
  setup() {
    const { fillip, $user } = useFillip();
    const { tr } = useI18n();
    const { router } = useRouter();
    const message = useMessage();

    const isValid = ref(false);
    const isEditing = ref(false);
    const disabled = ref(false);
    const formData = ref({
      username: '',
      email: '',
      newPassword: '',
    });
    const formRef = ref(null);

    const isPending = computed(() => $user.value?.isVerified === 'pending');
    const upgradeTitle = computed(() =>
      isPending.value ? tr('logout.upgradePending') : tr('logout.upgrade'),
    );

    const resendEmailVerification = async () => {
      // TODO: Add error handling
      // TODO: set up locales for this notification
      await fillip.auth.resendEmailVerification($user.value?.email);
      message.success(tr('confirmation.emailSent'));
    };

    const abortUpgradeProcess = async () => {
      await fillip.auth.cancelRequest();
      message.info(tr('user.upgradeAborted'));
    };

    const cancelAccountEdit = () => {
      isEditing.value = false;
      formData.value = {
        username: $user.value?.username,
        email: $user.value?.email || '',
        newPassword: '',
      };
    };

    const upgradeAccount = async () => {
      if ($user.value?.isVerified === 'no-email') {
        if (!formRef.value.validate()) {
          return;
        }
        const user = {
          username: formData.value.username,
          password: formData.value.newPassword,
          email: formData.value.email,
          shell: $user.value?.shell,
          domain: window.location.hostname,
          role: Role.RESTRICTED,
        };
        // TODO: Add error and loading state handling
        await fillip.auth.upgradeToUserAccount(user);
        message.success(tr('user.upgradeSuccess'));
        router.push({ name: 'Logout' });
      }
      cancelAccountEdit();
    };

    watch(
      () => $user.value,
      () => {
        formData.value.username = $user.value?.username;
        formData.value.email = $user.value?.email || '';
      },
    );

    return {
      isValid,
      isEditing,
      disabled,
      formData,
      formRef,
      isPending,
      upgradeTitle,
      resendEmailVerification,
      abortUpgradeProcess,
      cancelAccountEdit,
      upgradeAccount,
    };
  },
});
