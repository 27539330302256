































































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import DOMPurify from 'dompurify';
// TODO: Implement preview mode for BlockEditor
// import Reveal from 'reveal.js';
// import RevealMarkdown from 'reveal.js/plugin/markdown/markdown.esm';
import InputCode from '@/components/input/code/InputCode.vue';

export default Vue.extend({
  name: 'RevealEditor',
  components: {
    InputCode,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'element',
    });

    return {
      buffer,
      save,
    };
  },
  computed: {
    controlsLayout() {
      return [
        {
          value: 'bottom-right',
          text: this.$t('element.reveal.layout.bottomRight'),
        },
        { value: 'edges', text: this.$t('element.reveal.layout.edges') },
      ];
    },
    controlledBy() {
      return this.buffer.revealControlledBy;
    },
  },
  watch: {
    controlledBy(newValue) {
      if (newValue == 'host') {
        this.buffer.options.controls = ":hasRole($roles)('host')($me)";
      } else {
        this.buffer.options.controls = true;
      }
      this.save();
    },
  },
  methods: {
    sanitize() {
      this.buffer.revealContent = DOMPurify.sanitize(this.buffer.revealContent);
      this.save();
    },
  },
});
