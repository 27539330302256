import { Fillip } from '@fillip/vue-client';
import { Role, Status, Shell } from '@fillip/api';
import i18n from '@/plugins/i18n';
import store from '@/store';
import { union } from 'lodash/fp';

export async function checkAndUpdateAccessToken(to, from, next) {
  if (!Fillip.instance.state.access_token)
    return next({ name: 'Login', query: { target: to.fullPath } });

  try {
    await Fillip.instance.auth.loginWithJwt(Fillip.instance.state.access_token);
  } catch (error) {
    next({ name: 'Logout' });
    store.dispatch('notify', {
      type: 'error',
      text: i18n.t('auth.error.jwtExpired'),
    });
  }
}

function getAccessibleCommunities(user: any, shell: Shell): string[] {
  const joinedCommunities: string[] =
    user.profiles
      .filter((profile) => {
        return Boolean(
          profile.community && profile.community.status == Status.ACTIVE,
        );
      })
      .map((profile) => profile.community.slug) || [];

  // Get accessible communities
  const shellCommunities = shell.restrictCommunities || [];

  return union(joinedCommunities, shellCommunities);
}

export async function checkCommunityAccess(
  user: any,
  shell: Shell,
  targetCommunity: string,
  forwardToTarget: boolean,
  next: (any) => any,
) {
  // Check access rights in user has a restricted account
  if (user.role === Role.RESTRICTED) {
    // Logout user if current shell doesn't match user shell
    // if (shell.name != user.shell) {
    //   store.dispatch('notify', {
    //     type: 'error',
    //     text: i18n.t('auth.error.userDoesntBelongToShell'),
    //   });
    //   return next({ name: 'Logout' });
    // }

    const accessibleCommunities = getAccessibleCommunities(user, shell);

    // Log out if no community is accessible
    if (!accessibleCommunities) {
      store.dispatch('notify', {
        type: 'error',
        text: i18n.t('auth.error.noActiveCommunities'),
      });
      return next({ name: 'Logout' });
    }

    // Forward to community if only one is accessible
    if (
      accessibleCommunities.length === 1 &&
      accessibleCommunities[0] !== targetCommunity
    ) {
      return next({
        name: 'Community',
        params: { slug: accessibleCommunities[0] },
      });
    }
    // TODO: Check if community is public and active (only in community path?)

    // Forward to target community if option is set and community is accessible
    if (
      forwardToTarget &&
      targetCommunity &&
      accessibleCommunities.includes(targetCommunity)
    ) {
      return next({
        name: 'Community',
        params: { slug: targetCommunity },
      });
    }
  }
}
