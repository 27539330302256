import { FillipError } from '@fillip/api';
import { FillipVueClient } from './client';

export class IFillipVueClientModule {
  constructor(moduleName: string, public root: FillipVueClient) {
    root.registerModule(moduleName, this);
  }

  public get state() {
    return this.root.state;
  }

  public get apiUrl() {
    return this.root.config.apiUrl;
  }

  public get config() {
    return this.root.config;
  }

  public get store() {
    return this.root.store;
  }

  public get axios() {
    return this.root.axios;
  }

  public restore() {}

  protected handleException(exception) {
    if (exception.response && exception.response.data.$$$fillipError) {
      const error = exception.response.data.error;
      throw new FillipError(error);
    } else {
      this.store.dispatch('displayError', exception);
      throw exception;
    }
  }
}
