import { getCurrentInstance } from '@vue/composition-api';

export function useI18n() {
  const vm = (getCurrentInstance() as any).proxy;
  const t = vm.$t.bind(vm);
  const tr = vm.$t.bind(vm);

  const maybeTr = (key: string) => {
    if (!key) {
      return '';
    }
    if (key.includes('.') && !key.includes(' ')) {
      return tr(key);
    }
    return key;
  };
  const setLocale = (locale: string) => {
    vm.$root.$i18n.locale = locale;
  };

  return {
    t,
    tr,
    maybeTr,
    setLocale,
  };
}
