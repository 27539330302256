import { DataDocument, toolsTemplateDirectoryId } from '@fillip/api';

import {
  TemplateToolCollection,
  TemplateToolCollectionChildren,
} from './TemplateToolCollection';
import {
  TemplateToolContent,
  TemplateToolContentChildren,
} from './TemplateToolContent';
import { TemplateToolEmbed } from './TemplateToolEmbed';
import { TemplateToolImage } from './TemplateToolImage';
import {
  TemplateToolWordCloud,
  TemplateToolWordCloudChildren,
} from './TemplateToolWordCloud';
import {
  TemplateToolVideo,
  TemplateToolVideoChildren,
} from './TemplateToolVideo';
import {
  TemplateToolFloatingChat,
  TemplateToolFloatingChatChildren,
} from './TemplateToolFloatingChat';
import {
  TemplateToolScreenshare,
  TemplateToolScreenshareChildren,
} from './TemplateToolScreenshare';
import {
  TemplateToolBreakoutRooms,
  TemplateToolBreakoutRoomsChildren,
} from './TemplateToolBreakoutRooms';
import {
  TemplateWorkspace,
  TemplateWorkspaceChildren,
} from './TemplateWorkspace';
import { TemplateToolPoll, TemplateToolPollChildren } from './TemplateToolPoll';

export const ToolTemplates: DataDocument[] = [
  TemplateWorkspace,
  TemplateToolVideo,
  TemplateToolScreenshare,
  TemplateToolCollection,
  TemplateToolContent,
  TemplateToolEmbed,
  TemplateToolImage,
  TemplateToolPoll,
  TemplateToolBreakoutRooms,
  TemplateToolWordCloud,
  // TemplateToolFloatingChat,
];
export const DefaultToolTemplates: DataDocument[] = [
  TemplateWorkspace,
  ...TemplateWorkspaceChildren,
  TemplateToolWordCloud,
  ...TemplateToolWordCloudChildren,
  TemplateToolVideo,
  ...TemplateToolVideoChildren,
  TemplateToolScreenshare,
  ...TemplateToolScreenshareChildren,
  TemplateToolCollection,
  ...TemplateToolCollectionChildren,
  TemplateToolContent,
  ...TemplateToolContentChildren,
  TemplateToolEmbed,
  TemplateToolImage,
  // TemplateToolFloatingChat,
  // ...TemplateToolFloatingChatChildren,
  TemplateToolPoll,
  ...TemplateToolPollChildren,
  TemplateToolBreakoutRooms,
  ...TemplateToolBreakoutRoomsChildren,
];

export const ToolTemplateDirectory: DataDocument = {
  id: toolsTemplateDirectoryId,
  info: {
    title: 'Tools',
    icon: 'tools',
    isProtected: true,
  },
  tag: {
    tag: 'collection',
  },
  list: {
    items: ToolTemplates.map((template) => ({
      id: template.id,
    })),
  },
};
