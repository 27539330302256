
























import Vue from 'vue';
import { useSave } from '@/composables';
import InputMedia from '@/components/input/media/InputMedia.vue';
import { BaseModuleEditor } from '../base';

export default Vue.extend({
  name: 'SvgEditor',
  components: {
    InputMedia,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, data, save } = useSave(props, {
      path: 'element',
    });
    return {
      buffer,
      data,
      save,
    };
  },
  computed: {
    library() {
      return [];
    },
  },
  methods: {
    changeMedia(newBuffer) {
      this.buffer = newBuffer;
      this.save();
    },
  },
});
