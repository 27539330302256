































































import { useStore } from '@/composables';
import {
  defineComponent,
  reactive,
  onMounted,
  onBeforeUnmount,
  set,
  ref,
} from '@vue/composition-api';

export default defineComponent({
  name: 'ProducerStats',
  setup() {
    const { store } = useStore();

    const stats = reactive({
      cameraProducer: null,
      audioProducer: null,
      screenShareProducer: null,
    });
    const interval = ref(null);
    const open = reactive({});

    const getProducerStats = async (name: string) => {
      const producer =
        store.getters.communityStore?.mediasoup?.producers?.[name];

      const producerType = {
        camera: 'cameraProducer',
        microphone: 'audioProducer',
        display: 'screenShareProducer',
      }[name];

      if (!producer) {
        set(stats, producerType, null);
        return;
      }

      const statsMap = await producer.instance.getStats();
      const newStats = {
        rtpParameters: producer.instance.rtpParameters,
        stats: Array.from(statsMap.values()).filter(
          (stat: any) => stat.type != 'certificate',
        ),
      };

      set(stats, producerType, newStats);
    };

    const getAllStats = async () => {
      getProducerStats('camera');
      getProducerStats('microphone');
      getProducerStats('display');
    };

    onMounted(() => {
      getAllStats();
      interval.value = setInterval(getAllStats, 1500);
    });

    onBeforeUnmount(() => {
      clearInterval(interval.value);
    });

    return {
      stats,
      open,
    };
  },
});
