












import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
  },
});
