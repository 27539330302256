

























































































































import Vue from 'vue';
import { ref } from '@vue/composition-api';
import { useData, useParticipants, useVolatileData } from '@/composables';
import { sampleSize } from 'lodash';
import { DataDocument, sleep } from '@fillip/api';

import ParticipantSelector from '../../participant/ParticipantSelector.vue';

export default Vue.extend({
  name: 'ActionBreakoutRooms',
  components: {
    ParticipantSelector,
  },
  inject: ['router'],
  props: {
    name: String,
    label: {
      type: String,
      default: function () {
        return this.$t('action.defaultActionText');
      },
    },
    id: {
      type: String,
      required: true,
    },
    canvasSlot: {
      type: String,
      default: 'bottom',
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    size: {
      type: String,
    },
    target: {
      type: String,
    },
    template: {
      type: String,
    },
    route: {
      type: Object,
      required: true,
    },
    vPropId: { type: String, required: true },
    vMinimized: {
      type: String,
    },
  },
  setup() {
    const { getData } = useData();
    const { getVolatileProp } = useVolatileData();

    const numberOfParticipants = ref(1);

    const {
      getParticipantsAtScene,
      getParticipantsWithNoFocus,
      getParticipantsWithSameFocus,
    } = useParticipants();

    return {
      numberOfParticipants,
      getData,
      getParticipantsWithNoFocus,
      getParticipantsWithSameFocus,
      getParticipantsAtScene,
      getVolatileProp,
    };
  },
  computed: {
    breakoutRooms() {
      const breakoutRooms = [];
      this.getData(this.target as string).list.items.forEach((item) => {
        return breakoutRooms.push(this.getData(item.id));
      });
      return breakoutRooms;
    },
    participantsInLobbyOrBreakoutRooms(): DataDocument[] {
      return this.getParticipantsAtScene(false, this.route);
    },
    minimized() {
      return this.getVolatileProp(this.vPropId, this.vMinimized, true);
    },
    participantsInLobby(): DataDocument[] {
      return this.getParticipantsWithNoFocus(this.route);
    },
    participantsInBreakoutRooms(): DataDocument[] {
      return this.participantsInLobbyOrBreakoutRooms.filter(
        (p) => !this.participantsInLobby.includes(p),
      );
    },
    targetDocument() {
      return this.getData(this.target);
    },
  },
  methods: {
    updateBreakoutRoomTitle(index: number, newTitle: string): void {
      const dataId = this.getData(this.target).list.items[index].id;
      this.$emit('submit', {
        script: 'breakoutRooms.updateBreakoutRoomTitle(dataId, newTitle)',
        dataId,
        newTitle,
      });
    },
    addBreakoutRoom(index?: number): void {
      if (index) {
        this.$emit('submit', {
          script: 'breakoutRooms.addBreakoutRoom(targetId, index)',
          targetId: this.target,
          index: index,
        });
      } else {
        this.$emit('submit', {
          script: 'breakoutRooms.addBreakoutRoom(targetId)',
          targetId: this.target,
        });
      }
    },
    removeBreakoutRoom(breakoutRoom: DataDocument): void {
      const pib = this.getParticipantsWithSameFocus(false, breakoutRoom.id);
      for (const p of pib) {
        this.removeParticipantFromBreakoutRoom(p.id);
      }
      this.$emit('submit', {
        script: 'breakoutRooms.removeBreakoutRoom(dataId)',
        dataId: breakoutRoom.id,
      });
    },
    moveToRoom(participantId, breakoutRoom) {
      this.$emit('submit', {
        script: 'router.focus(segment, participantId)',
        segment: breakoutRoom.id,
        participantId,
      });
    },
    moveParticipantsToBreakoutRoom(participants, breakoutRoom) {
      for (const participant of participants) {
        this.moveToRoom(participant.id, breakoutRoom);
      }
    },
    removeParticipantFromBreakoutRoom(participantId) {
      this.$emit('submit', {
        script: 'router.removeFocus(participantId)',
        participantId,
      });
    },
    async distribute() {
      const amountBreakoutRooms =
        this.participantsInLobbyOrBreakoutRooms.length /
        this.numberOfParticipants;
      for (let i = 0; i < amountBreakoutRooms; i++) {
        this.addBreakoutRoom(i + 1);
        await this.$nextTick();
        await sleep(500);
      }
      const breakoutRooms = [];
      this.getData(this.target).list.items.forEach((item) => {
        return breakoutRooms.push(this.getData(item.id));
      });
      const splitToChunks = (array, chunks) => {
        const result = [];
        for (let i = chunks; i > 0; i--) {
          result.push(array.splice(0, Math.ceil(array.length / i)));
        }
        return result;
      };
      const participantsForRooms = splitToChunks(
        this.participantsInLobby,
        this.breakoutRooms.length,
      );
      for (const index in participantsForRooms) {
        this.moveParticipantsToBreakoutRoom(
          participantsForRooms[index],
          breakoutRooms[index],
        );
      }
    },
    pullAllBack() {
      for (const participant of this.participantsInBreakoutRooms) {
        this.removeParticipantFromBreakoutRoom(participant.id);
      }
    },
    async pickRandomParticipants() {
      this.pullAllBack();
      for (const breakoutRoom of this.breakoutRooms) {
        if (!(this.participantsInLobby.length > 0)) return;
        // If we wouldn't want to shuffle but just keep the distribution to the rooms:
        // if (
        //   this.getParticipantsWithSameFocus(false, breakoutRoom.id).length >=
        //   this.numberOfParticipants
        // )
        //   return;
        const sample = sampleSize(
          this.participantsInLobby,
          this.numberOfParticipants,
        );
        this.moveParticipantsToBreakoutRoom(sample, breakoutRoom);
        await this.$nextTick();
        await sleep(500);
      }
    },
  },
});
