export default {
  editor: {
    clear: 'Clear',
    min: 'Min',
    max: 'Max',
    condition: 'Add Condition',
    conditional: 'Conditional:',
    save: 'Save',
    if: 'if',
    elseIf: 'else if ',
    else: 'else',
    dark: 'Dark',
  },
  actions: {
    title: 'Actions',
  },
  action: {
    actionName: 'Action name',
    id: 'Id',
    add: 'Add',
    script: 'Script',
    document: 'Global document',
    localDoc: 'Local document',
    minimizeIcon: 'Custom icon',
    defaultActionText: 'What do you think?',
    placeholder: 'Placeholder',
    restricions: 'Restrictions',
    showIfLocation: 'Show if location',
    showForRoles: 'Show for Roles',
    condition: 'Condition',
    multiple: 'Allow multi-select',
    maxOptions: 'Limit options to',
    title: 'Question Title',
    items: 'Response Options',
    targetId: 'Target',
    template: 'Template',
    multiSelect: {
      title: 'Single-/Multi-Select',
      targetId: 'Target Id',
      template: 'Template',
    },
    allowLinebreaks: 'Allow linebreaks',
    allowFormatting: 'Allow formatting',
    breakoutRooms: {
      title: 'Breakout Rooms',
      breakoutRoom: 'Breakout Room: ',
      addBreakoutRoom: 'Add Breakout Room',
      pickRandomly: 'Pick participants for rooms randomly',
      pick: 'Pick',
      reset: 'Reset',
      noData: 'You have selected all possible participants',
      selectParticipants: 'Select Participants',
      distribute: 'Create rooms of:',
      pullAllBack: 'All back to lobby',
      participants: 'participants',
      lobby: 'Currently in lobby:',
      nobodyLobby: 'Nobody in lobby',
      remove: 'Remove Breakout Room',
      room: 'Room',
      distributeParticipants:
        'Create breakout rooms and distribute participants',
    },
    button: {
      title: 'Button',
    },
    textfield: {
      title: 'Textfield',
    },
    stepper: {
      title: 'Stepper',
    },
    roleManager: {
      title: 'Role Manager',
      selectParticipants: 'Select participants',
      selectRole: 'Select role',
    },
    propertiesManager: {
      title: 'Properties Manager',
    },
    listManager: {
      title: 'List Manager',
    },
    documentManager: {
      defaultTitle: 'Document Manager',
      minimize: 'Minimize',
    },
    mediaControls: {
      title: 'Media Controls',
    },
  },
  arrangement: {
    title: 'Arrangement',
    type: 'Arrangement',
    absolute: { title: 'Absolute' },
    slides: { title: 'Slides' },
    grid: {
      title: 'Grid',
      gridColumns: 'Columns',
      gridRows: 'Rows',
    },
    block: { title: 'Block', keepSize: 'Keep size', gutter: 'Gutter' },
    flex: { title: 'Flex', stretchHeight: 'Stretch height', gutter: 'Gutter' },
    origin: { title: 'Origin' },
    divide: {
      title: 'Divide',
      aspectRatio: 'Aspect Ratio (1 / 1)',
      stretch: 'Stretch',
    },
    circle: {
      title: 'Circle',
      '3d': '3D',
      '2d': '2D',
      facingInwards: 'Facing Inwards',
    },
    rectangle: {
      title: 'Rectangle',
      '3d': '3D',
      '2d': '2D',
      keepSize: 'Keep size',
    },
    sphere: { title: 'Sphere' },
  },
  class: {
    title: 'Class',
    container: 'Container Classes: ',
    element: 'Element Classes: ',
  },
  computeds: {
    title: 'Computeds',
    scoped: 'Scoped Computeds',
    key: 'Key',
  },
  placement: {
    title: 'Placement',
    parentArrangement: 'Parent Arrangement',
    rotation: 'Rotation',
    position: 'Position',
    scale: 'Scale',
    area: 'Area',
    ignoreParentConstraints: "Don't inherit parent size",
    fixed: {
      left: 'Left',
      center: 'Center',
      right: 'Right',
      top: 'Top',
      bottom: 'Bottom',
      horizontal: 'Horizontal',
      vertical: 'Vertical',
    },
  },
  props: {
    title: 'Properties',
  },
  station: {
    title: 'Station',
  },
  camera: {
    title: 'Camera',
    type: 'Camera Type',
    'pan-and-zoom': {
      title: 'Pan and Zoom',
    },
    orbit: {
      title: 'Orbit',
    },
    fixed: {
      title: 'Fixed',
    },
    'auto-zoom': {
      title: 'Auto Zoom',
    },
    'first-person': {
      title: 'First Person',
    },
    panorama: {
      title: 'Panorama',
    },
    customPosition: 'Custom Position',
    zoomLimits: 'Zoom Limits',
    limitZoomForScreenSize: 'Limit zoom based on screen size',
    azimuthAngle: 'Azimuth Angle',
    polarAngle: 'Polar Angle',
    controlIfLoaded: 'Control if loaded',
  },
  size: {
    title: 'Size',
  },
  style: {
    title: 'Style',
    addProperty: 'Add Property',
  },
  sheet: {
    title: 'Sheet',
    orientTowardsCamera: 'Orient towards camera',
    scriptedType: 'Scripted Type',
  },
  children: {
    title: 'Children',
    template: 'Template',
    data: 'Data',
    useDefault: '- Use default -',
    query: 'Query',
    import: 'Import',
    remove: 'Remove',
    addQuery: 'Add Query',
    invalidTag:
      'Selected Document is not of tag template. All valid templates show their icon in the treeview. Please select one.',
    mapping: 'Mapping',
  },
  template: {
    title: 'Template',
  },
  templates: {
    title: 'Templates',
  },
  info: {
    title: 'Info',
    idCopiedToClipboard: 'ID copied to clipboard',
  },
  notes: {
    title: 'Notes',
  },
  tag: {
    hint: 'Tag',
    title: 'Tag',
    variant: 'Variant',
  },
  tagDefinition: {
    title: 'Tag Definition',
    definition: 'Definition',
    tag: 'Tag',
    titleField: 'Title',
    category: 'Category',
    icon: 'Icon',
    editor: 'Editor',
    allowedChildren: 'Allowed Children',
    variants: 'Variants',
    addVariant: 'Add Variant',
    allowedTagCategories: 'Child Categories',
    allowedTags: 'Child Tags',
  },
  interfaceDefinition: {
    title: 'Interface',
    inheritFrom: 'Inherit Props',
    inheritFromId: 'From Id',
    props: 'Props',
  },
  elementModule: {
    title: 'Element',
  },
  list: {
    title: 'Content',
    h1: 'H1',
    h2: 'H2',
    h3: 'H3',
    h4: 'H4',
    h5: 'H5',
    h6: 'H6',
    text: 'Text',
    styledText: 'Styled Text',
    reference: 'Reference',
    embed: 'Embed',
    image: 'Image',
    quote: 'Quote',
    markdown: 'Markdown',
    html: 'Html',
    page: 'Page',
    document: 'Document',
    collection: 'Collection',
    template: 'Template',
    participant: 'Participant',
    circle: 'Circle',
    noSearchResults: 'No search Results',
    placeholder: 'Your text ...',
    table: 'Table',
  },
  listener: {
    title: 'Listener',
    on: 'On Event',
    goto: 'Go to',
    add: 'Add',
    script: 'Script',
    stopPropagation: 'Stop propagation',
    listenToChildren: 'Listen to children',
    maxDepth: 'Max Depth',
  },
  element: {
    title: 'Element',
    text: {
      title: 'Text',
      subtitle: 'Display text',
      description: 'Display text',
      textContent: 'Content',
      textFormat: 'Format',
      p: 'Paragraph',
      div: 'Div',
      h1: 'Headline 1',
      h2: 'Headline 2',
      h3: 'Headline 3',
      h4: 'Headline 4',
      h5: 'Headline 5',
      h6: 'Headline 6',
    },
    icon: {
      title: 'Icon',
      sizes: {
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
        xl: 'X-Large',
      },
    },
    image: {
      title: 'Image',
      subtitle: 'Upload images',
      description: 'Upload images',
      imageSrc: 'Image URL',
      imageDescription: 'Description',
      imageCopyright: 'Copyright',
      noImageSet: 'No Image',
      expandOnClick: 'Expand on click',
    },
    embed: {
      title: 'Embed',
      subtitle: 'Embed external content',
      description: 'Embed external content',
      embedSrc: 'Embed URL',
      embedCode: 'Embed Code',
      preferredEmbedMode: 'Preferred Embed Mode',
      noEmbedSet: 'No URL to embed',
    },
    participant: {
      title: 'Participant',
      subtitle: 'Participant boxes',
      description: 'Emit participant boxes',
      boxShapes: 'Video shapes',
      circle: 'Circles',
      square: 'Squares',
      rectangle: 'Rectangles',
      cover: 'Cover',
      alwaysShowName: 'Always show names',
      hideDetails: 'Hide details',
      video: 'Video active',
      audio: 'Audio active',
      id: 'Id',
    },
    screenshare: {
      title: 'Screenshare',
      subtitle: 'Share your screen',
      description: 'Share your screen',
    },
    html: {
      title: 'HTML',
      subtitle: 'Code input',
      description: 'Write and render html code',
    },
    styledText: {
      title: 'Styled (HTML) Text',
    },
    markdown: {
      title: 'Markdown',
      subtitle: 'Code input',
      description: 'Write and render markdown code',
      loadFromUrl: 'Load content from url (.md only)',
      loadingFailed: 'Loading external content failed. Please check the url.',
      onlyMarkdownSupported:
        'Currently, only markdown files (ending in .md) are supported.',
    },
    button: {
      title: 'Button',
      text: 'Text',
      icon: 'Icon',
      event: 'Event',
      args: 'Arguments',
      href: 'Link Target',
    },
    chart: {
      title: 'Chart',
      chartType: 'Type',
      colorGridLines: 'Color',
      colorAxis: 'Color',
      showGrid: 'Show',
      direction: 'horizontal',
      axisX: 'X Axis',
      axisY: 'Y Axis',
      axis: 'Axis',
      showAxis: 'Show Axis',
      labelsY: 'Labels Y',
      labelsX: 'Labels X',
      ticksUnitY: 'Unit Y',
      ticksUnitX: 'Unit X',
      datasets: 'Data Sets',
      label: 'Label',
      values: 'Values',
      backgroundColor: 'Color',
      borderColor: 'Color',
      stacked: 'Stacked',
      beginAtZero: 'Begin At Zero',
      max: 'Max',
      min: 'Min',
      borderRadius: 'Radius',
      borderWidth: 'Width',
      showLabel: 'Label',
      labelPosition: 'Label Position',
      ticksUnitPosition: 'Axis Unit Position',
      start: 'start',
      center: 'center',
      end: 'end',
      general: 'General',
      grid: 'Grid',
      numLayers: 'Amount',
      customLayers: 'Custom',
      layers: 'Layers',
      background: 'Background',
      border: 'Border',
      scriptedDatasets: 'Scripted Datasets',
      scriptedType: 'Scripted Type',
      scriptedOptions: 'Scripted Options',
      options: 'Options',
      legendFontSize: 'Legend Font Size',
    },
    table: {
      title: 'Table',
      useQuery: 'Use Query',
      tableQuery: 'Query',
      tableRowData: 'Row Data',
      columns: 'Columns',
      columnTitle: 'Column Title',
      columnPath: 'Content Path',
      cellClasses: 'Cell Classes',
      column: 'Column',
      allowContentEditing: 'Allow Content Editing',
      lockEditing: 'Lock editing',
    },
    reveal: {
      title: 'Revealjs',
      noRevealCodeSet: 'No code available',
      revealContent: 'Markdown content',
      controls: 'Show controls: ',
      controlsLayout: 'Controls positioning: ',
      layout: { bottomRight: 'Bottom Right', edges: 'Edges' },
    },
    audio: {
      title: 'Audio-Loader',
    },
    svg: {
      title: 'SVG',
      svgCode: 'SVG Code',
      svgStyles: 'SVG CSS Styles',
    },
    inlineEditing: {
      title: 'Inline Editing',
      documentId: 'Document ID',
      label: 'Label',
      placeholder: 'Placeholder',
      allowEditing: 'Allow editing',
      fieldPath: 'Field Path',
      formatting: {
        title: 'Formatting',
        none: 'None',
        inline: 'Inline',
        basic: 'Basic',
        advanced: 'Advanced',
      },
      autoSave: 'Auto save',
      saveAsVolatile: 'Save As Volatile',
      targetFormat: {
        title: 'Text Format',
        html: 'HTML',
        text: 'Text',
      },
    },
    line: {
      title: 'Line',
      lineColor: 'Color',
      lineWidth: 'Width',
      lineFrom: 'From',
      lineTo: 'To',
    },
    video: {
      title: 'Video',
      videoSrc: 'Video URL',
      videoAssetId: 'Asset ID',
      videoPlaybackId: 'Playback ID',
      videoDescription: 'Description',
      videoTitle: 'Title',
      videoCopyright: 'Copyright',
      subtitles: 'Subtitles',
      subtitleTitle: 'Title',
      subtitleLanguage: 'Language',
      subtitleUrl: 'URL',
    },
  },
  model: {
    title: 'Model',
    loader: {
      title: 'Object-Loader',
      subtitle: 'Embed 3D objects',
      description: 'Embed 3D objects',
      library: 'Library:',
      animate: 'Animate',
      loaderSrc: 'Custom 3D Object URL',
      allowedFormats: '.gftl and .glb only',
      header3D: '3D Objects',
      options: {
        littleTokyo: 'Little Tokyo',
        ship: 'Ship in a Bottle',
        expressiveRobot: 'Expressive Robot',
        headerMaps: 'Maps & Backgrounds',
        cartoonWorld: 'Low Poly Cartoon World',
        floatingIslands: 'Floating Islands',
      },
    },
    marker: {
      title: 'Marker',
      library: 'Library',
      markerContent: 'Content',
      animate: 'Animate',
      headerMarkers: 'Markers',
    },
    video360: {
      title: '360° Video',
      subtitle: 'Embed 360° video',
      description: 'Embed 360° video',
      library: 'Library',
      videoSrc: 'Custom 360 Video URL',
      settings: {
        controlledBy: 'Controlled by',
        controlledByHost: 'Host',
        controlledByUser: 'User',
        controlPanel: 'Control Panel',
        startEvent: 'Start',
        startManually: 'Manually',
        startOnFocus: 'Focus',
        loop: 'Loop Video',
      },
      options: {
        climateArena: 'Climate Arena Tour',
        tunnel: 'Tunnel Tour',
        rainforest: 'Rainforest Tour',
      },
    },
    scenery: {
      title: 'Scenery',
      subtitle: 'New Scenery',
      description: 'Add a scenery as background',
      sceneryOptions: 'Scenery',
      options: {
        emptyScene: 'Empty Scene',
        ocean: 'Ocean',
        colorfulSky: 'Colorful sky',
        hdrTexture: 'HDR image',
        simpleFloorAndBackground: 'Floor and background',
        ambientLight: 'Ambient light',
      },
      animate: 'Animate',
      sunPosition: 'Sun position',
      azimuth: 'Azimuth',
      elevation: 'Elevation ',
      colors: 'Colors',
      baseColor: 'Base color',
      backgroundColor: 'Background color',
      image: 'Image',
      hdrImagesLibrary: 'HDR library',
      customHdrUrl: 'Custom url',
    },
    colorfulSky: {
      title: 'Colorful Sky',
    },
    hdrTexture: {
      title: 'HDR texture',
      library: 'HDR Images',
      allowedFormats: 'Allowed formats',
      customUrl: 'Custom URL',
      options: {
        starsAndSky: 'Stars and sky',
        nebula: 'Nebula',
        satara: 'Satara',
        outdoors: 'Outdoors',
        greenField: 'Green field',
        lakeview: 'Lakeview',
        lakeside: 'Lakeside',
        veniceSunset: 'Venice sunset',
        otherSunset: 'Sunset',
        indoors: 'Indoors',
        machineShop: 'Workshop',
        machineShop2: 'Machine Shop',
        studio: 'Studio',
        decorShop: 'Decor shop',
        entranceHall: 'Entrance hall',
        comfyCafe: 'Café',
        tvStudio: 'TV studio',
        oldSchoolTheater: 'Old-school theater',
      },
    },
    positionalAudio: {
      title: '3D Audio',
    },
    audioLoader: {
      title: 'Audio-Loader',
      options: {
        christmas: 'Christmas',
        inspirationalMusic: 'Inspirational Music',
      },
      settings: {
        playback: 'Play',
        controlledBy: 'Controlled by',
        controlledByHost: 'Host',
        controlledByUser: 'User',
        volume: 'Volume',
        loop: 'Loop',
        startEvent: 'Trigger',
        startOnLoad: 'Loaded',
        startOnFocus: 'Focus',
        startManually: 'Manually',
        controlPanel: 'Control Panel',
      },
      error: {
        noValidMediaSource: 'The URL does not provide a valid media source.',
      },
    },
  },
  input: {
    inputMedia: {
      mediaSrc: 'URL',
      mediaTitle: 'Title',
      mediaCopyright: 'Copyright',
      mediaDescription: 'Description',
      library: 'Library',
      upload: 'Upload file',
      files: 'Files on Fillip',
      ownUploads: 'My uploads',
    },
  },
  navigation: {
    title: 'Navigation',
    linkName: 'Link name: ',
    displaySlot: 'Display at: ',
    'Navigation-tree': 'Nav-tree',
    'Main-drawer top': 'Drawer top',
    'Main-drawer bottom': 'Drawer bottom',
    sortingArea: 'Sorting area',
    enableSorting: 'Enable sorting',
    index: 'Index',
    icon: 'Icon: ',
    linkTo: 'Root: ',
    removeLink: 'Remove link',
    addLink: 'Add Link',
    addSegment: 'Add route-segment',
    removeSegment: 'Remove route-segment',
    explicit: 'Explicit',
    segment: 'Segment',
    tooltips: {
      home: 'Home',
      navigation: 'Navigation',
      chat: 'Chat',
      activity: 'Activity',
      search: 'Search',
      explorer: 'Explorer',
      communityOverview: 'Community Overview',
      help: 'Help',
      trash: 'Trash',
      extensions: 'Extensions',
    },
  },
  participantAccount: {
    title: 'Participant Account',
    userId: 'User Id',
    role: 'Role',
    host: 'Community Host',
    admin: 'Community Admin',
    editor: 'Editor',
    participant: 'Participant',
    restricted: 'Restricted Participant',
    inactive: 'Inactive Participant',
  },
  participantStatusEditor: {
    title: 'Participant Status',
    currentlyOnline: 'Currently online',
    currentlyOffline: 'Currently offline',
    seePrototype: 'See prototypes',
  },
  participantVc: {
    title: 'Participant Videoconferencing',
  },
  avatar: {
    title: 'Avatar',
  },
  participantCircle: {
    title: 'Participant Circle',
    currentCircle: 'Current circle',
  },
  location: {
    title: 'Location',
  },
  roles: {
    title: 'Roles',
  },
  variables: {
    title: 'Variables',
    identifier: 'Identifier',
  },
  schema: {
    title: 'Schema',
    childSchema: 'Child Schema',
    ownSchema: 'Own Schema',
    entities: 'Entity Schema',
    loadFrom: 'Load Schema from',
    loadFromId: 'Load from Id',
    loadFields: 'Load Fields',
    selectItems: 'Select Items',
    addSelectItem: 'Add select item',
    removeSelectItem: 'Remove select item',
  },
  streams: {
    title: 'Streams',
  },
  circleSyncLocation: {
    title: 'Circle Location',
  },
  videoConferencing: {
    title: 'Videoconferencing',
    camera: 'Camera',
    microphone: 'Microphone',
    screen: 'Screenshare',
    roomSize: 'Room Size',
    channel: 'Channel',
    state: 'State',
    global: 'global',
    roomSizes: {
      xs: '< 4 participants',
      sm: '4 - 8 participants',
      md: '8 - 15 participants',
      lg: '15-30 participants',
    },
    settings: {
      resolutionConstraints: {
        title: 'Resolution Constraints',
        width: 'Width',
        height: 'Height',
      },
      screen: {
        title: 'Screenshare Settings',
        audio: 'Audio',
        video: {
          title: 'Video',
          cursor: 'Cursor',
          cursorOptions: {
            always: 'Always',
            never: 'Never',
            motion: 'Motion',
          },
          resizeMode: {
            title: 'Resize Mode',
            none: 'None',
            'crop-and-scale': 'Crop and Scale',
          },
          frameRate: 'Frame Rate',
          logicalSurface: 'Logical Surface',
          displaySurface: {
            title: 'Display Surface',
            monitor: 'Monitor',
            window: 'Window',
            applicaiton: 'Application',
            browser: 'Browser',
          },
        },
      },
    },
  },
  objectEditor: {
    add: 'Add modules',
    activeModules: 'Active Modules',
    noModulesSelected: 'No Modules selected',
  },
  documentEditor: {
    document: 'Document Editor',
    object: 'Object Editor',
    json: 'JSON Editor',
  },
  properties: {
    title: 'Properties',
  },
  schemaFieldsEditor: {
    title: 'Title',
    key: 'Key',
    type: 'Type',
    restrictions: 'Restrictions',
    filter: 'From Collections',
    tags: 'Included Tags',
    defaultValue: 'Default value',
    required: 'Required',
  },
  sceneDefinitions: {
    title: 'Scene Definitions',
    sceneName: 'Scene name: ',
    removeScene: 'Remove scene',
    addScene: 'Add scene',
    sceneTemplate: 'Template: ',
    sceneData: 'Data: ',
    sceneSlug: 'Slug: ',
    isDynamic: 'Dynamic?',
  },
  draggable: {
    title: 'Draggable',
    settings: 'Settings',
    isActive: 'Active?',
    dragType: 'Drag Type',
    inertia: 'Inertia?',
    bounds: 'Bounds',
    classes: 'Classes',
    draggableClasses: 'Draggable',
    draggedClasses: 'Dragged',
    droppable: 'Droppable',
    droppableIdentifier: 'Identifier',
    hitTarget: 'Hit Target',
    stayAtPosition: 'Stay at position',
    droppableOverlap: 'Overlap',
    actions: 'Action Scripts',
    onDragStart: 'onDragStart',
    onDragEnd: 'onDragEnd',
    dragTypes: {
      x: 'X (horizontal)',
      y: 'Y (vertical)',
      'x,y': '2D (X & Y)',
      rotation: 'Rotation',
    },
  },
  droppable: {
    title: 'Droppable',
    settings: 'Settings',
    condition: 'Condition',
    droppableIdentifier: 'Identifier',
    classes: 'Classes',
    droppableClasses: 'Droppable',
    droppableOverlap: 'Overlap Treshold',
    droppedClasses: 'Dropped',
    actions: 'Action Scripts',
    onDrop: 'onDrop',
    onDragEnter: 'onDragEnter',
    onDragLeave: 'onDragLeave',
  },
  transitions: {
    title: 'Transitions',
    enter: 'Enter',
    transform: 'Transform',
    leave: 'Leave',
    enterFrom: 'Enter from',
    leaveTo: 'Leave to',
    target: 'Target',
    parent: 'Parent',
  },
  editorErrors: {
    invalidUrl: 'Invalid URL',
  },
};
