import { Ability } from '@casl/ability';
import { get } from 'lodash';

function parseRuleTemplate(
  ruleTemplate: Array<string> | Record<string, any>,
  variables: Record<string, any>,
): Array<string> {
  return JSON.parse(JSON.stringify(ruleTemplate), (key, value) => {
    // make specific to variables content?
    const regex = /{{(.*)}}/gm;

    if (typeof value == 'object' || typeof value == 'boolean') return value;

    const match = value.match(regex);
    if (match) {
      const refValue = get(variables, match[0].slice(2, -2));
      if (typeof refValue == 'number') return refValue;
      return value.replace(regex, refValue);
    }
    return value;
  });
}

export default function defineAbilitiesFor(
  ruleTemplate: Array<string> | Record<string, any>,
  variables: Record<string, any>,
): Array<string> {
  return parseRuleTemplate(ruleTemplate, variables);
}
