








































import {
  useMessage,
  useFillip,
  useI18n,
  useRouter,
  useShell,
} from '@/composables';
import { computed, defineComponent, ref } from '@vue/composition-api';
import PasswordInput from '@/components/form-inputs/PasswordInput.vue';
import EmailInput from '@/components/form-inputs/EmailInput.vue';

export default defineComponent({
  name: 'AuthMethodLogin',
  components: {
    EmailInput,
    PasswordInput,
  },
  props: {
    isPrimary: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { shell, toggleLoading } = useShell();
    const { tr } = useI18n();
    const message = useMessage();
    const { router, gotoTargetOrCommunities } = useRouter();
    const { fillip } = useFillip();

    const user = ref({
      email: '',
      password: '',
    });

    const triggerLogin = async () => {
      toggleLoading(true);
      try {
        await fillip.auth.loginWithEmailAndPassword(
          user.value.email,
          user.value.password,
        );
        if (!fillip.auth.user) return router.push({ name: 'Logout' });
        gotoTargetOrCommunities();
      } catch (error) {
        toggleLoading(false);
        if (error.messageKey == 'login.error.emailNotVerified') {
          router.push({ name: 'ConfirmationPending' });
        } else {
          console.error(error);
          message.error(tr(error.messageKey || 'general.error.unknown'));
        }
      }
    };
    const submitDisabled = computed(() => {
      return !user.value.email || !user.value.password;
    });
    return {
      triggerLogin,
      shell,
      user,
      submitDisabled,
    };
  },
});
