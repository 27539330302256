var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"el",staticClass:"relative"},[_c('div',{staticClass:"element-html",domProps:{"innerHTML":_vm._s(_vm.content)}}),(_vm.annotations && _vm.annotations.length)?[(_vm.isDesktop)?_vm._l((_vm.annotations),function(annotation){return _c('div',{key:annotation.comment,staticClass:"absolute font-bold gray-700 text-left print:text-sm",style:({
          // TODO: Fix overlapping annotations
          top: ((annotation.top - 4) + "px"),
          left: _vm.globalDisplayMode === 'print' ? '-26%' : '-33%',
          maxWidth: _vm.globalDisplayMode === 'print' ? '24%' : '30%',
        })},[_vm._v(" "+_vm._s(annotation.comment)+" ")])}):[(_vm.selectedAnnotation)?_c('div',{directives:[{name:"on-click-outside",rawName:"v-on-click-outside",value:(_vm.hideAnnotation),expression:"hideAnnotation"}],staticClass:"absolute px-3 py-1 bg-gray-100 border-1 border-solid border-gray-300 rounded",style:({
          top: ((_vm.selectedAnnotation.top + 24) + "px"),
          left: ((_vm.selectedAnnotation.left) + "px"),
          maxWidth: '30%',
        })},[_vm._v(_vm._s(_vm.selectedAnnotation.comment)+" ")]):_vm._e()]]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }