import { cloneDeep, isEqual, debounce } from 'lodash';
import { useData, useVolatileData } from '@/composables';
import Vue from 'vue';
import { DataDocument, rootDataId, localPrefix } from '@fillip/api';

export const BaseDocumentEditor = Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
    forbidEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { getData, getPath, pushData, removeData } = useData();
    const { getVolatileProp, setVolatileProp, clearVolatile } =
      useVolatileData();
    return {
      getData,
      getPath,
      pushData,
      removeData,
      getVolatileProp,
      setVolatileProp,
      clearVolatile,
    };
  },
  data() {
    return {
      buffer: null,
      dirtyModules: {},
    };
  },
  computed: {
    document() {
      return this.getData(this.id);
    },
    pathItems() {
      const idPath = this.getPath(this.id);
      return idPath
        .map((id) => this.getData(id))
        .map((doc: DataDocument) => {
          return {
            id: doc.id,
            text: doc.info?.title || doc.id,
          };
        });
    },
    title() {
      return this.document?.info.title;
    },
    tag() {
      return this.document?.tag?.tag;
    },
    parentId() {
      return this.document?.parentId;
    },
    isLocal() {
      return this.id.startsWith(localPrefix);
    },
  },
  watch: {
    document: {
      immediate: true,
      handler(newValue) {
        this.buffer = cloneDeep(newValue);
      },
    },
  },
  created() {
    this.debouncedSave = debounce(() => {
      this.save();
    }, 100);
  },
  methods: {
    open(id) {
      this.$dialog.push({ name: 'DocumentEditor', params: { id } });
    },
    async remove() {
      if (this.getVolatileProp(rootDataId, 'selection') == this.id) {
        this.setVolatileProp(rootDataId, 'selection', null);
      }
      await this.removeData(this.id);
      this.clearVolatile(this.id);
    },
    async save() {
      if (isEqual(this.buffer, this.template)) return;
      const update = Object.keys(this.dirtyModules).reduce(
        (patch, moduleName) => {
          patch[moduleName] = this.buffer[moduleName];
          return patch;
        },
        {},
      );
      this.dirtyModules = {};

      await this.$fiStoreDispatcher.patchPaths('data', this.id, update);
    },
    triggerSave(moduleName) {
      // console.log('DEBOUNCED Save: ', moduleName);

      this.$set(this.dirtyModules, moduleName, true);
      this.debouncedSave();
    },
    immediateSave(moduleName) {
      // console.log('IMMEDIATE Save: ', moduleName);

      this.$set(this.dirtyModules, moduleName, true);
      this.save();
    },
  },
});
