import type { ParticipantDocument } from '@/features/main/core';
import {
  type EvaluationContext,
  type Id,
  type ModuleData,
  frontendPrefixMarker,
} from '@fillip/api';
import _ from 'lodash/fp';
import { nanoid } from 'nanoid';

type ProgressState = 'started' | 'finished';
type PersistenceMode = 'none' | 'router' | 'local' | 'profile' | 'shared';
interface ProgressOptions {
  $id: Id;
  dataId: Id;

  collection?: any[];
  isSequential?: boolean;
  doneAfterStarted?: boolean;

  navigationPersistence?: PersistenceMode;
  progressPersistence?: PersistenceMode;
  progressCollectionId?: string; // if navigationPersistence OR progressPersistence == profile
  stationTemplateId?: string;

  autoForward?: boolean;
  showAfterStarted?: 'firstActive' | 'lastActive' | 'overview';
  showAfterFinished?: 'firstActive' | 'lastActive' | 'persistent' | 'overview';
  cycle?: boolean;

  onStarted?: () => void;
  onFinished?: () => void;
  onStepStarted?: (stepId: string) => void;
  onStepFinished?: (stepId: string) => void;

  // TODO: Implement?
  // onDone?: () => void;
  // onStepDone?: () => void;

  // template: Id;
  // templateOptions: Record<string, any>;
  // navigationTemplate: Id;
  // navigationOptions: Record<string, any>;
  // headerTemplate: Id;
  // headerOptions: Record<string, any>;
  // sidebarTemplate: Id;
  // sidebarOptions: Record<string, any>;
}

export function useProgress(
  env: any,
  $me: ParticipantDocument,
  context: EvaluationContext,
  options: ProgressOptions,
) {
  // console.log('useProgress', options);
  const {
    $id,
    dataId,
    collection,
    progressCollectionId,

    isSequential = true,
    doneAfterStarted = false,
    navigationPersistence = 'local',
    progressPersistence = 'local',
    stationTemplateId = '',
    autoForward = false,
    showAfterFinished = 'persistent',
    showAfterStarted = 'overview',
    cycle = false,

    onStarted = null,
    onFinished = null,
    onStepStarted = null,
    onStepFinished = null,
    // onClose = '',
    // onStepDone = '',

    // template: ,
    // templateOptions: ,
    // navigationTemplate: ,
    // navigationOptions: ,
    // headerTemplate: ,
    // headerOptions: ,
    // sidebarTemplate: ,
    // sidebarOptions: ,
  } = options;
  // console.log(
  //   'useProgress',
  //   $id,
  //   $me,
  //   dataId,
  //   navigationPersistence,
  //   progressPersistence,
  // );

  if (!$id || !dataId) throw new Error('Missing id');

  const {
    getList,
    evaluateCondition,
    emitEvent,
    getVolatileProp,
    setVolatileProp,
    clearVolatileProp,
    getParticipantList,
    pushData,
    updateProperties,
    router,
    maybeId,
  } = env;

  const NAVIGATION_KEY = 'currentId';
  const PROGRESS_KEY = 'progress:' + $id;
  const PROGRESS_VALUE = $id;

  const createProgressDocument = async (progressCollectionId) => {
    if (![progressPersistence, navigationPersistence].includes('profile'))
      return;
    const doc = {
      id: nanoid(),
      info: {
        title: $me.id,
        createdAt: Date.now(),
        createdBy: $me.id,
      },
      properties: {
        [PROGRESS_VALUE]: {
          type: 'string',
          value: 'started',
        },
      },
    };
    await pushData(progressCollectionId, 'document', doc);
    return doc;
  };

  const progressDocument =
    progressCollectionId && progressPersistence === 'profile'
      ? // (progressPersistence === 'profile' || navigationPersistence === 'profile')
        getParticipantList(progressCollectionId, $me.id)()?.[0] ?? {}
      : {};

  const getStepByIndex = (index: number) => steps[index];
  const getStepById = (id: string) => steps.filter((s) => s.id === id)?.[0];

  const rawSteps = collection || getList(dataId)();
  if (!rawSteps?.length)
    throw new Error(
      `useProgress: Couldn't find documents in collection or as children of ${dataId}`,
    );

  // Set and get values dependent on persistence mode

  const setValue = (persistence: PersistenceMode, key: string, value: any) => {
    if (persistence === 'none') return;

    if (persistence === 'local') {
      return setVolatileProp(PROGRESS_KEY, key, value);
    }
    if (['profile', 'shared'].includes(persistence)) {
      return updateProperties(
        persistence === 'shared' ? dataId : progressDocument.id,
        {
          [key]: {
            type: typeof value === 'boolean' ? 'boolean' : 'string',
            value,
          },
        },
      );
    }

    if (persistence === 'router') {
      if (key === NAVIGATION_KEY) {
        return router.value.focus(`${stationTemplateId}:${value}`);
      }
    }
    throw new Error(
      `Progress: Persistence mode ${persistence} is not supported yet!`,
    );
  };

  const clearValue = (persistence: PersistenceMode, key: string) => {
    if (persistence === 'none') return;

    if (persistence === 'router') {
      if (key === NAVIGATION_KEY) {
        router.value.removeFocus();
        return;
      }
    }

    if (persistence == 'local') {
      return clearVolatileProp(PROGRESS_KEY, key);
    }
    if (['profile', 'shared'].includes(persistence)) {
      return updateProperties(
        persistence === 'shared' ? dataId : progressDocument.id,
        {
          [key]: null,
        },
      );
    }
    throw new Error(
      `Progress: Persistence mode ${persistence} is not supported yet!`,
    );
  };

  const getValue = (
    persistence: PersistenceMode,
    key: string,
    fallback: any = null,
  ) => {
    if (persistence === 'none') return;

    if (persistence === 'router')
      if (key === NAVIGATION_KEY) {
        const focused = router.value.focusedId;
        if (!focused) return fallback;
        return focused.split(':')[1];
      }

    if (persistence == 'local') {
      return getVolatileProp(PROGRESS_KEY, key, fallback);
    }
    if (['profile', 'shared'].includes(persistence)) {
      return progressDocument?.properties?.[key]?.value ?? fallback;
    }
    throw new Error(
      `Progress: Persistence mode ${persistence} is not supported yet!`,
    );
  };

  // State navigation and persistence

  const setCurrent = (target: string | any) => {
    const id = maybeId(target);
    const targetStep = getStepById(id);
    if (!targetStep.computed?.progress?.isActive) return;
    setValue(navigationPersistence, NAVIGATION_KEY, id);
    return targetStep;
  };

  const clearCurrent = () => {
    clearValue(navigationPersistence, NAVIGATION_KEY);
    return null;
  };

  const getCurrentId = (fallback?: string): any => {
    return getValue(navigationPersistence, NAVIGATION_KEY, fallback);
  };

  // Progress management and persistence

  const setStepProgress =
    (state: ProgressState) =>
    (stepIdOrDoc: string | any): void => {
      const id = maybeId(stepIdOrDoc);
      setValue(progressPersistence, id, state);
    };

  const getStepProgress = (stepIdOrDoc: string | any): ProgressState => {
    const id = maybeId(stepIdOrDoc);
    return getValue(progressPersistence, id, null);
  };

  const currentId = getCurrentId(null);

  // Called in ready listener of the document within the progress is initiated
  const setStarted = () => {
    if (getValue(progressPersistence, PROGRESS_VALUE, null)) return;

    if (progressCollectionId) {
      if (progressDocument.id) {
        setValue(progressPersistence, PROGRESS_VALUE, 'started');
      } else {
        createProgressDocument(progressCollectionId);
      }
    }

    if (typeof onStarted === 'function') {
      onStarted();
    }
  };

  // Called in unload listener of the document within the progress is initiated
  const setFinished = () => {
    if (
      !isDone ||
      getValue(progressPersistence, PROGRESS_VALUE, null) === 'finished'
    )
      return;
    if (typeof onFinished === 'function') {
      onFinished();
    }
    setValue(progressPersistence, PROGRESS_VALUE, 'finished');

    if (showAfterFinished === 'firstActive') setCurrent(firstActiveDoc);
    if (showAfterFinished === 'lastActive') setCurrent(lastActiveDoc);
    if (showAfterFinished === 'overview') clearCurrent();
  };

  // Called in ready listener of step content container
  const setStepStarted = (stepId) => {
    if (getStepProgress(stepId)) return;
    if (typeof onStepStarted === 'function') {
      onStepStarted(stepId);
    }
    setStepProgress('started')(stepId);
  };

  // Called in unload listener of step content container
  const setStepFinished = (stepId: string = currentId) => {
    const step = getStepById(stepId);
    if (
      evaluateCondition(context, step?.computed?.progress?.isDone, false) &&
      getStepProgress(stepId) === 'finished'
    )
      return;
    if (typeof onStepFinished === 'function') {
      onStepFinished(stepId);
    }
    setStepProgress('finished')(stepId);
    // TODO: Return to parent
  };

  const isStepActive = (step: ModuleData, previousSteps: ModuleData[]) => {
    const customCondition = step.properties?.isActive?.value as
      | string
      | undefined;

    if (customCondition) {
      if (customCondition.startsWith(frontendPrefixMarker)) {
        return evaluateCondition(context, customCondition, true);
      }
      if (customCondition.includes('::')) {
        const [predicate, value] = customCondition.split('::');
        if (predicate === 'after') {
          return previousSteps[value].computed.progress.isFinished;
        }
      }
    }

    if (!isSequential) return true;
    return (
      previousSteps.filter((s) => !s.computed?.progress?.isDone).length === 0
    );
  };

  const steps = rawSteps.reduce(
    (acc: ModuleData[], step: ModuleData, index: number) => {
      const progress = getStepProgress(step);
      const isStarted = !!progress;
      const isActive = isStepActive(step, acc);

      const isStepDone = doneAfterStarted
        ? isStarted
        : evaluateCondition(context, step.properties?.isDone?.value, isStarted);

      const isStepFinished = isStepDone && progress === 'finished';

      acc.push({
        ...step,
        isComputed: true,
        computed: {
          progress: {
            index,

            isStarted,
            isActive,
            isDone: isStepDone,
            isFinished: isStepFinished,

            // isDisabledForNav: step.properties?.isDisabledForNav?.value ?? false,
            isCurrent: step.id === currentId,
            // getIsLastActive: () => step.id === lastActiveDoc.id,
            isLast: index === rawSteps.length - 1,
            isFirst: index === 0,
            steps: rawSteps.length,
          },
        },
      });
      return acc;
    },
    [],
  );

  // console.log('steps', steps);

  const isFinished =
    getValue(progressPersistence, PROGRESS_VALUE, 'finished') === 'finished';

  const getIndex = (idOrDoc: string | any): number => {
    if (!idOrDoc) return -1;
    const id = maybeId(idOrDoc);
    const index =
      idOrDoc.computed?.progress?.index ?? steps.findIndex((s) => s.id === id);
    if (index < 0) throw new Error(`Cannot find step with id ${id}`);
    return index;
  };

  const activeSteps = steps.filter(
    (s) => s.computed.progress.isActive === true,
  );
  // const navActiveSteps = steps.filter(
  //   (s) =>
  //     s.computed.progress.isActive === true &&
  //     (!s.computed.progress.isDisabledForNav || !s.computed.progress.isDone),
  // );
  const doneSteps = steps.filter((s) => s.computed.progress.isDone === true);

  const firstActiveDoc = _.first(activeSteps) as any;
  const firstActiveIndex = getIndex(firstActiveDoc);

  const lastActiveDoc = _.last(activeSteps) as any;
  const lastActiveIndex = getIndex(lastActiveDoc);

  // const firstNavActiveDoc = _.first(activeSteps) || {};
  // const lastNavActiveDoc = _.last(activeSteps) || {};

  const determineCurrentDoc = () => {
    const savedCurrentId = getCurrentId(null);

    const savedCurrentDoc = savedCurrentId && getStepById(savedCurrentId);

    if (savedCurrentDoc?.computed?.progress?.isActive) {
      return savedCurrentDoc;
    }

    if (getValue(progressPersistence, PROGRESS_VALUE, null) === 'finished') {
      if (showAfterFinished === 'firstActive') return firstActiveDoc;
      if (showAfterFinished === 'lastActive') return lastActiveDoc;
      if (showAfterFinished === 'overview') return null;
    }

    if (autoForward) return lastActiveDoc;

    if (showAfterStarted === 'firstActive') return setCurrent(firstActiveDoc);
    if (showAfterStarted === 'lastActive') return setCurrent(lastActiveDoc);

    if (showAfterStarted === 'overview') return clearCurrent();

    return null;
  };

  const currentDoc = determineCurrentDoc();
  const currentIndex = getIndex(currentDoc);
  const isFirst = currentIndex === 0;
  const isLast = currentIndex === steps.length - 1;
  const isOverview = !currentIndex;
  if (currentIndex && currentIndex >= 0)
    steps[currentIndex].computed.progress.isCurrent = true;

  const prevDoc = !currentDoc
    ? lastActiveDoc
    : _.pipe(
        _.filter(
          (s: any) =>
            s.computed?.progress?.index < currentDoc.computed.progress.index,
        ),
        _.last,
      )(activeSteps);
  const prevIndex = getIndex(prevDoc);

  const nextDoc = !currentDoc
    ? firstActiveDoc
    : _.pipe(
        _.filter(
          (s: any) =>
            s.computed.progress.index > currentDoc.computed.progress.index,
        ),
        _.first,
      )(activeSteps);
  const nextIndex = getIndex(nextDoc);

  const numberOfSteps = steps.length;
  const numberOfActiveSteps = activeSteps.length;
  const numberOfDoneSteps = doneSteps.length;

  const isDone = numberOfDoneSteps == numberOfSteps;

  const percentDone = (doneSteps.length / steps.length) * 100;

  // To be called in progress.close listener
  const closeStep = (stepId) => {
    setStepFinished(stepId);
    clearCurrent();
  };

  const prev = () => {
    if (prevDoc) {
      return setCurrent(prevDoc);
    }

    if (cycle) {
      return setCurrent(lastActiveDoc);
    }
    close('back');
  };

  const next = () => {
    if (nextDoc) {
      return setCurrent(nextDoc);
    }

    if (cycle) {
      return setCurrent(firstActiveDoc);
    }
    close('forward');
  };

  const jumpTo = (idOrIndex: string | number) => {
    const id =
      typeof idOrIndex === 'string' ? idOrIndex : getStepByIndex(idOrIndex);
    if (!id) throw new Error(`Cannot find step with id ${id}`);
    // TODO: Check if id as an allowed target
    setCurrent(id);
  };

  const close = (direction?: 'back' | 'forward') => {
    // if (!onClose) {
    //   return;
    // }
    emitEvent('close', { direction }, $id);
  };
  return {
    steps,
    numberOfSteps,

    activeSteps,
    numberOfActiveSteps,

    firstActiveDoc,
    firstActiveIndex,
    prevDoc,
    prevIndex,
    currentDoc,
    currentIndex,
    nextDoc,
    nextIndex,
    lastActiveDoc,
    lastActiveIndex,

    isFirst,
    isLast,
    isDone,
    percentDone,
    isFinished,

    prev,
    next,
    jumpTo,
    close,

    getStepById,
    getStepByIndex,

    setStarted,
    setFinished,
    setStepStarted,
    setStepFinished,
    closeStep,

    isOverview,
  };
}
