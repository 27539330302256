import { DataDocument, PresetDefinition } from '@fillip/api';

const LibraryContentInstance: DataDocument = {
  id: 'LibraryContentInstance',
  info: {
    title: 'Library Content',
    icon: 'book',
    toolEditing: {
      preferredArrangement: 'topbar-main',
      editors: {
        title: {
          id: 'LibraryContentInstance',
        },
        properties: { id: 'LibraryContentInstance', title: 'Einstellungen' },
      },
    },
  },
  tag: {
    tag: 'template',
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  children: {
    default: [
      {
        templateId: 'tool-content',
        query:
          ':properties?.document?.value ? get(properties.document.value) : []',
        condition: ':!!properties?.document?.value',
      },
    ],
  },
  list: {
    items: [],
  },
  properties: {
    document: {
      type: 'id',
      value: '',
    },
  },
  schema: {
    fields: [],
    ownFields: [
      {
        title: 'Document',
        key: 'document',
        type: 'id',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'templateParent',
        query: ':id',
      },
    ],
  },
  sheet: {
    orientTowardsCamera: false,
  },
};

export const PresetLibraryContent: PresetDefinition = {
  id: 'PresetLibraryContent',
  title: 'Library Content',
  icon: 'book',
  group: 'tools',
  version: '0.1',
  documents: [LibraryContentInstance],
};
