export type RgbaColor = { r: number; g: number; b: number; a?: number };

export function useColors({ useAlpha = false }: { useAlpha?: boolean }) {
  const toCss = (rgba: RgbaColor) => {
    if (!rgba) return `rgba(255,255,255,1)`;
    return useAlpha
      ? `rgba(${rgba.r},${rgba.g},${rgba.b},${Math.round(rgba.a * 100) / 100})`
      : `rgb(${rgba.r},${rgba.g},${rgba.b})`;
  };

  const toRgba = (css: string): RgbaColor => {
    if (!css) return { r: 255, g: 255, b: 255, a: 1 };

    const [r, g, b, a] = css
      .slice(css.startsWith('rgba') ? 5 : 4, -1)
      .split(',');
    const result: any = {
      r: Number(r.trim()),
      g: Number(g.trim()),
      b: Number(b.trim()),
    };
    if (useAlpha) {
      result.a = Number(a.trim());
    }
    return result;
  };

  return {
    toCss,
    toRgba,
  };
}
