import { ModuleTypeMeta, TypeMeta, Vector3Type } from '../../common';

export interface Vector3 {
  x: number;
  y: number;
  z: number;
}

export interface Location3D {
  position: Vector3;
  rotation: Vector3;
  scale: Vector3;
}

type PlacementTypeKeys =
  | 'placement.absolute'
  | 'placement.origin'
  | 'placement.fixed'
  | 'placement.slides'
  | 'placement.grid'
  | 'placement.flex'
  | 'placement.block'
  | 'placement.divide'
  | 'placement.circle'
  | 'placement.rectangle'
  | 'placement.sphere';

interface PlacementType<Key extends PlacementTypeKeys> {
  type: Key;
}

export interface PlacementTypeMeta<T extends PlacementType<PlacementTypeKeys>>
  extends ModuleTypeMeta<T> {
  type: T['type'];
  name?: string;
}

export interface PlacementAbsolute extends PlacementType<'placement.absolute'> {
  ignoreParentConstraints?: boolean;
  containerPointerNone?: boolean;
  absoluteLocation: Location3D;
}

export const PlacementAbsoluteMeta: PlacementTypeMeta<PlacementAbsolute> = {
  type: 'placement.absolute',
  name: 'Absolute',
  default: {
    type: 'placement.absolute',
    ignoreParentConstraints: false,
    absoluteLocation: {
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
      rotation: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
    },
  },
};
export interface PlacementOrigin extends PlacementType<'placement.origin'> {}

export const PlacementOriginMeta: PlacementTypeMeta<PlacementOrigin> = {
  type: 'placement.origin',
  name: 'Origin',
  default: {
    type: 'placement.origin',
  },
};

export interface PlacementFixed extends PlacementType<'placement.fixed'> {
  fixedHorizontal: 'left' | 'center' | 'right';
  fixedVertical: 'top' | 'center' | 'bottom';
  padding?: number;
  position?: Vector3Type;
}

export const PlacementFixedMeta: PlacementTypeMeta<PlacementFixed> = {
  type: 'placement.fixed',
  name: 'Fixed',
  default: {
    type: 'placement.fixed',
    fixedHorizontal: 'center',
    fixedVertical: 'center',
  },
};

export interface PlacementSlides extends PlacementType<'placement.slides'> {}

export const PlacementSlidesMeta: PlacementTypeMeta<PlacementSlides> = {
  type: 'placement.slides',
  name: 'Slides',
  default: {
    type: 'placement.slides',
  },
};

export interface PlacementGrid extends PlacementType<'placement.grid'> {
  gridLeft: number;
  gridTop: number;
  gridWidth: any;
  gridHeight: any;
}

export const PlacementGridMeta: PlacementTypeMeta<PlacementGrid> = {
  type: 'placement.grid',
  name: 'Grid',
  default: {
    type: 'placement.grid',
    gridLeft: 0,
    gridTop: 0,
    gridWidth: 0,
    gridHeight: 0,
  },
};

export interface PlacementFlex extends PlacementType<'placement.flex'> {}

export const PlacementFlexMeta: PlacementTypeMeta<PlacementFlex> = {
  type: 'placement.flex',
  name: 'Flex',
  default: {
    type: 'placement.flex',
  },
};

export interface PlacementBlock extends PlacementType<'placement.block'> {}

export const PlacementBlockMeta: PlacementTypeMeta<PlacementBlock> = {
  type: 'placement.block',
  name: 'Block',
  default: {
    type: 'placement.block',
  },
};

export interface PlacementDivide extends PlacementType<'placement.divide'> {}

export const PlacementDivideMeta: PlacementTypeMeta<PlacementDivide> = {
  type: 'placement.divide',
  name: 'Divide',
  default: {
    type: 'placement.divide',
  },
};

export interface PlacementCircle extends PlacementType<'placement.circle'> {}

export const PlacementCircleMeta: PlacementTypeMeta<PlacementCircle> = {
  type: 'placement.circle',
  name: 'Circle',
  default: {
    type: 'placement.circle',
  },
};

export interface PlacementRectangle
  extends PlacementType<'placement.rectangle'> {}

export const PlacementRectangleMeta: PlacementTypeMeta<PlacementRectangle> = {
  type: 'placement.rectangle',
  name: 'Rectangle',
  default: {
    type: 'placement.rectangle',
  },
};

export interface PlacementSphere extends PlacementType<'placement.sphere'> {}

export const PlacementSphereMeta: PlacementTypeMeta<PlacementSphere> = {
  type: 'placement.sphere',
  name: 'Sphere',
  default: {
    type: 'placement.sphere',
  },
};

export type EvaluatedModulePlacement =
  | PlacementAbsolute
  | PlacementFixed
  | PlacementSlides
  | PlacementGrid
  | PlacementFlex
  | PlacementDivide
  | PlacementCircle
  | PlacementRectangle
  | PlacementSphere
  | PlacementBlock;

export interface ModulePlacementTypeMeta
  extends ModuleTypeMeta<EvaluatedModulePlacement> {
  types: {
    [key in PlacementTypeKeys]: PlacementTypeMeta<PlacementType<key>>;
  };
}

export const ModulePlacementMeta: ModulePlacementTypeMeta = {
  types: {
    'placement.absolute': PlacementAbsoluteMeta,
    'placement.origin': PlacementOriginMeta,
    'placement.fixed': PlacementFixedMeta,
    'placement.slides': PlacementSlidesMeta,
    'placement.grid': PlacementGridMeta,
    'placement.flex': PlacementFlexMeta,
    'placement.divide': PlacementDivideMeta,
    'placement.circle': PlacementCircleMeta,
    'placement.rectangle': PlacementRectangleMeta,
    'placement.sphere': PlacementSphereMeta,
    'placement.block': PlacementBlockMeta,
  },
  default: PlacementAbsoluteMeta.default,
};
