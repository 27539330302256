import { extractFromParamsAndMeta } from '@fillip/api';

export default [
  {
    path: '/members/member/:participantId',
    name: 'ParticipantView',
    meta: {},
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(
        /* webpackChunkName: "main" */ '@/features/community/ParticipantView.vue'
      ),
  },
  {
    path: '/profile',
    name: 'ParticipantProfile',
    meta: {
      heading: 'profile.yourProfile',
    },
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(
        /* webpackChunkName: "main" */ '@/features/participant/ParticipantProfile.vue'
      ),
  },
  {
    path: '/community-editor',
    name: 'CommunityEditor',
    meta: {
      heading: 'communities.communityEditor',
      permission: 'community:edit',
    },
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(
        /* webpackChunkName: "main" */ '@/features/community/CommunityEditor.vue'
      ),
  },
  {
    path: '/select-devices',
    name: 'SelectDevices',
    meta: { heading: 'videoConference.selectYourDevices' },
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(
        /* webpackChunkName: "main" */ '@/features/video-conferencing/SelectDevices.vue'
      ),
  },
  {
    path: '/debug',
    name: 'WorldDebug',
    meta: { heading: 'debug.debug', permission: 'global:admin' },
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/features/tools/WorldDebug.vue'),
  },
  {
    path: '/editor/:id?',
    name: 'Editor',
    meta: { hideDefaultHeader: true, permission: 'global:admin' },
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/features/editor/Editor.vue'),
  },
  {
    path: '/stepper/:id?',
    name: 'Stepper',
    meta: {},
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '@/features/stepper/StepperEditor.vue'
      ),
  },
  {
    path: '/tool-editor/:workspaceId/:id/:meta?',
    name: 'ToolEditor',
    meta: { heading: 'editing.editTool' },
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '@/features/stepper/ToolEditor.vue'
      ),
  },
  {
    path: '/user-account',
    name: 'UserAccount',
    meta: { heading: 'user.account' },
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(
        /* webpackChunkName: "world" */ '@/features/account/UserAccount.vue'
      ),
  },
  {
    path: '/upgrade-user',
    name: 'UpgradeUser',
    meta: { title: 'logout.upgrade' },
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(/* webpackChunkName: "main" */ '@/features/auth/Upgrade.vue'),
  },
  {
    path: '/clipboard/:mode?',
    name: 'DataClipboard',
    meta: { heading: 'templateClipboard.title', permission: 'community:edit' },
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(
        /* webpackChunkName: "main" */ '@/features/explorer/DataClipboard.vue'
      ),
  },
  {
    path: '/document-editor/:id',
    name: 'DocumentEditor',
    meta: { hideDefaultHeader: true, permission: 'community:edit' },
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(
        /* webpackChunkName: "main" */ '@/features/explorer/DocumentEditor.vue'
      ),
  },
  {
    path: '/legal/:content',
    name: 'Legal',
    meta: { hideDefaultHeader: true },
    props: (route) => extractFromParamsAndMeta(route),
    component: () =>
      import(/* webpackChunkName: "auth" */ '@/features/auth/Legal.vue'),
  },
];
