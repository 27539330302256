
















































import { icons } from '@/utils/icons';
import { frontendPrefixMarker } from '@fillip/api';

export default {
  name: 'InputIcon',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    label: {
      type: String,
      default: function () {
        return this.$t('icon.default');
      },
    },
    singleLine: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultIcons: {
        type: Array,
      },
      buffer: null,
      frontendPrefixMarker,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.buffer = newValue;
      },
    },
  },
  created() {
    this.setDefaultIcons();
  },
  methods: {
    setDefaultIcons() {
      (this as any).defaultIcons = icons;
    },
    updateBuffer(newValue) {
      if ((this as any).value != newValue) {
        this.$emit('input', newValue);
        this.$emit('change', newValue);
        this.$emit('blur', newValue);
      }
    },
  },
};
