import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagH3: DataDocument = {
  id: 'tag-h3',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Heading 3',
    icon: 'h3',
    isProtected: true,
  },
  element: {
    type: 'element.text',
    textFormat: 'h3',
    textContent: ':$text',
  },
  class: {
    class: ':`${$containerClasses}`',
    elementClass: ':`${$elementClasses}`',
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'h3',
    title: 'Heading 3',
    category: 'content',
    icon: 'h3',
    editor: 'h3',
  },
  interfaceDefinition: {
    title: '',
    inheritFrom: 'baseContent',
    props: [
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":`${$$contentTagClasses} ${$$headlineClasses} text-lg ${$containerClasses || properties?.containerClasses?.value || ''}`",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":`${$elementClasses || properties?.elementClasses?.value || ''}`",
      },
    ],
  },
};
