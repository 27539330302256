import {
  DataDocument,
  hasRole,
  DefaultLayouts,
  Action,
  objectPush,
  determineLayoutForBreakpoint,
} from '@fillip/api';
import { RenderContext, renderTemplate } from './../scene/render';
import { VNode, f } from '@/features/main/engine';

import {
  FunctionTemplate,
  FunctionTemplateRenderMode,
  RenderResult,
} from './types';

// Properties in data
// library: id of folder with tool templates for selection (read only)
// tools: id of folder with tool instances
// phases: id of folder with pre configured phases
// phase: index of currently active phase

export const stepper: FunctionTemplate = (
  context: RenderContext,
  mode: FunctionTemplateRenderMode,
  data?: DataDocument,
): RenderResult => {
  const variables = context.env.$vars;
  const props = context.env.$props;
  const computeds = context.env.$computeds;
  if (!data) {
    return { variables, props, computeds, template: f({}, []) };
  }

  const {
    tools: toolsId,
    phases: phasesId,
    phase,
    library: libraryId,
  } = data.properties;

  const isOnSite = context.env.$vars.$$isOnSite || false;
  const isCamera = context.env.$vars.$$isCamera || false;

  const library = context.vueInstance.getData(libraryId.value as string);
  const phases = context.vueInstance.getData(phasesId.value as string);
  const tools = context.vueInstance.getData(toolsId.value as string);

  const activePhase = context.vueInstance.getData(
    phases?.list?.items[phase.value as number]?.id,
  );

  const toolInstances = [0, 1, 2, 3]
    .map((index) => 'tool-' + index)
    .map((prop) => activePhase?.properties[prop]?.value as string)
    .map((id) => context.vueInstance.getData(id));

  const layout = (activePhase.properties?.layout?.value as string) || 'single';

  const activeLayoutName = activePhase
    ? determineLayoutForBreakpoint(
        layout,
        context.vueInstance.$vuetify.breakpoint,
      ) || 'main-side'
    : 'single';

  let activeLayout = DefaultLayouts[activeLayoutName] || DefaultLayouts.single;

  const control: Action = {
    name: 'stepper',
    type: 'action.stepper',
    context: {
      documentId: data.id,
    },
    slot: 'bottom',
    roles: ['host'],
  };

  if (
    hasRole(context.env.$roles)('host')(context.env.$me) ||
    (data?.roles?.roles && hasRole(data.roles.roles)('host')(context.env.$me))
  ) {
    objectPush(context.out.globalProps, 'actions.canvas', control);
  }

  const usedIds = {};

  let toolsToDisplay;
  if (isOnSite || isCamera) {
    if (activeLayout.numTools > 1) {
      toolsToDisplay = toolInstances.slice(1, activeLayout.numTools + 1);
      activeLayout = DefaultLayouts['single'];
    } else {
      if (toolInstances[0].id == `${data.id}-tool-livestream`) {
        toolsToDisplay = [context.vueInstance.getData(`${data.id}-tool-title`)];
      } else {
        toolsToDisplay = toolInstances.slice(0, activeLayout.numTools + 1);
      }
    }
  } else {
    toolsToDisplay = toolInstances.slice(0, activeLayout.numTools + 1);
  }

  const children: VNode[] = toolsToDisplay.map((tool) => {
    if (!tool) {
      return f({}, []);
    }

    let suffix = 0;
    while (usedIds[tool.id + suffix]) ++suffix;

    const id = tool.id + suffix;
    usedIds[id] = true;
    return renderTemplate(id, context, tool.id, tool);
  });

  const result = f(
    {
      arrangement: activeLayout.arrangement,
      roles: data?.roles,
      sheet: {
        orientTowardsCamera: false,
      },
    },
    children,
  );
  // console.log(result);
  return { variables, props, computeds, template: result };
};
