import store from '@/store';
import { useI18n } from '../i18n/useI18n';

type messageAction = {
  buttonText: string;
  color: string;
  action: string;
  target: string;
  arguments: Object;
};

export function useMessage() {
  const { maybeTr } = useI18n();

  const show = (
    text: string,
    type: string,
    timeout?: number,
    action?: messageAction,
  ) => {
    text = maybeTr(text);
    store.dispatch('notify', {
      text,
      type,
      timeout,
      action,
    });
  };

  const success = (message: string) => {
    show(message, 'success');
  };

  const error = (message: string) => {
    show(message, 'error');
  };

  const info = (message: string) => {
    show(message, 'info');
  };

  const warning = (message: string) => {
    show(message, 'warning');
  };

  return {
    show,
    success,
    error,
    info,
    warning,
  };
}
