































// TODO: Offer choice between rgba and hex, show theme colors as swatches
import Vue from 'vue';
export default Vue.extend({
  name: 'InputColor',
  props: {
    showValues: { type: Boolean, default: true },
    value: {
      type: String,
      default: 'rgba(255, 255, 255, 1)',
    },
    alpha: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buffer: '',
    };
  },
  computed: {
    currentAsCss() {
      return this.toCss(this.buffer);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.buffer = this.toRgba(newValue);
      },
    },
  },
  methods: {
    updateColor(newColor) {
      this.buffer = newColor;
      this.$emit('input', this.toCss(this.buffer));
      this.$emit('blur', this.toCss(this.buffer));
    },
    toCss(rgba) {
      if (!rgba) return `rgba(255,255,255,1)`;
      return this.alpha
        ? `rgba(${rgba.r},${rgba.g},${rgba.b},${
            Math.round(rgba.a * 100) / 100
          })`
        : `rgb(${rgba.r},${rgba.g},${rgba.b})`;
    },
    toRgba(css) {
      if (!css) return { r: 255, g: 255, b: 255, a: 1 };

      const [r, g, b, a] = css
        .slice(css.startsWith('rgba') ? 5 : 4, -1)
        .split(',');
      const result: any = {
        r: Number(r.trim()),
        g: Number(g.trim()),
        b: Number(b.trim()),
      };
      if (this.alpha) {
        result.a = Number(a.trim());
      }
      return result;
    },
  },
});
