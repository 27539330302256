export * from './PresetDefaultTemplate';
export * from './PresetWorkspace';
export * from './PresetOnboarding';
import { PresetDefaultTemplate } from './PresetDefaultTemplate';
import { PresetWorkspace } from './PresetWorkspace';
import { PresetOnboarding } from './PresetOnboarding';

export const TemplatePresets = {
  default: PresetDefaultTemplate,
  workspace: PresetWorkspace,
  onboarding: PresetOnboarding,
};
