import { ModuleTypeMeta } from '../../common';

interface ListenerChild {
  script: string;
  context: Record<string, any>;
  stopPropagation?: boolean;
  listenToChildren?: boolean;
  maxDepth?: number;
}
export interface ModuleListener {
  listeners: {
    [name: string]: ListenerChild;
  };
}

export const ModuleListenerMeta: ModuleTypeMeta<ModuleListener> = {
  default: {
    listeners: {},
  },
  childDefault: {
    script: '',
    context: {},
    stopPropagation: false,
    listenToChildren: false,
    maxDepth: 0,
  },
  icon: 'info-circle',
};
