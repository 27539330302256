import { BaseObject3D } from '../core';
import {
  SphereGeometry,
  MeshBasicMaterial,
  Color,
  Mesh,
  CanvasTexture,
  LinearFilter,
  PlaneGeometry,
  DoubleSide,
} from 'three';
import { createExplicitSegmentFromObject } from '@fillip/api';

export const ModelMarker = {
  name: 'ModelMarker',
  mixins: [BaseObject3D],
  inject: ['router'],
  data() {
    return {
      hover: false,
    };
  },
  created() {
    const geometry = new SphereGeometry(20, 20, 20);
    const material = new MeshBasicMaterial({
      color: new Color(0x1065de),
      transparent: true,
      opacity: 0.5,
    });
    const point = new Mesh(geometry, material);

    const titleCanvas = document.createElement('canvas');
    const texture = new CanvasTexture(titleCanvas);
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    const sign = new Mesh(
      new PlaneGeometry(1, 1, 1, 1),
      new MeshBasicMaterial({
        map: texture,
        transparent: true,
        side: DoubleSide,
      }),
    );
    sign.position.y = 40;
    sign.position.z = 30;
    this.$mesh = point;
    this.sign = sign;
    this.titleCanvas = titleCanvas;
    this.$object3D.add(point);
    this.$object3D.add(sign);
  },
  beforeMount() {
    this.isCSS3DSprite = true;
  },
  mounted() {
    this.drawText(this.title);

    this.size = {
      width: 500,
      height: 500,
      depth: 500,
    };
  },
  computed: {
    title() {
      return this.modules?.model?.markerContent || this.modules?.info?.title;
    },
    activeScene() {
      const router = this.router.value;
      if (!router || !router.activeSceneObjectId) return null;
      return this.$store.state.objects.instances[router.activeSceneObjectId];
    },
    // parentId(): string {
    //   return this.activeScene?.parentId;
    // },
  },
  watch: {
    title: {
      handler(newValue) {
        this.drawText(newValue);
      },
    },
    inactive: {
      immediate: true,
      handler(newValue) {
        this.$object3D.visible = !newValue;
        this.canvas.value.render('webGL', true);
      },
    },
    hover(newValue) {
      if (newValue) {
        this.$mesh.material = new MeshBasicMaterial({
          color: new Color(0x1065de),
          transparent: true,
          opacity: 1.0,
        });
      } else {
        this.$mesh.material = new MeshBasicMaterial({
          color: new Color(0x1065de),
          transparent: true,
          opacity: 0.5,
        });
      }
      this.canvas.value.render('webGL', true);
    },
  },
  methods: {
    drawText(text) {
      const titleCanvas = this.titleCanvas;
      if (!titleCanvas || !text) return;
      const context = titleCanvas.getContext('2d');
      context.clearRect(0, 0, titleCanvas.width, titleCanvas.height);
      context.textBaseline = 'top';
      context.font = '240px sans-serif';
      const metrics = context.measureText(text);
      titleCanvas.width = metrics.width;
      titleCanvas.height = metrics.actualBoundingBoxDescent;

      context.fillStyle = 'black';
      context.textBaseline = 'top';
      context.font = '240px sans-serif';

      context.fillText(text, 0, 0, titleCanvas.width);

      this.sign.geometry = new PlaneGeometry(
        titleCanvas.width / 8,
        titleCanvas.height / 8,
        1,
        1,
      );
      this.sign.material.map.needsUpdate = true;
      this.canvas.value.render('webGL', true);
    },
    gotoMarker(markedObject) {
      const segment = createExplicitSegmentFromObject(markedObject);
      if (this.parentId == markedObject.parentId) {
        this.router.value.gotoSibling(segment);
      } else {
        this.router.value.gotoChild(segment);
      }
    },
  },
  render(h) {
    return h('div', {
      style: {
        width: '60px',
        height: '60px',
        borderRadius: '100%',
        border: '5px solid transparent',
        borderColor: this.hover ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
        cursor: this.hover ? 'pointer' : 'inherit',
      },
      on: {
        mouseenter: () => (this.hover = true),
        mouseleave: () => (this.hover = false),
        // TODO: test implementation
        click: () => this.gotoMarker(this),
      },
    });
  },
};
