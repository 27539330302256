import { ModuleTypeMeta } from '../../common';

export type Tag = string;

export interface ModuleTag {
  tag: Tag;
}

export const ModuleTagMeta: ModuleTypeMeta<ModuleTag> = {
  default: {
    tag: 'text',
  },
};
