





















import Vue from 'vue';
import { useSave, useData } from '@/composables';
import { BaseModuleEditor } from '../base';

export default Vue.extend({
  name: 'ParticipantVcEditor',
  mixins: [BaseModuleEditor],
  inject: ['videoConferencingManager'],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'participantVc',
    });
    const { getData } = useData();
    return {
      getData,
      buffer,
      save,
    };
  },
  methods: {
    toggleStream(stream: string) {
      const participant = this.getData(this.id);
      if (stream == 'camera')
        this.videoConferencingManager.value.toggleCamera(participant);
      else if (stream == 'microphone')
        this.videoConferencingManager.value.toggleMicrophone(participant);
      else if (stream == 'screen')
        this.videoConferencingManager.value.toggleScreenshare(participant);
      this.save();
    },
  },
});
