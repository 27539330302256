










































import { useI18n } from '@/composables';
import { ActionControl } from '@fillip/api';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    actions: {
      type: Array as PropType<ActionControl[]>,
      required: true,
    },
    itemArgument: {
      type: [Object, Array, String, Number, Boolean],
      default: undefined,
    },
    menuTitle: {
      type: String,
      default: '',
    },
    menuSubtitle: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { maybeTr } = useI18n();

    const showAction = (action) => {
      if (action.if === undefined) return true;
      if (typeof action.if === 'function') return action.if(props.itemArgument);
      return action.if;
    };
    const disableAction = (action) => {
      if (action.disabled === undefined) return false;
      if (typeof action.disabled === 'function')
        return action.disabled(props.itemArgument);
      return action.disabled;
    };

    return {
      showAction,
      disableAction,
      maybeTr,
    };
  },
});
