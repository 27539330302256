export class RenderContext {
  constructor(name, renderEngine, canvas, scene, settings = {}) {
    if (!name || !renderEngine || !canvas || !scene) {
      throw new Error(
        'renderContext needs name, renderEngine, canvas, camera and scene - at least one is missing!',
      );
    }
    this.name = name;
    this.settings = settings;

    this.renderEngine = new renderEngine(settings);
    this.canvas = canvas;
    this.scene = scene;

    this.onRenderCallbacks = new Map();
    this.shouldRender = false;
  }

  render() {
    this.shouldRender = true;
  }

  onTick(time, deltaTime, frame) {
    if (this.onRenderCallbacks.size > 0) {
      this.onRenderCallbacks.forEach((cb) => {
        const result = cb(time, deltaTime, frame);
        if (result && !this.shouldRender) {
          this.shouldRender = true;
        }
      });
    }
    // console.log('onTick: ', this.name, this.shouldRender);
    return this.shouldRender;
  }

  doRender() {
    if (!this.camera) return;
    // console.log('doRender', this.name);

    this.renderEngine.render(this.scene, this.camera);
    this.shouldRender = false;
  }

  onRender(handle, callback) {
    this.onRenderCallbacks.set(handle, callback);
  }

  removeOnRender(handle) {
    this.onRenderCallbacks.delete(handle);
  }

  resetOnRender() {
    this.onRenderCallbacks.clear();
  }

  setSize(width, height) {
    this.renderEngine.setSize(width, height);
    this.render();
  }

  setCamera(camera) {
    if (!camera) {
      throw new Error('Invalid camera!');
    }
    this.camera = camera;
    this.render();
  }

  dispose() {
    this.resetOnRender();
    if (this.renderEngine.dispose) {
      this.renderEngine.dispose();
      this.renderEngine.forceContextLoss();
      this.renderEngine.domElement = null;
    }
  }
}
