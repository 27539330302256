



































import SaveAndCancelButtons from '@/components/form-inputs/SaveAndCancelButtons.vue';
import PasswordInput from '@/components/form-inputs/PasswordInput.vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { useFillip, useI18n, useMessage } from '@/composables';

export default defineComponent({
  name: 'PasswordForm',
  components: {
    SaveAndCancelButtons,
    PasswordInput,
  },
  props: {
    inFocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const message = useMessage();
    const { fillip, $user } = useFillip();
    const { tr } = useI18n();

    const isValid = ref(false);
    const isEditing = ref(false);
    const showRequirements = ref(false);
    const formData = ref({
      oldPassword: '',
      newPassword: '',
    });
    const form = ref(null);

    const edit = () => {
      isEditing.value = true;
      emit('focused');
    };

    const resetValidation = () => {
      return form.value.resetValidation();
    };

    const clear = () => {
      formData.value.oldPassword = '';
      formData.value.newPassword = '';
      resetValidation();
    };

    const cancel = () => {
      isEditing.value = false;
      showRequirements.value = false;
      clear();
    };

    const submit = async () => {
      if (!form.value.validate()) {
        return;
      }

      isEditing.value = false;
      showRequirements.value = false;
      await fillip.auth.updatePassword(
        formData.value.oldPassword,
        formData.value.newPassword,
      );
      message.success(tr('user.passwordChanged'));
      clear();
    };

    watch(
      () => props.inFocus,
      (inFocus) => {
        if (!inFocus && isEditing.value) {
          cancel();
        }
      },
    );

    return {
      isValid,
      isEditing,
      showRequirements,
      formData,
      edit,
      cancel,
      clear,
      resetValidation,
      form,
      submit,
      $user,
    };
  },
});
