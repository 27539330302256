var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layer overflow-y-auto",style:({ background: _vm.classes.siteBackground })},[_c('div',{staticClass:"auth-wrapper overflow-y-auto",class:_vm.classes.siteContainer},[_c('AuthSplash'),_c('main',{class:_vm.classes.siteContent},[_c('section',{class:_vm.classes.contentWrapper},[_c('div',{class:_vm.classes.contentCardContainer},[_c('div',{class:_vm.classes.contentCard},[_c('BaseTransition',{attrs:{"name":"fade"}},[_c('router-view')],1),(_vm.isLoading)?_c('BaseLayer',{attrs:{"overlay":"","opacity":0.8}},[_c('BaseIcon',{attrs:{"icon":"spinner","size":"xl","class-prop":"fa-spin"}})],1):_vm._e()],1)])]),(_vm.content.content && _vm.content.content.length)?_vm._l((_vm.content.content),function(sectionContent,index){return _c('section',{key:index,class:[
            _vm.classes.sectionWrapper,
            sectionContent.type === 'full-width'
              ? _vm.classes.contentContainer
              : _vm.classes.contentBox ]},[_c('div',{class:[
              _vm.classes.sectionContent,
              sectionContent.image
                ? 'grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8 justify-center'
                : '' ]},[_c('h2',{class:[
                _vm.classes.contentTitle,
                sectionContent.image ? 'md:order-2 md:col-span-2' : '' ]},[_vm._v(" "+_vm._s(sectionContent.title)+" ")]),_c('div',{class:[
                _vm.classes.content,
                sectionContent.image ? 'md:order-3 md:col-span-2' : '' ],domProps:{"innerHTML":_vm._s(sectionContent.text)}}),(sectionContent.image)?_c('img',{class:[
                _vm.classes.sectionImage,
                'max-w-full md:row-span-2 md:row-start-1',
                sectionContent.imagePosition === 'right'
                  ? 'md:order-4 md:col-start-3'
                  : 'md:order-1' ],attrs:{"src":sectionContent.image,"alt":""}}):_vm._e()])])}):_vm._e()],2),_c('AuthFooter')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }