












import PropertiesEditorLarge from '@/features/editor/modules/PropertiesEditorLarge.vue';
import { useVolatileData } from '@/composables';
import { useData } from '@/composables';

export default {
  name: 'ActionPropertiesManager',
  components: {
    PropertiesEditorLarge,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    vPropId: {
      type: String,
      required: true,
    },
    canvasSlot: {
      type: String,
      default: 'bottom',
    },
    name: {
      type: String,
      default: function () {
        return this.$t('action.propertiesManager.title');
      },
    },
    icon: {
      type: String,
    },
    size: {
      type: String,
    },
    color: {
      type: String,
    },
    areaIDs: { type: Array },
    vMinimized: {
      type: String,
    },
  },
  setup() {
    const { getVolatileProp } = useVolatileData();
    const { getData } = useData();
    return {
      getVolatileProp,
      getData,
    };
  },

  computed: {
    minimized() {
      return this.getVolatileProp(this.vPropId, this.vMinimized, true);
    },
    doc() {
      return this.getData(this.id);
    },
    title() {
      return this.doc.info.title;
    },
  },
};
