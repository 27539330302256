import { BaseElement } from './../core';
import DOMPurify from 'dompurify';

export const ElementHtml = {
  name: 'ElementHtml',
  mixins: [BaseElement],
  methods: {},
  computed: {
    content() {
      return DOMPurify.sanitize(this.modules.element.htmlContent || '');
    },
  },
  render(h) {
    return h('div', {
      ref: 'el',
      domProps: {
        innerHTML: this.content,
      },
    });
  },
};
