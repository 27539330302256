import { DataDocument, toolsTemplateDirectoryId } from '@fillip/api';

export const TemplateToolCollection: DataDocument = {
  id: 'tool-collection',
  info: {
    title: 'Tool: Collection',
    icon: 'comments',
  },
  tag: {
    tag: 'template',
  },
  parentId: toolsTemplateDirectoryId,
  list: {
    items: [
      {
        id: 'CollectionTitle',
      },
      {
        id: 'CollectionContribution',
      },
      {
        id: 'CollectionContributionList',
      },
      {
        id: 'CollectionThread',
      },
      {
        id: 'CollectionTitle3DWrapper',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'CollectionTitle',
        query: ':[data]',
        condition: ':($$showCollectionTitle || $$isEmpty) && !$$grid',
      },
      {
        templateId: 'CollectionTitle3DWrapper',
        query: ':[data]',
        identity: '',
        condition: ':($$showCollectionTitle || $$isEmpty) && $$grid',
      },
      {
        templateId: 'CollectionContributionList',
        query: ':[data]',
        identity: '',
        condition: '',
      },
    ],
  },
  actions: {
    actions: [
      {
        type: 'action.textfield',
        script:
          "(createDocument($$currentParent, 'text', input,$$properties), setVolatileProp($$templateParent, 'currentParent', $$collectionDocument))",
        name: 'Beitrag einsenden',
        placeholder:
          ':$$currentParent == $$collectionDocument ? "Ihr Beitrag ..." : "Ihre Antwort ..."',
        icon: 'comment-plus',
        color: 'rgba(117, 117, 117, 1)',
        focused: 'always',
        condition: ":!hasRole($roles)('camera')($me)",
      },
      {
        name: 'Bearbeiten',
        type: 'action.textfield',
        script:
          "updateDocumentTitle($$contributionToEdit, input); setVolatileProp($$templateParent, 'contributionToEdit', '');",
        id: 'editContribution',
        color: 'rgba(117, 117, 117,1)',
        icon: 'pencil',
        slot: 'bottom',
        focused: 'always',
        condition: ":$$contributionToEdit != ''",
        docId: ':$$contributionToEdit',
      },
    ],
  },
  variables: {
    variables: [
      {
        identifier: 'isEmpty',
        query: ':getList($$collectionDocument)().length == 0',
      },
      {
        identifier: 'toolsActiveFor',
        query: ": getVolatileProp($$templateParent, 'showTools', '')",
      },
      {
        identifier: 'allowHighlight',
        query: ':false',
      },
      {
        identifier: 'properties',
        query:
          ":!$$randomColors ? {}: { properties: {color: {type: 'string', value: $$brandColors[_.random(0,4)]}}} ",
      },
    ],
  },
  class: {
    elementClass: '',
    class:
      ":!$$grid ? ' w-full h-full p-2 overflow-y-auto overflow-x-hidden' : ''",
  },
  arrangement: {
    type: 'conditional',
    variants: [
      {
        condition: ':$$showCollectionTitle && $$grid',
        value: {
          type: 'arrangement.grid',
          gridColumns: '1fr',
          gridRows: '100px 1fr',
        },
      },
      {
        condition: ':!!$$grid',
        value: {
          type: 'arrangement.absolute',
        },
      },
      {
        condition: '',
        value: {
          type: 'arrangement.origin',
        },
      },
    ],
  },
};

export const TemplateToolCollectionChildren: DataDocument[] = [
  {
    id: 'CollectionTitle',
    info: {
      title: 'Title',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-collection',
    element: {
      type: 'element.text',
      textContent: ':$$toolTitle',
      textFormat: 'h2',
    },
    class: {
      elementClass: 'leading-none',
      class:
        'mb-4 m-4 p-4 blurred-glass rounded-lg grid align-center text-xl font-bold text-primary',
    },
  },
  {
    id: 'CollectionContribution',
    info: {
      title: 'Collection: Beitrag',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-collection',
    list: {
      items: [
        {
          id: 'CollectionContributionContent',
        },
        {
          id: 'CollectionAnswerButton',
        },
        {
          id: 'CollectionAdminButtons',
        },
        {
          id: 'CollectionAvatar',
        },
        {
          id: 'CollectionAnsweredCheckbox',
        },
        {
          id: 'CollectionUpvoteButton',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'CollectionAnsweredCheckbox',
          query: ':[data]',
          identity: '',
          condition: ':$$allowAnswered',
        },
        {
          templateId: 'CollectionAvatar',
          query: ':[data]',
          identity: '',
          condition: ':$$showAvatar',
        },
        {
          templateId: 'CollectionContributionContent',
          query: ':[data]',
        },
        {
          templateId: 'CollectionAnswerButton',
          query: ':[data]',
          identity: '',
          condition: ':$$allowReplies',
        },
        {
          templateId: 'CollectionUpvoteButton',
          query: ':[data]',
          identity: '',
          condition: ':$$allowUpvotes',
        },
        {
          templateId: 'CollectionAdminButtons',
          query: ':[data]',
          condition: ':$$userCanEdit && $$toolsActiveFor == data.id',
          identity: '',
        },
      ],
    },
    class: {
      elementClass: '',
      class:
        ":`${$$randomColors ? 'bg-'+data.properties.color.value:''} ${$$grid ? 'p-4 rounded-xl  w-full h-full shadow-md relative grid place-content-center text-center' :'p-4 mb-2 rounded-lg shadow-md flex flex-row  relative  bg-white'} ${$$isOwnPost ? 'bg-primary-lightest' : ''} ${$$isHighlighted ? 'bg-accent-light' : ''} ${$$isPinned ? 'bg-warning-light text-lg shadow-xl' : ''} ${$$isAnswered ? 'bg-gray-200 opacity-60' : ''} ${$$isHidden ? 'bg-gray-100 opacity-25' : ''} ${$$chatStyle ? 'flex-grow-0' : 'w-full'} ${$$chatStyle ? $$isOwnPost ? 'place-self-end text-right' : 'bg-gray-200 self-start text-left' : ''} ${$$isActiveParent ? 'primary opacity-85' : ''}`",
    },
    listener: {
      listeners: {
        click: {
          script:
            "$$userCanEdit ?setVolatileProp($$templateParent, 'showTools', $$toolsActiveFor == data.id ? '' : data.id) : ''",
          context: {},
        },
      },
    },
    variables: {
      variables: [
        {
          identifier: 'isOwnPost',
          query: ': info.createdBy == $me.id',
        },
        {
          identifier: 'userCanEdit',
          query: ": hasRole($roles)('host')($me) || $$isOwnPost",
        },
        {
          identifier: 'isHighlighted',
          query: ": getPropFrom('isHighlighted')(data.id) == true",
        },
        {
          identifier: 'isPinned',
          query: ": getPropFrom('isPinned')(data.id) == true",
        },
        {
          identifier: 'isAnswered',
          query: ": getPropFrom('isAnswered')(data.id) == true",
        },
        {
          identifier: 'isHidden',
          query: ": getPropFrom('isHidden')(data.id) == true",
        },
        {
          identifier: 'upvotedByMe',
          query:
            ": pipe(getList(self.data.id), where(matchesProperty('info.createdBy', $me.id), matchesProperty('tag.tag', 'document')))().length > 0",
        },
        {
          identifier: 'numberOfUpvotes',
          query:
            ": pipe(getList(self.data.id), where(matchesProperty('tag.tag', 'document')))().length",
        },
        {
          identifier: 'isActiveParent',
          query:
            ":getVolatileProp($$templateParent, 'currentParent') == data.id",
        },
      ],
    },
  },
  {
    id: 'CollectionContributionContent',
    info: {
      title: 'Collection: Contribution Content',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionContribution',
    class: {
      elementClass: '',
      class: 'flex-grow break-words',
    },
    list: {
      items: [
        {
          id: 'CollectionContributionText',
        },
        {
          id: 'CollectionContributionMeta',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'CollectionContributionText',
          query: ':[data]',
        },
        {
          templateId: 'CollectionContributionMeta',
          query: ':[data]',
          condition: ':$$showAuthor || $$showTime',
        },
      ],
    },
  },
  {
    id: 'CollectionContributionText',
    info: {
      title: 'Collection: Contribution Text',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionContributionContent',
    element: {
      type: 'element.text',
      textContent: ':info.title',
      textFormat: 'p',
    },
    class: {
      class: ":`text-lg break-all ${$$grid ? 'text-white' : ''}`",
      elementClass: '',
    },
  },
  {
    id: 'CollectionContributionMeta',
    info: {
      title: 'Collection: Contribution Meta',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionContributionContent',
    class: {
      elementClass: '',
      class:
        ":`${$$chatStyle && $$isOwnPost ? ' justify-end':''} text-gray-500 text-sm mt-1 align-center flex flex-row`",
    },
    list: {
      items: [
        {
          id: 'CollectionAuthor',
        },
        {
          id: 'CollectionCreatedAt',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'CollectionAuthor',
          query: ':[data]',
          condition: ':$$showAuthor',
        },
        {
          templateId: 'CollectionCreatedAt',
          query: ':[data]',
          identity: '',
          condition: ':$$showTime',
        },
      ],
    },
  },
  {
    id: 'CollectionAuthor',
    info: {
      title: 'Collection: Autor',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionContributionMeta',
    element: {
      type: 'element.text',
      textContent: ':`Von ${get(info.createdBy)()[0].info.title}`',
      textFormat: 'p',
    },
    class: {
      class: 'flex flex-initial',
      elementClass: '',
    },
  },
  {
    id: 'CollectionCreatedAt',
    info: {
      title: 'Collection: Created At',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionContributionMeta',
    element: {
      type: 'element.text',
      textContent: ':`${dayjs(info.createdAt).fromNow()}`',
      textFormat: 'p',
    },
    class: {
      class: 'flex flex-initial  ml-1',
      elementClass: '',
    },
  },
  {
    id: 'CollectionAnswerButton',
    info: {
      title: 'Collection: Answer Button',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionContribution',
    element: {
      type: 'element.button',
      buttonText: '',
      buttonIcon: 'reply',
      buttonEvent: 'reply',
    },
    listener: {
      listeners: {
        reply: {
          script: "setVolatileProp($$templateParent, 'currentParent', data.id)",
          context: {},
        },
      },
    },
    class: {
      elementClass: '',
      class: 'ml-2 flex-grow-0',
    },
  },
  {
    id: 'CollectionAdminButtons',
    info: {
      title: 'Collection: Admin Buttons',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionContribution',
    list: {
      items: [
        {
          id: 'CollectionAdminButtonContainer',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'CollectionAdminButtonContainer',
          query: ':[data]',
        },
      ],
    },
    class: {
      class:
        'absolute inset-y-1/2 transform -translate-y-1/2 left-5 right-5 bg-opacity-75 bg-gray-600 rounded-xl h-12 z-20',
      elementClass: 'text-gray-200',
    },
    style: {
      background: 'rgba(150,150,150',
    },
  },
  {
    id: 'CollectionAdminButtonContainer',
    info: {
      title: 'Collection: Admin Button Container',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionAdminButtons',
    list: {
      items: [
        {
          id: 'CollectionHideButton',
        },
        {
          id: 'CollectionEditButton',
        },
        {
          id: 'CollectionHighlightButton',
        },
        {
          id: 'CollectionPinButton',
        },
        {
          id: 'CollectionDeleteButton',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'CollectionEditButton',
          query: ':[data]',
        },
        {
          templateId: 'CollectionHighlightButton',
          query: ':[data]',
          condition: ':$$allowHighlight && $$isHost',
        },
        {
          templateId: 'CollectionPinButton',
          query: ':[data]',
          condition: ':$$allowPinning && $$isHost',
        },
        {
          templateId: 'CollectionHideButton',
          query: ':[data]',
          condition: ':$$isHost',
        },
        {
          templateId: 'CollectionDeleteButton',
          query: ':[data]',
          identity: '',
        },
      ],
    },
    class: {
      class:
        'grid grid-flow-col items-center items-center justify-items-stretch h-full ',
      elementClass: '',
    },
    listener: {
      listeners: {
        mouseenter: {
          script: "setVolatileProp($$templateParent, 'showTools', data.id)",
          context: {},
        },
        mouseleave: {
          script: "setVolatileProp($$templateParent, 'showTools', '')",
          context: {},
        },
      },
    },
  },
  {
    id: 'CollectionHideButton',
    info: {
      title: 'Collection: Hide Button',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionAdminButtonContainer',
    class: {
      class:
        ": ` flex flex-row grid-rows-1 justify-center items-center items-center cursor-pointer w-full h-full  text-center hover:bg-gray-800 hover:bg-opacity-50 ${$$isHidden ? 'bg-gray-900  bg-opacity-75' : ''}`",
      elementClass: 'h-full group-hover:text-white text-gray-200',
    },
    element: {
      type: 'element.icon',
      icon: 'eye-slash',
      size: 'medium',
      color: 'rgba(1, 1, 1, 0.54)',
    },
    listener: {
      listeners: {
        click: {
          script:
            "updateProperties(data.id, { isHidden: {type: 'boolean', value: data.properties.isHidden && data.properties.isHidden.value ? false : true}})",
          context: {},
        },
      },
    },
  },
  {
    id: 'CollectionEditButton',
    info: {
      title: 'Collection: Edit Button',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionAdminButtonContainer',
    class: {
      class:
        ': `flex flex-row grid-rows-1 justify-center items-center items-center rounded-l-full cursor-pointer w-full h-full  text-center hover:bg-gray-800 hover:bg-opacity-50`',
      elementClass: 'h-full text-gray-200 group-hover:text-white',
    },
    element: {
      type: 'element.icon',
      icon: 'edit',
      size: 'medium',
      color: 'rgb(255, 255, 255)',
    },
    listener: {
      listeners: {
        click: {
          script:
            "setVolatileProp($$templateParent, 'contributionToEdit', data.id)",
          context: {},
        },
      },
    },
  },
  {
    id: 'CollectionHighlightButton',
    info: {
      title: 'Collection: Highlight Button',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionAdminButtonContainer',
    class: {
      class:
        ": `flex flex-row grid-rows-1 justify-center items-center items-center cursor-pointer w-full h-full  text-center hover:bg-gray-800 hover:bg-opacity-50 ${$$isHighlighted ? 'bg-gray-900  bg-opacity-75' : ''}`",
      elementClass: 'h-full text-gray-200 ',
    },
    element: {
      type: 'element.icon',
      icon: 'highlighter',
      size: 'medium',
      color: 'rgba(1, 1, 1, 0.54)',
    },
    listener: {
      listeners: {
        click: {
          script:
            "updateProperties(data.id, { isHighlighted: {type: 'boolean', value: !$$isHighlighted}});\nsetVolatileProp($$templateParent, 'showTools', '');",
          context: {},
        },
      },
    },
  },
  {
    id: 'CollectionPinButton',
    info: {
      title: 'Collection: Pin Button',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionAdminButtonContainer',
    class: {
      class:
        ": `flex flex-row grid-rows-1 justify-center items-center items-center cursor-pointer w-full h-full  text-center hover:bg-gray-800 hover:bg-opacity-50 ${$$isPinned ? 'bg-gray-900  bg-opacity-75' : ''}`",
      elementClass: 'h-full group-hover:text-white text-gray-200',
    },
    element: {
      type: 'element.icon',
      icon: 'thumbtack',
      size: 'medium',
      color: 'rgba(1, 1, 1, 0.54)',
    },
    listener: {
      listeners: {
        click: {
          script:
            "updateProperties(data.id, { isPinned: {type: 'boolean', value: !$$isPinned}});\nsetVolatileProp($$templateParent, 'showTools', '');",
          context: {},
        },
      },
    },
  },
  {
    id: 'CollectionDeleteButton',
    info: {
      title: 'Collection: Delete Button',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionAdminButtonContainer',
    class: {
      class:
        ': `flex flex-row grid-rows-1 justify-center items-center items-center rounded-r-full cursor-pointer w-full h-full  text-center hover:bg-gray-800 hover:bg-opacity-50`',
      elementClass: 'h-full group-hover:text-white text-gray-200',
    },
    element: {
      type: 'element.icon',
      icon: 'trash',
      size: 'medium',
      color: 'rgba(1, 1, 1, 0.54)',
    },
    listener: {
      listeners: {
        click: {
          script: 'removeDocument(data.id)',
          context: {},
        },
      },
    },
  },
  {
    id: 'CollectionAvatar',
    info: {
      title: 'Collection: Avatar',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionContribution',
    element: {
      type: 'element.image',
      imageSrc: ':getAvatarUrl(getDoc(info.createdBy))',
      imageDescription: '',
      imageCopyright: '',
    },
    class: {
      class:
        ":` rounded-full h-12 w-12  flex flex-grow-0 flex-shrink-0 items-center content-center border border-solid ${$$chatStyle && $$isOwnPost ? 'order-3 ml-2': 'mr-2'}  ${$$grid ?'justify-self-center' :'justify-center'} `",
      elementClass: 'rounded-full h-12 w-12 object-cover',
    },
  },
  {
    id: 'CollectionAnsweredCheckbox',
    info: {
      title: 'Collection: Answered Checkbox',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionContribution',
    class: {
      class:
        ":`${$$grid ? 'absolute top-6 right-6': 'flex-grow-0 flex-shrink-0 w-8 h-10 mr-2 flex items-center'} ${properties.isAnswered && properties.isAnswered.value ? 'text-success' : ''} ${$$isHost ? 'cursor-pointer' : '' } group`",
      elementClass: '',
    },
    list: {
      items: [
        {
          id: 'CollectionIsAnsweredIcon',
        },
        {
          id: 'CollectionIsNotAnsweredIcon',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'CollectionIsAnsweredIcon',
          query: ':[data]',
          condition: ':$$isAnswered',
        },
        {
          templateId: 'CollectionIsNotAnsweredIcon',
          query: ':[data]',
          identity: '',
          condition: ':!$$isAnswered',
        },
      ],
    },
    listener: {
      listeners: {
        click: {
          script:
            "$$isHost ? (updateProperties(data.id, { isAnswered: {type: 'boolean', value: !$$isAnswered}}),\nsetVolatileProp($$templateParent, 'showTools', '')) : ''",
          context: {},
        },
      },
    },
  },
  {
    id: 'CollectionIsAnsweredIcon',
    info: {
      title: 'Collection: Is Answered Icon',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionAnsweredCheckbox',
    element: {
      type: 'element.icon',
      icon: 'comment-alt-check',
      size: 'large',
      color: 'rgba(1, 1, 1, 0.54)',
    },
    class: {
      class: "`${$$isHost ? 'cursor-pointer' : '' }`",
      elementClass:
        ":`text-primary ${$$isHost ? 'group-hover:text-warning' : '' }`",
    },
  },
  {
    id: 'CollectionIsNotAnsweredIcon',
    info: {
      title: 'Collection: Is Not Answered Icon',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionAnsweredCheckbox',
    element: {
      type: 'element.icon',
      icon: 'comment-alt',
      size: 'large',
      color: 'rgba(1, 1, 1, 0.54)',
    },
    class: {
      class: ":`${$$isHost ? 'cursor-pointer' : '' }`",
      elementClass: ":`${$$isHost ? 'group-hover:text-primary' : '' }`",
    },
  },
  {
    id: 'CollectionUpvoteButton',
    info: {
      title: 'Collection: Upvote Button',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionContribution',
    class: {
      class: 'ml-2 flex-grow-0',
      elementClass: ":`${$$upvotedByMe ? 'bg-primary text-white' : ''}`",
    },
    listener: {
      listeners: {
        upvote: {
          script:
            "$$upvotedByMe ? removeDocument(pipe(getList(self.data.id), where(matchesProperty('info.createdBy', self.context.$me.id), matchesProperty('tag.tag', 'document')))()[0].id) : createDocument(self.data.id, 'document', 'Upvote')",
          context: {},
        },
      },
    },
    element: {
      type: 'element.button',
      buttonText: ':$$numberOfUpvotes',
      buttonIcon: 'thumbs-up',
      buttonEvent: 'upvote',
    },
  },
  {
    id: 'CollectionContributionList',
    info: {
      title: 'Collection: List',
    },
    tag: {
      tag: 'template',
    },
    class: {
      class:
        ":` flex-grow ${$$grid ? ' p-8 h-full w-full grid grid-cols-3 gap-4 align-start' :''} ${$$chatStyle && !$$isEmpty ? 'grid gap-0 grid-cols-1' : 'grid gap-0 grid-cols align-stretch'} `",
      elementClass: '',
    },
    parentId: 'tool-collection',
    children: {
      default: [
        {
          templateId: 'CollectionThread',
          query: ':$$pinnedQuery',
          condition: ':$$allowPinning',
          identity: '',
        },
        {
          templateId: 'CollectionThread',
          query: ':$$contributionQuery',
          condition: '',
          identity: '',
        },
        {
          templateId: 'CollectionThread',
          query: ':$$answeredQuery',
          condition: ':$$allowAnswered',
          identity: '',
        },
        {
          templateId: 'CollectionThread',
          query: ':$$hiddenQuery',
          condition: ':$$isHost',
          identity: '',
        },
        {
          templateId: 'empty-state-cover',
          query: ':[data]',
          condition: ':$$isEmpty',
          identity: '',
        },
      ],
    },
    variables: {
      variables: [
        {
          identifier: 'contributionQuery',
          query:
            ":getList($$collectionDocument) |> where(\ncheckProp('isHidden', false), checkProp('isPinned', false),  checkProp('isAnswered', false)\n) |> sortBy('info.createdAt', 'desc') |> take(30)",
        },
        {
          identifier: 'pinnedQuery',
          query:
            ":getList($$collectionDocument) |> where(\n checkProp('isPinned', true), checkProp('isHidden', false),\n) |> sortBy('info.createdAt', 'desc')",
        },
        {
          identifier: 'hiddenQuery',
          query:
            ":getList($$collectionDocument) |> where(\n checkProp('isHidden', true)\n) |> sortBy('info.createdAt', 'desc')",
        },
        {
          identifier: 'answeredQuery',
          query:
            ":getList($$collectionDocument) |> where(\n checkProp('isAnswered', true), checkProp('isHidden', false),\n checkProp('isPinned', false)\n) |> sortBy('info.createdAt', 'desc')",
        },
      ],
    },
    arrangement: {
      type: 'conditional',
      variants: [
        {
          condition: ':!!$$grid',
          value: {
            type: 'arrangement.divide',
            divideAspectRatio: '4/3',
            divideStretch: false,
          },
        },
        {
          condition: '',
          value: {
            type: 'arrangement.absolute',
          },
        },
      ],
    },
    sheet: {
      scriptedValue: true,
      orientTowardsCamera: false,
      value: ':!!$$grid',
    },
  },
  {
    id: 'CollectionThread',
    info: {
      title: 'Collection: Thread',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-collection',
    children: {
      default: [
        {
          templateId: 'CollectionContribution',
          query: ':[data]',
        },
        {
          templateId: 'CollectionContributionContainer',
          query: ':[data]',
        },
      ],
    },
    list: {
      items: [
        {
          id: 'CollectionContributionContainer',
        },
      ],
    },
    sheet: {
      orientTowardsCamera: false,
      scriptedValue: true,
      value: ':!!$$grid',
    },
    class: {
      class:
        ":!!$$grid ? ' w-full h-full p-1' :'grid grid-cols-1  justify-items-start '",
      elementClass: '',
    },
  },
  {
    id: 'CollectionContributionContainer',
    info: {
      title: 'Collection: Thread Container',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'CollectionThread',
    children: {
      default: [
        {
          templateId: 'CollectionThread',
          query:
            ":getList(id) |> where( matchesProperty('tag.tag', 'text'), checkProp('isHidden', false))",
        },
      ],
    },
    class: {
      class: ":`${$$grid ? 'p-2' :!$$chatStyle ? 'pl-4 w-full':'w-full'}`",
      elementClass: 'bg-primary',
    },
    variables: {
      variables: [
        {
          identifier: 'allowPin',
          query: ':false',
        },
      ],
    },
  },
  {
    id: 'CollectionTitle3DWrapper',
    info: {
      title: 'Title: 3D Wrapper',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-collection',
    children: {
      default: [
        {
          templateId: 'CollectionTitle',
          query: ':[data]',
          condition: '',
          identity: '',
        },
      ],
    },
    sheet: {
      scriptedValue: false,
      orientTowardsCamera: false,
      value: ':true',
    },
    class: {
      class: 'h-full w-full',
      elementClass: '',
    },
  },
];
