import { tw } from 'twind';

const classStringToArray = (classes: string | string[] = []) => {
  return Array.isArray(classes)
    ? classes
    : (classes || '').split(' ').filter(Boolean);
};

export const addClasses = (el: HTMLElement, classes: string | string[]) => {
  classes = classStringToArray(classes);
  if (!classes.length) return;
  tw(...classes);
  el.classList.add(...classes);
};

export const removeClasses = (el: HTMLElement, classes: string | string[]) => {
  classes = classStringToArray(classes);
  if (!classes.length) return;
  el.classList.remove(...classes);
};

export const patchClasses = (el: HTMLElement, prev: string, next: string) => {
  if (prev === next) return;
  removeClasses(el, prev);
  addClasses(el, next);
};
