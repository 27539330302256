export * from './Object3DAnimator';
export * from './Transform3D';

import { isObject } from 'lodash/fp';
import { type TransitionOptions, type Transition, transitions } from '@fillip/api';

export function resolveTransition(transition: TransitionOptions): Transition {
  if (transition === true) return transitions['default'];
  if (transition === false) return transitions['none'];
  if (typeof transition === 'string' && transition in transitions)
    return transitions[transition];
  if (isObject(transition) && 'to' in transition) return transition;

  return null;
}

// export function resolveTransitionDuration(
//   transition: TransitionOptions,
//   defaultDuration: number,
// ): number {
//   const resolvedTransition = resolveTransition(transition);
//   return resolvedTransition ? resolvedTransition.duration : defaultDuration;
// }

// export function resolveTransitionEasing(
//   transition: TransitionOptions,
//   defaultEasing: string,
// ): string {
//   const resolvedTransition = resolveTransition(transition);
//   return resolvedTransition ? resolvedTransition.easing : defaultEasing;
// }

// export function resolveTransitionDelay(
//   transition: TransitionOptions,
//   defaultDelay: number,
// ): number {
//   const resolvedTransition = resolveTransition(transition);
//   return resolvedTransition ? resolvedTransition.delay : defaultDelay;
// }

// export function resolveTransitionStagger(
//   transition: TransitionOptions,
//   defaultStagger: number,
// ): number {
//   const resolvedTransition = resolveTransition(transition);
//   return resolvedTransition ? resolvedTransition.stagger : defaultStagger;
// }

// export function resolveTransitionOrder(
//   transition: TransitionOptions,
//   defaultOrder: number,
// ): number {
//   const resolvedTransition = resolveTransition(transition);
//   return resolvedTransition ? resolvedTransition.order : defaultOrder;
// }

// export function resolveTransitionProps(
//   transition: TransitionOptions,
//   defaultProps: string[],
// ): string[] {
//   const resolvedTransition = resolveTransition(transition);
//   return resolvedTransition ? resolvedTransition.props : defaultProps;
// }

// export function resolveTransitionPropsFilter(
//   transition: TransitionOptions,
//   defaultFilter: string[],
// ): string[] {
//   const resolvedTransition = resolveTransition(transition);
//   return resolvedTransition ? resolvedTransition.filter : defaultFilter;
// }