export default {
  general: {
    hello: 'Hallo',
    close: 'Schließen',
    cancel: 'Abbrechen',
    or: 'oder',
    saveChanges: 'Speichern',
    pleaseSelect: 'Bitte wählen Sie aus',
    back: 'Zurück',
    expand: 'Erweitern',
    collapse: 'Reduzieren',
    more: 'Mehr',
    reload: 'Neu laden',
    upload: 'Hochladen',
    done: 'Beenden',
    submit: 'Senden',
    untitled: 'Ohne Titel',
    search: 'Suche',
    reset: 'Zurücksetzen',
    create: 'erstellen',
    displayName: 'Öffentlicher Name oder Pseudonym',
    error: {
      unknown: 'Unbekannter Fehler. Bitte wenden Sie sich an Ihren Admin.',
      typeError:
        'Eingabefehler: Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
      networkError:
        'Netzwerkfehler: Es konnte keine Verbindung zum Server hergestellt werden.',
      pleaseCheckYourConnection:
        'Es scheint, als ob Sie keine Verbindung zum Internet haben. Bitte stellen Sie eine Internetverbindung her und probieren Sie es erneut.',
      reconnectionFailed:
        'Es konnte keine Verbindung hergestellt werden. Bitte laden Sie die Seite neu.',
      noActiveCommunities:
        'Ihr Benutzerkonto hat keine aktiven Communities. Bitte wenden Sie sich an Ihren Admin, wenn Sie einer Community beitreten möchten.',
    },
    validation: {
      isRequired: 'Bitte tragen Sie etwas ein!',
      classValidator:
        'Ihre Eingabe erfüllt nicht alle Anforderungen. Bitte überprüfen Sie Ihre Eingabe anhand der Anforderungen, die an den Eingabefeldern angezeigt werden.',
    },
  },
  communities: {
    welcome: 'Willkommen bei fillip',
    selectCommunity: 'Wählen Sie eine Community!',
    selectOrCreateCommunity: 'Wählen oder erstellen Sie eine Community!',
    open: 'Öffnen',
    createdCommunities: 'Von Ihnen erstellte Communities',
    joinedCommunities: 'Ihre Communities',
    publicCommunities: 'Öffentliche Communities',
    delete: 'Löschen',
    communityDeleted: 'Community "{title}" gelöscht',
    switchCommunity: 'Community wechseln',
    createNewCommunity: 'Neue Community erstellen',
    joinBySlug: 'Mit Code beitreten',
    showMore: 'Zeige mehr',
    showLess: 'Zeige weniger',
    createFirstCommunity:
      'Sie haben noch keine Community erstellt. Erstellen Sie Ihre erste Community',
    loginToCreate:
      'Bitte loggen Sie sich ein, um Communities erstellen zu können',
    joinFirstCommunity: 'Sie sind noch keiner Community beigetreten.',
    createCommunity: {
      createCommunity: 'Erstellen Sie Ihre Community',
      preview: 'Vorschau',
      upload: 'Hochladen',
      name: 'Name der Community',
      slug: 'Slug',
      subtitle: 'Untertitel',
      description: 'Beschreibung',
      placeholderName: 'Geniale Fillip Community',
      placeholderSlug: 'geniale-community-slug',
      placeholderSubtitle: 'Genialer Untertitel',
      placeholderDescription: 'Geniale Beschreibung',
      backToHub: 'Zurück zum Community-Hub',
      access: 'Access',
      public: 'Öffentlich zugänglich',
      private: 'Private Community',
      status: 'Status',
      language: 'Sprache',
      shell: 'Shell',
    },
    editCommunity: 'Community bearbeiten',
    communityEditor: 'Community-Editor',
    error: {
      slugAlreadyExists: 'Dieser Community-Code existiert bereits',
      notAuthorizedToEdit:
        'Sie sind nicht authorisiert die Veranstaltungen anderer Benutzer zu bearbeiten',
      notAuthorizedToDelete: 'Sie können nur Ihre eigenen Communities löschen',
      noCommunityFoundForSlug:
        'Es tut uns leid, wir konnten keine Community unter dem Code "{argument}" finden. Bitte prüfen Sie Ihre Eingabe und versuchen Sie es erneut.',
      missingSlug:
        'Bitte geben Sie einen gültigen Community-Code ein, um eine Community zu betreten.',
      loggedInUserRequired:
        'Sie müssen angemeldet sein, um dieser Community beizutreten.',
      couldNotCreate:
        'Neue Community "{argument}" konnte nicht erstellt werden. Bitte versuchen Sie es erneut.',
      couldNotFindAll: 'Communities konnten nicht geladen werden.',
      titleNotEmpty: 'Bitte geben Sie einen Namen an',
      accessNotEmpty:
        'Bitte geben Sie an ob die Community privat oder öffentlich sein soll',
      titleTooLong:
        'Der Name einer Community kann aus maximal {argument} Zeichen bestehen.',
      couldNotUpdate:
        'Die Community mit dem Slug "{argument}" konnte nicht geupdated werden. Bitte versuchen Sie es erneut.',
    },
  },
  profile: {
    yourProfile: 'Ihr Profil',
    displayNameRequired: 'Benutzername ist erforderlich.',
    updated: 'Ihre Profilinformationen wurden erfolgreich aktualisiert.',
    memberProfile: 'Profil',
    displayName: 'Benutzername',
    description: 'Selbstbeschreibung',
    personalColor: 'Persönliche Farbe',
  },
  participants: {
    participants: 'Teilnehmer:innen',
    all: 'Alle',
    online: 'Online',
    circle: 'Circle',
    scene: 'In der Nähe',
    invite: 'Einladen',
    status: 'Status',
    location: 'Position',
    noLocation: 'Keine Position',
    profile: 'Profil',
  },
  circles: {
    circles: 'Circles',
    members: 'Mitglieder',
    takeLead: 'Leitung übernehmen',
    stopLead: 'Leitung beenden',
    gather: 'Versammeln',
    leader: 'Leiter:in',
    noLeader: 'Keine Leitung',
    rejoin: 'Beitreten',
    sync: 'Sync',
    stopSync: 'Sync stoppen',
    addCircle: 'Circle hinuzfügen',
  },
  worldNavigation: {
    title: 'Navigation',
    noLocation: 'kein Standort',
    linkOverview: 'Navigationslinks',
    noLinks: 'keine Navigationslinks verfügbar',
    focus: 'Fokussieren',
    unfocus: 'Fokus verlassen',
    back: 'Zurück',
  },
  icon: {
    default: 'Icon wählen',
    noIcon: 'Kein Icon gefunden',
  },
  explorer: {
    import: 'Import',
    export: 'Export',
    open: 'Öffnen',
    remove: 'Entfernen',
    clearChildren: 'Kinder löschen',
    edit: 'Bearbeiten',
    editJson: 'Als JSON bearbeiten',
    editDocument: 'Document-Editor',
    close: 'Schließen',
    duplicate: 'Duplizieren',
    copy: 'Kopieren',
    paste: 'Einfügen',
    cut: 'Ausschneiden',
    exportHelp:
      'Markieren Sie den gesamten Text und kopieren Sie ihn in den Export',
    importHelp: 'Inhalt zum importieren einfügen',
    goto: 'Hierhin springen',
    runMigrations: 'Starte Migrationen {current} bis {latest}',
  },
  templateClipboard: {
    title: 'Objekt-Zwischenablage',
    clear: 'Leeren',
    target: 'Ziel',
    copiedToClipboard: 'In die Zwischenablage kopiert',
    importSuccessful: 'Erfolgreich importiert',
    documentUpdated: 'Dokument aktualisiert',
  },
  editing: {
    edit: 'Bearbeiten',
    delete: 'Löschen',
    tool: 'Werkzeug',
    tools: 'Werkzeuge',
    addTool: 'Werkzeug hinzufügen',
    editTool: 'Werkzeug bearbeiten',
    hideTools: 'Werkzeuge verbergen',
    createTool: 'Leeres Werkzeug erstellen',
    onlyVideoconference: 'Nur Videokonferenz',
    workspaceTools: 'Werkzeuge',
    activateTool: 'Aktivieren',
    createAndActivate: 'Erstellen und aktivieren',
  },
  audios: {
    error: {
      uploadFetchFailed:
        'Das Audio konnte nicht hochgeladen werden. Bitte versuchen Sie es erneut.',
      notAuthorizedToDelete:
        'Löschen fehlgeschlagen. Sie können nur eigene Audiodateien entfernen.',
    },
  },
  media: {
    error: {
      uploadFetchFailed:
        'Die Datei konnte nicht hochgeladen werden. Bitte versuchen Sie es erneut.',
      notAuthorizedToDelete:
        'Löschen fehlgeschlagen. Sie können nur eigene Dateien entfernen.',
    },
  },
  userAccount: {
    userAccount: 'Benutzerkonto',
    accountSettings: 'Bearbeiten',
    profiles: 'Profile',
    editProfile: 'Profil bearbeiten',
    editAccount: 'Benutzerkonto bearbeiten',
  },
  branding: {
    primaryColor: 'Grundfarbe',
    primaryContrastColor: 'primäre Kontrastfarbe',
    accentColor: 'Akzentfarbe',
    errorColor: 'Fehlerfarbe',
    successColor: 'Erfolgsfarbe',
    infoColor: 'Infofarbe',
    backgroundColor: 'Hintergrundfarbe',
    thumbnailStyle: 'Vorschaubild-Stil',
    tile: 'Eckig',
    rounded: 'Abgerundet',
    colors: 'Farben',
    Optionen: 'Optionen',
  },
  participantStatus: {
    setStatus: 'Status festlegen',
    chooseYourStatus: 'Ändern Sie Ihren Status',
    beRightBack: 'Bin gleich zurück',
    busy: 'Beschäftigt',
    needHelp: 'Ich brauche Hilfe!',
    offline: 'Offline',
    online: 'Online',
    away: 'Abwesend',
  },
  videoConference: {
    toggleMicrophone: 'Mikrofon an/aus',
    toggleCamera: 'Kamera an/aus',
    screenshare: 'Bildschirm freigeben',
    screenshareOff: 'Bildschirmfreigabe beenden',
    enterFullscreen: 'Vollbild',
    exitFullscreen: 'Vollbild beenden',
    selectYourDevices: 'Geräte auswählen',
    status: 'Status',
    camera: 'Kamera',
    microphone: 'Mikrofon',
    cameras: 'Kameras',
    microphones: 'Mikrofone',
    speaker: 'Lautsprecher',
    applyChanges: 'Einstellungen speichern',
    permissionDenied:
      'Zugriff verweigert! Bitte prüfen Sie die Browsereinstellungen.',
    checkBrowserPermissions:
      'Zugriff verweigert! Bitte erlauben Sie in den Browsereinstellungen, dass Fillip auf Ihre Kamera, Mikrofone und Lautsprecher zuzugreifen darf.',
    currentlyUsed:
      'Ihre Kamera wird derzeit in einem anderen Programm verwendet.',
    micPermissionDenied:
      'Bitte erlauben Sie in den Browsereinstellungen, dass Fillip auf Ihre Mirkofone und Lautsprecher zugreifen darf, um zwischen Ihren angeschlossenen Geräten auszuwählen.',
    muteVideoconference: 'Ton ausschalten',
    unmuteVideoconference: 'Ton anschalten',
    startVideo: 'Für alle einschalten',
    stopVideo: 'Für andere verbergen',
    muteMicrophone: 'Mikro ausschalten',
    unmuteMicrophone: 'Mikro anschalten',
    upscaleVideoResolution: 'Auflösung meiner Kamera erhöhen',
    downscaleVideoResolution: 'Auflösung meiner Kamera verringern',
    shortcuts: {
      muteVideoconference: 'Shift + Alt + M',
      toggleAudio: 'Shift + Alt + A',
      toggleVideo: 'Shift + Alt + V',
      toggleScreen: 'Shift + Alt + S',
    },
  },
  fileCollections: {
    error: {
      couldNotFindAllCollections: 'Collections konnten nicht gefunden werden',
      couldNotFindCollectionGeneric:
        'Die angefragte Collection konnte nicht gefunden werden',
      couldNotFindCollection:
        'Es konnte keine Collection unter "{argument}" gefunden werden',
      couldNotCreateCollection:
        'Die neue Collection "{argument}" konnte nicht erstellt werden',
      unauthorizedToEditCollection:
        'Sie sind nicht authorisiert, die Collection "{argument}" zu bearbeiten',
      unauthorizedToDeleteCollection:
        'Sie sind nicht authorisiert, die Collection "{argument}" zu löschen',
      couldNotCreateFile:
        'Das neue Dokument "{argument}" konnte nicht erstellt werden',
      unauthorizedToEditFile:
        'Sie sind nicht authorisiert, das Dokument "{argument}" zu bearbeiten',
      couldNotGetAllFilesOfCommunity:
        'Die Dokumente der Community "{argument}" konnten nicht gefunden werden',
      couldNotGetAllFilesInCollection:
        'Für diese Collection konnten keine Dokumente gefunden werden',
      couldNotFindFileGeneric:
        'Das angefragte Dokument konnte nicht gefunden werden',
      couldNotFindFile:
        'Es konnte kein Dokument unter "{argument}" gefunden werden',
      unauthorizedToAccessPrivateFile:
        'Sie sind nicht authorisiert, das private Dokument unter "{argument}" aufzurufen',
      unauthorizedToDeleteFile:
        'Sie sind nicht authorisiert, das Dokument "{argument}" zu löschen',
    },
  },
  vm: {
    error: {
      InvalidVmCommand: 'Ungültiges VM Kommando: "{argument}"',
      MethodOnNonExistentNode: 'Node {argument} existiert nicht',
      ObjectCannotHaveChildren:
        'Dieses Objekt kann keine Objekte erzeugen. Bitte wählen Sie ein anderes Objekt als Ausgangspunkt.',
      UnknownMethod: 'Unbekannte Methode: "{argument}"',
      InfiniteLoopDetected: 'Endlosschleife in VM erkannt',
      RootNotDeletable: 'Root kann nicht gelöscht werden',
      InvalidId: 'Ungültige ID: {argument}',
      NodeDoesNotHaveParent: 'Node hat keinen Ausgangspunkt',
      InvalidObject: 'Objekt ungültig',
      InvalidLink: 'Link ungültig',
      ObjectWithTitleAlreadyExists:
        'Es existiert bereits ein Objekt mit diesem Titel',
      ObjectNeedsTitle: 'Bitte geben Sie einen Titel für das Objekt an',
      ObjectWithSlugAlreadyExists:
        'Es existiert bereits ein Objekt mit diesem Slug',
      ObjectWithIdAlreadyExists:
        'Es existiert bereits ein Objekt mit der ID: "{argument}"',
    },
  },
  debug: {
    debug: 'Debugging',
    producer: 'Producer',
    consumer: 'Consumer',
    data: 'Data',
    locations: 'Locations',
    reassignPosts: 'Reassign posts',
  },
};
