<template>
  <v-dialog :value="openDialog" :max-width="maxWidth" :persistent="persistent">
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    openDialog: Boolean,
    maxWidth: String,
    persistent: Boolean,
  },
};
</script>
