


















import Vue from 'vue';

import { useSave } from '@/composables';

export default Vue.extend({
  name: 'TitleEditor',
  props: {
    id: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      default: '2.5rem',
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'info.title',
      defaultValue: '',
    });
    return {
      buffer,
      save,
    };
  },
});
