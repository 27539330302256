import { upperFirst, camelCase } from 'lodash';

const requireBaseComponents = require.context(
  '../components/base',

  // Whether or not to look in subfolders
  false,
  // Match names that begin with 'Base'
  /Base[A-Z]\w+\.(vue|js)$/,
);

export default {
  install(Vue) {
    requireBaseComponents.keys().forEach((fileName) => {
      const componentConfig = requireBaseComponents(fileName);
      const componentName = upperFirst(
        camelCase(
          // Gets the file name regardless of folder depth
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, ''),
        ),
      );

      // Register component globally
      Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig,
      );
    });
  },
};
