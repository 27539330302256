





















































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { useData } from '@/composables/data/useData';
import { pickBy, cloneDeep } from 'lodash';

import { computed } from '@vue/composition-api';
import { ModuleListener } from '@fillip/api';

export default Vue.extend({
  name: 'ListenerEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'listener',
      convertToBuffer: (data: ModuleListener) => {
        if (!data) return null;
        return Object.keys(data.listeners).map((key) => ({
          key,
          value: cloneDeep(data.listeners[key]),
        }));
      },
      convertFromBuffer: (buffer): ModuleListener => {
        if (buffer == null) return null;
        return {
          listeners: buffer.reduce((result, { key, value }) => {
            if (key) {
              result[key] = value;
            }
            return result;
          }, {}),
        };
      },
    });
    const { data } = useData();
    // To Do: Refactor into a composable
    const stations = computed(() => {
      return pickBy(data.value, (template) => template.station);
    });
    const stationItems = computed(() => {
      return Object.entries(stations.value).map(([key, value]) => ({
        value: value.id,
        text: value.info?.title || value.id,
      }));
    });
    return {
      buffer,
      save,
      stations,
      stationItems,
    };
  },
  methods: {
    addListener() {
      this.buffer.push({
        key: 'event',
        value: {
          script: '',
          context: {},
          stopPropagation: false,
          listenToChildren: false,
          maxDepth: 0,
        },
      });
    },
    removeListener(keyToRemove) {
      this.buffer = this.buffer.filter(({ key }) => key != keyToRemove);
      this.save();
    },
  },
});
