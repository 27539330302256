import { DataDocument, fragmentsTemplateDirectoryId } from '@fillip/api';

export const TemplateFragmentSpinner: DataDocument = {
  id: 'fragment-spinner',
  parentId: fragmentsTemplateDirectoryId,
  info: {
    title: 'Spinner',
    icon: 'spinner',
    isProtected: true,
  },
  class: {
    class: ':`${$containerClasses}`',
    elementClass: ':`${$elementClasses}`',
  },
  tag: {
    tag: 'tag',
  },
  children: {
    default: [
      {
        templateId: 'tag-icon',
        query: ':[data]',
        propMappings: {
          icon: '$icon',
          containerClasses: 'h-40 w-96 max-w-prose grid place-content-center',
          elementClasses: 'fa-spin',
        },
      },
    ],
  },
  interfaceDefinition: {
    title: '',
    inheritFrom: 'baseContent',
    props: [
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":$containerClasses || properties?.containerClasses?.value || 'h-full w-full grid place-content-center'",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":$elementClasses || properties?.elementClasses?.value || ''",
      },
    ],
  },
};
