














































import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ElementInlineEditingMeta } from '@fillip/api';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'InlineEditingEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, data, save } = useSave(props, {
      path: 'element',
    });

    const formattingOptions = ElementInlineEditingMeta.schema.formatting;
    const targetFormats = ElementInlineEditingMeta.schema.targetFormats;

    return {
      buffer,
      data,
      save,
      formattingOptions,
      targetFormats,
    };
  },
});
