<template>
  <BaseTextInput
    ref="usernameInput"
    autocomplete="name"
    :value="value"
    :label="label"
    :rules="usernameRules"
    :dark="dark"
    icon="user-circle"
    type="text"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
import { usernameRequired } from '@/utils/validators';

export default {
  name: 'UsernameInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: function () {
        return this.$t('user.username');
      },
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    usernameRules() {
      return [usernameRequired];
    },
  },
};
</script>
