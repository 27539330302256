










import Notifications from './features/app/Notifications.vue';
import RouterDialog from './features/app/RouterDialog.vue';
import defineAbilitiesFor from './utils/abilities';
import { setup as setupTwind, disconnect as disconnectTwind } from 'twind/shim';
import tailwindTheme from './styles/tailwind';
import typography from '@twind/typography';
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick,
  onBeforeMount,
} from '@vue/composition-api';
import { useEventListener } from '@vueuse/core';
import {
  useFillip,
  useI18n,
  useMessage,
  useNav,
  useRouter,
  useShell,
  useStore,
} from './composables';

export default defineComponent({
  name: 'App',
  components: {
    Notifications,
    RouterDialog,
  },
  setup(props, { emit }) {
    const { store } = useStore();
    const { $communitySlug, $community, $me, $ability, $user, fillip } =
      useFillip();
    const message = useMessage();
    const { shell, setShell, setDefaultShell } = useShell();
    const { $drawer, $navdrawer } = useNav();
    const { tr, setLocale } = useI18n();
    const { extractCommunitySlugFromTarget } = useRouter();

    const onlineStatus = computed(() => store.state.onlineStatus);
    const rules = computed(() => store.getters.communityState?.rules);

    useEventListener('offline', () => {
      store.commit('SET_ONLINE_STATUS', 'offline');
    });
    useEventListener('online', () => {
      store.commit('SET_ONLINE_STATUS', 'online');
    });

    onBeforeMount(async () => {
      const slug = extractCommunitySlugFromTarget();
      if (slug) {
        try {
          const community = await fillip.communities.getBySlug(slug);

          if (community?.shell) {
            setShell(community.shell);
          }
          if (community?.language) {
            setLocale(community.language);
          }
        } catch (error) {
          setDefaultShell();
        }
      } else setDefaultShell();
    });

    onMounted(() => {
      store.commit('SET_ONLINE_STATUS', 'online');

      setupTwind({
        target: document.querySelector('#fillip'),
        // Set to 'warn' as soon as twind/shim is deactivated
        mode: 'silent',
        preflight: false,
        important: true,
        theme: tailwindTheme,
        plugins: {
          columns: (parts) => ({ columns: parts[0] }),
          hyphens: (parts) => ({ hyphens: parts[0] }),
          ...typography(),
        },
      });
    });

    onBeforeUnmount(() => {
      disconnectTwind();
    });

    watch(
      $communitySlug,
      async (newValue, oldValue) => {
        if (!oldValue || newValue === oldValue) return;

        await nextTick();
        $drawer.navigate('/');
        $navdrawer.navigate('/');
      },
      { immediate: true },
    );

    watch(
      onlineStatus,
      (newStatus) => {
        if (newStatus === 'offline') {
          message.error(tr('general.error.pleaseCheckYourConnection'));
        }
      },
      { immediate: true },
    );

    watch(rules, (newValue, oldValue) => {
      if (!newValue) return;
      const participant = $me.value;
      const community = $community.value;

      $ability.update(
        defineAbilitiesFor(Object.values(newValue), {
          participant,
          community,
        }),
      );
    });

    return {
      onlineStatus,
      rules,
      shell,
    };
  },
});
