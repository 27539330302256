












































import Vue from 'vue';
import { useTags } from '@/composables';

export default Vue.extend({
  name: 'InputTag',
  props: {
    value: {
      type: Array,
    },
  },
  setup(props: any, { emit }) {
    const { tagDefinitions } = useTags();

    const update = (newValue) => emit('input', newValue);
    const removeTag = (tag) => {
      const newValue = props.value.filter((t) => t != tag);
      update(newValue);
    };

    return {
      tagDefinitions,
      update,
      removeTag,
    };
  },
});
