export * from './shared';
export * from './actions.module';
export * from './arrangement.module';
export * from './avatar.module';
export * from './camera.module';
export * from './children.module';
export * from './circle-sync-location.module';
export * from './class.module';
export * from './computeds.module';
export * from './data.module';
export * from './draggable.module';
export * from './droppable.module';
export * from './element.module';
export * from './info.module';
export * from './notes.module';
export * from './interface-definition.module';
export * from './list.module';
export * from './listener.module';
export * from './model.module';
export * from './navigation.module';
export * from './participant-account.module';
export * from './participant-circle.module';
export * from './participant-status.module';
export * from './participant-vc.module';
export * from './placement.module';
export * from './properties.module';
export * from './roles.module';
export * from './scene-definitions.module';
export * from './schema.module';
export * from './sheet.module';
export * from './size.module';
export * from './station.module';
export * from './streams.module';
export * from './style.module';
export * from './tag.module';
export * from './tag-definition.module';
export * from './templates.module';
export * from './transitions.module';
export * from './variables.module';
export * from './video-conferencing.module';
export * from './scene-definitions.module';

import {
  EvaluatedModuleArrangement,
  ModuleArrangementMeta,
} from './arrangement.module';
import {
  EvaluatedModulePlacement,
  ModulePlacementMeta,
} from './placement.module';
import { EvaluatedModuleSize, ModuleSizeMeta } from './size.module';
import {
  EvaluatedModuleVideoConferencing,
  ModuleVideoConferencingMeta,
} from './video-conferencing.module';
import { ModuleActions, ModuleActionsMeta } from './actions.module';
import { ModuleAvatar, ModuleAvatarMeta } from './avatar.module';
import { ModuleCamera, ModuleCameraMeta } from './camera.module';
import { ModuleChildren, ModuleChildrenMeta } from './children.module';
import {
  ModuleCircleSyncLocation,
  ModuleCircleSyncLocationMeta,
} from './circle-sync-location.module';
import { ModuleClass, ModuleClassMeta } from './class.module';
import { ModuleComputeds, ModuleComputedsMeta } from './computeds.module';
import { ModuleData, ModuleDataMeta } from './data.module';
import { ModuleElement, ModuleElementMeta } from './element.module';
import { ModuleInfo, ModuleInfoMeta } from './info.module';
import { ModuleNotes, ModuleNotesMeta } from './notes.module';
import {
  ModuleInterfaceDefinition,
  ModuleInterfaceDefinitionMeta,
} from './interface-definition.module';
import { ModuleList, ModuleListMeta } from './list.module';
import { ModuleListener, ModuleListenerMeta } from './listener.module';
import { ModuleModel, ModuleModelMeta } from './model.module';
import { ModuleNavigation, ModuleNavigationMeta } from './navigation.module';
import {
  ModuleParticipantAccount,
  ModuleParticipantAccountMeta,
} from './participant-account.module';
import {
  ModuleParticipantCircle,
  ModuleParticipantCircleMeta,
} from './participant-circle.module';
import {
  ModuleParticipantStatus,
  ModuleParticipantStatusMeta,
} from './participant-status.module';
import {
  ModuleParticipantVc,
  ModuleParticipantVcMeta,
} from './participant-vc.module';
import { ModuleProperties, ModulePropertiesMeta } from './properties.module';
import { ModuleRoles, ModuleRolesMeta } from './roles.module';
import {
  ModuleSceneDefinitions,
  ModuleSceneDefinitionsMeta,
} from './scene-definitions.module';
import { ModuleSchema, ModuleSchemaMeta } from './schema.module';
import { ModuleSheet, ModuleSheetMeta } from './sheet.module';
import { ModuleStation, ModuleStationMeta } from './station.module';
import { ModuleStreams, ModuleStreamsMeta } from './streams.module';
import { ModuleStyle, ModuleStyleMeta } from './style.module';
import { ModuleTag, ModuleTagMeta } from './tag.module';
import {
  ModuleTagDefinition,
  ModuleTagDefinitionMeta,
} from './tag-definition.module';
import { ModuleTemplates, ModuleTemplatesMeta } from './templates.module';
import { ModuleTransitions, ModuleTransitionsMeta } from './transitions.module';
import { ModuleVariables, ModuleVariablesMeta } from './variables.module';
import { ModuleDraggable, ModuleDraggableMeta } from './draggable.module';
import { ModuleDroppable, ModuleDroppableMeta } from './droppable.module';

export type EvaluableModule<T> =
  | T
  | {
      type: 'conditional';
      variants: Array<{ condition: string; value: T }>;
    };

export interface Modules {
  actions?: ModuleActions;
  arrangement?: EvaluableModule<EvaluatedModuleArrangement>;
  avatar?: ModuleAvatar;
  camera?: ModuleCamera;
  children?: ModuleChildren;
  circleSyncLocation?: ModuleCircleSyncLocation;
  class?: ModuleClass;
  computeds?: ModuleComputeds;
  data?: ModuleData;
  element?: ModuleElement;
  info?: ModuleInfo;
  notes?: ModuleNotes;
  interfaceDefinition?: ModuleInterfaceDefinition;
  list?: ModuleList;
  listener?: ModuleListener;
  model?: ModuleModel;
  navigation?: ModuleNavigation;
  participantAccount?: ModuleParticipantAccount;
  participantCircle?: ModuleParticipantCircle;
  participantStatus?: ModuleParticipantStatus;
  participantVc?: ModuleParticipantVc;
  placement?: EvaluableModule<EvaluatedModulePlacement>;
  properties?: ModuleProperties;
  roles?: ModuleRoles;
  schema?: ModuleSchema;
  sheet?: ModuleSheet;
  size?: EvaluableModule<EvaluatedModuleSize>;
  station?: ModuleStation;
  streams?: ModuleStreams;
  style?: ModuleStyle;
  tag?: ModuleTag;
  tagDefinition?: ModuleTagDefinition;
  templates?: ModuleTemplates;
  transitions?: ModuleTransitions;
  variables?: ModuleVariables;
  videoConferencing?: EvaluableModule<EvaluatedModuleVideoConferencing>;
  sceneDefinitions?: ModuleSceneDefinitions;
  draggable?: ModuleDraggable;
  droppable?: ModuleDroppable;
}

export const ModulesMeta = {
  info: ModuleInfoMeta,
  tag: ModuleTagMeta,
  notes: ModuleNotesMeta,
  list: ModuleListMeta,
  actions: ModuleActionsMeta,
  arrangement: ModuleArrangementMeta,
  avatar: ModuleAvatarMeta,
  camera: ModuleCameraMeta,
  children: ModuleChildrenMeta,
  circleSyncLocation: ModuleCircleSyncLocationMeta,
  class: ModuleClassMeta,
  computeds: ModuleComputedsMeta,
  data: ModuleDataMeta,
  element: ModuleElementMeta,
  model: ModuleModelMeta,
  interfaceDefinition: ModuleInterfaceDefinitionMeta,
  tagDefinition: ModuleTagDefinitionMeta,
  listener: ModuleListenerMeta,
  navigation: ModuleNavigationMeta,
  participantAccount: ModuleParticipantAccountMeta,
  participantCircle: ModuleParticipantCircleMeta,
  participantStatus: ModuleParticipantStatusMeta,
  participantVc: ModuleParticipantVcMeta,
  placement: ModulePlacementMeta,
  properties: ModulePropertiesMeta,
  roles: ModuleRolesMeta,
  sceneDefinitions: ModuleSceneDefinitionsMeta,
  schema: ModuleSchemaMeta,
  sheet: ModuleSheetMeta,
  size: ModuleSizeMeta,
  station: ModuleStationMeta,
  streams: ModuleStreamsMeta,
  style: ModuleStyleMeta,
  templates: ModuleTemplatesMeta,
  transitions: ModuleTransitionsMeta,
  variables: ModuleVariablesMeta,
  videoConferencing: ModuleVideoConferencingMeta,
  draggable: ModuleDraggableMeta,
  droppable: ModuleDroppableMeta,
};
