import { BaseElement } from './../core';

export const ElementRectangle = {
  name: 'ElementRectangle',
  mixins: [BaseElement],
  render(h) {
    return h('div', {
      ref: 'el',
    });
  },
};
