































































































































































































































































import Vue from 'vue';
import clone from 'rfdc/default';

import { BaseModuleEditor } from '../base';
import { useSave, useData, useVolatileData } from '@/composables';
import {
  DocumentRole,
  localPrefix,
  localRootDataId,
  ModuleActionsMeta,
} from '@fillip/api';
import { nanoid } from 'nanoid';
import IconEditor from '@/features/editor/elements/IconEditor.vue';

export default Vue.extend({
  name: 'ActionsEditor',
  components: {
    IconEditor,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'actions',
    });
    const { getData } = useData();
    const { removeVolatileProp } = useVolatileData();
    return {
      buffer,
      save,
      getData,
      removeVolatileProp,
    };
  },
  data() {
    return {
      localDoc: '',
    };
  },
  computed: {
    document() {
      return this.getData(this.id);
    },
    items() {
      return Object.values(ModuleActionsMeta.types).map((type) => ({
        text: this.$t(`${type.type}.title`),
        value: type.type,
      }));
    },
    slots() {
      return [
        { value: 'top', text: 'Top' },
        { value: 'left', text: 'Left' },
        { value: 'bottom', text: 'Bottom' },
        { value: 'right', text: 'Right' },
      ];
    },
    locations() {
      return [
        { value: 'always', text: 'Always' },
        { value: 'focused', text: 'Focused' },
        { value: 'station', text: 'Station' },
      ];
    },
    roles() {
      if (!this.document.roles?.roles || this.document.roles.roles.length < 1)
        return null;
      return Object.values(this.document.roles.roles).map(
        (role: DocumentRole) => ({
          value: role.key,
          text: role.title,
        }),
      );
    },
  },
  // TODO: Remove after old communities have been updated
  watch: {
    buffer: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.actions) {
          let needsUpdate = false;
          for (const action of newValue.actions) {
            if (action.type && !action.type.startsWith('action.')) {
              action.type = 'action.' + action.type;
              needsUpdate = true;
            }
          }
          if (needsUpdate) this.save();
        }
      },
    },
  },
  methods: {
    setType(index, type) {
      this.buffer.actions[index] = clone(ModuleActionsMeta.types[type].default);
      if (this.documentManagerActive(type)) {
        this.buffer.actions[index].context.documentId = this.id;
      }
      this.save();
    },
    removeAction(index: number) {
      this.removeVolatileProp(
        this.id,
        'vMinimized:' + this.buffer.actions[index].name,
      );
      this.buffer.actions.splice(index, 1);
      this.save();
    },
    addAction() {
      this.buffer.actions.push(
        clone(ModuleActionsMeta.types['action.button'].default),
      );
      this.save();
    },
    documentManagerActive(type) {
      return ModuleActionsMeta.types[type].category == 'managers';
    },
    updateDoc(index, id?) {
      if (id) {
        this.buffer.actions[index].context.documentId = id;
        if (this.localDoc) {
          this.removeLocalDoc();
        }
      } else if (!this.useLocalDoc(index)) {
        this.createLocalDoc();
        this.buffer.actions[index].context.documentId = this.localDoc;
      } else {
        if (this.localDoc) {
          this.removeLocalDoc();
        }
        this.buffer.actions[index].context.documentId = this.id;
      }
      this.save();
    },
    createLocalDoc() {
      const id = localPrefix + nanoid();
      this.$fiStore.setDocument('data', id, {
        id,
        parentId: localRootDataId,
        info: {
          title: '',
          icon: '',
        },
        tag: {
          tag: 'page', // Just a placeholder, might be dynamic in the future
        },
        properties: {},
        list: { items: [] },
      });
      this.localDoc = id;
    },
    removeLocalDoc() {
      this.$fiStore.removeDocument('data', this.localDoc);
      this.localDoc = '';
    },
    useLocalDoc(index) {
      return this.buffer.actions[index].context.documentId.startsWith('local:');
    },
    restrictionsEnabled(index) {
      return Boolean(
        this.buffer.actions[index].roles || this.buffer.actions[index].focused,
      );
    },
    updateRestrictions(index) {
      if (this.restrictionsEnabled(index)) {
        this.buffer.actions[index].roles = null;
        this.buffer.actions[index].focused = null;
        this.save();
      } else {
        this.buffer.actions[index].focused = 'focused';
        this.save();
      }
    },
  },
});
