<template>
  <div>
    <BaseTextInput
      ref="passwordInput"
      :value="value"
      :label="label"
      :rules="passwordRules"
      icon="lock"
      :append-icon="showPassword ? 'eye-slash' : 'eye'"
      :type="showPassword ? 'text' : 'password'"
      :hint="confirmation"
      :hint-text="confirmation ? $t('user.passwordRequirements') : null"
      :dark="dark"
      :autocomplete="autocomplete"
      @appendclick="showPassword = !showPassword"
      @input="$emit('input', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    />
    <BaseTextInput
      v-if="confirmation"
      ref="passwordInput"
      v-model="confirmPassword"
      :label="$t('user.confirmPassword')"
      :rules="passwordConfirmedRules"
      icon="lock"
      :append-icon="showConfirmedPassword ? 'eye-slash' : 'eye'"
      :type="showConfirmedPassword ? 'text' : 'password'"
      :hint="false"
      :dark="dark"
      :autocomplete="autocomplete"
      @appendclick="showConfirmedPassword = !showConfirmedPassword"
    />
  </div>
</template>

<script>
import { passwordRequired, passwordStrong } from '@/utils/validators';

export default {
  name: 'PasswordInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: function () {
        return this.$t('user.password');
      },
    },
    requireStrong: {
      type: Boolean,
      default: true,
    },
    confirmation: { type: Boolean },
    autocomplete: { type: String },
    dark: { type: Boolean, default: false },
  },
  data() {
    return {
      showPassword: false,
      showConfirmedPassword: false,
      confirmPassword: '',
    };
  },
  computed: {
    passwordRules() {
      const pwRules = [passwordRequired];
      if (this.requireStrong) {
        pwRules.push(passwordStrong);
      }
      return pwRules;
    },
    passwordConfirmedRules() {
      // Necessary to trigger recomputation
      const password = this.value;
      const passwordsMatch = (passwordConfirmed) => {
        return (
          Boolean(password == passwordConfirmed) || 'signup.passwordValidation'
        );
      };
      const pwRules = [passwordRequired, passwordsMatch];
      if (this.requireStrong) {
        pwRules.push(passwordStrong);
      }
      return pwRules;
    },
  },
  watch: {
    value(newValue) {
      if (newValue == '') {
        this.confirmPassword = '';
      }
    },
  },
};
</script>
