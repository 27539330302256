import { ComponentConfig, ComponentInstance } from '../../../core/types';
import { DefaultSizeConstraints } from './../size';
import * as Location3D from '@/utils/location3D';

export const originArranger =
  (vm: ComponentInstance) => (input: ComponentConfig[]) => {
    return input.map(() => ({
      location: Location3D.origin,
      sizeConstraints: DefaultSizeConstraints,
    }));
  };
