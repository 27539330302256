
























import {
  ExplicitSceneDefinition,
  ObjectId,
  rootTemplateId,
  SceneParams,
} from '@fillip/api';

export default {
  name: 'Prompt',
  inject: ['router'],
  data() {
    return {
      prompt: '',
      errors: [],
      results: [],
      selection: null,
    };
  },
  computed: {
    currentSelection() {
      return this.selection;
    },
  },
  watch: {
    async results() {
      await this.$nextTick();
      const lastLine = this.$refs.lines[this.$refs.lines.length - 1];
      if (lastLine) {
        lastLine.scrollIntoView();
      }
    },
  },
  mounted() {
    this.$refs.textField.focus();
  },
  methods: {
    async submit() {
      this.command = this.prompt.split(' ');

      this.errors = [];

      const console = {
        log: (...args) => {
          args.forEach((arg) => {
            this.results.push(arg);
          });
        },
      };
      let exec;
      try {
        exec = Function(
          'remove',
          'state',
          'pwd',
          'show',
          'goto',
          `return (async () => {return ${this.prompt}})()`,
        );
      } catch (error) {
        this.errors = [String(error)];
        return;
      }

      const proxy = new Proxy(
        { id: this.currentSelection },
        {
          get: (target, prop) => {
            return (...args) => {
              return this.$invoke(prop, this.currentSelection, ...args);
            };
          },
        },
      );
      try {
        const state =
          this.$fillip.realtime.state.communities[this.$communitySlug].db;
        const pwd = this.currentSelection;
        const show = {
          id: this.currentSelection,
        };

        const goto = (objectId: ObjectId) => {
          // TODO: test implementation, move to router
          const router = this.router.value;
          if (router.activeSceneObjectId == objectId) return;

          const activeScene =
            this.$store.state.objects.instances[router.activeSceneObjectId];
          const targetScene = this.$store.state.objects.instances[objectId];

          const [templateId, dataId] = objectId.split('/')[1].split(':');
          const segment = {
            scene: {
              explicit: true,
              templateId: templateId || rootTemplateId,
              dataId: dataId || null,
            } as ExplicitSceneDefinition,
            params: {} as SceneParams,
          };
          if (activeScene?.parentId == targetScene.parentId) {
            router.gotoSibling(segment);
          } else {
            router.gotoChild(segment);
          }
        };

        const remove = (id) => this.$invoke('removeDocument', 'data', id);

        this.results.push(this.prompt);
        this.results.push(
          await exec.bind(proxy)(remove, state, pwd, show, goto),
        );
      } catch (error) {
        this.errors = [String(error)];
        return;
      }

      // this.$emit('submit');
      this.prompt = '';
    },
  },
};
