













































import Vue from 'vue';
import ActionsComponent from './ActionsComponent.vue';
import { Action, ActionSlot } from '@fillip/api';
import BaseActionDocumentManager from './document-managers/BaseActionDocumentManager.vue';
import { useVolatileData } from '@/composables';

export default Vue.extend({
  name: 'ActionsLayer',
  components: { ActionsComponent, BaseActionDocumentManager },
  inject: ['actions'],
  setup() {
    const { setVolatileProp } = useVolatileData();
    return { setVolatileProp };
  },
  data() {
    return {
      noMenuActions: ['action.button', 'action.stepper', 'action.workspace'],
      vMinimized: 'vMinimized',
      areaMeta: {},
    };
  },
  computed: {
    default(): Action[] {
      return this.actions.value?.canvas;
    },
    areas() {
      return [
        {
          id: 'left',
          axis: 'X',
          // actions: this.getActionsForArea('left'),
          actions: this.left,
        },
        {
          id: 'right',
          axis: 'X',
          // actions: this.getActionsForArea('right'),
          actions: this.right,
        },
        {
          id: 'top',
          axis: 'Y',
          // actions: this.getActionsForArea('top'),
          actions: this.top,
        },
        {
          id: 'bottom',
          axis: 'Y',
          // actions: this.getActionsForArea('bottom'),
          actions: this.bottom,
        },
      ];
    },
    left(): Action[] {
      return this.getActionsForArea('left');
    },
    right(): Action[] {
      return this.getActionsForArea('right');
    },
    top(): Action[] {
      return this.getActionsForArea('top');
    },
    bottom(): Action[] {
      return this.getActionsForArea('bottom');
    },
  },
  watch: {
    bottom(newValue, oldValue) {
      this.createMeta(newValue, oldValue, 'bottom');
    },
    top(newValue, oldValue) {
      this.createMeta(newValue, oldValue, 'top');
    },
    left(newValue, oldValue) {
      this.createMeta(newValue, oldValue, 'left');
    },
    right(newValue, oldValue) {
      this.createMeta(newValue, oldValue, 'right');
    },
  },
  methods: {
    setId(action) {
      return !action.context
        ? action.id
        : action.context.documentId
        ? action.context.documentId
        : action.context.$id;
    },
    createVpropId(action) {
      return action.id == 'default' || !action.id
        ? action.context.$id + ':' + action.name
        : action.id;
    },
    createMeta(newValue, oldValue, area) {
      const all = [];
      let lastAdded = null;

      newValue.forEach((action1) => {
        const vPropId = this.createVpropId(action1);
        all.push(vPropId);
        if (
          !oldValue.find(
            (action2) =>
              action2.context?.$id == action1.context?.$id &&
              action2.name == action1.name,
          )
        )
          lastAdded = vPropId;
      });
      if (!this.areaMeta[area]) {
        this.$set(this.areaMeta, area, {
          lastAdded: null,
          vMinimized: this.vMinimized,
          all: [],
        });
      }
      const areaM = this.areaMeta[area];

      areaM.all = all;
      if (lastAdded) this.areaMeta[area].lastAdded = lastAdded;
    },
    getActionsForArea(area: ActionSlot = 'bottom') {
      return this.actions.value?.canvas.filter((action) => {
        if (area == 'bottom' && !action.slot) return true;
        return action.slot == area;
      });
    },
  },
});
