var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-text-field',{ref:"inputComponent",class:{
    'align-right': _vm.align == 'right',
    'v-text-field--minimal': ['minimal', 'plain', 'inline-label'].includes(
      _vm.variant
    ),
    'v-text-field--inline-label': _vm.variant == 'inline-label',
    plain: _vm.variant == 'plain',
  },attrs:{"value":_vm.value,"placeholder":_vm.defaultEntry,"type":"number","hide-details":"","readonly":_vm.readOnly,"label":_vm.label,"dense":"","suffix":_vm.suffix},on:{"input":function($event){_vm.$emit('input', Number($event))},"focus":function($event){return _vm.$emit('focus', $event)},"change":function($event){_vm.$emit('change', Number($event))},"blur":function($event){return _vm.$emit('blur', $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $event.target.blur()}}})}
var staticRenderFns = []

export { render, staticRenderFns }