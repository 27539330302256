import { ModuleTypeMeta } from './../../common';

// Generic key value store
import { TypeAny } from '../../type-system/any';

export interface ModuleProperties {
  [name: string]: TypeAny;
}

export const ModulePropertiesMeta: ModuleTypeMeta<ModuleProperties> = {
  default: {},
};
