




























import Vue from 'vue';

export default Vue.extend({
  name: 'InputNumber',
  props: {
    value: {
      type: Number,
      required: false,
    },
    align: {
      type: String,
      default: 'left',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: [Number, String],
      required: false,
    },
    variant: {
      type: String,
      default: 'default',
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  computed: {
    defaultEntry() {
      return this.defaultValue?.toString() || '';
    },
  },
});
