



































































































import Vue from 'vue';
import { useMediaControls } from '@/composables';
import InputNumberSlider from '@/components/input/number/InputNumberSlider.vue';
import { ref } from '@vue/composition-api';
import DOMPurify from 'dompurify';

export default Vue.extend({
  name: 'MediaControls',
  components: {
    InputNumberSlider,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    mediaType: {
      type: String,
      required: true,
    },
    progressOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const {
      errorMessage,
      buffered,
      percentage,
      volume,
      loop,
      mediaSource,
      shouldBePlaying,
      pausedAt,
      trackDuration,
      uiDuration,
      uiPosition,
      setPosition,
      setStop,
      setPlay,
      setPause,
      setLoop,
      setVolume,
      transcript,
      endTrack,
    } = useMediaControls(props, {
      mediaType: props.mediaType,
    });

    const ccOn = ref(false);

    const setCc = (newValue) => {
      ccOn.value = newValue;
    };

    const setDone = () => {
      ccOn.value = false;
      endTrack();
    };

    const sanitizeContent = (content: string) => DOMPurify.sanitize(content);

    return {
      errorMessage,
      buffered,
      percentage,
      volume,
      loop,
      mediaSource,
      shouldBePlaying,
      pausedAt,
      trackDuration,
      uiDuration,
      uiPosition,
      setPosition,
      setStop,
      setPlay,
      setPause,
      setLoop,
      setVolume,
      transcript,
      ccOn,
      setCc,
      setDone,
      sanitizeContent,
    };
  },
});
