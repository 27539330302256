



























import { Action } from '@fillip/api';
import { useData, useI18n } from '@/composables';
import {
  defineComponent,
  computed,
  inject,
  Ref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  name: 'Navigation',
  setup() {
    const { getData } = useData();
    const { tr } = useI18n();
    const router = inject('router') as Ref<any>;
    const actions = inject('actions') as Ref<any>;

    const showBack = computed(() => {
      if (!router.value || !router.value.activeSceneObjectId) return false;
      if (router.value.activeSceneObjectId == 0) {
        return false;
      }
      return true;
    });

    watch(showBack, (value) => {
      console.log('showBack', value);
    });

    const goToActions = computed(() => {
      return actions.value.getNavigationLinks('Navigation-tree');
    });
    const activeSceneTitle = computed(() => {
      return router?.value.activeSceneTitle || tr('worldNavigation.title');
    });

    const navigationTitle = computed(() => {
      return goToActions.value.length > 0
        ? tr('worldNavigation.linkOverview')
        : tr('worldNavigation.noLinks');
    });

    const execute = (action: Action, data: Record<string, any> = {}) => {
      actions.value.execute(action.script, {
        ...action.context,
        ...data,
      });
    };

    const gotoParent = () => {
      router.value.gotoParent();
    };

    return {
      getData,
      showBack,
      goToActions,
      activeSceneTitle,
      navigationTitle,
      execute,
      gotoParent,
    };
  },
});
