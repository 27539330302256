import { DataDocument, toolsTemplateDirectoryId } from '@fillip/api';
export const TemplateWorkspace: DataDocument = {
  id: 'tool-workspace',
  parentId: toolsTemplateDirectoryId,
  info: {
    title: 'Tool: Workspace',
  },
  tag: {
    tag: 'template',
  },
  size: {
    minWidth: '100vw',
    minHeight: '100vh',
    minDepth: '',
    maxWidth: '100vw',
    maxHeight: '100vh',
    maxDepth: '',
  },
  list: {
    items: [
      {
        id: 'WorkspaceContainer',
      },
    ],
  },
  class: {
    class: 'overflow-hidden ',
    elementClass: '',
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
  children: {
    default: [
      {
        templateId: 'WorkspaceContainer',
        query: ':[data]',
        identity: '',
      },
    ],
  },
  videoConferencing: {
    camera: {
      channel: ":data && data.id ? data.id : 'global'",
      roomSize: '0.5',
      state: 'ON',
      settings: {
        width: {
          min: 320,
          ideal: 480,
          max: 640,
        },
        height: {
          min: 240,
          ideal: 320,
          max: 480,
        },
      },
    },
    microphone: {
      channel: ":data && data.id ? data.id : 'global'",
      roomSize: '0.5',
      state: 'ON',
      settings: {},
    },
    screen: {
      channel: ":data && data.id ? data.id : 'global'",
      roomSize: '0.5',
      state: 'ON',
      settings: {
        audio: true,
        video: {
          displaySurface: 'monitor',
          logicalSurface: true,
          cursor: 'always',
          resizeMode: 'none',
          width: {
            max: 1920,
          },
          height: {
            max: 1080,
          },
          frameRate: {
            max: 30,
          },
        },
      },
    },
  },
};

export const TemplateWorkspaceChildren: DataDocument[] = [
  {
    id: 'WorkspaceContainer',
    info: {
      title: 'Workspace Container',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-workspace',
    children: {
      default: [
        {
          templateId: 'function:workspace',
          query: ':[data]',
          identity: '',
        },
      ],
    },
    list: {
      items: [],
    },
    sheet: {
      orientTowardsCamera: false,
    },
    arrangement: {
      type: 'arrangement.absolute',
    },
  },
];
