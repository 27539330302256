



















import AuthContainer from '@/features/auth/components/AuthContainer.vue';
import EmailInput from '@/components/form-inputs/EmailInput.vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { useFillip, useMessage, useI18n, useRouter } from '@/composables';

export default defineComponent({
  name: 'RequestConfirmation',
  components: {
    AuthContainer,
    EmailInput,
  },
  setup() {
    const { fillip, $user } = useFillip();
    const message = useMessage();
    const { tr } = useI18n();
    const { router } = useRouter();

    const email = ref('');
    const form = ref(null);
    const loading = ref(false);

    const submit = async () => {
      loading.value = true;
      if (
        $user.value?.isVerified === 'verified' &&
        $user.value.email === email.value
      ) {
        message.info(tr('confirmation.emailAlreadyConfirmed'));
        return router.push({ name: 'Login' });
      }

      try {
        await fillip.auth.resendEmailVerification(email.value);
        message.success(tr('confirmation.emailSent'));
        router.push({ name: 'ConfirmationPending' });
      } catch (e) {
        // TODO: Handle error case better, check locales
        message.error(tr('confirmation.emailNotSent'));
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    watch(
      $user,
      () => {
        if ($user.value?.pendingEmail) {
          email.value = $user.value.pendingEmail;
        } else if ($user.value?.email) {
          email.value = $user.value.email;
        } else {
          email.value = '';
        }
      },
      { immediate: true },
    );

    return { email, submit, user: $user, form };
  },
});
