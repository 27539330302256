import { DataId, FrontendExpression, ModuleTypeMeta } from './../../common';

// Generic key value store
import { TypeName } from '../../type-system/types';

// TODO: Split options by Type, add more options, use options in editors
export interface SchemaFieldOptions {
  editor?: string;
  colorMode?: 'rgb' | 'rgba' | 'hex' | 'hexa';
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  items?: string | Record<string, any>[]; // TODO: Unify typings for InputSelect
  multiple?: boolean;
}

export interface SchemaField {
  title: string;
  key: string;
  type: TypeName;
  condition?: string;
  restrictions?: 'readonly' | 'hidden';
  required?: boolean;
  defaultValue?: FrontendExpression;
  filter?: string[];
  tags?: string[];
  options?: SchemaFieldOptions;
}

export type SchemaReferenceKey =
  | 'custom'
  | 'props'
  | 'fields'
  | 'ownFields'
  | 'entities'
  | 'interface';

export interface SchemaReference {
  loadFromId: string;
  loadFields: SchemaReferenceKey[];
}

export interface ModuleSchema {
  fields?: SchemaField[];
  ownFields?: SchemaField[];
  entities?: SchemaField[];
  loadFrom?: string | SchemaReference[];
}

export interface SchemaDefinition {
  title: string;
  fromId: DataId;
  key: SchemaReferenceKey;
  fields: SchemaField[];
  allowCustomFields?: boolean;
}

export const ModuleSchemaMeta: ModuleTypeMeta<ModuleSchema> = {
  default: {
    fields: [],
    ownFields: [],
  },
  childDefault: {
    title: 'Field',
    key: 'field',
    type: 'string',
  },
};
