import { Base3dControls } from './Base3dControls';
import type { IBaseControls } from './index';
import { type CameraOrbit, CameraOrbitMeta } from '@fillip/api';
import CameraControls from 'camera-controls';

export class OrbitControls extends Base3dControls implements IBaseControls {
  initialize(options?: CameraOrbit) {
    super.initialize();
    this.controls.setPosition(0, 0, 1000);

    // TODO: Enable shift left again
    // See: https://github.com/yomotsu/camera-controls/blob/dev/examples/mouse-drag-with-modifier-keys.html
    // TODO: Add TOUCH_ACTION

    // this.controls.mouseButtons.shiftLeft = CameraControls.ACTION.TRUCK;
    this.controls.dollyToCursor = true;
    this.controls.infinityDolly = true;
    this.updateCamera(options);
    this.renderInLoop();
    this.controls.addEventListener('control', () => this.renderInLoop());
  }
  updateCamera(sceneCamera: CameraOrbit = CameraOrbitMeta.default): void {
    this.controls.minDistance = sceneCamera.zoomMinDistance || 10;
    this.controls.maxDistance =
      sceneCamera.zoomMaxDistance > 0 ? sceneCamera.zoomMaxDistance : 5000;
  }
}
