import { computed, provide, reactive } from '@vue/composition-api';
import { useI18n } from '../i18n/useI18n';
import { useNav } from './useNav';

export function useLayoutArea(name: string) {
  const { tr } = useI18n();
  const nav = useNav();

  const layoutRouter = nav[`$${name}`];
  const $navigate = nav.$navigate;

  const currentRoute = computed(() => {
    return layoutRouter.route?.matched?.[0] || layoutRouter.currentRoute || {};
  });

  const route = computed(() => layoutRouter.route);

  const component = computed(() => {
    return currentRoute.value?.components?.default;
  });

  const componentProps = computed(() => {
    return typeof currentRoute.value?.props?.default === 'function'
      ? currentRoute.value?.props?.default(layoutRouter.route)
      : currentRoute.value?.props?.default;
  });

  const heading = computed(() => {
    const title =
      currentRoute.value.meta?.heading || currentRoute.value.meta?.title;
    return title ? tr(title) : '';
  });

  const hideDefaultHeader = computed(() => {
    return Boolean(currentRoute.value.meta?.hideDefaultHeader);
  });

  const isOpen = computed(() => currentRoute.value.path !== '/');

  const close = () => layoutRouter.close();

  const expand = () => {
    $navigate.push(layoutRouter.route.fullPath, 'dialog');
    close();
  };
  const collapse = () => {
    $navigate.push(layoutRouter.route.fullPath, 'drawer');
    close();
  };
  const navigate = (path: any) => {
    $navigate.push(path, name).catch((error) => {
      if (error.name != 'NavigationDuplicated') {
        throw error;
      }
    });
  };
  const go = (delta: number = -1) => {
    return $navigate.go(delta, 'dialog');
  };

  const layoutArea = reactive({
    route: layoutRouter.route,
    area: layoutRouter.route.area,
    router: layoutRouter,
    close,
    expand,
    collapse,
    navigate,
    go,
  });

  provide('layoutArea', layoutArea);

  return {
    layoutRouter,
    $navigate,
    route,
    currentRoute,
    component,
    componentProps,
    heading,
    hideDefaultHeader,
    isOpen,
    close,
    expand,
    collapse,
    navigate,
    go,
  };
}
