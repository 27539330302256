import {
  DataDocument,
  EvaluatedModuleArrangement,
  Action,
  objectPush,
} from '@fillip/api';
import { RenderContext } from './../scene/render';
import { VNode, f } from '@/features/main/engine';

import {
  FunctionTemplate,
  FunctionTemplateRenderMode,
  RenderResult,
} from './types';

const tools = {
  participants: {
    area: 'top',
    id: 'participants',
  },
  iframe: {
    area: 'main',
    id: 'iframe',
  },
};

export const sequence: FunctionTemplate = (
  context: RenderContext,
  mode: FunctionTemplateRenderMode,
  data?: DataDocument,
): RenderResult => {
  const list = (data.list?.items || []).map((m) =>
    context.vueInstance.getData(m.id),
  );
  const index = Number(data.properties?.phase?.value) || 0;
  const properties = list[index]?.properties || {};

  const variables = context.env.$vars;
  const props = context.env.$props;
  const computeds = context.env.$computeds;

  console.log(list, index, properties);

  const activatedTools = Object.keys(tools)
    .filter((tool) => properties[tool]?.value)
    .map((key) => tools[key]);

  const toolByArea = {
    top: [],
    main: [],
  };

  activatedTools.forEach((tool) => toolByArea[tool.area].push(tool));

  const top = toolByArea.top.map((tool) =>
    f({
      element: { type: 'element.text', textContent: tool.id, textFormat: 'p' },
      sheet: { orientTowardsCamera: false },
    }),
  );

  const main = toolByArea.main.map((tool) =>
    f({
      element: { type: 'element.text', textContent: tool.id, textFormat: 'p' },
      sheet: { orientTowardsCamera: false },
    }),
  );

  const grid: EvaluatedModuleArrangement = {
    type: 'arrangement.grid',
    gridRows: top.length && main.length ? '30vp 70vp' : '100vp',
    gridColumns: '100vp',
  };

  const divide: EvaluatedModuleArrangement = {
    type: 'arrangement.divide',
    divideAspectRatio: '1 / 1',
    divideStretch: true,
  };

  const topArea = top.length ? [f({ arrangement: divide }, top)] : [];
  const mainArea = main.length ? [f({ arrangement: divide }, main)] : [];

  const nextIndex =
    ((Number(data.properties.phase.value) || 0) + 1) % list.length;

  const next: Action = {
    name: list[nextIndex].info.title,
    type: 'action.button',
    icon: '',
    color: '',
    script: 'updateProperties(docId, value)',
    context: {
      documentId: data.id,
      value: {
        phase: {
          type: 'number',
          value: nextIndex,
        },
      },
    },
  };

  objectPush(context.out.globalProps, 'actions.canvas', next);
  return {
    variables,
    props,
    computeds,
    template: f(
      {
        arrangement: grid,
      },
      [...topArea, ...mainArea],
    ),
  };
};
