







































































import Vue from 'vue';
import { dayjs } from '@fillip/api';

export default Vue.extend({
  name: 'InputDatetime',
  props: {
    value: {
      type: Number,
      default: () => Date.now(),
    },
  },
  data() {
    return {
      dateMenu: false,
      timeMenu: false,
      dateBuffer: '',
      timeBuffer: '',
    };
  },
  computed: {
    asDayjs() {
      return dayjs(this.dateBuffer + '-' + this.timeBuffer, 'YYYY-MM-DD-HH:mm');
    },
    formattedDate() {
      return this.asDayjs.format('DD.MM.YYYY');
    },
    formattedTime() {
      return this.asDayjs.format('HH:mm');
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        const newDate = dayjs(newValue);
        this.dateBuffer = newDate.format('YYYY-MM-DD');
        this.timeBuffer = newDate.format('HH:mm');
      },
    },
  },
  methods: {
    updateDate(newDate) {
      this.dateBuffer = dayjs(newDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      this.updateDateTime();
    },
    updateTime(newTime) {
      this.timeBuffer = newTime;
      this.updateDateTime();
    },
    updateDateTime() {
      const dateTime = this.asDayjs.valueOf();
      this.$emit('input', dateTime);
      this.$emit('change', dateTime);
      this.$emit('blur', dateTime);
    },
  },
});
