<template>
  <v-list
    ><v-subheader>{{ control.name }}</v-subheader>
    <v-list-item
      v-for="(option, index) in control.options"
      :key="index"
      @click="triggerAction(option)"
    >
      <v-list-item-icon
        ><base-icon :icon="option.icon"></base-icon></v-list-item-icon
      ><v-list-item-content>{{ option.title }}</v-list-item-content>
    </v-list-item></v-list
  >
</template>
<script>
export default {
  name: 'ActionListControl',
  props: {
    control: {
      type: Object,
    },
  },
  methods: {
    triggerAction(option) {
      option.action.bind(this)();
    },
  },
};
</script>
