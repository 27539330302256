





























































import { useData, usePermissions } from '@/composables';

import ListEditorLarge from '@/features/editor/modules/ListEditorLarge.vue';
import PropertiesEditorLarge from '@/features/editor/modules/PropertiesEditorLarge.vue';
import TagEditor from '@/features/editor/modules/TagEditor.vue';
import TitleEditor from '@/features/editor/modules/TitleEditor.vue';
import DocumentId from '@/features/editor/components/DocumentId.vue';
import DocumentJsonEditor from './DocumentJsonEditor.vue';

import ObjectEditor from '@/features/editor/ObjectEditor.vue';

import { BaseDocumentEditor } from '@/features/editor/base/BaseDocumentEditor';
import EditorContainer from '@/features/editor/components/EditorContainer.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    ListEditorLarge,
    PropertiesEditorLarge,
    TagEditor,
    TitleEditor,
    ObjectEditor,
    EditorContainer,
    DocumentJsonEditor,
    DocumentId,
  },
  mixins: [BaseDocumentEditor],
  setup() {
    const { isGlobalAdmin, canEditCommunity } = usePermissions();
    const { updateProperties, updateTitle, updateTag } = useData();
    return {
      updateProperties,
      updateTitle,
      updateTag,
      isGlobalAdmin,
      canEditCommunity,
    };
  },
  data() {
    return {
      editors: ['document', 'object'],
      activeEditor: null,
    };
  },

  computed: {
    properties() {
      return this.document?.properties;
    },
    list() {
      return this.document?.list?.items || [];
    },
  },
  watch: {
    title: {
      immediate: true,
      handler(newValue) {
        if (this.titleBuffer != newValue) {
          this.titleBuffer = newValue;
        }
      },
    },
    tag: {
      immediate: true,
      handler(newValue) {
        if (this.tagBuffer != newValue) {
          this.tagBuffer = newValue;
        }
      },
    },
  },
  methods: {
    close() {
      this.$dialog.push('/');
    },
    open(id) {
      this.$dialog.push({ name: 'DocumentEditor', params: { id } });
    },
    saveTitle() {
      if (this.titleBuffer != this.title) {
        this.updateTitle(this.id, this.titleBuffer);
      }
    },
    saveTag() {
      if (this.tagBuffer != this.tag) {
        this.updateTag(this.id, this.tagBuffer);
      }
    },
  },
});
