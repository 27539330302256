export default {
  general: {
    hello: 'Hello',
    close: 'Close',
    cancel: 'Cancel',
    or: 'or',
    saveChanges: 'Save Changes',
    pleaseSelect: 'Please select',
    back: 'Back',
    expand: 'Expand',
    collapse: 'Collapse',
    more: 'More',
    reload: 'Reload',
    upload: 'Upload',
    done: 'Done',
    submit: 'Submit',
    untitled: 'Untitled',
    search: 'Search',
    reset: 'Reset',
    displayName: 'Public Name or Pseudonym',
    error: {
      unknown: 'Unknown error, please contact your admin.',
      typeError: 'Type Error: Please check your inputs and try again.',
      networkError:
        'Network Error: A connection to the server could not be established.',
      pleaseCheckYourConnection:
        'It seems that you do not have an internet connection. Please establish an internet connection and reload the page',
      reconnectionFailed:
        'We could not establish a connection. Please try reloading the page.',
      noActiveCommunities:
        'You currently do not have any active community for this account. Please contact your admin, if you are trying to access a specific community.',
    },
    validation: {
      isRequired: 'Please enter something!',
      classValidator:
        "Your input doesn't meet all requirements. Please check your input against the requirements displayed at each corresponding input field.",
    },
  },
  communities: {
    welcome: 'Welcome to fillip',
    selectOrCreateCommunity: 'Select or create a community!',
    selectCommunity: 'Select a community!',
    open: 'Open',
    createdCommunities: 'My Communities',
    joinedCommunities: 'Joined Communities',
    publicCommunities: 'Public Communities',
    delete: 'Delete',
    communityDeleted: 'Community "{title}" deleted',
    switchCommunity: 'Switch community',
    createNewCommunity: 'Create a new Community',
    joinBySlug: 'Enter a community slug to find and enter a community',
    showMore: 'Show more',
    showLess: 'Show less',
    createFirstCommunity:
      "You haven't creted a community yet. Create your first community now.",
    loginToCreate:
      'Please login or create an account to create your first community.',
    joinFirstCommunity:
      "You haven't joined a community yet. Join your first community now.",
    createCommunity: {
      createCommunity: 'Create Your Community',
      preview: 'Preview',
      upload: 'Upload',
      name: 'Community Name',
      slug: 'Slug',
      subtitle: 'Subtitle',
      description: 'Description',
      placeholderName: 'Awesome Fillip Community',
      placeholderSlug: 'awesome-community-slug',
      placeholderSubtitle: 'Awesome Subtitle',
      placeholderDescription: 'Awesome Description',
      backToHub: 'Back to community hub',
      access: 'Access',
      public: 'Publicly accessable',
      private: 'Private Community',
      status: 'Status',
      language: 'Language',
      shell: 'Shell',
    },
    editCommunity: 'Edit community',
    communityEditor: 'Community Editor',
    error: {
      slugAlreadyExists: 'This community code already exists',
      notAuthorizedToEdit:
        'You are not authorized to edit the events of other users',
      notAuthorizedToDelete: 'You may only delete communities created by you.',
      noCommunityFoundForSlug:
        'Sorry, we couldn\'t find a community with the community code: "{argument}". Please check the community code and try again.',
      missingSlug:
        'Please provide a valid community code to enter a community.',
      loggedInUserRequired: 'You need to be logged in to join this community.',
      couldNotCreate:
        'Could not create new community: "{argument}". Please try again.',
      couldNotFindAll: 'Failed to get all communites.',
      titleNotEmpty: 'Please provide a title',
      accessNotEmpty:
        'Please choose whether the community is publicly accessable or not',
      titleTooLong:
        'Community titles may only consist of a maximum of {argument} characters.',
      couldNotUpdate:
        'Community with slug: "{argument}" could not be updated. Please try again.',
    },
  },
  profile: {
    yourProfile: 'Your Profile',
    displayNameRequired: 'Display name required.',
    updated: 'Your profile information has been successfully updated.',
    memberProfile: 'Profile',
    displayName: 'Display Name',
    description: 'Description',
    personalColor: 'Personal color',
  },
  participants: {
    participants: 'Participants',
    all: 'All',
    online: 'Online',
    circle: 'Circle',
    scene: 'Near me',
    invite: 'Invite',
    status: 'Status',
    location: 'Location',
    noLocation: 'No location',
    profile: 'Profile',
  },
  circles: {
    circles: 'Circles',
    members: 'Members',
    takeLead: 'Take Lead',
    stopLead: 'Stop Lead',
    gather: 'Gather',
    leader: 'Leader: ',
    noLeader: 'No Leader',
    rejoin: 'rejoin',
    sync: 'Sync',
    stopSync: 'Stop Sync',
    addCircle: 'Add Circle',
  },
  worldNavigation: {
    title: 'Navigation',
    noLocation: 'No location',
    linkOverview: 'Navigation links',
    noLinks: 'No navigation links available',
    focus: 'Focus',
    unfocus: 'Unfocus',
    back: 'Back',
  },
  icon: {
    default: 'Pick your icon',
    noIcon: 'No icon found',
  },
  explorer: {
    import: 'Import',
    export: 'Export',
    open: 'Open',
    remove: 'Remove',
    clearChildren: 'Clear children',
    edit: 'Edit',
    editJson: 'Edit as JSON',
    editDocument: 'Document Editor',
    close: 'Close',
    duplicate: 'Duplicate',
    copy: 'Copy',
    paste: 'Paste',
    cut: 'Cut',
    exportHelp: 'Select all text and copy to export',
    importHelp: 'Paste content to import',
    goto: 'Go here',
    runMigrations: 'Run Migrations {current} to {latest}',
  },
  templateClipboard: {
    title: 'Object Clipboard',
    clear: 'Clear',
    target: 'Target',
    copiedToClipboard: 'Copied to clipboard',
    importSuccessful: 'Import successful',
    documentUpdated: 'Document updated',
  },
  editing: {
    edit: 'Edit',
    delete: 'Delete',
    tool: 'Tool',
    tools: 'Tools',
    addTool: 'Add Tool',
    editTool: 'Edit Tool',
    hideTools: 'Hide Tools',
    createTool: 'Create Tool',
    onlyVideoconference: 'Only VideoConference',
    workspaceTools: 'Workspace Tools',
    activateTool: 'Activate',
    createAndActivate: 'Create & Activate',
  },
  audios: {
    error: {
      uploadFetchFailed: 'Uploading the audio failed. Please try again.',
      notAuthorizedToDelete:
        'Deleting failed. You can only delete audios you uploaded.',
    },
  },
  media: {
    error: {
      uploadFetchFailed: 'File upload failed. Please try again.',
      notAuthorizedToDelete:
        'Deleting failed. You can only delete files you uploaded.',
    },
  },
  userAccount: {
    userAccount: 'User Account',
    accountSettings: 'Edit',
    profiles: 'Profiles',
    editProfile: 'Edit Profile',
    editAccount: 'Edit Account',
  },
  branding: {
    primaryColor: 'primaryColor',
    primaryContrastColor: 'primaryContrastColor',
    accentColor: 'accentColor',
    errorColor: 'errorColor',
    successColor: 'successColor',
    infoColor: 'infoColor',
    backgroundColor: 'backgroundColor',
    thumbnailStyle: 'Thumbnail-Style',
    tile: 'Eckig',
    rounded: 'Abgerundet',
    colors: 'Farben',
    Optionen: 'Optionen',
  },
  participantStatus: {
    setStatus: 'Set Status',
    chooseYourStatus: 'Change your Status',
    beRightBack: 'Be right back',
    busy: 'Busy',
    needHelp: 'I need help!',
    offline: 'Offline',
    online: 'Online',
    away: 'Away',
  },
  videoConference: {
    toggleMicrophone: 'Toggle microphone',
    toggleCamera: 'Toggle camera',
    screenshare: 'Share your screen',
    screenshareOff: 'Stop sharing your screen',
    enterFullscreen: 'Fullscreen',
    exitFullscreen: 'Exit Fullscreen',
    selectYourDevices: 'Select Your Devices',
    status: 'Status',
    camera: 'Camera',
    microphone: 'Microphone',
    cameras: 'Cameras',
    microphones: 'Microphones',
    speaker: 'Speaker',
    applyChanges: 'Apply changes',
    permissionDenied:
      'Permission denied! Please check your browser permissions.',
    checkBrowserPermissions:
      'Permission denied! Please provide permissions to use your camera, microphone and speaker in your browser controls.',
    currentlyUsed:
      'It seems that your camera is currently used in another program.',
    micPermissionDenied:
      'Please check the browser permissions for your microphone and speaker to select between devices.',
    startVideo: 'Video visible for all',
    stopVideo: 'Stop Video for others',
    muteMicrophone: 'Mute Microphone',
    unmuteMicrophone: 'Unmute Microphone',
    muteVideoconference: 'Mute Videoconference',
    unmuteVideoconference: 'Unmute Videoconference',
    upscaleVideoResolution: 'Increase my camera resolution',
    downscaleVideoResolution: 'Decrease my camera resolution',
    shortcuts: {
      MuteVideoconference: 'Shift + Alt + M',
      toggleAudio: 'Shift + Alt + A',
      toggleVideo: 'Shift + Alt + V',
      toggleScreen: 'Shift + Alt + S',
    },
  },
  fileCollections: {
    error: {
      couldNotFindAllCollections: 'Could not find Collections',
      couldNotFindCollectionGeneric: 'Could not find your requested collection',
      couldNotFindCollection: 'Could not find a collection under "{argument}"',
      couldNotCreateCollection: 'Could not create new collection "{argument"',
      unauthorizedToEditCollection:
        'You are not authorized to edit collection "{argument}"',
      unauthorizedToDeleteCollection:
        'You are not authorized to edit collection "{argument}"',
      couldNotCreateFile: 'Could not create new file "{argument}"',
      unauthorizedToEditFile:
        'You are not authorized to edit file "{argument}"',
      couldNotGetAllFilesOfCommunity:
        'Could not get all files from community "{argument}"',
      couldNotGetAllFilesInCollection:
        'Could not find any files for this collection',
      couldNotFindFileGeneric: 'Could not find your requested file',
      couldNotFindFile: 'Could not find a file under "{argument}"',
      unauthorizedToAccessPrivateFile:
        'You are not authorized to access the private file under "{argument}"',
      unauthorizedToDeleteFile:
        'You are not authorized to edit file "{argument}"',
    },
  },
  vm: {
    error: {
      InvalidVmCommand: 'Invalid VM command: "{argument}"',
      MethodOnNonExistentNode:
        'Trying to call method on non existent node {argument}',
      ObjectCannotHaveChildren:
        'This object cannot create children. Please select a different parent object.',
      UnknownMethod: 'No such method: "{argument}"',
      InfiniteLoopDetected: 'Detected infinite loop in VM',
      RootNotDeletable: 'Cannot delete root',
      InvalidId: 'Invalid ID: {argument}',
      NodeDoesNotHaveParent: "Node doesn't have parent",
      InvalidObject: 'Object invalid',
      InvalidLink: 'Invalid Link',
      ObjectWithTitleAlreadyExists: 'Object with given title already exists',
      ObjectNeedsTitle: 'Please supply a title for the object to add',
      ObjectWithSlugAlreadyExists: 'Object with given slug already exists',
      ObjectWithIdAlreadyExists: 'Object with ID "{argument}" already exists',
    },
  },
  debug: {
    debug: 'Debugging',
    producer: 'Producer',
    consumer: 'Consumer',
    data: 'Data',
    locations: 'Locations',
    reassignPosts: 'Reassign posts',
  },
};
