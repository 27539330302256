import { Transport, Producer, Consumer } from 'mediasoup-client/lib/types';

export const getFullMediasoupResourceId = (
  mediasoupResource: Transport | Producer | Consumer,
) => {
  if (!mediasoupResource.appData.routerId) throw new Error('Missing routerId');
  return createMediasoupResourceId(
    mediasoupResource.appData.routerId,
    mediasoupResource.id,
  );
};

export const createRouterId = (
  serverId: string,
  routerGroupId: string,
  routerIndex: number,
) => {
  return `${serverId}/${routerGroupId}/${routerIndex}`;
};

export const createMediasoupResourceId = (routerId, mediasoupId) => {
  return `${routerId}::${mediasoupId}`;
};

export const parseFullMediasoupResourceId = (id: string) => {
  const [routerId, mediasoupId] = id.split('::');
  return { routerId, mediasoupId, ...parseRouterId(routerId) };
};

export const getMediasoupResourceRouterId = (
  mediasoupResource: Transport | Producer | Consumer,
) => {
  if (!mediasoupResource.appData.routerId) throw new Error('Missing routerId');
  return mediasoupResource.appData.routerId;
};

export const parseRouterId = (routerId: string) => {
  const [serverId, routerGroupId, routerIndex] = routerId.split('/');
  return {
    serverId,
    routerGroupId,
    routerIndex: parseInt(routerIndex),
  };
};
