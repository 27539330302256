import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagH5: DataDocument = {
  id: 'tag-h5',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Heading 5',
    // TODO: Replace with h5 after update to font awesome 6
    icon: 'heading',
    isProtected: true,
  },
  element: {
    type: 'element.text',
    textFormat: 'h5',
    textContent: ':$text',
  },
  class: {
    class: ':`${$containerClasses}`',
    elementClass: ':`${$elementClasses}`',
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'h5',
    title: 'Heading 5',
    category: 'content',
    // TODO: Replace with h5 after update to font awesome 6
    icon: 'heading',
    editor: 'h5',
  },
  interfaceDefinition: {
    title: '',
    inheritFrom: 'baseContent',
    props: [
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":`${$$contentTagClasses} ${$$headlineClasses} text-base xl:text-lg ${$containerClasses || properties?.containerClasses?.value || ''}`",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":`font-medium ${$elementClasses || properties?.elementClasses?.value || ''}`",
      },
    ],
  },
};
