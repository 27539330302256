














































































































































































































































// Module Editors
import ActionsEditor from './modules/ActionsEditor.vue';
import ArrangementEditor from './modules/ArrangementEditor.vue';
import AvatarEditor from './modules/AvatarEditor.vue';
import ChildrenEditor from './modules/ChildrenEditor.vue';
import ClassEditor from './modules/ClassEditor.vue';
import ComputedsEditor from './modules/ComputedsEditor.vue';
import DraggableEditor from './modules/DraggableEditor.vue';
import DroppableEditor from './modules/DroppableEditor.vue';
import ElementEditor from './modules/ElementEditor.vue';
import InfoEditor from './modules/InfoEditor.vue';
import NotesEditor from './modules/NotesEditor.vue';
import InterfaceDefinitionEditor from './modules/InterfaceDefinitionEditor.vue';
import ListenerEditor from './modules/ListenerEditor.vue';
import LocationEditor from './modules/LocationEditor.vue';
import ModelEditor from './modules/ModelEditor.vue';
import NavigationEditor from './modules/NavigationEditor.vue';
import PlacementEditor from './modules/PlacementEditor.vue';
import RolesEditor from './modules/RolesEditor.vue';
import SchemaEditor from './modules/SchemaEditor.vue';
import SceneDefinitionsEditor from './modules/SceneDefinitionsEditor.vue';
import SheetEditor from './modules/SheetEditor.vue';
import SizeEditor from './modules/SizeEditor.vue';
import StationEditor from './modules/StationEditor.vue';
import StyleEditor from './modules/StyleEditor.vue';
import TagDefinitionEditor from './modules/TagDefinitionEditor.vue';
import TemplatesEditor from './modules/TemplatesEditor.vue';
import TransitionsEditor from './modules/TransitionsEditor.vue';
import VariablesEditor from './modules/VariablesEditor.vue';
import VideoConferencingEditor from './modules/VideoConferencingEditor.vue';
import ParticipantAccountEditor from './modules/ParticipantAccountEditor.vue';
import ParticipantCircleEditor from './modules/ParticipantCircleEditor.vue';
import ParticipantStatusEditor from './modules/ParticipantStatusEditor.vue';
import ParticipantVcEditor from './modules/ParticipantVcEditor.vue';
import CameraEditor from './modules/CameraEditor.vue';
import PropertiesEditorLarge from './modules/PropertiesEditorLarge.vue';
import { BaseDocumentEditor } from '@/features/editor/base/BaseDocumentEditor';
import { BaseModuleEditor } from '@/features/editor/base';
import { ModulesMeta } from '@fillip/api';
import { usePermissions, useElementSize } from '@/composables';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'ObjectEditor',
  components: {
    ActionsEditor,
    ArrangementEditor,
    AvatarEditor,
    ChildrenEditor,
    ClassEditor,
    ComputedsEditor,
    DraggableEditor,
    DroppableEditor,
    ElementEditor,
    InfoEditor,
    NotesEditor,
    InterfaceDefinitionEditor,
    ListenerEditor,
    LocationEditor,
    ModelEditor,
    NavigationEditor,
    PlacementEditor,
    RolesEditor,
    SchemaEditor,
    SheetEditor,
    SizeEditor,
    SceneDefinitionsEditor,
    StationEditor,
    StyleEditor,
    TagDefinitionEditor,
    TemplatesEditor,
    TransitionsEditor,
    ParticipantAccountEditor,
    ParticipantCircleEditor,
    ParticipantStatusEditor,
    ParticipantVcEditor,
    VariablesEditor,
    VideoConferencingEditor,
    PropertiesEditorLarge,
    CameraEditor,
  },
  mixins: [BaseDocumentEditor, BaseModuleEditor],
  setup() {
    const { canEditCommunity } = usePermissions();

    return {
      canEditCommunity,
    };
  },
  data() {
    return {
      panel: null,
    };
  },
  computed: {
    items() {
      const activeItems = [];
      const inactiveItems = [];
      Object.keys(ModulesMeta).forEach((key) => {
        if (
          [
            'list',
            'data',
            'info',
            'participantAccount',
            'participantStatus',
            'participantCircle',
            'participantVc',
            'sceneDefinitions',
          ].includes(key)
        )
          return;
        if (!this.buffer?.[key]) inactiveItems.push(key);
        else activeItems.push(key);
      });

      return {
        active: activeItems.map((moduleName) => {
          return { text: this.$t(`${moduleName}.title`), value: moduleName };
        }),
        inactive: inactiveItems.map((moduleName) => {
          return {
            text: this.$t(`${moduleName}.title`),
            value: moduleName,
          };
        }),
      };
    },
    editorWrapper() {
      return this.large ? 'large' : 'small';
    },
  },
  methods: {
    addModule(moduleName) {
      this.buffer[moduleName] = ModulesMeta[moduleName].default;
      this.triggerSave(moduleName);
      this.panel = 0;
    },
    removeModule(moduleName) {
      this.buffer[moduleName] = null;
      this.triggerSave(moduleName);
    },
  },
});
