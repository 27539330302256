import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';

export const TemplateTagH2: DataDocument = {
  id: 'tag-h2',
  parentId: tagsTemplateDirectoryId,
  info: {
    title: 'Heading 2',
    icon: 'h2',
    isProtected: true,
  },
  element: {
    type: 'element.text',
    textFormat: 'h2',
    textContent: ':$text',
  },
  class: {
    class: ':`${$containerClasses}`',
    elementClass: ':`${$elementClasses}`',
  },
  tag: {
    tag: 'tag',
  },
  tagDefinition: {
    tag: 'h2',
    title: 'Heading 2',
    category: 'content',
    icon: 'h2',
    editor: 'h2',
  },
  interfaceDefinition: {
    title: '',
    inheritFrom: 'baseContent',
    props: [
      {
        title: 'Container Classes',
        key: 'containerClasses',
        type: 'string',
        defaultValue:
          ":`${$$contentTagClasses} ${$$headlineClasses} text-lg md:text-xl ${$containerClasses || properties?.containerClasses?.value || ''}`",
      },
      {
        title: 'Element Classes',
        key: 'elementClasses',
        type: 'string',
        defaultValue:
          ":`font-bold ${$elementClasses || properties?.elementClasses?.value || ''}`",
      },
    ],
  },
};
