


















import { useStore, useRouter } from '@/composables';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  // TODO: Fix width of notifications
  name: 'Notification',
  props: {
    type: {
      type: String,
      default: 'info',
    },
    text: {
      type: String,
      default: '',
    },
    action: {
      type: Object,
      default: null,
    },
    // example Object: {
    //   buttonText: string,
    //   color: string,
    //   action: string,
    //   target: string,
    //   arguments: Object
    // }
  },
  setup(props: any, { emit }) {
    const { router } = useRouter();
    const { store } = useStore();

    const performAction = (action: any) => {
      switch (action.action) {
        case 'navigate':
          router.push({ name: action.target, params: action.arguments });
          break;

        case 'dispatchAction':
          store.dispatch(action.target, action.arguments);
          break;
        case 'reload':
          location.reload();
          break;
        default:
          console.error(`action not available: ${action.action}`);
          break;
      }
      emit('remove');
    };
    return { performAction };
  },
});
