



























import {
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
} from '@vue/composition-api';
import { useStore } from '@/composables';

export default defineComponent({
  setup() {
    const { store } = useStore();

    const stats = ref([]);
    const interval = ref(null);
    const open = reactive({});

    const getConsumerStats = async () => {
      const consumers = store.getters.communityStore?.mediasoup?.consumers;
      if (!consumers) stats.value = [];

      const result = [];

      for (const consumer of Object.values(consumers) as any) {
        try {
          const stats = await consumer.getStats();
          result.push({
            id: consumer.id,
            rtpParameters: consumer.rtpParameters,
            stats: Array.from(stats.values()).filter(
              (stat: any) => stat.type != 'certificate',
            ),
          });
        } catch (error) {
          console.error(
            `Couldn't get stats for consumer ${consumer.id}`,
            consumers,
          );
        }
      }

      stats.value = result;
    };

    onMounted(() => {
      getConsumerStats();
      interval.value = setInterval(getConsumerStats, 1500);
    });
    onBeforeUnmount(() => {
      clearInterval(interval.value);
    });

    return { stats, open };
  },
});
