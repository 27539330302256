















import Vue from 'vue';

export default Vue.extend({
  name: 'InputBooleanCheckbox',
  props: {
    value: {
      type: [Boolean, String],
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    trueValue: {
      type: [String, Boolean],
      default: true,
    },
    falseValue: {
      type: [String, Boolean],
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
});
