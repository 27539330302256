import { DataDocument, tagsTemplateDirectoryId } from '@fillip/api';
export * from './TemplateTagBaseContent';
export * from './TemplateTagPage';
export * from './TemplateTagH1';
export * from './TemplateTagH2';
export * from './TemplateTagH3';
export * from './TemplateTagH4';
export * from './TemplateTagH5';
export * from './TemplateTagH6';
export * from './TemplateTagText';
export * from './TemplateTagStyledText';
export * from './TemplateTagHtml';
export * from './TemplateTagMarkdown';
export * from './TemplateTagReference';
export * from './TemplateTagButton';
export * from './TemplateTagEmbed';
export * from './TemplateTagParticipant';
export * from './TemplateTagImage';
export * from './TemplateTagDocument';
export * from './TemplateTagSubline';
export * from './TemplateTagMetaTag';
export * from './TemplateTagIcon';

import { TemplateTagBaseContent } from './TemplateTagBaseContent';
import { TemplateTagPage } from './TemplateTagPage';
import { TemplateTagH1 } from './TemplateTagH1';
import { TemplateTagH2 } from './TemplateTagH2';
import { TemplateTagH3 } from './TemplateTagH3';
import { TemplateTagH4 } from './TemplateTagH4';
import { TemplateTagH5 } from './TemplateTagH5';
import { TemplateTagH6 } from './TemplateTagH6';
import { TemplateTagText } from './TemplateTagText';
import { TemplateTagStyledText } from './TemplateTagStyledText';
import { TemplateTagHtml } from './TemplateTagHtml';
import { TemplateTagMarkdown } from './TemplateTagMarkdown';
import { TemplateTagReference } from './TemplateTagReference';
import { TemplateTagQuote } from './TemplateTagQuote';
import { TemplateTagButton } from './TemplateTagButton';
import { TemplateTagEmbed } from './TemplateTagEmbed';
import { TemplateTagParticipant } from './TemplateTagParticipant';
import { TemplateTagImage } from './TemplateTagImage';
import { TemplateTagTable } from './TemplateTagTable';
import { TemplateTagDocument } from './TemplateTagDocument';
import { TemplateTagSubline } from './TemplateTagSubline';
import { TemplateTagMetaTag } from './TemplateTagMetaTag';
import { TemplateTagIcon } from './TemplateTagIcon';

export const DefaultTagTemplates: DataDocument[] = [
  TemplateTagBaseContent,
  TemplateTagPage,
  TemplateTagH1,
  TemplateTagH2,
  TemplateTagH3,
  TemplateTagH4,
  TemplateTagH5,
  TemplateTagH6,
  TemplateTagText,
  TemplateTagStyledText,
  TemplateTagHtml,
  TemplateTagMarkdown,
  TemplateTagReference,
  TemplateTagQuote,
  TemplateTagButton,
  TemplateTagEmbed,
  TemplateTagParticipant,
  TemplateTagImage,
  TemplateTagTable,
  TemplateTagDocument,
  TemplateTagSubline,
  TemplateTagMetaTag,
  TemplateTagIcon,
];

export const TagTemplates: DataDocument[] = [
  TemplateTagBaseContent,
  TemplateTagPage,
  TemplateTagH1,
  TemplateTagH2,
  TemplateTagH3,
  TemplateTagH4,
  TemplateTagH5,
  TemplateTagH6,
  TemplateTagText,
  TemplateTagStyledText,
  TemplateTagHtml,
  TemplateTagMarkdown,
  TemplateTagReference,
  TemplateTagQuote,
  TemplateTagButton,
  TemplateTagEmbed,
  TemplateTagParticipant,
  TemplateTagImage,
  TemplateTagTable,
  TemplateTagDocument,
  TemplateTagSubline,
  TemplateTagMetaTag,
  TemplateTagIcon,
];

export const TagsTemplateDirectory: DataDocument = {
  id: tagsTemplateDirectoryId,
  info: {
    title: 'Tags',
    icon: 'text',
    isProtected: true,
  },
  tag: {
    tag: 'collection',
  },
  list: {
    items: TagTemplates.map((template) => ({
      id: template.id,
    })),
  },
};
